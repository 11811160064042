import { createPortal } from 'react-dom'
import { useLockScroll } from '@src/hooks/useLockScroll'

import classes from './LoyaltyReward.module.scss'
import { useCallback, useState } from 'react'
import classNames from 'classnames'
import { Cashback } from './Cashback'
import { Rakeback } from './Rakeback'

const TABS = ['CASHBACK', 'RAKEBACK', 'ELITESEAT', 'REWARDS'] as const

type Tab = typeof TABS[number]

export function LoyaltyReward() {
  const [isOpen, setIsOpen] = useState(false)
  const [tab, setTab] = useState<Tab>('CASHBACK')

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  useLockScroll(isOpen)

  const getActiveTabContent = useCallback(() => {
    switch (tab) {
      case 'CASHBACK':
        return <Cashback />
      case 'RAKEBACK':
        return <Rakeback />
      default:
        return null
    }
  }, [tab])

  return (
    <Portal>
      {isOpen && (
        <Overlay onClick={close}>
          <section className={classes.root}>
            <header className={classes.header}>
              <h2 className={classes.heading}>LOYALTY REWARD</h2>
              <button type="button">
                <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <ellipse
                    cx="12.4286"
                    cy="12.5"
                    rx="12.5"
                    ry="12.4286"
                    transform="rotate(-90 12.4286 12.5)"
                    fill="#333333"
                  />
                  <path
                    d="M9 17L12.5 13L16 17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 9L12.5 13L9 9"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </header>
            <div className={classes.wrapper}>
              <div className={classes.buttons}>
                {TABS.map((t, i) => (
                  <button
                    key={i}
                    type="button"
                    className={classNames(classes.button, {
                      [classes.active]: tab === t,
                    })}
                    onClick={() => setTab(t)}
                  >
                    {t}
                  </button>
                ))}
              </div>
              <div className={classes.content}>{getActiveTabContent()}</div>
            </div>
          </section>
        </Overlay>
      )}
    </Portal>
  )
}

function Overlay({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) {
  return (
    <div onClick={onClick} className={classes.overlay}>
      {children}
    </div>
  )
}

function Portal({ children }: { children: React.ReactNode }) {
  const body = document.querySelector('body') as HTMLBodyElement

  return createPortal(children, body)
}

import React, { useEffect } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import Currencies from '../Currencies/Currencies'
import TextBlock from '../TextBlock/TextBlock'
import { LatestBets } from '../LatestBets/LatestBets'
import Footer from '../Footer/Footer'

import { useRecoverPassword } from '../authentication/RecoverPassword/RecoverPassword'
import { ActionBanners } from '../ActionBanners'
import { RakeBackAnnouncement } from '../RakeBackAnnouncement'
import { TopGames } from '../TopGames'

import classNames from 'classnames'
import { User } from '@src/reducers/authentication.reducer'

import './LandingComponent.scss'
import classes from './LandingComponent.module.scss'
import ClassicGames from '../TopGames/classicGames'
import { Events } from '../Events'
import { useLocation } from 'react-router-dom'
import { isUserLoggedIn } from '@src/utils/functions'
import { useActionBanners } from '@src/context/ActionBannerContext'
import Blog from './Blog/Blog'
import Pedestal from './Pedestal'
import DepositBanner from './DepositBanner'
import LandingCarousel from './LandingCarousel'
import LandingNavigation from './LandingNavigation/LandingNavigation'
import { useTranslation } from 'react-i18next'

export type Banner = {
  id: string
  image: string
  onClick?: () => void

  redirect?: string
}
export const LandingPageComponent = ({ me }: { me?: User }) => {
  useRecoverPassword()
  const { t, i18n } = useTranslation()

  const { pathname } = useLocation()
  const isLoggedIn = isUserLoggedIn()
  const { fetchBanners } = useActionBanners()

  useEffect(() => {
    if (pathname === '/' || pathname.startsWith('/bonus/')) {
      fetchBanners()
    }
  }, [pathname, fetchBanners])

  useEffect(() => {
    if (!pathname.startsWith('/bonus/')) return
    if (isLoggedIn) return
    const uuid = pathname.split('/').pop() as string

    localStorage.setItem('referral_uuid', uuid)
  }, [pathname, isLoggedIn])

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng)
  // }

  return (
    <div className={classNames('overflow-x-hidden mx-auto', classes.root)}>
      {/* <div>
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('pt')}>Portuguese</button>
      </div> */}
      <LandingCarousel />
      <LandingNavigation />
      <Pedestal />
      <ActionBanners />
      <RakeBackAnnouncement />
      <TopGames />
      <ClassicGames />
      <Events />
      <TopGames category="slots" />
      <TopGames category="live" />
      <DepositBanner />
      <Blog />
      <LatestBets />
      <div className="mb-22">
        <TextBlock
          headline={t('introText.heading', 'Who is blackjack.fun?')}
          shorttext={t(
            'introText.shortText',
            'Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various cryptocurrencies and we will add more in the future. Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge.'
          )}
          text={t(
            'introText.text',
            `Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various cryptocurrencies and we will add more in the future.<br/>
    Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge.<br/>
    Blackjack.fun's greatest achievement is its community. The members can get involved in the functionality of the platform, they can bring improvements, change various aspects of the experience and even suggest games. All these are meant to provide their members a unique experience, an honest, transparent and safe approach, and a place shaped based on their perspective.`
          )}
        />
      </div>
      <div className="currencies flex flex-wrap px-35 py-30 bg-eerieBlack rounded-lg mb-30">
        <div className="text-white text-base font-medium mb-30">{t('coins.heading', 'Accepted Networks')}</div>
        <Currencies />
      </div>
      <Footer />
    </div>
  )
}

export const listFreespins = graphql`
  query LandingComponentListFreespinCampaignsQuery {
    listFreespinCampaigns {
      name
    }
  }
`

export const joinFreespinMutation = graphql`
  mutation LandingComponentJoinFreespinCampaignMutation($input: JoinFreespinCampaignInput!) {
    joinFreespinCampaign(input: $input) {
      res
    }
  }
`

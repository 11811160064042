const BlogIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M8.04952 11.896C7.79714 11.896 7.54233 11.879 7.29237 11.8499C7.04727 11.8183 6.87255 11.5951 6.9041 11.35C6.93565 11.1049 7.15648 10.9302 7.40401 10.9617C7.61514 10.9884 7.83354 11.003 8.04952 11.003C10.8379 11.003 13.1045 8.73635 13.1045 5.948C13.1045 3.15965 10.8354 0.893047 8.04952 0.893047C5.2636 0.893047 2.99457 3.16208 2.99457 5.948C2.99457 6.26591 3.0237 6.58382 3.08195 6.89444C3.12805 7.13712 2.96788 7.37251 2.7252 7.41862C2.48253 7.46473 2.24714 7.30457 2.20103 7.06189C2.13308 6.69788 2.09668 6.32172 2.09668 5.95043C2.09668 2.66944 4.76611 0 8.04952 0C11.3329 0 14 2.66944 14 5.95043C14 9.23141 11.3305 11.9009 8.04952 11.9009V11.896Z"
      fill="white"
    />
    <path
      d="M10.1901 8.28497C10.0858 8.28497 9.97898 8.24857 9.89404 8.17334C9.7096 8.00832 9.69262 7.72682 9.85522 7.53996C10.4668 6.85076 10.6294 5.85821 10.2702 5.00641C10.1731 4.7783 10.2799 4.51621 10.508 4.41914C10.7337 4.32207 10.9982 4.42884 11.0953 4.65696C11.5855 5.82181 11.3622 7.18565 10.525 8.13209C10.4352 8.23158 10.3139 8.28255 10.1901 8.28255V8.28497Z"
      fill="white"
    />
    <path
      d="M5.19066 6.39212C4.94313 6.39212 4.7417 6.19069 4.7417 5.94316C4.7417 5.05982 5.0863 4.22988 5.70998 3.6062C6.99859 2.31759 9.09532 2.31759 10.3864 3.6062C10.5611 3.78093 10.5611 4.06485 10.3864 4.23958C10.2116 4.41431 9.9277 4.41431 9.75297 4.23958C8.81381 3.30042 7.28496 3.30042 6.34337 4.23958C5.88714 4.69581 5.63718 5.30007 5.63718 5.94316C5.63718 6.19069 5.43576 6.39212 5.18823 6.39212H5.19066Z"
      fill="white"
    />
    <path
      d="M8.39649 9.229C8.17323 9.229 7.97908 9.06155 7.95239 8.83586C7.92327 8.59076 8.09801 8.3675 8.34311 8.33595C8.61248 8.3044 8.86971 8.22675 9.11239 8.10783C9.33322 7.99863 9.60259 8.09084 9.71179 8.31167C9.821 8.53494 9.72879 8.80189 9.50795 8.91109C9.17549 9.07369 8.82117 9.17803 8.4523 9.22414C8.43289 9.22414 8.41591 9.22657 8.39892 9.22657L8.39649 9.229Z"
      fill="white"
    />
    <path
      d="M12.4031 7.61276C12.3303 7.61276 12.255 7.59578 12.1871 7.55695C11.7939 7.34097 11.5513 6.90901 11.5513 6.42609V5.46022C11.5513 4.76374 12.0657 4.1983 12.6991 4.1983H13.398C13.6456 4.1983 13.847 4.39973 13.847 4.64726C13.847 4.89479 13.6456 5.09621 13.398 5.09621H12.6991C12.5802 5.09621 12.4467 5.25395 12.4467 5.46265V6.4285C12.4467 6.60323 12.5341 6.727 12.6191 6.77311C12.835 6.89202 12.9151 7.16382 12.7962 7.38223C12.7137 7.53026 12.5608 7.61519 12.4031 7.61519V7.61276Z"
      fill="white"
    />
    <path
      d="M4.10107 6.82649C3.85354 6.82649 3.65212 6.62507 3.65212 6.37754V5.46265C3.65212 5.28792 3.56718 5.16416 3.47982 5.11805C3.26383 4.99914 3.18376 4.72734 3.30267 4.50893C3.42158 4.29295 3.69338 4.21287 3.91179 4.33178C4.30492 4.54776 4.5476 4.97972 4.5476 5.46265V6.37754C4.5476 6.62507 4.34618 6.82649 4.09865 6.82649H4.10107Z"
      fill="white"
    />
    <path
      d="M9.34779 11.7407C9.10026 11.7407 8.89884 11.5393 8.89884 11.2917V10.5928C8.89884 10.4739 8.7411 10.3404 8.5324 10.3404H7.95969C7.71216 10.3404 7.51074 10.139 7.51074 9.89148C7.51074 9.64395 7.71216 9.44254 7.95969 9.44254H8.5324C9.22888 9.44254 9.79675 9.957 9.79675 10.5904V11.2893C9.79675 11.5368 9.59532 11.7382 9.34779 11.7382V11.7407Z"
      fill="white"
    />
    <path
      d="M8.53245 2.44375H7.56904C6.87256 2.44375 6.30469 1.92928 6.30469 1.2959V0.596986C6.30469 0.349456 6.5061 0.148041 6.75363 0.148041C7.00116 0.148041 7.20259 0.349456 7.20259 0.596986V1.2959C7.20259 1.41481 7.36033 1.54827 7.56904 1.54827H8.53245C8.70718 1.54827 8.83094 1.46335 8.87705 1.37598C8.99596 1.16 9.26776 1.07991 9.48617 1.19882C9.70215 1.31773 9.78225 1.58953 9.66333 1.80794C9.44735 2.20107 9.01538 2.44375 8.53245 2.44375Z"
      fill="white"
    />
    <path
      d="M10.265 10.3331C10.0709 10.3331 9.89129 10.207 9.83548 10.0104C9.70929 9.58085 9.84519 9.10278 10.1849 8.76061L10.8669 8.07869C11.3595 7.58606 12.1239 7.54965 12.5729 7.99617L13.0679 8.49123C13.2427 8.66596 13.2427 8.9499 13.0679 9.12463C12.8932 9.29935 12.6093 9.29935 12.4345 9.12463L11.9395 8.62956C11.857 8.54462 11.6507 8.56161 11.5002 8.70964L10.8183 9.39156C10.6946 9.51533 10.6679 9.66336 10.6946 9.75558C10.7649 9.9934 10.6266 10.2409 10.3888 10.3113C10.3475 10.3234 10.3038 10.3283 10.2626 10.3283L10.265 10.3331Z"
      fill="white"
    />
    <path
      d="M4.305 4.20317C4.01621 4.20317 3.73713 4.09882 3.526 3.89012L3.03095 3.39505C2.85622 3.22032 2.85622 2.9364 3.03095 2.76167C3.20567 2.58694 3.4896 2.58694 3.66433 2.76167L4.1594 3.25674C4.24434 3.34167 4.44333 3.32953 4.59864 3.17422L5.28056 2.4923C5.40433 2.36854 5.43101 2.2205 5.40431 2.12586C5.33637 1.88803 5.47227 1.64051 5.71009 1.57013C5.94791 1.50218 6.19545 1.63809 6.26583 1.87591C6.38959 2.30545 6.25612 2.78351 5.91637 3.12568L5.23445 3.8076C4.97236 4.06969 4.63504 4.20317 4.30743 4.20317H4.305Z"
      fill="white"
    />
    <path
      d="M11.7915 4.20558C11.459 4.20558 11.1241 4.06725 10.8669 3.81001L10.185 3.12809C9.95687 2.89998 9.81612 2.60149 9.79428 2.29329C9.77002 1.96325 9.88165 1.64534 10.1049 1.4245L10.6 0.929446C10.7747 0.754719 11.0586 0.754719 11.2334 0.929446C11.4081 1.10417 11.4081 1.3881 11.2334 1.56283L10.7383 2.0579C10.6873 2.10886 10.6849 2.18652 10.6873 2.22534C10.6946 2.31999 10.7431 2.41948 10.8184 2.49471L11.5003 3.17663C11.6241 3.3004 11.7721 3.32709 11.8643 3.3004C12.1021 3.23245 12.3497 3.36835 12.42 3.60617C12.4904 3.844 12.3521 4.09152 12.1143 4.1619C12.0075 4.19344 11.8983 4.20801 11.7891 4.20801L11.7915 4.20558Z"
      fill="white"
    />
    <path
      d="M1.78367 13.3569C1.66475 13.3569 1.5507 13.3108 1.46576 13.2259L0.13104 11.8911C0.0461032 11.8062 0 11.6921 0 11.5732C0 11.4543 0.0461032 11.3403 0.13104 11.2578L2.92425 8.46455L1.48032 7.77536C1.32015 7.6977 1.22066 7.5351 1.22551 7.35795C1.23037 7.18079 1.33958 7.02306 1.5046 6.95511L8.57133 4.0624C8.79945 3.96775 9.06153 4.07939 9.15617 4.3075C9.25082 4.53562 9.14161 4.79772 8.91107 4.89236L2.78106 7.40163L3.88525 7.92825C4.0163 7.99134 4.1085 8.11267 4.13277 8.25585C4.15704 8.39903 4.11093 8.54464 4.009 8.64899L1.08476 11.5732L1.78609 12.2746L4.71034 9.35033C4.81227 9.2484 4.95787 9.20229 5.10348 9.22656C5.24666 9.25083 5.368 9.34305 5.4311 9.47409L5.9577 10.5758L8.00103 5.58884C8.09568 5.36073 8.35535 5.25152 8.58589 5.34374C8.81401 5.43838 8.92321 5.69804 8.831 5.92858L6.40423 11.8499C6.33628 12.0149 6.17854 12.1217 6.00138 12.129C5.83151 12.1338 5.66164 12.0343 5.58399 11.8742L4.89478 10.4302L2.10157 13.2234C2.01663 13.3084 1.90258 13.3545 1.78367 13.3545V13.3569Z"
      fill="white"
    />
  </svg>
)

export default BlogIcon

/**
 * @generated SignedSource<<84cee1e9690ee06f937b72a64be92d85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type ConfigurationServiceGlobalSettingsUpdatedSubscription$variables = {};
export type ConfigurationServiceGlobalSettingsUpdatedSubscription$data = {
  readonly globalSettingsUpdated: {
    readonly betLimits: ReadonlyArray<{
      readonly currency: string;
      readonly limits: ReadonlyArray<number>;
    }>;
    readonly betLimitsMp: ReadonlyArray<{
      readonly currency: string;
      readonly limits: ReadonlyArray<number>;
    }>;
    readonly disabled: boolean | null;
    readonly rainLimits: any;
    readonly tipLimits: {
      readonly tipMax: number;
      readonly tipMin: number;
    };
  } | null;
};
export type ConfigurationServiceGlobalSettingsUpdatedSubscription = {
  variables: ConfigurationServiceGlobalSettingsUpdatedSubscription$variables;
  response: ConfigurationServiceGlobalSettingsUpdatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "limits",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalSettingsObject",
    "kind": "LinkedField",
    "name": "globalSettingsUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BetLimit",
        "kind": "LinkedField",
        "name": "betLimits",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BetLimit",
        "kind": "LinkedField",
        "name": "betLimitsMp",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rainLimits",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TipLimit",
        "kind": "LinkedField",
        "name": "tipLimits",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tipMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tipMin",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigurationServiceGlobalSettingsUpdatedSubscription",
    "selections": (v1/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConfigurationServiceGlobalSettingsUpdatedSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ddb762fc478ca1e8220d24f7b7fa1bed",
    "id": null,
    "metadata": {},
    "name": "ConfigurationServiceGlobalSettingsUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription ConfigurationServiceGlobalSettingsUpdatedSubscription {\n  globalSettingsUpdated {\n    betLimits {\n      currency\n      limits\n    }\n    betLimitsMp {\n      currency\n      limits\n    }\n    disabled\n    rainLimits\n    tipLimits {\n      tipMax\n      tipMin\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a44d2cc8016ed2d2f8d1f0fcd4af3cf";

export default node;

import { TopTier } from './TopTier'

import classes from './Tier7.module.scss'

export function Tier7() {
  const image = <img alt="" src="/assets/pages/elite-seat/tier7.png" className={classes.image} />

  return (
    <TopTier
      image={image}
      text="Once here, you will be contacted by our executives. This is where it starts to get really fun in terms of rewards!"
    >
      <h2 className="font-poppins font-medium h-min">6</h2>
      <div className="flex flex-col transform translate-y-20 -translate-x-40">
        <h3 className="text-sm leading-5 font-bold text-white font-inter">
          TIER 7<br />
          <span className="text-pink">Elite Seat 6</span>
        </h3>
        <p className="text-white">
          VIP
          <br />
          <span className="text-eucalyptus">Wager</span> <span className="text-yellow">15.000 + BTC</span>
          <br />
          <br />
          <span className="text-white font-black font-inter text-xl">
            Mercedes Benz
            <br />
            AMG GT Coupe
          </span>
        </p>
      </div>
    </TopTier>
  )
}

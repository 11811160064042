/**
 * @generated SignedSource<<572ed0829adf4d654de3fab3b6d65d44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type useChatGivewayUpdatedSubscription$variables = {};
export type useChatGivewayUpdatedSubscription$data = {
  readonly giveawayUpdated: {
    readonly giveawayId: number;
    readonly currency: Currency;
    readonly amount: number;
    readonly usageLimit: number;
    readonly usages: number;
    readonly meta: any;
    readonly user: {
      readonly name: string;
      readonly uuid: string;
      readonly hasProfileImage: boolean;
      readonly vip: {
        readonly badgeId: string | null;
        readonly offset: number | null;
        readonly avatar: string | null;
      } | null;
    };
  };
};
export type useChatGivewayUpdatedSubscription = {
  variables: useChatGivewayUpdatedSubscription$variables;
  response: useChatGivewayUpdatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Giveaway",
    "kind": "LinkedField",
    "name": "giveawayUpdated",
    "plural": false,
    "selections": [
      {
        "alias": "giveawayId",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usageLimit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PublicUser",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasProfileImage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Vip",
            "kind": "LinkedField",
            "name": "vip",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "badgeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "offset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useChatGivewayUpdatedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useChatGivewayUpdatedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "325b81400a75d059b664145032dd04ee",
    "id": null,
    "metadata": {},
    "name": "useChatGivewayUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription useChatGivewayUpdatedSubscription {\n  giveawayUpdated {\n    giveawayId: id\n    currency\n    amount\n    usageLimit\n    usages\n    meta\n    user {\n      name\n      uuid\n      hasProfileImage\n      vip {\n        badgeId\n        offset\n        avatar\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "57a6d63bc109eaa03216f5986e1c94fc";

export default node;

import { graphql } from 'babel-plugin-relay/macro'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './ExternalDepositView.scoped.scss'
import { fetchQuery, useMutation } from 'react-relay'
import Env from '../../RelayEnvironment'
import {
  ExternalDepositViewTokensQuery,
  ExternalDepositViewTokensQueryResponse,
} from './__generated__/ExternalDepositViewTokensQuery.graphql'
import Select from 'react-select'
import {
  ExternalDepositViewGeneratePaymentOrderMutation,
  ExternalDepositViewGeneratePaymentOrderMutationResponse,
} from './__generated__/ExternalDepositViewGeneratePaymentOrderMutation.graphql'
import QRCode from 'react-qr-code'
import React from 'react'

export const ExternalDepositView = () => {
  const [generatePaymentOrder] =
    useMutation<ExternalDepositViewGeneratePaymentOrderMutation>(generatePaymentOrderMutation)
  const [tokens, setTokens] = useState<ExternalDepositViewTokensQueryResponse>()
  const [selectedOption, setSelectedOption] = useState<{ coin?: string; token?: string }>()
  const [paymentOrder, setPaymentOrder] = useState<ExternalDepositViewGeneratePaymentOrderMutationResponse>()
  const { t } = useTranslation()

  useEffect(() => {
    fetchQuery<ExternalDepositViewTokensQuery>(Env, listTokens, {}, { fetchPolicy: 'network-only' }).subscribe({
      next: (data) => {
        setTokens(data)
      },
    })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    /*const value = Math.round(+event.target.elements.amount_usd.value * 100);
    if (isNaN(value)) {
      return;
    }*/
    const value = 100

    if (!selectedOption?.token) {
      return
    }

    generatePaymentOrder({
      variables: { input: { amountCents: value, tokenId: selectedOption.token } },
      onCompleted: (resp) => {
        console.log({ resp })
        setPaymentOrder(resp)
      },
    })
  }

  const coin = tokens?.tokens.find((x) => x.coinId === selectedOption?.coin)
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '10px 10px',
      backgroundColor: 'white',
    }),
  }

  if (paymentOrder?.generatePaymentOrder) {
    return (
      <div className="container">
        <div className="qr">
          <QRCode size={122} value={paymentOrder.generatePaymentOrder.payAddress} />
        </div>
        {(paymentOrder.generatePaymentOrder.memo?.length || 0) > 0 ? (
          <React.Fragment>
            <span className="memo">
              {t('wallet.memo', 'Memo')}: {paymentOrder.generatePaymentOrder.memo}
            </span>
            <p>{t('wallet.memoWarning', 'NB! Not providing memo may result in loss of your assets')}</p>
          </React.Fragment>
        ) : null}
      </div>
    )
  }

  const filterOption = (candidate, input) => {
    input = input.toLowerCase()
    return undefined !== Object.values(candidate.data).find((x: any) => x.toString().toLowerCase().indexOf(input) >= 0)
  }

  return (
    <form onSubmit={handleSubmit} className="container">
      <div className="content">
        {tokens ? (
          <Select
            maxMenuHeight={200}
            options={tokens?.tokens}
            value={tokens.tokens.find((x) => x.coinId === selectedOption?.coin)}
            placeholder={t('wallet.selectCoin', 'Select coin')}
            onChange={(e) => setSelectedOption({ coin: e?.coinId, token: undefined })}
            styles={customStyles}
            filterOption={filterOption}
            formatOptionLabel={(e) => {
              return (
                <div className="currency-select">
                  <img className="external-deposit-coin-img" alt={e.name} src={e.logo} />
                  <span className="currency-select-span">{e.name}</span>
                </div>
              )
            }}
          />
        ) : null}
        <Select
          maxMenuHeight={200}
          key={JSON.stringify(selectedOption)}
          value={coin?.tokens.find((x) => x.tokenId === selectedOption?.token)}
          options={coin?.tokens}
          placeholder={t('wallet.selectNetwork', 'Select network')}
          onChange={(e) => setSelectedOption({ coin: selectedOption?.coin, token: e?.tokenId })}
          styles={customStyles}
          filterOption={filterOption}
          formatOptionLabel={(e) => {
            return (
              <div className="currency-select">
                <img className="external-deposit-coin-img" alt={e.name} src={e.logo} />
                <span>
                  {e.name}({e.network})
                </span>
              </div>
            )
          }}
        />
        {/* <input id="amount_usd" type='text' placeholder='Amount USD' /> */}
        <button>{t('general.deposit', 'Deposit')}</button>
      </div>
    </form>
  )
}

const generatePaymentOrderMutation = graphql`
  mutation ExternalDepositViewGeneratePaymentOrderMutation($input: GeneratePaymentOrderInput!) {
    generatePaymentOrder(input: $input) {
      payAddress
      memo
    }
  }
`

const generateDepositLinkMutation = graphql`
  mutation ExternalDepositViewGenerateDepositLinkMutation($input: GenerateDepositLinkInput!) {
    generateDepositLink(input: $input) {
      paymentUrl
    }
  }
`

const listTokens = graphql`
  query ExternalDepositViewTokensQuery {
    tokens {
      symbol
      crypto
      name
      logo
      min
      price
      coinId
      status
      tokens {
        tokenId
        crypto
        logo
        name
        network
        chain
        contract
        chainLogo
        status
      }
    }
  }
`

import { graphql } from 'babel-plugin-relay/macro'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchQuery, useMutation } from 'react-relay'
import { store } from '../../store'
import { AppTypes } from '../../reducers/app.reducer'
import { Deposit, formatBalance, Withdraw } from '../../reducers/wallet.reducer'
import formatError from '../errorFormatter'
import Env from '../../RelayEnvironment'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import { Currency } from './__generated__/DepositViewComponentDepositAddressQuery.graphql'
import { HistoryViewComponentDepositsQuery } from './__generated__/HistoryViewComponentDepositsQuery.graphql'
import { HistoryViewComponentWithdrawsQuery } from './__generated__/HistoryViewComponentWithdrawsQuery.graphql'
import { HistoryViewCancelWithdrawMutation } from './__generated__/HistoryViewCancelWithdrawMutation.graphql'
import './HistoryView.scoped.scss'
import WithdrawalRow from './WithdrawalRow'

enum View {
  deposits,
  withdraws,
}

export const CancelWithdrawPopup = ({ uuid, pastWithdraws, setPastWithdraws, setPopup }) => {
  const { t } = useTranslation()

  const showNotification = (status, text) =>
    store.dispatch({ type: AppTypes.SHOW_NOTIFICATION, notification: { status: status, text: text } })

  const [commit] = useMutation<HistoryViewCancelWithdrawMutation>(cancelWithdrawMutation)

  const HandleClick = () => {
    commit({
      variables: { input: { uuid } },

      onCompleted: (data) => {
        setPopup(null)
        setPastWithdraws(pastWithdraws.filter((x) => x.uuid !== uuid))
        showNotification('success', t('wallet.cancelWithdrawPopup.cancelled', 'The transaction is canceled'))
        console.log('data', data)
      },
      onError: (e) => {
        showNotification('error', formatError(e)[0])
      },
    })
  }

  return (
    <div className="CancelWithdrawPopup">
      <div className="title">TXID</div>
      <div className="status">{t('wallet.pending', 'Pending')}</div>
      <button type="button" onClick={() => HandleClick()}>
        {t('wallet.cancelWithdrawPopup.cancel', 'Cancel Transaction')}
      </button>
    </div>
  )
}

export const HistoryView = ({ setPopup }) => {
  const { t } = useTranslation()
  const [view, setView] = useState<View>(View.deposits)
  const [pastDeposits, setPastDeposits] = useState<Deposit[]>([])
  const [pastWithdraws, setPastWithdraws] = useState<Withdraw[]>([])

  const walletStore = store.getState().wallet
  const [activeWallet] = useState(walletStore?.activeWallet)

  const HandleClick = (uuid) => {
    setPopup(
      <CancelWithdrawPopup
        uuid={uuid}
        pastWithdraws={pastWithdraws}
        setPastWithdraws={setPastWithdraws}
        setPopup={setPopup}
      />
    )
  }

  useEffect(() => {
    if (!activeWallet) {
      return
    }

    fetchQuery<HistoryViewComponentDepositsQuery>(Env, getDepositHistoryQuery, {
      filter: { currency: activeWallet.currency as Currency },
      page: 1,
    }).subscribe({
      next: ({ deposits }) => {
        if (deposits?.entries) {
          setPastDeposits([...deposits.entries])
        }
      },
    })

    fetchQuery<HistoryViewComponentWithdrawsQuery>(Env, withdrawHistoryQuery, {
      filter: { currency: activeWallet.currency as Currency },
      page: 1,
    }).subscribe({
      next: ({ withdraws }) => {
        if (withdraws?.entries) {
          setPastWithdraws([...withdraws.entries])
        }
      },
    })
  }, [activeWallet])

  if (!activeWallet) {
    return <span>...</span>
  }

  const mapDate = (date: string) => {
    return date.split(' ').map((x) => {
      return <span key={'split' + x + date}>{x}</span>
    })
  }

  const mapAmount = ({ amount, currency }: { amount: number; currency: Currency }) => {
    return (
      <div className="history-amount">
        <img className="coin-image" src={getLogoForCoin(currency)} alt={currency} />
        {formatBalance({ balance: amount, currency: currency as string }, true, false)}
      </div>
    )
  }

  const mapDeposits = () => {
    return pastDeposits.map((x) => {
      return (
        <div className="tr" key={'dep_' + x.txid + x.amount + x.insertedAt}>
          <div className="td date-column">{mapDate(x.insertedAt)}</div>
          <div className="td">{mapAmount(x)}</div>
          <div className={`td status ${x.isConfirmed ? 'success' : 'pending'}`}>
            {x.isConfirmed ? t('wallet.success', ' Success') : t('wallet.pending', 'Pending')}
          </div>
          <div className="td"></div>
        </div>
      )
    })
  }

  const mapWithdraws = () => {
    return pastWithdraws.map((x, i) => (
      <WithdrawalRow key={i} withdrawal={x} index={i} handleCancelClick={HandleClick} />
    ))
  }

  return (
    <div className="history">
      <div className="type-selector">
        <button
          onClick={() => setView(View.deposits)}
          className={'deposits' + (view === View.deposits ? ' active' : '')}
        >
          {t('wallet.cancelWithdrawPopup.deposits', 'Deposits')}
        </button>
        <button
          onClick={() => setView(View.withdraws)}
          className={'withdraws' + (view === View.withdraws ? ' active' : '')}
        >
          {t('wallet.cancelWithdrawPopup.withdrawals', 'Withdrawals')}
        </button>
      </div>
      <div className="table">
        <div className="tr">
          <div className="th"> {t('wallet.cancelWithdrawPopup.time', 'Time')}</div>
          <div className="th"> {t('wallet.cancelWithdrawPopup.amount', 'Amount')}</div>
          <div className="th"> {t('wallet.cancelWithdrawPopup.status', 'Status')}</div>
          <div className="th"></div>
        </div>
        {view === View.deposits ? mapDeposits() : mapWithdraws()}
      </div>
    </div>
  )
}

export const withdrawHistoryQuery = graphql`
  query HistoryViewComponentWithdrawsQuery($page: Int!, $filter: WithdrawsFilter!) {
    withdraws(page: $page, filter: $filter) {
      entries {
        amount
        currency
        isProcessed
        txid
        isAuthenticated
        address
        insertedAt
        uuid
        meta
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`

const getDepositHistoryQuery = graphql`
  query HistoryViewComponentDepositsQuery($page: Int!, $filter: DepositsFilter!) {
    deposits(page: $page, filter: $filter) {
      entries {
        txid
        amount
        currency
        confirmations
        confirmationsRequired
        isConfirmed
        insertedAt
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`

const cancelWithdrawMutation = graphql`
  mutation HistoryViewCancelWithdrawMutation($input: CancelWithdrawInput!) {
    cancelWithdraw(input: $input) {
      withdraw {
        amount
        currency
        isProcessed
        txid
        isAuthenticated
        address
        insertedAt
        uuid
        meta
      }
    }
  }
`

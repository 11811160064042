export function CheckmarkSVG() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8947 20.472L10.8947 20.472C11.1535 20.4474 11.3882 20.3047 11.5311 20.0863L11.5313 20.0861L20.3582 6.63071L20.3583 6.63058C20.6199 6.23242 20.5092 5.69697 20.1095 5.43459C19.7112 5.17331 19.1741 5.28404 18.9128 5.68315L18.9127 5.68331L10.8439 17.984L10.6741 18.2429L10.4568 18.0223L7.42278 14.9421C7.42277 14.9421 7.42277 14.9421 7.42277 14.9421C7.08795 14.6022 6.5405 14.598 6.20078 14.9332L6.2003 14.9337C5.86033 15.2673 5.85592 15.8155 6.19023 16.1555L10.8947 20.472ZM10.8947 20.472L10.8904 20.4724C10.8643 20.4754 10.8378 20.4767 10.8089 20.4767C10.5783 20.4767 10.3568 20.3845 10.1934 20.2189L6.19035 16.1556L10.8947 20.472Z"
        stroke="#3CCEA2"
        strokeWidth="0.5"
      />
      <path
        d="M23.9927 24.5054H24.2427L24.2427 24.2554L24.2422 2.71222L24.2422 2.46222H23.9922H2.44953H2.19953V2.71222V24.2554V24.5054H2.44953H23.9927ZM0.993867 0.734131H25.4499C25.7326 0.734131 25.9711 0.970679 25.9711 1.2736V25.6948C25.9711 25.998 25.7329 26.2341 25.4499 26.2341H0.993867C0.710248 26.2341 0.471069 25.9973 0.471069 25.6947V1.27341C0.471069 0.97124 0.7103 0.734131 0.993867 0.734131Z"
        stroke="#3CCEA2"
        strokeWidth="0.5"
      />
    </svg>
  )
}

import { useHistory } from 'react-router-dom'
import { Subject } from 'rxjs'
import { GamePage } from './Blackjack'
import { graphql } from 'babel-plugin-relay/macro'
import { Table } from '../tables/TablesComponent'
import { RedBlack } from './rb/RedBlack'
import { rbStartRbGameMutation$data } from './rb/__generated__/rbStartRbGameMutation.graphql'
import { HoldemManager } from './holdem/HoldemManager'
export const openTableTask = new Subject<Table | null>()
export const updateTableListTask = new Subject<void>()
export const tabledJoined = new Subject<Table>()
export const tableUpdated = new Subject<Table>()
export const tablesRemoved = new Subject<Table[]>()
export const tableErrorQueue = new Subject<string>()
export const joinTableTask = new Subject<{ table: string; idx: number }>()
export const rbGameFinished = new Subject<rbStartRbGameMutation$data>()

export const InHouseGame = ({ gameId }: { gameId: string }): JSX.Element => {
  const history = useHistory()

  if (gameId === 'blackjack') {
    return <GamePage history={history} />
  } else if (gameId === 'redorblack') {
    return <RedBlack />
  } else if (gameId === 'holdem') {
    return <HoldemManager fullscreen={true} />
  }
  return <div>{gameId}</div>
}

export const getGameQuery = graphql`
  query InHouseGameGetGameQuery($gameId: String!) {
    gameById(gameId: $gameId) {
      game {
        state
        gameId
        sp
        mp
        splitCount
        insured
        serverSeedHash
        currency
        virtualId
        validMoves {
          canSplit
          canDoubleDown
          canInsurance
          canHit
        }
        summary {
          serverSeed
          serverSeedHash
          clientSeed
          finalShuffle
          finalShuffleHash
          winnings
          details {
            userIdx
            slotId
            winning
          }
        }
        slots {
          slotId
          parentId
          bet
          result
          userUuid
          userName
          userIdx
          cards {
            rank
            suit
            flipped
          }
        }
      }
    }
  }
`

export const newGameMutation = graphql`
  mutation InHouseGameNewGameMutation($input: NewGameInput!) {
    newGame(input: $input) {
      game {
        state
        gameId
        sp
        mp
        splitCount
        insured
        serverSeedHash
        currency
        virtualId
        validMoves {
          canSplit
          canDoubleDown
          canInsurance
          canHit
        }
        summary {
          serverSeed
          serverSeedHash
          clientSeed
          finalShuffle
          finalShuffleHash
          winnings
          details {
            userIdx
            slotId
            winning
          }
        }
        slots {
          slotId
          parentId
          bet
          result
          userUuid
          userName
          userIdx
          cards {
            rank
            suit
            flipped
          }
        }
      }
    }
  }
`

export const startGameMutation = graphql`
  mutation InHouseGameStartGameMutation($input: StartGameInput!) {
    startGame(input: $input) {
      game {
        state
        gameId
        sp
        mp
        splitCount
        insured
        serverSeedHash
        currency
        virtualId
        validMoves {
          canSplit
          canDoubleDown
          canInsurance
          canHit
        }
        summary {
          serverSeed
          serverSeedHash
          clientSeed
          finalShuffle
          finalShuffleHash
          winnings
          details {
            userIdx
            slotId
            winning
          }
        }
        slots {
          slotId
          parentId
          bet
          result
          userUuid
          userName
          userIdx
          cards {
            rank
            suit
            flipped
          }
        }
      }
    }
  }
`

export const setBetMutation = graphql`
  mutation InHouseGameSetBetMutation($input: SetBetInput!) {
    setBet(input: $input) {
      table {
        uuid
        players {
          slotIdx
          playerUuid
          playerName
          playerBalance
          kicked
        }
        maxPlayers
        currency
        type
        virtualId
        state
        speed
        timer
        name
        meta
        gameCount
        game {
          state
          gameId
          sp
          mp
          splitCount
          insured
          serverSeedHash
          currency
          virtualId
          validMoves {
            canSplit
            canDoubleDown
            canInsurance
            canHit
          }
          summary {
            serverSeed
            serverSeedHash
            clientSeed
            finalShuffle
            finalShuffleHash
            winnings
            details {
              userIdx
              slotId
              winning
            }
          }
          slots {
            slotId
            parentId
            bet
            result
            userUuid
            userName
            userIdx
            cards {
              rank
              suit
              flipped
            }
          }
        }
      }
    }
  }
`

export const gameMoveMutation = graphql`
  mutation InHouseGameGameMoveMutation($input: GameMoveInput!) {
    gameMove(input: $input) {
      table {
        uuid
        players {
          slotIdx
          playerUuid
          playerName
          playerBalance
          kicked
        }
        maxPlayers
        currency
        type
        virtualId
        state
        speed
        timer
        name
        meta
        gameCount
        game {
          state
          gameId
          sp
          mp
          splitCount
          insured
          serverSeedHash
          currency
          virtualId
          validMoves {
            canSplit
            canDoubleDown
            canInsurance
            canHit
          }
          summary {
            serverSeed
            serverSeedHash
            clientSeed
            finalShuffle
            finalShuffleHash
            winnings
            details {
              userIdx
              slotId
              winning
            }
          }
          slots {
            slotId
            parentId
            bet
            result
            userUuid
            userName
            userIdx
            cards {
              rank
              suit
              flipped
            }
          }
        }
      }
      game {
        state
        gameId
        sp
        mp
        splitCount
        insured
        serverSeedHash
        currency
        virtualId
        validMoves {
          canSplit
          canDoubleDown
          canInsurance
          canHit
        }
        summary {
          serverSeed
          serverSeedHash
          clientSeed
          finalShuffle
          finalShuffleHash
          winnings
          details {
            userIdx
            slotId
            winning
          }
        }
        slots {
          slotId
          parentId
          bet
          result
          userUuid
          userName
          userIdx
          cards {
            rank
            suit
            flipped
          }
        }
      }
    }
  }
`

export const tableUpdatedSubscription = graphql`
  subscription InHouseGameTableUpdatedSubscription {
    tableUpdated {
      uuid
      players {
        slotIdx
        playerUuid
        playerName
        playerBalance
        kicked
      }
      maxPlayers
      currency
      type
      virtualId
      state
      speed
      timer
      name
      meta
      gameCount
      tableIndex

      game {
        state
        gameId
        sp
        mp
        splitCount
        insured
        serverSeedHash
        currency
        virtualId
        validMoves {
          canSplit
          canDoubleDown
          canInsurance
          canHit
        }
        summary {
          serverSeed
          serverSeedHash
          clientSeed
          finalShuffle
          finalShuffleHash
          winnings
          details {
            userIdx
            slotId
            winning
          }
        }
        slots {
          slotId
          parentId
          bet
          result
          userUuid
          userName
          userIdx
          cards {
            rank
            suit
            flipped
          }
        }
      }
    }
  }
`

export const joinTableMutation = graphql`
  mutation InHouseGameJoinTableMutation($input: JoinTableInput!) {
    joinTable(input: $input) {
      table {
        uuid
        players {
          slotIdx
          playerUuid
          playerName
          playerBalance
          kicked
        }
        maxPlayers
        currency
        type
        virtualId
        state
        speed
        timer
        name
        meta
        gameCount
        game {
          state
          gameId
          sp
          mp
          splitCount
          insured
          serverSeedHash
          currency
          virtualId
          validMoves {
            canSplit
            canDoubleDown
            canInsurance
            canHit
          }
          summary {
            serverSeed
            serverSeedHash
            clientSeed
            finalShuffle
            finalShuffleHash
            winnings
            details {
              userIdx
              slotId
              winning
            }
          }
          slots {
            slotId
            parentId
            bet
            result
            userUuid
            userName
            userIdx
            cards {
              rank
              suit
              flipped
            }
          }
        }
      }
    }
  }
`

export const tableExitedSubscription = graphql`
  subscription InHouseGameTableExitedSubscription {
    tableExited {
      uuid
    }
  }
`

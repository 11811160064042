import React, { useEffect, useRef, useState } from 'react'
import { useIsMobile } from '@src/hooks'
import { Game } from './GameListComponent.types'

import 'swiper/scss'
import 'swiper/scss/grid'
import 'swiper/scss/navigation'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, Navigation, type Swiper as SwiperType } from 'swiper'
import { useForceUpdate } from '../rightSide/walletComponent/WalletComponent'

import ConfigurationService from '@src/service/ConfigurationService'

import './GameListComponent.scss'
import classes from './GameListComponent.module.scss'
import Arrow from '../icons/arrow'
import { GameList } from './GameList'
import { GameFilter } from './NewGameListComponent'

export let overrideProviders: string[] = []

export function FilteredGames({
  games,
  providers,
  filter,
  setFilter,
  category,
  hasMore,
  fetchMore,
}: {
  games: Game[]
  providers: string[]
  filter: GameFilter
  setFilter: (filter: GameFilter) => void
  category?: 'live' | 'slots'
  hasMore: () => boolean
  fetchMore: () => void
}): JSX.Element | null {
  const isMobile = useIsMobile()

  const swiperContainerRef = useRef<HTMLDivElement>(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const [providersWithImages, setProvidersWithImages] = useState<string[]>([])

  useEffect(() => {
    fetch('/assets/providersWithLogos.json')
      .then((response) => response.json())
      .then((json) => {
        setProvidersWithImages(json)
      })
  }, [])

  const forceRender = useForceUpdate()
  const [initialRendering, setInitialRendering] = useState(true)

  useEffect(() => {
    if (initialRendering) {
      setInitialRendering(false)
      forceRender()
    }
  }, [initialRendering, forceRender])

  useEffect(() => {
    const clickHandler = (e: Event) => {
      if (e.target) {
        const dropdowns = document.querySelectorAll('.dropdown-check-list.visible')
        if (dropdowns.length === 0) {
          return
        }
        dropdowns.forEach((x) => {
          if (x.contains(e.target as HTMLDivElement)) {
            return
          }
          x.classList.remove('visible')
        })
      }
    }
    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  })

  const swiperRef = useRef<any | SwiperType>(null)

  if (ConfigurationService.instance.areGamesDisabled()) {
    return null
  }

  const isActive = (provider: string) => {
    if (category === 'live' && provider === 'pragmaticplay') return filter.provider === 'pragmaticplaylive'
    return filter.provider === provider
  }

  return (
    <React.Fragment>
      <div className="games-top">
        <div className="providersFilter">
          <div className="header">
            <div className="pl-24">Providers</div>
            <div className="flex gap-x-10 items-center px-20">
              <div
                ref={navigationPrevRef}
                className="h-26 rounded-full inline-flex items-center justify-center cursor-pointer"
              >
                <Arrow className="text-white transform rotate-90" />
              </div>
              <div className="index">{`${0}/${0}`}</div>
              <div
                ref={navigationNextRef}
                className="h-26 rounded-full inline-flex items-center justify-center cursor-pointer"
              >
                <Arrow className="text-white transform -rotate-90" />
              </div>
            </div>
          </div>
          <div className={classes.providerBody} ref={swiperContainerRef}>
            <Swiper
              modules={[Grid, Navigation]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              spaceBetween={8}
              slidesPerView={isMobile ? 4 : 'auto'}
              slidesPerGroupAuto
              grid={{
                rows: category === 'live' ? 1 : 2,
                fill: 'row',
              }}
              onInit={(swiper) => (swiperRef.current = swiper)}
            >
              {providers.map((provider, i) => (
                <SwiperSlide className="" key={JSON.stringify(provider) + i}>
                  <button
                    type="button"
                    className={`provider ${isActive(provider) ? 'active' : ''}`}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        provider,
                      })
                    }}
                  >
                    {providersWithImages.includes(provider) ? (
                      <div className="flex justify-center items-center w-full h-full md:p-16">
                        <img src={`/assets/providers/${provider}.png`} alt="provider logo" className="object-contain" />
                      </div>
                    ) : (
                      provider
                    )}
                  </button>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="s-cards flex-grow">
        <GameList games={games} category={category} filter={filter} hasMore={hasMore} fetchMore={fetchMore} />
      </div>
    </React.Fragment>
  )
}

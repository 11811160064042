import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useIsMobile } from '@src/hooks'
import type SwiperCore from 'swiper'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  swiperRef?: React.MutableRefObject<SwiperCore | null>
  icon: React.ReactNode
  linkHref?: string
  uniqueId: string
}

const CarouselHeader = ({ text, swiperRef, icon, linkHref, uniqueId }: Props) => {
  const isMobile = useIsMobile('sm')
  const location = useLocation()
  const { t } = useTranslation()
  const currentPath = location.pathname

  return (
    <div className="flex flex-row gap-6 ml-5 mb-10 items-center">
      {icon}
      <div className="text-carouselHeading font-medium flex-grow ml-4">{text}</div>
      {!!linkHref && linkHref !== currentPath && (
        <Link to={linkHref} className="text-eucalyptus text-sm font-medium hover:text-eucalyptusHover mr-5">
          {t('carouselHeading.viewAll', 'View all')}
        </Link>
      )}
      {swiperRef && (
        <>
          <button
            onClick={() => swiperRef.current?.slidePrev()}
            className={`${uniqueId}-swiper-custom-button-prev rounded-3 ${isMobile ? 'hidden' : ''}`}
          >
            <FontAwesomeIcon icon={faChevronLeft} width={20} size="xs" />
          </button>
          <button
            onClick={() => swiperRef.current?.slideNext()}
            className={`${uniqueId}-swiper-custom-button-next rounded-3 ${isMobile ? 'hidden' : ''}`}
          >
            <FontAwesomeIcon icon={faChevronRight} width={20} size="xs" />
          </button>
        </>
      )}
    </div>
  )
}

export default CarouselHeader

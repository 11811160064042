const BuyJackIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 16" fill="none">
    <path d="M3.53986 6.41934H3.09717" stroke="currentColor" strokeWidth="0.6" strokeLinejoin="round" />
    <path d="M2.65411 6.41934H2.21143" stroke="currentColor" strokeWidth="0.6" strokeLinejoin="round" />
    <path
      d="M0 2.80634H3.98419C4.10593 2.80634 4.20553 2.90795 4.20553 3.03214V7.09666C4.20553 7.22085 4.10593 7.32246 3.98419 7.32246H0.885375"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M7.08545 2.80634H9.96292C10.2064 2.80634 10.4056 3.00956 10.4056 3.25795V3.48375C10.4056 3.73214 10.2064 3.93537 9.96292 3.93537"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.5166 3.93536H10.1806C10.4241 3.93536 10.6233 4.13859 10.6233 4.38698V4.61278C10.6233 4.86117 10.4241 5.0644 10.1806 5.0644H9.5166"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.96091 5.06442C10.2044 5.06442 10.4036 5.26765 10.4036 5.51604V5.74184C10.4036 5.99023 10.2044 6.19345 9.96091 6.19345H9.29688"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M4.2085 6.87087H5.31522L7.08597 7.32248H9.74209C9.98557 7.32248 10.1848 7.11926 10.1848 6.87087V6.64506C10.1848 6.39668 9.98557 6.19345 9.74209 6.19345"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M7.30731 1.67728L6.20059 1.90308L4.87253 3.25792H4.2085"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.07642 2.80645H7.74835L7.30566 1H9.5191L9.07642 2.80645Z"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.74209 7.29984C10.4061 8.45145 12.1769 9.80629 12.1769 11.6127C12.1769 13.8573 10.3928 14.9998 8.19269 14.9998C5.99253 14.9998 4.2085 13.8573 4.2085 11.6127C4.2085 9.80629 5.97925 8.45145 6.64328 7.29984"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M9.72879 9.62108V8.91296H7.49424C7.04115 8.91296 6.70133 8.98008 6.48165 9.11097C6.26197 9.24185 6.15213 9.44657 6.15213 9.72847C6.15213 9.91305 6.20019 10.0674 6.2963 10.1983C6.39241 10.3292 6.51941 10.4131 6.6773 10.45V10.4735C6.47135 10.5272 6.32032 10.6178 6.22765 10.742C6.13497 10.8662 6.08691 11.0306 6.08691 11.2286C6.08691 11.5239 6.20019 11.7589 6.43016 11.93C6.65671 12.1012 6.96907 12.1851 7.3638 12.1851H7.75854V12.514C7.75854 12.7287 7.72765 12.8764 7.66243 12.967C7.59721 13.0543 7.4908 13.0979 7.34321 13.0979C7.27799 13.0979 7.21964 13.0912 7.16472 13.0811C7.1098 13.0711 7.05831 13.061 7.01025 13.0509V13.7355C7.15785 13.7657 7.31575 13.7825 7.48051 13.7825C7.87524 13.7825 8.17387 13.6684 8.37296 13.4402C8.57204 13.212 8.67158 12.8764 8.67158 12.4368V10.9568H9.65328V10.2487H8.67158V9.62444H9.73909L9.72879 9.62108ZM7.50453 9.58081H7.75167V10.1681H7.48394C7.3535 10.1681 7.25396 10.1413 7.18188 10.0909C7.1098 10.0406 7.07547 9.96004 7.07547 9.85936C7.07547 9.67478 7.2162 9.58417 7.5011 9.58417L7.50453 9.58081ZM7.01712 11.1514C7.01712 11.0407 7.05488 10.9601 7.13039 10.8997C7.20591 10.8427 7.31575 10.8125 7.45991 10.8125H7.75511V11.5004H7.44275C7.16128 11.5004 7.02055 11.383 7.02055 11.1514H7.01712Z"
      fill="url(#paint0_linear_500_245)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_500_245"
        x1="7.913"
        y1="8.91296"
        x2="7.913"
        y2="13.7825"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34C1CE" />
        <stop offset="1" stopColor="#00D595" />
      </linearGradient>
    </defs>
  </svg>
)

export default BuyJackIcon

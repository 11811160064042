const CherryIcon = ({ color = '#D5DCEB', width = '16', height = '16', ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 14" fill="none">
    <path
      d="M2.84431 14C2.11091 14 1.4212 13.7134 0.90393 13.1962C0.38666 12.6789 0.100098 11.9892 0.100098 11.2558C0.100098 10.5224 0.384231 9.83512 0.90393 9.31542C1.4212 8.79815 2.11091 8.51159 2.84431 8.51159C3.57772 8.51159 4.26741 8.79572 4.78468 9.31542C5.30438 9.83269 5.58851 10.5224 5.58851 11.2558C5.58851 12.7687 4.35727 14 2.84431 14ZM2.84431 9.4077C2.35133 9.4077 1.88748 9.59956 1.53778 9.94927C1.18808 10.299 0.996214 10.7628 0.996214 11.2558C0.996214 11.7488 1.18808 12.215 1.53778 12.5623C1.88748 12.912 2.35133 13.1039 2.84431 13.1039C3.86428 13.1039 4.6924 12.2733 4.6924 11.2558C4.6924 10.7628 4.50055 10.299 4.15085 9.94927C3.80114 9.59956 3.3373 9.4077 2.84431 9.4077Z"
      fill="currentColor"
    />
    <path
      d="M9.4234 14C7.91044 14 6.6792 12.7687 6.6792 11.2558C6.6792 9.74283 7.91044 8.51159 9.4234 8.51159C10.9364 8.51159 12.1676 9.74283 12.1676 11.2558C12.1676 12.7687 10.9364 14 9.4234 14ZM9.4234 9.4077C8.40343 9.4077 7.57532 10.2358 7.57532 11.2558C7.57532 12.2733 8.40586 13.1039 9.4234 13.1039C10.4409 13.1039 11.2715 12.2758 11.2715 11.2558C11.2715 10.2382 10.4409 9.4077 9.4234 9.4077Z"
      fill="currentColor"
    />
    <path
      d="M8.55854 8.42174C8.37154 8.42174 8.19669 8.30274 8.13355 8.11574C7.64785 6.65621 7.66486 5.26469 7.75957 4.38557C5.23879 5.91795 4.35481 8.11089 4.3451 8.13517C4.25524 8.36588 3.99539 8.48002 3.76468 8.39016C3.53398 8.30031 3.41984 8.04289 3.5097 7.81218C3.55584 7.69075 4.6948 4.8227 8.15541 3.15189C8.31327 3.07661 8.50025 3.09848 8.63382 3.21019C8.76982 3.3219 8.8281 3.49917 8.78439 3.66916C8.77953 3.68859 8.27683 5.70911 8.98596 7.83404C9.06367 8.06961 8.93739 8.32218 8.70183 8.39989C8.65568 8.41446 8.60711 8.42174 8.56097 8.42174H8.55854Z"
      fill="currentColor"
    />
    <path
      d="M9.8869 3.44088C9.82619 3.44088 9.76789 3.42875 9.70961 3.40446L8.0971 2.70505C7.87125 2.60548 7.76682 2.34321 7.86396 2.11493C7.96353 1.88908 8.2258 1.78222 8.45408 1.88179L10.0666 2.5812C10.2925 2.68076 10.3969 2.94304 10.2997 3.17132C10.2269 3.34131 10.0617 3.44088 9.88932 3.44088H9.8869Z"
      fill="currentColor"
    />
    <path
      d="M4.19226 4.32C3.89841 4.32 3.60213 4.2933 3.30828 4.23744C1.90704 3.97274 0.690358 3.11305 0.0540911 1.93523C-0.0527628 1.73852 0.00309979 1.49323 0.182809 1.36209C0.221665 1.33295 4.16068 -1.51324 7.65772 1.10954C7.85685 1.25767 7.89572 1.53938 7.74759 1.73609C7.59945 1.93523 7.31774 1.97408 7.12103 1.82595C4.68282 -0.00271342 2.00661 1.28925 1.06435 1.85752C1.61077 2.61278 2.50203 3.17376 3.47828 3.35833C4.69739 3.58903 5.9505 3.2199 7.00447 2.32622C7.19389 2.16593 7.47559 2.19021 7.63587 2.3772C7.79615 2.56663 7.77186 2.84834 7.58487 3.00862C6.56975 3.86831 5.39193 4.32 4.19711 4.32H4.19226Z"
      fill="currentColor"
    />
  </svg>
)

export default CherryIcon

import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay'
import { store } from '../../../store'
import { AppTypes } from '../../../reducers/app.reducer'
import ILogin, { ILoginHeader } from './Login.interface'
import styles from './Login.module.scss'
import SignUp from '../SignUp/SignUp'
import { GraphQLError } from '../../../RelayEnvironment'
import formatError from '../../errorFormatter'
import { LoginLoginMutation } from './__generated__/LoginLoginMutation.graphql'
import ForgotPassword from '../ForgotPassword/ForgotPassword'

enum View {
  login,
  otp,
}

export const LoginHeader: React.FC<ILoginHeader> = ({ activeTab }) => {
  const { t } = useTranslation()
  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  // const [follow] = useMutation<PlayerProfileFollowUserMutation>(followUserMutation);

  return (
    <div className={styles.header}>
      {activeTab === t('authentication.joinUs') || activeTab === t('authentication.logIn') ? (
        <>
          <button
            type="button"
            className={activeTab === t('authentication.joinUs') ? styles.active : ''}
            onClick={() => setModalView(<SignUp />)}
          >
            {t('authentication.joinUs', 'Join Us')}
          </button>
          <button
            type="button"
            className={activeTab === t('authentication.logIn') ? styles.active : ''}
            onClick={() => setModalView(<Login />)}
          >
            {t('authentication.logIn', 'Login')}
          </button>
        </>
      ) : (
        <div className={styles.title}>{activeTab}</div>
      )}
    </div>
  )
}

export const getMessageContainer = (message) => {
  let messageContainer: JSX.Element | undefined
  if (message) {
    messageContainer = <div className={styles.errorMessage}>{message}</div>
  }
  return messageContainer
}

const Login: React.FC<ILogin> = () => {
  const { t } = useTranslation()
  const [view, _setView] = useState(View.login)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOTP] = useState<string>()
  const [message, setMessage] = useState('')

  const [commitLogin] = useMutation<LoginLoginMutation>(loginMutation)

  const setView = (view: View) => {
    setMessage('')
    _setView(view)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    commitLogin({
      variables: {
        input: { username: email, password: password, otp: otp },
      },
      onCompleted: (e) => {
        if (e.login) {
          const token = e.login.token
          const user = e.login.user
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          window.location.href = '/'
          /*
          Would be nice to reset everything
          store.dispatch<AuthAction>({type: UserConstant.LOGIN_SUCCESS, user: user as unknown as User, token});
          history.push('/');
          */
        } else {
          setMessage(t('authentication.login.error', 'Unknown error, please try again.'))
        }
      },
      onError: (e: GraphQLError) => {
        if (e.response?.json?.errors[0]?.message === 'otp_required') {
          setOTP('')
          setView(View.otp)
        } else {
          setMessage(formatError(e)[0])
        }
      },
    })
  }

  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  return (
    <div className={styles.root}>
      <LoginHeader activeTab={t('authentication.logIn')} />
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          {view === View.login && (
            <>
              <div className="">
                <label htmlFor="email-form-control">
                  {t('authentication.email', 'Email')}/{t('authentication.username', 'Username')}
                </label>
                <input
                  id="email-form-control"
                  name="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="">
                <label htmlFor="password-form-control">{t('authentication.password', 'Password')}</label>
                <input
                  id="password-form-control"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {getMessageContainer(message)}

              <button className="" type="submit">
                {t('authentication.logInSpaced', 'Log in')}
              </button>
            </>
          )}
          {view === View.otp && (
            <>
              <label> {t('authentication.login.enter2FACode', 'Enter 2FA verification code')}</label>
              <input required={true} type="number" name="otp" onChange={(e) => setOTP(e.target.value)} value={otp} />

              {getMessageContainer(message)}

              <button className="" type="submit">
                {t('authentication.logInSpaced', 'Log in')}
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault()
                  setView(View.login)
                }}
                type="button"
              >
                {t('general.back', 'Back')}
              </button>
            </>
          )}
        </form>
      </div>
      <div className={styles.footer}>
        <button onClick={() => setModalView(<ForgotPassword />)} className="" type="button">
          {t('authentication.iForgotPassword', 'I forgot my password')}
        </button>
      </div>
    </div>
  )
}

export default Login

const loginMutation = graphql`
  mutation LoginLoginMutation($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        preferences {
          key
          value
          valueJson
        }
      }
    }
  }
`

import React, { useState } from 'react'

import { PedestalStandHeader as Header } from './PedestalStandHeader'
import { PedestalStandContent as Content } from './PedestalStandContent'

import classes from './PedestalStand.module.scss'

export const Options = ['WEEKLY CHAMPIONS', 'DAILY CHAMPIONS'] as const
export type Option = typeof Options[number]

export function PedestalStand({ bright = false }: { bright?: boolean }) {
  const [option, setOption] = useState<Option>('WEEKLY CHAMPIONS')

  return (
    <div className={classes.root}>
      <Header option={option} setOption={setOption} />
      <Content bright={bright} option={option} />
    </div>
  )
}

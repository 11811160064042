import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { UserComponent } from '../TopRow/UserComponent/UserComponent'
import { GlobalSearchComponent } from '../TopRow/GlobalSearch/GlobalSearchComponent'
import { LoginLinkComponent } from '../TopRow/LoginLink/LoginLinkComponent'
import { ChatControl } from '../TopRow/ChatControl/ChatControl'
import NotificationsControl from '../TopRow/NotificationsControl/NotificationsControl'
import { WalletComponent } from '../rightSide/walletComponent/WalletComponent'
import Logo from '../icons/logo'
import styles from './AppTop.module.scss'

import { User } from '@src/reducers/authentication.reducer'
import { Game } from '../gameListComponent/GameListComponent.types'

const AppTop: React.FC<{
  user: User
  searchData: {
    listGames: Game[]
  }
}> = ({ searchData, user }) => {
  const isAnonymousUser = null !== user?.anonymousHash
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const observer = new ResizeObserver(() => {
      const height = element.getBoundingClientRect().height
      document.documentElement.style.setProperty('--header-h', `${height}px`)
    })

    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div ref={ref} id="app-header" className={`${styles.root} lg:flex justify-between pt-10 pb-5 md:py-20`}>
      <div
        className={`${styles.col1} flex justify-between md:justify-end md:gap-10 mb-10 md:mb-16 lg:m-0 lg:order-2 gap-x-4`}
      >
        <div className={`flex items-center md:hidden ${styles.logoWrapper}`}>
          <Link to="/" className="">
            <Logo className="text-white w-54 md:w-auto" />
          </Link>
        </div>
        <div className="hidden md:flex gap-x-10">
          {!isAnonymousUser && <NotificationsControl />}
          <ChatControl />
        </div>
        <div className="md:hidden w-200">{isAnonymousUser ? null : <WalletComponent />}</div>
        <div className="flex justify-end gap-x-12">
          {isAnonymousUser ? null : <UserComponent user={user} />}
          {isAnonymousUser ? <LoginLinkComponent /> : null}
        </div>
      </div>
      <div className={`${styles.col2} grid grid-cols-2 gap-x-8 lg:flex lg:w-full lg:justify-center`}>
        <div className={`${styles.search} hidden md:block lg:flex-grow lg:max-w-xs`}>
          <GlobalSearchComponent />
        </div>
        <div className="hidden md:block">
          <WalletComponent />
        </div>
      </div>
    </div>
  )
}

export default AppTop

import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation } from 'swiper'
import 'swiper/scss'
import 'swiper/scss/navigation'
import FireIcon from '../../icons/fire'
import { useRef } from 'react'
import classes from './Blog.module.scss'
import type SwiperCore from 'swiper'
import CarouselHeader from '../../Elements/CarouselHeader'
import { useTranslation } from 'react-i18next'

const Blog = () => {
  const { t } = useTranslation()
  const swiperRef = useRef<SwiperCore | null>(null)

  const blogPosts = [
    {
      imagePath: 'assets/landing/blog5.png',
      title: t('blog.blog1', 'How to Withdraw Your Winnings from Online Gambling with Cryptocurrency'),
      link: 'https://blog.blackjack.fun/how-to-withdraw-your-winnings-from-online-gambling-with-cryptocurrency/',
    },
    {
      imagePath: 'assets/landing/blog6.png',
      title: t('blog.blog2', 'The Beginners Guide To Crypto Card Games'),
      link: 'https://blog.blackjack.fun/the-beginners-guide-to-crypto-card-games/',
    },
    {
      imagePath: 'assets/landing/blog7.png',
      title: t('blog.blog3', 'Decoding the Benefits: Why Online Casinos Prefer Cryptocurrency Transactions'),
      link: 'https://blog.blackjack.fun/decoding-the-benefits-why-online-casinos-prefer-cryptocurrency-transactions/',
    },
    {
      imagePath: 'assets/landing/blog8.png',
      title: t('blog.blog4', 'Guide To Understanding Against The Spread (ATS) In Sports Betting'),
      link: 'https://blog.blackjack.fun/guide-to-understanding-against-the-spread-ats-in-sports-betting/',
    },
  ]

  const uniqueId = 'blog-carousel'

  return (
    <div className="mb-40 w-full">
      <CarouselHeader
        text={t('blog.header', 'Explore Blackjack.fun blog')}
        swiperRef={swiperRef}
        icon={<FireIcon className={'w-16 h-16 text-carouselHeading'} />}
        uniqueId={uniqueId}
      />

      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        modules={[Mousewheel, Navigation]}
        speed={1000}
        mousewheel={{ forceToAxis: true }}
        spaceBetween={15}
        slidesPerView={'auto'}
        navigation={{
          prevEl: `.${uniqueId}-swiper-custom-button-prev`,
          nextEl: `.${uniqueId}-swiper-custom-button-next`,
        }}
        className={classes.root}
      >
        {blogPosts.map((post, index) => (
          <SwiperSlide key={index} className={classes.slide}>
            <a href={post.link} target="_blank" rel="noreferrer" className="block h-full">
              <div className="bg-landingItemBackground p-10 rounded-7 h-full">
                <div className="h-130 mb-10">
                  <img alt={`blog${index + 1}`} src={post.imagePath} />
                </div>
                <p className="text-footerDarkGray font-medium text-sm">{post.title}</p>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Blog

import { useTranslation } from 'react-i18next'

const Banner2 = () => {
  const { t } = useTranslation()

  return (
    <div
      className="relative flex flex-row justify-between items-center rounded-4 overflow-hidden h-160 md:h-auto bg-cover bg-center"
      style={{
        backgroundImage: 'url("/assets/hero_carousel/palm-trees-bg.png")',
      }}
    >
      <div className="z-10 text-white text-sm max-w-200 md:max-w-none md:text-xl lg:text-2xl font-medium ml-15 md:ml-20 lg:ml-30">
        <p>{t('banners.hiThere', 'Hi there,')}</p>{' '}
        <p className="turquiseBlueText text-xl md:text-4xl lg:text-5xl font-extrabold whitespace-nowrap">
          {t('banners.banner2.extraBonuses', 'EXTRA BONUSES')}
        </p>
        <p>{t('banners.banner2.text', 'Wait for you in our telegram group')}</p>
        <div className="mt-10 md:mt-20">
          <a
            href="https://t.me/Blackjack_fun_Official"
            target="_blank"
            rel="noreferrer"
            className="bg-gradient-pink font-semibold px-10 py-5 md:px-15 md:py-10 lg:px-20 md:mt-10 rounded-4 cursor-pointer"
          >
            {t('banners.banner2.joinTelegram', 'Join our Telegram now')}
          </a>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 md:static md:flex-shrink-0 pt-2 md:pt-5 lg:pt-10 w-1/2 md:w-auto">
        <img
          alt="vice-city-couple"
          src="/assets/hero_carousel/vice-city-couple.png"
          draggable={false}
          className="object-contain h-full w-full md:h-full md:w-auto"
        />
      </div>
    </div>
  )
}

export default Banner2

import { useParams } from 'react-router-dom'
import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'
import { Spinner } from '../spinner'
import { ConfirmEmailComponentConfirmEmailMutation } from './__generated__/ConfirmEmailComponentConfirmEmailMutation.graphql'
import Env from '../../RelayEnvironment'

export const ConfirmEmailComponent = () => {
  const { uuid, secret } = useParams<{ uuid: string; secret: string }>()

  console.log({ uuid, secret })

  commitMutation<ConfirmEmailComponentConfirmEmailMutation>(Env, {
    mutation: confirmEmailMutation,
    variables: { input: { userUuid: uuid, secret } },
    onCompleted: (data) => {
      localStorage.setItem('show_email_verified_popup', '1')
      window.location.href = '/'
    },
    onError: (err) => {
      console.log(err)
      window.location.href = '/'
    },
  })

  return <Spinner />
}

const confirmEmailMutation = graphql`
  mutation ConfirmEmailComponentConfirmEmailMutation($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      user {
        uuid
        confirmed
      }
    }
  }
`

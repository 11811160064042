/**
 * @generated SignedSource<<83b74497dd1803a95d49ebcb4b386d1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type AppComponentBroadcastCreatedSubscription$variables = {
  windowId?: string | null;
};
export type AppComponentBroadcastCreatedSubscription$data = {
  readonly broadcastCreated: any | null;
};
export type AppComponentBroadcastCreatedSubscription = {
  variables: AppComponentBroadcastCreatedSubscription$variables;
  response: AppComponentBroadcastCreatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "windowId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "windowId",
        "variableName": "windowId"
      }
    ],
    "kind": "ScalarField",
    "name": "broadcastCreated",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppComponentBroadcastCreatedSubscription",
    "selections": (v1/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppComponentBroadcastCreatedSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "27a0d8ce638cf9fdc43ef165eeeec249",
    "id": null,
    "metadata": {},
    "name": "AppComponentBroadcastCreatedSubscription",
    "operationKind": "subscription",
    "text": "subscription AppComponentBroadcastCreatedSubscription(\n  $windowId: String\n) {\n  broadcastCreated(windowId: $windowId)\n}\n"
  }
};
})();

(node as any).hash = "5aa1e39f0a65ab4edf1f0d69148208c4";

export default node;

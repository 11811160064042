import * as React from 'react'
import { cw } from '../../..'
import { activeWalletUpdated, getBalance, WalletConstant } from '../../../reducers/wallet.reducer'
import ConfigurationService, { Currency } from '../../../service/ConfigurationService'
import SoundManager, { Sound } from '../../../SoundManager'
import { store } from '../../../store'
import { nextServerSeedHash, seedUsed } from '../../provablyFairComponent/ProvablyFairComponent'
import { generateRandomString } from '../../SignInComponent'
import { newGameMutation, RBGame, startGameMutation } from './rb'
import './rb.scss'
import { UserProp } from '../../../reducers/authentication.reducer'
import { getUserPropOr } from '../../SettingsComponent/SettingsComponent'
import { getChipStackForChips } from '../chips/stack'
import { Subscription } from 'rxjs'
import { startAnimation, stopAnimation } from './wheel'
import { commitMutation } from 'relay-runtime'
import Env from '../../../RelayEnvironment'
import { rbNewRbGameMutation } from './__generated__/rbNewRbGameMutation.graphql'
import { rbStartRbGameMutation } from './__generated__/rbStartRbGameMutation.graphql'
import { rbGameFinished } from '../InHouseGame'
import { ClientSeed } from '../ClientSeed'
import { Money } from '@src/money'

const currencies = Money.listAll()

enum WheelState {
  idle,
  chooseStrength,
  spinning,
}

interface Props {}

interface State {
  game?: RBGame
  choice?: 'red' | 'black'
  errors: string[]
  bet: number
  chips: number[]
  showResults: boolean
  wheelState: WheelState
  currency: string | undefined
}

export class RedBlack extends React.Component<Props, State> {
  public static CHIP_ANIMATION_SPEED = (1000 / 60) * 16

  private animatingBets = 0
  private chipWidth = 0
  private strength = 100
  private stengthSpeed = 1
  private subscriptions: Subscription[] = []

  constructor(props: Props) {
    super(props)
    this.state = {
      bet: 0,
      errors: [],
      chips: [],
      showResults: true,
      choice: this.choose(['red', 'black']),
      wheelState: WheelState.idle,
      currency: undefined,
    }
  }

  choose<T>(choices: T[]) {
    var index = Math.floor(Math.random() * choices.length)
    return choices[index]
  }

  componentDidMount() {
    this.subscriptions.push(
      activeWalletUpdated.subscribe(() => {
        const newWallet = store.getState().wallet.activeWallet
        if (newWallet?.currency !== this.state.currency) {
          this.setState({ currency: newWallet?.currency })
          this.newGame()
        } else {
          this.setState({})
        }
      })
    )
    this.newGame()
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe())
    this.subscriptions = []
  }

  doChipAnimation = (chip: number, callback: () => void) => {
    this.chipWidth = cw / 2
    // Find start and end positions
    const sourceEl = document.querySelector('.rb-bottom .chip.chip' + chip)
    let targetEl = document.querySelector((this.state.choice === 'red' ? '.target-red' : '.target-black') + ' .chip-t')
    if (!sourceEl || !targetEl) {
      return
    }
    const source = sourceEl.getBoundingClientRect()
    const target = targetEl.getBoundingClientRect()
    const clickfn = (e: any) => {
      const xx = e.clientX
      const yy = e.clientY
      const allChips = document.querySelectorAll('.rb-chips .chip')
      for (let i = 0; i < allChips.length; i++) {
        const rect = allChips[i].getBoundingClientRect()
        if (xx >= rect.x && xx <= rect.x + rect.width && yy >= rect.y && yy <= rect.y + rect.height) {
          ;(allChips[i] as HTMLDivElement).click()
          break
        }
      }
    }
    const animatedChip = document.createElement('div')
    animatedChip.classList.add('chip')
    animatedChip.classList.add('chip-animated')
    animatedChip.classList.add('chip' + chip)
    animatedChip.style.transform = 'translateX(' + source.left + 'px) translateY(' + source.top + 'px)'
    animatedChip.addEventListener('mousedown', clickfn)
    animatedChip.addEventListener('touch', clickfn)
    document.body.appendChild(animatedChip)

    this.frame(animatedChip, target, callback)
  }

  frame = (node: HTMLDivElement, target: ClientRect, callback: () => void) => {
    node.animate(
      [
        {
          transform:
            'translateX(' + (target.left - this.chipWidth) + 'px) translateY(' + (target.top - this.chipWidth) + 'px)',
        },
      ],
      RedBlack.CHIP_ANIMATION_SPEED
    ).onfinish = () => {
      callback()
      document.body.removeChild(node)
    }
  }

  startRipples() {
    document.querySelector('.rb-spin')?.classList.add('make-a-ripple')
  }

  stopRipples() {
    document.querySelector('.rb-spin')?.classList.remove('make-a-ripple')
  }

  chipSelect = (chip: number) => {
    if (this.state.wheelState !== WheelState.idle) {
      return
    }
    const c = this.getCurrency()
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const shift =
      (currencies as any)[c.m_unit].units[c.s_unit].shift - (currencies as any)[c.m_unit].units[format].shift
    const m = chip * Math.pow(10, shift)
    const limits = ConfigurationService.instance.getBetLimits(c)
    const balance = getBalance(c.short, this.state.game?.virtualId || 0)
    const haveEnoughBalance = balance >= m + this.animatingBets + this.state.bet
    const dontExceedMaxBetLimit = m + this.animatingBets + this.state.bet <= limits.max
    if (haveEnoughBalance && dontExceedMaxBetLimit) {
      this.animatingBets += m
      SoundManager.playSound(Sound.CHIP_MOVE)
      this.doChipAnimation(chip, () => {
        this.animatingBets -= m
        // First bet
        if (this.state.bet === 0) {
          this.startRipples()
        }
        this.setState({ bet: this.state.bet + m, chips: [...this.state.chips, chip] })
      })
    } else {
      if (!dontExceedMaxBetLimit) {
        this.setState({
          errors: ['Maximum bet reached!'],
        })
        setTimeout(() => {
          this.setState({
            errors: [],
          })
        }, 200000)
      } else {
        this.setState({
          errors: ['Not enough balance!'],
        })
      }
    }
    SoundManager.playSound(Sound.CHIP_SELECT)
  }

  clear = () => {
    if (this.state.wheelState !== WheelState.idle) {
      return
    }
    this.animatingBets = 0
    this.setState({ bet: 0, errors: [], chips: [] })
  }

  spin = (e: any) => {
    e.preventDefault()
    if (
      undefined === this.state.choice ||
      undefined === this.state.game ||
      this.state.bet <= 0 ||
      this.state.wheelState === WheelState.spinning
    ) {
      return
    }
    const wallet = store.getState().wallet.activeWallet
    if (!wallet) {
      return
    }
    this.stopRipples()
    if (this.state.wheelState === WheelState.idle) {
      const c = this.getCurrency()
      const limits = ConfigurationService.instance.getBetLimits(c)
      if (this.state.bet < limits.min) {
        this.setState({ errors: ['Minimum bet is ' + Money.convertUnit(limits.min, c.m_unit, c.s_unit, c.format)] })
        return
      } else if (this.state.bet > limits.max) {
        this.setState({ errors: ['Maximum bet reached ' + Money.convertUnit(limits.max, c.m_unit, c.s_unit, c.format)] })
        return
      }

      this.setState({ wheelState: WheelState.chooseStrength }, () => {
        const currentStrength = document.querySelector('.rb-current-strength') as HTMLDivElement
        const scale = document.querySelector('img.rb-srength-scale') as HTMLImageElement
        const frame = () => {
          if (this.state.wheelState !== WheelState.chooseStrength) {
            return
          }
          this.strength = this.strength + this.stengthSpeed
          if (this.strength > 100) {
            this.strength = 100
            this.stengthSpeed = -1
          } else if (this.strength < 0) {
            this.strength = 0
            this.stengthSpeed = 1
          }

          const degrees = (this.strength * 180) / 100
          currentStrength.innerHTML = '' + this.strength
          scale.style.transform = 'translateX(-50%) rotate(' + (180 + degrees) + 'deg)'
          requestAnimationFrame(frame)
        }
        requestAnimationFrame(frame)
      })
      return
    }

    let seed = localStorage.getItem('next_seed')
    if (null != seed) {
      const autoGenerate = localStorage.getItem('seed_generate')
      if (autoGenerate !== 'false') {
        localStorage.removeItem('next_seed')
        seedUsed.next()
      }
    } else {
      seed = generateRandomString(40)
    }

    const input = {
      currency: wallet.currency,
      virtualId: wallet.virtualId,
      gameId: this.state.game.gameId,
      seed: seed,
      bet: this.state.bet,
      betColor: this.state.choice,
      strength: this.strength,
    }
    // FIXME
    // this.balanceComponent.stopBalanceChange();
    store.dispatch({ type: WalletConstant.SET_DEFER })
    this.setState({ wheelState: WheelState.spinning })
    startAnimation(this.strength)
    commitMutation<rbStartRbGameMutation>(Env, {
      mutation: startGameMutation,
      variables: { input },
      onCompleted: (data) => {
        this.setState({ game: data.startRbGame?.game, errors: [], showResults: false, wheelState: WheelState.spinning })
        const choice = this.state.choice as string
        const notChoice = choice === 'red' ? 'black' : 'red'
        setTimeout(() => {
          console.time('start')
          stopAnimation(this.state.game?.result ? choice : notChoice, () => {
            rbGameFinished.next(data)
            console.timeEnd('start')
            store.dispatch({ type: WalletConstant.STOP_DEFER })
            this.setState({ showResults: true, wheelState: WheelState.idle }, () => {
              this.newGame()
            })
          })
        }, 500)
      },
    })
  }

  showResults = () => {
    this.setState({ showResults: true })
  }

  newGame = () => {
    if (this.state.wheelState !== WheelState.idle) {
      return
    }
    this.animatingBets = 0
    this.setState({ bet: 0, errors: [], chips: [], game: undefined, currency: this.getCurrency().short })
    commitMutation<rbNewRbGameMutation>(Env, {
      mutation: newGameMutation,
      variables: { input: {} },
      onCompleted: (data) => {
        if (data.newRbGame?.game.serverSeedHash) {
          nextServerSeedHash.next(data.newRbGame?.game.serverSeedHash)
        }
        this.setState({ game: data.newRbGame?.game })
      },
    })
  }

  getCurrency(): Currency {
    if (this.state.game && this.state.game.currency) {
      return ConfigurationService.instance.getCurrency(this.state.game.currency)
    }
    const wallet = store.getState().wallet.activeWallet
    return ConfigurationService.instance.getCurrency(wallet?.currency || 'BTC')
  }

  formatCurrentBet() {
    const value = this.state.chips
    let label: JSX.Element | null = null
    if (value.length === 0) {
      return (
        <div className="rb-bet-label">
          <span>0</span>
        </div>
      )
    }
    const sum = this.state.bet
    if (sum > 0) {
      const c = this.getCurrency()
      const m = Money.convertUnit(sum, c.m_unit, c.s_unit, c.format)
      const cFormat = c.short === 'FUN' ? 'FUN' : c.format
      label = (
        <div className="rb-bet-label">
          <span>
            {m}
            {cFormat}
          </span>
        </div>
      )
    }
    return label
  }

  chooseColor = (color: 'red' | 'black') => {
    if (this.state.wheelState !== WheelState.idle) {
      return
    }
    this.setState({ choice: color, chips: [], bet: 0 })
  }

  renderState() {
    const c = this.getCurrency()
    const shift =
      (currencies as any)[c.m_unit].units[c.s_unit].shift - (currencies as any)[c.m_unit].units[c.format].shift
    const chipValues = [5, 10, 25, 50, 500].map((v) => {
      return v * Math.pow(10, shift)
    })

    const chips = chipValues.map((e) => {
      return (
        <div
          key={e}
          className={'chip chip' + e / Math.pow(10, shift)}
          onTouchEnd={() => this.chipSelect(e / Math.pow(10, shift))}
          onMouseUp={() => this.chipSelect(e / Math.pow(10, shift))}
        />
      )
    })

    const errors = this.state.errors.map((x, i) => {
      return (
        <div key={i} className="skewed-btn">
          <span>{x}</span>
        </div>
      )
    })

    let chipStackClass = ''
    const isGameFinished = this.state.game?.state === 'finished'
    if (isGameFinished) {
      if (this.state.showResults) {
        if (this.state.game?.result) {
          chipStackClass = ' down'
          const userChips = document.querySelectorAll('.chip-t .chip')
          for (let i = 0; i < userChips.length; i++) {
            const dealerChip = userChips[i].cloneNode() as HTMLDivElement
            dealerChip.style.position = 'absolute'
            dealerChip.style.top = '0'
            dealerChip.style.left = '50%'
            dealerChip.style.padding = '0'
            dealerChip.style.zIndex = '10000'
            dealerChip.style.transform = 'translateX(-50%)'
            document.body.appendChild(dealerChip)
            dealerChip.animate(
              [
                {
                  top: '100vh',
                },
              ],
              300
            ).onfinish = () => {
              document.body.removeChild(dealerChip)
            }
          }
          SoundManager.playSound(Sound.CHIP_MOVE)
        } else {
          chipStackClass = ' up'
        }
      }
    }

    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const balance = getBalance(c.short, this.state.game && this.state.game.virtualId ? this.state.game.virtualId : 0)
    const m = Money.convertUnit(balance, c.m_unit, c.s_unit, format)
    const cFormat = c.short === 'FUN' ? 'FUN' : format

    return (
      <React.Fragment>
        <div className="rb-head">
          <div className="rb-min-max rb-max-strength">100</div>
          <div className="rb-current-strength">0</div>
          <div className="rb-min-max rb-min-strength">0</div>
          <img className="rb-srength-scale" alt="" src="/assets/strength.svg" />
          <img className="rb-spin-strength" alt="" src="/assets/ss.svg" />
          <div className="rb-wheel-container">
            <img className="rb-wheel" alt="" src="/assets/wheel.png" />
          </div>
          <div className="rb-tick-container">
            <img className="rb-knob" alt="" src="/assets/knob.png" />
          </div>
        </div>
        <div className="rb-top-middle">
          <div className="rb-spin" onMouseUp={this.spin} onTouchEnd={this.spin}>
            <span>SPIN</span>
          </div>
        </div>
        <div className="rb-center">
          <div className="rb-bet-container">
            <div
              onClick={() => this.chooseColor('red')}
              className={'rb-target target-red' + (this.state.choice === 'red' ? ' selected' : '')}
            >
              RED
              {this.state.choice === 'red' ? (
                <div className={'chip-t' + chipStackClass}>{getChipStackForChips(this.state.chips)}</div>
              ) : null}
            </div>
            <img className="rb-lightning" alt="" src="/assets/lightning.svg" />
            <div
              onClick={() => this.chooseColor('black')}
              className={'rb-target target-black' + (this.state.choice === 'black' ? ' selected' : '')}
            >
              BLACK
              {this.state.choice === 'black' ? (
                <div className={'chip-t' + chipStackClass}>{getChipStackForChips(this.state.chips)}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={'rb-bottom'}>
          <div className="rb-errors-row">
            <div className="rb-errors">{errors}</div>
          </div>
          <div className="rb-chip-row">
            <div className="rb-balance">
              <div className="rb-small-head">BALANCE</div>
              <div>
                {m}
                {cFormat}
              </div>
            </div>
            <div className="rb-chips">{chips}</div>
            <div className="rb-bet">
              <div className="rb-small-head">CURRENT BET ON {this.state.choice === 'black' ? 'BLACK' : 'RED'}</div>
              {this.formatCurrentBet()}
            </div>
          </div>
          <div className="rb-rules-row">
            <div className="rb-rules-left">
              <span>HOUSE TAKES 3% BET COMMISION</span>
            </div>
            <div className="rb-rules-middle">
              <img alt="" src="/assets/pf.png" />
            </div>
            <div className="rb-rules-right">
              <div className="rb-rule-100">50%</div>
              <div className="rb-column">
                <span>CHANCE TO WIN</span>
                <span>JUST LIKE IN LIFE</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  toggleMoreGames() {
    const e = document.querySelector('.more-games-container')
    if (!e) {
      return
    }
    if (e.classList.toggle('open')) {
      e.classList.remove('cclose')
    } else {
      e.classList.add('cclose')
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="more-games-container">
          <div className="more-games-head" onClick={this.toggleMoreGames}>
            PROVABLY FAIR
          </div>
          <ClientSeed />
        </div>
        <div className="rb-game-container">{this.renderState()}</div>
      </React.Fragment>
    )
  }
}

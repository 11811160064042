import { useState } from 'react'
import { User } from '../../reducers/authentication.reducer'
import './kyc.scss'
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay'
import { KYCSetupsubmitKycInfoMutation } from './__generated__/KYCSetupsubmitKycInfoMutation.graphql'
import formatError from '../errorFormatter'

enum KYCState {
  info,
  id,
  resident,
  selfie,
  done,
}

const order = [KYCState.info, KYCState.id, KYCState.resident, KYCState.selfie, KYCState.done]

export const KYCSetup = ({ user }: { user: User }) => {
  const [submitInfo] = useMutation<KYCSetupsubmitKycInfoMutation>(submitKYCMutation)
  const [state, setState] = useState(!user || user.kycState === 'waiting_submission' ? KYCState.info : KYCState.done)
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    gender: '',
    dob: '',
    address: '',
    email: '',
    zip: '',
    city: '',
    country: '',
    file1: {
      data: undefined,
      name: 'Upload file',
    },
    file2: {
      data: undefined,
      name: 'Upload file',
    },
    file3: {
      data: undefined,
      name: 'Upload file',
    },
    error: '',
    uploadInProgress: false,
  })

  const nextState = (e) => {
    e.preventDefault()

    // We are done, let's submit all information
    if (state === KYCState.selfie) {
      const infoFields = {
        firstname: form.firstname,
        lastname: form.lastname,
        gender: form.gender,
        dob: form.dob,
        address: form.address,
        email: form.email,
        zip: form.zip,
        city: form.city,
        country: form.country,
      }
      const imageFields = {
        file1: form.file1,
        file2: form.file2,
        file3: form.file3,
      }

      form.uploadInProgress = true
      setForm(Object.assign({}, form))

      setTimeout(() => {
        submitInfo({
          variables: { input: { infoFields: JSON.stringify(infoFields), imageFields: JSON.stringify(imageFields) } },
          onCompleted: (resp) => {
            setState(order[order.indexOf(state) + 1])
          },
          onError: (err) => {
            const e = formatError(err)
            form.error = e[0]
            setForm(Object.assign({}, form))
          },
        })
      }, 1000)
    } else {
      setState(order[order.indexOf(state) + 1])
    }
  }

  const handleselectedFile = (event) => {
    const file = event.target.files[0]
    form[event.target.name].name = file.name
    if (!(file.name.endsWith('.jpg') || file.name.endsWith('.png') || file.name.endsWith('.jpeg'))) {
      event.target.setCustomValidity('Invalid file type')
      return false
    }
    if (file.size > 3 * 1024 * 1024) {
      event.target.setCustomValidity('File too big')
      return false
    } else {
      event.target.setCustomValidity('')
    }
    form[event.target.name].name = file.name
    const reader = new FileReader()
    reader.onloadend = () => {
      form[event.target.name].data = reader.result
      setForm(Object.assign({}, form))
    }
    reader.readAsDataURL(file)
  }

  const handleInputChange = (event) => {
    event.target.classList.add('touched')
    const name = event.target.name
    const value = event.target.valuez.object({})

    setForm(Object.assign(form, { [name]: value }))
  }

  const getState = (state: KYCState) => {
    switch (state) {
      case KYCState.info:
        return (
          <div>
            <div>
              <img src="/assets/oval.svg" alt={''} /> <span>All fields are mandatory</span>
            </div>
            <form onSubmit={nextState}>
              <div className="form-group">
                <div className="form-element">
                  <span>First name</span>
                  <input onChange={handleInputChange} name="firstname" type="text" required />
                </div>
                <div className="form-element">
                  <span>Last name</span>
                  <input onChange={handleInputChange} name="lastname" type="text" required />
                </div>
              </div>
              <div className="form-group">
                <div className="form-element">
                  <span>Gender</span>
                  <select onChange={handleInputChange} name="gender" required>
                    <option>Unspecified</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
                <div className="form-element">
                  <span>Date of Birth</span>
                  <input onChange={handleInputChange} name="dob" type="date" required />
                </div>
              </div>
              <div className="form-group">
                <div className="form-element">
                  <span>Address</span>
                  <input onChange={handleInputChange} name="address" type="text" required />
                </div>
                <div className="form-element">
                  <span>Email</span>
                  <input onChange={handleInputChange} name="email" type="email" required />
                </div>
              </div>
              <div className="form-group">
                <div className="form-element">
                  <span>ZIP/Postal code</span>
                  <input onChange={handleInputChange} name="zip" type="text" required />
                </div>
                <div className="form-element">
                  <span>City</span>
                  <input onChange={handleInputChange} name="city" type="text" required />
                </div>
              </div>
              <div className="form-group">
                <div className="form-element">
                  <span>Country</span>
                  <select onChange={handleInputChange} name="country" required>
                    {countryList.map((x) => {
                      return (
                        <option value={x} key={x}>
                          {x}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="form-element">
                  <input type="submit" value="Next step" />
                </div>
              </div>
            </form>
          </div>
        )
      case KYCState.id:
        return (
          <div key="id">
            <div className="info-row">
              <img src="/assets/oval.svg" alt={''} />{' '}
              <span>
                Upload a valid govermment-issued ID document or Driver’s license. Name and adress should be visible
              </span>
            </div>
            <form id="form-1" className="upload-container" onSubmit={nextState}>
              <label className="upload-target" htmlFor="file1">
                {form.file1.name}
              </label>
              <input
                name="file1"
                onChange={handleselectedFile}
                id="file1"
                type="file"
                accept=".jpg,.png,.jpeg"
                required
              />
              <div className="upload-types">
                <span>Permited formats, JPG, PNG. Maximum file size 3Mb.</span>
              </div>
              <input type="submit" value="Next Step" />
            </form>
          </div>
        )
      case KYCState.resident:
        return (
          <div key="resident">
            <div className="info-row">
              <img src="/assets/oval.svg" alt={''} />{' '}
              <span>
                Upload proof of residence for the last three months, such as utility bill, bank statement,
                government-issued letter and ID (different from the ID provided), etc.
              </span>
            </div>
            <form id="form-2" className="upload-container" onSubmit={nextState}>
              <label className="upload-target" htmlFor="file2">
                {form.file2.name}
              </label>
              <input
                name="file2"
                onChange={handleselectedFile}
                id="file2"
                type="file"
                accept=".jpg,.png,.jpeg"
                required
              />
              <div className="upload-types">
                <span>Permited formats, JPG, PNG. Maximum file size 3Mb.</span>
              </div>
              <input type="submit" value="Next Step" />
            </form>
          </div>
        )
      case KYCState.selfie:
        return (
          <div key="selfie">
            <div className="info-row">
              <img src="/assets/oval.svg" alt={''} />{' '}
              <span>
                Please upload a photo of yourself. In the photo you need to hold the id you uploaded, and a piece of
                paper with "blackjack.fun" written, together with your username and the date on it. Make sure your face
                and the information you hold are clear.
              </span>
            </div>
            <form id="form-3" className="upload-container" onSubmit={nextState}>
              <label className="upload-target" htmlFor="file3">
                {form.file3.name}
              </label>
              <input
                name="file3"
                onChange={handleselectedFile}
                id="file3"
                type="file"
                accept=".jpg,.png,.jpeg"
                required
              />
              <div className="upload-types">
                <span>Permited formats, JPG, PNG. Maximum file size 3Mb.</span>
              </div>
              <input type="submit" className="final-step" value="Send Verification" />
              {form.error.length > 0 ? <h2>{form.error}</h2> : null}
              {form.uploadInProgress ? <h2>Uploading ...</h2> : null}
            </form>
          </div>
        )
      case KYCState.done:
        return (
          <div className="kyc-done">
            <span>
              Your information has been successfully sent. Please wait while one of our admins verifies the data. We
              will reach out as soon as possible.
            </span>
          </div>
        )
    }
  }

  const getStateNumbers = (state: KYCState) => {
    if (state === KYCState.done) {
      return
    }
    const rows = [0, 1, 2, 3].map((x) => {
      if (order.indexOf(state) === x) {
        return (
          <div key={'b' + x} className="pagination active">
            {x + 1}
          </div>
        )
      }
      return <div key={'b' + x} className="pagination" />
    })
    return <div className="pagination-container">{rows}</div>
  }

  return (
    <div className="kyc-container">
      <div>
        <div className="top-header">
          <span>KYC Setup</span>
          {state === KYCState.done ? null : <span className="step">Step {order.indexOf(state) + 1} / 4</span>}
        </div>
        <div className="kyc-content">
          {getState(state)}
          {getStateNumbers(state)}
        </div>
      </div>
    </div>
  )
}

const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
]

const submitKYCMutation = graphql`
  mutation KYCSetupsubmitKycInfoMutation($input: SubmitKycInfoInput!) {
    submitKycInfo(input: $input) {
      user {
        kycState
      }
    }
  }
`

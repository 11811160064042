const TeamIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 14" fill="none">
    <path
      d="M10.4839 3.17242H11.3871C12.1345 3.17242 12.742 3.7568 12.742 4.47587V8.60346"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M5.51588 3.17242H4.61265C3.86523 3.17242 3.25781 3.7568 3.25781 4.47587V8.60346"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path d="M10.0323 13.6H5.96777" stroke="currentColor" strokeWidth="0.7" strokeMiterlimit="10" />
    <path
      d="M9.12916 2.19483C9.12916 2.95517 8.68658 3.6069 8.00013 3.6069C7.31367 3.6069 6.87109 2.95517 6.87109 2.19483C6.87109 1.43448 7.0969 1 8.00013 1C8.90335 1 9.12916 1.43448 9.12916 2.19483Z"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M8.67767 3.38965L10.2583 4.25861V4.6931C10.2583 5.05372 10.0325 5.34482 9.8067 5.34482H6.1938C5.96799 5.34482 5.74219 5.05372 5.74219 4.6931V4.25861L7.32283 3.38965"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M4.38697 10.45C4.38697 11.2103 3.94439 11.8621 3.25794 11.8621C2.57149 11.8621 2.12891 11.2103 2.12891 10.45C2.12891 9.68964 2.35471 9.25516 3.25794 9.25516C4.16116 9.25516 4.38697 9.68964 4.38697 10.45Z"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M3.93548 11.6448L5.51613 12.5138V12.9483C5.51613 13.3089 5.29032 13.6 5.06452 13.6H1.45161C1.22581 13.6 1 13.3089 1 12.9483V12.5138L2.58065 11.6448"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M13.8709 10.45C13.8709 11.2103 13.4283 11.8621 12.7418 11.8621C12.0554 11.8621 11.6128 11.2103 11.6128 10.45C11.6128 9.68964 11.8386 9.25516 12.7418 9.25516C13.6451 9.25516 13.8709 9.68964 13.8709 10.45Z"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M13.4194 11.6448L15 12.5138V12.9483C15 13.3089 14.7742 13.6 14.5484 13.6H10.9355C10.7097 13.6 10.4839 13.3089 10.4839 12.9483V12.5138L12.0645 11.6448"
      stroke="currentColor"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
  </svg>
)

export default TeamIcon

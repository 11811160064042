import { UserProp } from '@src/reducers/authentication.reducer'
import ConfigurationService from '@src/service/ConfigurationService'
import { CompType } from '@src/__generated__/AppComponentlistCompetitionsQuery.graphql'
import { graphql } from 'babel-plugin-relay/macro'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-relay'
import { fetchQuery, requestSubscription } from 'relay-runtime'
import Env from '../RelayEnvironment'
import { getUserPropOr } from './SettingsComponent/SettingsComponent'
import { Currency } from './wallet/__generated__/WithdrawViewComponentEstimateFeeQuery.graphql'

import './NotificationsOld.scoped.scss'
import {
  NotificationsOldListNotificationsQuery,
  NotificationType,
} from './__generated__/NotificationsOldListNotificationsQuery.graphql'
import { NotificationsOldMarkNotificationsAsReadMutation } from './__generated__/NotificationsOldMarkNotificationsAsReadMutation.graphql'
import { NotificationsOldNotificationAddedSubscription } from './__generated__/NotificationsOldNotificationAddedSubscription.graphql'
import { NotificationsOldNotificationUpdatedSubscription } from './__generated__/NotificationsOldNotificationUpdatedSubscription.graphql'
import { Money } from '@src/money'

type FreespinReason =
  | 'regSpinLink'
  | 'regSpinBCode'
  | 'competition'
  | 'vote'
  | 'cfreespin'
  | 'manual'
  | 'fcampaign'
  | 'initial'

interface FreespinAdded {
  issue_id: string
  reason: FreespinReason
  games: {
    games: string[]
  }
  amount: number
}

interface CompetitionWinBonus {
  competition: true
  name: string
  type: CompType
  auto: boolean
  round_id: number
  competition_id: number
  currency: Currency
  amount: number
}

interface RainBonus {
  source: 'rain'
  type: 'chat_bot' | 'bet_bot'
  currency: Currency
  amount: number
}

interface CompetitionFreespinBonus {
  source: 'freespin'
  issue_id: string
}

interface ManualBonus {
  manual_request: true
}

interface ManualRainBonus {
  source: 'manual_rain'
  sub_type: 'tip_followers' | 'regular'
}

type BonusMeta = CompetitionWinBonus | RainBonus | CompetitionFreespinBonus | ManualBonus | ManualRainBonus

interface NewBonusAdded {
  currency: Currency
  amount: number
  payout_multiplyer: number // typo but hard to fix
  type: 'global_playthrough'
  meta: BonusMeta
}

interface UserLogin {
  ip: string
}

interface ManualCredit {
  amount: number
  currency: Currency
}

interface ManualCashback {
  amount: number
  currency: Currency
}

interface BankrollPayout {
  amount: number
  currency: Currency
}

type DirectRain = RainBonus

interface Rakeback {
  amount: number
  currency: Currency
  percent: number
}

type DirectCompetitionCredit = CompetitionWinBonus

interface DepositBonus {
  currency: Currency
  deposit_amount: number
  bonus_amount: number
}

interface CompetitionVotePayout {
  competition_id: number
  name: string
  round_id: number
  type: CompType
}

type NotificationContent =
  | FreespinAdded
  | NewBonusAdded
  | UserLogin
  | ManualCredit
  | ManualCashback
  | DirectRain
  | BankrollPayout
  | Rakeback
  | DirectCompetitionCredit
  | DepositBonus
  | CompetitionVotePayout

interface Notification {
  readonly notificationId: number
  readonly type: NotificationType
  readonly meta: NotificationContent
  readonly readTime: string | null
  readonly insertedAt: string
}

const addOrUpdate = (list: Notification[], element: Notification): Notification[] => {
  const idx = list.findIndex((x) => x.notificationId === element.notificationId)
  if (idx >= 0) {
    return list.map((x, i) => {
      if (i === idx) {
        return element
      }
      return x
    })
  } else {
    return [...list, element]
  }
}

const sortNotifications = (list: Notification[]): Notification[] => {
  return [...list].sort((a, b) => b.notificationId - a.notificationId)
}

export const NotificationTestPage = () => {
  const [notifications, setNotifications] = useState<Notification[]>()
  const [markAsRead] = useMutation<NotificationsOldMarkNotificationsAsReadMutation>(markNotificationsAsRead)
  const [notificationState, setNotificationState] = useState<'read' | 'unread' | 'all'>('unread')

  useEffect(() => {
    const subsAdded = requestSubscription<NotificationsOldNotificationAddedSubscription>(Env, {
      subscription: notificationAddedSubscription,
      variables: {},
      onNext: (data) => {
        if (data?.notificationAdded) {
          setNotifications((current) => {
            if (!current) {
              return
            }
            let newArr = addOrUpdate(current, data.notificationAdded as Notification)
            newArr = sortNotifications(newArr)
            return newArr
          })
        }
      },
    })

    const subsUpdated = requestSubscription<NotificationsOldNotificationUpdatedSubscription>(Env, {
      subscription: notificationUpdatedSubscription,
      variables: {},
      onNext: (data) => {
        if (data?.notificationUpdated) {
          setNotifications((current) => {
            if (!current) {
              return
            }
            let newArr = addOrUpdate(current, data.notificationUpdated as Notification)
            newArr = sortNotifications(newArr)
            return newArr
          })
        }
      },
    })

    return () => {
      subsAdded.dispose()
      subsUpdated.dispose()
    }
  }, [])

  useEffect(() => {
    fetchQuery<NotificationsOldListNotificationsQuery>(
      Env,
      listNotifications,
      {
        page: 1,
        pageSize: 40,
        filter: {
          type: notificationState,
        },
      },
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: ({ listNotifications }) => {
        setNotifications(sortNotifications(sortNotifications(listNotifications.entries as Notification[])))
      },
    })
  }, [notificationState])

  const formatFreespinAdded = (body: FreespinAdded) => {
    return (
      <span>
        {body.amount} freespins are now available in {body.games.games.join(', ')}
      </span>
    )
  }

  const formatNewBonusAdded = (body: NewBonusAdded) => {
    const c = ConfigurationService.instance.getCurrency(body.currency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const amount = Money.convertUnit(body.amount, c.m_unit, c.s_unit, format)

    return (
      <span>
        New bonus available {amount}
        {format}, play it through {body.payout_multiplyer} times to unlock coins
      </span>
    )
  }

  const formatUserLogin = (body: UserLogin) => {
    return <span>Successfully logged in from ip {body.ip}</span>
  }

  const formatNotification = (notification: Notification) => {
    if (notification.type === 'FREESPIN_ADDED') {
      return formatFreespinAdded(notification.meta as FreespinAdded)
    }
    if (notification.type === 'NEW_BONUS_ADDED') {
      return formatNewBonusAdded(notification.meta as NewBonusAdded)
    }
    if (notification.type === 'USER_LOGIN') {
      return formatUserLogin(notification.meta as UserLogin)
    }
    // TODO do all others
  }

  const read = (notifications: Notification[]) => {
    // Passing empty array will mark all user notifications as read. In that case notificationUpdated event
    // is not fired and frontend must mark notification as read or refetch notifications
    markAsRead({
      variables: { input: { ids: notifications.map((x) => x.notificationId) } },
      onCompleted: (response, _errors) => {
        console.log('done, count: ', response.markNotificationsAsRead?.count)
      },
    })
  }
  if (!notifications) {
    return <span>...</span>
  }
  return (
    <div>
      <div className="notifications-header">
        <button type="button" onClick={() => read([])}>
          Mark all as read
        </button>
        <button onClick={() => setNotificationState('all')}>All notifications</button>
        <button onClick={() => setNotificationState('read')}>Read notifications</button>
        <button onClick={() => setNotificationState('unread')}>Unread notifications</button>
      </div>
      <table className="notifications-table">
        <thead>
          <tr>
            <th className="notif-table-head">ID</th>
            <th className="notif-table-head">type</th>
            <th className="notif-table-head">Raw meta</th>
            <th className="notif-table-head">Formatted meta</th>
            <th className="notif-table-head">Read time?</th>
            <th className="notif-table-head">Inserted at</th>
            <th className="notif-table-head">Actions</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((x) => {
            return (
              <tr key={'notification_' + x.notificationId}>
                <td className="notif-table-data">{x.notificationId}</td>
                <td className="notif-table-data">{x.type}</td>
                <td className="notif-table-data">
                  <pre>{JSON.stringify(x.meta, null, 4)}</pre>
                </td>
                <td className="notif-table-data">{formatNotification(x)}</td>
                <td className="notif-table-data">{x.readTime}</td>
                <td className="notif-table-data">{x.insertedAt}</td>
                <td className="notif-table-data">
                  {!x.readTime ? <button onClick={() => read([x])}>Mark as read</button> : null}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const listNotifications = graphql`
  query NotificationsOldListNotificationsQuery($filter: ListNotificationsFilter!, $page: Int!, $pageSize: Int) {
    listNotifications(filter: $filter, page: $page, pageSize: $pageSize) {
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        notificationId: id
        type
        readTime
        meta
        insertedAt
      }
    }
  }
`

const notificationAddedSubscription = graphql`
  subscription NotificationsOldNotificationAddedSubscription {
    notificationAdded {
      notificationId: id
      type
      readTime
      meta
      insertedAt
    }
  }
`

const notificationUpdatedSubscription = graphql`
  subscription NotificationsOldNotificationUpdatedSubscription {
    notificationUpdated {
      notificationId: id
      type
      readTime
      meta
      insertedAt
    }
  }
`

const markNotificationsAsRead = graphql`
  mutation NotificationsOldMarkNotificationsAsReadMutation($input: MarkNotificationsAsReadInput!) {
    markNotificationsAsRead(input: $input) {
      count
    }
  }
`
import { useFormikContext } from 'formik'
import classes from './ProfileKYC.module.scss'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'
import { schema } from './ProfileKYC'
import { toast } from 'react-toastify'

export function Step3() {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<z.infer<typeof schema>>()

  return (
    <div>
      <h5 className={classes.notice}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 0C13.9706 -2.16029e-07 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 2.18519e-07 13.9706 0 9C-2.18519e-07 4.02944 4.02944 2.16029e-07 9 0Z"
            fill="#8F4BD0"
          />
          <path
            d="M8 13V7.41455H10V13H8ZM9 6.83273C8.72947 6.83273 8.49758 6.74303 8.30435 6.56364C8.11111 6.38424 8.01449 6.16848 8.01449 5.91636C8.01449 5.66424 8.11111 5.44848 8.30435 5.26909C8.49758 5.0897 8.72947 5 9 5C9.27295 5 9.50483 5.0897 9.69565 5.26909C9.88889 5.44848 9.98551 5.66424 9.98551 5.91636C9.98551 6.16848 9.88889 6.38424 9.69565 6.56364C9.50483 6.74303 9.27295 6.83273 9 6.83273Z"
            fill="white"
          />
        </svg>
        {t(
          'profile.KYC.step3.notice',
          'Upload proof of residence for the last month (utility bill, bank statement, etc).'
        )}
      </h5>
      <div className={classes.uploadContainer}>
        <label className={classes.uploadTarget} htmlFor="file2">
          {values.file2 ? values.file2.name : t('profile.KYC.step3.uploadPlaceholder', 'tap to upload file')}
        </label>
        <input
          name="file2"
          id="file2"
          type="file"
          accept=".jpg,.png,.jpeg"
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0]
            if (!file) return

            const maxSizeInBytes = 3 * 1024 * 1024 // 3MB
            if (file.size > maxSizeInBytes) {
              toast.error(t('profile.KYC.step3.fileSizeError', 'File size is too big. Maximum file size is 3Mb.'))
              setFieldValue('file2', null)
              return
            }

            const reader = new FileReader()
            reader.onloadend = () => {
              setFieldValue('file2', {
                data: reader.result,
                name: file.name,
              })
            }
            reader.readAsDataURL(file)
          }}
        />
        <div className={classes.uploadTypes}>
          <span>{t('profile.KYC.step3.uploadFormats', 'Permitted formats: JPG, PNG. Maximum file size 3Mb.')}</span>
        </div>
      </div>
    </div>
  )
}

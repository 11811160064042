import { Link, useHistory } from 'react-router-dom'
import { getGameBackgroundURL, openGame } from '../helper'

import './GameListComponent.scss'
import { Game } from './GameListComponent.types'

export function GameComponent({ game }: { game: Game }) {
  const history = useHistory()

  return (
    <div
      onClick={() => {
        openGame(history, game)
      }}
      className="s-card"
    >
      <img src={game.urlThumb || getGameBackgroundURL(game)} alt="game" />
      {game.gameId === 'blackjack' ? (
        <Link to="/game/blackjack">
          <span className="landing-play-button">PLAY NOW</span>
        </Link>
      ) : null}
      {game.gameId === 'redorblack' ? (
        <Link to="/game/redorblack">
          <span className="landing-play-button">SPIN NOW</span>
        </Link>
      ) : null}

      <div className="h-full w-full flex flex-col justify-center">
        <div className="providerTitle">{game.provider}</div>
        <div className="gameTitle">{game.label}</div>
      </div>
    </div>
  )
}

import { countries } from '@src/configs/countries'
import { Input } from '../Form/Input'
import { Label } from '../Form/Label'
import classes from './ProfileKYC.module.scss'
import { useFormikContext } from 'formik'
import { z } from 'zod'
import { schema } from './ProfileKYC'
import { useTranslation } from 'react-i18next'

export function Step1() {
  const { t } = useTranslation()
  const { errors, values } = useFormikContext<z.infer<typeof schema>>()

  return (
    <div>
      <div className="flex flex-col">
        <h5 className={classes.notice}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 0C13.9706 -2.16029e-07 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 2.18519e-07 13.9706 0 9C-2.18519e-07 4.02944 4.02944 2.16029e-07 9 0Z"
              fill="#8F4BD0"
            />
            <path
              d="M8 13V7.41455H10V13H8ZM9 6.83273C8.72947 6.83273 8.49758 6.74303 8.30435 6.56364C8.11111 6.38424 8.01449 6.16848 8.01449 5.91636C8.01449 5.66424 8.11111 5.44848 8.30435 5.26909C8.49758 5.0897 8.72947 5 9 5C9.27295 5 9.50483 5.0897 9.69565 5.26909C9.88889 5.44848 9.98551 5.66424 9.98551 5.91636C9.98551 6.16848 9.88889 6.38424 9.69565 6.56364C9.50483 6.74303 9.27295 6.83273 9 6.83273Z"
              fill="white"
            />
          </svg>
          {t('profile.KYC.step1.allFieldsMandatory', 'All fields are mandatory')}
        </h5>
        <div className={classes.content}>
          <div className="flex flex-col">
            <Label htmlFor="firstname">{t('profile.KYC.step1.firstName', 'First Name')}</Label>
            <Input name="firstname" required wrong={Boolean(errors.firstname)} />
          </div>
          <div className="flex flex-col">
            <Label htmlFor="lastname">{t('profile.KYC.step1.lastName', 'Last Name')}</Label>
            <Input name="lastname" required wrong={Boolean(errors.lastname)} />
          </div>
          <div className="flex flex-col">
            <Label htmlFor="dob">{t('profile.KYC.step1.dob', 'Date of Birth')}</Label>
            <Input name="dob" type="date" required wrong={Boolean(errors.dob)} />
          </div>
          <div
            className="flex flex-col flex-shrink-0 flex-grow"
            style={{
              minWidth: '320px',
            }}
          >
            <Label htmlFor="address">{t('profile.KYC.step1.address', 'Address')}</Label>
            <Input name="address" className="w-full" required wrong={Boolean(errors.address)} />
          </div>
          <div className="flex flex-col">
            <Label htmlFor="zip">{t('profile.KYC.step1.zip', 'Zip/Postal Code')}</Label>
            <Input name="zip" required wrong={Boolean(errors.zip)} />
          </div>
          <div className="flex flex-col">
            <Label htmlFor="city">{t('profile.KYC.step1.city', 'City')}</Label>
            <Input name="city" required wrong={Boolean(errors.city)} />
          </div>
          <div className="flex flex-col">
            <Label htmlFor="country">{t('profile.KYC.step1.country', 'Country')}</Label>
            <Input name="country" as="select" required value={values.country} wrong={Boolean(errors.country)}>
              {countries.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Input>
          </div>
        </div>
      </div>
    </div>
  )
}

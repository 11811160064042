import { Competition } from '@src/App'
import { useEffect, useState } from 'react'
import { FormattedWin, getFormattedWin } from './functions/getFormattedWin'
import { useCompetitions } from '@src/context/CompetitionsContext'

export const filters = ['Wins', 'Wagers', 'Bets'] as const
const valueDescriptions = ['Winning amount', 'Wager amount', 'Bets'] as const

export function useLeaderBoards() {
  const [data, setData] = useState<Array<FormattedWin>>()
  const [competition, setCompetition] = useState<Competition>()
  const [selected, setSelected] = useState<typeof filters[number]>(filters[0])
  const [valueDescription, setValueDescription] = useState<typeof valueDescriptions[number]>(valueDescriptions[0])

  const { competitions } = useCompetitions()


  useEffect(() => {
    switch (selected) {
      case 'Wins':
        setCompetition(competitions.find((c) => c.name === 'BIGGEST WIN COMPETITION'))
        setValueDescription('Winning amount')
        break
      case 'Wagers':
        setCompetition(competitions.find((c) => c.name === 'Weekly WAGERING COMPETITION'))
        setValueDescription('Wager amount')
        break
      case 'Bets':
        setCompetition(competitions.find((c) => c.name === 'Daily Bet Count Competition'))
        setValueDescription('Bets')
        break
      default:
        break
    }
  }, [competitions, selected])

  useEffect(() => {
    if (!competition) return

    const formattedWin = getFormattedWin(competition) || []
    setData(formattedWin.slice(0, 3))
  }, [competition])

  return { data, filters, valueDescription, selected, setSelected }
}

import React from 'react'
import { Link, useLocation } from 'react-router-dom'

interface SubMenuItemProps {
  Icon: React.ElementType
  label: string
  iconColor?: string
  linkPath: string
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({ Icon, label, iconColor = '#828282', linkPath }) => {
  const { pathname } = useLocation()
  const isActive = linkPath && pathname === linkPath

  return (
    <Link to={linkPath} className="flex items-center justify-between w-full px-20 py-5">
      <div
        className={`flex items-center space-x-15 cursor-pointer ${
          isActive ? 'text-topaz' : 'text-menuLink hover:text-topaz'
        }`}
      >
        <Icon color={iconColor} className="w-16 h-16" />
        <span className="text-xs leading-5 font-semibold">{label}</span>
      </div>
    </Link>
  )
}

export default SubMenuItem

import React from 'react'

import classes from './Tier3.module.scss'

export function Tier3() {
  return (
    <div className={classes.root}>
      <div className={classes.stats}>
        <h2 className="font-poppins font-medium h-min">2</h2>
        <div className="flex flex-col transform translate-y-20 -translate-x-40">
          <h3 className="text-sm leading-5 font-bold text-white font-inter">
            TIER 3<br />
            <span className="text-pink">Elite Seat 2</span>
          </h3>
          <p className="text-white">
            VIP
            <br />
            <span className="text-eucalyptus">Wager</span> <span className="text-yellow">10 - 100 BTC</span>
            <br />
            <span className="text-lg font-bold">
              10% rakeback
              <br />
              5% cashback
            </span>
          </p>
          <p className="text-eucalyptus font-medium">
            Mystery Gold box
            <br />
            Executive Support
            <br />
            Contribute directly
            <br />
            and have custom services
            <br />
            to fit your needs
          </p>
        </div>
      </div>
      <div className={classes.imageWrapper}>
        <div className={classes.cloud}>
          <img alt="" src="/assets/pages/elite-seat/tier3-cloud.png" />
        </div>
        <div className={classes.badge1}>
          <img alt="" src="/assets/pages/elite-seat/tier3-knight-badge.png" />
        </div>
        <div className={classes.badge2}>
          <img alt="" src="/assets/pages/elite-seat/tier3-reptile-badge.png" />
        </div>
        <img alt="" src="/assets/pages/elite-seat/tier3.png" className={classes.image} />
      </div>
      <div className={classes.container}>
        <div className="relative w-full flex flex-col">
          <div className="relative flex justify-end">
            <div className={classes.rectangle} />
            <div className="relative">
              <div className={classes.plus}>
                <span>+</span>
              </div>
              <img alt="" src="/assets/pages/elite-seat/tier5-crate.png" />
            </div>
          </div>
        </div>

        <p className="font-poppins font-medium text-sm leading-5 text-white">
          Things are getting bigger on your end so we should really even them out. MORE rakeback and more CASHBACK.
          MISTERY GOLD BOX, which we hope the rewards level speaks by itself. At this point of our journey you might ask
          for personal preferences and chat directly with the team. You can get involve in shaping the platform.
        </p>
      </div>
    </div>
  )
}

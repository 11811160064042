/**
 * @generated SignedSource<<2eb38605b9d5683baa06e5764a90554b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type TipUserInput = {
  uuid: string;
  currency: Currency;
  amount: number;
};
export type TipUserComponentTipUserMutation$variables = {
  input: TipUserInput;
};
export type TipUserComponentTipUserMutation$data = {
  readonly tipUser: {
    readonly result: boolean;
  } | null;
};
export type TipUserComponentTipUserMutation = {
  variables: TipUserComponentTipUserMutation$variables;
  response: TipUserComponentTipUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TipUserPayload",
    "kind": "LinkedField",
    "name": "tipUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TipUserComponentTipUserMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TipUserComponentTipUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "57f731014accd0262b411a805b3dcc75",
    "id": null,
    "metadata": {},
    "name": "TipUserComponentTipUserMutation",
    "operationKind": "mutation",
    "text": "mutation TipUserComponentTipUserMutation(\n  $input: TipUserInput!\n) {\n  tipUser(input: $input) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c22fe44010cd36ae019dffe8c190484";

export default node;

import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'

export default function SkeletonLoader() {
  const [scale, setScale] = useState(1)

  const itemWidth = 143
  const itemHeight = 198
  const gap = 14
  const totalWidth = itemWidth * 9 + gap * 8
  const adjustedHeight = itemHeight * scale

  const wrapperStyle = {
    overflow: 'hidden',
    width: '100%',
    display: 'block',
    maxWidth: '100%',
    maxHeight: `${adjustedHeight}px`,
  }

  const containerStyle = {
    transformOrigin: '0 0',
    transform: `scale(${scale})`,
    display: 'flex',
    gap: `${gap}px`,
    width: `${totalWidth}px`,
    minHeight: `${itemHeight}px`,
  }

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth
      const scaleFactor = screenWidth < 500 ? screenWidth / 512 : 1
      setScale(scaleFactor)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div style={wrapperStyle}>
      <div style={containerStyle}>
        <ContentLoader
          speed={1.5}
          width={totalWidth}
          height={itemHeight}
          viewBox={`0 0 ${totalWidth} ${itemHeight}`}
          backgroundColor="#17181C"
          foregroundColor="#323232"
        >
          {Array.from({ length: 9 }).map((_, index) => (
            <rect
              key={index}
              x={index * (itemWidth + gap)}
              y="0"
              rx="10"
              ry="10"
              width={itemWidth}
              height={itemHeight}
            />
          ))}
        </ContentLoader>
      </div>
    </div>
  )
}

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Mousewheel } from 'swiper'
import type SwiperCore from 'swiper'
import { useRef } from 'react'
import Banner1 from './components/Banner1'
import Banner2 from './components/Banner2'

import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/scss/autoplay'

const LandingCarousel = () => {
  const swiperRef = useRef<SwiperCore | null>(null)

  const handleSlideChange = (core: SwiperCore) => {
    swiperRef.current = core
  }

  return (
    <Swiper
      autoplay={{ delay: 15000, disableOnInteraction: false }}
      speed={1000}
      mousewheel={{ forceToAxis: true }}
      modules={[Autoplay, Pagination, Mousewheel]}
      spaceBetween={64}
      onInit={(core: SwiperCore) => {
        swiperRef.current = core
      }}
      onSlideChange={handleSlideChange}
    >
      <>
        <SwiperSlide>
          <Banner1 />
        </SwiperSlide>
        <SwiperSlide>
          <Banner2 />
        </SwiperSlide>
      </>
    </Swiper>
  )
}

export default LandingCarousel

import * as React from 'react'
import { GameCard } from '../Blackjack'
import { getCardBack, getCardHeight, getCardWidth, getUrlForCard } from '../components/Card'

interface CardProps {
  card: GameCard
  sourcePos?: { x: number; y: number }
  index?: number
  scale?: number
  flipAfter?: number
}

interface State {
  url?: string
}

export default class Card extends React.Component<CardProps, State> {
  style = { width: getCardWidth(), height: getCardHeight(), transform: undefined }

  constructor(props: CardProps) {
    super(props)
    this.state = { url: undefined }
  }

  componentDidMount() {
    this.setURL()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.card !== this.props.card) {
      this.setURL()
    }
  }

  setURL() {
    // Not a card but a placeholder
    if (this.props.card.rank === '' && this.props.card.suit === '') {
      this.setState({ url: '/assets/h-slot.png' })
    } else {
      this.setState({ url: this.props.card.flipped ? getCardBack() : getUrlForCard(this.props.card) })
    }
  }

  flip(ref: HTMLDivElement) {
    setTimeout(() => {
      ref?.classList?.add('flip')
    }, this.props.flipAfter)
  }

  render() {
    if (undefined === this.state.url) {
      return null
    }
    // Kind of mess, we support card placeholders, cards, flipping cards
    const slot = this.props.card.rank === '' && this.props.card.suit === ''
    const s = this.props.scale ? this.props.scale : 1
    let containerStyle = { height: this.style.height * s, width: this.style.width * s, transform: '' }
    if (undefined !== this.props.sourcePos && undefined === this.props.flipAfter) {
      containerStyle.transform = `translate(${this.props.sourcePos.x}px, ${this.props.sourcePos.y}px)`
    }
    if ((this.props?.flipAfter || 0) > 0) {
      const innerStyle = { height: this.style.height * s, width: this.style.width * s }
      return (
        <div ref={(ref) => this.flip(ref as HTMLDivElement)} className="flip-card" style={containerStyle}>
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div
                data-table-card={this.props.index}
                style={containerStyle}
                className={slot ? 'h-empty' : 'h-card-container'}
              >
                <div style={innerStyle} className="h-card">
                  <img alt="" src="/assets/h-slot.png" />
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div
                data-table-card={this.props.index}
                style={containerStyle}
                className={slot ? 'h-empty' : 'h-card-container'}
              >
                <div style={innerStyle} className="h-card">
                  <img alt="" src={this.state.url} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div data-table-card={this.props.index} style={containerStyle} className={slot ? 'h-empty' : 'h-card-container'}>
        <div style={{ height: this.style.height * s, width: this.style.width * s }} className="h-card">
          <img alt="" src={this.state.url} />
        </div>
      </div>
    )
  }

  private getSuit(s: string): string {
    switch (s) {
      case 'S':
        return 'spades'
      case 'C':
        return 'clubs'
      case 'H':
        return 'hearts'
      case 'D':
        return 'diamonds'
      default:
        console.error('Unknown suit: ', s)
        return s
    }
  }

  private getRank(r: string): string {
    switch (r) {
      case 'A':
        return 'A'
      case 'K':
        return 'K'
      case 'Q':
        return 'Q'
      case 'J':
        return 'J'
      default:
        return r
    }
  }
}

/**
 * @generated SignedSource<<3eb263fa44cf6475fbbccb5f20a1ab79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HoldemManagerListAllGamesQuery$variables = {
  currency?: string | null;
};
export type HoldemManagerListAllGamesQuery$data = {
  readonly listAllGames: ReadonlyArray<{
    readonly cards: {
      readonly playerHands: ReadonlyArray<{
        readonly hand: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        }> | null;
        readonly player: {
          readonly uuid: string | null;
        };
      }> | null;
      readonly table: ReadonlyArray<{
        readonly rank: string | null;
        readonly suit: string | null;
        readonly flipped: number | null;
      }> | null;
    };
    readonly chips: {
      readonly chipRoll: ReadonlyArray<{
        readonly key: string | null;
        readonly value: string | null;
      }> | null;
      readonly inPlay: ReadonlyArray<{
        readonly key: string | null;
        readonly value: string | null;
      }> | null;
      readonly paid: ReadonlyArray<{
        readonly key: string | null;
        readonly value: string | null;
      }> | null;
      readonly round: ReadonlyArray<{
        readonly key: string | null;
        readonly value: string | null;
      }> | null;
      readonly pot: number;
      readonly toCall: number;
    };
    readonly gameId: string;
    readonly meta: {
      readonly currency: string;
      readonly virtualId: number;
    };
    readonly phase: string;
    readonly playerTracker: {
      readonly active: ReadonlyArray<string> | null;
      readonly allIn: ReadonlyArray<string> | null;
      readonly called: ReadonlyArray<string> | null;
      readonly folded: ReadonlyArray<string> | null;
      readonly timeout: number | null;
    };
    readonly roles: {
      readonly bigBlind: {
        readonly uuid: string | null;
      } | null;
      readonly smallBlind: {
        readonly uuid: string | null;
      } | null;
      readonly dealer: {
        readonly uuid: string | null;
      } | null;
    };
    readonly scoring: {
      readonly hands: ReadonlyArray<{
        readonly bestHand: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        }> | null;
        readonly hand: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        }> | null;
        readonly hasFlush: boolean | null;
        readonly typeString: string | null;
      } | null> | null;
      readonly rewards: ReadonlyArray<{
        readonly key: string | null;
        readonly value: string | null;
      } | null> | null;
      readonly winningHand: {
        readonly bestHand: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        }> | null;
        readonly hand: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        }> | null;
        readonly hasFlush: boolean | null;
        readonly typeString: string | null;
      } | null;
    };
    readonly seating: {
      readonly arrangement: ReadonlyArray<{
        readonly user: {
          readonly uuid: string | null;
          readonly name: string | null;
        };
        readonly idx: number;
      }> | null;
    };
    readonly move: string | null;
  }>;
};
export type HoldemManagerListAllGamesQuery = {
  variables: HoldemManagerListAllGamesQuery$variables;
  response: HoldemManagerListAllGamesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currency"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rank",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "suit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "flipped",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "HoldemCard",
  "kind": "LinkedField",
  "name": "hand",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "HoldemCard",
    "kind": "LinkedField",
    "name": "bestHand",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasFlush",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "typeString",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      }
    ],
    "concreteType": "HoldemGame",
    "kind": "LinkedField",
    "name": "listAllGames",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HoldemCards",
        "kind": "LinkedField",
        "name": "cards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HoldemHand",
            "kind": "LinkedField",
            "name": "playerHands",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "player",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HoldemCard",
            "kind": "LinkedField",
            "name": "table",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HoldemChips",
        "kind": "LinkedField",
        "name": "chips",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "chipRoll",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "inPlay",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "paid",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "round",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toCall",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gameId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HoldemMeta",
        "kind": "LinkedField",
        "name": "meta",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "virtualId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phase",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HoldemPlayers",
        "kind": "LinkedField",
        "name": "playerTracker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allIn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "called",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "folded",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeout",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HoldemRoles",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "bigBlind",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "smallBlind",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "dealer",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HoldemScore",
        "kind": "LinkedField",
        "name": "scoring",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScoreHand",
            "kind": "LinkedField",
            "name": "hands",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "rewards",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScoreHand",
            "kind": "LinkedField",
            "name": "winningHand",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HoldemSeating",
        "kind": "LinkedField",
        "name": "seating",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HoldemSeat",
            "kind": "LinkedField",
            "name": "arrangement",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "idx",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "move",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HoldemManagerListAllGamesQuery",
    "selections": (v7/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HoldemManagerListAllGamesQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "0d792b840a1284d3c235e5de94743166",
    "id": null,
    "metadata": {},
    "name": "HoldemManagerListAllGamesQuery",
    "operationKind": "query",
    "text": "query HoldemManagerListAllGamesQuery(\n  $currency: String\n) {\n  listAllGames(currency: $currency) {\n    cards {\n      playerHands {\n        hand {\n          rank\n          suit\n          flipped\n        }\n        player {\n          uuid\n        }\n      }\n      table {\n        rank\n        suit\n        flipped\n      }\n    }\n    chips {\n      chipRoll {\n        key\n        value\n      }\n      inPlay {\n        key\n        value\n      }\n      paid {\n        key\n        value\n      }\n      round {\n        key\n        value\n      }\n      pot\n      toCall\n    }\n    gameId\n    meta {\n      currency\n      virtualId\n    }\n    phase\n    playerTracker {\n      active\n      allIn\n      called\n      folded\n      timeout\n    }\n    roles {\n      bigBlind {\n        uuid\n      }\n      smallBlind {\n        uuid\n      }\n      dealer {\n        uuid\n      }\n    }\n    scoring {\n      hands {\n        bestHand {\n          rank\n          suit\n          flipped\n        }\n        hand {\n          rank\n          suit\n          flipped\n        }\n        hasFlush\n        typeString\n      }\n      rewards {\n        key\n        value\n      }\n      winningHand {\n        bestHand {\n          rank\n          suit\n          flipped\n        }\n        hand {\n          rank\n          suit\n          flipped\n        }\n        hasFlush\n        typeString\n      }\n    }\n    seating {\n      arrangement {\n        user {\n          uuid\n          name\n        }\n        idx\n      }\n    }\n    move\n  }\n}\n"
  }
};
})();

(node as any).hash = "41eeceac203715e72fbf1f5322923a3a";

export default node;

import type { ComponentProps } from 'react'

export default function JackToken(props: ComponentProps<'svg'>) {
  const { height, width } = props

  return (
    <svg width={width} height={height} viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M196.57 103.583C196.57 155.497 154.485 197.583 102.57 197.583C50.6555 197.583 8.57031 155.497 8.57031 103.583C8.57031 51.6678 50.6555 9.58252 102.57 9.58252C154.485 9.58252 196.57 51.6678 196.57 103.583Z"
        fill="#161616"
      />
      <path
        d="M200.272 103.886C200.272 157.118 157.119 200.272 103.886 200.272C50.6536 200.272 7.50009 157.118 7.50009 103.886C7.50009 50.6535 50.6536 7.50005 103.886 7.50005C157.119 7.50005 200.272 50.6535 200.272 103.886Z"
        stroke="url(#paint0_linear_185_126)"
        strokeWidth="15"
      />
      <path
        d="M149.735 66.7588V48H92.2218C80.5602 48 71.8139 49.7781 66.1598 53.2453C60.5056 56.7126 57.6786 62.1358 57.6786 69.6037C57.6786 74.4935 58.9154 78.583 61.3891 82.0503C63.8628 85.5176 67.1316 87.7402 71.1955 88.7181V89.3405C65.8947 90.7629 62.0075 93.1633 59.6222 96.4528C57.2368 99.7422 56 104.099 56 109.344C56 117.167 58.9154 123.391 64.8346 127.925C70.6654 132.459 78.7049 134.682 88.8647 134.682H99.0244V143.394C99.0244 149.084 98.2293 152.996 96.5508 155.396C94.8722 157.708 92.1335 158.864 88.3346 158.864C86.656 158.864 85.1541 158.686 83.7406 158.419C82.3271 158.152 81.0019 157.886 79.765 157.619V175.755C83.5639 176.555 87.6278 177 91.8684 177C102.028 177 109.714 173.977 114.838 167.932C119.962 161.886 122.524 152.996 122.524 141.349V102.143H147.791V83.3839H122.524V66.8477H150L149.735 66.7588ZM92.4868 65.6919H98.8477V81.2502H91.9568C88.5996 81.2502 86.0376 80.5389 84.1823 79.2054C82.3271 77.8718 81.4436 75.7381 81.4436 73.071C81.4436 68.1813 85.0658 65.7808 92.3985 65.7808L92.4868 65.6919ZM79.9417 107.299C79.9417 104.365 80.9135 102.232 82.8571 100.631C84.8007 99.1199 87.6278 98.3198 91.3383 98.3198H98.9361V116.545H90.8966C83.6523 116.545 80.0301 113.433 80.0301 107.299H79.9417Z"
        fill="url(#paint1_linear_185_126)"
      />
      <defs>
        <linearGradient id="paint0_linear_185_126" x1="98.5" y1="258" x2="111" y2="-95" gradientUnits="userSpaceOnUse">
          <stop stopColor="#267A75" />
          <stop offset="0.934577" stopColor="#3CCEA2" />
        </linearGradient>
        <linearGradient id="paint1_linear_185_126" x1="103" y1="48" x2="103" y2="177" gradientUnits="userSpaceOnUse">
          <stop stopColor="#34C1CE" />
          <stop offset="1" stopColor="#00D595" />
        </linearGradient>
      </defs>
    </svg>
  )
}

/**
 * @generated SignedSource<<9714e2c7718472f1cb623e93698333d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CompType = "HIGHEST_BET" | "HIGHEST_WIN" | "WAGER" | "GAME_COUNT" | "MULTIPLIER" | "%future added value";
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type AppComponentlistCompetitionsQuery$variables = {};
export type AppComponentlistCompetitionsQuery$data = {
  readonly listCompetitions: ReadonlyArray<{
    readonly compId: number;
    readonly type: CompType;
    readonly name: string;
    readonly autoPayouts: boolean;
    readonly autoRenew: boolean;
    readonly enabled: boolean;
    readonly votingEnabled: boolean;
    readonly chatAnnouncement: boolean;
    readonly payoutStr: any;
    readonly price: number;
    readonly currency: Currency;
    readonly period: string;
    readonly votingFreerollCount: number | null;
    readonly votingFreerollGame: string | null;
    readonly insertedAt: string;
    readonly payoutToComp: boolean;
    readonly position: number;
    readonly runningRound: {
      readonly roundId: number;
      readonly startTime: number;
      readonly endTime: number;
      readonly payoutsDone: boolean;
      readonly payouts: {
        readonly price: number;
        readonly payoutStr: any;
        readonly votingFreerollCount: number | null;
        readonly votingFreerollGame: string | null;
        readonly payouts: ReadonlyArray<{
          readonly user: {
            readonly uuid: string;
            readonly name: string;
          };
          readonly value: string | null;
        }>;
        readonly votingPayouts: ReadonlyArray<{
          readonly user: {
            readonly uuid: string;
            readonly name: string;
          };
          readonly value: string | null;
        }> | null;
      } | null;
      readonly votes: ReadonlyArray<{
        readonly user: {
          readonly uuid: string;
          readonly name: string;
        };
        readonly votes: ReadonlyArray<{
          readonly user: {
            readonly uuid: string;
            readonly name: string;
          };
          readonly value: string | null;
        }>;
      }>;
      readonly players: ReadonlyArray<{
        readonly user: {
          readonly uuid: string;
          readonly name: string;
          readonly vip: {
            readonly badgeId: string | null;
            readonly offset: number | null;
            readonly avatar: string | null;
          } | null;
          readonly hasProfileImage: boolean;
        };
        readonly amount: number;
        readonly bet: {
          readonly multiplier: number;
          readonly bets: ReadonlyArray<number>;
          readonly win: number | null;
          readonly currency: string;
          readonly btcWin: number | null;
          readonly normalizedGame: {
            readonly label: string;
            readonly gameId: string;
            readonly provider: string | null;
            readonly urlThumb: string | null;
          } | null;
        } | null;
      }>;
    } | null;
  }>;
};
export type AppComponentlistCompetitionsQuery = {
  variables: AppComponentlistCompetitionsQuery$variables;
  response: AppComponentlistCompetitionsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payoutStr",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "votingFreerollCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "votingFreerollGame",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PublicUser",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v0/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Competition",
    "kind": "LinkedField",
    "name": "listCompetitions",
    "plural": true,
    "selections": [
      {
        "alias": "compId",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "autoPayouts",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "autoRenew",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "votingEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chatAnnouncement",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "period",
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payoutToComp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "position",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetitionRound",
        "kind": "LinkedField",
        "name": "runningRound",
        "plural": false,
        "selections": [
          {
            "alias": "roundId",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payoutsDone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionPayout",
            "kind": "LinkedField",
            "name": "payouts",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserValuePair",
                "kind": "LinkedField",
                "name": "payouts",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserValuePair",
                "kind": "LinkedField",
                "name": "votingPayouts",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionVote",
            "kind": "LinkedField",
            "name": "votes",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserValuePair",
                "kind": "LinkedField",
                "name": "votes",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionPlayerRow",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PublicUser",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Vip",
                    "kind": "LinkedField",
                    "name": "vip",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "badgeId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "offset",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasProfileImage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GameBet",
                "kind": "LinkedField",
                "name": "bet",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "multiplier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bets",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "win",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "btcWin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NormalizedGame",
                    "kind": "LinkedField",
                    "name": "normalizedGame",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gameId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "provider",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urlThumb",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppComponentlistCompetitionsQuery",
    "selections": (v9/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppComponentlistCompetitionsQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "253fcd94ba6aba956900cbf3ca6eb9ea",
    "id": null,
    "metadata": {},
    "name": "AppComponentlistCompetitionsQuery",
    "operationKind": "query",
    "text": "query AppComponentlistCompetitionsQuery {\n  listCompetitions {\n    compId: id\n    type\n    name\n    autoPayouts\n    autoRenew\n    enabled\n    votingEnabled\n    chatAnnouncement\n    payoutStr\n    price\n    currency\n    period\n    votingFreerollCount\n    votingFreerollGame\n    insertedAt\n    payoutToComp\n    position\n    runningRound {\n      roundId: id\n      startTime\n      endTime\n      payoutsDone\n      payouts {\n        price\n        payoutStr\n        votingFreerollCount\n        votingFreerollGame\n        payouts {\n          user {\n            uuid\n            name\n          }\n          value\n        }\n        votingPayouts {\n          user {\n            uuid\n            name\n          }\n          value\n        }\n      }\n      votes {\n        user {\n          uuid\n          name\n        }\n        votes {\n          user {\n            uuid\n            name\n          }\n          value\n        }\n      }\n      players {\n        user {\n          uuid\n          name\n          vip {\n            badgeId\n            offset\n            avatar\n          }\n          hasProfileImage\n        }\n        amount\n        bet {\n          multiplier\n          bets\n          win\n          currency\n          btcWin\n          normalizedGame {\n            label\n            gameId\n            provider\n            urlThumb\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c19db552d887b9c55c9ae1317fbf495f";

export default node;

import { controlBus, init, messageBus } from '@src/components/chatComponent/chat'
import { IStore, store } from '@src/store'
import { graphql } from 'babel-plugin-relay/macro'
import { RefObject, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchQuery, requestSubscription } from 'relay-runtime'
import { Subscription } from 'rxjs'
import { bufferTime, filter } from 'rxjs/operators'
import { useChatGiveawaysQuery } from './__generated__/useChatGiveawaysQuery.graphql'
import RelayEnvironment from '@src/RelayEnvironment'
import { useChatGivewayUpdatedSubscription } from './__generated__/useChatGivewayUpdatedSubscription.graphql'
import { useMutation } from 'react-relay'
import { deleteMessageMutation } from '@src/components/chat/AdminActions'
import { setGiveaways } from '@src/reducers/chat.reducer'
import { AppTypes } from '@src/reducers/app.reducer'
import { Money } from '@src/money'

export interface Message {
  timestamp: number
  from: string
  from_uuid: string
  from_admin: boolean
  from_muted_user?: boolean
  private: boolean
  has_profile_image: boolean
  vip?: {
    badge_id: string
    offset: number
    appender: number
    avatar: string
  }
  msg: string
  hash: string
  type: string
}

export function useChat({ ref, value }: { ref: RefObject<HTMLDivElement>; value: string }) {
  const isOpen = useSelector((state: IStore) => state.app.chatOpened)
  const giveaways = useSelector((state: IStore) => state.chat.giveaways)
  const closeChat = () => store.dispatch({ type: AppTypes.CLOSE_CHAT })

  const [shouldShowConfeti, setShouldShowConfeti] = useState(false)

  const [grabGiveaway, isLoadingGrabGiveaway] = useMutation(grabGiveawayMutation)

  const [messages, setMessages] = useState<Message[]>([])
  const [groupedMessages, setGroupedMessages] = useState<Message[][]>([])
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [isCommandPalleteOpen, setIsCommandPalletOpen] = useState(false)
  const [isEmojiWindowOpen, setIsEmojiWindowOpen] = useState(false)

  const [confetiTimeout, setConfetiTimeout] = useState<NodeJS.Timeout>()

  const handleGrabGiveaway = useCallback(
    (id: string) => {
      grabGiveaway({
        variables: {
          input: {
            giveawayId: id,
          },
        },
        onCompleted: (response) => {},
      })
    },
    [grabGiveaway]
  )

  const [deleteMessage] = useMutation(deleteMessageMutation)
  const handleMessageDelete = useCallback(
    (hash: string) => {
      deleteMessage({
        variables: {
          input: {
            hash,
          },
        },
        onCompleted: (response: any) => {
          if (!response.moderatorDeleteMessage.ok) return
          // setMessages((prev) => prev.filter((msg) => msg.hash !== hash))
        },
      })
    },
    [deleteMessage]
  )

  const displayConfeti = useCallback(
    (duration: number) => {
      if (confetiTimeout) clearTimeout(confetiTimeout)

      setShouldShowConfeti(true)
      const timeout = setTimeout(() => {
        setShouldShowConfeti(false)
      }, duration)

      setConfetiTimeout(timeout)

      return () => {
        clearTimeout(timeout)
      }
    },
    [confetiTimeout, setConfetiTimeout]
  )

  const scrollToBottom = useCallback(() => {
    if (!ref.current) return

    ref.current.scrollTo({
      top: ref.current.scrollHeight,
      behavior: 'auto',
    })
  }, [ref])

  const handleScroll = useCallback(() => {
    if (!ref.current) return

    const chatContainer = ref.current
    // Calculate the difference between the scroll height and the client height
    const scrollDifference = chatContainer.scrollHeight - chatContainer.clientHeight
    // Determine if the user is at the bottom or scrolling up
    const isAtBottom = chatContainer.scrollTop >= scrollDifference - 50 // Adding a small buffer for accuracy

    // Update the state to control whether to auto-scroll to the bottom
    setShouldScrollToBottom(isAtBottom)
  }, [ref])

  const [, setStorageChanged] = useState(0)

  useEffect(() => {
    const event = () => {
      init()
      setStorageChanged((p) => p + 1)
    }

    window.addEventListener('storage', event)

    return () => {
      window.removeEventListener('storage', event)
    }
  }, [])

  useEffect(() => {
    if (shouldScrollToBottom) scrollToBottom()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedMessages])

  useEffect(() => {
    if (value?.startsWith('/')) {
      setIsCommandPalletOpen(true)
      return
    }

    setIsCommandPalletOpen(false)
  }, [value])

  useEffect(() => {
    const grouped = messages.reduce<Message[][]>((acc, curr) => {
      if (acc.length === 0) return [[curr]]
      const lastGroup = acc[acc.length - 1]

      if (
        lastGroup?.[0]?.from_uuid === curr.from_uuid &&
        lastGroup?.[0]?.from === curr.from &&
        lastGroup?.every((m) => m.hash !== curr.hash)
      ) {
        lastGroup.push(curr)
      } else {
        acc.push([curr])
      }

      return acc
    }, [])

    setGroupedMessages(grouped)
  }, [scrollToBottom, messages])

  useEffect(() => {
    init()
    const chatMessages = messageBus.pipe(
      filter(
        (x: any) =>
          x.type === 'message' ||
          x.type === 'competition' ||
          x.type === 'giveaway_code' ||
          x.type === 'chat_support' ||
          x.type === 'code_master' ||
          x.type === 'mp_reward' ||
          x.type === 'mp_reward_v2' ||
          x.type === 'tournament_participation_rewards'
      )
    )
    const generalMessages = messageBus.pipe(
      filter(
        (x: any) =>
          x.type !== 'message' &&
          x.type !== 'competition' &&
          x.type !== 'giveaway_code' &&
          x.type !== 'chat_support' &&
          x.type !== 'code_master'
      )
    )

    fetchQuery<useChatGiveawaysQuery>(RelayEnvironment, giveawaysQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
      next: ({ giveaways }) => {
        store.dispatch({
          type: setGiveaways.type,
          payload: {
            giveaways,
          },
        })
      },
    })

    setSubscriptions([
      generalMessages.subscribe((msg: any) => {
        if (msg.type === 'channel_join') {
          // const receivedNick = msg.msg
          //   this.setState({
          //     nickname: receivedNick || '',
          //     state: receivedNick && receivedNick.length > 0 ? 1 : 0,
          //     statusMessage: '',
          //   })
        } else if (msg.type === 'onClose') {
          //   this.setState({
          //     state: 2,
          //     statusMessage: 'Connection lost. Reconnecting...',
          //   })
          //   this.connectionLost = true
        } else if (msg.type === 'error' && (msg as any).msg.reason === 'ban') {
          //   this.setState({
          //     state: 3,
          //     statusMessage: 'Banned',
          //   })
        } else if (msg.type === 'error' && (msg as any).msg.reason === 'anon') {
          console.error('You have to be logged in to use the chat')
          //   this.props.history.push('/login')
        }
      }),
      chatMessages.pipe(bufferTime(10)).subscribe((data) => {
        const msgs = data as Message[]
        if (msgs.length === 0) {
          return
        }

        msgs.forEach((msg) => {
          if (msgs.length === 0) {
            return
          }
        })

        if (msgs.length === 1 && msgs[0].type === 'competition') {
          const competition = JSON.parse(atob(msgs[0].msg)) as {
            name: string
            type: string
            players: { name: string }[]
          }

          if (competition.type === 'make_it_rain') {
            displayConfeti(30000)
          }
        }

        setMessages((prev) => {
          const uniqueSet = new Set()

          const messages = msgs.filter((m) => {
            const { hash } = m
            if (prev.some((p) => p.hash === hash)) return false
            if (!uniqueSet.has(hash)) {
              uniqueSet.add(hash)
              return true
            }
            return false
          })

          return [
            ...prev,
            ...messages.map((msg) => {
              const { msg: message } = msg
              if (msg.type === 'giveaway_code') {
                type Error = {
                  error_message: string
                  amount: string
                }
                type Reply = {
                  state: string
                  currency: string
                  code: string
                  amount: string
                }

                type Serialized = Reply & Error
                const serialized: Serialized = JSON.parse(message)

                if (serialized?.state === 'new') {
                  return {
                    ...msg,
                    msg: `${serialized.code}\n${Money.fromInteger(serialized.amount, serialized.currency)} ${
                      serialized.currency
                    } was added to your wallet.`,
                  }
                }

                if (serialized?.error_message) {
                  return { ...msg, msg: serialized.error_message }
                }

                if (serialized?.state === 'reply') {
                  return {
                    ...msg,
                    msg: serialized.code,
                  }
                }
              }
              return msg
            }),
          ]
        })
      }),
      controlBus.subscribe((msg) => {
        if (msg.action === 'delete') {
          setMessages((prev) => prev.filter((m) => m.hash !== (msg as any).hash))
        }
      }),
    ])

    return () => {
      subscriptions.forEach((sub) => sub.unsubscribe())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStorageChanged])

  useEffect(() => {
    const sub = requestSubscription<useChatGivewayUpdatedSubscription>(RelayEnvironment, {
      subscription: giveawayUpdatedSubscription,
      variables: {},
      onNext: (data) => {
        const giveawayUpdated = data?.giveawayUpdated

        if (giveawayUpdated?.usageLimit === giveawayUpdated?.usages) {
          store.dispatch({
            type: setGiveaways.type,
            payload: {
              giveaways: giveaways.filter((g) => g.giveawayId !== data?.giveawayUpdated.giveawayId),
            },
          })
          return
        }

        if (!giveaways.some((g) => g.giveawayId === data?.giveawayUpdated.giveawayId)) {
          store.dispatch({
            type: setGiveaways.type,
            payload: {
              giveaways: [...giveaways, giveawayUpdated],
            },
          })
          return
        }

        store.dispatch({
          type: setGiveaways.type,
          payload: {
            giveaways: giveaways.map((g) => {
              if (data?.giveawayUpdated.giveawayId === g.giveawayId) {
                return { ...g, ...giveawayUpdated }
              }
              return g
            }),
          },
        })
      },
    })

    return () => {
      sub.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [, setDocWidth] = useState<number | null>(null)

  useEffect(() => {
    if (!document.documentElement) return

    const observer = new ResizeObserver((entities) => {
      for (const entity of entities) {
        const { width } = entity.target.getBoundingClientRect()
        setDocWidth((prev) => {
          if (prev && prev > width && width < 1364) {
            setTimeout(() => {
              // the idea behind this is to hide the chat when the window is being resized to a tablet? size
              // at this point the chat is being displayed on top of the page, so we hide it
              closeChat()
            }, 0)
          }
          return width
        })
      }
    })

    observer.observe(document.documentElement)

    return () => {
      observer.disconnect()
    }
  }, [])

  return {
    isOpen,
    messages,
    setMessages,
    handleMessageDelete,
    handleGrabGiveaway,
    displayConfeti,
    shouldShowConfeti,
    handleScroll,
    groupedMessages,
    isLoadingGrabGiveaway,
    isCommandPalleteOpen,
    setIsCommandPalletOpen,
    isEmojiWindowOpen,
    setIsEmojiWindowOpen,
  }
}

const giveawaysQuery = graphql`
  query useChatGiveawaysQuery {
    giveaways {
      giveawayId: id
      currency
      amount
      usageLimit
      usages
      meta
      user {
        name
        uuid
        hasProfileImage
        vip {
          badgeId
          offset
          avatar
        }
      }
    }
  }
`

export const giveawayUpdatedSubscription = graphql`
  subscription useChatGivewayUpdatedSubscription {
    giveawayUpdated {
      giveawayId: id
      currency
      amount
      usageLimit
      usages
      meta
      user {
        name
        uuid
        hasProfileImage
        vip {
          badgeId
          offset
          avatar
        }
      }
    }
  }
`

export const grabGiveawayMutation = graphql`
  mutation useChatGrabGiveawayMutation($input: GrabGiveawayInput!) {
    grabGiveaway(input: $input) {
      result
    }
  }
`

import { useEffect, useState, Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation, fetchQuery, commitMutation } from 'react-relay'
import { faChevronDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import formatError from '../errorFormatter'
import { activeWalletUpdated, formatBalance, WalletConstant, Withdraw } from '../../reducers/wallet.reducer'
import { applyUpdatedUserPreference, UserProp } from '../../reducers/authentication.reducer'
import ConfigurationService, { CurrencyEnum } from '../../service/ConfigurationService'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import { store } from '../../store'
import { AppTypes } from '../../reducers/app.reducer'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import { formatFiat } from '../rightSide/walletComponent/WalletComponent'
import Env from '../../RelayEnvironment'
import Warning from '../icons/warning'
import { Currency } from './__generated__/DepositViewComponentDepositAddressQuery.graphql'
import { WithdrawViewSubmitWithdrawMutation } from './__generated__/WithdrawViewSubmitWithdrawMutation.graphql'
import Select from 'react-select'

import 'react-toastify/dist/ReactToastify.css'

import { GameListComponentstoreUserPreferenceMutation } from '../gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import { updateUserPreference } from '../gameListComponent/GameListComponent'
import { DepositViewTokensQuery, DepositViewTokensQuery$data } from './__generated__/DepositViewTokensQuery.graphql'
import { listTokens } from './DepositView'
import { WithdrawViewComponentEstimateFeeQuery } from './__generated__/WithdrawViewComponentEstimateFeeQuery.graphql'
import { CancelWithdrawPopup, withdrawHistoryQuery } from './HistoryView'
import { HistoryViewComponentWithdrawsQuery } from './__generated__/HistoryViewComponentWithdrawsQuery.graphql'
import React from 'react'

import './WithdrawView.scoped.scss'
import './HistoryView.scoped.scss'
import WithdrawalRow from './WithdrawalRow'
import { Money } from '@src/money'

const WithdrawConfirmPopup = ({
  unit,
  amount,
  currency,
  virtualId,
  address,
  network,
  memo,
  setPopup,
  clearForm,
  setIsLoadingSubmission,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const showNotification = (status, text) =>
    store.dispatch({ type: AppTypes.SHOW_NOTIFICATION, notification: { status: status, text: text } })

  const [commit] = useMutation<WithdrawViewSubmitWithdrawMutation>(submitWithdrawMutation)

  const HandleClick = () => {
    const c = ConfigurationService.instance.getCurrency(currency)

    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const preparedAmout = Money.convertUnit(+amount, c.m_unit, format, c.s_unit)

    setIsLoadingSubmission(true)
    commit({
      variables: { input: { amount: preparedAmout, currency, virtualId, address, network, memo } },

      onCompleted: (data) => {
        setPopup(
          <div className="TransactionCompletePopup">
            <div className="title">{t('wallet.withdrawConfirmPopup.transactionComplete', 'transaction complete')}</div>
          </div>
        )
        clearForm()
        setIsLoadingSubmission(false)

        if (onSubmit) {
          onSubmit()
        }
      },
      onError: (e) => {
        // toast.error(e)
        showNotification('error', formatError(e)[0])
        setIsLoadingSubmission(false)
      },
    })
  }

  return (
    <div className="WithdrawConfirmPopup">
      <div className="title">
        {t('wallet.withdrawConfirmPopup.requestToWithdraw', 'You requested to withdraw')}
        <br />
        {amount}
        {currency === 'BTC' ? unit : currency}
      </div>
      {address && (
        <div className="address">
          <div className="label">{t('wallet.withdrawConfirmPopup.toThisAddress', 'to this adress')}</div>
          <div>{address}</div>
        </div>
      )}
      <button type="button" onClick={() => HandleClick()}>
        {t('wallet.withdrawConfirmPopup.approve', 'Approve')}
      </button>
    </div>
  )
}

const allCurrencies = Money.listAll()

export const WithdrawView = ({ openListView, setPopup }) => {
  const { t } = useTranslation()
  const walletStore = store.getState().wallet
  const [activeWallet, setActiveWallet] = useState(walletStore?.activeWallet)

  const [commit] = useMutation<GameListComponentstoreUserPreferenceMutation>(updateUserPreference)

  const [amount, setAmount] = useState<string>()
  const [address, setAddress] = useState<string>()
  const [memo, setMemo] = useState<string>()
  const [fee, setFee] = useState<string>()
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false)
  const isExternalCurrency =
    ConfigurationService.instance.getExternalCurrencies().indexOf(activeWallet?.currency as CurrencyEnum) >= 0
  const [tokens, setTokens] = useState<DepositViewTokensQuery$data>()
  const [network, setNetwork] = useState<string>()
  const [pastWithdraws, setPastWithdraws] = useState<Withdraw[]>([])

  const HandleClick = (uuid) => {
    setPopup(
      <CancelWithdrawPopup
        uuid={uuid}
        pastWithdraws={pastWithdraws}
        setPastWithdraws={setPastWithdraws}
        setPopup={setPopup}
      />
    )
  }

  const mapWithdraws = () => {
    return pastWithdraws
      .filter((x) => !x.isProcessed)
      .map((x, i) => <WithdrawalRow key={i} withdrawal={x} index={i} handleCancelClick={HandleClick} />)
  }

  const showNotification = (status, text) =>
    store.dispatch({ type: AppTypes.SHOW_NOTIFICATION, notification: { status: status, text: text } })

  const clearForm = () => {
    setAddress('')
    setAmount('')
  }

  const fetchPastWithdraws = useCallback(() => {
    if (!activeWallet) return
    fetchQuery<HistoryViewComponentWithdrawsQuery>(Env, withdrawHistoryQuery, {
      filter: { currency: activeWallet.currency as Currency },
      page: 1,
    }).subscribe({
      next: ({ withdraws }) => {
        if (withdraws?.entries) {
          setPastWithdraws([...withdraws.entries])
        }
      },
    })
  }, [activeWallet])

  useEffect(() => {
    fetchPastWithdraws()
  }, [activeWallet, fetchPastWithdraws])

  useEffect(() => {
    const subscription = activeWalletUpdated.subscribe(() => {
      setActiveWallet(store.getState().wallet.activeWallet)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [activeWallet])

  useEffect(() => {
    fetchQuery<DepositViewTokensQuery>(Env, listTokens, {}).subscribe({
      next: (data) => {
        setTokens(data)
        if (isExternalCurrency && activeWallet) {
          // const token = data.tokens.find(x => x.crypto === activeWallet.currency);
          setNetwork(undefined)
        }
      },
    })
  }, [activeWallet, isExternalCurrency])

  useEffect(() => {
    const isExternal =
      ConfigurationService.instance.getExternalCurrencies().indexOf(activeWallet?.currency as CurrencyEnum) >= 0
    if (
      activeWallet &&
      (ConfigurationService.instance.getBTCLikeCurrencies().indexOf(activeWallet.currency) >= 0 || isExternal)
    ) {
      if (activeWallet?.currency === 'JACK') {
        // Imaginary coin
        return
      }
      let args = {
        currency: activeWallet.currency as Currency,
      }
      if (isExternal) {
        if ((network?.length || 0) > 0 && (address?.length || 0) > 0) {
          args['tokenId'] = network
          args['address'] = address
        } else {
          return
        }
      }
      fetchQuery<WithdrawViewComponentEstimateFeeQuery>(Env, feeEstimateQuery, args).subscribe({
        next: ({ estimateFee }) => {
          if (estimateFee && estimateFee.length === 1) {
            setFee(estimateFee[0].feerate?.toFixed(8))
          }
        },
      })
    }
  }, [activeWallet, network, address])

  if (!activeWallet) {
    return <span>...</span>
  }

  const unitConfig = allCurrencies[activeWallet.currency]
  const defaultUnit = ConfigurationService.instance.getCurrency(activeWallet.currency).format
  const selectedUnit = getUserPropOr(('unit_' + activeWallet.currency.toUpperCase()) as UserProp, defaultUnit)

  const setActive = (e: React.MouseEvent | undefined, currency: string) => {
    e?.preventDefault()
    e?.stopPropagation()

    const w = store
      .getState()
      .wallet.wallets.find((x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === 0)
    if (!w) return

    store.dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: w })
    commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
      mutation: updateUserPreference,
      variables: { input: { key: UserProp.ACTIVE_COIN, value: w.currency } },
    })
  }

  const setActiveUnit = (e: React.MouseEvent, currency: string, unit: string) => {
    e.preventDefault()
    e.stopPropagation()
    setActive(undefined, currency)
    commit({
      variables: { input: { key: 'unit_' + currency, value: unit } },
      onCompleted: (resp) => {
        applyUpdatedUserPreference(resp)
      },
    })
  }
  const coin = tokens?.tokens.find((x) => x.crypto === activeWallet.currency)
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: 'white',
      backgroundColor: 'unset',
      border: '1px solid #fff',
      borderRadius: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px 10px',
      backgroundColor: '#212226',
      color: '#fff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#212226',
    }),
  }

  const filterOption = (candidate, input) => {
    input = input.toLowerCase()
    return undefined !== Object.values(candidate.data).find((x: any) => x.toString().toLowerCase().indexOf(input) >= 0)
  }

  if (activeWallet?.currency === 'JACK') {
    // Imaginary coin
    return <h2>{t('general.comingSoon', 'Coming soon')}</h2>
  }

  return (
    <div>
      <div className="with-table">
        <div>
          <span>{t('wallet.amount', 'Amount')}</span>
          <span>{t('wallet.balance', 'Balance')}</span>
          <span className="coin-header">{t('wallet.coin', 'Coin')}</span>
        </div>
        <div className="balance-row">
          <div className="withdraw-amount ">
            <input type="number" step={1} value={amount} onChange={(e) => setAmount(e.target.value)} />
          </div>
          <div className="balance">
            <span onClick={() => setAmount(formatBalance(activeWallet, false, false))}>
              {formatBalance(activeWallet, false, false)}
            </span>
            {formatFiat(walletStore.rates, activeWallet)}
          </div>
          <div>
            <div className="select" onClick={openListView}>
              <div className="currency">
                <img className="coin-image" alt={activeWallet.currency} src={getLogoForCoin(activeWallet.currency)} />
                {activeWallet.currency}
              </div>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
      <div className="withdraw-units">
        <span className="label">{t('wallet.unit', 'Unit')}</span>
        <div className="unit-list">
          {Object.keys(unitConfig.units).map((key) => {
            const unit = unitConfig.units[key]
            if (unit.code === 'raw') return null
            return (
              <span
                onClick={(e) => setActiveUnit(e, activeWallet.currency, unit.code)}
                className={'wallet-unit' + (unit.code === selectedUnit ? ' selected' : '')}
                title={unit.name}
                key={'unit_' + unit.name}
              >
                {unit.code}
              </span>
            )
          })}
        </div>
      </div>
      <div className="address">
        <div className="label">{t('wallet.withdrawView.yourAddress', 'Your address')}</div>
        <input type="text" className="value" onChange={(e) => setAddress(e.target.value)} value={address} />
      </div>
      {isExternalCurrency ? (
        <Fragment>
          <div className="network">
            <Select
              maxMenuHeight={150}
              key={network}
              value={coin?.tokens.find((x) => x.tokenId === network)}
              options={coin?.tokens}
              placeholder={t('wallet.selectNetwork', 'Select network')}
              onChange={(e) => setNetwork(e?.tokenId)}
              styles={customStyles}
              filterOption={filterOption}
              formatOptionLabel={(e) => {
                return (
                  <div className="currency-select">
                    <img alt={e.name} src={e.chainLogo} />
                    <span>
                      {e.name === 'Ethereum' && e.network === 'NATIVE' ? 'Ethereum ERC20' : `${e.name}(${e.network})`}
                    </span>
                  </div>
                )
              }}
            />
          </div>
          <div className="address">
            <div className="label">{t('wallet.memo', 'Memo')}</div>
            <input
              placeholder="Optional memo"
              type="text"
              className="value"
              onChange={(e) => setMemo(e.target.value)}
              value={memo}
            />
          </div>
        </Fragment>
      ) : null}
      {fee ? (
        <div className="fee">
          {t('wallet.withdrawView.estimatedFee', 'Estimated fee')}: {fee} {activeWallet.currency}
        </div>
      ) : null}
      <div className="warning">
        <Warning className="inline-block mr-3" />
        {t('wallet.withdrawView.reminder', 'Make sure you have the correct adress. This cannot be undone.')}
      </div>
      <button
        disabled={isLoadingSubmission}
        className="button"
        onClick={() => {
          const invalidExternal = isExternalCurrency && (network?.length || 0) === 0
          if (amount && address && !invalidExternal) {
            setPopup(
              <WithdrawConfirmPopup
                unit={selectedUnit}
                amount={amount}
                currency={activeWallet?.currency}
                virtualId={activeWallet?.virtualId}
                address={address}
                network={network}
                memo={memo}
                setPopup={setPopup}
                clearForm={clearForm}
                setIsLoadingSubmission={setIsLoadingSubmission}
                onSubmit={fetchPastWithdraws}
              />
            )
          } else {
            showNotification(
              'error',
              t('wallet.withdrawView.withDrawError', 'Correct amount and address should be provided')
            )
          }
        }}
      >
        {isLoadingSubmission ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} spin className="text-base" />
          </div>
        ) : (
          <span className="uppercase">{t('wallet.withdrawView.withdraw', 'Withdraw')}</span>
        )}
      </button>
      <div
        className="withdraw-history-wrapper"
        style={{
          maxHeight: '200px',
        }}
      >
        <div className="table pt-20">{mapWithdraws()}</div>
      </div>
    </div>
  )
}

const feeEstimateQuery = graphql`
  query WithdrawViewComponentEstimateFeeQuery($currency: Currency!, $address: String, $tokenId: String) {
    estimateFee(currency: $currency, address: $address, tokenId: $tokenId) {
      blocks
      feerate
    }
  }
`

const submitWithdrawMutation = graphql`
  mutation WithdrawViewSubmitWithdrawMutation($input: SubmitWithdrawInput!) {
    submitWithdraw(input: $input) {
      withdraw {
        amount
        currency
        isProcessed
        txid
        isAuthenticated
        address
        insertedAt
        uuid
        meta
      }
    }
  }
`

import { graphql } from 'babel-plugin-relay/macro'
import './BetHistoryComponent.scss'
import React, { useEffect, useState } from 'react'
import { BetStatistic, betStatisticQueue, getUserLink, serverTimeDiff } from '../../../App'
import { userUpdated } from '../../../reducers/authentication.reducer'
import { useForceUpdate } from '../walletComponent/WalletComponent'
import { timer } from 'rxjs'
import { ProvablyFairValidator } from '../../provablyFairValidator/ProvablyFairValidator'
import { UserBet } from '../../LatestBets/LatestBets_old'
import { Link } from 'react-router-dom'
import { closeModalTask } from '../../modalComponent/modalComponent'
import { viewportWidth } from '../../..'
import { store } from '../../../store'
import { formatAmount } from '../../../reducers/wallet.reducer'

enum Tab {
  lastBets,
  top24Wins,
}

let animationId: any

const minimalLabels = ['s', 's', 'm', 'h', 'd', 'm', 'y']
const fatLabels = ['second', 'seconds', 'minutes', 'hour', 'days', 'months', 'years']

export const timeAgo = (prevDate: number) => {
  const now = new Date().getTime() + serverTimeDiff
  const diff = now - prevDate
  const minute = 60 * 1000
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const year = day * 365

  const labels = viewportWidth < 1000 ? minimalLabels : fatLabels

  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000)
      return `${seconds} ${seconds > 1 ? labels[0] : labels[1]} ago`
    case diff < hour:
      return Math.round(diff / minute) + ' ' + labels[2] + ' ago'
    case diff < day:
      return Math.round(diff / hour) + ' ' + labels[3] + ' ago'
    case diff < month:
      return Math.round(diff / day) + ' ' + labels[4] + ' ago'
    case diff < year:
      return Math.round(diff / month) + ' ' + labels[5] + ' ago'
    case diff > year:
      return Math.round(diff / year) + ' ' + labels[6] + ' ago'
    default:
      return ''
  }
}

export const BetHistoryComponent = () => {
  const [tab, _setTab] = useState(Tab.lastBets)
  const [bets, setBets] = useState<BetStatistic>()
  const [validatorUUID, setValidatorUUID] = useState<string>()

  const forceUpdate = useForceUpdate()

  const setTab = (tab: Tab) => {
    _setTab(tab)
    setValidatorUUID(undefined)
  }

  useEffect(() => {
    const subscription = userUpdated.subscribe(() => {
      forceUpdate()
    })
    const ticker = timer(5000, 5000).subscribe(() => {
      forceUpdate()
    })
    const betSubscription = betStatisticQueue.subscribe((data) => {
      setBets(data)
    })

    return () => {
      betSubscription.unsubscribe()
      ticker.unsubscribe()
      subscription.unsubscribe()
    }
  }, [forceUpdate])

  const offset = new Date().getTimezoneOffset() * 60 * 1000

  if (!bets) {
    return null
  }

  const toggleValidator = (e: any, bet: UserBet) => {
    if ((e.target as HTMLElement).tagName.toLowerCase() === 'a') {
      closeModalTask.next()
      return
    }
    if (!bet.gameProvider.startsWith('blackjackfun')) {
      return
    }
    if (undefined !== animationId) {
      clearTimeout(animationId)
    }
    if (validatorUUID === bet.gameId) {
      animationId = setTimeout(() => {
        animationId = undefined
        setValidatorUUID(undefined)
      }, 150)
      document.querySelector('.bet-history-content .game-entry-validator')?.classList.add('remove')
    } else {
      if (undefined !== validatorUUID) {
        animationId = setTimeout(() => {
          animationId = undefined
          setValidatorUUID(bet.gameId)
        }, 150)
        document.querySelector('.bet-history-content .game-entry-validator')?.classList.add('remove')
      } else {
        setValidatorUUID(bet.gameId)
      }
    }
  }

  return (
    <div className="bet-history">
      <div className="bet-buttons">
        <div className={tab === Tab.lastBets ? 'active' : ''} onClick={() => setTab(Tab.lastBets)}>
          LATEST BETS
        </div>
        <div className={tab === Tab.top24Wins ? 'active' : ''} onClick={() => setTab(Tab.top24Wins)}>
          TOP WINS 24
        </div>
      </div>
      <div className="bet-history-content">
        {(tab === Tab.lastBets ? bets.recent : bets.biggestWins)?.slice(0, 20).map((x) => {
          const betSum = x.bets.reduce((acc, val) => acc + val, 0)
          let status = x.finished
            ? betSum === (x.win || 0)
              ? 'Draw'
              : betSum > (x.win || 0)
              ? 'Lose'
              : 'Win'
            : 'Running'

          const amount = formatAmount(betSum, x.currency)
          // const amount = <img alt={''} src={'data:image/png;base64,' + x.btcBet} />
          if (tab === Tab.top24Wins) {
            status = 'Win'
          }

          /*
            const provider = x.gameProvider
              .replace('blackjackfun:sp', 'Blackjack SP')
              .replace('blackjackfun:mp', 'Blackjack MP')
              .replace('blackjackfun:rb', 'Red & Black')
              .replace('blackjackfun', 'Blackjack SP');
            */

          let gameName = x.gameProvider
          let link
          if (gameName.startsWith('blackjackfun')) {
            if (gameName === 'blackjackfun:rb') {
              gameName = 'Red or Black'
            } else {
              gameName = 'Blackjack.fun'
            }
            if (x.gameProvider === 'blackjackfun:rb') {
              link = 'game/redorblack'
            } else if (x.gameProvider === 'blackjackfun:mp:casual') {
              gameName = 'Blackjack.fun CT'
              const tables = store
                .getState()
                .multiplayer.tables.filter(
                  (table) => table.currency === x.currency && table.name.startsWith('Casual table')
                )
              if (tables.length > 0) {
                const availableTable = tables.find((table) => table.players.length < table.maxPlayers)
                link =
                  'game/blackjack/bitcoin-blackjack/' +
                  (undefined !== availableTable ? availableTable.uuid : tables[0].uuid)
              } else {
                link = 'game/blackjack'
              }
            } else {
              link = 'game/blackjack'
            }
          } else if (gameName.startsWith('softswiss')) {
            link = 'game/' + gameName
            gameName = gameName.replace('softswiss:', '')
          } else {
            link = 'game/' + gameName
            gameName = gameName.substring(gameName.indexOf(':') + 1)
            gameName = gameName.substring(0, 1).toUpperCase() + gameName.substring(1)
          }

          let validator: JSX.Element | null = null
          if (validatorUUID === x.gameId) {
            validator = <ProvablyFairValidator gameId={validatorUUID} />
          }

          return (
            <React.Fragment>
              <div className={x.gameId === validatorUUID ? 'open bet-history-row' : 'bet-history-row'}>
                <span>{getUserLink(x.user)}</span>
                <span>{amount}</span>
                <span onClick={(e) => toggleValidator(e, x)} key={x.gameId}>
                  {status}
                </span>
                <span>{x ? timeAgo(new Date(x.insertedAt).getTime() - offset) : null}</span>
                <span>
                  <Link to={link}>{gameName}</Link>
                </span>
              </div>
              {validator}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export const topbetsUpdatedSubscription = graphql`
  subscription BetHistoryComponentTopBetsUpdatedSubscription {
    topBetsUpdated {
      sportBets {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        multiplier
        profit
        meta
        insertedAt
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      highrollers {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        profit
        multiplier
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      biggestWins {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        profit
        multiplier
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      biggestWinsGlobal {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        profit
        multiplier
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      recent {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        profit
        multiplier
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
    }
  }
`

export const betsQuery = graphql`
  query BetHistoryComponentBetsQuery {
    bets {
      sportBets {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        multiplier
        profit
        meta
        insertedAt
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      highrollers {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        profit
        multiplier
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      biggestWins {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        multiplier
        profit
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      biggestWinsGlobal {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        profit
        multiplier
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
      recent {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        multiplier
        profit
        meta
        insertedAt
        normalizedGame {
          label
          gameId
          provider
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
    }
  }
`

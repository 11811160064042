import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { graphql } from 'babel-plugin-relay/macro'
import error from 'react-hot-toast'
import { faArrowLeft, faCaretDown, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchQuery, useMutation } from 'react-relay'
import { interval } from 'rxjs'
import { UserProp } from '../../reducers/authentication.reducer'
import { formatBalance, getBalance, getWallet } from '../../reducers/wallet.reducer'
import ConfigurationService from '../../service/ConfigurationService'
import Env from '../../RelayEnvironment'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import { formatFiat, getFiatRatesQuery } from '../rightSide/walletComponent/WalletComponent'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import formatError from '../errorFormatter'
import { Currency } from '../modalComponent/__generated__/RainComponentMakeItRainMutation.graphql'
import { WalletComponentFiatRatesQuery } from '../rightSide/walletComponent/__generated__/WalletComponentFiatRatesQuery.graphql'
import { BecomeAnInvestorEndBankrollMutation } from './__generated__/BecomeAnInvestorEndBankrollMutation.graphql'
import {
  BecomeAnInvestorGetMyBankrollsQuery,
  BecomeAnInvestorGetMyBankrollsQuery$data,
} from './__generated__/BecomeAnInvestorGetMyBankrollsQuery.graphql'
import {
  BecomeAnInvestorGetMyBankrollPaymentsQuery,
  BecomeAnInvestorGetMyBankrollPaymentsQuery$data,
} from './__generated__/BecomeAnInvestorGetMyBankrollPaymentsQuery.graphql'
import { BecomeAnInvestorAddBankrollMutation } from './__generated__/BecomeAnInvestorAddBankrollMutation.graphql'
import './BecomeAnInvestor.scoped.scss'
import { Money } from '@src/money'

enum ActivityTab {
  investments,
  earnings,
}

export const BecomeAnInvestorModal = ({ closeCallback }: { closeCallback: () => void }) => {
  const { t } = useTranslation()
  const [fiatRates, setFiatRates] = useState<{ key: string; value: number }[]>([])
  const [currency, setCurrency] = useState<string>('BTC')
  const [currencyFilter, setCurrencyFilter] = useState<string>()
  const [unitConfig, setUnitConfig] = useState<{
    selectedUnit: string
    balance: number
    allUnits: { code: string }[]
  }>()
  const [amount, setAmount] = useState<string>()
  const [step, setStep] = useState<{ number: number; was?: number; back?: (string) => void; invested?: number }>({
    number: 1,
    back: undefined,
  })
  const [addBankroll] = useMutation<BecomeAnInvestorAddBankrollMutation>(addBankrollMutation)
  const [endBankroll] = useMutation<BecomeAnInvestorEndBankrollMutation>(endBankrollMutation)
  const [myBankrolls, setMyBankrolls] = useState<BecomeAnInvestorGetMyBankrollsQuery$data>()
  const [myBankrollPayments, setMyBankrollPayments] = useState<BecomeAnInvestorGetMyBankrollPaymentsQuery$data>()
  const [myBankrollPeriod, setMyBankrollPeriod] = useState('hourly')
  const [activityTab, setActivityTab] = useState<ActivityTab>(ActivityTab.investments)
  const [bankrollToCancel, setBankrollToCancel] = useState<string>()

  useEffect(() => {
    updateBankrolls()
  }, [step])

  useEffect(() => {
    fetchQuery<BecomeAnInvestorGetMyBankrollPaymentsQuery>(Env, listBankrollPayments, {
      period: myBankrollPeriod,
      currency: currencyFilter as Currency,
    }).subscribe({
      next: (data) => {
        setMyBankrollPayments(data)
      },
    })
  }, [currencyFilter, myBankrollPeriod])

  useEffect(() => {
    const allCurrencies = Money.listAll()
    const units = Object.keys(allCurrencies[currency].units)
      .map((x) => allCurrencies[currency].units[x])
      .sort((a: any, b: any) => (a.shift > b.shift ? 1 : a.shift === b.shift ? 0 : -1))
    const format = getUserPropOr(('unit_' + currency) as UserProp, units[0].code)
    setUnitConfig({ allUnits: units, selectedUnit: format, balance: getBalance(units[0].code, 0) })
  }, [currency])

  useEffect(() => {
    const getFiatRates = () => {
      fetchQuery<WalletComponentFiatRatesQuery>(Env, getFiatRatesQuery, {}).subscribe({
        next: (data) => {
          setFiatRates(
            data.fiatRates.map((x) => {
              return { key: x.key as string, value: +(x.value as string) }
            })
          )
        },
      })
    }

    const fiatSubscription = interval(200 * 1000).subscribe(() => {
      getFiatRates()
    })
    getFiatRates()

    return () => {
      fiatSubscription.unsubscribe()
    }
  }, [])

  const updateBankrolls = () => {
    fetchQuery<BecomeAnInvestorGetMyBankrollsQuery>(Env, listBankrolls, {}).subscribe({
      next: (data) => {
        setMyBankrolls(data)
      },
    })
  }

  const setUnit = (x: { code: string }) => {
    setUnitConfig({ allUnits: unitConfig!.allUnits, balance: unitConfig!.balance, selectedUnit: x.code })
  }

  if (!unitConfig) {
    return <div />
  }
  const c = ConfigurationService.instance.getCurrency(currency)

  const formattedAmount = Money.convertUnit(unitConfig.balance, c.m_unit, c.s_unit, unitConfig!.selectedUnit)
  const invalidAmount = isNaN((amount || formattedAmount) as any)

  const round = (number, places) => {
    const d = Math.pow(10, places)
    return (Math.round((number + Number.EPSILON) * d) / d).toFixed(places)
  }

  let yearly = 0
  // let hourly = 0;
  if (!invalidAmount) {
    yearly = +(amount || formattedAmount) * 0.15
    // const hourly = Math.ceil(yearly / 365 / 24)
  }

  const formattedBalance = (currency: string) => {
    const wallet = getWallet(currency)
    if (wallet) {
      return (
        <span className="wallet">
          {formatBalance(wallet, true, false)}
          <br />
          <span className="fiat">{formatFiat(fiatRates, wallet)}</span>
        </span>
      )
    }
    return <span className="wallet no-funds">{t('becomeInvestor.noFundsAvailable', 'No funds available')}</span>
  }

  const getBody = () => {
    if (step.number === 1) {
      const stake = () => {
        let bankAmount = unitConfig.balance
        if (undefined !== amount) {
          bankAmount = Money.convertUnit(+amount, currency, unitConfig.selectedUnit, c.s_unit)
        }
        addBankroll({
          variables: { input: { amount: bankAmount, currency: currency as Currency } },
          onCompleted: (resp) => {
            setStep({ number: 3, invested: bankAmount })
            updateBankrolls()
            setUnitConfig({
              allUnits: unitConfig.allUnits,
              selectedUnit: unitConfig.selectedUnit,
              balance: getBalance(unitConfig.allUnits[0].code, 0),
            })
          },
          onError: (err) => {
            error(formatError(err)[0], {
              style: {
                background: 'red',
                color: 'white',
              },
            })
          },
        })
      }

      return (
        <React.Fragment>
          <div
            className="coin-selector green-border-theme"
            onClick={() =>
              setStep({
                number: 2,
                was: 1,
                back: (newCoin) => {
                  setStep({ number: 1 })
                  if (newCoin && newCoin !== currency) {
                    setUnitConfig(undefined)
                    setCurrency(newCoin)
                  }
                },
              })
            }
          >
            <div className="currency">
              <img src={getLogoForCoin(currency)} alt={currency} />
              <span>{currency}</span>
            </div>
            <div className="purpole">{t('becomeInvestor.chooseCoin', 'Choose coin')}</div>
          </div>
          <div className="units">
            {unitConfig?.allUnits.map((x) => (
              <span
                onClick={() => setUnit(x)}
                className={'unit' + (x.code === unitConfig.selectedUnit ? ' selected' : '')}
                key={'unit_' + x.code}
              >
                {x.code}
              </span>
            ))}
          </div>
          <div className="amount green-border-theme">
            <input
              key={unitConfig.selectedUnit}
              className={invalidAmount ? 'invalid' : ''}
              defaultValue={formattedAmount}
              type="text"
              required
              onChange={(e) => setAmount(e.target.value)}
            />
            <span className="purpole">{t('becomeInvestor.investmentAmount', 'Investment amount')}</span>
          </div>
          <div className="stat-entries">
            <div className="stat-entry">
              <span>{round(yearly, 8)}</span>
              <span className="purpole">{t('becomeInvestor.yearly', 'Yearly (15%)')}</span>
            </div>
            <div className="stat-entry">
              <span>{round(yearly / 12, 8)}</span>
              <span className="purpole">{t('becomeInvestor.monthly', 'Monthly Profit')}</span>
            </div>
            <div className="stat-entry">
              <span>{round(yearly / 12 / 30, 8)}</span>
              <span className="purpole">{t('becomeInvestor.daily', 'Daily Profit')}</span>
            </div>
            <div className="stat-entry">
              <span>{round(yearly / 12 / 30 / 24, 8)}</span>
              <span className="purpole">{t('becomeInvestor.hourly', 'Hourly Profit')}</span>
            </div>
          </div>
          <div className="buttons">
            <button onClick={stake} className="stake">
              {t('becomeInvestor.stakeMyCoins', 'Stake my coins')}
            </button>
            <button onClick={() => setStep({ number: 4 })} className="bactivity">
              {t('becomeInvestor.activity', 'Activity')}
            </button>
          </div>
          <div className="info">
            <div>
              <div className="p-circle" />
              <Trans i18nKey="becomeInvestor.stakeConceptInfo">
                <span onClick={() => setStep({ number: 5 })} className="link">
                  Find out more
                </span>
                about the stake concept
              </Trans>
            </div>
            <div>
              <span>{t('becomeInvestor.forMoreInfoContactUs', 'For more information contact us at')}</span>
            </div>
            <div>
              <a href="mailto:info@blackjack.fun" className="link">
                info@blackjack.fun
              </a>
            </div>
          </div>
        </React.Fragment>
      )
    } else if (step.number === 2) {
      const currencies = Object.keys(ConfigurationService.instance.getTradeableCurrencies())

      const onClick = (currency: string) => {
        if (step.back) {
          step.back(currency)
        }
      }

      return (
        <React.Fragment>
          {currencies.map((tradableCurrency) => {
            const b = getWallet(tradableCurrency)?.balance || 0
            return (
              <div
                onClick={() => (b > 0 ? onClick(tradableCurrency) : false)}
                className={
                  'row' +
                  (b > 0 ? ' selectable' : '') +
                  (tradableCurrency === currency && step.was === 1 ? ' selected' : '')
                }
                key={'cur_' + tradableCurrency}
              >
                <div className="currency">
                  <img alt={tradableCurrency} src={getLogoForCoin(tradableCurrency)} />
                  {tradableCurrency}
                </div>
                {formattedBalance(tradableCurrency)}
              </div>
            )
          })}
        </React.Fragment>
      )
    } else if (step.number === 3) {
      const investedAmount = Money.convertUnit(step.invested!, c.m_unit, c.s_unit, unitConfig!.selectedUnit)
      const selectedUnit = unitConfig.selectedUnit
      return (
        <div className="investor">
          <img alt="ninja" src="/assets/ninja-investor.svg" />
          <span>
            <Trans
              i18nKey="becomeInvestor.congratulationsMessage"
              values={{ investedAmount, selectedUnit: unitConfig.selectedUnit }}
            >
              Congratulations!
              <br />
              By investing {{ investedAmount }} {{ selectedUnit }} you are now a ninja investor.
              <br />
              Check your wallet every hour and see it grow
              <br />
              and take the profit.
            </Trans>
          </span>
          <button onClick={() => setStep({ number: 4 })}>
            {t('becomeInvestor.awesomeSeeYourMoneyWorking', 'Awesome, see your money working!')}
          </button>
        </div>
      )
    } else if (step.number === 4) {
      const getRows = () => {
        if (activityTab === ActivityTab.investments) {
          return myBankrolls?.getMyBankrolls
            .filter((x) => {
              if (currencyFilter) {
                return x.currency === currencyFilter
              }
              return true
            })
            .map((b) => {
              const cancel = () => {
                endBankroll({
                  variables: { input: { currency: b.currency } },
                  onCompleted: () => {
                    setBankrollToCancel(undefined)
                    updateBankrolls()
                    setUnitConfig({
                      allUnits: unitConfig.allUnits,
                      selectedUnit: unitConfig.selectedUnit,
                      balance: getBalance(unitConfig.allUnits[0].code, 0),
                    })
                  },
                })
              }
              if (b.insertedAt === bankrollToCancel) {
                return (
                  <tr key={'roll_c' + b.insertedAt}>
                    <td colSpan={3}>
                      <div className="cancel-roll">
                        <span className="cancel-1">
                          {t('becomeInvestor.cancelInvestmentMessage', 'You are going to cancel this investment')}
                        </span>
                        <span className="cancel-2">
                          {t('becomeInvestor.cancelConfirm', 'Are you sure about that?')}
                        </span>
                        <div className="c-buttons">
                          <button className="button-no uppercase" onClick={() => setBankrollToCancel(undefined)}>
                            {t('general.no', 'No')}
                          </button>
                          <button className="button-yes uppercase" onClick={() => cancel()}>
                            {t('general.yes', 'Yes')}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              }
              const c = ConfigurationService.instance.getCurrency(b.currency)
              const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
              const amount = Money.convertUnit(b.amount, c.m_unit, c.s_unit, format)
              return (
                <tr key={'roll_' + b.insertedAt}>
                  <td>
                    <div className="date">
                      {b.insertedAt.split(' ').map((x) => (
                        <span key={b.insertedAt + x}>{x}</span>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="amount">
                      <img alt={b.currency} src={getLogoForCoin(b.currency)} />
                      {amount}
                    </div>
                  </td>
                  <td>
                    <button onClick={() => setBankrollToCancel(b.insertedAt)}>
                      {t('becomeInvestor.cancelInvestment', 'Cancel Investment')}
                    </button>
                  </td>
                </tr>
              )
            })
        } else {
          return myBankrollPayments?.getMyBankrollPayments
            .filter((x) => {
              if (currencyFilter) {
                return x.currency === currencyFilter
              }
              return true
            })
            .map((b) => {
              const active = b.bankroll.active
              const c = ConfigurationService.instance.getCurrency(b.currency)
              const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
              const amount = Money.convertUnit(b.amount, c.m_unit, c.s_unit, format)
              return (
                <tr key={'earn_' + b.date + b.currency + b.amount}>
                  <td>
                    <div className="date">
                      {b.date.split(' ').map((x) => (
                        <span key={b.date + x}>{x}</span>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="amount">
                      <img alt={b.currency} src={getLogoForCoin(b.currency)} />
                      {amount.toFixed(8)}
                    </div>
                  </td>
                  <td>
                    <span className={active ? 'active' : 'inactive'}>
                      {active ? t('becomeInvestor.active', 'Active') : t('becomeInvestor.ended', 'Ended')}
                    </span>
                  </td>
                </tr>
              )
            })
        }
      }

      const getSummary = () => {
        if (!myBankrolls) {
          return
        }
        const data = myBankrolls.getMyBankrolls.filter((x) => {
          if (currencyFilter) {
            return x.currency === currencyFilter
          }
          return true
        })

        if (activityTab === ActivityTab.investments) {
          return data.map((x, i) => {
            const c = ConfigurationService.instance.getCurrency(x.currency)
            const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
            const amount = Money.convertUnit(x.amount, c.m_unit, c.s_unit, format)

            return (
              <div className="summary-row" key={'invst-' + i + x.insertedAt}>
                <span>{t('becomeInvestor.totalInvestments', 'Total Investments')}</span>
                <span className="currency">
                  <img alt={x.currency} src={getLogoForCoin(x.currency)} />
                  <span>
                    {amount}
                    {format}
                  </span>
                </span>
              </div>
            )
          })
        } else {
          return data.map((x, i) => {
            const c = ConfigurationService.instance.getCurrency(x.currency)
            const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
            const amount = Money.convertUnit(x.paidAmount, c.m_unit, c.s_unit, format)
            const lastEarned = myBankrollPayments?.getMyBankrollPayments[0]?.amount || 0
            const lastEarnedFormatted = Money.convertUnit(lastEarned, c.m_unit, c.s_unit, format)

            return (
              <React.Fragment>
                <div className="summary-row" key={'invst-' + i + x.insertedAt}>
                  <span>{t('becomeInvestor.totalEarnedFunds', 'Total Earned Funds')}</span>
                  <span className="currency">
                    <img alt={x.currency} src={getLogoForCoin(x.currency)} />
                    <span>
                      {amount}
                      {format}
                    </span>
                  </span>
                </div>
                {i === data.length - 1 ? (
                  <div className="period-row">
                    <span>
                      {lastEarnedFormatted}
                      {format}
                    </span>
                    <select value={myBankrollPeriod} onChange={(e) => setMyBankrollPeriod(e.target.value)}>
                      <option value="hourly">{t('becomeInvestor.hourlyIncome', 'Hourly income')}</option>
                      <option value="daily">{t('becomeInvestor.dailyIncome', 'Daily income')}</option>
                      <option value="monthly">{t('becomeInvestor.monthlyIncome', 'Monthly income')}</option>
                    </select>
                  </div>
                ) : null}
              </React.Fragment>
            )
          })
        }
      }

      return (
        <div className="activity">
          <div className="header">
            <div
              onClick={() => setActivityTab(ActivityTab.investments)}
              className={activityTab === ActivityTab.investments ? 'active' : ''}
            >
              {t('becomeInvestor.investments', 'Investments')}
            </div>
            <div
              onClick={() => setActivityTab(ActivityTab.earnings)}
              className={activityTab === ActivityTab.earnings ? 'active' : ''}
            >
              {t('becomeInvestor.earnings', 'Earnings')}
            </div>
          </div>
          {getSummary()}
          <table>
            <thead>
              <tr>
                {activityTab === ActivityTab.investments ? (
                  <React.Fragment>
                    <th>{t('becomeInvestor.time', 'Time')}</th>
                    <th className="amount-header">{t('becomeInvestor.amount', 'Amount')}</th>
                    <th>{t('becomeInvestor.returns', 'Returns')}</th>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <th>{t('becomeInvestor.time', 'Time')}</th>
                    <th className="amount-header">{t('becomeInvestor.amount', 'Amount')}</th>
                    <th>{t('becomeInvestor.status', 'Status')}</th>
                  </React.Fragment>
                )}
              </tr>
            </thead>
            <tbody>{getRows()}</tbody>
          </table>
        </div>
      )
    } else if (step.number === 5) {
      return (
        <div className="find-out">
          <span>
            <Trans i18nKey="becomeInvestor.findOutMorePage.earnWithBlacjackFun">
              Earn with blackjack.fun by staking your coins. Instead of just keeping your funds on your wallet, make
              them work for you. You can join instantly and leave whenever you want. Rewards are distributed every hour.
            </Trans>
          </span>
          <p className="limit">
            <Trans i18nKey="becomeInvestor.findOutMorePage.offerLimited">
              This offer is limited to 20 BTC. Once the cap is reached, new players won't be able to join.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="becomeInvestor.findOutMorePage.heading1">What is the concept about?</Trans>
          </p>
          <span>
            <Trans i18nKey="becomeInvestor.findOutMorePage.conceptIdeology">
              It has been our ideology from the beginning that we want to build this platform around our community.
              After a few ideas, we managed to create this concept, where any player can stake their wallet for an
              undetermined period of time, and gain a constant flow of crypto based on the amount staked.
            </Trans>
          </span>
          <p>
            <Trans i18nKey="becomeInvestor.findOutMorePage.heading2">How it works?</Trans>
          </p>
          <span>
            <Trans i18nKey="becomeInvestor.findOutMorePage.howItWorksDetails">
              To stake the amount you desire, all you need to do is to select the type of coin and then select the unit.
              After that you will see the amounts of returns calculated for your input. You press the “Stake my coins”
              button and that's it. Every hour you will receive back a percentage of the amount you entered. The bigger
              the amount the better the return. You can check the status of your investments by accessing the activity
              dashboard. In the investments tab you can see the invested coins and the option to disconnect from
              staking. In the “Earnings” tab you can see real-time the income based on hours, days, and months.
            </Trans>
          </span>
          <p>
            <Trans i18nKey="becomeInvestor.findOutMorePage.heading3">Risk free investment</Trans>
          </p>
          <span>
            <Trans i18nKey="becomeInvestor.findOutMorePage.riskFreeInvestmentDetails">
              Yes, that is right! Your coins will not be active on the casino pool, hence they are protected from any
              form of danger.
            </Trans>
          </span>
        </div>
      )
    } else {
      return <div />
    }
  }

  const header = () => {
    switch (step.number) {
      case 1:
        return t('becomeInvestor.makeYourMoneyWorkForYou', 'Make your money work for you.')
      case 2:
        return t('becomeInvestor.assets', 'Assets')
      case 3:
        return t('becomeInvestor.youAreNowInvestor', 'You are now an investor')
      case 4:
        return t('becomeInvestor.activity', 'Activity')
      case 5:
        return t('becomeInvestor.findOutMore', 'Find out more')
    }
  }

  return (
    <div className="bankroll-container">
      <div className="bankroll-top-bar">
        {step.number !== 1 ? (
          <FontAwesomeIcon
            className="cursor"
            icon={faArrowLeft}
            size="1x"
            onClick={() => (step.back ? step.back(undefined) : setStep({ number: 1 }))}
          />
        ) : null}
        <span className="header">{header()}</span>
        <div onClick={closeCallback}>
          <FontAwesomeIcon className="close-modal" icon={faClose} size="2x" onClick={closeCallback} />
        </div>
      </div>
      <div className="bankroll-inner-container">{getBody()}</div>
      {step.number === 4 ? (
        <div className="filter">
          <span className="label">{t('becomeInvestor.filterCoins', 'Filter coins')}</span>
          <div
            className="selector"
            onClick={() =>
              setStep({
                number: 2,
                back: (newCoin) => {
                  setStep({ number: 4 })
                  if (newCoin) {
                    setCurrencyFilter(newCoin)
                  }
                },
              })
            }
          >
            <span>
              {currencyFilter ? (
                <span>
                  <img alt={'filter'} src={getLogoForCoin(currencyFilter)} />
                  {currencyFilter}
                </span>
              ) : (
                t('becomeInvestor.allAssets', 'All assets')
              )}
            </span>
            <FontAwesomeIcon icon={faCaretDown} size="1x" />
          </div>
        </div>
      ) : null}
    </div>
  )
}

const addBankrollMutation = graphql`
  mutation BecomeAnInvestorAddBankrollMutation($input: AddBankrollInput!) {
    addBankroll(input: $input) {
      resp {
        currency
        active
        percent
        meta
        amount
        paidAmount
        insertedAt
      }
    }
  }
`

const endBankrollMutation = graphql`
  mutation BecomeAnInvestorEndBankrollMutation($input: EndBankrollInput!) {
    endBankroll(input: $input) {
      resp {
        insertedAt
      }
    }
  }
`

const listBankrolls = graphql`
  query BecomeAnInvestorGetMyBankrollsQuery {
    getMyBankrolls {
      currency
      active
      percent
      meta
      amount
      paidAmount
      insertedAt
    }
  }
`

const listBankrollPayments = graphql`
  query BecomeAnInvestorGetMyBankrollPaymentsQuery($period: String!, $currency: Currency) {
    getMyBankrollPayments(period: $period, currency: $currency) {
      date
      amount
      currency
      bankroll {
        active
      }
    }
  }
`

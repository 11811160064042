import './LoginLinkComponent.scoped.scss'
import { useTranslation } from 'react-i18next'
import { open } from '@src/reducers/authModal.reducer'
import { useDispatch } from 'react-redux'

export const LoginLinkComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <>
      <div className="login-link">
        <button
          className="uppercase"
          type="button"
          onClick={() => {
            dispatch(open({ tab: 'Join Us' }))
          }}
        >
          {t('authentication.joinUs', 'Join Us')}
        </button>
        <button
          className="uppercase"
          type="button"
          onClick={() => {
            dispatch(open({ tab: 'Login' }))
          }}
        >
          {t('authentication.logIn', 'Login')}
        </button>
      </div>
    </>
  )
}

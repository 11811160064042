/**
 * @generated SignedSource<<806c8aa7c78e5a5a340860cac2288c7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type generateOtpSecretMutation$variables = {};
export type generateOtpSecretMutation$data = {
  readonly generateOtpSecret: {
    readonly url: string;
  } | null;
};
export type generateOtpSecretMutation = {
  variables: generateOtpSecretMutation$variables;
  response: generateOtpSecretMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GenerateOtpSecretPayload",
    "kind": "LinkedField",
    "name": "generateOtpSecret",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "generateOtpSecretMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "generateOtpSecretMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3b8b237bdbf9018b84c492b4b4ef2a97",
    "id": null,
    "metadata": {},
    "name": "generateOtpSecretMutation",
    "operationKind": "mutation",
    "text": "mutation generateOtpSecretMutation {\n  generateOtpSecret {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "888f94ff49d5c8500e3bd9c076b3724a";

export default node;

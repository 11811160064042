import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Autoplay, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import type SwiperCore from 'swiper'

import { useIsMobile } from '@src/hooks'
import sanityClient from '@src/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

import classes from './topGames.module.scss'
import { Link } from 'react-router-dom'
import { GameCard } from './gameCard'
import useIntersectionObserver from '@src/hooks/useIntersectionObserver'
import SkeletonLoader from './SkeletonLoader'
import CarouselHeader from '../Elements/CarouselHeader'
import DealerIcon from '../icons/dealer'
import CardsIcon from '../icons/cards'
import CherryIcon from '../icons/cherry'

// const filters = ['Popular', 'Recommended', 'Big Wins'] as const
const filters = ['Popular']

export default function TopGames({ category }: { category?: 'live' | 'slots' }) {
  const [selected, setSelected] = useState<typeof filters[number]>('Popular')

  const [games, setGames] = useState<
    Array<{
      _id: string
      _type: string
      order: number
      header: string
      image: string
      gameLink: string
    }>
  >([])

  const isMobile = useIsMobile()
  const swiperRef = useRef<SwiperCore | null>(null)
  const [loading, setLoading] = useState(true)
  const componentRef = useRef(null) // This ref is used for observing the component visibility
  const hasFetchedRef = useRef(false) // Control flag to prevent multiple fetches
  const { t } = useTranslation()

  const isVisible = useIntersectionObserver({
    target: componentRef,
    onIntersect: () => {},
    enabled: true,
    rootMargin: '175px',
  })

  useEffect(() => {
    if (isVisible && !hasFetchedRef.current) {
      fetchData()
      hasFetchedRef.current = true // Indicate that data has been fetched
    }
  }, [isVisible])

  function fetchData() {
    let base: string = 'topGames'
    if (category === 'live') {
      base = 'topGamesLive'
    } else if (category === 'slots') {
      base = 'topGamesSlots'
    }

    let query = `*[_type == "${base}"]`

    if (selected === 'Popular') {
      query = `*[_type == "${base}"]`
    } else if (selected === 'Recommended') {
      query = `*[_type == "${base}_Recommended"]`
    } else if (selected === 'Big Wins') {
      query = `*[_type == "${base}_BigWins"]`
    } else {
      query = `*[_type == "${base}"]`
    }

    sanityClient.fetch(query).then((res) => {
      const fetchedGames = res
        .filter((g) => !g?._id.startsWith('drafts.'))
        .map((g) => ({ ...g, image: imageUrlBuilder(sanityClient).image(g.image).url() }))
        .sort((a, b) => (a.order > b.order ? 1 : -1))
      setGames(fetchedGames)
      setLoading(false)
    })
  }

  let headerText = t('carouselHeading.topGames', 'Top Games')
  let headerLinkHref = '/games'
  let headerIcon = <CardsIcon className={'w-16 h-16 text-carouselHeading'} />
  let uniqueId = 'games'
  if (category === 'live') {
    headerText = t('carouselHeading.topLiveGames', 'Top Live Games')
    headerLinkHref = '/live-games'
    uniqueId = 'live-games'
    headerIcon = <DealerIcon className={'w-16 h-16 text-carouselHeading'} />
  }

  if (category === 'slots') {
    headerText = t('carouselHeading.topSlotGames', 'Top Slot Games')
    headerLinkHref = '/slots'
    uniqueId = 'slots'
    headerIcon = <CherryIcon className={'w-16 h-16 text-carouselHeading'} />
  }

  return (
    <div className={classes.root} ref={componentRef}>
      <CarouselHeader
        text={headerText}
        swiperRef={swiperRef}
        linkHref={headerLinkHref}
        icon={headerIcon}
        uniqueId={uniqueId}
      />
      <div className="pb-10 overflow-hidden">
        {loading ? (
          <SkeletonLoader />
        ) : (
          <Swiper
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            mousewheel={{ forceToAxis: true }}
            modules={[Autoplay, Mousewheel, Navigation]}
            spaceBetween={14}
            centerInsufficientSlides
            centeredSlidesBounds
            slidesPerView={isMobile ? 3 : 'auto'}
            onInit={(core: SwiperCore) => {
              swiperRef.current = core
            }}
            navigation={{
              prevEl: `.${uniqueId}-swiper-custom-button-prev`,
              nextEl: `.${uniqueId}-swiper-custom-button-next`,
            }}
          >
            {games.map((g) => (
              <SwiperSlide key={g._id} className={classes.slide}>
                {isMobile ? (
                  <Link to={g.gameLink}>
                    <img alt="" src={g.image} />
                  </Link>
                ) : (
                  <GameCard gameURL={g.gameLink} gameImageSRC={g.image} />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

import classNames from 'classnames'
import { getUserIcon } from '@src/App'

import classes from './Chat.module.scss'
import { AdminActions } from './AdminActions'
import { User } from '@src/reducers/authentication.reducer'
import { ChatSupport } from './ChatSupport'
import { Rain } from './Rain'
import { Competition } from './Competition'
import { CodeMaster } from './CodeMaster'
import { MultiplayerRewardV2 } from './MultiplayerRewardV2'
import { ParticipationReward } from './ParticipationReward'

const getAllUrlsFromString = (text: string): URL[] => {
  const words = text.split(/\s+/)
  const urls: URL[] = []

  words.forEach((word) => {
    try {
      const url = new URL(word)
      if (url.protocol === 'http:' || url.protocol === 'https:') {
        urls.push(url)
      }
    } catch (e) {
      // ignore
    }
  })

  return urls
}

const getFormattedText = (text: string): string => {
  const urls = getAllUrlsFromString(text)

  let formattedText = text

  urls.forEach((url) => {
    formattedText = formattedText.replace(
      url.toString(),
      `<a href="${url}" class="hover:underline text-pink font-bold" target="_blank">${url}</a>`
    )
  })

  return formattedText
}

export function Message({
  me,
  from,
  from_uuid,
  hash,
  has_profile_image,
  vip,
  from_admin,
  from_muted_user,
  messages,
  setMessages,
  handleMessageDelete,
  className,
}: {
  me?: User
  from: string
  from_uuid: string
  hash: string
  has_profile_image: boolean
  vip: any
  from_admin: boolean
  from_muted_user?: boolean
  messages: any[]
  setMessages: React.Dispatch<React.SetStateAction<any[]>>
  handleMessageDelete: (hash: string) => void
  className?: string
}) {
  const isModerator = me?.roles?.includes('moderator')

  const shouldHideUser = messages.some(
    (msg) =>
      msg.type === 'mp_reward' ||
      msg.type === 'mp_reward_v2' ||
      msg.type === 'tournament_participation_rewards' ||
      msg.type === 'chat_support'
  )

  return (
    <div className={classNames(classes.message, 'flex flex-col gap-y-8', className)}>
      {
        <div
          className={classNames('text-white font-bold font-inter relative flex items-center', {
            hidden: shouldHideUser,
          })}
          style={{
            color: '#27C059',
            fontSize: '13px',
          }}
        >
          <div className="h-28 w-28 rounded-full">
            {getUserIcon(from, from_uuid, has_profile_image, vip, from_admin)}
          </div>
          <span className="relative flex items-center px-8">
            {from}
            {isModerator && (
              <AdminActions
                hash={hash}
                setMessages={setMessages}
                from_uuid={from_uuid}
                from_muted_user={from_muted_user}
                hideDelete={messages.length > 1}
                handleMessageDelete={handleMessageDelete}
              />
            )}
          </span>
        </div>
      }

      <div className="text-white flex flex-col gap-y-6">
        {messages.map((msg, j) => {
          if (msg.type === 'chat_support') {
            const data = JSON.parse(msg.msg)
            return <ChatSupport key={j} data={data} />
          }

          if (msg.type === 'code_master') {
            const data = JSON.parse(msg.msg)
            return <CodeMaster key={j} data={data} className="mx-0" />
          }

          if (msg.type === 'competition') {
            const competition = JSON.parse(atob(msg.msg)) as {
              name: string
              type: string
              players: { idx: number; name: string }[]
            }

            if (competition.type === 'make_it_rain') {
              const rain = competition as unknown as {
                uuid: string
                from: string
                message: string
                amount: number
                currency: string
                player_count: number
                has_profile_image: boolean
              }
              return <Rain key={j} rain={rain} />
            }

            return <Competition key={j} competition={competition} />
          }

          if (msg.type === 'mp_reward') {
            return null
          }

          if (msg.type === 'mp_reward_v2') {
            const style: React.CSSProperties = {
              maxWidth: 'max-content',
            }
            return <MultiplayerRewardV2 key={j} msg={msg} style={style} />
          }

          if (msg.type === 'tournament_participation_rewards') {
            const style: React.CSSProperties = {
              maxWidth: 'max-content',
            }
            return <ParticipationReward key={j} msg={msg} style={style} />
          }

          let message = msg.msg

          return (
            <div
              key={j}
              className={classNames('flex flex-col relative justify-center pl-32', {
                'max-h-150': message.endsWith('giphy.webp'),
              })}
            >
              {isModerator && messages.length > 1 && (
                <button
                  type="button"
                  className="absolute left-0"
                  onClick={() => {
                    handleMessageDelete(hash)
                  }}
                >
                  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.90303 0L5.93939 2.61914H6.03636L8.09697 0H11.8545L8.14546 4.5L12 9H8.14546L6.03636 6.32813H5.93939L3.8303 9H0L3.80606 4.5L0.121212 0H3.90303Z"
                      fill="#F3177B"
                    />
                  </svg>
                </button>
              )}
              {message.endsWith('giphy.webp') ? (
                <img
                  className="px-22 py-12 h-full"
                  style={{
                    fontSize: '13px',
                    borderRadius: '7px',
                    backgroundColor: 'rgba(228, 228, 228, 0.10)',
                  }}
                  alt="Giphy"
                  src={message}
                />
              ) : (
                <p
                  dangerouslySetInnerHTML={{ __html: getFormattedText(message) }}
                  className="px-22 py-12 whitespace-pre-line font-inter font-medium"
                  style={{
                    fontSize: '13px',
                    borderRadius: '7px',
                    backgroundColor: 'rgba(228, 228, 228, 0.10)',
                    maxWidth: 'fit-content',
                  }}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

import classNames from 'classnames'
import { useTranslation, Trans } from 'react-i18next'

import { getUserIcon } from '@src/App'

import classes from './GrabGiveaway.module.scss'
import { ModalType, openModalTask } from '../modalComponent/modalComponent'
import { useSelector } from 'react-redux'
import { IStore, store } from '@src/store'
import { AppTypes } from '@src/reducers/app.reducer'
import Login from '../authentication/Login/Login'
import { isUserLoggedIn } from '@src/utils/functions'
import { Money } from '@src/money'

export function GrabGiveaway({ isLoadingGrabGiveaway }: { isLoadingGrabGiveaway: boolean }) {
  const { t } = useTranslation()
  const me = useSelector((state: IStore) => state.authentication.user)
  const giveaways = useSelector((state: IStore) => state.chat.giveaways)

  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  const unusedGiveaways = giveaways.filter(
    (giveaway) => me?.uuid && giveaway.meta && !Object.keys(giveaway.meta as {}).includes(me.uuid)
  )

  const isLoggedIn = isUserLoggedIn()

  return (
    <div
      className="absolute bg-eerieBlack z-20 py-22 px-26 w-full flex flex-col gap-y-8"
      style={{
        bottom: 0,
        transform: 'translateY(100%)',
      }}
    >
      <div
        className="flex flex-col justify-center items-center pt-22 pb-28"
        style={{
          backgroundColor: 'rgba(228, 228, 228, 0.10)',
          borderRadius: '7px',
        }}
      >
        <div
          className={classes.imageWrapper}
          style={{
            width: '86px',
            height: '86px',
          }}
        >
          {getUserIcon(
            unusedGiveaways[0].user.name,
            unusedGiveaways[0].user.uuid,
            unusedGiveaways[0].user.hasProfileImage
          )}
        </div>
        {/* <img
          alt=""
          src="/assets/make_it_rain_logo_header.png"
          style={{
            width: '86px',
            height: '86px',
          }}
          className="rounded-full"
        /> */}
        <span
          className="font-bold text-xl"
          style={{
            color: '#27C059',
          }}
        >
          {unusedGiveaways[0].user.name}
        </span>
        <p
          className="font-medium text-white"
          style={{
            fontSize: '13px',
          }}
        >
          <Trans
            i18nKey="chat.grabGiveaway.created"
            values={{
              amount: Money.fromInteger(unusedGiveaways[0].amount, unusedGiveaways[0].currency),
              currency: unusedGiveaways[0].currency,
              usageLimit: unusedGiveaways[0].usageLimit,
            }}
            components={{
              0: <span className="font-black text-pink" />,
              1: <span className="font-black text-pink" />,
            }}
          />
        </p>
      </div>
      <button
        type="button"
        style={{
          borderRadius: '7px',
        }}
        className={classNames('font-bold text-xl py-12 px-22 text-white', {
          'bg-brightYellow': isLoadingGrabGiveaway,
          'bg-lavenderIndigo': !isLoadingGrabGiveaway,
        })}
        onClick={() => {
          if (!isLoggedIn) {
            setModalView(<Login />)
            return
          }

          const giveaway = unusedGiveaways[0]

          openModalTask.next({
            type: ModalType.shareWithFriends,
            payload: {
              giveaway: giveaway,
            },
          })
        }}
      >
        {isLoadingGrabGiveaway
          ? t('chat.grabGiveaway.youClaimedThisGiveaway', 'You claimed this giveaway')
          : t('chat.grabGiveaway.grab', 'Grab')}
      </button>
    </div>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { formatAmount, formatBalance, Wallet } from '../../reducers/wallet.reducer'
import ConfigurationService from '../../service/ConfigurationService'
import { store, IStore } from '../../store'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import { formatFiat } from '../rightSide/walletComponent/WalletComponent'
import Activity from '../icons/activity'
import './CoinsView.scoped.scss'

import { Tab } from './WalletModal'
import { Link } from 'react-router-dom'

export const CoinsView = ({
  setPopup,
  setTab,
  closeWalletModal,
}: {
  setPopup: (popup: any) => void
  setTab: React.Dispatch<React.SetStateAction<Tab>>
  closeWalletModal: () => void
}) => {
  const { t } = useTranslation()
  const me = useSelector((s: IStore) => s.authentication.user)
  const walletState = useSelector((state: IStore) => state.wallet)
  const currencies = Object.keys(ConfigurationService.instance.getTradeableCurrencies())

  const getBonusWallets = (currency: string = '') => {
    return walletState.wallets.filter(
      (x) =>
        (currency === '' || x.currency.toLowerCase() === currency.toLowerCase()) &&
        x.virtualId !== 0 &&
        x.balance > 0 &&
        x?.campaign?.type === 'global_playthrough'
    )
  }

  const balance = (currency: string) => {
    const wallets = getBonusWallets(currency)

    let newWallet: Wallet = {
      balance: 0,
      currency: wallets.length > 0 ? wallets[0].currency : '',
      virtual: true,
      virtualId: 0,
    }
    wallets.forEach((w) => {
      newWallet = {
        balance: newWallet.balance + w.balance,
        currency: newWallet.currency,
        virtual: true,
        virtualId: 0,
      }
    })

    if (wallets.length > 0) {
      return (
        <span className="balance-block">
          <span>{formatBalance(newWallet, true, false)}</span>
          {formatFiat(store.getState().wallet.rates, newWallet)}
        </span>
      )
    }
    return 0
  }

  const bonusWallets = getBonusWallets().sort((a, b) => {
    if (a.virtualId < b.virtualId) {
      return -1
    }
    if (a.virtualId > b.virtualId) {
      return 1
    }
    return 0
  })

  const lastBonusWallet = bonusWallets.length > 0 ? bonusWallets?.[0] : null

  const getWalletSource = (wallet: Wallet) => {
    const meta = wallet.campaign?.meta
    let source: string | null = null
    if (meta?.source) source = meta.source

    switch (source) {
      case 'rain':
        source = t('wallet.coinsView.chatRain', 'Chat rain')
        break
      case 'freespin':
        source = t('wallet.coinsView.freeSpins', 'Free spins')
        break
    }
    return source
  }

  const noBonus =
    !lastBonusWallet ||
    lastBonusWallet?.campaign?.payoutMultiplyer == null ||
    lastBonusWallet?.campaign?.userInCampaign?.amount == null ||
    lastBonusWallet?.campaign?.userInCampaign?.betSum == null

  const isKYCDone = me?.kycState === 'idle' && Boolean(me?.kycSuccessful)
  const data = () => {
    if (
      !lastBonusWallet ||
      lastBonusWallet?.campaign?.payoutMultiplyer == null ||
      lastBonusWallet?.campaign?.userInCampaign?.amount == null ||
      lastBonusWallet?.campaign?.userInCampaign?.betSum == null
    )
      return null

    const target = lastBonusWallet.campaign.payoutMultiplyer * lastBonusWallet.campaign.userInCampaign.amount
    const betSum = lastBonusWallet.campaign.userInCampaign.betSum
    const percent = Math.round((betSum / target) * 10000) / 100
    const source = getWalletSource(lastBonusWallet)

    return (
      <div className="data">
        <div className="line">
          <Trans i18nKey="wallet.coinsView.sourceOfBonus" values={{ source }} components={{ 1: <span /> }}>
            Source of Bonus <span>{{ source }}</span>
          </Trans>
        </div>
        <div className="line">
          {t('wallet.coinsView.bonusAmount', 'Bonus Amount')}{' '}
          <span>
            {formatAmount(lastBonusWallet.campaign?.userInCampaign?.amount ?? 0, lastBonusWallet.currency, true)}
          </span>
        </div>
        <div className="line">
          <Trans i18nKey="wallet.coinsView.statusUnlocked" values={{ percent }}>
            Status <span>{{ percent }}% Unlocked</span>
          </Trans>
        </div>
        <div className="statusValue">
          <div style={{ width: `${percent}%` }} />
        </div>
        <div className="info">
          {formatAmount(target - betSum, lastBonusWallet.currency, true)}{' '}
          {t('wallet.coinsView.wagerToUnlock', 'Wager Remaining to unlock')}
        </div>
        {bonusWallets.length > 0 && (
          <div className="nextBonuses">
            {bonusWallets.map((w) => (
              <div className="next">
                {t('wallet.coinsView.nextBonus', 'Next Bonus')} {getWalletSource(w)}{' '}
                {formatAmount(w.campaign?.userInCampaign?.amount ?? 0, w.currency, true)}{' '}
                <span className="uppercase">{t('wallet.coinsView.locked', 'Locked')}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      {data()}
      <div className="body">
        {noBonus ? (
          <div className="no-bonus">
            <img alt="" src="/assets/wallet/figure.png" />
            <p>{t('wallet.coinsView.noBonuses', 'It seems that you don’t have any bonuses at the moment.')}</p>
            <div className="">
              <button type="button" className="uppercase" onClick={() => setTab(Tab.wallet)}>
                {t('general.deposit', 'Deposit')}
              </button>
            </div>
          </div>
        ) : (
          <div className="list">
            {currencies.map((x) => {
              const b = balance(x)
              if (b === 0) return null

              return (
                <div
                  key={'wallet-list-x' + x}
                  className={`row has-funds ${walletState.activeWallet?.currency === x ? 'active' : ''}`}
                >
                  <div className="flex">
                    <img className="coin-image" alt={x} src={getLogoForCoin(x)} />
                    <span className="coin">{x}</span>
                  </div>
                  <div className="balance">{b}</div>
                  <div className="icon">
                    <Activity />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

import React from 'react'
import { BonusBalanceView } from './WalletModal'
import { CoinsView } from './CoinsView'
import { FreeSpinsView } from './FreeSpinsView'
import './BonusBalanceTab.scoped.scss'

export const BonusBalanceTab = ({ view, openView, activeView, setPopup, setTab, closeWalletModal }) => {
  const renderBody = () => {
    switch (view) {
      case BonusBalanceView.coins:
        return <CoinsView setPopup={setPopup} setTab={setTab} closeWalletModal={closeWalletModal} />
      case BonusBalanceView.freeSpins:
        return <FreeSpinsView />
    }
  }

  return (
    <>
      {/* <div className="nav">
        <button
          className={activeView() === BonusBalanceView.coins ? 'active' : ''}
          onClick={() => openView(BonusBalanceView.coins)}
        >
          Coins
        </button>
        <button
          className={activeView() === BonusBalanceView.freeSpins ? 'active' : ''}
          onClick={() => openView(BonusBalanceView.freeSpins)}
        >
          Free Spins
        </button>
      </div> */}
      <div>{renderBody()}</div>
    </>
  )
}

import { useRef, useEffect } from 'react'
import { History } from 'history'
import PropTypes from 'prop-types'
import { paramCase as kebabCase } from 'change-case'

import { ExtendedGame, Game } from '@components/gameListComponent/GameListComponent.types'

export const getGameBackgroundURL = (game: Game) => {
  if (game.provider === 'blackjackfun') {
    const g = game as unknown as ExtendedGame

    if (g.identifier === 'redorblack') {
      return '/assets/in_house_rb_container.png'
    } else if (g.identifier === 'blackjack') {
      return '/assets/in_house_bj_container.png'
    } else if (g.identifier === 'holdem') {
      return '/assets/th.png'
    }
  }

  if (game.provider === 'endorphina') {
    return `/assets/games/endorphina/${kebabCase(game.label)}.png`
  }

  const g = game as unknown as ExtendedGame

  let gameId = g.identifier?.split(':')[1]
  // if (g.provider === 'softswiss') {
  //   gameId = g.identifier2 as string
  // }

  return `https://cdn.softswiss.net/i/s2/${g.provider}/${gameId}.png`
}

export const openGame = (history: History, game: Game) => {
  if (!game.gameId) {
    const g = game as unknown as ExtendedGame

    history.push(`/game/${g.identifier}`)
    return
  }

  if (game.gameId === 'blackjack') {
    history.push(`/game/bitcoin-blackjack`)
    return
  }

  if (game.provider === 'endorphina') {
    history.push(`/game/${game.gameId}`)
    return
  }

  history.push(`/game/${game.provider}:${game.gameId}`)
}

export const ourGames = [
  {
    label: 'Blackjack',
    urlThumb: '/assets/in_house_bj_container.png',
    category: 'card',
    gameId: 'blackjack',
    provider: 'bjfun',
    producer: 'bjfun',
    description: '',
  },
  // {
  //   label: 'Red or Black',
  //   urlThumb: '/assets/in_house_rb_container.png',
  //   category: 'casual',
  //   gameId: 'redorblack',
  //   provider: 'bjfun',
  //   producer: 'bjfun',
  //   description: '',
  // },
  // {category: 'poker', identifier: 'holdem', provider: 'blackjackfun', title: 'Holdem', payout: '100', identifier2: '', description: ''},
]

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export const OutsideAlerter = (props) => {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, props.callback)

  return <div ref={wrapperRef}>{props.children}</div>
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  callback: PropTypes.func.isRequired,
}

/**
 * @generated SignedSource<<8a9f240acdc7a9a723498da441e5f75f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type walletWithdrawAddedSubscription$variables = {};
export type walletWithdrawAddedSubscription$data = {
  readonly withdrawAdded: {
    readonly amount: number;
    readonly currency: Currency;
    readonly isProcessed: boolean;
    readonly insertedAt: string;
    readonly txid: ReadonlyArray<string> | null;
    readonly uuid: string;
    readonly meta: any;
  };
};
export type walletWithdrawAddedSubscription = {
  variables: walletWithdrawAddedSubscription$variables;
  response: walletWithdrawAddedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Withdraw",
    "kind": "LinkedField",
    "name": "withdrawAdded",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isProcessed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "txid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "walletWithdrawAddedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "walletWithdrawAddedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8c3b3a786c99d5bad0b52b5272051c67",
    "id": null,
    "metadata": {},
    "name": "walletWithdrawAddedSubscription",
    "operationKind": "subscription",
    "text": "subscription walletWithdrawAddedSubscription {\n  withdrawAdded {\n    amount\n    currency\n    isProcessed\n    insertedAt\n    txid\n    uuid\n    meta\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8b0d8819015e6e9dd526288aec8d1bf";

export default node;

import { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-relay'
import { useTranslation } from 'react-i18next'

import { generateOtpSecretMutation } from './gql/generateOtpSecret.gql'
import { enableOtpMutation } from './gql/enableOtp.gql'
import { disableOtpMutation } from './gql/disableOtp.gql'

import type { enableOtpMutation as EnableOtpMutation } from './gql/__generated__/enableOtpMutation.graphql'
import type { disableOtpMutation as DisableOtpMutation } from './gql/__generated__/disableOtpMutation.graphql'
import type { generateOtpSecretMutation as GenerateOtpSecretMutation } from './gql/__generated__/generateOtpSecretMutation.graphql'
import { GraphQLError } from '@src/RelayEnvironment'
import QRCode from 'react-qr-code'

import classes from './Profile2FA.module.scss'
import { IStore, store } from '@src/store'
import { UserConstant } from '@src/reducers/authentication.reducer'
import formatError from '@src/components/errorFormatter'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

export function Profile2FA() {
  const { t } = useTranslation()
  const me = useSelector((s: IStore) => s.authentication.user)
  const [code, setCode] = useState('')
  const [message, setMessage] = useState('')
  const [otpURL, setOTPURL] = useState<string>('')

  const otpEnabled = !!me?.otpEnabled

  const [enableOtp] = useMutation<EnableOtpMutation>(enableOtpMutation)
  const [disableOtp] = useMutation<DisableOtpMutation>(disableOtpMutation)
  const [generateOtpSecret] = useMutation<GenerateOtpSecretMutation>(generateOtpSecretMutation)

  const getOptSecret = useCallback(() => {
    setMessage('')
    generateOtpSecret({
      variables: {},
      onCompleted: (data) => {
        if (data.generateOtpSecret?.url) {
          setOTPURL(data.generateOtpSecret.url)
        }
      },
      onError: (e: GraphQLError) => {},
    })
  }, [generateOtpSecret])

  const handleEnablingOtp = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.preventDefault()
      event?.stopPropagation()
      if (!code) {
        return
      }
      setMessage('')
      enableOtp({
        variables: { input: { code } },
        onCompleted: (result) => {
          if (result.enableOtp?.user) {
            store.dispatch({ type: UserConstant.USER_UPDATED, user: result.enableOtp.user })
            setMessage('')
            setCode('')
            toast.success(t('profile.profile2FA.2FAEnabledSuccess', '2FA enabled successfully'))
          }
        },
        onError: (e: GraphQLError) => {
          const errorMessage = formatError(e)[0]
          setMessage(errorMessage)
          toast.error(errorMessage)
        },
      })
    },
    [code, enableOtp, t]
  )

  const handleDisablingOtp = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.preventDefault()
      event?.stopPropagation()
      if (!code) {
        return
      }
      setMessage('')
      disableOtp({
        variables: { input: { code } },
        onCompleted: (result) => {
          if (result.disableOtp?.user) {
            store.dispatch({ type: UserConstant.USER_UPDATED, user: result.disableOtp.user })
            setMessage(t('profile.profile2FA.2FADisabled', '2FA is disabled now'))
            toast.success(t('profile.profile2FA.2FADisabled', '2FA is disabled now'))
          }
        },
        onError: (e: GraphQLError) => {
          const errorMessage = formatError(e)[0]
          setMessage(errorMessage)
          toast.error(errorMessage)
        },
      })
    },
    [code, disableOtp, t]
  )

  useEffect(() => {
    getOptSecret()
  }, [getOptSecret, disableOtp])

  return (
    <div className={classes.root}>
      <h3>{t('profile.profile2FA.enable2FA', 'Enable 2FA')}</h3>
      <div className={classes.container}>
        <QRCode size={186} value={otpURL} className="flex flex-shrink-0" />
        <div className={classes.actionWrapper}>
          <span
            className={classNames({
              [classes.enabled]: otpEnabled,
            })}
          >
            {otpEnabled ? t('profile.profile2FA.2FAEnabled', '2FA is enabled.') : otpURL}
          </span>
          <div className="flex max-w-xl gap-x-20 w-full">
            <input
              name="otp"
              placeholder={t('profile.profile2FA.enterOTP', 'Enter one time password.')}
              className="w-1/2"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button type="button" className="w-1/2" onClick={otpEnabled ? handleDisablingOtp : handleEnablingOtp}>
              {otpEnabled ? t('profile.profile2FA.disable', 'Disable') : t('profile.profile2FA.enable', 'Enable')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

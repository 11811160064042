import { Howl } from 'howler'
import { getUserPropOr } from './components/SettingsComponent/SettingsComponent'
import { UserProp } from './reducers/authentication.reducer'

enum Sound {
  BUTTON_CLICK,
  CHIP_SELECT,
  DEAL,
  CHIP_SOUND,
  CHIP_MOVE,
  TICK,
}

class SoundManager {
  public static instance: SoundManager = new SoundManager()
  private sounds: { [id: string]: Howl } = {}

  private constructor() {
    this.sounds[Sound[Sound.BUTTON_CLICK]] = new Howl({
      src: ['/assets/sound1.mp3', '/assets/sound1.webm', '/assets/sound1.m4a'],
    })
    this.sounds[Sound[Sound.CHIP_SELECT]] = new Howl({
      src: ['/assets/sound2.mp3', '/assets/sound2.webm', '/assets/sound2.m4a'],
    })
    this.sounds[Sound[Sound.DEAL]] = new Howl({
      src: ['/assets/sound4.mp3', '/assets/sound4.webm', '/assets/sound4.m4a'],
    })
    this.sounds[Sound[Sound.CHIP_SOUND]] = new Howl({
      src: ['/assets/chip_sound_1_3.mp3', '/assets/chip_sound_1_3.webm', '/assets/chip_sound_1_3.m4a'],
      sprite: {
        first: [100, 600],
        second: [1100, 1150],
        third: [2250, 2600],
      },
    })
    this.sounds[Sound[Sound.CHIP_MOVE]] = new Howl({
      src: ['/assets/chip_move.mp3', '/assets/chip_move.webm', '/assets/chip_move.m4a'],
    })
    this.sounds[Sound[Sound.TICK]] = new Howl({
      src: ['/assets/tick.wav', '/assets/tick.webm', '/assets/tick.m4a'],
    })
  }

  public playSound(sound: Sound) {
    if (this.isSoundEnabled()) {
      this.sounds[Sound[sound]].play()
    }
  }

  public playSpriteSound(sound: Sound) {
    if (this.isSoundEnabled()) {
      let sprite
      switch (sound) {
        case Sound.CHIP_SOUND: {
          sprite = ['first', 'second', 'third'][Math.floor(Math.random() * 3)]
          break
        }
        default: {
          console.error('Unknown sound: ' + sound)
        }
      }
      this.sounds[Sound[sound]].play(sprite)
    }
  }

  private isSoundEnabled() {
    return getUserPropOr(UserProp.SOUND_ENABLED, 'true') === 'true'
  }
}
const instance = SoundManager.instance
export default instance
export { Sound }

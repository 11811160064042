import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import useIsMobile from '../../hooks/IsMobile'
import Discord from '../icons/discord'
import FacebookWhite from '../icons/facebookWhite'
import Instagram from '../icons/instagram'
import Twitter from '../icons/twitter'
import TelegramWhite from '../icons/telegramWhite'
import { IFooterLogo } from './Footer.interface'
import styles from './Footer.module.scss'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const FooterLogo: React.FC<IFooterLogo> = ({ image, className, width, height, link }) => {
  let elType: any = 'div'
  let props: {
    className?: string
    href?: string
    to?: string
    target?: string
    rel?: string
  } = {}
  props.className = `${styles.item} text-center px-4 ${className}`
  if (link) {
    elType = Link
    props.to = link
    if (link.includes('http://') || link.includes('https://')) {
      elType = 'a'
      props.href = link
      props.target = '_blank'
      props.rel = 'noreferrer'
    }
  }

  return React.createElement(
    elType,
    props,
    <img
      src={image}
      alt="footer-logo"
      width={width}
      height={height}
      className={`${styles.image} inline-block relative h-22 lg:h-26 w-auto lg:w-100 object-contain`}
    />
  )
}

declare global {
  interface Window {
    anj_b8fc79ad_a460_4d25_847a_c8d8a6407b5c: any
  }
}

const FooterMenu = () => {
  const { t } = useTranslation()

  return (
    <div className="container mx-auto grid grid-cols-1 sm:grid-cols-4 items-start gap-10">
      <div className="flex flex-col gap-y-10">
        <h5 className="font-medium text-lg mb-3 text-footerDarkGray">{t('menu.casino', 'Casino')}</h5>
        <ul className="flex flex-col gap-y-5">
          <li>
            <Link to="/games" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.classicGames', 'Classic Games')}
            </Link>
          </li>
          <li>
            <Link to="/live-games" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.liveGames', 'Live Games')}
            </Link>
          </li>
          <li>
            <Link to="/slots" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.slots', 'Slots')}
            </Link>
          </li>
          <li>
            <Link to="/game/BetsyGames:btsg_sportbetting" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.sports', 'Sports')}
            </Link>
          </li>
          <li>
            <Link to="/game/Oddin:odn_oddingame" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.eSports', 'eSports')}
            </Link>
          </li>
          <li>
            <Link to="/game/bitcoin-blackjack" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.blackjack', 'Blackjack')}
            </Link>
          </li>
          {/* <li>
          <Link to="/game/bjfun:redorblack" className="text-menuLink hover:text-menuLinkHover">
            Red Or Black
          </Link>
        </li> */}
        </ul>
      </div>
      <div className="flex flex-col gap-y-10">
        <h6 className="text-lg font-medium mb-3 text-footerDarkGray">{t('menu.promo', 'Promo')}</h6>
        <ul className="flex flex-col gap-y-5">
          <li>
            <a
              href="https://partners.blackjack.fun"
              target="_blank"
              rel="noreferrer"
              className="text-menuLink hover:text-menuLinkHover"
            >
              {t('menu.affiliate', 'Affiliate')}
            </a>
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-y-10">
        <h5 className="font-medium text-lg mb-3 text-footerDarkGray">{t('menu.support', 'Support')}</h5>
        <ul className="flex flex-col gap-y-5">
          <li>
            <>
              <Helmet>
                <script type="text/javascript">{`(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.defer=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document, 'https://blackjackfun.ladesk.com/scripts/track.js', function(e){ LiveAgent.createButton('q18nu9lz', e); });`}</script>
              </Helmet>
              <Link
                to=""
                className={`text-menuLink hover:text-menuLinkHover`}
                onClick={() => {
                  const btn = document.querySelector('.myChatButtonText') as HTMLElement
                  if (btn) btn.click()
                }}
              >
                {t('menu.helpCentre', 'Help Centre')}
              </Link>
            </>
          </li>
          <li>
            <Link to="/provably-fair/toc" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.termsOfService', 'Terms Of Service')}
            </Link>
          </li>
          <li>
            <Link to="/kyc-policy" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.KYC', 'KYC')}
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-y-10">
        <h5 className="font-medium text-lg mb-3 text-footerDarkGray">{t('menu.aboutUs', 'About Us')}</h5>
        <ul className="flex flex-col gap-y-5">
          <li>
            <a
              href="https://blog.blackjack.fun/"
              target="_blank"
              rel="noreferrer"
              className="text-menuLink hover:text-menuLinkHover"
            >
              {t('menu.news', 'News')}
            </a>
          </li>
          <li>
            <Link to="/jack-token" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.jackToken', 'Jack Token')}
            </Link>
          </li>
          <li>
            <a href="/link9" className="text-menuLink hover:text-menuLinkHover">
              {t('menu.representative', 'Representative')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

const Footer: React.FC = () => {
  // const isMobile = useIsMobile('lg')
  const { t } = useTranslation()

  useEffect(() => {
    globalThis?.anj_b8fc79ad_a460_4d25_847a_c8d8a6407b5c?.init()
  }, [])

  return (
    <footer className={`${styles.root} bg-shark typo-caption`}>
      <div className={`${styles.wrapper} overflow-hidden text-paleSky`}>
        <div className="grid grid-cols-12 lg:gap-x-40 gap-y-45 px-15 py-50 md:px-35 text-center lg:text-left">
          <div className={'col-span-full lg:col-span-5 flex flex-col justify-center items-center gap-50'}>
            <div className="flex flex-col  items-center gap-10">
              <img alt="footer blackjack logo" src="/assets/footer/footer-BJ-log.png" width={184} />
              <p className="font-medium text-footerDarkGray text-sm">
                {t('footer.joinCommunity', 'Join the Community')}
              </p>
              <div className="flex gap-x-15 items-center">
                <a target="_blank" rel="noreferrer" href="https://twitter.com/blackjack_fun" title="Twitter">
                  <Twitter className={`${styles.socialLogo} w-16 h-16`} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/c93ZXs6Dsh"
                  title="Discord"
                  className="w-16 h-16"
                >
                  <Discord className={`${styles.socialLogo} w-16 h-16`} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/Blackjack_fun_Official"
                  title="Telegram"
                  className="w-16 h-16"
                >
                  <TelegramWhite className="w-16 h-16" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/Blackjackfun-689594501908642"
                  title="Twitter"
                >
                  <FacebookWhite className="w-16 h-16" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/blackjack.fun/" title="Instagram">
                  <Instagram className={`${styles.socialLogo} w-16 h-16`} />
                </a>
              </div>
            </div>
            <img alt="Guy and girl" src="/assets/hero_carousel/old_banners/elite-seat.png" height={198} />
          </div>
          <div
            className="col-span-full lg:col-span-7 px-20 lg:p-0 flex flex-col sm:items-center gap-30"
            style={{
              lineHeight: '19px',
            }}
          >
            <FooterMenu />
            <div className="border-t border-separatorGray w-full"></div>
            <p className="text-footerDarkGray text-2xs font-normal">
              {t(
                'footer.company',
                'Blackjack.fun is owned and operated by Satya Code SRL. Registration nr: 3102813297. Registered address: San Jose, Montes De Oca, San Pedro, 400N 25E Los Yoses, CR. Contact us info@blackjack.fun. Blackjack.fun is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. ALSI-032401017-FI3. Blackjack.fun has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.'
              )}
            </p>
            <p className="text-footerDarkGray text-2xs font-normal">
              {t(
                'footer.legal',
                'Your use of and access to blackjack.fun signifies that you fully understand and agree to be legally bound by the contents of our Terms of Service and Responsible Gaming Policy.'
              )}
            </p>
          </div>
        </div>
        <div className={`${styles.bottom} px-35 pt-35 pb-24 lg:pb-35`}>
          <div className="flex items-center justify-center gap-x-16">
            <div
              id="anj-b8fc79ad-a460-4d25-847a-c8d8a6407b5c"
              data-anj-seal-id="b8fc79ad-a460-4d25-847a-c8d8a6407b5c"
              data-anj-image-size="28"
              data-anj-image-type="basic-small"
            ></div>
            <FooterLogo image="/assets/footer_2.png" width={22} height={27} link="/responsible-gaming" />
            <FooterLogo image="/assets/footer_3.png" width={101} height={21} />
            <FooterLogo image="/assets/footer_5.png" width={83} height={24} />
            <FooterLogo
              image="/assets/footer_6.png"
              width={129}
              height={30}
              link="https://bitcointalk.org/index.php?topic=5106215.0"
            />
          </div>
        </div>
      </div>
      <div className="text-darkGrey text-center text-xs leading-4 font-inter">
        ©{new Date().getFullYear()} {t('footer.rightsReserved', 'blackjack.fun ALL RIGHTS RESERVED')}
      </div>
    </footer>
  )
}

export default Footer

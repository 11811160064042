import React, { useCallback, useEffect, useRef, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation, fetchQuery, useMutation } from 'react-relay'
import { cloneDeep, isEqual } from 'lodash'
import { store } from '../../store'
import { GameListComponentstoreUserPreferenceMutation } from './__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import { applyUpdatedUserPreference, UserProp } from '../../reducers/authentication.reducer'
import {
  Currency,
  GameListComponentNewSessionMutation,
} from './__generated__/GameListComponentNewSessionMutation.graphql'
import { deviceType, throttledResizeEvent } from '../..'
import Env, { GraphQLError } from '../../RelayEnvironment'
import { useHistory, useParams } from 'react-router'
import formatError from '../errorFormatter'
import { InHouseGame } from '../inHouseGames/InHouseGame'
import { useForceUpdate } from '../rightSide/walletComponent/WalletComponent'
import { getUserPropOrJSONValue } from '../SettingsComponent/SettingsComponent'
import ConfigurationService from '../../service/ConfigurationService'
import { ourGames } from '../helper'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, Navigation, type Swiper as SwiperType } from 'swiper'
import 'swiper/scss'
import 'swiper/scss/grid'
import 'swiper/scss/navigation'
import Arrow from '../icons/arrow'
import useIsMobile from '../../hooks/IsMobile'

import './GameListComponent.scss'
import classes from './GameListComponent.module.scss'
import classNames from 'classnames'

import { LeaderBoards } from '@components/LeaderBoards'
import LatestBets from '@src/components/LatestBets/LatestBets_old'
import Footer from '../Footer/Footer'
import TextBlock from '../TextBlock/TextBlock'
import { EndorphinaComponentListEndorphinaGamesQuery$data } from '../endorphina/__generated__/EndorphinaComponentListEndorphinaGamesQuery.graphql'
import { GameListComponentEndorphinaCreateUrlMutation } from './__generated__/GameListComponentEndorphinaCreateUrlMutation.graphql'
import { GameListComponentListEndorphinaGamesQuery } from './__generated__/GameListComponentListEndorphinaGamesQuery.graphql'
import { AppTypes } from '@src/reducers/app.reducer'
import { TopGames } from '../TopGames'
import { activeWalletUpdated } from '@src/reducers/wallet.reducer'

export enum TopLevelSection {
  inHouse,
  affiliate,
  providers,
  favourites,
}

export let overrideProviders: string[] = []

export interface Game {
  title: string
  identifier: string
  identifier2?: string
  provider: string
  category: string
  producer: string
  has_live?: boolean
  payout: string | null
  description: string | null
}

export type EndorphinaGameWithProvider =
  EndorphinaComponentListEndorphinaGamesQuery$data['listEndorphinaGames'][number] & {
    title: string
    identifier: string
    provider: string
    producer: string
  }

export type ExtendedGame = Game & EndorphinaGameWithProvider

export const GameListComponent = ({ data, category }: { data: { listGames: Game[] }; category: 'live' | 'slots' }) => {
  const [games] = useState<Game[]>(data?.listGames || [])
  const [endorphinaGames, setEndorphinaGames] = useState<EndorphinaGameWithProvider[]>([])
  const [activeWallet, setActiveWallet] = useState(store.getState().wallet.activeWallet)

  useEffect(() => {
    const subscription = activeWalletUpdated.subscribe(() => {
      if (activeWallet) return
      if (isEqual(activeWallet, store.getState().wallet.activeWallet)) return

      setActiveWallet(store.getState().wallet.activeWallet)
    })

    return () => {
      subscription.unsubscribe()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [openGameCommit] = useMutation<GameListComponentNewSessionMutation>(newSessionMutation)
  const [openEndorphinaGameCommit] =
    useMutation<GameListComponentEndorphinaCreateUrlMutation>(createEndorphinaUrlMutation)

  const isMobile = useIsMobile()
  const { gameId } = useParams<{ gameId: string }>()
  const history = useHistory()

  const gameWrapperRef = useRef<HTMLDivElement>(null)

  history.listen((ev) => {
    const e = document.querySelector('.app-frame iframe')
    if (e) {
      e.parentNode?.removeChild(e)
    }
  })

  const [gamewrapperStyle, setGamewrapperStyle] = useState({})

  useEffect(() => {
    if (!isMobile) return

    setGamewrapperStyle({
      position: 'relative',
    })
  }, [isMobile])

  useEffect(() => {
    fetchQuery<GameListComponentListEndorphinaGamesQuery>(
      Env,
      getEndorphinaGamesQuery,
      {},
      {
        fetchPolicy: 'network-only',
      }
    ).subscribe({
      next: ({ listEndorphinaGames }) => {
        setEndorphinaGames(
          listEndorphinaGames.map((g) => ({
            ...g,
            title: g.description,
            identifier: g.gameId,
            provider: 'endorphina',
            producer: 'endorphina',
          }))
        )
      },
    })
  }, [])

  useEffect(() => {
    if (!activeWallet) return

    const ourGame = isOurGame(gameId)
    if (ourGame) {
      return
    }

    if (gameId?.startsWith('endorphina')) {
      if (store.getState().authentication.user?.anonymousHash !== null) {
        return
      }
      openEndorphinaGameCommit({
        variables: {
          input: { currency: activeWallet.currency as Currency, gameId },
        },
        onCompleted: ({ createUrl }) => {
          const gameLaunchOptions = {
            target_element: 'game_wrapper',
            launch_options: {
              strategy: 'iframe',
              game_url: createUrl?.url,
            },
          }
          const success = (gameDispatcher: any) => {
            console.log(gameDispatcher)
          }
          const errHandler = (error: any) => {
            console.log(error)
          }
          ;(window as any).sg.launch(gameLaunchOptions, success, errHandler)
        },
        onError(error) {
          store.dispatch({
            type: AppTypes.SHOW_NOTIFICATION,
            notification: {
              status: 'error',
              text: formatError(error)[0],
            },
          })
          history.push('/')
        },
      })

      return
    }

    if (!gameId) return
    let gameId2 = gameId.replace('bgaming:', 'softswiss:')
    openGameCommit({
      variables: {
        input: { currency: activeWallet.currency as Currency, clientType: deviceType, gameId: gameId2 },
      },
      onCompleted: (response) => {
        const gameLaunchOptions = {
          target_element: 'game_wrapper',
          launch_options: cloneDeep(response.newSession?.launchOptions),
        }

        const success = (gameDispatcher: any) => {
          console.log(gameDispatcher)
        }
        const errHandler = (error: any) => {
          console.log(error)
        }
        ;(window as any).sg.launch(gameLaunchOptions, success, errHandler)
      },
      onError: (err: GraphQLError) => {
        store.dispatch({
          type: AppTypes.SHOW_NOTIFICATION,
          notification: {
            status: 'error',
            text: formatError(err)[0],
          },
        })
        history.push('/')
      },
    })
    return
  }, [history, gameId, openGameCommit, openEndorphinaGameCommit, activeWallet])

  const ourGame = isOurGame(gameId)
  if (ourGame) {
    return <InHouseGame gameId={gameId} />
  }

  if (gameId) {
    return (
      <div className="flex items-center justify-center lg:px-40 h-full" ref={gameWrapperRef} style={gamewrapperStyle}>
        <div id="game_wrapper" />
      </div>
    )
  }

  return (
    <div className={classNames(classes.root, 'px-16 app-content-inner text-white flex flex-col')}>
      <FilteredGames games={games} endorphinaGames={endorphinaGames} category={category} />
    </div>
  )
}

export let saveSearchStr: () => void
let lastSearchStr: string | undefined

export const FilteredGames = ({
  rows,
  games,
  endorphinaGames,
  category,
}: {
  rows?: number
  games: Game[]
  endorphinaGames: EndorphinaGameWithProvider[]
  category: 'live' | 'slots'
}): JSX.Element | null => {
  const isMobile = useIsMobile()

  const swiperContainerRef = React.useRef<HTMLDivElement>(null)
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const [providersWithImages, setProvidersWithImages] = useState<string[]>([])
  const [carousel, setCarousel] = useState<SwiperType>()
  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    fetch('/assets/providersWithLogos.json')
      .then((response) => response.json())
      .then((json) => {
        setProvidersWithImages(json)
      })
  }, [])

  let defaultState = { providerFilter: Array<string>() }
  if (undefined === rows) {
    const serializedDefaultState = getUserPropOrJSONValue(UserProp.HOME_PAGE, JSON.stringify(defaultState))
    try {
      defaultState = JSON.parse(serializedDefaultState)
    } catch (e) {}

    if (overrideProviders && overrideProviders.length > 0) {
      defaultState.providerFilter = JSON.parse(JSON.stringify(overrideProviders))
      setTimeout(() => {
        overrideProviders = []
      }, 2000)
    }
  }

  const forceRender = useForceUpdate()
  let ff = []
  const preferences = store.getState().authentication.user?.preferences
  if (undefined !== preferences) {
    const idx = preferences.findIndex((x) => x.key === UserProp.FAVOURITES)
    if (idx >= 0) {
      const value = preferences[idx].valueJson
      if (value) {
        const storedValue = JSON.parse(value)
        ff = storedValue.games
      }
    }
  }

  const [favved] = useState<string[]>(ff)
  const [providerFilter, _setProviderFilter] = useState<string[]>(defaultState.providerFilter)
  const [initialRendering, setInitialRendering] = useState(true)
  const [keyword, setKeyword] = useState<string | undefined>(lastSearchStr)

  if (undefined !== lastSearchStr) {
    setTimeout(() => {
      lastSearchStr = undefined
    }, 1000)
  }

  saveSearchStr = () => {
    lastSearchStr = keyword
    console.log({ lastSearchStr })
  }

  useEffect(() => {
    if (initialRendering) {
      setInitialRendering(false)
      forceRender()
    }
  }, [initialRendering, forceRender])

  useEffect(() => {
    const clickHandler = (e: Event) => {
      if (e.target) {
        const dropdowns = document.querySelectorAll('.dropdown-check-list.visible')
        if (dropdowns.length === 0) {
          return
        }
        dropdowns.forEach((x) => {
          if (x.contains(e.target as HTMLDivElement)) {
            return
          }
          x.classList.remove('visible')
        })
      }
    }
    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  })

  useEffect(() => {
    const subs = throttledResizeEvent.subscribe(() => {
      forceRender()
    })

    return () => {
      subs.unsubscribe()
    }
  })

  const saveState = (state: { providerFilter: string[] }) => {
    commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
      mutation: updateUserPreference,
      variables: {
        input: { key: UserProp.HOME_PAGE, valueJson: JSON.stringify(state) },
      },
      onCompleted: (resp) => {
        applyUpdatedUserPreference(resp)
      },
    })
  }

  const setProviderFilter = useCallback((filter: string[]) => {
    _setProviderFilter(filter)
    saveState({ providerFilter: filter })
  }, [])

  useEffect(() => {
    setProviderFilter([])
  }, [category, setProviderFilter])

  const swiperRef = useRef<any | SwiperType>(null)

  if (ConfigurationService.instance.areGamesDisabled()) {
    return null
  }

  if (undefined === providerFilter && games) {
    setProviderFilter(
      games.reduce((acc, val) => {
        const producer = val?.producer as string
        if (acc.indexOf(producer) < 0) {
          acc.push(producer)
        }
        return acc
      }, new Array<string>())
    )
  }

  let mappedGames = [...ourGames, ...games, ...endorphinaGames] as ExtendedGame[]

  mappedGames = mappedGames.map((x) => Object.assign({ favourite: favved.indexOf(x?.identifier as string) >= 0 }, x))
  mappedGames = mappedGames.filter((x) => {
    if (!x) {
      return false
    }
    let passProvider = false

    if (providerFilter.length > 0) {
      if (providerFilter.indexOf(x.producer) >= 0) {
        passProvider = true
      }
    } else {
      passProvider = true
    }

    return passProvider
  })

  if (category === 'live') {
    mappedGames = mappedGames.filter((g) => {
      const game = g as Game
      return game?.has_live || false
    })
  }

  if (category === 'slots') {
    mappedGames = mappedGames.filter((g) => {
      const game = g as Game
      return game?.category === 'slots' || false
    })
  }

  const getProvidersList = () => {
    if (!providerFilter || !games) return []
    return [...ourGames, ...games, ...endorphinaGames]
      .filter((g) => {
        if (!category) return true

        const game = g as Game

        if (category === 'live' && Object.hasOwn(game, 'has_live') && game.has_live === true) {
          return true
        }

        if (category === 'slots') {
          if (Object.hasOwn(game, 'category') && game.category === 'slots') {
            return true
          }
        }

        return false
      })
      .reduce((acc, val) => {
        const producer = val?.producer as string
        if (acc.indexOf(producer) < 0) {
          acc.push(producer)
        }
        return acc
      }, new Array<string>())
      .sort((a, b) => {
        if (a === 'blackjackfun') {
          return -1
        }
        if (b === 'blackjackfun') {
          return 1
        }
        return a.localeCompare(b)
      })
  }

  const livePriorityOrder = [
    'pragmaticplaylive',
    'ezugi',
    'evolution',
    'luckystreak',
    'beterlive',
    'alg',
    '7mojos',
    'tvbet',
    'vivogaming',
    'gameart',
    'goldenrace',
    'pipa',
  ]

  const liveProvidersList = getProvidersList()
    .sort((a, b) => {
      const aIndex = livePriorityOrder.indexOf(a)
      const bIndex = livePriorityOrder.indexOf(b)
      if (aIndex < 0 && bIndex < 0) {
        return a.localeCompare(b)
      }
      if (aIndex < 0) {
        return 1
      }
      if (bIndex < 0) {
        return -1
      }
      return aIndex - bIndex
    })
    .map((p) => {
      if (p === 'pragmaticplaylive') return 'pragmaticplay'
      return p
    })

  const providersList = category === 'live' ? liveProvidersList : getProvidersList()
  const isActive = (provider: string) => {
    if (category === 'live' && provider === 'pragmaticplay') return providerFilter.includes('pragmaticplaylive')
    return providerFilter.includes(provider)
  }

  const gridRows = 2

  return (
    <React.Fragment>
      <TopGames category={category} />
      <div className="games-top">
        <div className="providersFilter">
          <div className="header">
            <div className="pl-24">Providers</div>
            <div className="flex gap-x-10 items-center px-20">
              <div
                ref={navigationPrevRef}
                className="h-26 rounded-full inline-flex items-center justify-center cursor-pointer"
              >
                <Arrow className="text-white transform rotate-90" />
              </div>
              <div className="index">{`${0}/${0}`}</div>
              <div
                ref={navigationNextRef}
                className="h-26 rounded-full inline-flex items-center justify-center cursor-pointer"
              >
                <Arrow className="text-white transform -rotate-90" />
              </div>
            </div>
          </div>
          <div className={classes.providerBody} ref={swiperContainerRef}>
            <Swiper
              modules={[Grid, Navigation]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              spaceBetween={8}
              slidesPerView={isMobile ? 4 : 'auto'}
              slidesPerGroupAuto
              grid={{
                rows: category === 'live' ? 1 : gridRows,
                fill: 'row',
              }}
              onInit={(swiper) => (swiperRef.current = swiper)}
              onSwiper={(swiper) => setCarousel(swiper)}
              onSlideChange={(swiper) => setActiveSlide(swiper?.activeIndex)}
            >
              {providersList.map((provider, i) => (
                <SwiperSlide className="" key={JSON.stringify(provider) + i}>
                  <button
                    type="button"
                    className={`provider ${isActive(provider) ? 'active' : ''}`}
                    onClick={() => {
                      if (!providerFilter.includes(provider)) {
                        if (category === 'live' && provider === 'pragmaticplay') {
                          setProviderFilter(['pragmaticplaylive'])
                          return
                        }
                        setProviderFilter([provider])
                      } else {
                        setProviderFilter([])
                      }
                    }}
                  >
                    {providersWithImages.includes(provider) ? (
                      <div className="flex justify-center items-center w-full h-full md:p-16">
                        <img src={`/assets/providers/${provider}.png`} alt="provider logo" className="object-contain" />
                      </div>
                    ) : (
                      provider
                    )}
                  </button>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="s-cards flex-grow">
        {/* <GameList games={mappedGames} category={category} providerFilter={providerFilter} /> */}
      </div>
      <div className="flex flex-col">
        <LeaderBoards />
        <LatestBets />
        <div className="mb-22 text-center inline-flex justify-center items-center w-full">
          <TextBlock
            headline="Who is blackjack.fun?"
            shorttext="Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various crypto currencies and we will add more in the future.
          Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge. "
            text="Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various crypto currencies and we will add more in the future.<br/>
          Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge.<br/>
          Blackjack.fun's greatest achievement is its community. The members can get involved in the functionality of the platform, they can bring improvements, change various aspects of the experience and even suggest games. All these are meant to provide their members a unique experience, an honest, transparent and safe approach, and a place shaped based on their perspective."
          />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

const isOurGame = (gameId: string) => {
  return ['blackjack', 'redorblack', 'holdem'].indexOf(gameId) >= 0
}

export const updateUserPreference = graphql`
  mutation GameListComponentstoreUserPreferenceMutation($input: StoreUserPreferenceInput!) {
    storeUserPreference(input: $input) {
      preference {
        key
        value
        valueJson
      }
    }
  }
`

const newSessionMutation = graphql`
  mutation GameListComponentNewSessionMutation($input: NewSessionInput!) {
    newSession(input: $input) {
      launchOptions
    }
  }
`

export const gameClosedSubscription = graphql`
  subscription GameListComponentSGameClosedSubscription {
    sGameClosed
  }
`
const getEndorphinaGamesQuery = graphql`
  query GameListComponentListEndorphinaGamesQuery {
    listEndorphinaGames {
      gameId
      description
      tags
    }
  }
`

const createEndorphinaUrlMutation = graphql`
  mutation GameListComponentEndorphinaCreateUrlMutation($input: CreateUrlInput!) {
    createUrl(input: $input) {
      url
    }
  }
`

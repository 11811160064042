/**
 * @generated SignedSource<<5b52ca535e7aca117f8a6e3dd765056e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginInput = {
  username: string;
  password: string;
  otp?: string | null;
};
export type LoginLoginMutation$variables = {
  input: LoginInput;
};
export type LoginLoginMutation$data = {
  readonly login: {
    readonly token: string;
    readonly user: {
      readonly name: string | null;
      readonly username: string | null;
      readonly otpEnabled: boolean | null;
      readonly anonymousHash: string | null;
      readonly uuid: string | null;
      readonly roles: ReadonlyArray<string> | null;
      readonly confirmed: boolean | null;
      readonly preferences: ReadonlyArray<{
        readonly key: string | null;
        readonly value: string | null;
        readonly valueJson: string | null;
      }> | null;
    };
  } | null;
};
export type LoginLoginMutation = {
  variables: LoginLoginMutation$variables;
  response: LoginLoginMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginPayload",
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "otpEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anonymousHash",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreference",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valueJson",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginLoginMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7bfe4fd586695c5c03a8e404db5b730f",
    "id": null,
    "metadata": {},
    "name": "LoginLoginMutation",
    "operationKind": "mutation",
    "text": "mutation LoginLoginMutation(\n  $input: LoginInput!\n) {\n  login(input: $input) {\n    token\n    user {\n      name\n      username\n      otpEnabled\n      anonymousHash\n      uuid\n      roles\n      confirmed\n      preferences {\n        key\n        value\n        valueJson\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "be013b804cac4bc723b914ae76cdd982";

export default node;

/**
 * @generated SignedSource<<e40281857e1225ae01dae7c703ba17ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GameAggregator = "SOFT_SWISS" | "ENDORPHINA" | "HUB88" | "SEVEN" | "BJFUN" | "%future added value";
export type ListNormalizedProvidersFilter = {
  category?: string | null;
  aggregator?: GameAggregator | null;
  aggregators?: ReadonlyArray<GameAggregator> | null;
};
export type NewGameListComponentNormalizedProvidersQuery$variables = {
  filter: ListNormalizedProvidersFilter;
};
export type NewGameListComponentNormalizedProvidersQuery$data = {
  readonly listNormalizedProviders: ReadonlyArray<string>;
};
export type NewGameListComponentNormalizedProvidersQuery = {
  variables: NewGameListComponentNormalizedProvidersQuery$variables;
  response: NewGameListComponentNormalizedProvidersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "kind": "ScalarField",
    "name": "listNormalizedProviders",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewGameListComponentNormalizedProvidersQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewGameListComponentNormalizedProvidersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "88716e89977cbb2f0b36edaf3be47d45",
    "id": null,
    "metadata": {},
    "name": "NewGameListComponentNormalizedProvidersQuery",
    "operationKind": "query",
    "text": "query NewGameListComponentNormalizedProvidersQuery(\n  $filter: ListNormalizedProvidersFilter!\n) {\n  listNormalizedProviders(filter: $filter)\n}\n"
  }
};
})();

(node as any).hash = "4a459c09748e4de1f0395661e5facd3c";

export default node;

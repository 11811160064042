import React from 'react'

import { ChatSVG } from '../chatSVG'
import { RainSVG } from '../rainSVG'
import { VoteSVG } from '../voteSVG'
import { CheckmarkSVG } from '../checkmarkSVG'

import classes from './Tier1.module.scss'

export function Tier1() {
  return (
    <div className={classes.root}>
      <h3 className="text-sm font-bold font-inter">TIER 1</h3>
      <p className="py-6 font-bold font-inter leading-5">
        For all players
        <br />
        <span className="font-normal text-eucalyptus">Wager</span>{' '}
        <span className="font-normal text-yellow">0-1 BTC</span>
        <br />
        no rakeback & cashback <span className="text-pink">(yet)</span>
      </p>
      <p className="font-poppins font-medium text-sm pt-10 lg:pt-40 pb-60">
        Our community is oriented towards players. This is why, straight from day one you have a lot of benefits. You
        don’t need to wager to get tips from friends, you don’t need to wager to use the chat, and you can really start
        winning without even making a deposit by voting on the competitions page. In addition there are hourly coin rain
        events.
      </p>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-x-65 gap-y-100">
        <div className="flex flex-col gap-y-14 justify-center items-center">
          <ChatSVG />
          <p className="h-60">
            Hourly in-Chat
            <br />
            Rewards
          </p>
        </div>
        <div className="flex flex-col gap-y-14 justify-center items-center">
          <RainSVG />
          <p className="h-60">
            Random and/or
            <br />
            organised
            <br />
            rain by staff and/or
            <br />
            players
          </p>
        </div>
        <div className="flex flex-col gap-y-14 justify-center items-center">
          <VoteSVG />
          <p className="h-60">
            Tipping system
            <br />
            from friends and/or
            <br />
            staff
          </p>
        </div>
        <div className="flex flex-col gap-y-14 justify-center items-center">
          <CheckmarkSVG />
          <p className="h-60">
            Voting system
            <br />
            rewards
            <br />
            free spins and/or
            <br />
            coins
          </p>
        </div>
      </div>
    </div>
  )
}

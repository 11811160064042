import React from 'react'

import classes from './CareerHighlights.module.scss'

export function CareerHighlights({
  totalWagered,
  highestWin,
  tournamentWon,
}: {
  totalWagered: string
  highestWin: string
  tournamentWon: number
}) {
  return (
    <div className={classes.root}>
      <div className="inline-flex justify-between text-white">
        <h3>Career Highlights</h3>
        <button type="button" className={classes.viewAllButton}>
          view all statistics
        </button>
      </div>
      <div className={classes.badges}>
        <div className="flex items-center gap-x-2">
          <img alt="" src="/assets/profile/badges/dice.svg" className="w-26 h-26" />
          <div className="flex flex-col">
            <span className="no-wrap">{totalWagered}</span>
            <h6 className="no-wrap">Total Wagered</h6>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <img alt="" src="/assets/profile/badges/thunder.svg" className="w-26 h-26" />
          <div className="flex flex-col">
            <span className="no-wrap">{highestWin}</span>
            <h6 className="no-wrap">Highest win</h6>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <img alt="" src="/assets/profile/badges/token.svg" className="w-26 h-26" />
          <div className="flex flex-col">
            <span className="no-wrap">{tournamentWon}</span>
            <h6 className="no-wrap">Tournaments wins</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

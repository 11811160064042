import React from 'react'

import classes from './ClaimReward.module.scss'

type ClaimRewardProps = {
  label: string
  button?: {
    text: string
  }
  children: React.ReactNode
}

export function ClaimReward({
  label,
  button = {
    text: 'CLAIM YOUR REWARD NOW',
  },
  children,
}: ClaimRewardProps) {
  const { text } = button

  return (
    <div className={classes.root}>
      <h3>{label}</h3>
      <div className={classes.reward}>{children}</div>
      <div className="flex flex-col gap-y-20">
        <button type="button" className={classes.button}>
          {text}
        </button>
        <p className={classes.note}>
          You can find your rewards in the Bonus wallet or the Main wallet depending on the nature of promotion you have
          acquired the funds.
        </p>
      </div>
    </div>
  )
}

import { TopTier } from './TopTier'

import classes from './Tier6.module.scss'

export function Tier6() {
  const image = <img alt="" src="/assets/pages/elite-seat/tier6.png" className={classes.image} />

  return (
    <TopTier
      image={image}
      text="You are a GURU player. This is not a level but a new life. The rewards reach celestial heights and you can rest assured, that everyone will treat you like a GOD!"
    >
      <h2 className="font-poppins font-medium h-min">5</h2>
      <div className="flex flex-col transform translate-y-20 -translate-x-40">
        <h3 className="text-sm leading-5 font-bold text-white font-inter">
          TIER 6<br />
          <span className="text-pink">Elite Seat 5</span>
        </h3>
        <p className="text-white">
          VIP
          <br />
          <span className="text-eucalyptus">Wager</span> <span className="text-yellow">4000 - 12.000 BTC</span>
          <br />
          <span className="text-lg font-bold">
            20% rakeback
            <br />
            15% cashback
          </span>
        </p>
        <p className="text-eucalyptus font-medium w-100 whitespace-nowrap">
          Mystery Celestial box
          <br />
          YACHT Parties
          <br />
          Unique presents crafted just for you
          <br />
          Private travel on us
          <br />
          Journey Health retreat to private locations
        </p>
      </div>
    </TopTier>
  )
}

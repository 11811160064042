export function ChatSVG() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.6746 26C1.4639 26 1.24995 25.9584 1.04233 25.8728C0.416719 25.6152 0.0280762 25.034 0.0280762 24.3556L0.0282675 3.81077C0.0282675 1.70961 1.73299 0 3.82847 0H22.2279C24.3232 0 26.0281 1.70946 26.0281 3.81077V16.8348C26.0281 18.9359 24.3233 20.6455 22.2279 20.6455H7.61593L2.82789 25.5081C2.51002 25.8305 2.09895 26 1.6747 26L1.6746 26ZM3.82847 1.10942C2.34308 1.10942 1.13458 2.32128 1.13458 3.8108V24.3556C1.13458 24.6857 1.38585 24.8147 1.46259 24.8465C1.5399 24.8786 1.80896 24.9638 2.04033 24.7285L6.99083 19.7009C7.09475 19.5953 7.23655 19.5358 7.38448 19.5358H22.2277C23.7131 19.5358 24.9216 18.324 24.9216 16.8344V3.81044C24.9216 2.32091 23.7131 1.10905 22.2277 1.10905L3.82847 1.10942Z"
        fill="#3CCEA2"
      />
      <path
        d="M19.7984 6.70971H7.07026C6.77094 6.70971 6.52808 6.3343 6.52808 5.871C6.52808 5.40798 6.77076 5.03229 7.07026 5.03229H19.7984C20.0977 5.03229 20.3406 5.40769 20.3406 5.871C20.3406 6.3343 20.0979 6.70971 19.7986 6.70971H19.7984Z"
        fill="#3CCEA2"
      />
      <path
        d="M19.7984 10.9032H7.07026C6.77094 10.9032 6.52808 10.5278 6.52808 10.0645C6.52808 9.60152 6.77076 9.22583 7.07026 9.22583H19.7984C20.0977 9.22583 20.3406 9.60123 20.3406 10.0645C20.3406 10.5278 20.0979 10.9032 19.7986 10.9032H19.7984Z"
        fill="#3CCEA2"
      />
      <path
        d="M15.7257 15.0968H7.08047C6.77552 15.0968 6.52808 14.7214 6.52808 14.2581C6.52808 13.7951 6.77533 13.4194 7.08047 13.4194H15.7257C16.0306 13.4194 16.2781 13.7948 16.2781 14.2581C16.2781 14.7214 16.0308 15.0968 15.7257 15.0968Z"
        fill="#3CCEA2"
      />
    </svg>
  )
}

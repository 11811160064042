import { UserProp } from '@src/reducers/authentication.reducer'
import ConfigurationService from '@src/service/ConfigurationService'
import { roundNumberToLength } from '@src/utils/functions'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import {
  BankrollPayout,
  ChatGiveaway,
  DepositBonus,
  // DirectCompetitionCredit,
  FreespinAdded,
  ManualCashback,
  ManualCredit,
  NewBonusAdded,
  RainBonus,
  Rakeback,
} from './Notification.interface'
import classes from './Notification.module.scss'
import { NotificationType } from './__generated__/NotificationsListNotificationsQuery.graphql'
import { useTranslation } from 'react-i18next'
import { Money } from '@src/money'

export function Notification({
  type,
  insertedAt,
  data,
}: {
  type: NotificationType
  insertedAt: string
  data?: unknown
}) {
  const { t } = useTranslation()
  let header = ''
  let highlight = ''

  if (type === 'NEW_BONUS_ADDED') {
    const Data = structuredClone(data) as NewBonusAdded

    const { amount, currency } = Data

    header = t('notification.newBonusAdded', 'has been added to bonus wallet')

    const c = ConfigurationService.instance.getCurrency(currency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const converted = Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)

    highlight = converted.toString() + ' ' + format
  }

  if (type === 'BANKROLL_PAYOUT') {
    const Data = structuredClone(data) as BankrollPayout

    const { amount, currency } = Data
    if (!currency) return null

    header = t('notification.bankrollPayout', 'Your investment profit is here')

    const c = ConfigurationService.instance.getCurrency(currency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const converted = Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)

    highlight = `+${converted.toString()} ${format}`
  }

  if (type === 'DIRECT_RAIN') {
    const Data = structuredClone(data) as RainBonus & { amount: number; currency: string }

    const { amount, currency } = Data
    if (!currency) {
      console.log({ Data })
      return <></>
    }

    header = t('notification.directRain', 'You have funds from chat coin rain')

    const c = ConfigurationService.instance.getCurrency(currency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const converted = Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)

    highlight = `${converted.toString()} ${format}`
  }

  if (type === 'MANUAL_CASHBACK') {
    const Data = structuredClone(data) as ManualCashback

    const { amount, currency } = Data
    // if (!currency) return null

    header = t('notification.manualCashback', 'Cashback')

    const c = ConfigurationService.instance.getCurrency(currency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const converted = Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)

    highlight = `${converted.toString()} ${format}`
  }

  if (type === 'MANUAL_CREDIT') {
    const Data = structuredClone(data) as ManualCredit

    const { amount, currency } = Data
    // if (!currency) return null

    header = t('notification.manualCredit', 'Manual credit')

    const c = ConfigurationService.instance.getCurrency(currency)
    let format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    let converted = roundNumberToLength(Money.convertUnit(amount as number, c.m_unit, c.s_unit, format), 5)

    if (converted === '0' && c.short === 'BTC') {
      format = 'uBTC'
      converted = '' + Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)
    }

    highlight = `${converted.toString()} ${format}`
  }

  if (type === 'RAKEBACK') {
    const Data = structuredClone(data) as Rakeback

    const { amount, currency } = Data
    // if (!currency) return null

    header = t('notification.rakeback', 'has been added to bonus wallet')

    const c = ConfigurationService.instance.getCurrency(currency)
    let format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    let converted = roundNumberToLength(Money.convertUnit(amount as number, c.m_unit, c.s_unit, format), 5)

    if (converted === '0' && c.short === 'BTC') {
      format = 'uBTC'
      converted = '' + Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)
    }

    highlight = `${converted.toString()} ${format}`
  }

  if (type === 'DEPOSIT_BONUS') {
    const Data = structuredClone(data) as DepositBonus

    const { bonus_amount, currency } = Data

    header = t('notification.depositBonus', 'Deposit bonus was added to your bonus wallet.')

    const c = ConfigurationService.instance.getCurrency(currency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const converted = Money.convertUnit(bonus_amount as number, c.m_unit, c.s_unit, format)

    highlight = `${converted.toString()} ${format}`
  }

  if (type === 'DIRECT_COMPETITION_CREDIT') {
    // const { amount, currency } = Data

    header = t('notification.directCompetitionCredit', 'Your competition reward is here')

    // const c = ConfigurationService.instance.getCurrency(currency)
    // const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    // const converted = Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)

    highlight = ``
  }

  if (type === 'CHAT_GIVEAWAY') {
    const Data = structuredClone(data) as ChatGiveaway

    const { amount, currency, username } = Data

    header = t('notification.chatGiveaway', 'from {{username}} chat giveaway', { username })

    const c = ConfigurationService.instance.getCurrency(currency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const converted = Money.convertUnit(amount as number, c.m_unit, c.s_unit, format)

    highlight = `${converted.toString()} ${format}`
  }

  if (
    type !== 'NEW_BONUS_ADDED' &&
    type !== 'FREESPIN_ADDED' &&
    type !== 'BANKROLL_PAYOUT' &&
    type !== 'DIRECT_RAIN' &&
    type !== 'MANUAL_CASHBACK' &&
    type !== 'MANUAL_CREDIT' &&
    type !== 'RAKEBACK' &&
    type !== 'DEPOSIT_BONUS' &&
    type !== 'CHAT_GIVEAWAY'
  ) {
    return null
  }

  if (type === 'FREESPIN_ADDED') {
    const Data = structuredClone(data) as FreespinAdded

    const { amount } = Data

    highlight = t('notification.freespinAdded', "You've won {{amount}} freespin.", { amount })

    return (
      <div className={classes.root}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          className="h-36 w-36"
        >
          <use xlinkHref="/assets/sprite.svg#notification-system"></use>
        </svg>
        <div className={classes.freespinWrapper}>
          <span className={classes.time}>{insertedAt}</span>
          <h4 className={classes.systemMessage}>{highlight}</h4>
          {/* <span className={classes.expiresAt}>
        This bonus will expire in: 10 Days | 8 Hours | 9 Minutes | 2 Seconds
      </span> */}
        </div>
      </div>
    )
  }

  if (type === 'DEPOSIT_BONUS' || type === 'RAKEBACK' || 'NEW_BONUS_ADDED') {
    return (
      <div className={classes.root}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          className="h-36 w-36"
        >
          <use xlinkHref="/assets/sprite.svg#notification-money"></use>
        </svg>
        <div className="flex flex-col text-white font-inter pb-4">
          <span className={classes.time}>{insertedAt}</span>
          <h4>
            <span>{highlight}</span> {header}
          </h4>
          {/* <span className={classes.expiresAt}>
        This bonus will expire in: 10 Days | 8 Hours | 9 Minutes | 2 Seconds
      </span> */}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        className="h-36 w-36"
      >
        <use xlinkHref="/assets/sprite.svg#notification-money"></use>
      </svg>
      <div className="flex flex-col text-white font-inter pb-4">
        <span className={classes.time}>{insertedAt}</span>
        <h4>{header}</h4>
        <p>
          <span>{highlight}</span> {t('notification.addedToWallet', 'added to your wallet.')}
        </p>
        {/* <span className={classes.expiresAt}>
          This bonus will expire in: 10 Days | 8 Hours | 9 Minutes | 2 Seconds
        </span> */}
      </div>
    </div>
  )
}

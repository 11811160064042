/**
 * @generated SignedSource<<a07b2f5c94407c65ef19b8a565e52281>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type vipProfileComponentUserStatUpdatedSubscription$variables = {};
export type vipProfileComponentUserStatUpdatedSubscription$data = {
  readonly userStatUpdated: {
    readonly uuid: string;
    readonly name: string;
    readonly value: number;
    readonly meta: any | null;
  };
};
export type vipProfileComponentUserStatUpdatedSubscription = {
  variables: vipProfileComponentUserStatUpdatedSubscription$variables;
  response: vipProfileComponentUserStatUpdatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserStatUser",
    "kind": "LinkedField",
    "name": "userStatUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "vipProfileComponentUserStatUpdatedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "vipProfileComponentUserStatUpdatedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ae891e9d258c70012eb939e0fb9c1455",
    "id": null,
    "metadata": {},
    "name": "vipProfileComponentUserStatUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription vipProfileComponentUserStatUpdatedSubscription {\n  userStatUpdated {\n    uuid\n    name\n    value\n    meta\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d11fcffbdccfdb9cdd10a7e83bc1160";

export default node;

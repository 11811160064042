import { useSelector } from 'react-redux'
import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery, useMutation } from 'react-relay'
import { AppTypes } from '../../reducers/app.reducer'
import {
  applyUpdatedUserPreference,
  AuthAction,
  User,
  UserConstant,
  UserProp,
} from '../../reducers/authentication.reducer'
import { IStore, store } from '../../store'
import Toggle2FA from '../authentication/Toggle2FA/Toggle2FA'
import { updateUserPreference } from '../gameListComponent/GameListComponent'
import { GameListComponentstoreUserPreferenceMutation } from '../gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import './SettingsComponent.scss'
import Env from '../../RelayEnvironment'
import { arrayBufferToBase64, arrayBufferToString, base64ToArrayBuffer } from './ArrayHelper'
import { SettingsComponentWebauthnRegisterEndMutation } from './__generated__/SettingsComponentWebauthnRegisterEndMutation.graphql'
import { SettingsComponentWebauthnLoginEndMutation } from './__generated__/SettingsComponentWebauthnLoginEndMutation.graphql'
import { SettingsComponentWebauthnLoginStartQuery } from './__generated__/SettingsComponentWebauthnLoginStartQuery.graphql'
import { useEffect, useState } from 'react'
import { SettingsComponentwebauthnRegisteredKeysQuery } from './__generated__/SettingsComponentwebauthnRegisteredKeysQuery.graphql'
import { SettingsComponentwebauthnRemoveKeyMutation } from './__generated__/SettingsComponentwebauthnRemoveKeyMutation.graphql'
import {
  SettingsComponentWebauthnRegisterStartMutation,
  WebauthnRegisterStartInput,
} from './__generated__/SettingsComponentWebauthnRegisterStartMutation.graphql'
import { generateRandomString } from '../SignInComponent'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const SettingsComponent = () => {
  const user = useSelector((state: IStore) => state.authentication.user)
  const [commit] = useMutation<GameListComponentstoreUserPreferenceMutation>(updateUserPreference)

  const [startWebauthnRegister] = useMutation<SettingsComponentWebauthnRegisterStartMutation>(webauthnRegisterStart)
  const [commitWebauthnRegister] = useMutation<SettingsComponentWebauthnRegisterEndMutation>(webauthnRegisterComplete)
  const [commitWebauthnLogin] = useMutation<SettingsComponentWebauthnLoginEndMutation>(webauthnLoginComplete)
  const [webauthnRegisteredKeyDelete] = useMutation<SettingsComponentwebauthnRemoveKeyMutation>(
    webauthnRegisteredKeyDeleteMutation
  )
  const [keys, setKeys] = useState<SettingsComponentwebauthnRegisteredKeysQuery['response']>()
  const [hack, setHack] = useState(0)
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    fetchQuery<SettingsComponentwebauthnRegisteredKeysQuery>(Env, webauthnRegisteredKeysQuery, {}).subscribe({
      next: (params) => {
        console.log({ params })
        setKeys(params)
      },
    })
  }, [hack])

  const [webauthnMessage, setWebauthnMessage] = useState<string>('')

  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  const soundEnabled = user?.preferences.find((x) => x.key === UserProp.SOUND_ENABLED)?.value === 'true'
  const privateUser = user?.preferences.find((x) => x.key === UserProp.PRIVATE)?.value === 'true'
  const activeTheme = getUserPropOr(UserProp.BACKGROUND, 'dark')
  const activeCardTheme = getUserPropOr(UserProp.CARD_THEME, 'modern')
  const activeCardBackground = getUserPropOr(UserProp.CARD_BACK, 9)

  const THEME_NAMES = [
    'dark',
    'crypto_is_talking_with_me',
    'crypto_rudolph',
    'dealers_special',
    'give_me_a_sign',
    'shroom_boom',
  ]
  const CARD_THEMES = ['modern', 'classical']
  const CARD_BACKGROUND = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const toggleUserPreference = (key: UserProp) => {
    let value = false
    switch (key) {
      case UserProp.SOUND_ENABLED:
        value = !soundEnabled
        break
      case UserProp.PRIVATE:
        value = !privateUser
        break
    }

    commit({
      variables: { input: { key: key, value: value.toString() } },
      onCompleted: (resp) => {
        const newUser = applyUpdatedUserPreference(resp)
        store.dispatch({ type: UserConstant.USER_UPDATED, user: newUser })
      },
    })
  }

  const setUserPreference = (key: UserProp, value: string) => {
    commit({
      variables: { input: { key: key, value: value.toString() } },
      onCompleted: (resp) => {
        const newUser = applyUpdatedUserPreference(resp)
        store.dispatch({ type: UserConstant.USER_UPDATED, user: newUser })
      },
    })
    if (key === UserProp.BACKGROUND) {
      const classList = document.querySelector('.app-frame')?.classList
      if (!classList) {
        return
      }
      classList.forEach((x) => {
        if (x.startsWith('theme-')) {
          classList.remove(x)
        }
      })
      classList.add('theme-' + value)
    }
  }

  const addPasskey = () => {
    let input: WebauthnRegisterStartInput = {}
    if (user?.anonymousHash) {
      let params: Array<{ key: string; value: string }> = []
      const savedProps = localStorage.getItem('ref_props')
      if (savedProps) {
        params = JSON.parse(localStorage.getItem('ref_props') as any)
      }

      /*
      if (promo.length > 0) {
        params.push({ key: 'bonus_code', value: promo })
      }
      */

      let str = generateRandomString(6)
      input = {
        anonymousHash: user.anonymousHash,
        refProps: params,
        name: str,
        username: str + '@blackjack.fun',
      }
    }
    startWebauthnRegister({
      variables: {
        // Leave empty when adding passkey to existing user
        // Fill if adding passkey to anonymous user, after creating passkey
        // user is registered and anonymous hash is deleted
        input,
      },
      onCompleted: ({ webauthnRegisterStart }) => {
        if (!webauthnRegisterStart) {
          return
        }
        console.log({ webauthnRegisterStart })

        const challenge = base64ToArrayBuffer(webauthnRegisterStart.params.challenge)
        const userId = base64ToArrayBuffer(webauthnRegisterStart.params.userId)
        const userDisplayName = user!.name || input.username!

        navigator.credentials
          .create({
            publicKey: {
              // random, cryptographically secure, at least 16 bytes
              challenge: challenge,
              // relying party
              rp: {
                id: webauthnRegisterStart.params.rpId,
                name: 'Blackjack.fun',
              },
              user: {
                id: userId,
                name: userDisplayName,
                displayName: userDisplayName,
              },
              pubKeyCredParams: [
                {
                  type: 'public-key',
                  alg: -7, // "ES256" IANA COSE Algorithms registry
                },
              ],
              attestation: webauthnRegisterStart.params.attestation as AttestationConveyancePreference,
              authenticatorSelection: {
                residentKey: 'preferred',
              },
            },
          })
          .then((newCredential) => {
            console.log({ newCredential })
            const data = newCredential as PublicKeyCredential

            commitWebauthnRegister({
              variables: {
                input: {
                  sessionId: webauthnRegisterStart.params.sessionId,
                  attestationObject: arrayBufferToBase64(
                    (data.response as AuthenticatorAttestationResponse).attestationObject
                  ),
                  clientDataJson: arrayBufferToString(data.response.clientDataJSON),
                  rawId: arrayBufferToBase64(data.rawId),
                },
              },
              onCompleted: (resp) => {
                console.log({ resp })
                setWebauthnMessage('Successfully added passkey')
                setHack(hack + 1)

                // Only present if new user was registered
                if (resp.webauthnRegisterEnd?.token && resp.webauthnRegisterEnd?.user) {
                  const token = resp.webauthnRegisterEnd.token
                  const user = resp.webauthnRegisterEnd.user
                  localStorage.setItem('token', token)
                  localStorage.setItem('user', JSON.stringify(user))
                  localStorage.removeItem('ref_props')
                  // if (_2fa) localStorage.setItem('show_2fa_popup', '1')
                  localStorage.setItem('show_welcome_popup', '1')
                  store.dispatch<AuthAction>({
                    type: UserConstant.LOGIN_SUCCESS,
                    user: user as unknown as User,
                    token,
                  })
                  history.push('/')
                  window.location.reload()
                }
              },
              onError: (err) => {
                console.log(err)
                setWebauthnMessage('Error: ' + err)
              },
            })
          })
      },
    })
  }

  const loginUsingPasskey = () => {
    fetchQuery<SettingsComponentWebauthnLoginStartQuery>(Env, webauthnLoginParams, {}).subscribe({
      next: (params) => {
        console.log({ params })
        navigator.credentials
          .get({
            publicKey: {
              challenge: base64ToArrayBuffer(params.webauthnLoginStart.challenge),
              allowCredentials: [],
            },
          })
          .then((newCredential) => {
            const data = newCredential as PublicKeyCredential
            commitWebauthnLogin({
              variables: {
                input: {
                  sessionId: params.webauthnLoginStart.sessionId,
                  authenticatorData: arrayBufferToBase64(
                    (data.response as AuthenticatorAssertionResponse).authenticatorData
                  ),
                  userHandle: arrayBufferToBase64((data.response as AuthenticatorAssertionResponse).userHandle!),
                  sig: arrayBufferToBase64((data.response as AuthenticatorAssertionResponse).signature),
                  rawId: arrayBufferToBase64(data.rawId),
                  clientDataJson: arrayBufferToString(data.response.clientDataJSON),
                },
              },
              onCompleted: (resp) => {
                console.log({ resp })
                setWebauthnMessage('Logged in as ' + resp.webauthnLoginEnd?.user.username)
              },
              onError: (err) => {
                console.log(err)
                setWebauthnMessage('Error: ' + err)
              },
            })
          })
      },
    })
  }

  const removePasskey = ({ publicKey }) => {
    webauthnRegisteredKeyDelete({
      variables: {
        input: {
          publicKey: publicKey,
        },
      },
      onCompleted: (resp) => {
        console.log({ resp })
        setHack(hack + 1)
      },
    })
  }

  return (
    <div className="settings">
      <div className="inner">
        <section>
          <div className="title">{t('globalSettings.privacy.heading', 'Privacy')}</div>
          <div className="body grid grid-cols-1 md:grid-cols-3 gap-18">
            <label className="checkbox" onClick={() => toggleUserPreference(UserProp.PRIVATE)}>
              <div>{t('globalSettings.privacy.privateUser', 'Private user')}</div>
              <input type="checkbox" checked={privateUser} />
              <div className="mark"></div>
            </label>
            <label className="checkbox" onClick={() => toggleUserPreference(UserProp.SOUND_ENABLED)}>
              <div>{t('globalSettings.privacy.soundOff', 'Sound off')}</div>
              <input type="checkbox" checked={soundEnabled} />
              <div className="mark"></div>
            </label>
            <label className="checkbox" onClick={() => setModalView(<Toggle2FA />)}>
              <div>{t('globalSettings.privacy.2FA', '2FA')}</div>
              <input type="checkbox" checked={user?.otpEnabled} />
              <div className="mark"></div>
            </label>
          </div>
        </section>

        <section>
          <div className="title">{t('globalSettings.theme.heading', 'Select page theme')}</div>
          <div className="body grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-18">
            {THEME_NAMES.map((x) => {
              const name = capitalizeFirstLetter(x.split('_').join(' '))
              const theme = x
                .split('_')
                .map((y) => y.substring(0, 1))
                .join('')

              return (
                <label className="radio" key={x}>
                  <div className="name">{name}</div>
                  <input
                    type="radio"
                    name="theme"
                    value={theme}
                    checked={theme === activeTheme}
                    onChange={(e) => {
                      setUserPreference(UserProp.BACKGROUND, e.target.value)
                    }}
                  />
                  <div className="bg"></div>
                </label>
              )
            })}
          </div>
        </section>

        <section>
          <div className="title">{t('globalSettings.cardTheme.heading', 'Select card theme')}</div>
          <div className="body grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-18">
            {CARD_THEMES.map((x, i) => (
              <label className={`radio lg:col-span-2 ${i === 0 ? 'lg:col-start-2' : ''}`} key={x}>
                <div className="name">{capitalizeFirstLetter(x)}</div>
                <input
                  type="radio"
                  name="cardTheme"
                  value={x}
                  checked={x === activeCardTheme}
                  onChange={(e) => {
                    setUserPreference(UserProp.CARD_THEME, e.target.value)
                  }}
                />
                <div className="bg"></div>
              </label>
            ))}
          </div>
        </section>

        <section>
          <div className="title">{t('globalSettings.cardBackground.heading', 'Select card background')}</div>
          <div className="body grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-18">
            {CARD_BACKGROUND.map((x) => {
              const theme = x.toString()

              return (
                <label className="radio" key={x}>
                  <div className="name">
                    {t('globalSettings.cardBackground.theme', 'Theme')}-{theme}
                  </div>
                  <input
                    type="radio"
                    name="cardBackground"
                    value={theme}
                    checked={theme === activeCardBackground}
                    onChange={(e) => {
                      setUserPreference(UserProp.CARD_BACK, e.target.value)
                    }}
                  />
                  <div className="bg"></div>
                </label>
              )
            })}
          </div>
        </section>

        <section className="passkey">
          <div className="ml-20 p-10">
            <p className="text-xl font-bold">{webauthnMessage}</p>
            <button className="block bg-slateblue p-10 rounded" onClick={addPasskey}>
              {t('globalSettings.passKey.addNew', 'Add new passkey')}
            </button>
            <br />
            <button className="block bg-slateblue p-10 rounded" onClick={loginUsingPasskey}>
              {t('globalSettings.passKey.login', 'Login using passkey')}
            </button>
            {keys && (
              <div>
                <h2>{t('globalSettings.passKey.registeredPasskeys', 'Registered passkeys')}</h2>
                <table>
                  <tr>
                    <td>{t('globalSettings.passKey.created', 'Created')}</td>
                    <td>{t('globalSettings.passKey.publicKey', 'Public key')}</td>
                    <td />
                  </tr>
                  {keys.webauthnRegisteredKeys.map((key, i) => (
                    <tr key={i}>
                      <td>{key.insertedAt}</td>
                      <td className="text-xs">{key.publicKey}</td>
                      <td>
                        <button className="bg-pink p-10 rounded" onClick={() => removePasskey(key)}>
                          {t('globalSettings.passKey.remove', 'Remove')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getUserPropOr = (key: UserProp, def?: any) => {
  const user = store.getState().authentication.user
  if (!user) {
    return def
  }
  const p = user.preferences.find((e) => {
    return e.key === key
  })
  if (!p) {
    return def
  }
  return p.value
}

export const getUserPropOrJSONValue = (key: UserProp, def?: any) => {
  const user = store.getState().authentication.user
  if (!user) {
    return def
  }
  const p = user.preferences.find((e) => {
    return e.key === key
  })
  if (!p) {
    return def
  }
  return p.valueJson
}

const webauthnRegisteredKeyDeleteMutation = graphql`
  mutation SettingsComponentwebauthnRemoveKeyMutation($input: WebauthnRemoveKeyInput!) {
    webauthnRemoveKey(input: $input) {
      ok
    }
  }
`

const webauthnRegisteredKeysQuery = graphql`
  query SettingsComponentwebauthnRegisteredKeysQuery {
    webauthnRegisteredKeys {
      publicKey
      insertedAt
      aaguid
    }
  }
`

const webauthnRegisterStart = graphql`
  mutation SettingsComponentWebauthnRegisterStartMutation($input: WebauthnRegisterStartInput!) {
    webauthnRegisterStart(input: $input) {
      params {
        challenge
        rpId
        username
        userId
        attestation
        sessionId
      }
    }
  }
`

const webauthnLoginParams = graphql`
  query SettingsComponentWebauthnLoginStartQuery {
    webauthnLoginStart {
      challenge
      rpId
      sessionId
    }
  }
`

const webauthnRegisterComplete = graphql`
  mutation SettingsComponentWebauthnRegisterEndMutation($input: WebauthnRegisterEndInput!) {
    webauthnRegisterEnd(input: $input) {
      ok
      token
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        chatApproval
        insertedAt
        kycState
        refProps
        hasProfileImage
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

const webauthnLoginComplete = graphql`
  mutation SettingsComponentWebauthnLoginEndMutation($input: WebauthnLoginEndInput!) {
    webauthnLoginEnd(input: $input) {
      token
      user {
        username
      }
    }
  }
`

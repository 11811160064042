import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { LoginHeader } from '../Login/Login'
import IEnabled2FA from './Enabled2FA.interface'
import styles from '../Login/Login.module.scss'
import { AppTypes } from '../../../reducers/app.reducer'
import { store } from '../../../store'
import AccountCreated from '../AccountCreated/AccountCreated'

const Enabled2FA: React.FC<IEnabled2FA> = () => {
  const { t } = useTranslation()
  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  return (
    <div className={styles.root}>
      <LoginHeader activeTab={t('authentication.forgotPassWord', 'Forgot password')} />
      <div className={styles.body}>
        <img src="/assets/auth/2FA.png" className="w-200 mx-auto mb-40" alt="2FA is activated" />
        <div className={styles.infoLarge}>
          <Trans i18nKey="authentication.enabled2FA.congrats" components={{ 0: <br /> }}>
            Congrats!
            <br />
            You activated 2FA.
            <br />
            Your account security is stronger.
          </Trans>
        </div>
      </div>
      <div className={styles.footer}>
        <button
          type="submit"
          className=""
          onClick={(e) => {
            e.preventDefault()
            const show_welcome_popup = localStorage.getItem('show_welcome_popup')
            if (show_welcome_popup) {
              localStorage.removeItem('show_welcome_popup')
              setModalView(<AccountCreated />)
            } else {
              setModalView(null)
            }
          }}
        >
          {t('authentication.enabled2FA.okGreat', 'Okay, great!')}
        </button>
      </div>
    </div>
  )
}

export default Enabled2FA

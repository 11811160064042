/**
 * @generated SignedSource<<8b34478bdab35bc80aa8afedda85d15f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type WithdrawsFilter = {
  currency?: Currency | null;
};
export type HistoryViewComponentWithdrawsQuery$variables = {
  page: number;
  filter: WithdrawsFilter;
};
export type HistoryViewComponentWithdrawsQuery$data = {
  readonly withdraws: {
    readonly entries: ReadonlyArray<{
      readonly amount: number;
      readonly currency: Currency;
      readonly isProcessed: boolean;
      readonly txid: ReadonlyArray<string> | null;
      readonly isAuthenticated: boolean;
      readonly address: string;
      readonly insertedAt: string;
      readonly uuid: string;
      readonly meta: any;
    }>;
    readonly pageNumber: number | null;
    readonly pageSize: number | null;
    readonly totalEntries: number | null;
    readonly totalPages: number | null;
  } | null;
};
export type HistoryViewComponentWithdrawsQuery = {
  variables: HistoryViewComponentWithdrawsQuery$variables;
  response: HistoryViewComponentWithdrawsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      }
    ],
    "concreteType": "WithdrawPage",
    "kind": "LinkedField",
    "name": "withdraws",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Withdraw",
        "kind": "LinkedField",
        "name": "entries",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isProcessed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "txid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAuthenticated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insertedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pageNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pageSize",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalEntries",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPages",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HistoryViewComponentWithdrawsQuery",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HistoryViewComponentWithdrawsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ab21cfcad3219e63ee5a290c9f52840c",
    "id": null,
    "metadata": {},
    "name": "HistoryViewComponentWithdrawsQuery",
    "operationKind": "query",
    "text": "query HistoryViewComponentWithdrawsQuery(\n  $page: Int!\n  $filter: WithdrawsFilter!\n) {\n  withdraws(page: $page, filter: $filter) {\n    entries {\n      amount\n      currency\n      isProcessed\n      txid\n      isAuthenticated\n      address\n      insertedAt\n      uuid\n      meta\n    }\n    pageNumber\n    pageSize\n    totalEntries\n    totalPages\n  }\n}\n"
  }
};
})();

(node as any).hash = "ece512cdb54cf64bd11501e385729ed7";

export default node;

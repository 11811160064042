import React from 'react'

import { Chip } from './Chip'
import { ClaimReward } from './ClaimReward'

import classes from './LoyaltyReward.module.scss'

export function Cashback() {
  const hasRewards = true

  return (
    <>
      <Chip heading="cashback" percentage={20} />
      {hasRewards ? (
        <ClaimReward label="Great news, you received your CASHBACK">
          <span>
            200
            <br />
            USDT
          </span>
        </ClaimReward>
      ) : (
        <p className={classes.noEntriesFoundText}>No cashback available</p>
      )}
    </>
  )
}

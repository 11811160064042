const ChipIcon = ({ width = '16', height = '16', ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M7 14C3.13969 14 0 10.8603 0 7C0 3.13969 3.13969 0 7 0C10.8603 0 14 3.13969 14 7C14 10.8603 10.8603 14 7 14ZM7 0.897743C3.63466 0.897743 0.89775 3.63466 0.89775 7C0.89775 10.3653 3.63466 13.1023 7 13.1023C10.3653 13.1023 13.1023 10.3653 13.1023 7C13.1023 3.63466 10.3653 0.897743 7 0.897743Z"
      fill="white"
    />
    <path
      d="M9.40689 9.85338C9.29285 9.85338 9.17881 9.80971 9.08903 9.72236C8.91433 9.54766 8.91433 9.26378 9.08903 9.08908C9.96251 8.21559 10.2003 6.87625 9.68106 5.75771C9.57673 5.53448 9.67379 5.26759 9.89944 5.16326C10.1227 5.05892 10.3896 5.15598 10.4939 5.38163C11.1708 6.83986 10.8603 8.5844 9.72231 9.72236C9.63496 9.80971 9.52092 9.85338 9.40446 9.85338H9.40689Z"
      fill="white"
    />
    <path
      d="M7.00245 10.8506C6.0028 10.8506 5.0177 10.4624 4.27766 9.72236C3.54976 8.99446 3.14941 8.02877 3.14941 7C3.14941 5.97123 3.54976 5.00555 4.27766 4.27764C5.77957 2.77574 8.22047 2.77574 9.72238 4.27764C9.89708 4.45234 9.89708 4.73622 9.72238 4.91092C9.54768 5.08561 9.2638 5.08561 9.0891 4.91092C7.93659 3.7584 6.06346 3.7584 4.91094 4.91092C4.35288 5.46898 4.04474 6.21144 4.04474 7C4.04474 7.78856 4.35288 8.53102 4.91094 9.08908C5.80384 9.98198 7.16743 10.2101 8.30054 9.65442C8.52376 9.54524 8.79066 9.63744 8.89984 9.85824C9.00903 10.0815 8.91683 10.3484 8.69603 10.4575C8.15738 10.722 7.57749 10.8506 7.00245 10.8506Z"
      fill="white"
    />
    <path
      d="M12.3137 8.95805C12.2676 8.95805 12.2215 8.95077 12.1754 8.93622C11.6344 8.75909 11.2559 8.20104 11.2559 7.57504V6.42738C11.2559 5.64609 11.8309 5.00797 12.537 5.00797H13.3692C13.6167 5.00797 13.8181 5.20935 13.8181 5.45684C13.8181 5.70433 13.6167 5.90572 13.3692 5.90572H12.537C12.3307 5.90572 12.1512 6.15077 12.1512 6.42738V7.57504C12.1512 7.84437 12.304 8.03605 12.452 8.08457C12.6874 8.16222 12.816 8.41455 12.7408 8.64991C12.6801 8.83916 12.503 8.96048 12.3137 8.96048V8.95805Z"
      fill="white"
    />
    <path
      d="M1.46568 8.99202H0.633445C0.385958 8.99202 0.18457 8.79064 0.18457 8.54315C0.18457 8.29566 0.385958 8.09427 0.633445 8.09427H1.46568C1.67192 8.09427 1.85147 7.84921 1.85147 7.57261V6.42495C1.85147 6.15562 1.69861 5.96395 1.5506 5.91542C1.31525 5.84021 1.18665 5.58543 1.26187 5.35008C1.33708 5.11472 1.59185 4.98613 1.8272 5.06134C2.36828 5.23604 2.74679 5.79653 2.74679 6.42253V7.57018C2.74679 8.35147 2.17175 8.9896 1.46568 8.9896V8.99202Z"
      fill="white"
    />
    <path
      d="M7.57488 2.74663H6.42723C5.64594 2.74663 5.00781 2.17159 5.00781 1.46552V0.633278C5.00781 0.38579 5.2092 0.184402 5.45669 0.184402C5.70417 0.184402 5.90556 0.38579 5.90556 0.633278V1.46552C5.90556 1.67176 6.15062 1.85131 6.42723 1.85131H7.57488C7.84421 1.85131 8.03589 1.69844 8.08441 1.55044C8.16206 1.31508 8.41439 1.18648 8.64975 1.2617C8.8851 1.33692 9.0137 1.59169 8.93849 1.82704C8.76136 2.36812 8.20331 2.74663 7.57731 2.74663H7.57488Z"
      fill="white"
    />
    <path
      d="M8.54576 13.8156C8.29827 13.8156 8.09688 13.6142 8.09688 13.3667V12.5345C8.09688 12.3283 7.85182 12.1487 7.57522 12.1487H6.42756C6.15824 12.1487 5.96656 12.2991 5.91803 12.4496C5.84281 12.6849 5.58804 12.8135 5.35269 12.7383C5.11733 12.6631 4.98874 12.4083 5.06395 12.173C5.24108 11.6319 5.79914 11.2534 6.42514 11.2534H7.57279C8.35407 11.2534 8.9922 11.8284 8.9922 12.5345V13.3667C8.9922 13.6142 8.79082 13.8156 8.54333 13.8156H8.54576Z"
      fill="white"
    />
    <path
      d="M11.462 4.84298C11.0883 4.84298 10.7049 4.6877 10.4162 4.39897L9.60338 3.58614C9.05018 3.03293 9.0065 2.17643 9.50632 1.67661L10.0959 1.087C10.2706 0.912304 10.5545 0.912304 10.7292 1.087C10.9039 1.2617 10.9039 1.54558 10.7292 1.72028L10.1396 2.30988C9.99402 2.45546 10.0401 2.7539 10.2367 2.95286L11.0495 3.76569C11.2412 3.95737 11.4814 3.98406 11.6221 3.9137C11.8429 3.80209 12.1122 3.88943 12.2238 4.11023C12.3354 4.33103 12.2481 4.60035 12.0273 4.71196C11.8526 4.80173 11.6585 4.84541 11.4595 4.84541L11.462 4.84298Z"
      fill="white"
    />
    <path
      d="M3.59097 13.044C3.47694 13.044 3.3629 13.0004 3.27312 12.913C3.09843 12.7383 3.09843 12.4544 3.27312 12.2797L3.86272 11.6901C3.94765 11.6052 3.9525 11.4863 3.94765 11.4232C3.93794 11.2898 3.87243 11.1539 3.76567 11.0471L2.95284 10.2343C2.76116 10.0426 2.52095 10.016 2.38022 10.0863C2.15943 10.1979 1.89011 10.1106 1.7785 9.88978C1.66688 9.66898 1.75423 9.39966 1.97503 9.28805C2.48213 9.02843 3.14695 9.15703 3.58855 9.59862L4.40137 10.4114C4.65856 10.6686 4.81627 11.0035 4.84296 11.3529C4.87208 11.7217 4.74591 12.0735 4.49842 12.321L3.90882 12.9106C3.82148 12.9979 3.70744 13.0416 3.59097 13.0416V13.044Z"
      fill="white"
    />
    <path
      d="M9.69075 12.2724C9.52818 12.2724 9.37047 12.1827 9.2904 12.0274C9.03078 11.5203 9.15938 10.8555 9.60097 10.4139L10.4138 9.60105C10.967 9.04784 11.8235 9.00416 12.3233 9.50399L12.9129 10.0936C13.0876 10.2683 13.0876 10.5522 12.9129 10.7269C12.7382 10.9016 12.4544 10.9016 12.2797 10.7269L11.6901 10.1373C11.5445 9.99169 11.246 10.0378 11.0471 10.2343L10.2342 11.0471C10.0426 11.2388 10.0159 11.479 10.0862 11.6198C10.1979 11.8406 10.1105 12.1099 9.88971 12.2215C9.8242 12.2555 9.75626 12.27 9.6859 12.27L9.69075 12.2724Z"
      fill="white"
    />
    <path
      d="M2.54533 4.84298C2.21292 4.84298 1.90234 4.71923 1.67912 4.49359L1.08952 3.90399C0.914822 3.72929 0.914822 3.4454 1.08952 3.27071C1.26422 3.09601 1.54809 3.09601 1.72279 3.27071L2.31239 3.86031C2.39731 3.94524 2.51621 3.95008 2.57929 3.94523C2.71274 3.93552 2.84861 3.87001 2.95537 3.76325L3.7682 2.95043C3.95988 2.75875 3.98657 2.51611 3.91621 2.37781C3.8046 2.15702 3.89194 1.8877 4.11274 1.77608C4.33354 1.66447 4.60286 1.75182 4.71447 1.97262C4.97409 2.47972 4.84549 3.14454 4.4039 3.58613L3.59108 4.39896C3.33389 4.65615 2.99905 4.81386 2.64966 4.84055C2.61569 4.84298 2.58172 4.84541 2.54775 4.84541L2.54533 4.84298Z"
      fill="white"
    />
  </svg>
)

export default ChipIcon

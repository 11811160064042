import React from 'react'
import { Withdraw, formatBalance } from '@src/reducers/wallet.reducer'
import { Currency } from '../__generated__/DepositViewComponentDepositAddressQuery.graphql'
import { getLogoForCoin } from '@src/components/coinsComponent/CoinsComponent'
import Cancel from '@src/components/icons/cancel'
import ErrorRow from './WithdrawalRow.error'
import WithdrawalProcessed from './WithdrawalRow.processed'

interface Props {
  withdrawal: Withdraw
  index: number
  handleCancelClick: (uuid: any) => void
}

const WithdrawalRow = ({ withdrawal, index, handleCancelClick }: Props) => {
  const mapDate = (date: string) => {
    return date.split(' ').map((x) => {
      return <span key={'split' + x + date}>{x}</span>
    })
  }

  const mapAmount = ({ amount, currency }: { amount: number; currency: Currency }) => {
    return (
      <div className="flex align-middle font-bold text-2xs leading-5">
        <img className="coin-image" src={getLogoForCoin(currency)} alt={currency} />
        {formatBalance({ balance: amount, currency: currency as string }, true, false)}
      </div>
    )
  }

  const hasError = undefined !== withdrawal.meta.reason
  const errorRow = hasError ? (
    <div
      className="tr"
      style={{
        height: '56px',
        marginBottom: 0,
      }}
    >
      <div>{withdrawal.meta.reason}</div>
    </div>
  ) : null

  return (
    <React.Fragment key={'with' + withdrawal.txid + withdrawal.amount + withdrawal.insertedAt + index}>
      <div
        className="tr"
        style={{
          height: '56px',
          marginBottom: 0,
        }}
      >
        <div className="td date-column">{mapDate(withdrawal.insertedAt)}</div>
        <div className="td">{mapAmount(withdrawal)}</div>
        <div
          className={`td status ${withdrawal.isProcessed && !hasError ? 'success' : hasError ? 'failed' : 'pending'}`}
        >
          <WithdrawalProcessed isProcessed={withdrawal.isProcessed} hasError={hasError} />
        </div>
        <div className="td">
          {(!withdrawal.isProcessed || hasError) && (
            <Cancel className="text-pink cursor-pointer" onClick={() => handleCancelClick(withdrawal.uuid)} />
          )}
        </div>
      </div>
      {errorRow}
      <ErrorRow hasError={hasError} reason={withdrawal.meta.reason} />
    </React.Fragment>
  )
}

export default WithdrawalRow

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useEffect, useState } from 'react'
import { serverTimeDiff } from '../../App'
import { store } from '../../store'
import { Game } from '../inHouseGames/Blackjack'
import './TablesComponent.scss'
import { TableConstant } from '../../reducers/multiplayer.reducer'
import { openTableTask as openTable, tablesRemoved, tableUpdated } from '../inHouseGames/InHouseGame'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import Timer from '../Timer'

export interface Player {
  playerUuid: string
  playerName?: string
  slotIdx: number
  playerBalance?: number
  kicked: boolean
}

export interface Table {
  uuid: string
  players: Player[]
  maxPlayers: number
  currency: string
  state: 'waiting_for_bets' | 'playing' | 'showdown' | 'waiting_for_game'
  speed: number
  name: string
  game?: Game
  timer?: number
  type: 'REGULAR' | 'BONUS_TABLE' | 'TOURNAMENT_TABLE' | 'SIT_AND_GO' | 'FREEROLL'
  virtualId: number
  meta?: { [key: string]: any }
  gameCount: number
  tableIndex: number
}

export const TablesComponent = ({ history }: any) => {
  const [openedTableRows, setOpenedTableRows] = useState<string[]>([])
  const [tables, setTables] = useState<Table[]>([])

  if (tables.length === 0 && store.getState().multiplayer.tables.length > 0) {
    setTables(store.getState().multiplayer.tables)
  }
  useEffect(() => {
    if (tables.length === 0) {
      return store.subscribe(() => {
        if (tables.length === 0 && store.getState().multiplayer.tables.length > 0) {
          setTables([...store.getState().multiplayer.tables])
        }
      })
    }
  }, [tables])

  useEffect(() => {
    const subscription = tableUpdated.subscribe((event) => {
      const idx = tables.findIndex((x) => x.uuid === event.uuid)
      if (idx < 0) {
        setTables([event, ...tables])
      } else {
        tables[idx] = event
        setTables([...tables])
      }
    })

    const removeSubs = tablesRemoved.subscribe((removed) => {
      const newTables = tables.filter((x) => removed.findIndex((r) => r.uuid === x.uuid) < 0)
      setTables([...newTables])
    })

    return () => {
      removeSubs.unsubscribe()
      subscription.unsubscribe()
    }
  }, [tables])

  if (tables.length === 0) {
    return null
  }

  const toggleRow = (table: Table) => {
    const idx = openedTableRows.indexOf(table.uuid)
    if (idx >= 0) {
      openedTableRows.splice(idx, 1)
      setOpenedTableRows([...openedTableRows])
    } else {
      setOpenedTableRows([...openedTableRows, table.uuid])
    }
  }
  const myUUID = store.getState().authentication.user?.uuid

  const openTableTask = (table: Table) => {
    history.push('/game/bitcoin-blackjack/table/' + table.uuid)
    store.dispatch({ type: TableConstant.SET_OPENED_TABLE, table })
    openTable.next(table)
  }

  const tableStarts = (table: Table) => {
    const allTablesOnGroup = tables.filter((x) => x.virtualId === table.virtualId)
    const myUUID = store.getState().authentication.user?.uuid
    const idx = allTablesOnGroup.findIndex((x) => x.players.findIndex((y) => y.playerUuid === myUUID) >= 0)
    if (idx >= 0) {
      openTableTask(allTablesOnGroup[idx])
    }
  }

  const joinTable = (table: Table) => {
    const allTablesOnGroup = tables.filter((x) => x.virtualId === table.virtualId)
    for (let i = 0; i < allTablesOnGroup.length; i++) {
      const tableCandiate = allTablesOnGroup[i]
      if (tableCandiate.players.length < tableCandiate.maxPlayers) {
        const isSlotUsed = (idx: number) => {
          return tableCandiate.players.findIndex((x) => x.slotIdx === idx) >= 0
        }
        for (let j = 0; j < tableCandiate.maxPlayers; j++) {
          if (!isSlotUsed(j)) {
            // Just join
            store.dispatch({ type: TableConstant.SIT_TABLE, tableUuid: tableCandiate.uuid, idx: j })
            return
          }
        }
      }
    }
    openTableTask(table)
  }

  const makeGroupHeader = (table: Table) => {
    let joined = table.type.split('_').join(' ')
    let timerT: JSX.Element | null = null
    if (table.name?.length > 0) {
      joined = table.name
    }

    const allTablesOnGroup = tables.filter((x) => x.virtualId === table.virtualId)
    const usedSlots = allTablesOnGroup.reduce((acc, val) => acc + val.players.length, 0)
    const totalSlots = allTablesOnGroup.reduce((acc, val) => acc + val.maxPlayers, 0)
    let currency = table.currency

    if (table.type === 'TOURNAMENT_TABLE' || table.type === 'FREEROLL') {
      const now = (new Date().getTime() + serverTimeDiff) / 1000
      const tournamentStart = table.meta?.start_time
      if (now < tournamentStart) {
        let timeRemaningClass = 'timer '
        const r = tournamentStart - now
        if (r < 60 * 60) {
          timeRemaningClass += 'red'
        } else if (r < 60 * 60 * 3) {
          timeRemaningClass += 'yellow'
        } else if (r < 60 * 60 * 5) {
          timeRemaningClass += 'green'
        }

        timerT = (
          <span className={timeRemaningClass}>
            <span className="starts-in">Starts in: </span>
            <Timer done={() => tableStarts(table)} endTime={tournamentStart} />
          </span>
        )
      }
    }
    if (table.type === 'FREEROLL') {
      currency = table.meta?.freeroll_win_currency
    }
    const isOpen = openedTableRows.findIndex((x) => x === table.uuid) >= 0
    const icon = isOpen ? faAngleUp : faAngleDown
    const isJoined = allTablesOnGroup.findIndex((t) => t.players.findIndex((x) => x.playerUuid === myUUID) >= 0)
    const isFreeroll = table.type === 'FREEROLL'
    return (
      <React.Fragment>
        <div className="img">
          <img src={getLogoForCoin(currency)} alt="" />
        </div>
        <span className="table-name">{joined.substring(0, 1).toUpperCase() + joined.substring(1)}</span>
        <span>
          {usedSlots}/{totalSlots}
        </span>
        <span>{currency}</span>
        <div>
          {isFreeroll && isJoined < 0 && usedSlots < totalSlots ? (
            <button onClick={() => joinTable(table)} className="open-btn-table tight sit">
              <span>SIT</span>
            </button>
          ) : (
            <button
              onClick={() => openTableTask(isJoined >= 0 ? allTablesOnGroup[isJoined] : table)}
              className="open-btn-table tight open"
            >
              <span>Open</span>
            </button>
          )}
        </div>
        <div className="caret" onClick={() => toggleRow(table)}>
          <FontAwesomeIcon icon={icon} />
        </div>
        {timerT}
      </React.Fragment>
    )
  }

  const multiplayerRow = (table: Table, addOffset?: boolean) => {
    let name = table.name
    let currency = table.currency
    let timerT
    let players = (
      <span>
        {table.players.length} / {table.maxPlayers}
      </span>
    )
    if (table.type === 'REGULAR' && table.name.startsWith('Casual')) {
      name = 'Casual table'
    } else if (table.type === 'BONUS_TABLE') {
      name = 'Bonus table'
    } else if ((table.type === 'TOURNAMENT_TABLE' || table.type === 'FREEROLL') && !addOffset) {
      name = table.name
      const now = (new Date().getTime() + serverTimeDiff) / 1000
      const tournamentStart = table.meta?.start_time
      if (now < tournamentStart) {
        timerT = (
          <span className="timer yellow">
            <span className="starts-in">Starts in: </span>
            <Timer done={() => tableStarts(table)} endTime={tournamentStart} />
          </span>
        )
      }
    } else if (table.type === 'SIT_AND_GO') {
      name = table.name
    }

    if (table.type === 'FREEROLL') {
      currency = table.meta?.freeroll_win_currency
    }
    if (addOffset) {
      name += ' - ' + (table.tableIndex + 1)
    }

    return (
      <div key={table.uuid} className={'table-row ' + table.type}>
        <div className="img">
          <img src={getLogoForCoin(currency)} alt="" />
        </div>
        <div className="table-name">{name}</div>
        <div>{players}</div>
        <span>{currency}</span>
        <div>
          <button onClick={() => openTableTask(table)} className="open-btn-table open">
            <span>Open</span>
          </button>
        </div>
        <div className="caret empty" />
        {timerT}
      </div>
    )
  }

  const multiplayerGames = tables
    .sort((a, b) => {
      let startTimeA = 0
      let startTimeB = 0
      if (a.meta?.start_time) {
        startTimeA = a.meta.start_time
      }
      if (b.meta?.start_time) {
        startTimeB = b.meta.start_time
      }
      return startTimeA === startTimeB ? 0 : startTimeA > startTimeB ? 1 : -1
    })
    .filter((x) => x.tableIndex === 0)
    .map((table) => {
      const myChilds = tables.filter((x) => table.virtualId > 0 && x.virtualId === table.virtualId)
      const open = myChilds.length > 1 && openedTableRows.indexOf(table.uuid) >= 0
      if (myChilds.length > 1) {
        return (
          <React.Fragment key={table.uuid}>
            <div className={'table-row ' + table.type + (open ? ' table-row-open' : '')}>{makeGroupHeader(table)}</div>
            {open ? <div className="mychilds">{myChilds.reverse().map((x) => multiplayerRow(x, true))}</div> : null}
          </React.Fragment>
        )
      } else {
        return multiplayerRow(table)
      }
    })

  /*const leave = openedTable?.type === 'sit_and_go' && this.state.openedTable?.game_count === 0 ?
        <div onClick={() => this.leaveTable()} className="sp">Sit out</div>
      :
      (
        <React.Fragment>
          <div onClick={() => openTableTask.next(null)} className="sp">Single Player Table</div>
        </React.Fragment>
        )
      ;
      */

  return (
    <div className="table-list">
      {/*leave */}
      {multiplayerGames}
    </div>
  )
}

export const listTablesQuery = graphql`
  query TablesComponentTablesQuery($page: Int!, $currency: String) {
    tables(page: $page, currency: $currency) {
      uuid
      players {
        slotIdx
        playerUuid
        playerName
        playerBalance
        kicked
      }
      maxPlayers
      currency
      type
      virtualId
      state
      speed
      timer
      name
      meta
      gameCount
      tableIndex

      game {
        state
        gameId
        sp
        mp
        splitCount
        insured
        serverSeedHash
        currency
        virtualId
        validMoves {
          canSplit
          canDoubleDown
          canInsurance
          canHit
        }
        summary {
          serverSeed
          serverSeedHash
          clientSeed
          finalShuffle
          finalShuffleHash
          winnings
          details {
            userIdx
            slotId
            winning
          }
        }
        slots {
          slotId
          parentId
          bet
          result
          userUuid
          userName
          userIdx
          cards {
            rank
            suit
            flipped
          }
        }
      }
    }
  }
`

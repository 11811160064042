const CollapseIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0015 6.75C12.0015 6.94891 11.9224 7.13968 11.7818 7.28033C11.6411 7.42098 11.4504 7.5 11.2515 7.5H2.56196L5.78246 10.719C5.9233 10.8598 6.00241 11.0508 6.00241 11.25C6.00241 11.4492 5.9233 11.6402 5.78246 11.781C5.64163 11.9218 5.45063 12.0009 5.25146 12.0009C5.0523 12.0009 4.86129 11.9218 4.72046 11.781L0.220465 7.281C0.15062 7.21133 0.0952053 7.12857 0.0573959 7.03745C0.0195866 6.94633 0.000123978 6.84865 0.000123978 6.75C0.000123978 6.65135 0.0195866 6.55367 0.0573959 6.46255C0.0952053 6.37143 0.15062 6.28867 0.220465 6.219L4.72046 1.719C4.86129 1.57817 5.0523 1.49905 5.25146 1.49905C5.45063 1.49905 5.64163 1.57817 5.78246 1.719C5.9233 1.85983 6.00241 2.05084 6.00241 2.25C6.00241 2.44916 5.9233 2.64017 5.78246 2.781L2.56196 6H11.2515C11.4504 6 11.6411 6.07902 11.7818 6.21967C11.9224 6.36032 12.0015 6.55109 12.0015 6.75ZM15.7515 14.25C15.9504 14.25 16.1411 14.171 16.2818 14.0303C16.4224 13.8897 16.5015 13.6989 16.5015 13.5V0.75C16.5015 0.551088 16.4224 0.360322 16.2818 0.21967C16.1411 0.0790176 15.9504 0 15.7515 0C15.5526 0 15.3618 0.0790176 15.2211 0.21967C15.0805 0.360322 15.0015 0.551088 15.0015 0.75V13.5C15.0015 13.6989 15.0805 13.8897 15.2211 14.0303C15.3618 14.171 15.5526 14.25 15.7515 14.25Z"
      fill="currentColor"
    />
  </svg>
)

export default CollapseIcon

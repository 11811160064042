import { graphql } from 'babel-plugin-relay/macro'

export const submitKYCMutation = graphql`
  mutation submitKYCMutation($input: SubmitKycInfoInput!) {
    submitKycInfo(input: $input) {
      user {
        kycState
      }
    }
  }
`

import { graphql } from 'babel-plugin-relay/macro'
import { useTranslation, Trans } from 'react-i18next'

import { useState } from 'react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from './buyJack.module.scss'
import Select from 'react-select'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import ConfigurationService from '@src/service/ConfigurationService'
import { store } from '@src/store'
import './jack.scoped.scss'
import {
  BuyJackComponentCalculateJackAmountMutation,
  Currency,
} from './__generated__/BuyJackComponentCalculateJackAmountMutation.graphql'
import { useMutation } from 'react-relay'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import { UserProp } from '@src/reducers/authentication.reducer'
import { BuyJackComponentTradeJackMutation } from './__generated__/BuyJackComponentTradeJackMutation.graphql'
import formatError from '../errorFormatter'
import { toast } from 'react-toastify'
import { Money } from '@src/money'

export function BuyJack({ username, closeCallback }: { username?: string; closeCallback: () => void }) {
  const { t } = useTranslation()
  const currencies = Object.keys(ConfigurationService.instance.getTradeableCurrencies())
  const userWallets = store.getState().wallet.wallets
  const [calculateJackAmount] = useMutation<BuyJackComponentCalculateJackAmountMutation>(calculateJackAmountMutation)
  const [tradeJack] = useMutation<BuyJackComponentTradeJackMutation>(tradeJackMutation)

  const [state, setState] = useState<{ openCurrency?: string; strAmount?: string; jackAmount: number }>({
    openCurrency: userWallets[0]?.currency,
    strAmount: undefined,
    jackAmount: 0,
  })

  const getRawAmount = (amount: string) => {
    const c = ConfigurationService.instance.getCurrency(state.openCurrency!)
    const format = getUserPropOr(('unit_' + state.openCurrency) as UserProp, c.format)
    const allCurrencies = Money.listAll()
    const units = Object.keys(allCurrencies[state.openCurrency!].units)
      .map((x) => allCurrencies[state.openCurrency!].units[x])
      .sort((a: any, b: any) => (a.shift > b.shift ? -1 : a.shift === b.shift ? 0 : 1))
    return Money.convertUnit(+amount, c.m_unit, format, units[0].code)
  }

  const setStrAmount = (amount: string) => {
    setState({ openCurrency: state.openCurrency, strAmount: amount, jackAmount: state.jackAmount })

    calculateJackAmount({
      variables: {
        input: {
          currency: state.openCurrency as Currency,
          amount: getRawAmount(amount),
        },
      },
      onCompleted(response, errors) {
        if (errors || !response.calculateJackAmount) {
          console.log(errors)
          return
        }

        const jackAmount = Money.fromInteger(response.calculateJackAmount.result, 'JACK')
        setState({ openCurrency: state.openCurrency, strAmount: amount, jackAmount })
      },
    })
  }

  const getWallet = (currency: string) => {
    const wallet = userWallets.find((x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === 0)
    return wallet
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: '2px 10px',
      backgroundColor: state.selected ? '#18191d' : state.isFocused ? '#212226' : '#212226',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided) => ({
      ...provided,
      color: 'white',
      backgroundColor: 'unset',
      border: '1px solid #3CCEA2',
      borderRadius: '20px',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#212226',
    }),
  }

  const options = currencies
    .filter((currency) => {
      // If user dont have any wallets show all options
      if (userWallets.length === 0) {
        return true
      }
      return (getWallet(currency)?.balance || 0) > 0
    })
    .map((currency) => {
      return {
        value: currency,
        label: currency,
      }
    })

  const doTrade = () => {
    if (state.jackAmount > 0 && state.strAmount) {
      tradeJack({
        variables: {
          input: {
            amount: getRawAmount(state.strAmount),
            currency: state.openCurrency as Currency,
          },
        },
        onCompleted(response, errors) {
          if (errors || !response.tradeJack) {
            console.log(errors)
            return
          }
          const message = t('toast.successBoughtJack', {
            jackAmount: state.jackAmount,
          })
          toast.success(message)
          setState({ openCurrency: state.openCurrency, strAmount: undefined, jackAmount: 0 })
        },
        onError(error) {
          toast.error(formatError(error)[0])
        },
      })
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.header_label}>
          <h2 className="text-sm font-poppins font-medium leading-6 uppercase pt-14 pb-20">
            {t('buyJackModal.getJack', 'GET JACK')}
          </h2>
        </div>
        <FontAwesomeIcon icon={faXmark} className={classes.xMark} onClick={closeCallback} />
      </div>
      <div className={classes.content}>
        <img src="/assets/buy_jack_bg.png" alt="Buy Jack" />
        {/* Copied from TipUsers.tsx */}
        <div className="label-row">
          <span>{t('buyJackModal.amount', 'Amount')}</span>
          <span>{t('buyJackModal.jackPrice', 'Jack price')}</span>
          <span>{t('buyJackModal.coin', 'Coin')}</span>
        </div>
        <div className="input-box">
          <input value={state.strAmount || '0'} onChange={(e) => setStrAmount(e.target.value)} />
          <div className="jack-price">
            <span>0.0006</span>
          </div>
          <Select
            styles={customStyles}
            maxMenuHeight={170}
            defaultValue={{ value: state.openCurrency as string, label: state.openCurrency as string }}
            onChange={(e) => setState({ openCurrency: e!.value, strAmount: undefined, jackAmount: 0 })}
            options={options as any}
            formatOptionLabel={(e) => {
              return (
                <div className="currency-select">
                  <img alt={e.value} src={getLogoForCoin(e.value)} />
                  <span>{e.label}</span>
                </div>
              )
            }}
          />
        </div>
        <div className="jack-amount">
          <img src="/assets/jack.svg" alt="Jack" />
          {state.jackAmount || '0'}
        </div>
        <div>
          <button className="buy-button uppercase" onClick={doTrade}>
            {t('buyJackModal.buyJackNow', 'BUY JACK NOW')}
          </button>
        </div>
      </div>
    </div>
  )
}

const calculateJackAmountMutation = graphql`
  mutation BuyJackComponentCalculateJackAmountMutation($input: CalculateJackAmountInput!) {
    calculateJackAmount(input: $input) {
      result
    }
  }
`

const tradeJackMutation = graphql`
  mutation BuyJackComponentTradeJackMutation($input: TradeJackInput!) {
    tradeJack(input: $input) {
      result
    }
  }
`

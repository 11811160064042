/**
 * @generated SignedSource<<b43e2389e367d0c20c9a5e7be52b2f06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type BecomeAnInvestorGetMyBankrollPaymentsQuery$variables = {
  period: string;
  currency?: Currency | null;
};
export type BecomeAnInvestorGetMyBankrollPaymentsQuery$data = {
  readonly getMyBankrollPayments: ReadonlyArray<{
    readonly date: string;
    readonly amount: number;
    readonly currency: Currency;
    readonly bankroll: {
      readonly active: boolean;
    };
  }>;
};
export type BecomeAnInvestorGetMyBankrollPaymentsQuery = {
  variables: BecomeAnInvestorGetMyBankrollPaymentsQuery$variables;
  response: BecomeAnInvestorGetMyBankrollPaymentsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currency"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "period"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "period",
        "variableName": "period"
      }
    ],
    "concreteType": "BankrollPayment",
    "kind": "LinkedField",
    "name": "getMyBankrollPayments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Bankroll",
        "kind": "LinkedField",
        "name": "bankroll",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BecomeAnInvestorGetMyBankrollPaymentsQuery",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BecomeAnInvestorGetMyBankrollPaymentsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1599fc334014e0780e17b53f3690d2f8",
    "id": null,
    "metadata": {},
    "name": "BecomeAnInvestorGetMyBankrollPaymentsQuery",
    "operationKind": "query",
    "text": "query BecomeAnInvestorGetMyBankrollPaymentsQuery(\n  $period: String!\n  $currency: Currency\n) {\n  getMyBankrollPayments(period: $period, currency: $currency) {\n    date\n    amount\n    currency\n    bankroll {\n      active\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18497ec56e4ad8c6df61a71d463ec079";

export default node;

/**
 * @generated SignedSource<<c3ce0efc671dcbdc42dad2b95d627cb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GrabGiveawayInput = {
  giveawayId: number;
};
export type useChatGrabGiveawayMutation$variables = {
  input: GrabGiveawayInput;
};
export type useChatGrabGiveawayMutation$data = {
  readonly grabGiveaway: {
    readonly result: boolean;
  } | null;
};
export type useChatGrabGiveawayMutation = {
  variables: useChatGrabGiveawayMutation$variables;
  response: useChatGrabGiveawayMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GrabGiveawayPayload",
    "kind": "LinkedField",
    "name": "grabGiveaway",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useChatGrabGiveawayMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useChatGrabGiveawayMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c09d1e0622eee6bb85f0c87d69692d7",
    "id": null,
    "metadata": {},
    "name": "useChatGrabGiveawayMutation",
    "operationKind": "mutation",
    "text": "mutation useChatGrabGiveawayMutation(\n  $input: GrabGiveawayInput!\n) {\n  grabGiveaway(input: $input) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "45b0b59aef3a12815784376404f5a58c";

export default node;

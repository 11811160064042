/**
 * @generated SignedSource<<a5f9eca3973814886d74dfdaea25fb85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadProfileImageInput = {
  imageData?: string | null;
};
export type PlayerProfileNewUploadProfileImageMutation$variables = {
  input: UploadProfileImageInput;
};
export type PlayerProfileNewUploadProfileImageMutation$data = {
  readonly uploadProfileImage: {
    readonly user: {
      readonly uuid: string | null;
      readonly hasProfileImage: boolean;
    };
  } | null;
};
export type PlayerProfileNewUploadProfileImageMutation = {
  variables: PlayerProfileNewUploadProfileImageMutation$variables;
  response: PlayerProfileNewUploadProfileImageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UploadProfileImagePayload",
    "kind": "LinkedField",
    "name": "uploadProfileImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasProfileImage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlayerProfileNewUploadProfileImageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlayerProfileNewUploadProfileImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a653f890e30263c48ba6aa9f5cbf597d",
    "id": null,
    "metadata": {},
    "name": "PlayerProfileNewUploadProfileImageMutation",
    "operationKind": "mutation",
    "text": "mutation PlayerProfileNewUploadProfileImageMutation(\n  $input: UploadProfileImageInput!\n) {\n  uploadProfileImage(input: $input) {\n    user {\n      uuid\n      hasProfileImage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05ec7d4c0198498cc101d70c95418373";

export default node;

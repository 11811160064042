/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Logo = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="35" viewBox="0 0 53 35" fill="none" {...props}>
    <g clipPath="url(#clip0_866_4165)">
      <path
        d="M6.15783 6.40716V8.66537H7.18629C8.10014 8.66537 8.55681 8.28292 8.5563 7.51801C8.5563 7.16041 8.43429 6.88574 8.19025 6.694C7.94622 6.50227 7.5914 6.40665 7.1258 6.40716H6.15783ZM6.15783 4.28134H7.0385C7.45815 4.28134 7.78259 4.19562 8.01182 4.02417C8.24105 3.85273 8.35592 3.59759 8.35643 3.25875C8.35643 2.65515 7.89695 2.35359 6.978 2.3541H6.15783V4.28134ZM3.23249 0.152954H7.00863C8.47998 0.152954 9.572 0.369797 10.2847 0.803483C10.9974 1.23717 11.3548 1.91255 11.3568 2.82963C11.3568 3.43274 11.2014 3.94783 10.8905 4.37493C10.5971 4.78911 10.1612 5.08223 9.66518 5.19893V5.27501C10.3365 5.45153 10.8215 5.74598 11.1202 6.15836C11.4188 6.57074 11.5679 7.10512 11.5674 7.76148C11.5674 8.73791 11.1983 9.50662 10.4601 10.0676C9.72184 10.6286 8.7161 10.9094 7.44284 10.9099H3.23096L3.23249 0.152954Z"
        fill="currentColor"
      />
      <path d="M13.4497 10.9091V0.152954H16.3751V8.56266H20.5425V10.9091H13.4497Z" fill="currentColor" />
      <path
        d="M27.776 6.50988L27.3096 4.74471C27.2009 4.35312 27.0689 3.84589 26.9137 3.223C26.7585 2.60012 26.6564 2.15375 26.6074 1.8839C26.5629 2.13447 26.4754 2.5466 26.3447 3.12028C26.214 3.69397 25.924 4.82409 25.4747 6.51064L27.776 6.50988ZM28.9162 10.9091L28.3802 8.89363H24.8659L24.3184 10.9091H21.1021L24.6339 0.110352H28.5349L32.1111 10.9091H28.9162Z"
        fill="currentColor"
      />
      <path
        d="M38.1037 2.38375C37.4083 2.38375 36.8654 2.66679 36.4748 3.23286C36.0843 3.79894 35.8895 4.58008 35.8905 5.57629C35.8905 7.64581 36.6818 8.68057 38.2645 8.68057C38.7358 8.68136 39.2048 8.61421 39.6567 8.48123C40.1163 8.34533 40.5685 8.18585 41.0114 8.00341V10.4602C40.1078 10.8574 39.0867 11.056 37.9482 11.056C36.3145 11.056 35.0622 10.5852 34.1912 9.64381C33.3202 8.70238 32.8848 7.34172 32.8848 5.56183C32.8848 4.44896 33.0956 3.4705 33.5173 2.62646C33.9133 1.81059 34.548 1.1325 35.3384 0.680964C36.1307 0.227496 37.0624 0.00050855 38.1335 1.3149e-06C39.2948 -0.000674831 40.4411 0.259423 41.487 0.760854L40.591 3.04417C40.2017 2.86213 39.8011 2.70483 39.3917 2.5732C38.9747 2.44342 38.5399 2.37899 38.1029 2.38223"
        fill="currentColor"
      />
      <path
        d="M52.3705 10.9091H49.0692L46.9249 6.80053L46.0588 7.31487V10.9122H43.1235V0.152954H46.0627V4.82383C46.2102 4.53471 46.5089 4.07617 46.9586 3.44821L49.2315 0.152954H52.4303L49.0148 4.97296L52.3705 10.9091Z"
        fill="currentColor"
      />
      <path
        d="M36.2551 34.914H34.5703V28.6165H38.3158V29.9822H36.2551V31.1836H38.1542V32.5493H36.2551V34.914Z"
        fill="currentColor"
      />
      <path
        d="M44.7449 28.6165V32.4078C44.7449 33.232 44.51 33.8702 44.0403 34.3221C43.5707 34.774 42.8937 35 42.0094 35C41.1451 35 40.4814 34.7804 40.0184 34.3411C39.5553 33.9019 39.3233 33.2698 39.3223 32.4451V28.6165H41.0438V32.3127C41.0438 32.7575 41.1275 33.0806 41.295 33.282C41.4624 33.4834 41.7095 33.5848 42.0363 33.5863C42.386 33.5863 42.6397 33.4864 42.7975 33.2866C42.9552 33.0867 43.0338 32.7601 43.0333 32.3066V28.6165H44.7449Z"
        fill="currentColor"
      />
      <path
        d="M52.1109 34.914H49.8648L47.5498 30.4775H47.5108C47.5659 31.1755 47.5935 31.7081 47.5935 32.0753V34.914H46.0757V28.6165H48.3126L50.623 32.9929H50.6483C50.6084 32.3583 50.5883 31.8486 50.5878 31.4636V28.6165H52.114L52.1109 34.914Z"
        fill="currentColor"
      />
      <path
        d="M2.12516 32.0958C1.41237 32.0994 0.701247 32.0272 0.00390625 31.8805V28.7184C0.225477 28.7595 0.460576 28.8056 0.709204 28.8569C0.975151 28.9098 1.2459 28.9353 1.51712 28.933C2.19408 28.933 2.67909 28.7301 2.97213 28.3243C3.26518 27.9185 3.40761 27.2271 3.39945 26.2502V12.3159H7.51407V25.8971C7.51407 27.9352 7.06506 29.478 6.16703 30.5254C5.26901 31.5729 3.92172 32.0963 2.12516 32.0958Z"
        fill="currentColor"
      />
      <path
        d="M18.4895 21.2118L17.8363 18.7406C17.6831 18.1912 17.4983 17.4811 17.2818 16.6102C17.0654 15.7393 16.9219 15.1143 16.8515 14.7354C16.7892 15.0854 16.6666 15.6619 16.4839 16.4649C16.3011 17.2678 15.8952 18.8501 15.2663 21.2118H18.4895ZM20.0854 27.3694L19.3388 24.5482H14.4162L13.6504 27.3694H9.15137L14.0946 12.2505H19.557L24.5623 27.3664L20.0854 27.3694Z"
        fill="currentColor"
      />
      <path
        d="M32.946 15.4354C31.9719 15.4354 31.212 15.8318 30.6662 16.6246C30.1204 17.4175 29.8476 18.5105 29.8476 19.9039C29.8476 22.8013 30.9564 24.2497 33.1742 24.2492C33.8339 24.2509 34.4905 24.1573 35.1231 23.9714C35.7663 23.7807 36.3992 23.5572 37.0192 23.3019V26.741C35.7552 27.2989 34.3257 27.5779 32.7308 27.5779C30.4441 27.5779 28.691 26.9185 27.4713 25.5997C26.2516 24.2809 25.6421 22.3762 25.6426 19.8857C25.6426 18.3264 25.9379 16.9569 26.5286 15.7771C27.0831 14.6348 27.9716 13.6855 29.0779 13.0532C30.1853 12.4166 31.4884 12.0983 32.9873 12.0983C34.6127 12.0973 36.2172 12.4615 37.6809 13.1635L36.4281 16.3591C35.8833 16.104 35.3226 15.8839 34.7494 15.7002C34.166 15.518 33.5575 15.4276 32.946 15.4324"
        fill="currentColor"
      />
      <path
        d="M52.9204 27.3694H48.295L45.3 21.6136L44.0878 22.3341V27.3694H39.9731V12.3152H44.0878V18.854C44.2945 18.4482 44.7124 17.8063 45.3414 16.9282L48.5232 12.3152H53.0008L48.2223 19.0602L52.9204 27.3694Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4625 34.3746C31.3099 34.3781 31.1621 34.3214 31.0514 34.2168C30.9408 34.1123 30.8763 33.9685 30.8721 33.8169C30.8721 33.6168 30.9793 33.5126 31.1263 33.371C31.511 33.0602 31.8312 32.678 32.069 32.2457C32.3095 32.6687 32.6126 33.0534 32.9681 33.387C33.0914 33.511 33.2338 33.6548 33.2323 33.8435C33.2247 33.9951 33.1572 34.1376 33.0445 34.24C32.9318 34.3425 32.783 34.3966 32.6303 34.3906C32.5258 34.3908 32.423 34.3641 32.332 34.313C32.241 34.262 32.1649 34.1883 32.1111 34.0992C32.0973 34.3046 32.2153 34.6006 32.3684 34.9194C32.1387 34.8372 31.9649 34.822 31.7328 34.914C31.8967 34.5732 31.9909 34.2932 31.9871 34.0999C31.9314 34.1876 31.8538 34.2594 31.7619 34.3085C31.6699 34.3575 31.5668 34.3822 31.4625 34.3799"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_866_4165">
        <rect width="53" height="35" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)

export default Logo

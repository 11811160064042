export function arrayBufferToString(buffer: ArrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array( buffer )
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] )
  }
  return binary
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array( buffer )
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] )
  }
  return window.btoa(binary)
}

export function base64ToArrayBuffer(base64: string) {
    const binary_string =  window.atob(base64)
    const len = binary_string.length
    const bytes = new Uint8Array( len )
    for (let i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}
import { Shape } from './Shape'

import { useEffect, useState } from 'react'
import classNames from 'classnames'

import classes from './AppLoader.module.scss'

const allShapes = ['diamond', 'heart', 'club', 'spade'] as const
type IShape = typeof allShapes[number]

export function AppLoader() {
  const [type, setType] = useState<IShape>(allShapes[0])

  useEffect(() => {
    const interval = setInterval(() => {
      setType((prev) => {
        const index = allShapes.indexOf(prev)
        const nextIndex = index + 1 === allShapes.length ? 0 : index + 1

        return allShapes[nextIndex]
      })
    }, 1500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <div
        className={classNames(
          [classes.root],
          'absolute flex w-screen top-0 left-0 items-center justify-center gap-x-20'
        )}
      >
        <img alt="Loader" src="/assets/loader/text.png" className={classes.text} />
        <div className={classes.shapeWrapper}>
          <Shape type={type} className={classes.shape} />
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import classes from './PedestalStand.module.scss'
import classNames from 'classnames'

import { Options, type Option } from './PedestalStand'

export function PedestalStandHeader({
  option,
  setOption,
}: {
  option: Option
  setOption: React.Dispatch<React.SetStateAction<Option>>
}) {
  const { t } = useTranslation()

  return (
    <div className={classes.header}>
      <div>
        <h3 className="uppercase">{t('competitions.pedestal.header', 'TOP LEADERS')}</h3>
      </div>
      <div className={classes.options}>
        {Options.map((o, i) => (
          <React.Fragment key={i}>
            <button
              type="button"
              onClick={() => setOption(o)}
              className={classNames({
                [classes.active]: o === option,
              })}
            >
              {t(`competitions.pedestal.${o}`, o)}
            </button>
            {i !== Options.length - 1 && <span>|</span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

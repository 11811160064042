import { useRef, useEffect } from 'react'
import * as jdenticon from 'jdenticon'

const Jdenticon = ({ value = 'test', size = '100%', className = '' }) => {
  const icon = useRef(null)
  useEffect(() => {
    jdenticon.update(icon.current as unknown as SVGElement, value)
  }, [value])

  return (
    <div className={className}>
      <svg data-jdenticon-value={value} height={size} ref={icon} width={size} />
    </div>
  )
}

export default Jdenticon

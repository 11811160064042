/**
 * @generated SignedSource<<507e986569272f80699adb2a853e7d67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetPermanentDepositAddressInput = {
  chainId: string;
  tokenId: string;
};
export type DepositViewGetPermanentDepositAddressMutation$variables = {
  input: GetPermanentDepositAddressInput;
};
export type DepositViewGetPermanentDepositAddressMutation$data = {
  readonly getPermanentDepositAddress: {
    readonly payAddress: string;
    readonly memo: string | null;
  } | null;
};
export type DepositViewGetPermanentDepositAddressMutation = {
  variables: DepositViewGetPermanentDepositAddressMutation$variables;
  response: DepositViewGetPermanentDepositAddressMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetPermanentDepositAddressPayload",
    "kind": "LinkedField",
    "name": "getPermanentDepositAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "memo",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DepositViewGetPermanentDepositAddressMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DepositViewGetPermanentDepositAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "85408792bdfa33af4837ebdd6873b612",
    "id": null,
    "metadata": {},
    "name": "DepositViewGetPermanentDepositAddressMutation",
    "operationKind": "mutation",
    "text": "mutation DepositViewGetPermanentDepositAddressMutation(\n  $input: GetPermanentDepositAddressInput!\n) {\n  getPermanentDepositAddress(input: $input) {\n    payAddress\n    memo\n  }\n}\n"
  }
};
})();

(node as any).hash = "85612c2041d0444246933554eed9ca13";

export default node;

import React, { useEffect, useRef, useState } from 'react'

import classes from './Collapsible.module.scss'

export function Collapsible({
  children,
  title,
  openOnRender,
  disabled = false,
}: {
  children: React.ReactNode | React.ReactNodeArray
  title: string
  openOnRender?: boolean
  disabled?: boolean
}) {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(openOnRender ?? false)
  const [contentHeight, setContentHeight] = useState<number | null>(null)
  const toggle = () => setIsOpen((prev) => !prev)

  useEffect(() => {
    if (ref.current) {
      setContentHeight(isOpen ? ref.current.scrollHeight : 0)
    }
  }, [isOpen])

  return (
    <div className={classes.root}>
      <button type="button" onClick={toggle} disabled={disabled} className={classes.header}>
        <h4>{title}</h4>
        {isOpen ? (
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L7 1L13 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        ) : (
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L7 7L1 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        )}
      </button>
      <div className="overflow-hidden">
        <div
          ref={ref}
          className={classes.content}
          style={{ maxHeight: contentHeight !== null ? `${contentHeight}px` : 'none' }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

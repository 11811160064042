import { getUserIcon } from '@src/App'
import type { ProfileQueryResponse } from './gql/__generated__/ProfileQuery.graphql'

import classes from './ProfileLevel.module.scss'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'
import { useTranslation } from 'react-i18next'

export function ProfileLevel({ uuid, profile }: { uuid: string; profile: ProfileQueryResponse['profile'] }) {
  const me = useSelector((s: IStore) => s.authentication.user)
  const { t } = useTranslation()
  const isPrivateUser = me?.preferences.find((x) => x.key === 'private')?.value === 'true'
  const isMyProfile = uuid === me?.uuid
  const name = isMyProfile && isPrivateUser ? 'Private user' : profile.name

  return (
    <div className={classes.root}>
      <div className={classes.profile}>{getUserIcon(name, uuid, profile.hasProfileImage, profile.vip!)}</div>
      <div className="flex flex-col text-white text-sm md:text-lg flex-1 font-inter font-medium gap-y-10">
        <div className="flex justify-between items-center">
          <span>{t('profile.profileLevel.heading', 'Current level')}</span>
          <img alt="Bronze" src="/assets/pages/profile/bronze.png" className="pointer-events-none select-none" />
        </div>
        <ProgressBar progress={30} />
        <div className="flex justify-between">
          <div>
            <span
              style={{
                color: '#72787B',
              }}
            >
              {t('profile.profileLevel.unranked', 'Unranked')}
            </span>
          </div>
          <p className={classes.next}>
            {t('profile.profileLevel.next', 'Next')}: <span className={classes.red}>Elite 1</span>{' '}
            <span className={classes.green}>1 - 10 BTC</span>
          </p>
        </div>
      </div>
    </div>
  )
}

const ProgressBar = ({ progress }: { progress: number }) => (
  <div className={classes.progressBar}>
    <div className={classes.progress} style={{ width: `${progress}%` }} />
  </div>
)

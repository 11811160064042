import React from 'react'
import { useTranslation } from 'react-i18next'

import classes from './PedestalStandContent.module.scss'
import { getUserIcon } from '@src/App'
import { formatFiatRaw } from '../rightSide/walletComponent/WalletComponent'
import { Option } from './PedestalStand'
import classNames from 'classnames'

import { store } from '@src/store'
import { useCompetitions } from '@src/context/CompetitionsContext'
import { Money } from '@src/money'

export function PedestalStandContent({ option, bright = false }: { option: Option; bright?: boolean }) {
  const { competitions } = useCompetitions()
  const { t } = useTranslation()

  const daily = competitions.find((x) => x.name === 'Daily WAGERING COMPETITION')
  const weekly = competitions.find((x) => x.name === 'Weekly WAGERING COMPETITION')

  const competition = option === 'DAILY CHAMPIONS' ? daily : weekly
  if (!competition) return null

  const currency = competition.currency

  const top3 =
    option === 'DAILY CHAMPIONS'
      ? daily?.runningRound?.players?.slice(0, 3) ?? []
      : weekly?.runningRound?.players?.slice(0, 3) ?? []

  return (
    <div
      className={classNames({
        [classes.root]: !bright,
        [classes.root_bright]: bright,
      })}
    >
      {top3.map((p, i) => {
        const place = i + 1

        const plate3attributes = {
          width: '63',
          height: '19',
        }

        const payout = competition.payoutStr[i]

        const rewardAsBTC = (competition.price * payout.amount) / 100
        const reward = Money.fromInteger(rewardAsBTC, currency)

        const wagered = Math.trunc(
          formatFiatRaw(store.getState().wallet.rates, {
            balance: p.amount,
            currency: 'BTC',
          }) ?? 0
        )

        return (
          <div key={i} className={classes.wrapper}>
            <div className={classes.player}>
              <div className={classes.placement}>
                <span>{place}</span>
              </div>
              <div className={classes.imageWrapper}>
                {getUserIcon(p.user.name, p.user.uuid, p.user.hasProfileImage, { ...p.user.vip })}
              </div>
              <div
                className={classNames({
                  [classes.plateWrapper_first]: place === 1,
                  [classes.plateWrapper]: place !== 1,
                })}
              >
                <Plate1 place1={place === 1} text={p.user.name} />
                <Plate2 place1={place === 1}>
                  {/* +{reward} {currency} */}+{reward} USD
                </Plate2>
                <Plate3 {...plate3attributes} text={`${t('competitions.pedestal.wagered', 'Wagered')} $${wagered}`} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Plate1 = ({ place1, text }: { place1: boolean; text: string }) => {
  return (
    <div className={place1 ? classes.plate1_first : classes.plate1}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 19" fill="none">
        <path
          d="M3.57617 0.730713H59.8413L62.4638 9.47564L59.8413 18.2206H3.57617L0 9.47564L3.57617 0.730713Z"
          fill="#FFD600"
        />
      </svg>
      <span className="absolute">{text}</span>
    </div>
  )
}

const Plate2 = ({ place1, children }: { place1: boolean; children: React.ReactChild | React.ReactChild[] }) => {
  return (
    <div className={place1 ? classes.plate2_first : classes.plate2}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 19" fill="none">
        <path
          d="M4.66258 0.555664H70.9068L75.4087 9.30059L70.9068 18.0455H4.66258L0.452148 9.30059L4.66258 0.555664Z"
          fill="#00FFB3"
        />
      </svg>
      <div className="absolute">{children}</div>
    </div>
  )
}

const Plate3 = ({ width, height, text }: { width: string; height: string; text: string }) => {
  return (
    <div className={classes.plate3}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 63 19" fill="none">
        <path
          d="M3.61523 0.599121H59.8803L62.5028 9.34405L59.8803 18.089H3.61523L0.0390625 9.34405L3.61523 0.599121Z"
          fill="#8D48D5"
        />
      </svg>
      <span className="absolute">{text}</span>
    </div>
  )
}

import { Competition, getUserIcon, PayoutEntry } from '@src/App'
import { formatFiatRaw } from '@src/components/rightSide/walletComponent/WalletComponent'
import { getUserPropOr } from '@src/components/SettingsComponent/SettingsComponent'
import { Currency } from '@src/reducers/__generated__/walletWalletChangedSubscription.graphql'
import type { UserProp } from '@src/reducers/authentication.reducer'
import { formatAmount } from '@src/reducers/wallet.reducer'
import ConfigurationService from '@src/service/ConfigurationService'
import { store } from '@src/store'
import { roundNumberToLength } from '@src/utils/functions'

export type FormattedWin = {
  name: string
  amount: string
  icon: JSX.Element
  reward: string
  payoutType: PayoutEntry['type']
  payoutAmount: number
  currency: any
  bet: {
    bets: number[]
    btcWin: number
    currency: Currency
    multiplier: number
    normalizedGame: {
      label: string | null
      gameId: string | null
      provider: string | null
    }
  }
}

export function getFormattedWin(competition: Competition) {
  const players = competition.runningRound?.players
  const payouts = competition.payoutStr

  if (!players || !payouts) return

  const currency = competition.currency
  const c = ConfigurationService.instance.getCurrency(currency)
  const cformat = getUserPropOr(('unit_' + c.short) as UserProp, c.format)

  const formatterResult = players.map((player, i) => {
    const payout = payouts[i]

    const competitionAmount = roundNumberToLength(
      Number(
        competition.type !== 'GAME_COUNT'
          ? formatFiatRaw(store.getState().wallet.rates, { balance: player!.amount, currency: 'BTC' })
          : player?.amount
      ),
      5
    )

    const icon = getUserIcon(player.user.name, player.user.uuid, player.user.hasProfileImage, { ...player.user.vip })
    const winAmount = formatAmount((competition.price * payout.amount) / 100, currency, false) || ''

    return {
      bet: player.bet,
      name: player.user.name,
      amount:
        competition.name !== 'Daily Bet Count Competition'
          ? Number(
              competitionAmount
                .split('')
                .filter((c) => c !== ',')
                .join('')
            ).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })
          : competitionAmount,
      icon,
      reward: winAmount,
      payoutType: payout.type,
      payoutAmount: payout.amount,
      currency: cformat,
    }
  })

  return formatterResult
}

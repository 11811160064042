import React from 'react'

import { Tier } from './Tier'

import classes from './Tier2.module.scss'

export function Tier2() {
  const image = <img alt="" src="/assets/pages/elite-seat/tier2.png" className={classes.image} />

  return (
    <Tier
      image={image}
      crateImageSRC="/assets/pages/elite-seat/tier2-crate.png"
      text="So you started winning and you like it. We want to reward the time you spend on our platform with rakeback and
    cashback. In addition you receive a big mystery iron box containing coins, freespins and other valueble items
    to use on our platform. We listen to all of our players, but we have a priority line, and you are the next
    one."
    >
      <h2 className="font-poppins font-medium h-min">1</h2>
      <div className="flex flex-col transform translate-y-20">
        <h3 className="text-sm leading-5 font-bold text-white font-inter">
          TIER 2<br />
          <span className="text-pink">Elite Seat 1</span>
        </h3>
        <p className="text-white">
          VIP
          <br />
          <span className="text-eucalyptus">Wager</span> <span className="text-yellow">1 - 10 BTC</span>
          <br />
          <span className="text-lg font-bold">
            5%
            <br />
            rakeback &<br />
            cashback
          </span>
        </p>
        <p className="text-eucalyptus font-medium">
          Mystery Iron box
          <br />
          Support tickets priority
        </p>
      </div>
    </Tier>
  )
}

import { UserGameRenderer } from '../GameRenderer'
import Konva from 'konva'
import { serverTimeDiff } from '../../../App'

export enum ClockType {
  waiting_game_clock,
  user_bet_clock,
  user_move_clock,
}

export class Timer {
  private runningClocks: { type: ClockType; ref: Konva.Group; destroy?: () => void }[] = []

  // Holds waiting_game_timer state
  private lastPos = { angle: 360, rotation: 0 }
  constructor(private renderer: UserGameRenderer) {}

  destroyTimerByType(type: ClockType) {
    const nextIdx = (types: ClockType[]) => {
      return this.runningClocks.findIndex((x) => types.indexOf(x.type) >= 0)
    }

    let typeParam: ClockType[] = []
    // tslint:disable-next-line:switch-default
    switch (type) {
      case ClockType.user_bet_clock:
        typeParam = [ClockType.user_bet_clock]
        break
      case ClockType.user_move_clock:
        typeParam = [ClockType.user_bet_clock, ClockType.user_move_clock]
        break
      case ClockType.waiting_game_clock:
        typeParam = [ClockType.user_bet_clock, ClockType.user_move_clock, ClockType.waiting_game_clock]
        break
    }

    let idx = nextIdx(typeParam)
    while (idx >= 0) {
      const clk = this.runningClocks[idx]
      ;(clk.ref.getAttr('anim') as Konva.Animation).stop()
      if (clk.destroy !== undefined) {
        clk.destroy()
      }
      clk.ref.destroy()
      this.runningClocks.splice(idx, 1)
      idx = nextIdx(typeParam)
    }
  }

  destoryTimerByUser(userUUID: string) {
    const layer = this.renderer.getLayer('mp')
    const lines = layer.find('#user_timer')
    lines.forEach((x) => {
      if (x.attrs.uuid === userUUID) {
        x.destroy()
      }
    })
    layer.batchDraw()
  }

  makeTimer(type: ClockType) {
    const layer = this.renderer.getLayer('mp')

    let timeRemaining = this.calculateRemainingTime(type)
    if (timeRemaining < 0) {
      return
    }

    this.destroyTimerByType(type)
    const clock = new Konva.Group()

    let tickFunc: any
    let destroyFunc: any
    if (type === ClockType.waiting_game_clock) {
      ;[tickFunc, destroyFunc] = this.makeWaitingGameClock(
        clock,
        timeRemaining,
        this.lastPos.angle,
        this.lastPos.rotation
      )
    } else if (type === ClockType.user_bet_clock || type === ClockType.user_move_clock) {
      ;[tickFunc, destroyFunc] = this.makerUserTimer(type)
    }

    const anim = new Konva.Animation((_frame) => {
      const tr = this.calculateRemainingTime(type)
      if (tr < 0) {
        anim.stop()
        destroyFunc()
        clock.destroy()
        layer.batchDraw()
      } else {
        tickFunc(tr)
        layer.batchDraw()
      }
    })

    clock.setAttr('anim', anim)
    this.runningClocks.push({ type: type, ref: clock, destroy: destroyFunc })
    layer.add(clock)
    layer.batchDraw()
    anim.start()
  }

  private makeWaitingGameClock(clock: Konva.Group, timeRemaining: number, angle?: number, rotation?: number) {
    const layer = this.renderer.getLayer('mp')

    clock.x(layer.getWidth() / 2)
    clock.y(layer.getHeight() / 2)

    let innerRadiusComp = 0
    let outerRadiusComp = 0
    if (layer.getWidth() < 700 || layer.getHeight() < 700) {
      // 54 comes from circle dimension after CSS is applied
      innerRadiusComp = 54 / 2 - 2
      outerRadiusComp = innerRadiusComp + 2
      const h = layer.getHeight()
      if (h < 700) {
        clock.y(clock.y() - h / 10)
      }
    }

    const arc = new Konva.Arc({
      x: 0,
      y: 0,
      innerRadius: 67 - innerRadiusComp,
      outerRadius: 73 - outerRadiusComp,
      fill: '#fffa00',
      strokeWidth: 0,
      angle: angle || 360,
      rotation: rotation || 0,
      id: 'waiting_game_arc',
    })
    /*
    const circle = new Konva.Circle({
      x: 0,
      y: 0,
      radius: 80,
      fill: '#000'
    });
    */
    /*
    const label = new Konva.Text({
      x: -55,
      y: -75,
      width: 110,
      height: 110,
      text: 'Game is starting',
      fill: '#fff',
      align: 'center',
      verticalAlign: 'middle',
      fontSize: 15,
    });
    */
    /*
    const remaining = new Konva.Text({
      x: -55,
      y: -35,
      width: 110,
      height: 110,
      text: Math.round(timeRemaining / 1000).toString(),
      fill: '#fff',
      align: 'center',
      verticalAlign: 'middle',
      fontSize: 30,
    });
    */
    // clock.add(circle);
    clock.add(arc)
    // clock.add(label);
    // clock.add(remaining);

    const tickFunc = (tr: number) => {
      // const newText = Math.round(tr / 1000).toString();
      /*
      if (remaining.text() !== newText) {
        fadeOut(remaining, () => {
          remaining.text(newText);
          fadeIn(remaining);
        });
      }
      */
      arc.angle(((tr / (this.getTableSpeed() / 1000)) * 1000 * 360) / 1000000)
      arc.rotation(arc.rotation() + 1)
      this.lastPos.angle = arc.angle()
      this.lastPos.rotation = arc.rotation()
    }

    const destroyFunc = () => {}
    return [tickFunc, destroyFunc]
  }

  private makerUserTimer(type: ClockType) {
    const layer = this.renderer.getLayer('mp')

    const slotGroup = layer.findOne('#slots') as any
    slotGroup.children.forEach((slot: Konva.Group) => {
      if (!slot.id().startsWith('player_')) {
        return
      }
      const playerIdx = slot.attrs.idx as number
      const uuid = slot.id().replace('player_', '')
      if (this.renderer.table?.players?.find((x) => x.playerUuid === uuid)?.kicked) {
        return
      }
      if (type === ClockType.user_bet_clock) {
        const gameSlot = this.renderer.game?.slots.find((x) => x.userUuid === uuid && x.userIdx === playerIdx)
        if (undefined !== gameSlot && gameSlot.bet > 0) {
          return
        }
      } else if (type === ClockType.user_move_clock) {
        const activeIdx = this.renderer.game?.slots[this.renderer.game.sp].userIdx
        if (playerIdx !== activeIdx) {
          return
        }
      } else {
        return
      }

      const rect: Konva.Rect = slot.findOne('#rect')
      const line = new Konva.Line({
        points: [rect.x(), rect.y(), rect.x() + rect.width(), rect.y()],
        stroke: 'red',
        strokeWidth: 7,
        id: 'user_timer',
      })
      line.attrs.width = rect.x() + rect.width()
      line.attrs.uuid = uuid
      slot.add(line)
    })
    const mobile = Math.min(layer.width(), layer.height()) < 800
    if (mobile) {
      this.renderer.table?.players.forEach((player) => {
        if (player.playerUuid !== this.renderer.myUUID) {
          return
        }

        if (this.renderer.table?.players?.find((x) => x.playerUuid === player.playerUuid)?.kicked) {
          return
        }
        if (type === ClockType.user_bet_clock) {
          const gameSlot = this.renderer.game?.slots.find(
            (x) => x.userUuid === player.playerUuid && x.userIdx === player.slotIdx
          )
          if (undefined !== gameSlot && gameSlot.bet > 0) {
            return
          }
        } else if (type === ClockType.user_move_clock) {
          const activeIdx = this.renderer.game?.slots[this.renderer.game.sp].userIdx
          if (player.slotIdx !== activeIdx) {
            return
          }
        } else {
          return
        }

        const lineWidth = layer.width() / 2
        const line = new Konva.Line({
          x: layer.width() / 2 - lineWidth / 2,
          points: [0, 0, lineWidth, 0],
          stroke: 'red',
          strokeWidth: 7,
          id: 'user_timer',
        })
        line.attrs.width = lineWidth
        line.attrs.uuid = player.playerUuid
        layer.add(line)
      })
    }

    const tickFunc = (tr: number) => {
      const percent = tr / (this.getTableSpeed() * 2)
      const lines = layer.find('#user_timer')
      lines.forEach((line: any) => {
        const currentPoints = line.points()
        currentPoints[2] = line.attrs.width * percent
        line.points(currentPoints)
      })
      layer.batchDraw()
    }

    const destroyFunc = () => {
      const lines = layer.find('#user_timer')
      lines.forEach((x) => x.destroy())
    }

    return [tickFunc, destroyFunc]
  }

  private calculateRemainingTime = (type: ClockType) => {
    if (undefined === this.renderer.table) {
      return -1
    }
    if (type === ClockType.user_bet_clock && this.renderer.table.state !== 'waiting_for_bets') {
      return -1
    }
    if (type === ClockType.user_move_clock && this.renderer.table.state !== 'playing') {
      return -1
    }
    if (type === ClockType.waiting_game_clock && this.renderer.table.state !== 'waiting_for_game') {
      return -1
    }
    const now = new Date().getTime()
    return (this.renderer.table.timer as number) - now - serverTimeDiff
  }

  private getTableSpeed() {
    if (this.renderer.table) {
      if (this.renderer.game && this.renderer.game.state > 40) {
        return 4000
      }
      return this.renderer.table.speed
    }
    return 0
  }
}

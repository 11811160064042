/**
 * @generated SignedSource<<1fe540973dd3666e6dbfde9a2f02b729>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RakebackType = "REGULAR" | "FLASH" | "%future added value";
export type RakeBackStampQuery$variables = {};
export type RakeBackStampQuery$data = {
  readonly listRakebacks: ReadonlyArray<{
    readonly name: string | null;
    readonly type: RakebackType;
    readonly percent: number;
    readonly startTime: number;
    readonly endTime: number;
  }>;
};
export type RakeBackStampQuery = {
  variables: RakeBackStampQuery$variables;
  response: RakeBackStampQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Rakeback",
    "kind": "LinkedField",
    "name": "listRakebacks",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endTime",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RakeBackStampQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RakeBackStampQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ea451980c3e9ddad5c409f602f5a3bf1",
    "id": null,
    "metadata": {},
    "name": "RakeBackStampQuery",
    "operationKind": "query",
    "text": "query RakeBackStampQuery {\n  listRakebacks {\n    name\n    type\n    percent\n    startTime\n    endTime\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa133c78a6b604932bc765f46029255c";

export default node;

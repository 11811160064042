const CrownIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 14" fill="none">
    <path
      d="M12.516 10.4839V11.3871H3.4837V10.4839L1.67725 3.25809L4.83854 7.32261L7.99983 3.25809L11.1611 7.32261L14.3224 3.25809L12.516 10.4839Z"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M1.67742 2.35484C2.05155 2.35484 2.35484 2.05155 2.35484 1.67742C2.35484 1.30329 2.05155 1 1.67742 1C1.30329 1 1 1.30329 1 1.67742C1 2.05155 1.30329 2.35484 1.67742 2.35484Z"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M14.3224 2.35484C14.6966 2.35484 14.9999 2.05155 14.9999 1.67742C14.9999 1.30329 14.6966 1 14.3224 1C13.9483 1 13.645 1.30329 13.645 1.67742C13.645 2.05155 13.9483 2.35484 14.3224 2.35484Z"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M8.00017 2.35484C8.3743 2.35484 8.67759 2.05155 8.67759 1.67742C8.67759 1.30329 8.3743 1 8.00017 1C7.62605 1 7.32275 1.30329 7.32275 1.67742C7.32275 2.05155 7.62605 2.35484 8.00017 2.35484Z"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M8.00035 9.12902L6.64551 7.32257L8.00035 5.51611L9.35519 7.32257L8.00035 9.12902Z"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M3.48389 12.0645V13.1936H12.5162V12.0645" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M4.16113 12.2903H4.61275" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M5.06445 12.2903H5.51607" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M5.96777 12.2903H6.41939" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M6.87109 12.2903H7.32271" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M7.77441 12.2903H8.22603" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M8.67725 12.2903H9.12886" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M9.58057 12.2903H10.0322" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M10.4839 12.2903H10.9355" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M11.3872 12.2903H11.8388" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M1.67725 3.25809L5.29015 5.29035" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M14.3229 3.25809L10.71 5.29035" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M3.48389 10.4839H12.5162" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M7.77441 7.3226H8.22603" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
  </svg>
)

export default CrownIcon

/**
 * @generated SignedSource<<ea7649eb6a602ad4fc6b95d06e30f4a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GameTableType = "REGULAR" | "BONUS_TABLE" | "TOURNAMENT_TABLE" | "SIT_AND_GO" | "FREEROLL" | "TOURNAMENT" | "%future added value";
export type GameMoveInput = {
  move: string;
  tableUuid?: string | null;
  gameId?: string | null;
  insure?: boolean | null;
};
export type InHouseGameGameMoveMutation$variables = {
  input: GameMoveInput;
};
export type InHouseGameGameMoveMutation$data = {
  readonly gameMove: {
    readonly table: {
      readonly uuid: string;
      readonly players: ReadonlyArray<{
        readonly slotIdx: number;
        readonly playerUuid: string;
        readonly playerName: string | null;
        readonly playerBalance: number | null;
        readonly kicked: boolean | null;
      }>;
      readonly maxPlayers: number;
      readonly currency: string;
      readonly type: GameTableType;
      readonly virtualId: number;
      readonly state: string;
      readonly speed: number;
      readonly timer: number | null;
      readonly name: string;
      readonly meta: any;
      readonly gameCount: number;
      readonly game: {
        readonly state: string | null;
        readonly gameId: string | null;
        readonly sp: number | null;
        readonly mp: boolean | null;
        readonly splitCount: number | null;
        readonly insured: number | null;
        readonly serverSeedHash: string | null;
        readonly currency: string | null;
        readonly virtualId: number | null;
        readonly validMoves: {
          readonly canSplit: boolean | null;
          readonly canDoubleDown: boolean | null;
          readonly canInsurance: boolean | null;
          readonly canHit: boolean | null;
        } | null;
        readonly summary: {
          readonly serverSeed: string | null;
          readonly serverSeedHash: string | null;
          readonly clientSeed: string | null;
          readonly finalShuffle: string | null;
          readonly finalShuffleHash: string | null;
          readonly winnings: number | null;
          readonly details: ReadonlyArray<{
            readonly userIdx: number | null;
            readonly slotId: number;
            readonly winning: number;
          } | null> | null;
        } | null;
        readonly slots: ReadonlyArray<{
          readonly slotId: number;
          readonly parentId: number | null;
          readonly bet: number | null;
          readonly result: string | null;
          readonly userUuid: string | null;
          readonly userName: string | null;
          readonly userIdx: number | null;
          readonly cards: ReadonlyArray<{
            readonly rank: string | null;
            readonly suit: string | null;
            readonly flipped: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
    readonly game: {
      readonly state: string | null;
      readonly gameId: string | null;
      readonly sp: number | null;
      readonly mp: boolean | null;
      readonly splitCount: number | null;
      readonly insured: number | null;
      readonly serverSeedHash: string | null;
      readonly currency: string | null;
      readonly virtualId: number | null;
      readonly validMoves: {
        readonly canSplit: boolean | null;
        readonly canDoubleDown: boolean | null;
        readonly canInsurance: boolean | null;
        readonly canHit: boolean | null;
      } | null;
      readonly summary: {
        readonly serverSeed: string | null;
        readonly serverSeedHash: string | null;
        readonly clientSeed: string | null;
        readonly finalShuffle: string | null;
        readonly finalShuffleHash: string | null;
        readonly winnings: number | null;
        readonly details: ReadonlyArray<{
          readonly userIdx: number | null;
          readonly slotId: number;
          readonly winning: number;
        } | null> | null;
      } | null;
      readonly slots: ReadonlyArray<{
        readonly slotId: number;
        readonly parentId: number | null;
        readonly bet: number | null;
        readonly result: string | null;
        readonly userUuid: string | null;
        readonly userName: string | null;
        readonly userIdx: number | null;
        readonly cards: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};
export type InHouseGameGameMoveMutation = {
  variables: InHouseGameGameMoveMutation$variables;
  response: InHouseGameGameMoveMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "virtualId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serverSeedHash",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userIdx",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Game",
  "kind": "LinkedField",
  "name": "game",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gameId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "splitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insured",
      "storageKey": null
    },
    (v4/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidMoves",
      "kind": "LinkedField",
      "name": "validMoves",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canSplit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canDoubleDown",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canInsurance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canHit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameSummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serverSeed",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientSeed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalShuffle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalShuffleHash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "winnings",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WinningDetail",
          "kind": "LinkedField",
          "name": "details",
          "plural": true,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "winning",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameSlot",
      "kind": "LinkedField",
      "name": "slots",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "result",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userUuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userName",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GameCard",
          "kind": "LinkedField",
          "name": "cards",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rank",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flipped",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GameMovePayload",
    "kind": "LinkedField",
    "name": "gameMove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GameTable",
        "kind": "LinkedField",
        "name": "table",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slotIdx",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playerUuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playerBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kicked",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxPlayers",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "speed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameCount",
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InHouseGameGameMoveMutation",
    "selections": (v8/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InHouseGameGameMoveMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "62b16c1e909acd9af880f4f73163001f",
    "id": null,
    "metadata": {},
    "name": "InHouseGameGameMoveMutation",
    "operationKind": "mutation",
    "text": "mutation InHouseGameGameMoveMutation(\n  $input: GameMoveInput!\n) {\n  gameMove(input: $input) {\n    table {\n      uuid\n      players {\n        slotIdx\n        playerUuid\n        playerName\n        playerBalance\n        kicked\n      }\n      maxPlayers\n      currency\n      type\n      virtualId\n      state\n      speed\n      timer\n      name\n      meta\n      gameCount\n      game {\n        state\n        gameId\n        sp\n        mp\n        splitCount\n        insured\n        serverSeedHash\n        currency\n        virtualId\n        validMoves {\n          canSplit\n          canDoubleDown\n          canInsurance\n          canHit\n        }\n        summary {\n          serverSeed\n          serverSeedHash\n          clientSeed\n          finalShuffle\n          finalShuffleHash\n          winnings\n          details {\n            userIdx\n            slotId\n            winning\n          }\n        }\n        slots {\n          slotId\n          parentId\n          bet\n          result\n          userUuid\n          userName\n          userIdx\n          cards {\n            rank\n            suit\n            flipped\n          }\n        }\n      }\n    }\n    game {\n      state\n      gameId\n      sp\n      mp\n      splitCount\n      insured\n      serverSeedHash\n      currency\n      virtualId\n      validMoves {\n        canSplit\n        canDoubleDown\n        canInsurance\n        canHit\n      }\n      summary {\n        serverSeed\n        serverSeedHash\n        clientSeed\n        finalShuffle\n        finalShuffleHash\n        winnings\n        details {\n          userIdx\n          slotId\n          winning\n        }\n      }\n      slots {\n        slotId\n        parentId\n        bet\n        result\n        userUuid\n        userName\n        userIdx\n        cards {\n          rank\n          suit\n          flipped\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29dcab6caa228ab02f87945b62ceda7e";

export default node;

import React from 'react'

import classes from './Chip.module.scss'

export function Chip({ heading, percentage }: { heading: string; percentage: number }) {
  return (
    <div className={classes.chip}>
      <h3 className="font-bold font-inter text-sm capitalize">{heading}</h3>
      <span className="text-eucalyptus font-bold text-2xl font-inter">{percentage}%</span>
    </div>
  )
}

import React from 'react'

import { Chip } from './Chip'

import classes from './LoyaltyReward.module.scss'

export function Rakeback() {
  return (
    <>
      <Chip heading="current rakeback amount" percentage={5} />
      <p className={classes.noEntriesFoundText}>No rakeback available</p>
    </>
  )
}

import React, { FormEvent, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay'
import { store } from '../../../store'
import { getMessageContainer, LoginHeader } from '../Login/Login'
import formatError from '../../errorFormatter'
import { GraphQLError } from '../../../RelayEnvironment'
import { SignUpRegisterMutation } from './__generated__/SignUpRegisterMutation.graphql'
import { AuthAction, UserConstant, User } from '../../../reducers/authentication.reducer'
import { Link, useHistory } from 'react-router-dom'
import ISignUp from './SignUp.interface'
import styles from '../Login/Login.module.scss'

const SignUp: React.FC<ISignUp> = () => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [promo, setPromo] = useState('')
  const [tos, setTos] = useState(false)
  const [_2fa, set_2fa] = useState(false)

  const history = useHistory()
  const [commit] = useMutation<SignUpRegisterMutation>(registerMutation)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!tos) {
      setMessage(t('authentication.signup.agreeToTOS', 'You must agree to the TOS'))
      return
    }

    const hash = localStorage.getItem('anonymous_hash')
    let params: Array<{ key: string; value: string }> = []
    const savedProps = localStorage.getItem('ref_props')
    if (savedProps) {
      params = JSON.parse(localStorage.getItem('ref_props') as any)
    }

    if (promo.length > 0) {
      params.push({ key: 'bonus_code', value: promo })
    }

    commit({
      variables: {
        input: {
          name: username,
          username: email,
          password: password,
          anonymousHash: hash,
          refProps: params,
        },
      },
      onCompleted: (e) => {
        if (e.register) {
          const token = e.register.token
          const user = e.register.user
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.removeItem('ref_props')
          if (_2fa) localStorage.setItem('show_2fa_popup', '1')
          localStorage.setItem('show_email_popup', '1')
          store.dispatch<AuthAction>({
            type: UserConstant.LOGIN_SUCCESS,
            user: user as unknown as User,
            token,
          })
          history.push('/')
          window.location.reload()
        } else {
          setMessage(t('toast.unknownError', 'Unknown error, please try again.'))
        }
      },
      onError: (e: GraphQLError) => {
        setMessage(formatError(e)[0])
      },
    })
  }

  return (
    <div className={styles.root}>
      <LoginHeader activeTab={t('authentication.joinUs')} />
      <div className={styles.body}>
        <form className="" onSubmit={handleSubmit}>
          <div className="">
            <label htmlFor="email-form-control">{t('authentication.email', 'Email')}</label>
            <input
              id="email-form-control"
              name="email"
              required={true}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="">
            <label htmlFor="name-form-control">{t('authentication.username', 'Username')}</label>
            <input
              id="name-form-control"
              name="name"
              required={true}
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="">
            <label htmlFor="password-form-control">{t('authentication.password', 'Password')}</label>
            <input
              id="password-form-control"
              name="password"
              required={true}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="">
            <label htmlFor="promo-form-control">{t('authentication.signup.promoCode', 'Promo code')}</label>
            <div className={styles.info}>
              {t('authentication.signup.leaveEmpty', 'Leave empty if you don’t have a promo code')}
            </div>
            <input
              id="promo-form-control"
              name="promo"
              required={false}
              type="text"
              value={promo}
              onChange={(e) => setPromo(e.target.value)}
            />
          </div>
          <div className={styles.checkboxes}>
            <div className={styles.option}>
              <input
                id="tos-form-control"
                name="tos"
                required={true}
                type="checkbox"
                value={tos ? 1 : 0}
                onChange={() => setTos(!tos)}
              />
              <label htmlFor="tos-form-control">
                <Trans
                  i18nKey="authentication.signup.agreeToTOC"
                  components={[<Link to="/provably-fair/toc" target="_blank" className={styles.green} />]}
                />
              </label>
            </div>
            <div className={styles.option}>
              <input
                id="2fa-form-control"
                name="_2fa"
                type="checkbox"
                value={_2fa ? 1 : 0}
                onChange={() => set_2fa(!_2fa)}
              />
              <label htmlFor="2fa-form-control" className={styles.green}>
                {t('authentication.toggle2FA.enable', 'Enable 2FA')}
              </label>
            </div>
          </div>
          {getMessageContainer(message)}
          <button className="" type="submit">
            {t('authentication.signup.joinCommunity', 'Join the blackjack.fun community')}
          </button>
        </form>
      </div>
    </div>
  )
}

export default SignUp

export const registerMutation = graphql`
  mutation SignUpRegisterMutation($input: RegisterInput!) {
    register(input: $input) {
      token
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

import React from 'react'

export function AppContent({ children }: { children?: React.ReactNode | React.ReactNodeArray }) {
  return (
    <div
      className="app-content"
      style={{
        paddingTop: `var(--header-h, 0)`,
      }}
    >
      {children}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Notifications from '../../icons/notifications'
import styles from '../ChatControl/ChatControl.module.scss'
import { graphql } from 'babel-plugin-relay/macro'
import { IStore, store } from '@src/store'
import { AppTypes } from '@src/reducers/app.reducer'
import Env from '@src/RelayEnvironment'
import { fetchQuery } from 'relay-runtime'
import { NotificationsListNotificationsQuery } from '@src/components/Notifications/__generated__/NotificationsListNotificationsQuery.graphql'
import { listNotificationsQuery } from '@src/components/Notifications/Notifications'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-relay'
import { NotificationsControlMarkNotificationsAsReadMutation } from './__generated__/NotificationsControlMarkNotificationsAsReadMutation.graphql'
import BellIcon from '@src/components/icons/bell'
import { useTranslation } from 'react-i18next'

interface Props {
  mobileVersion?: boolean
}

const NotificationsControl = ({ mobileVersion = false }: Props) => {
  const isOpen = useSelector((state: IStore) => state.app.isNotificationsOpen)
  const { t } = useTranslation()
  const [unread, setUnread] = useState(0)

  const [markAsRead] = useMutation<NotificationsControlMarkNotificationsAsReadMutation>(markNotificationsAsRead)

  useEffect(() => {
    fetchQuery<NotificationsListNotificationsQuery>(
      Env,
      listNotificationsQuery,
      {
        page: 1,
        pageSize: 999,
        filter: {
          type: 'unread',
        },
      },
      { fetchPolicy: 'store-or-network' }
    ).subscribe({
      next: ({ listNotifications }) => {
        setUnread(listNotifications.entries.filter((n) => n.type !== 'USER_LOGIN').length)
      },
    })
  }, [isOpen])

  const read = () => {
    markAsRead({
      variables: {
        input: {
          ids: [],
        },
      },
      onCompleted: (response, _error) => {
        setUnread(0)
      },
    })
  }

  const notificationClickHandler = () => {
    store.dispatch({ type: AppTypes.CLOSE_CHAT })
    store.dispatch({ type: AppTypes.TOGGLE_NOTIFICATIONS })
    read()
  }

  return mobileVersion ? (
    <button
      onClick={notificationClickHandler}
      className={`inline-flex flex-col items-center justify-center px-5 gap-7 text-whiteSmoke relative`}
    >
      <div className="relative">
        <BellIcon className={'w-24 h-24'} />
        {unread > 0 && (
          <span className="absolute top-0 right-0 block h-9 w-9 bg-eucalyptus rounded-full transform translate-x-12 -translate-y-2"></span>
        )}
      </div>
      <span className="text-xs text-whiteSmoke">{t('menu.news', 'News')}</span>
    </button>
  ) : (
    <div className={styles.root} onClick={notificationClickHandler}>
      <Notifications className="w-18 h-18 md:w-20 md:h-20 hover:text-eucalyptus" />
      {unread > 0 && <div className={styles.number}>{unread}</div>}
    </div>
  )
}

export default NotificationsControl

const markNotificationsAsRead = graphql`
  mutation NotificationsControlMarkNotificationsAsReadMutation($input: MarkNotificationsAsReadInput!) {
    markNotificationsAsRead(input: $input) {
      count
    }
  }
`

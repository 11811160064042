import { useSelector } from 'react-redux'
import { IStore } from '@src/store'

import { ProfileQueryResponse } from './gql/__generated__/ProfileQuery.graphql'

// import { ProfileBadgeCollection } from './ProfileBadgeCollection'
import { ProfileLevel } from './ProfileLevel'
import { ProfileStatistics } from './ProfileStatistics'
import { Profile2FA } from './Settings/Profile2FA'

import classes from './ProfileContent.module.scss'
import { ProfileKYC } from './Settings/ProfileKYC'
import { ProfileAffiliate, useBonusCode } from './ProfileAffiliate'
import { useMutation } from 'react-relay'
import { ProfileAffiliateUseBonusCodeMutation } from './__generated__/ProfileAffiliateUseBonusCodeMutation.graphql'
import { toast } from 'react-toastify'

export function ProfileContent({ uuid, profile }: { uuid: string; profile: ProfileQueryResponse['profile'] }) {
  const { tab, subTab } = useSelector((s: IStore) => s.profile)

  switch (tab) {
    case 'account':
      return subTab === 'Bonus Code' ? null : ( // <BonusCodeComponent />
        <>
          <ProfileLevel uuid={uuid} profile={profile} />
          <ProfileStatistics profile={profile} />
          {/* <ProfileBadgeCollection /> */}
        </>
      )

    case 'affiliate':
      return <ProfileAffiliate />

    case 'settings':
      return (
        <div className={classes.settingsWrapper}>
          {subTab === 'KYC' && <ProfileKYC />}
          {subTab === 'Security' && <Profile2FA />}
        </div>
      )
    default:
      return null
  }
}

const BonusCodeComponent = () => {
  const [submitBonusCode] = useMutation<ProfileAffiliateUseBonusCodeMutation>(useBonusCode)

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const input = (e.target as HTMLFormElement).querySelector('input')
    const code = input?.value

    const clearInput = () => {
      if (!input) return
      input.value = ''
    }

    submitBonusCode({
      variables: {
        input: {
          bonusCode: code,
        },
      },
      onError: (err) => {
        toast.error(JSON.stringify(err))
      },
      onCompleted: (response, errors) => {
        if (errors) {
          errors.forEach((err) => toast.error(err.message))
          return
        }

        if (response.useBonusCode?.result?.toLowerCase() === 'kyc') {
          toast.success('Bonus claimed successfully, but you need to complete KYC to use it')
        }

        if (response.useBonusCode?.result?.toLowerCase() === 'success') {
          toast.success('Bonus claimed successfully')
        }

        clearInput()
      },
    })
  }

  return (
    <div className={classes.bonusCodeRoot}>
      <h3 className={classes.claimBonusText}>Claim a bonus by inserting your bonus here</h3>
      <form className={classes.inputWrapper} onSubmit={handleFormSubmit}>
        <input type="text" placeholder="Insert code here" />
        <button type="submit">Claim your bonus</button>
      </form>
    </div>
  )
}

import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './LandingNavigation.module.scss'
import ChipIcon from '@src/components/icons/chip'
import CherryIcon from '@src/components/icons/cherry'
import DealerIcon from '@src/components/icons/dealer'
import SportsIcon from '@src/components/icons/sports'
import Heart21Icon from '@src/components/icons/heart21'

const LandingNavigation = () => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  const buttons = [
    {
      name: t('menu.lobby', 'Lobby'),
      path: '/',
      icon: <ChipIcon className={'w-20 h-20'} />,
    },
    {
      name: t('menu.sports', 'Sports'),
      path: '/game/BetsyGames:btsg_sportbetting',
      icon: <SportsIcon className={'w-20 h-20'} />,
    },
    {
      name: t('menu.slots', 'Slots'),
      path: '/slots',
      icon: <CherryIcon className={'w-20 h-20'} />,
    },
    {
      name: t('menu.liveGames', 'Live Games'),
      path: '/live-games',
      icon: <DealerIcon className={'w-20 h-20'} />,
    },
    {
      name: t('menu.blackjack', 'Blackjack'),
      path: '/game/bitcoin-blackjack',
      icon: <Heart21Icon className={'w-20 h-20'} />,
    },
  ]

  return (
    <div className={`bg-blackPearl rounded-4 my-20 py-5 px-7 overflow-x-auto ${styles.noScrollbar}`}>
      <div className="flex space-x-7 whitespace-nowrap">
        {buttons.map((button) => {
          const isActive = location.pathname === button.path
          return (
            <button
              key={button.name}
              onClick={() => history.push(button.path)}
              className={`flex items-center text-white font-medium py-10 px-15 border rounded-4 ${
                isActive ? 'border-gold' : 'border-blackPearl hover:border-gold'
              }`}
            >
              <span className="mr-10">{button.icon}</span>
              <span>{button.name}</span>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default LandingNavigation

import React, { useMemo } from 'react'
import type { ProfileQueryResponse } from './gql/__generated__/ProfileQuery.graphql'
import classes from './ProfileStatistics.module.scss'
import { formatFiatRaw } from '@src/components/rightSide/walletComponent/WalletComponent'
import { IStore } from '@src/store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

type StatElementProps = {
  value: string
  title: string
  color?: string
  hideProgress?: boolean
}

const StatElement = ({ title, value, color = '#6C5DD3', hideProgress = false }: StatElementProps) => (
  <div className={classes.statElement}>
    <div className="flex items-center gap-x-4 md:gap-x-8">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="6" fill="#6C5DD3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.48329 5.62668H9.50663C10.7366 5.62668 11.3366 6.28335 11.3333 7.63002V9.25335C11.3333 10.54 10.54 11.3333 9.24996 11.3333H6.74663C5.46329 11.3333 4.66663 10.54 4.66663 9.25002V6.74668C4.66663 5.36668 5.27996 4.66668 6.48996 4.66668H7.01663C7.32696 4.66335 7.61663 4.80668 7.80663 5.05002L8.09996 5.44002C8.19329 5.55668 8.33329 5.62668 8.48329 5.62668ZM6.45663 9.09657H9.54329C9.67996 9.09657 9.78996 8.98324 9.78996 8.84657C9.78996 8.70657 9.67996 8.59657 9.54329 8.59657H6.45663C6.31663 8.59657 6.20663 8.70657 6.20663 8.84657C6.20663 8.98324 6.31663 9.09657 6.45663 9.09657Z"
          fill="white"
        />
      </svg>
      <h4 className="whitespace-nowrap overflow-hidden overflow-ellipsis">{title}</h4>
    </div>
    <div>
      <h5>{value}</h5>
    </div>
    {!hideProgress && <StatProgressBar progress={20} color={color} />}
  </div>
)

export function ProfileStatistics({ profile }: { profile: ProfileQueryResponse['profile'] }) {
  const { t } = useTranslation()
  const rates = useSelector((state: IStore) => state.wallet.rates)

  const insertedAt = new Date(profile.insertedAt)
  const elapsedDays = Math.floor((new Date().getTime() - insertedAt.getTime()) / (1000 * 3600 * 24))

  const wager = useMemo(() => {
    const value = profile.userStats.find((stat) => stat.name === 'wager')?.value ?? 0

    return `${formatFiatRaw(rates, { balance: value, currency: 'BTC' }) ?? 0}$`
  }, [profile, rates])

  const votes = profile.votes ?? 0

  const stats: StatElementProps[] = [
    { title: t('profile.statistics.votes', 'Votes'), value: votes.toString(), color: '#6C5DD3' },
    { title: t('profile.statistics.playTime', 'Play time (days)'), value: elapsedDays.toString(), color: '#FFA2C0' },
    {
      title: t('profile.statistics.followers', 'Followers'),
      value: profile.followers.toLocaleString('en-US'),
      color: '#7FBA7A',
    },
    { title: t('profile.statistics.wager', 'Wager'), value: wager, color: '#FF9A7B' },
  ]

  return (
    <div className={classes.root}>
      <h3>{t('profile.statistics.title', 'Statistics')}</h3>
      <div className={classes.statWrapper}>
        {stats.map((stat, i) => (
          <React.Fragment key={i}>
            <StatElement {...stat} />
            {i !== stats.length - 1 && <Separator />}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const Separator = () => <div className={classes.separator} />

const StatProgressBar = ({ progress, color }: { progress: number; color: string }) => (
  <div className={classes.progressBar}>
    <div className={classes.progress} style={{ width: `${progress}%`, backgroundColor: color }} />
  </div>
)

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Cancel = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none" {...props}>
    <ellipse
      cx="14.9966"
      cy="4.15014"
      rx="1.44165"
      ry="1.44165"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1933 3.34009H5.74253C3.48395 3.34009 2.0835 4.93963 2.0835 7.19821V13.26C2.0835 15.5186 3.45649 17.1113 5.74253 17.1113H12.1956C14.4542 17.1113 15.8547 15.5186 15.8547 13.26V7.98082"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.77175 6.72727L8.96493 8.84375H9.02175L10.2291 6.72727H12.4308L10.2575 10.3636L12.5161 14H10.2575L9.02175 11.8409H8.96493L7.72914 14H5.48482L7.71493 10.3636L5.55584 6.72727H7.77175Z"
      fill="currentColor"
    />
  </svg>
)

export default Cancel

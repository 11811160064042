import Env from '@src/RelayEnvironment'
import { fetchQuery } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { useEffect, useState } from 'react'
import {
  RakeBackAnnouncementTempQuery,
  RakeBackAnnouncementTempQuery$data,
} from './__generated__/RakeBackAnnouncementTempQuery.graphql'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'

import classes from './RakeBackAnnouncementTemp.module.scss'

const getRakeListQuery = graphql`
  query RakeBackAnnouncementTempQuery {
    listRakebacks {
      name
      type
      percent
      startTime
      endTime
    }
  }
`

export function RakeBackAnnouncementTemp() {
  const [rakeBacks, setRakeBacks] = useState<RakeBackAnnouncementTempQuery$data['listRakebacks']>([])

  const [isActive, setIsActive] = useState<boolean>(false)
  const [rakeBack, setRakeBack] = useState<typeof rakeBacks[number]>()

  const [formattedTimeLeft, setFormattedTimeLeft] = useState<string>()

  const getCurrentRakeback = (rakebacks: RakeBackAnnouncementTempQuery$data['listRakebacks']) => {
    const now = new Date()

    return rakebacks
      .filter((rakeback) => {
        const startTime = new Date(rakeback.startTime)
        const endTime = new Date(rakeback.endTime)

        return now >= startTime && now <= endTime
      })
      .sort((a, b) => b.percent - a.percent)[0]
  }

  const getNearestRakeback = (rakebacks: RakeBackAnnouncementTempQuery$data['listRakebacks']) => {
    const now = new Date()

    return rakebacks.reduce((prev, current) => {
      const prevStartTime = new Date(prev.startTime)
      const currentStartTime = new Date(current.startTime)

      return now >= prevStartTime && now <= currentStartTime ? prev : current
    }, rakebacks[0])
  }

  useEffect(() => {
    fetchQuery<RakeBackAnnouncementTempQuery>(Env, getRakeListQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
      next: (data) => {
        setRakeBacks(data.listRakebacks)
      },
    })

    const interval = setInterval(() => {
      fetchQuery<RakeBackAnnouncementTempQuery>(Env, getRakeListQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
        next: (data) => {
          setRakeBacks(data.listRakebacks)
        },
      })
    }, 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const current = getCurrentRakeback(rakeBacks)
    const nearest = getNearestRakeback(rakeBacks)

    if (isEqual(current, nearest)) {
      setIsActive(true)
      setRakeBack(current)
    } else {
      setIsActive(false)
      setRakeBack(nearest)
    }
  }, [rakeBacks])

  useEffect(() => {
    if (!rakeBack) return

    const interval = setInterval(() => {
      const timeLeftInSeconds = dayjs(rakeBack.endTime).diff(dayjs(), 'second')
      const hoursLeft = Math.floor(timeLeftInSeconds / 3600)
      const minutesLeft = Math.floor((timeLeftInSeconds % 3600) / 60)
      const secondsLeft = Math.floor((timeLeftInSeconds % 3600) % 60)

      const formattedHoursLeft = hoursLeft.toString().padStart(2, '0')
      const formattedMinutesLeft = minutesLeft.toString().padStart(2, '0')
      const formattedSecondsLeft = secondsLeft.toString().padStart(2, '0')

      setFormattedTimeLeft(`${formattedHoursLeft}:${formattedMinutesLeft}:${formattedSecondsLeft}`)
    }, 200)

    return () => {
      clearInterval(interval)
    }
  }, [rakeBack])

  if (!rakeBack) return null

  return (
    <div className={classes.root}>
      <div className={classes.bg}></div>
      <div className={classes.content}>
        <div>
          <h3 className="pl-20 py-15 md:text-xl leading-7 tracking-tight">
            {isActive ? (
              <>
                NEXT {rakeBack?.percent}% RAKEBACK PAYOUT IN <span className={classes.time}>{formattedTimeLeft}</span>
              </>
            ) : (
              <>
                NEXT RAKEBACK BONUS {rakeBack?.percent}% IN <span className={classes.time}>{formattedTimeLeft}</span>
              </>
            )}
          </h3>
        </div>
      </div>
    </div>
  )
}

import { Route, Redirect, RouteProps } from 'react-router-dom'
import { store } from '../store'

interface PrivateRouteProps extends RouteProps {
  component: any
  componentProps?: any
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, componentProps: ComponentProps, ...rest } = props
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const loggedIn = store.getState().authentication.loggedIn
        return loggedIn ? (
          <Component {...routeProps} {...ComponentProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: routeProps.location },
            }}
          />
        )
      }}
    />
  )
}

import Konva from 'konva'
import { fromEvent } from 'rxjs'
import { UserProp } from '../../../reducers/authentication.reducer'
import { getUserPropOr } from '../../SettingsComponent/SettingsComponent'
import { GameCard } from '../Blackjack'

const getShortCardValue = (value: string) => {
  if (isNaN(+value)) {
    return value.substring(0, 1).toUpperCase()
  }
  if (+value === 10) {
    return 'r' + value
  }
  return 'r0' + value
}

export const getUrlForCard = (card: GameCard): string => {
  const theme = getUserPropOr(UserProp.CARD_THEME, 'modern')
  if (theme === 'modern') {
    return '/assets/theme_modern/' + card.suit + '-' + getShortCardValue(card.rank) + '.png'
  } else if (theme === 'classical') {
    return '/assets/theme_classical/' + card.suit + '-' + getShortCardValue(card.rank) + '.svg'
  }
  return ''
}

export const getCardBack = () => {
  const bacngroundIdx = getUserPropOr(UserProp.CARD_BACK, 9)
  return '/assets/b' + bacngroundIdx + '.png'
}

const findLastCardIdxInStack = (stack: Konva.Group): number => {
  if (!stack || !stack.children) {
    return 0
  }
  for (let i = 0; i < stack.children.length; i++) {
    const el = stack.children[i]
    if (el.attrs.id !== 'canvas_card') {
      return i - 1
    }
  }
  return 0
}

let scaleFactor: number | undefined = undefined
fromEvent(window, 'resize').subscribe(() => {
  scaleFactor = undefined
})

const getCardScaleFactor = () => {
  if (undefined !== scaleFactor) {
    return scaleFactor
  }
  // TODO Seems to be slow, maybe cache these values and update on resize
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  const landScape = width > height

  if (height < 1000) {
    const cardHeightWanted = height * 0.25
    scaleFactor = cardHeightWanted / 320
  } else if (landScape && height < 1100) {
    scaleFactor = (70 * (Math.max(width - 320), 1)) / 100
  } else if (width >= 1200) {
    scaleFactor = 1
  } else if (width >= 1024) {
    scaleFactor = (70 * (Math.max(width - 320), 1)) / 100
  } else {
    scaleFactor = (50 * (Math.max(width - 320), 1)) / 100
  }
  return scaleFactor
}

export const scaleUp = (card: Konva.Group) => {
  if (card.getAttr('lock') === true) {
    return
  }
  card.setAttr('scale_x', card.scaleX())
  card.setAttr('scale_y', card.scaleY())
  card.setAttr('scale_o', card.opacity())
  card.scale({ x: card.scaleX() + 0.1, y: card.scaleY() + 0.1 })
  card.opacity(1)
  card.zIndex(findLastCardIdxInStack(card.parent as any))
}

export const scaleDown = (card: Konva.Group) => {
  const lock = card.getAttr('lock')
  if (lock) {
    return
  }
  const scaleX = card.getAttr('scale_x')
  const scaleY = card.getAttr('scale_y')
  const opacity = card.getAttr('scale_o')
  card.scale({ x: scaleX, y: scaleY })
  card.opacity(opacity)
  card.zIndex(card.attrs.cardIdx)
}

export const addScaleAnimation = (card: Konva.Group, layer: Konva.Layer) => {
  /*card.on('mouseover', () => {
    scaleUp(card);
    layer.batchDraw();
  });
  card.on('mouseout', () => {
    scaleDown(card);
    layer.batchDraw();
  });*/
}

export const getCardHeight = () => {
  const slim = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 800
  const cardHeight = getUserPropOr(UserProp.CARD_THEME, 'modern') === 'modern' ? 228 : 208
  return cardHeight * getCardScaleFactor() * (slim ? 0.7 : 1)
}

export const getCardWidth = () => {
  const slim = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 800
  return 149 * getCardScaleFactor() * (slim ? 0.7 : 1)
}

export const getCard = (config: GameCard, slot: number, cardIdx: number): Konva.Group => {
  if (undefined === config) {
    return undefined as any
  }
  const card = new Konva.Group({
    width: getCardWidth(),
    height: getCardHeight(),
    id: 'canvas_card',
  })
  card.attrs.value = config.rank
  card.attrs.slot = slot
  card.attrs.cardIdx = cardIdx
  card.attrs.flipped = config.flipped

  const rect = new Konva.Rect({
    x: 0,
    y: 0,
    offsetX: card.width() / 2,
    offsetY: card.height() / 2,
    width: card.width(),
    height: card.height(),
    cornerRadius: 5,
    fill: '#FFF',
    shadowColor: 'black',
    shadowBlur: 5,
    shadowOffset: { x: 0, y: 0 },
    shadowOpacity: 0.5,
  })

  const imageObj = new Image()
  imageObj.onload = function () {
    const img = new Konva.Image({
      x: 0,
      y: 0,
      offsetX: card.width() / 2,
      offsetY: card.height() / 2,
      width: card.width(),
      height: card.height(),
      image: imageObj,
    })
    card.add(img)
    const layer = rect.getLayer()
    if (layer) {
      layer.batchDraw()
    }
  }

  if (config.flipped) {
    imageObj.src = getCardBack()
  } else {
    imageObj.src = getUrlForCard(config) as string
  }

  card.add(rect)
  return card
}

/**
 * @generated SignedSource<<f750d43ccaaf606c4889df6d5d2e868a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type walletDepositChangedSubscription$variables = {};
export type walletDepositChangedSubscription$data = {
  readonly depositChanged: {
    readonly amount: number;
    readonly currency: Currency;
    readonly confirmations: number;
    readonly isConfirmed: boolean;
    readonly confirmationsRequired: number | null;
    readonly insertedAt: string;
    readonly txid: string | null;
  };
};
export type walletDepositChangedSubscription = {
  variables: walletDepositChangedSubscription$variables;
  response: walletDepositChangedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Deposit",
    "kind": "LinkedField",
    "name": "depositChanged",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confirmations",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfirmed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confirmationsRequired",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "txid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "walletDepositChangedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "walletDepositChangedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "965d7e5caea25bb05d839cfd48cc109a",
    "id": null,
    "metadata": {},
    "name": "walletDepositChangedSubscription",
    "operationKind": "subscription",
    "text": "subscription walletDepositChangedSubscription {\n  depositChanged {\n    amount\n    currency\n    confirmations\n    isConfirmed\n    confirmationsRequired\n    insertedAt\n    txid\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cb07e2eca66bd139a7070336d5b6045";

export default node;

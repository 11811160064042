const DealerIcon = ({ color = '#D5DCEB', width = '16', height = '16', ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 14" fill="none">
    <path
      d="M6.58149 8.08597C6.50626 8.08597 6.4286 8.06656 6.36065 8.02773C5.13028 7.32397 3.44854 6.14214 3.05783 3.9896C2.92193 3.23973 3.03356 2.03848 3.80042 1.11873C4.22753 0.606688 5.02108 0 6.38492 0C8.4234 0 9.43294 1.34442 9.69746 2.60148C10.0154 4.12064 9.35043 6.0936 7.45755 6.92355C7.23186 7.02305 6.96735 6.92112 6.86785 6.69301C6.76835 6.46732 6.87028 6.2028 7.09839 6.1033C8.55445 5.46264 9.0665 3.94834 8.82139 2.78592C8.57386 1.60166 7.6614 0.895477 6.38735 0.895477C5.56953 0.895477 4.93129 1.16484 4.48962 1.69388C3.95816 2.33212 3.82954 3.22516 3.93874 3.82943C4.18627 5.18599 5.09631 6.27319 6.80475 7.24874C7.02073 7.37251 7.09354 7.6443 6.9722 7.86028C6.88969 8.00589 6.73923 8.08597 6.58392 8.08597H6.58149Z"
      fill="currentColor"
    />
    <path
      d="M12.2649 14H0.448951C0.201421 14 0 13.7986 0 13.551C0 12.0926 0.0412556 10.0419 0.410124 9.08821C0.956146 7.66613 3.30525 6.76338 3.5722 6.66631C3.79303 6.58622 4.03571 6.68815 4.13521 6.89928C4.57931 7.86756 5.44809 8.46939 6.4018 8.46696C7.31912 8.46696 8.10782 7.89425 8.56648 6.89928C8.66355 6.68573 8.90865 6.58622 9.12949 6.66631C9.39643 6.76338 11.748 7.66613 12.2916 9.08821C12.6337 9.97884 12.6167 10.9204 12.6022 11.7528C12.5998 11.9081 12.5973 12.061 12.5973 12.2066C12.5973 12.4541 12.3959 12.6556 12.1484 12.6556C11.9009 12.6556 11.6994 12.4541 11.6994 12.2066C11.6994 12.0537 11.7019 11.8984 11.7043 11.7358C11.7164 10.986 11.7334 10.1342 11.4519 9.40855C11.1655 8.6611 9.93275 7.98404 9.18045 7.654C8.54949 8.73149 7.5351 9.36244 6.39938 9.36244C5.24667 9.36244 4.15462 8.70236 3.51881 7.65157C2.76651 7.98161 1.52886 8.65867 1.2425 9.40612C1.02652 9.9667 0.910037 11.2092 0.895477 13.1021H12.26C12.5075 13.1021 12.709 13.3035 12.709 13.551C12.709 13.7986 12.5075 14 12.26 14H12.2649Z"
      fill="currentColor"
    />
    <path
      d="M6.35816 11.1073C6.69322 11.1073 6.96485 10.8357 6.96485 10.5006C6.96485 10.1655 6.69322 9.89391 6.35816 9.89391C6.02309 9.89391 5.75146 10.1655 5.75146 10.5006C5.75146 10.8357 6.02309 11.1073 6.35816 11.1073Z"
      fill="currentColor"
    />
    <path
      d="M8.21467 11.4762C8.12245 11.4762 8.03023 11.447 7.95015 11.3888L7.23425 10.8622C7.1202 10.7772 7.05225 10.6438 7.05225 10.5006C7.05225 10.3574 7.1202 10.224 7.23425 10.139L7.95015 9.61241C8.14914 9.46681 8.43065 9.50806 8.57626 9.70706C8.72186 9.90605 8.68061 10.1875 8.48161 10.3332L8.25592 10.5006L8.48161 10.6656C8.68061 10.8112 8.72429 11.0927 8.57868 11.2917C8.49132 11.4106 8.35542 11.4737 8.21709 11.4737L8.21467 11.4762Z"
      fill="currentColor"
    />
    <path
      d="M4.49911 11.4762C4.36079 11.4762 4.22489 11.4131 4.13752 11.2942C3.99192 11.0952 4.03317 10.8137 4.23459 10.668L4.46028 10.503L4.23459 10.3356C4.0356 10.19 3.99192 9.90846 4.13995 9.70947C4.28556 9.51047 4.56706 9.4668 4.76606 9.61484L5.48195 10.1414C5.59601 10.2264 5.66396 10.3598 5.66396 10.503C5.66396 10.6462 5.59601 10.7797 5.48195 10.8646L4.76606 11.3912C4.68597 11.4495 4.59376 11.4786 4.50154 11.4786L4.49911 11.4762Z"
      fill="currentColor"
    />
  </svg>
)

export default DealerIcon

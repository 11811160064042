import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export const authTabs = ['Join Us', 'Login', 'Verify', 'Verify Success'] as const
type AuthTab = typeof authTabs[number]

export type AuthModalState = {
  tab: AuthTab | null
  isOpen: boolean
}

const initialState: AuthModalState = {
  tab: null,
  isOpen: false,
}

const authModalSlice = createSlice({
  name: 'authModal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ tab?: AuthTab | null }>) => {
      state.isOpen = true
      if (action.payload.tab) {
        state.tab = action.payload.tab
      }
    },
    close: (state) => {
      state.isOpen = false
      state.tab = null
    },
    changeTab: (state, action: PayloadAction<{ tab: AuthTab | null }>) => {
      state.tab = action.payload.tab
    },
  },
})

export const { open, close, changeTab } = authModalSlice.actions
export default authModalSlice.reducer

/**
 * @generated SignedSource<<8e3a284416b77e47606d4d7306d285d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ModeratorDeleteMessageInput = {
  hash: string;
};
export type AdminActionsDeleteMutation$variables = {
  input: ModeratorDeleteMessageInput;
};
export type AdminActionsDeleteMutation$data = {
  readonly moderatorDeleteMessage: {
    readonly ok: boolean | null;
  } | null;
};
export type AdminActionsDeleteMutation = {
  variables: AdminActionsDeleteMutation$variables;
  response: AdminActionsDeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ModeratorDeleteMessagePayload",
    "kind": "LinkedField",
    "name": "moderatorDeleteMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminActionsDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminActionsDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4d909930baa3d6b9319073f21f31abe6",
    "id": null,
    "metadata": {},
    "name": "AdminActionsDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation AdminActionsDeleteMutation(\n  $input: ModeratorDeleteMessageInput!\n) {\n  moderatorDeleteMessage(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "0adb5f6d5ba2a99853f0d11c9b1157e0";

export default node;

/**
 * @generated SignedSource<<10d36befee689127852827d0db5a18c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type NotificationType = "FREESPIN_ADDED" | "NEW_BONUS_ADDED" | "USER_LOGIN" | "MANUAL_CREDIT" | "MANUAL_CASHBACK" | "DIRECT_RAIN" | "BANKROLL_PAYOUT" | "RAKEBACK" | "DIRECT_COMPETITION_CREDIT" | "DEPOSIT_BONUS" | "COMPETITION_VOTING_PAYOUT" | "AFFILIATE_PAYOUT" | "WAGER_LEVEL_REACHED_BONUS" | "CHAT_GIVEAWAY" | "%future added value";
export type NotificationsOldNotificationAddedSubscription$variables = {};
export type NotificationsOldNotificationAddedSubscription$data = {
  readonly notificationAdded: {
    readonly notificationId: number;
    readonly type: NotificationType;
    readonly readTime: string | null;
    readonly meta: any | null;
    readonly insertedAt: string;
  };
};
export type NotificationsOldNotificationAddedSubscription = {
  variables: NotificationsOldNotificationAddedSubscription$variables;
  response: NotificationsOldNotificationAddedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "notificationAdded",
    "plural": false,
    "selections": [
      {
        "alias": "notificationId",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "readTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsOldNotificationAddedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsOldNotificationAddedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "47fda3dc11e3d3145dfb559748a201d7",
    "id": null,
    "metadata": {},
    "name": "NotificationsOldNotificationAddedSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationsOldNotificationAddedSubscription {\n  notificationAdded {\n    notificationId: id\n    type\n    readTime\n    meta\n    insertedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "9db933472fe0464d493e4c1de382335f";

export default node;

import instance, { Sound } from '../../../SoundManager'
import { startSimAnimation } from './wheelSim'

/* eslint-disable */
let constantDegrees = 0
let speed = 0
let acceleration = 0
let freeRestriction = 0.03
let tickerRestriction = 0.04
let extraRestriction = 0.01
let finalRestriction = 0.008
let finalPosition = 0
let animationRunning = false
let animationWon: '0' | '1' = '0'
let slowDown = false
let animationStoppedFunction: () => void
let currentTickRotation = -55
let sector1Value = 0
let sector2Value = sector1Value
let sector3Value = sector2Value

let tickerMoveStart = 0.97
let tickerMoveEnd = 0.15
let tickerStartCoefficient = 5.5
let tickerEndCoefficient = (tickerMoveEnd + 1 - tickerMoveStart) / tickerMoveEnd
let win: string
let knob: HTMLDivElement
let wheel: HTMLDivElement

const playTick = () => {
  instance.playSound(Sound.TICK)
}

// let magicNumber = 17.1428571428571;
// let magicNumber = 0;
const sectorValue = (deg: number): number => {
  const r = (((deg - 15) % 360) + 180) / 30
  return r - Math.floor(r)
}

const sectorWinValue = (deg: number): string => {
  // -21 is magic nuber, wont work without it
  const r = Math.floor((((deg - 15) % 360) + 180) / 30)
  return r % 2 === 0 ? '0' : '1'
}

const isPosition = (winVal: string, deg: number): boolean => {
  return winVal === sectorWinValue(deg)
}

const tickerValue = (deg: number) => {
  let a = sectorValue(deg)
  if (a <= tickerMoveEnd) {
    a += 1
  }
  a -= tickerMoveStart

  if (a < 0) {
    return 0
  }

  const tickerDelta = tickerMoveEnd + 1 - tickerMoveStart
  return a / tickerDelta
}

const rotateTicker = () => {
  knob.style.transform = 'rotate(' + (currentTickRotation + 90) + 'deg) translateX(-50%)'
}

const rotateWheel = () => {
  wheel.style.transform = 'rotate(' + constantDegrees + 'deg)'
}

const tickerRotation = (deg: number): number => {
  const a = sectorValue(deg)
  if (a > tickerMoveStart || a <= tickerMoveEnd) {
    const tr = a > tickerMoveStart ? (a - tickerMoveStart) * tickerStartCoefficient : a * tickerEndCoefficient + 0.25
    return Math.round(-90 - tr * 45)
  } else {
    return -90
  }
}

const animateTickerFinal = (started?: boolean) => {
  if (started) {
    if (Math.abs(-90 - tickerRotation(constantDegrees)) < 1) {
      speed = 0
    }
    // setTimeout(animateTickerFinal, 30);
    requestAnimationFrame(() => animateTickerFinal())
  } else {
    if (Math.abs(-90 - tickerRotation(constantDegrees)) < 1) {
      if (speed > 0) {
        speed = 0
      } else {
        speed += 0.02
      }
    } else {
      // accelerating the wheel backwards
      if (speed > -0.6) {
        speed -= 0.02
      }
    }

    constantDegrees += speed
    rotateWheel()

    if (currentTickRotation < -90) {
      if (speed === 0) {
        currentTickRotation += 7
      } else {
        currentTickRotation += 1
      }
    } else {
      currentTickRotation = -90
    }
    if (speed === 0 && Math.abs(currentTickRotation + 90) < 1) {
      setTimeout(function () {
        animationRunning = false
        if (undefined !== animationStoppedFunction) {
          animationStoppedFunction()
        }
      }, 200)
    } else {
      // setTimeout(animateTickerFinal, 20);
      requestAnimationFrame(() => animateTickerFinal())
    }
    rotateTicker()
  }
}

const animationTick = () => {
  if (slowDown) {
    if (0 === finalRestriction) {
      let hasStoppedAtRightPosition = false
      let ii = 0
      while (!hasStoppedAtRightPosition) {
        if (ii > 100) {
          break
        }
        ii++
        startSimAnimation(
          win,
          (degrees) => {
            hasStoppedAtRightPosition = isPosition(animationWon, degrees)
            if (!hasStoppedAtRightPosition) {
              speed += 0.006
            }
          },
          acceleration,
          constantDegrees,
          speed,
          acceleration,
          freeRestriction,
          tickerRestriction,
          extraRestriction,
          finalRestriction
        )
      }
      finalRestriction = freeRestriction
      let count = 0
      do {
        if (count % 10 === 0) {
          speed += 0.26 // just for failsafe, if the speed is too low
          finalRestriction = freeRestriction
        }
        count++
        finalRestriction += 0.5 / 600
        finalPosition = constantDegrees
        let speedProj = speed
        while (speedProj > 0) {
          finalPosition += speedProj
          speedProj -= finalRestriction + tickerValue(finalPosition) * tickerRestriction
        }
      } while (!isPosition(animationWon, finalPosition))
    }

    rotateWheel()
    constantDegrees += speed

    if (speed > 0) {
      speed -= finalRestriction + tickerValue(constantDegrees) * tickerRestriction
      animationRunning = true
      // setTimeout(animationTick, 20);
      requestAnimationFrame(animationTick)
    } else {
      constantDegrees = finalPosition
      animateTickerFinal(true)
    }
  } else {
    if (acceleration > 0) {
      speed += 1
      acceleration -= 1
      extraRestriction = 0
    } else {
      extraRestriction = speed / 300
    }

    constantDegrees += speed

    if (speed > 2) {
      speed -= freeRestriction + tickerValue(constantDegrees) * tickerRestriction + extraRestriction
    }
    rotateWheel()
    animationRunning = true
    // setTimeout(animationTick, 20);
    requestAnimationFrame(animationTick)
  }
  sector3Value = sector2Value
  sector2Value = sector1Value
  sector1Value = tickerValue(constantDegrees)

  const t = tickerRotation(constantDegrees)
  if (t - currentTickRotation < 7) {
    currentTickRotation = t
  } else {
    if (currentTickRotation < -90) {
      currentTickRotation += 7
    }
  }
  if (speed > 7 || (speed > 0 && sector2Value > sector1Value && sector2Value > sector3Value)) {
    // the top
    playTick()
  }

  // console.log('trotate', t, currentTickRotation);
  rotateTicker()
}

export const startAnimation = (scalePercent: number) => {
  acceleration = 1.6 + scalePercent / 28
  slowDown = false
  // speed = 0.05;
  finalRestriction = 0
  animationRunning = true
  knob = document.querySelector('.rb-knob') as HTMLImageElement
  wheel = document.querySelector('.rb-wheel-container') as HTMLImageElement
  animationTick()
}

export const stopAnimation = (w: string, stoppedFunc: () => void) => {
  win = w
  if (win === 'red') {
    animationWon = '1'
  } else {
    animationWon = '0'
  }
  animationStoppedFunction = stoppedFunc
  slowDown = true
}

/**
 * @generated SignedSource<<103317a48a9bb09836dcba80491de141>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ValidateInput = {
  gameUuid: string;
};
export type ProvablyFairValidatorValidateMutation$variables = {
  input: ValidateInput;
};
export type ProvablyFairValidatorValidateMutation$data = {
  readonly validate: {
    readonly res: string | null;
  } | null;
};
export type ProvablyFairValidatorValidateMutation = {
  variables: ProvablyFairValidatorValidateMutation$variables;
  response: ProvablyFairValidatorValidateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidatePayload",
    "kind": "LinkedField",
    "name": "validate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "res",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvablyFairValidatorValidateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProvablyFairValidatorValidateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7013a89d05a98724970fa4838659ca3",
    "id": null,
    "metadata": {},
    "name": "ProvablyFairValidatorValidateMutation",
    "operationKind": "mutation",
    "text": "mutation ProvablyFairValidatorValidateMutation(\n  $input: ValidateInput!\n) {\n  validate(input: $input) {\n    res\n  }\n}\n"
  }
};
})();

(node as any).hash = "db555e6579bb147940ebdf2d3e48dcfb";

export default node;

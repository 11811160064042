import { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import type SwiperCore from 'swiper'
import { Pagination, Mousewheel, Autoplay } from 'swiper'

import 'swiper/scss'
import 'swiper/scss/pagination'

import classes from './actionBanners.module.scss'
import { useIsMobile } from '@src/hooks'
import { Link } from 'react-router-dom'

import { openModalTask, ModalType } from '../modalComponent/modalComponent'
import React from 'react'
import { useActionBanners } from '@src/context/ActionBannerContext'

const ActionBannersComponent = () => {
  const actionBanners = useActionBanners()
  const isMobile = useIsMobile()

  const swiperRef = useRef<SwiperCore | null>(null)

  if (!actionBanners) {
    return null
  }

  return (
    <Swiper
      className={classes.root}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      modules={[Autoplay, Pagination, Mousewheel]}
      speed={1000}
      mousewheel={{ forceToAxis: true }}
      pagination={{ clickable: true }}
      spaceBetween={8}
      slidesPerView={isMobile ? 1.5 : 'auto'}
      onInit={(core: SwiperCore) => {
        swiperRef.current = core
      }}
    >
      {actionBanners.actionBanners.map((banner) => (
        <SwiperSlide
          key={banner._id}
          className={classes.slide}
          onClick={() => {
            if (banner?.modalWindow === 'investor') {
              openModalTask.next({ type: ModalType.beacomeAnInvestor })
            }
          }}
        >
          {banner?.redirect ? (
            <Link to={banner.redirect}>
              <img alt="Banner" src={banner.image} draggable={false} />
            </Link>
          ) : (
            <img alt="Banner" src={banner.image} draggable={false} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ActionBannersComponent

import { Money } from '@src/money'
import { Currency } from '../../../service/ConfigurationService'

const currencies = Money.listAll()

export const getChipsForValue = (value: number, c: Currency): number[] => {
  if (value <= 0) {
    return []
  }

  const shift =
    (currencies as any)[c.m_unit].units[c.s_unit].shift - (currencies as any)[c.m_unit].units[c.format].shift
  const chipValues = [5, 10, 25, 50, 500]
    .map((v) => v * Math.pow(10, shift))
    .slice()
    .sort((x, y) => {
      return x > y ? 1 : -1
    })

  let totalValue = value
  const ret: number[] = []

  out: while (totalValue > 0) {
    for (let i = chipValues.length - 1; i >= 0; i--) {
      const val = chipValues[i]
      if (totalValue >= val) {
        ret.push(val / Math.pow(10, shift))
        totalValue -= val
        if (ret.length > 20) {
          break out
        }
        continue out
      }
    }
    break
  }
  return ret
}

export const getChipForValue = (value: number, index: number) => {
  const divStyle = {
    top: index * -0.1 + 'em',
    left: index * 0.06 + 'em',
  }
  return <div key={'chip_' + index} style={divStyle} data-value={value} className={'chip chip' + value} />
}

export const getChipStackForChips = (value: number[]) => {
  let chips: JSX.Element[] = value.slice(0, 8).map((val, index) => {
    return getChipForValue(val, index)
  })
  return <div className="chip-stack">{chips}</div>
}

export const getChipStackForValue = (value: number, c: Currency) => {
  if (value <= 0) {
    return null
  }

  return getChipStackForChips(getChipsForValue(value, c))
}

/**
 * @generated SignedSource<<76b99f6dd4a0a8665c4984e4237cfbf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type AddBankrollInput = {
  currency: Currency;
  amount: number;
};
export type BecomeAnInvestorAddBankrollMutation$variables = {
  input: AddBankrollInput;
};
export type BecomeAnInvestorAddBankrollMutation$data = {
  readonly addBankroll: {
    readonly resp: {
      readonly currency: Currency;
      readonly active: boolean;
      readonly percent: number;
      readonly meta: any;
      readonly amount: number;
      readonly paidAmount: number;
      readonly insertedAt: string;
    };
  } | null;
};
export type BecomeAnInvestorAddBankrollMutation = {
  variables: BecomeAnInvestorAddBankrollMutation$variables;
  response: BecomeAnInvestorAddBankrollMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddBankrollPayload",
    "kind": "LinkedField",
    "name": "addBankroll",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bankroll",
        "kind": "LinkedField",
        "name": "resp",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insertedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BecomeAnInvestorAddBankrollMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BecomeAnInvestorAddBankrollMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1c8423fc8fb0ce8e869a26831c6de1ce",
    "id": null,
    "metadata": {},
    "name": "BecomeAnInvestorAddBankrollMutation",
    "operationKind": "mutation",
    "text": "mutation BecomeAnInvestorAddBankrollMutation(\n  $input: AddBankrollInput!\n) {\n  addBankroll(input: $input) {\n    resp {\n      currency\n      active\n      percent\n      meta\n      amount\n      paidAmount\n      insertedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d7a041c2c410d714c45d99d6c37a12d";

export default node;

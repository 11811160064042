/**
 * @generated SignedSource<<ca7a9208355ae75b7d5589dc195480a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type GameListComponentSGameClosedSubscription$variables = {};
export type GameListComponentSGameClosedSubscription$data = {
  readonly sGameClosed: any | null;
};
export type GameListComponentSGameClosedSubscription = {
  variables: GameListComponentSGameClosedSubscription$variables;
  response: GameListComponentSGameClosedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sGameClosed",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GameListComponentSGameClosedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GameListComponentSGameClosedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7f1c7b5fab2e0606af197327f6cdd13f",
    "id": null,
    "metadata": {},
    "name": "GameListComponentSGameClosedSubscription",
    "operationKind": "subscription",
    "text": "subscription GameListComponentSGameClosedSubscription {\n  sGameClosed\n}\n"
  }
};
})();

(node as any).hash = "7bd7faacf50d7935af5621210ca934d2";

export default node;

/**
 * @generated SignedSource<<70995162126aec09236457590067daff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WalletComponentFiatRatesQuery$variables = {};
export type WalletComponentFiatRatesQuery$data = {
  readonly fiatRates: ReadonlyArray<{
    readonly key: string | null;
    readonly value: string | null;
  }>;
};
export type WalletComponentFiatRatesQuery = {
  variables: WalletComponentFiatRatesQuery$variables;
  response: WalletComponentFiatRatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "KeyValue",
    "kind": "LinkedField",
    "name": "fiatRates",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletComponentFiatRatesQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WalletComponentFiatRatesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "600178c35af0e78a424caaceef39ac54",
    "id": null,
    "metadata": {},
    "name": "WalletComponentFiatRatesQuery",
    "operationKind": "query",
    "text": "query WalletComponentFiatRatesQuery {\n  fiatRates {\n    key\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "77d46556b62e60689d72aaed1f2316d7";

export default node;

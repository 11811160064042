import { getUserIcon } from '@src/App'
import { FormattedPlayer } from '..'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatNumberWithCommas, gameClickHandler } from '@src/utils/functions'

interface Props {
  player: FormattedPlayer
}

const MainWinnerItem = ({ player }: Props) => {
  const { user } = player
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div
      className="bg-eucalyptus rounded-4 p-12 flex items-center lg:h-145"
      style={{
        backgroundImage: 'url("/assets/landing/depositBanner.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="w-1/3 flex justify-center">
        {getUserIcon(user.name, user.uuid, user.hasProfileImage, user.vip, false, '100px')}
      </div>
      <div className="w-2/3 flex flex-col ml-15">
        <p className="text-white text-md font-extrabold">{user.name}</p>
        <p className="linerGradientText text-md font-extrabold"></p>
        <p className="linerGradientText text-4xl font-extrabold">${formatNumberWithCommas(Number(player.amountWon))}</p>
        <p className="text-white text-2xs font-medium">
          {t('landingPedestal.bet', 'Bet')} <span className="text-topaz">{player.betAmount}</span>{' '}
          {t('landingPedestal.multiplier', 'Multiplier')} <span className="text-topaz">{player.multiplier}x</span>{' '}
          {t('landingPedestal.game', 'Game')}{' '}
          <span
            className="text-topaz cursor-pointer"
            onClick={() => gameClickHandler(player.normalizedGame.gameId, player.normalizedGame.provider, history)}
          >
            {player.normalizedGame.label}
          </span>
        </p>
      </div>
    </div>
  )
}

export default MainWinnerItem

const ExpandIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 7.5C4.5 7.30109 4.57902 7.11032 4.71967 6.96967C4.86032 6.82902 5.05109 6.75 5.25 6.75L13.9395 6.75L10.719 3.531C10.5782 3.39017 10.4991 3.19916 10.4991 3C10.4991 2.80084 10.5782 2.60983 10.719 2.469C10.8598 2.32817 11.0508 2.24905 11.25 2.24905C11.4492 2.24905 11.6402 2.32817 11.781 2.469L16.281 6.969C16.3508 7.03867 16.4063 7.12143 16.4441 7.21255C16.4819 7.30367 16.5013 7.40135 16.5013 7.5C16.5013 7.59865 16.4819 7.69633 16.4441 7.78745C16.4063 7.87857 16.3508 7.96133 16.281 8.031L11.781 12.531C11.6402 12.6718 11.4492 12.7509 11.25 12.7509C11.0508 12.7509 10.8598 12.6718 10.719 12.531C10.5782 12.3902 10.4991 12.1992 10.4991 12C10.4991 11.8008 10.5782 11.6098 10.719 11.469L13.9395 8.25L5.25 8.25C5.05109 8.25 4.86032 8.17098 4.71967 8.03033C4.57902 7.88968 4.5 7.69891 4.5 7.5ZM0.75 0C0.551088 0 0.360322 0.0790167 0.21967 0.219669C0.0790178 0.360322 0 0.551087 0 0.75L0 13.5C0 13.6989 0.0790178 13.8897 0.21967 14.0303C0.360322 14.171 0.551088 14.25 0.75 14.25C0.948912 14.25 1.13968 14.171 1.28033 14.0303C1.42098 13.8897 1.5 13.6989 1.5 13.5L1.5 0.75C1.5 0.551087 1.42098 0.360322 1.28033 0.219669C1.13968 0.0790167 0.948912 0 0.75 0Z"
      fill="currentColor"
    />
  </svg>
)

export default ExpandIcon

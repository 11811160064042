import { Money } from '@src/money'
import { UserProp } from '../../reducers/authentication.reducer'
import { getBalance } from '../../reducers/wallet.reducer'
import { Currency } from '../../service/ConfigurationService'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'

const currencies = Money.listAll()

export const BetBalance = ({
  currency,
  bet,
  chipSelect,
}: {
  currency: Currency
  bet: JSX.Element | null
  chipSelect: (ev: React.MouseEvent, chip: number) => void
}) => {
  const c = currency
  const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
  const balance = getBalance(c.short, 0)
  const m = Money.convertUnit(balance, c.m_unit, c.s_unit, format)
  const cFormat = c.short === 'FUN' ? 'FUN' : format

  const shift = currencies[c.m_unit].units[c.s_unit].shift - currencies[c.m_unit].units[c.format].shift
  const chipValues = [5, 10, 25, 50, 500].map((v) => {
    return v * Math.pow(10, shift)
  })

  const chips = chipValues.map((e) => {
    return (
      <div
        key={e}
        className={'chip chip' + e / Math.pow(10, shift)}
        onTouchEnd={(ev) => chipSelect(ev as any, e / Math.pow(10, shift))}
        onMouseUp={(ev) => chipSelect(ev, e / Math.pow(10, shift))}
      />
    )
  })

  const openProvablyFair = () => {}

  return (
    <div className={'h-bottom'}>
      <div className="h-chip-row">
        <div className="h-balance">
          <div className="h-small-head">BALANCE</div>
          <div>
            {m}
            {cFormat}
          </div>
        </div>
        <div className="h-chips">{chips}</div>
        <div className="h-bet">
          <div className="h-small-head">YOUR TOTAL BET</div>
          {bet}
        </div>
      </div>
      <div className="h-rules-row">
        <div className="h-rules-left">
          <span>HOUSE TAKES 0.5% BET COMMISION</span>
        </div>
        <div className="h-rules-middle">
          <img alt="" onClick={openProvablyFair} src="/assets/pf.png" />
        </div>
        <div className="h-rules-right">
          <div className="h-rule-100">50%</div>
          <div className="h-column">
            <span>CHANCE TO WIN</span>
            <span>JUST LIKE IN LIFE</span>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * @generated SignedSource<<f2c3049f16ff425dba337679b9c82904>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type SignInComponentMeQuery$variables = {};
export type SignInComponentMeQuery$data = {
  readonly me: {
    readonly name: string | null;
    readonly username: string | null;
    readonly otpEnabled: boolean | null;
    readonly anonymousHash: string | null;
    readonly uuid: string | null;
    readonly roles: ReadonlyArray<string> | null;
    readonly confirmed: boolean | null;
    readonly chatApproval: boolean | null;
    readonly insertedAt: string | null;
    readonly kycState: string;
    readonly refProps: any | null;
    readonly hasProfileImage: boolean;
    readonly preferences: ReadonlyArray<{
      readonly key: string | null;
      readonly value: string | null;
      readonly valueJson: string | null;
    }> | null;
    readonly userStats: ReadonlyArray<{
      readonly name: string;
      readonly value: number;
      readonly meta: any | null;
    }>;
    readonly affiliateStats: {
      readonly count: number;
      readonly totalCreditSumUsd: number;
      readonly creditByCurrency: any;
    };
    readonly kycSuccessful: boolean | null;
    readonly pendingKycBonuses: ReadonlyArray<{
      readonly amount: string;
      readonly currency: Currency;
      readonly playThrough: number;
    }>;
    readonly eliteSeat: {
      readonly currentLevel: {
        readonly name: string;
        readonly tierStartUsd: number;
        readonly tierStartLevel: number;
        readonly tierStep: number;
        readonly tierCount: number;
        readonly bonusUsd: ReadonlyArray<number>;
        readonly levelNr: number;
      } | null;
      readonly currentTier: number;
      readonly nextTier: {
        readonly name: string;
        readonly tierStartUsd: number;
        readonly tierStartLevel: number;
        readonly tierStep: number;
        readonly tierCount: number;
        readonly bonusUsd: ReadonlyArray<number>;
        readonly levelNr: number;
      } | null;
      readonly tierProgress: number;
      readonly levelProgress: number;
    };
  } | null;
};
export type SignInComponentMeQuery = {
  variables: SignInComponentMeQuery$variables;
  response: SignInComponentMeQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tierStartUsd",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tierStartLevel",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tierStep",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tierCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bonusUsd",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "levelNr",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otpEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "anonymousHash",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confirmed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chatApproval",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kycState",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refProps",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasProfileImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPreference",
        "kind": "LinkedField",
        "name": "preferences",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valueJson",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserStat",
        "kind": "LinkedField",
        "name": "userStats",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAffiliateStat",
        "kind": "LinkedField",
        "name": "affiliateStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCreditSumUsd",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creditByCurrency",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kycSuccessful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PendingBonus",
        "kind": "LinkedField",
        "name": "pendingKycBonuses",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playThrough",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EliteSeatProgress",
        "kind": "LinkedField",
        "name": "eliteSeat",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EliteSeatLevel",
            "kind": "LinkedField",
            "name": "currentLevel",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentTier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EliteSeatLevel",
            "kind": "LinkedField",
            "name": "nextTier",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tierProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "levelProgress",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInComponentMeQuery",
    "selections": (v3/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignInComponentMeQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4a3450e84fe0abad1fdf2bb1f01fef26",
    "id": null,
    "metadata": {},
    "name": "SignInComponentMeQuery",
    "operationKind": "query",
    "text": "query SignInComponentMeQuery {\n  me {\n    name\n    username\n    otpEnabled\n    anonymousHash\n    uuid\n    roles\n    confirmed\n    chatApproval\n    insertedAt\n    kycState\n    refProps\n    hasProfileImage\n    preferences {\n      key\n      value\n      valueJson\n    }\n    userStats {\n      name\n      value\n      meta\n    }\n    affiliateStats {\n      count\n      totalCreditSumUsd\n      creditByCurrency\n    }\n    kycSuccessful\n    pendingKycBonuses {\n      amount\n      currency\n      playThrough\n    }\n    eliteSeat {\n      currentLevel {\n        name\n        tierStartUsd\n        tierStartLevel\n        tierStep\n        tierCount\n        bonusUsd\n        levelNr\n      }\n      currentTier\n      nextTier {\n        name\n        tierStartUsd\n        tierStartLevel\n        tierStep\n        tierCount\n        bonusUsd\n        levelNr\n      }\n      tierProgress\n      levelProgress\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d111ec4ffc1dc0eeb49094265294c2ed";

export default node;

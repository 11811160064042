import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoginHeader } from '../Login/Login'
import IAccountCreated from './AccountCreated.interface'
import styles from '../Login/Login.module.scss'
import { AppTypes } from '../../../reducers/app.reducer'
import { IStore, store } from '../../../store'
import { useSelector } from 'react-redux'

const AccountCreated: React.FC<IAccountCreated> = () => {
  const { t } = useTranslation()
  const user = useSelector((state: IStore) => state.authentication.user)
  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  return (
    <div className={styles.root}>
      <LoginHeader activeTab={t('authentication.accountCreated.header', 'ACCOUNT CREATED')} />
      <div className={styles.body}>
        <img src="/assets/auth/welcome.svg" className="w-180 mx-auto mb-30" alt="Account created" />
        <div className={styles.infoLarge}>
          {t('authentication.accountCreated.welcome', 'Welcome to our community')} {user?.name}.
        </div>
      </div>
      <div className={styles.footer}>
        <button
          type="submit"
          className={styles.green}
          onClick={(e) => {
            e.preventDefault()
            setModalView(null)
          }}
        >
          {t('authentication.accountCreated.haveFun', 'Have Fun!')}
        </button>
      </div>
    </div>
  )
}

export default AccountCreated

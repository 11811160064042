export function DiamondShape({ className }: { className?: string }) {
  return (
    <svg
      width="96"
      height="113"
      viewBox="0 0 96 113"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M69.9696 96.802H47.7539V102.393H69.9696V96.802Z" fill="#FF03F9" />
      <path d="M42.9971 18.5398L69.6826 18.5398V16.6184L42.9971 16.6184V18.5398Z" fill="#FF03F9" />
      <path d="M35.9457 25.6782H13.73V30.8552H35.9457V25.6782Z" fill="#02E5FF" />
      <path d="M33.7505 71.4573H7.06494V73.3786H33.7505V71.4573Z" fill="#02E5FF" />
      <path d="M15.3442 89.4233H42.3633V85.574H15.3442V89.4233Z" fill="#02E5FF" />
      <path d="M76.2874 51.9634H95.6211V48.114H76.2874V51.9634Z" fill="#FF03F9" />
      <path d="M5.71738 56.5L28.3334 25.6782H22.616L0 56.5L9.56677 69.5359H15.2842L5.71738 56.5Z" fill="#02E5FF" />
      <path d="M47.1798 0H41.4624L27.3857 19.1802H33.1031L47.1798 0Z" fill="#02E5FF" />
      <path d="M21.335 85.574L41.4625 113H47.1799L27.0523 85.574H21.335Z" fill="#02E5FF" />
      <path d="M16.6983 71.4573H10.981L18.513 81.7245H24.2303L16.6983 71.4573Z" fill="#02E5FF" />
      <path
        d="M88.6362 56.4999L61.8906 92.9523H67.6013L94.3536 56.4999L88.2026 48.1206H82.4852L88.6362 56.4999Z"
        fill="#FF03F9"
      />
      <path d="M63.6719 14.6904L52.8909 0H47.1802L57.9611 14.6904H63.6719Z" fill="#FF03F9" />
      <path d="M47.1802 113H52.8909L64.7793 96.802H59.0619L47.1802 113Z" fill="#FF03F9" />
      <path d="M79.663 44.2714H85.3804L65.086 16.6118H59.3687L79.663 44.2714Z" fill="#FF03F9" />
      <path
        d="M55.9595 96.8019V92.9525H61.8904L88.636 56.5L82.485 48.1207H77.1345V44.2713H79.663L59.3686 16.6118H55.8928V14.6904H57.9609L47.18 0L33.1033 19.1802H35.9454V25.6782H28.3333L5.71729 56.5L15.2841 69.5359H24.2704V71.4573H16.6984L24.2304 81.7245H34.9313V85.5739H27.0524L47.18 113L59.0617 96.8019H55.9595Z"
        fill="black"
      />
      <path d="M3.21585 73.3853H5.13721V71.4639H3.21585V73.3853Z" fill="#02E5FF" />
      <path d="M18.7267 29.5342L47.6538 29.5342V25.6848L18.7267 25.6848V29.5342Z" fill="black" />
      <path d="M44.0315 100.645H67.5415V96.7951H44.0315V100.645Z" fill="black" />
      <path d="M71.911 100.645H78.042V96.7951H71.911V100.645Z" fill="#02E5FF" />
      <path d="M75.7201 100.645H81.8511V96.7951H75.7201V100.645Z" fill="#FF03F9" />
      <path d="M73.8124 100.645H79.9434V96.7951H73.8124V100.645Z" fill="black" />
      <path d="M42.6033 81.7246H38.7539V85.574H42.6033V81.7246Z" fill="#02E5FF" />
      <path d="M48.3676 81.7246H46.4463V85.574H48.3676V81.7246Z" fill="#FF03F9" />
      <path d="M69.4226 48.1206H73.272V44.2712H69.4226V48.1206Z" fill="#FF03F9" />
      <path d="M63.6519 48.1206H65.5732V44.2712H63.6519V48.1206Z" fill="#02E5FF" />
      <path d="M11.4947 89.4233H13.416V85.574H11.4947V89.4233Z" fill="#02E5FF" />
      <path d="M7.64553 89.4233H9.56689V85.574H7.64553V89.4233Z" fill="#02E5FF" />
      <path d="M26.7659 42.3499H22.9165V46.1992H26.7659V42.3499Z" fill="#02E5FF" />
      <path d="M69.469 68.7019H65.6196V72.5513H69.469V68.7019Z" fill="#FF03F9" />
      <path d="M48.1941 16.6184H52.0435V14.697H48.1941V16.6184Z" fill="#FF03F9" />
      <path d="M73.5317 16.6118H71.6104V18.5332H73.5317V16.6118Z" fill="#FF03F9" />
      <path d="M31.9627 69.5359H28.1133V71.4572H31.9627V69.5359Z" fill="#02E5FF" />
      <path d="M33.8842 36.7527H31.9629V38.674H33.8842V36.7527Z" fill="#FF03F9" />
      <path d="M71.3906 66.7808H69.4692V68.7021H71.3906V66.7808Z" fill="#02E5FF" />
    </svg>
  )
}

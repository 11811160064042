import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { ProfileQueryResponse } from '@src/pages/Profile/gql/__generated__/ProfileQuery.graphql'
import { Writable } from '@src/types/writable.type'

// Define the structure of the profile menu
const profileMenu = {
  profile: {
    tabs: [] as { name: string; disabled: boolean }[],
    disabled: true,
  },
  account: {
    tabs: [
      { name: 'Dashboard', disabled: false },
      { name: 'Bonus Code', disabled: true },
    ] as const,
    disabled: false,
  },
  affiliate: {
    tabs: [
      { name: 'Banners', disabled: true },
      { name: 'Direct Link', disabled: false },
      { name: 'Earnings', disabled: true },
    ] as const,
    disabled: false,
  },
  quests: {
    tabs: [
      { name: 'Daily Quests', disabled: false },
      { name: 'Weekly Quests', disabled: false },
    ] as const,
    disabled: true,
  },
  transactions: {
    tabs: [
      { name: 'Deposit', disabled: false },
      { name: 'Withdraw', disabled: false },
      { name: 'History', disabled: false },
    ] as const,
    disabled: true,
  },
  settings: {
    tabs: [
      { name: 'KYC', disabled: false },
      { name: 'Security', disabled: false },
      { name: 'History', disabled: true },
    ] as const,
    disabled: false,
  },
} as const

// Infer types for profile tab and sub-tab
export type ProfileTab = keyof typeof profileMenu
export type ProfileSubTab<T extends keyof typeof profileMenu> = typeof profileMenu[T]['tabs'][number]['name']

// Define Profile type
type Profile = Writable<ProfileQueryResponse['profile']> | null

// Create an array of profile tabs
export const profileTabs = Object.keys(profileMenu).map((key) => ({
  name: key as ProfileTab,
  disabled: profileMenu[key as ProfileTab].disabled,
}))

// Get sub-tabs for a given profile tab
export const getProfileSubTabs = (tab: ProfileTab) => {
  return [...profileMenu[tab].tabs]
}

// Infer sub-tab type based on profile tab
type SubTabInferredFromTab<T extends ProfileTab> = T extends ProfileTab ? ProfileSubTab<T> : never

// Define profile state
export type ProfileState = {
  tab: ProfileTab
  subTab: SubTabInferredFromTab<ProfileTab> | null
  profile: Profile
}

// Initial state for the profile slice
const initialState: ProfileState = {
  tab: 'account',
  subTab: 'Dashboard',
  profile: null,
}

// Create profile slice
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<{ tab: ProfileTab }>) => {
      state.tab = action.payload.tab
      state.subTab = null
    },
    changeSubTab: (state, action: PayloadAction<{ subTab: SubTabInferredFromTab<ProfileTab> }>) => {
      state.subTab = action.payload.subTab
    },
    setProfile: (state, action: PayloadAction<{ profile: Profile }>) => {
      state.profile = action.payload.profile
    },
  },
})

// Export actions and reducer
export const { changeTab, changeSubTab, setProfile } = profileSlice.actions
export default profileSlice.reducer

import { combineReducers, Reducer } from '@reduxjs/toolkit'
import { AuthAction, authentication, State as AState } from './authentication.reducer'
import { wallet, State as WState, WalletAction } from './wallet.reducer'
import { app, State as AppState, AppAction } from './app.reducer'

import { multiplayer, State as TState, TableAction } from './multiplayer.reducer'
import chat, { type ChatAction, type ChatState } from './chat.reducer'

import profile, { ProfileState } from './profile.reducer'
import authModal, { AuthModalState } from './authModal.reducer'

export const rootReducer = combineReducers<{
  app: Reducer<AppState, AppAction>
  chat: Reducer<ChatState, ChatAction>
  authModal: Reducer<AuthModalState>
  authentication: Reducer<AState, AuthAction>
  wallet: Reducer<WState, WalletAction>
  multiplayer: Reducer<TState, TableAction>
  profile: Reducer<ProfileState>
}>({ app, chat, authModal, authentication, wallet, multiplayer, profile })

import { User } from '../reducers/authentication.reducer'
import { store } from '../store'

export const isUserLoggedIn = () => {
  return store.getState().authentication.user?.anonymousHash === null
}

export const roundNumberToLength = (value: number, requiredNumberOfSybols: number = 5) => {
  const numerOfSymbols = value.toString().split('.')[0].length
  const numerOfSymbolsLeft = numerOfSymbols >= requiredNumberOfSybols ? 0 : requiredNumberOfSybols - numerOfSymbols
  const res = Math.round(value * 10 ** numerOfSymbolsLeft) / 10 ** numerOfSymbolsLeft
  return res.toLocaleString('en-US')
}

export const isAdminUser = () => {
  const user = store.getState().authentication.user as User
  console.log(user)
  return user.roles && user.roles.findIndex((x) => x === 'admin') >= 0
}

export const isModeratorUser = () => {
  const user = store.getState().authentication.user as User
  return user.roles && user.roles.findIndex((x) => x === 'admin' || x === 'moderator') >= 0
}

export const convertToK = (num: number) => {
  if (num === 0) return '0';
  if (Math.abs(num) < 1000) return num.toString();

  const sign = Math.sign(num);
  const absNum = Math.abs(num);
  const kValue = absNum / 1000;

  const formattedValue = kValue % 1 === 0 ? kValue.toFixed(0) : kValue.toFixed(1);

  return sign === -1 ? `-${formattedValue}K` : `${formattedValue}K`;
}

export const formatNumberWithCommas = (num: number) => {
  const roundedNum = Math.round(num);

  return roundedNum.toLocaleString('en-US');
}

export const gameClickHandler = (gameId: string, provider: string, history: any) => {
  if (gameId === 'blackjack' && provider === 'bjfun') {
    history.push('/game/bitcoin-blackjack');
  } else {
    history.push(`/game/${provider}:${gameId}`);
  }
};
import { useEffect, useRef, useState } from 'react'

import { Autoplay, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import type SwiperCore from 'swiper'

import { useIsMobile } from '@src/hooks'
import sanityClient from '@src/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

import classes from './topGames.module.scss'
import { Link } from 'react-router-dom'
import { GameCard } from './gameCard'
import useIntersectionObserver from '@src/hooks/useIntersectionObserver'
import SkeletonLoader from './SkeletonLoader'
import CarouselHeader from '../Elements/CarouselHeader'
import FireIcon from '../icons/fire'
import { useTranslation } from 'react-i18next'

export default function ClassicGames() {
  const uniqueId = 'classic-carousel'
  const { t } = useTranslation()

  const [games, setGames] = useState<
    Array<{
      _id: string
      _type: string
      order: number
      header: string
      image: string
      gameLink: string
    }>
  >([])

  const isMobile = useIsMobile()
  const swiperRef = useRef<SwiperCore | null>(null)
  const [loading, setLoading] = useState(true)
  const componentRef = useRef(null) // This ref is used for observing the component visibility
  const hasFetchedRef = useRef(false) // Control flag to prevent multiple fetches

  const isVisible = useIntersectionObserver({
    target: componentRef,
    onIntersect: () => {},
    enabled: true,
    rootMargin: '50px',
  })

  useEffect(() => {
    if (isVisible && !hasFetchedRef.current) {
      fetchData()
      hasFetchedRef.current = true // Indicate that data has been fetched
    }
  }, [isVisible])

  function fetchData() {
    const query = `*[_type == "topGames_classic"]`

    sanityClient.fetch(query).then((res) => {
      const fetchedGames = res
        .filter((g) => !g?._id.startsWith('drafts.'))
        .map((g) => ({ ...g, image: imageUrlBuilder(sanityClient).image(g.image).url() }))
        .sort((a, b) => (a.order > b.order ? 1 : -1))
      setGames(fetchedGames)
      setLoading(false)
    })
  }

  const headerText = t('menu.classicGames', 'Classic Games')

  return (
    <div className={classes.root} ref={componentRef}>
      <CarouselHeader
        text={headerText}
        swiperRef={swiperRef}
        icon={<FireIcon color="#D5DCEB" className={'w-16 h-16 text-carouselHeading'} />}
        uniqueId={uniqueId}
      />
      <div className="pb-10">
        {loading ? (
          <SkeletonLoader />
        ) : (
          <Swiper
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            mousewheel={{ forceToAxis: true }}
            modules={[Autoplay, Mousewheel, Navigation]}
            spaceBetween={14.6}
            slidesPerView={isMobile ? 3 : 'auto'}
            onInit={(core: SwiperCore) => {
              swiperRef.current = core
            }}
            navigation={{
              prevEl: `.${uniqueId}-swiper-custom-button-prev`,
              nextEl: `.${uniqueId}-swiper-custom-button-next`,
            }}
          >
            {games.map((g) => (
              <SwiperSlide key={g._id} className={classes.slide}>
                {isMobile ? (
                  <Link to={g.gameLink}>
                    <img alt="" src={g.image} />
                  </Link>
                ) : (
                  <GameCard gameURL={g.gameLink} gameImageSRC={g.image} />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

import { useState, useLayoutEffect } from 'react'

const screens = {
  xs: '375px',
  sm: '420px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
  xxl: '1600px',
}

export const isMobile = (breakPoint: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'md', overwrite = true, width?: number) => {
  if (!screens || !(breakPoint in screens) || typeof window === 'undefined') {
    return overwrite
  }

  const bpWidth = width ?? parseInt(screens[breakPoint].replace('px', ''))

  return window.innerWidth < bpWidth
}

const useIsMobile = (breakPoint: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'md', overwrite = true, width?: number) => {
  const [isMobile, setIsMobile] = useState(overwrite)

  useLayoutEffect(() => {
    if (!screens || !(breakPoint in screens) || typeof window === 'undefined') {
      return
    }

    const bpWidth = width ?? parseInt(screens[breakPoint].replace('px', ''))

    const onResize = () => {
      setIsMobile(window.innerWidth < bpWidth)
    }

    // trigger callback once after initial rendering
    onResize()
    // add event listeners for all events from the array above
    const events = ['resize', 'orientationchange']
    events.forEach((e: any) => {
      window.addEventListener(e, onResize)
    })
    // remove event listeners for all events from the array above
    return () => {
      events.forEach((e: any) => {
        window.removeEventListener(e, onResize)
      })
    }
  }, [width, breakPoint])

  return isMobile
}

export default useIsMobile

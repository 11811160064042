import { graphql } from 'babel-plugin-relay/macro'

export const myBetsQuery = graphql`
  query myBetsQuery {
    myBets {
      gameId
      gameProvider
      currency
      virtualId
      finished
      bets
      win
      meta
      insertedAt
      profit
      multiplier
      normalizedGame {
        label
        gameId
        provider
      }
      user {
        name
        uuid
        vip {
          badgeId
          offset
          avatar
        }
        hasProfileImage
      }
    }
  }
`

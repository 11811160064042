import { z } from 'zod'
import { useTranslation } from 'react-i18next'
import { countries } from '@src/configs/countries'
import { toast } from 'react-toastify'
import { Step1 } from './Step1'
import classes from './ProfileKYC.module.scss'
import { Collapsible } from '../Collapsible/Collapsible'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'
import { useMutation } from 'react-relay'
import type { submitKYCMutation as SubmitKYCMutation } from './gql/__generated__/submitKYCMutation.graphql'
import { submitKYCMutation } from './gql/submitKYC.gql'
import formatError from '@src/components/errorFormatter'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

export const schema = z.object({
  firstname: z.string(),
  lastname: z.string(),
  email: z.string().email().optional(),
  gender: z.enum(['Unspecified', 'Male', 'Female']).optional(),
  dob: z.string(),
  country: z.union([z.string(), z.enum(countries)]),
  city: z.string(),
  address: z.string(),
  zip: z.string(),
  file1: z.object({
    data: z.any(),
    name: z.string(),
  }),
  file2: z.object({
    data: z.any(),
    name: z.string(),
  }),
  file3: z.object({
    data: z.any(),
    name: z.string(),
  }),
})

export const kycSteps = [1, 2, 3, 4] as const

export function ProfileKYC() {
  const { t } = useTranslation()
  const me = useSelector((s: IStore) => s.authentication.user)!
  const { kycState, kycSuccessful } = me

  const [submitKYC] = useMutation<SubmitKYCMutation>(submitKYCMutation)

  const initialValues: z.infer<typeof schema> = {
    firstname: '',
    lastname: '',
    email: '',
    dob: '',
    city: '',
    country: '',
    address: '',
    zip: '',
    file1: {
      data: undefined,
      name: t('profile.KYC.tapToUpload', 'tap to upload file'),
    },
    file2: {
      data: undefined,
      name: t('profile.KYC.tapToUpload', 'tap to upload file'),
    },
    file3: {
      data: undefined,
      name: t('profile.KYC.tapToUpload', 'tap to upload file'),
    },
  }

  const handleSubmit = (values: z.infer<typeof schema>) => {
    const { file1, file2, file3, ...rest } = values

    submitKYC({
      variables: {
        input: {
          infoFields: JSON.stringify({ ...rest }),
          imageFields: JSON.stringify({
            file1,
            file2,
            file3,
          }),
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          for (const err of errors) {
            toast.error(JSON.stringify(err))
          }
          return
        }

        window.location.reload()
      },
      onError: (err) => {
        const e = formatError(err)
        toast.error(e[0])
      },
    })
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={toFormikValidationSchema(schema)}>
      <Form className={classes.root}>
        <h3 className="flex items-center gap-x-12">
          {t('profile.KYC.verification', 'Verification (KYC)')}
          {kycState === 'idle' && kycSuccessful && (
            <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#7FBA7A" />
              <path d="M13.6 18L10.4 14.8L8 17.2L13.6 22.8L24 12.4L21.6 10L13.6 18Z" fill="white" />
            </svg>
          )}
          {kycState === 'waiting_confirmation' && (
            <span className="text-brightYellow text-xs">
              {t('profile.KYC.awaitingConfirmation', 'Awaiting confirmation.')}
            </span>
          )}
        </h3>
        <div className={classes.container}>
          <Collapsible title={t('profile.KYC.generalInformation', 'General Information')} openOnRender disabled>
            <Step1 />
          </Collapsible>
          <Collapsible
            title={t('profile.KYC.governmentDocument', 'Valid government-issued document')}
            openOnRender
            disabled
          >
            <Step2 />
          </Collapsible>
          <Collapsible title={t('profile.KYC.proofOfResidence', 'Proof of residence')} openOnRender disabled>
            <Step3 />
          </Collapsible>
          <Collapsible title={t('profile.KYC.photoOfDay', 'Photo of the day')} openOnRender disabled>
            <Step4 />
          </Collapsible>
          <div className={classes.buttonWrapper}>
            <button
              type="submit"
              style={{
                color: 'white',
                backgroundColor: '#3CCEA2',
                fontSize: '14px',
                fontWeight: '700',
                width: 292,
                padding: '18px 0',
                borderRadius: '7px',
              }}
            >
              {t('profile.KYC.sendVerification', 'Send verification')}
            </button>
          </div>
          <div className="pt-45">
            <h6>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 0C13.9706 -2.16029e-07 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 2.18519e-07 13.9706 0 9C-2.18519e-07 4.02944 4.02944 2.16029e-07 9 0Z"
                  fill="#28C99B"
                />
                <path
                  d="M8 13V7.41455H10V13H8ZM9 6.83273C8.72947 6.83273 8.49758 6.74303 8.30435 6.56364C8.11111 6.38424 8.01449 6.16848 8.01449 5.91636C8.01449 5.66424 8.11111 5.44848 8.30435 5.26909C8.49758 5.0897 8.72947 5 9 5C9.27295 5 9.50483 5.0897 9.69565 5.26909C9.88889 5.44848 9.98551 5.66424 9.98551 5.91636C9.98551 6.16848 9.88889 6.38424 9.69565 6.56364C9.50483 6.74303 9.27295 6.83273 9 6.83273Z"
                  fill="white"
                />
              </svg>
              {t('profile.KYC.withdrawalsInfo', 'Withdrawals can be made regardless of KYC status')}
            </h6>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

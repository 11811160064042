/**
 * @generated SignedSource<<27c6105403e54d6aaadb9475a4538657>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecoverPasswordInput = {
  key?: string | null;
  newPassword?: string | null;
};
export type RecoverPasswordRecoverPasswordMutation$variables = {
  input: RecoverPasswordInput;
};
export type RecoverPasswordRecoverPasswordMutation$data = {
  readonly recoverPassword: {
    readonly res: string | null;
  } | null;
};
export type RecoverPasswordRecoverPasswordMutation = {
  variables: RecoverPasswordRecoverPasswordMutation$variables;
  response: RecoverPasswordRecoverPasswordMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecoverPasswordPayload",
    "kind": "LinkedField",
    "name": "recoverPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "res",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecoverPasswordRecoverPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecoverPasswordRecoverPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "639a315cdc856a6c70b1d83127a7fe1b",
    "id": null,
    "metadata": {},
    "name": "RecoverPasswordRecoverPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation RecoverPasswordRecoverPasswordMutation(\n  $input: RecoverPasswordInput!\n) {\n  recoverPassword(input: $input) {\n    res\n  }\n}\n"
  }
};
})();

(node as any).hash = "f62430af2d93aca3103646f99d55e7dd";

export default node;

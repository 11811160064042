/**
 * @generated SignedSource<<cef779074acd3a04f98294af41f402b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type SubmitWithdrawInput = {
  address: string;
  currency: Currency;
  amount: number;
  virtualId?: number | null;
  network?: string | null;
  memo?: string | null;
};
export type WithdrawViewSubmitWithdrawMutation$variables = {
  input: SubmitWithdrawInput;
};
export type WithdrawViewSubmitWithdrawMutation$data = {
  readonly submitWithdraw: {
    readonly withdraw: {
      readonly amount: number;
      readonly currency: Currency;
      readonly isProcessed: boolean;
      readonly txid: ReadonlyArray<string> | null;
      readonly isAuthenticated: boolean;
      readonly address: string;
      readonly insertedAt: string;
      readonly uuid: string;
      readonly meta: any;
    } | null;
  } | null;
};
export type WithdrawViewSubmitWithdrawMutation = {
  variables: WithdrawViewSubmitWithdrawMutation$variables;
  response: WithdrawViewSubmitWithdrawMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitWithdrawPayload",
    "kind": "LinkedField",
    "name": "submitWithdraw",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Withdraw",
        "kind": "LinkedField",
        "name": "withdraw",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isProcessed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "txid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAuthenticated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insertedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WithdrawViewSubmitWithdrawMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WithdrawViewSubmitWithdrawMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "581a7f5c38f5be75b1a42d1eb6a17402",
    "id": null,
    "metadata": {},
    "name": "WithdrawViewSubmitWithdrawMutation",
    "operationKind": "mutation",
    "text": "mutation WithdrawViewSubmitWithdrawMutation(\n  $input: SubmitWithdrawInput!\n) {\n  submitWithdraw(input: $input) {\n    withdraw {\n      amount\n      currency\n      isProcessed\n      txid\n      isAuthenticated\n      address\n      insertedAt\n      uuid\n      meta\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c5a9003e17169e269a4ebbea17c4c47";

export default node;

import * as React from 'react'
import { GameState, Game, GameMove, GameCard, GameSlot } from './Blackjack'

import Konva from 'konva'
import { chipList } from './components/Chip'
import { animateTo, flip, fadeIn } from './components/KonvaAnimation'
import SoundManager, { Sound } from '../../SoundManager'
import { ButtonConfig, getSkewedRect } from './components/SkwedRect'
import { getCard, addScaleAnimation, scaleDown, scaleUp, getCardHeight, getCardWidth } from './components/Card'
import { getHandSum, getSlotValues } from './components/ChipHelper'
import { Subject, fromEvent, Subscription } from 'rxjs'
import { DiffResult, DiffResultEnum } from './GameDiff'
import { measureTextWidth, getElementBounds, isSmallScreen } from './Konva/Helper'
import { ChipStack } from './Konva/ChipStack'
import { debounceTime, throttleTime } from 'rxjs/operators'
import { Timer, ClockType } from './components/timer'
import ConfigurationService, { Currency } from '../../service/ConfigurationService'
import { store } from '../../store'
import { activeWalletUpdated, getBalance } from '../../reducers/wallet.reducer'
import { Table } from '../tables/TablesComponent'
import { joinTableTask } from './InHouseGame'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import { UserProp, userUpdated } from '../../reducers/authentication.reducer'
import { mobile, removeTheme, setTheme } from '../../App'
import { nextServerSeedHash } from '../provablyFairComponent/ProvablyFairComponent'
import { Money } from '@src/money'

enum Role {
  GAME,
  FULL_TV,
  THUMB_TV,
}

type layerType = 'game' | 'mp'
type action = 'button_click' | 'start_game'
const diffProcessOrder = [
  DiffResultEnum.buttonClick,
  DiffResultEnum.slotAdded,
  DiffResultEnum.cardMoved,
  DiffResultEnum.cardUpdated,
  DiffResultEnum.cardAdded,
  DiffResultEnum.stateChaged,
  DiffResultEnum.slotPointerChanged,
]

const nextCardID = 'perk_next_card'

class UserGameRenderer {
  public onEvent: Subject<{ action: action; parameter?: any; extra?: any }> = new Subject()

  public game: Game | undefined
  public table: Table | undefined
  public role: Role
  public myUUID: string
  private stage: Konva.Stage | undefined

  private chipStack: ChipStack | undefined
  private el: JSX.Element | undefined
  private fontLoaded = false
  private bet = 0
  private diffProcessed = true
  private subscriptions: Subscription[] = []
  private timeouts: Array<any> = []
  private blurMode = false
  private onCardSelect?: Subject<{ slot: number; cardIdx: number }>
  private errors: Array<string> = []
  private errorTimeout: any
  // For displaying TV moves
  private activeMove: string | undefined
  private clickHandlers: { target: Konva.Container; func: (e: any) => void }[] = []
  private ref: HTMLDivElement | undefined
  private timer: Timer
  private preMoves: { table: string; slot: number; move: GameMove; extra: any }[] = []
  private lastSlotGroup: Konva.Group | undefined
  private images: any = {}
  private pendingChipEvent: any
  private needConfirmedHit = false
  private justConfirmed = false

  constructor(role?: Role) {
    for (let i = 0; i < 5; i++) {
      const src = '/assets/sit_' + (i + 1) + '.png'
      const img = new Image()
      img.onload = () => {
        this.images[src] = img
      }
      img.src = src
    }

    this.role = role || Role.GAME
    this.subscriptions.push(
      fromEvent(window, 'resize')
        .pipe(debounceTime(100))
        .subscribe(() => {
          const container = document.querySelector('.game_canvas_container')
          if (!container) {
            return
          }
          const bounds = container.getBoundingClientRect()
          if (undefined === this.stage) {
            return
          }
          if (this.role === Role.THUMB_TV) {
            this.stage.width(this.getThumbStageWidth())
            this.stage.height(100)
            const gameLayer = this.getLayer('game')
            if (gameLayer) {
              const card = getCard({ flipped: 0, rank: '2', suit: 'spades' }, 0, 0)
              const scale = 1 / (card.height() / 80)
              gameLayer.scale({ x: scale, y: scale })
            }
          } else {
            try {
              this.stage.width(bounds.width)
              this.stage.height(bounds.height)
            } catch (e) {
              console.log(e)
            }
          }

          this.timeouts.forEach((x) => clearTimeout(x))
          this.timeouts = []
          this.setGame(this.game)
        })
    )
    this.myUUID = store.getState().authentication.user?.uuid as string
    this.subscriptions.push(
      activeWalletUpdated.pipe(debounceTime(500)).subscribe(() => {
        this.updateBalance()
      })
    )
    this.subscriptions.push(
      userUpdated.subscribe(() => {
        this.updateBalance()
      })
    )

    this.timer = new Timer(this)
  }

  deconstructor() {
    if (this.ref) {
      'click touchstart'.split(' ').forEach((x) => {
        this.ref?.removeEventListener(x, this.canvasClick)
      })
    }
    this.subscriptions.forEach((x) => x.unsubscribe())
    this.subscriptions = []
    this.timeouts.forEach((x) => clearTimeout(x))
    this.timeouts = []
    this.preMoves = []
    this.errors = []
    this.clickHandlers = []
    this.stage?.destroy()
  }

  getThumbStageWidth() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    if (w >= 1200) {
      return w * 0.25
    }
    if (w > 700) {
      return w * 0.35
    }
    return w
  }

  setRef = (ref: HTMLDivElement) => {
    if (!ref) {
      return
    }
    this.ref = ref
    'click touchstart'.split(' ').forEach((x) => {
      ref.addEventListener(x, this.canvasClick)
    })
    this.subscriptions.push(
      fromEvent(ref, 'mousemove')
        .pipe(throttleTime(40))
        .subscribe((e) => {
          this.canvasMove(e)
        })
    )

    this.errors = []

    const container = document.querySelector('.game_canvas_container')
    if (!container) {
      return
    }
    const bounds = container.getBoundingClientRect()
    if (this.role === Role.THUMB_TV) {
      this.stage = new Konva.Stage({
        container: ref,
        width: this.getThumbStageWidth(),
        height: 100,
      })
    } else {
      this.stage = new Konva.Stage({
        container: ref,
        width: bounds.width,
        height: bounds.height,
      })
    }

    // const chipsLayer = new Konva.Layer().listening(false);
    const gameLayer = new Konva.Layer().listening(false)
    const mpLayer = new Konva.Layer().listening(false)
    // const lll = new Konva.Layer().listening(false);

    gameLayer.id('game')
    // chipsLayer.id('chips');
    mpLayer.id('mp')

    this.stage.getStage().add(gameLayer).add(mpLayer)
    // .add(chipsLayer);
    // .add(lll);

    if (typeof FontFace === 'function') {
      const font1 = new FontFace('Fjalla One', 'url(/assets/FjallaOne-Regular.ttf)')
      const font2 = new FontFace('Rubik', 'url(/assets/Rubik-Regular.ttf)')

      Promise.all([font1.loaded, font2.loaded]).then(() => {
        document.fonts.add(font1)
        document.fonts.add(font2)
        this.fontLoaded = true
      })

      font1.load()
      font2.load()

      // Fallback if font loading is not working
      this._setTimeout(() => {
        this.fontLoaded = true
      }, 1000)
    } else {
      this.fontLoaded = true
    }

    if (this.role === Role.THUMB_TV) {
      const card = getCard({ flipped: 0, rank: '2', suit: 'spades' }, 0, 0)
      const scale = 1 / (card.height() / 80)
      gameLayer.scale({ x: scale, y: scale })
    }
    /*
    lll.add(new Konva.Line({
      points: [w / 2, 0, w / 2, h],
      stroke: '#fff',
      strokeWidth: 2
    }));
    lll.draw();
    */
  }

  canvasClick = (e: any) => {
    e.preventDefault()
    var rect = e.target.getBoundingClientRect()
    var ex = (e.clientX || e.touches[0].clientX) - rect.left
    var ey = (e.clientY || e.touches[0].clientY) - rect.top
    const handler = this.getElementAtPosition({ x: ex, y: ey })
    if (handler) {
      handler.o.func({ target: handler.o.target, currentTarget: handler.o.target })
    }
  }

  canvasMove = (e: any) => {
    e.preventDefault()
    try {
      this.clickHandlers
        .filter((x) => x.target.id() === 'canvas_card' && x.target.attrs.mouse_is_over)
        .forEach((x) => {
          x.target.fire('mouseout', {})
          x.target.attrs.mouse_is_over = false
        })

      var rect = e.target.getBoundingClientRect()
      var ex = (e.clientX || e.touches[0].clientX) - rect.left
      var ey = (e.clientY || e.touches[0].clientY) - rect.top
      const handler = this.getElementAtPosition({ x: ex, y: ey })
      if (handler) {
        const id = handler.o.target.id()
        if (id === 'button' || handler.o.target?.attrs?.pointer) {
          document.body.style.cursor = 'pointer'
          return
        }
        if (id === 'canvas_card') {
          if (!handler.o.target.attrs.mouse_is_over) {
            handler.o.target.attrs.mouse_is_over = true
            handler.o.target.fire('mouseover', {})
          }
        }
        if (id === 'slot_header') {
          document.body.style.cursor = 'pointer'
          return
        }
      }
      document.body.style.cursor = 'default'
      // tslint:disable-next-line:no-empty
    } catch (_err) {}
  }

  getElementAtPosition({ x, y }: { x: number; y: number }) {
    this.clickHandlers = this.clickHandlers.filter((xx) => xx.target.getLayer() !== null)
    const handlers = this.clickHandlers
      .map((handler) => {
        let height = handler.target.height()
        let width = handler.target.width()
        const absPos = handler.target.getAbsolutePosition()
        let elPosX = absPos.x
        let elPosY = absPos.y
        if (handler.target.id() === 'canvas_card') {
          elPosX -= width / 2
          elPosY -= height / 2
        }
        const scale = handler.target.scale()
        if (scale.x !== 1 && scale.y !== 1) {
          height = height * scale.y
          width = width * scale.x
          elPosX += width / 2
          elPosY += height / 2
        }

        return { elPosX, elPosY, height, width, o: handler }
      })
      .filter((el) => x >= el.elPosX && x <= el.elPosX + el.width && y >= el.elPosY && y <= el.elPosY + el.height)
    if (handlers.length > 0) {
      const handler = handlers.sort((a, b) =>
        a.o.target.zIndex() > b.o.target.zIndex() ? -1 : a.o.target.zIndex() === b.o.target.zIndex() ? 0 : 1
      )[0]

      return handler
    }
  }

  setTable(table: Table) {
    removeTheme()
    const e = document.querySelector('.app-frame')
    if (table.meta?.theme && e) {
      if (table.meta.theme.startsWith('theme-')) {
        e.classList.add(table.meta?.theme)
      } else {
        e.classList.add('theme-' + table.meta?.theme)
      }
    } else {
      setTheme()
    }
    this.table = table
    this.setGame(this.table?.game)
  }

  setGame(game: Game | undefined, noAnimations?: boolean) {
    if (!this.stage) {
      console.error('stage is missing')
    }
    if (game && false === game.mp && this.table) {
      this.table = undefined
    }
    this.errors = []
    if (!this.fontLoaded) {
      if (!this.stage) {
        return
      }
      this._setTimeout(() => {
        this.setGame(game)
      }, 50)
      return
    }
    // this.getLayer('chips').removeChildren().batchDraw();
    this.clickHandlers = []
    this.preMoves = []
    this.game = game
    if (this.game?.serverSeedHash) {
      nextServerSeedHash.next(this.game.serverSeedHash)
    }
    if (this.game === null && this.table !== undefined) {
      this.game = {
        currency: this.table.currency,
        virtualId: this.table.virtualId,
        state: 0,
        mp: true,
      } as any
      this.getLayer('game').removeChildren().batchDraw()
    }
    this.getLayer('game').removeChildren().batchDraw()
    this.chipStack = new ChipStack(this, this.getLayer('game'), this.getGameCurrency(), this.role)
    this.drawChipList()

    // tslint:disable-next-line:switch-default
    switch (this.getGameState(this.game)) {
      case GameState.CONFIGURE:
        this.drawMultiplayerSlots()
        this.bet = 0
        this.initStateConfigure()
        this.updateButtons()
        this.chipStack.init()
        if (this.role === Role.THUMB_TV) {
          this.chipStack.stackFromChips(this.bet, 'right')
        } else {
          this.chipStack.stackFromChips(this.bet, 'middle')
        }
        break
      case GameState.PLAY:
        this.drawMultiplayerSlots()
        this.initStatePlay(() => {
          this.updateButtons()
          this.updateHandSum()
        }, noAnimations)
        this.chipStack.init()
        if (this.role === Role.THUMB_TV) {
          this.chipStack.stackFromChips(this.bet, 'right')
        } else {
          this.chipStack.stackFromChips(this.bet, 'middle')
        }
        break
      case GameState.RESULTS:
        this.initStatePlay(() => {
          this.updateButtons()
          this.updateHandSum()
          this.addSlotResults()
          this.drawMultiplayerSlots()
        }, noAnimations)
        this.chipStack.init()
        if (this.role === Role.THUMB_TV) {
          this.chipStack.stackFromChips(this.bet, 'right')
        } else {
          this.chipStack.stackFromChips(this.bet, 'middle')
        }
        break
      default:
        if (this.game?.mp) {
          this.drawMultiplayerSlots()
        }
    }

    if (undefined !== this.pendingChipEvent) {
      this.chipClick(this.pendingChipEvent)
      this.pendingChipEvent = undefined
    }
  }

  showNextCard(card: GameCard) {
    if (!this.stage) {
      return
    }
    const layer = this.getLayer('game')
    const slot = this.getSlot(0)
    if (!slot) {
      return
    }
    const cardsInThisSlot = this.getSlotCards(0)
    const cardsSoFar = cardsInThisSlot.length
    const lastCardInSlot = cardsInThisSlot[cardsSoFar - 1]
    const canvasCard = getCard(card, 0, 0)
    const bounds = getElementBounds(canvasCard)
    const offsetX = lastCardInSlot.x() + lastCardInSlot.width()
    const text = new Konva.Text({
      x: 0 - canvasCard.offsetX() - canvasCard.width() / 2,
      y: 0 - canvasCard.offsetY() + canvasCard.height() / 2 + 25,
      width: canvasCard.width(),
      height: 37,
      align: 'center',
      // verticalAlign: 'middle',
      text: 'Next card',
      fill: '#fff',
      fontStyle: 'bold',
      fontFamily: 'Rubik',
      fontSize: 27,
    })

    const group = new Konva.Group({
      x: slot.x() + canvasCard.x() + offsetX * 2,
      y: this.stage.height() / 2 - this.getCardOffestYFromCenter(),
      id: nextCardID,
    })

    if (isSmallScreen(layer)) {
      group.x(group.x() * 0.8)
    }

    if (this.role === Role.THUMB_TV) {
      group.y(bounds.height / 2 + 15)
    }

    group.scaleX(0.7)
    group.scaleY(0.7)

    group.add(canvasCard)
    group.add(text)

    layer.add(group)
    layer.batchDraw()
  }

  // Perk swap
  blur(onCardSelect: Subject<{ slot: number; cardIdx: number }>) {
    this.blurMode = true
    this.onCardSelect = onCardSelect
    // this.getLayer('chips').cache().opacity(.3).batchDraw();
    const gameLayer = this.getLayer('game')
    gameLayer.find('#play_buttons').forEach((x) => x.hide())
    gameLayer.batchDraw()
  }

  unBlur() {
    this.blurMode = false
    this.onCardSelect = undefined
    // this.getLayer('chips').clearCache().opacity(1).batchDraw();
    const gameLayer = this.getLayer('game')
    gameLayer.find('#play_buttons').forEach((x) => x.show())
    gameLayer.find('#canvas_card').forEach((x) => {
      x.setAttr('lock', undefined)
      x.setAttr('scale_o', 1)
      scaleDown(x as any)
    })
    gameLayer.batchDraw()
  }

  isBetSet() {
    if (this.game?.mp && undefined !== this.game && undefined !== this.game.slots) {
      const myPlayerCount = this.table?.players.filter((x) => x.playerUuid === this.myUUID).length
      const myBettedSlots = this.game.slots.filter((x) => x.userUuid === this.myUUID && x.bet > 0).length
      return myPlayerCount === myBettedSlots
    }
    return false
  }

  initStateConfigure() {
    if (this.table && this.table.state === 'waiting_for_game') {
      this.timer.makeTimer(ClockType.waiting_game_clock)
    }
    if (this.table && this.table.state === 'waiting_for_bets') {
      this.timer.makeTimer(ClockType.user_bet_clock)
    }
  }

  drawChipList() {
    const chipWidth = ChipStack.getChipSize(this.getLayer('game'), this.role)
    chipList(chipWidth, this.getGameCurrency()).subscribe((group) => {
      const stage = this.stage?.getStage()
      if (!stage) {
        return
      }
      stage.find('#chip_list').forEach((x) => x.destroy())
      stage.find('#balance_group').forEach((x) => x.destroy())
      const stageWidth = stage.width() / 2
      const layer = this.getLayer('game')
      if (!layer) {
        return
      }
      layer.add(group)
      const shouldStack = stage.width() < 800
      const offset = stage.height() / 20
      const pos = {
        x: stageWidth - chipWidth * 2.5,
        y: stage.height() - (shouldStack ? offset : 0) - group.height() * 1.2,
      }
      if (this.role === Role.THUMB_TV) {
        pos.y = stage.height() / 2 - chipWidth / 2
      }
      group.position(pos)

      group.children?.forEach((x: any) => {
        if (x.getAttr('isChip') === true) {
          this.addClickHandler(x as any, this.chipClick)
        }
      })

      this.updateBalance()
    })
  }

  updateBalance = () => {
    if (this.role !== Role.GAME) {
      return
    }
    if (undefined === this.game) {
      return
    }
    const stage = this.stage?.getStage()
    if (!stage) {
      return
    }
    const layer = this.getLayer('game')
    if (!layer) {
      return
    }
    let chips = stage.find('#chip_list')
    if (chips?.length !== 1) {
      return
    }

    const currency = store.getState().wallet.activeWallet?.currency
    if (this.chipStack?.currency.short !== currency) {
      this.chipStack?.setCurrency(this.getGameCurrency())
    }
    const shouldStack = stage.width() < 800
    const group = chips[0]
    stage
      .getStage()
      .find('#balance_group')
      .forEach((x) => x.destroy())
    const balanceGroup = new Konva.Group({ id: 'balance_group' })
    const c = this.getGameCurrency()
    const balance = getBalance(c.short, this.game ? this.game.virtualId : 0)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const m = Money.convertUnit(balance, c.m_unit, c.s_unit, format)
    const m2 = Money.convertUnit(this.bet, c.m_unit, c.s_unit, format)
    const cFormat = c.short === 'FUN' ? 'FUN' : format
    const mobile = stage.width() < 800
    let fontSize = mobile ? 20 : 40
    let textWidth = 0
    // Shrink font until it fits
    for (let i = fontSize; i > 0; i--) {
      fontSize = i
      textWidth = measureTextWidth(m + cFormat, 'bold ' + fontSize + 'px Fjalla One') + fontSize * 2
      if (mobile || group.x() - textWidth > 0) {
        break
      }
    }

    const konvaBalance = new Konva.Text({
      x: group.x() - textWidth,
      y: group.y() + (group.height() / 2 - fontSize / 2),
      width: textWidth,
      text: m + cFormat,
      fontSize: fontSize,
      fontFamily: 'Fjalla One',
      fontStyle: 'bold italic',
      fill: 'white',
      align: 'center',
    })

    const konvaBalanceLabel = new Konva.Text({
      x: group.x() - textWidth,
      y: konvaBalance.y() - fontSize / 2,
      width: textWidth - (fontSize - 6),
      text: 'BALANCE',
      fontSize: fontSize / 3,
      fontFamily: 'Fjalla One',
      fontStyle: 'italic',
      fill: 'white',
      align: 'right',
    })

    const konvaBet = new Konva.Text({
      x: group.x() + group.width(),
      y: konvaBalance.y(),
      fontSize: fontSize,
      text: m2 + cFormat,
      fontFamily: 'Fjalla One',
      fontStyle: 'bold italic',
      fill: 'white',
    })

    const konvaBetLabel = new Konva.Text({
      x: group.x() + group.width() + fontSize / 4,
      y: konvaBet.y() - fontSize / 2,
      text: 'YOUR BET TOTAL',
      fontSize: fontSize / 3,
      fontFamily: 'Fjalla One',
      fontStyle: 'italic',
      fill: 'white',
    })

    if (shouldStack) {
      const chipSize = ChipStack.getChipSize(this.getLayer('game'), this.role)
      const chipsAtTop = stage.height() - stage.height() / 20 - balanceGroup.height()
      const offset = stage.height() - chipsAtTop + chipSize / 2 - fontSize / 4

      konvaBalance.x(konvaBalance.x() + group.width() / 2)
      konvaBalance.y(konvaBalance.y() + offset)

      konvaBalanceLabel.x(konvaBalanceLabel.x() + group.width() / 2)
      konvaBalanceLabel.y(konvaBalanceLabel.y() + offset)

      konvaBet.x(konvaBet.x() - group.width() / 2)
      konvaBet.y(konvaBet.y() + offset)

      konvaBetLabel.x(konvaBetLabel.x() - group.width() / 2)
      konvaBetLabel.y(konvaBetLabel.y() + offset)
    }

    balanceGroup.add(konvaBalance)
    balanceGroup.add(konvaBet)
    balanceGroup.add(konvaBalanceLabel)
    balanceGroup.add(konvaBetLabel)
    layer.add(balanceGroup)
    layer.batchDraw()
  }

  drawMultiplayerSlots() {
    if (!this.table) {
      return
    }
    if (!this.stage) {
      return
    }
    console.log('draw multi slots')
    const buttonConfig = this.getDefaultButtonConfig() as ButtonConfig
    const stageWidth = this.stage.width()
    const stageHeight = this.stage.height()
    const mobile = stageWidth < 800
    const isMobileLandscape = Math.min(stageWidth, stageHeight) < 800 && stageWidth > stageHeight
    let playerCount = this.table.maxPlayers
    if (mobile || isMobileLandscape) {
      const mySlots = this.table.players.filter((x) => x.playerUuid === this.myUUID).length
      playerCount = this.table.maxPlayers - mySlots
    }

    const dimension = Math.min(stageWidth, stageHeight)
    buttonConfig.fill = '#0b2c5a'
    buttonConfig.textColor = '#fff'
    buttonConfig.width = Math.min(mobile ? dimension / (playerCount + 1) : 150, dimension / 5)
    buttonConfig.height = buttonConfig.width / 2
    buttonConfig.skew = 0
    buttonConfig.lineHeight = mobile ? 1.3 : 2
    buttonConfig.cornerRadius = mobile ? 4 : 7
    const layer = this.getLayer('mp')
    layer.removeChildren()
    const slotGroup = new Konva.Group({ id: 'slots' })
    slotGroup.attrs.positions = {}
    slotGroup.height(buttonConfig.height)

    const totalWidth = playerCount * buttonConfig.width * 2

    // Total width - logo width - top right buttons width
    const logoRect = document.querySelector('.logo-container')?.getBoundingClientRect()
    const rightTopButtonsRect = document.querySelector('.darkMode-button')?.getBoundingClientRect()
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (!mobile && undefined !== rightTopButtonsRect && undefined !== logoRect) {
      vw -= vw - rightTopButtonsRect?.left
      vw -= logoRect.width
      vw -= 45
    }
    if (buttonConfig.width * 5 > vw) {
      buttonConfig.width = vw / 6
    }
    let cap = buttonConfig.width * 2
    if (totalWidth > vw) {
      cap = (vw - buttonConfig.width * playerCount) / playerCount + buttonConfig.width
    }
    const slotsUp = true
    let renderedSlotCount = 0
    for (let i = 0; i < this.table.maxPlayers; i++) {
      const player = this.table.players.find((x) => x.slotIdx === i)
      const offsetX = renderedSlotCount * cap

      const xPos = offsetX
      if ((mobile || isMobileLandscape) && player?.playerUuid === this.myUUID) {
        const key = player.playerUuid + '_' + i
        slotGroup.attrs.positions[key] = [vw / 2 - buttonConfig.width / 2, 0]
        continue
      }
      renderedSlotCount++
      let btn
      if (undefined !== player) {
        let isThisActiveSlot = false
        if (undefined !== this.game && undefined !== this.game.slots) {
          const activeGameSlot = this.game.slots[this.game.sp]
          isThisActiveSlot = activeGameSlot.userUuid === player.playerUuid && activeGameSlot.userIdx === i
          if (!isThisActiveSlot && this.table.state === 'waiting_for_bets') {
            if (this.game.slots.findIndex((x) => x.userUuid === this.myUUID && x.userIdx === i && x.bet > 0) < 0) {
              isThisActiveSlot = player.playerUuid === this.myUUID && !player.kicked
            }
          }
        }
        let btnConfig = isThisActiveSlot ? Object.assign({ stroke: '#FF0' }, buttonConfig) : buttonConfig
        if (player.kicked) {
          btnConfig = JSON.parse(JSON.stringify(btnConfig))
          btnConfig.fill = '#747575'
        }
        const c = this.getGameCurrency()
        const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
        const cFormat = c.short === 'FUN' ? 'FUN' : format

        let buttonText =
          player.playerName && player.playerName.length > 0 ? player.playerName : player.playerUuid.substring(0, 8)
        if (null !== player.playerBalance) {
          const b = Money.convertUnit(player.playerBalance as number, c.m_unit, c.s_unit, format)
          buttonText += '\n' + b + cFormat
        }
        btn = getSkewedRect(Object.assign({ text: buttonText }, btnConfig))
        btn.id('player_' + player.playerUuid)
        btn.attrs.pointer = true
        btn.attrs.idx = i
        if (player.kicked) {
          btn.attrs.gameMove = GameMove.SIT
          btn.attrs.params = { slotIdx: i, table: this.table.uuid }
          this.addClickHandler(btn as any, this.buttonClick)
        }
        const key = player.playerUuid + '_' + i
        slotGroup.attrs.positions[key] = [xPos, 0]
      } else {
        const userHasSlot = this.table.players.findIndex((x) => x.playerUuid === this.myUUID) >= 0
        if (!(mobile || isMobileLandscape) || !userHasSlot) {
          btn = this.makeButton(GameMove.SIT, buttonConfig, 'SIT', { slotIdx: i, table: this.table.uuid })
        } else {
          renderedSlotCount--
        }
      }
      if (undefined !== btn) {
        const rect = (btn as Konva.Group).findOne('#rect') as Konva.Rect
        const text = (btn as Konva.Group).findOne('#rect_text') as Konva.Text
        const src = '/assets/sit_' + (i + 1) + '.png'
        if (src in this.images) {
          rect.fillPatternImage(this.images[src])
          rect.fill('')
          rect.moveToBottom()
        } else {
          const img = new Image()
          img.onload = () => {
            try {
              rect.fillPatternImage(img)
              rect.fill('')
              rect.moveToBottom()
              rect.draw()
              text.draw()
            } catch (_e) {}
          }
          img.src = src
        }

        btn.x(xPos)
        slotGroup.add(btn)
      }
    }

    const bounds = getElementBounds(slotGroup)
    if (slotsUp) {
      slotGroup.y(mobile ? (logoRect?.height || 0) + 15 : 40)
    } else {
      slotGroup.y(this.stage.getStage().height() - bounds.height - this.getButtonsOffsetFromBottom() - 50)
    }

    slotGroup.x(this.stage.getStage().width() / 2 - bounds.width / 2)
    Object.keys(slotGroup.attrs.positions).forEach((x) => {
      if (mobile && x.startsWith(this.myUUID)) {
        return
      }
      slotGroup.attrs.positions[x][0] += slotGroup.x()
    })
    this.lastSlotGroup = slotGroup
    layer.add(slotGroup)
    layer.batchDraw()

    this.chipStack?.setMultiplayerPosistions(slotGroup.attrs.positions)

    if (undefined !== this.table) {
      if (this.table.state === 'waiting_for_bets') {
        this.timer.makeTimer(ClockType.user_bet_clock)
      }
      if (this.table.state === 'playing') {
        this.timer.makeTimer(ClockType.user_move_clock)
      }
      if (this.table.state === 'waiting_for_game') {
        this.timer.makeTimer(ClockType.waiting_game_clock)
      }
    }
  }

  initStatePlay(doneFn?: () => void, noAnimate?: boolean) {
    if (undefined === this.game) {
      if (doneFn) {
        doneFn()
      }
      return
    }
    this.bet = this.getInitialBet(this.game)
    const slots = JSON.parse(JSON.stringify(this.game.slots))
    const orderedSlots = slots.reverse() as GameSlot[]
    const dealerSlot = orderedSlots.pop()
    if (!dealerSlot) {
      return
    }
    orderedSlots.unshift(dealerSlot)
    const maxCardCount = Math.max(...slots.map((x: any) => x.cards.length))
    this.createPlayerSlots()
    let insertRequests = 0
    let insertedCards = 0
    const gameLayer = this.getLayer('game')
    const wasAtIdx = gameLayer.zIndex()
    // gameLayer.zIndex(this.stage?.children?.length || 0 - 1);
    for (let i = 0; i < maxCardCount; i++) {
      for (let j = orderedSlots.length - 1; j >= 0; j--) {
        const slot = orderedSlots[j]
        if (slot.cards.length > i) {
          // eslint-disable-next-line no-loop-func
          this._setTimeout(
            () => {
              this.insertCard(
                orderedSlots[j].cards[i],
                slot.slotId,
                i,
                () => {
                  insertedCards++
                  if (insertedCards === insertRequests && doneFn) {
                    // Last card was added to scene
                    gameLayer.zIndex(wasAtIdx)
                    doneFn()
                  }
                },
                noAnimate
              )
            },
            noAnimate ? 0 : insertRequests * 170
          )
          insertRequests++
        }
      }
    }
  }

  updateHandSum() {
    let scaleFactor = 1
    if (isSmallScreen(this.getLayer('game'))) {
      scaleFactor = 0.5
    }
    if (undefined === this.game?.slots) {
      return
    }
    for (let i = 0; i < this.game.slots.length; i++) {
      const gameSlot = this.game.slots[i]
      const slot = this.getSlot(gameSlot.slotId)
      if (!slot) {
        continue
      }
      const last = slot.findOne('#sum')
      if (last) {
        last.destroy()
      }

      const cards = this.getSlotCards(gameSlot.slotId)
      if (this.game.mp) {
        // Check if slot have any upward cards
        const hasNotFlippedCard = cards.findIndex((x: Konva.Group) => x.attrs.flipped === 0) >= 0
        if (!hasNotFlippedCard) {
          continue
        }
      }
      let foundVisibleCard = false
      slot.find('#canvas_card').forEach((x) => {
        if (!foundVisibleCard) {
          foundVisibleCard = x.isVisible()
        }
      })
      if (gameSlot.slotId > 0 && !foundVisibleCard) {
        continue
      }

      const lastCard = cards[cards.length - 1]
      let secondCard = lastCard
      if (cards.length > 2) {
        secondCard = cards[1]
      }
      if (lastCard) {
        let scaleF = Math.min(scaleFactor, lastCard.scaleX())
        const handSum = getHandSum(this.game.slots[i])
        const textWidth = measureTextWidth(handSum) + 10
        if (this.game?.mp && gameSlot.userUuid !== this.myUUID) {
          scaleF = 0.5
        }

        // On multiplayer games show hand sum on top of second card
        let sum
        if (this.game.mp && gameSlot.slotId > 0) {
          sum = new Konva.Group({
            x: secondCard.x() + (lastCard.width() * lastCard.scaleX()) / 2 - 18 * 3 * scaleF,
            y: lastCard.y() - (lastCard.height() * lastCard.scaleY()) / 2 - 18 * lastCard.scaleY(),
            id: 'sum',
          })
        } else {
          sum = new Konva.Group({
            x: lastCard.x() + (lastCard.width() * lastCard.scaleX()) / 2 - 18 * scaleF,
            y: lastCard.y() - (lastCard.height() * lastCard.scaleY()) / 2 + 18 * lastCard.scaleY(),
            id: 'sum',
          })
        }

        const rect = new Konva.Rect({
          width: Math.max(35, textWidth),
          height: 37,
          fill: '#134031',
          stroke: '#fff',
          strokeWidth: 1,
        })
        const text = new Konva.Text({
          x: 0,
          y: 0,
          width: Math.max(35, textWidth),
          height: 37,
          align: 'center',
          verticalAlign: 'middle',
          text: handSum,
          fill: '#fff',
          fontStyle: 'bold',
          fontFamily: 'Rubik',
          fontSize: 17,
        })
        sum.add(rect)
        sum.add(text)
        sum.scale({ x: scaleF, y: scaleF })
        slot.add(sum)
        if (!last) {
          fadeIn(sum as any)
        }
        // slot.draw();
      }
    }
  }

  addClickHandler = (to: Konva.Container, func: (e: any) => void) => {
    if (this.role === Role.GAME) {
      to.on('click', func)
      to.on('tap', func)
    }

    this.clickHandlers.push({ target: to, func })
  }

  cardClick = (e: any) => {
    if (this.blurMode) {
      const gameLayer = this.getLayer('game')
      gameLayer.find('#canvas_card').forEach((x) => {
        x.setAttr('lock', undefined)
        scaleDown(x as any)
        x.opacity(0.3)
      })
      const card: Konva.Container = e.currentTarget
      scaleUp(card as any)
      card.setAttr('lock', true)

      gameLayer.batchDraw()
      const slot = e.currentTarget.attrs.slot
      const cardIdx = e.currentTarget.attrs.cardIdx
      this.onCardSelect?.next({ slot, cardIdx })
    }
  }

  isPremove(move: GameMove) {
    return (
      move === GameMove.PRE_DOUBLE_DOWN ||
      move === GameMove.PRE_HIT ||
      move === GameMove.PRE_INSURANCE ||
      move === GameMove.PRE_SPLIT ||
      move === GameMove.PRE_STAND
    )
  }

  buttonClick = (e: any) => {
    if (!this.diffProcessed) {
      console.log('diff is not processed')
      return
    }
    this.needConfirmedHit = false
    const gameMove = e.currentTarget.attrs.gameMove
    const params = e.currentTarget.attrs.params

    if (params?.dummy === true) {
      this.justConfirmed = true
      this.updateButtons()
      return
    }
    this.justConfirmed = false
    /*
    For debugging slot pointer change event
    if (gameMove === 'left' || gameMove === 'right') {
      if (gameMove === 'right') {
        this.game.sp += -1;
        this.processDiff(this.game, [{result: DiffResultEnum.slotPointerChanged, param: {from: this.game.sp + 1, to: this.game.sp}}])
      }

      if (gameMove === 'left') {
        this.game.sp += 1;
        this.processDiff(this.game, [{result: DiffResultEnum.slotPointerChanged, param: {from: this.game.sp - 1, to: this.game.sp}}])
      }
      return;
    }
    */
    if (gameMove) {
      SoundManager.playSound(Sound.BUTTON_CLICK)
      if (this.isPremove(gameMove)) {
        if (this.table && this.table.uuid) {
          this.preMoves = this.preMoves.filter((x) => x.table === this.table?.uuid && x.slot !== params.slot_id)
          this.preMoves.push({
            move: (GameMove as any)[
              (GameMove as any)[gameMove.toUpperCase()].replace('pre_', '').toUpperCase() as any
            ] as any,
            extra: params,
            slot: params.slot_id,
            table: this.table.uuid,
          })
        }
        this.updateButtons()
      } else {
        if (gameMove === GameMove.REBET_X1 || gameMove === GameMove.REBET_X2) {
          if (gameMove === GameMove.REBET_X2) {
            this.bet = this.bet * 2
          }
          if (this.chipStack?.getBet() !== this.bet) {
            this.chipStack?.clear(false)
          }
          this.updateBalance()
          if (this.chipStack?.getBet() !== this.bet) {
            this.chipStack?.stackFromChips(this.bet, 'middle')
          }
          this.onEvent.next({ action: 'button_click', parameter: gameMove, extra: { bet: this.bet } })
        } else if (gameMove === GameMove.DEAL) {
          const c = this.getGameCurrency()
          const limits =
            undefined === this.table
              ? ConfigurationService.instance.getBetLimits(c)
              : ConfigurationService.instance.getBetLimitsMp(c)

          let max = limits.max
          if (this.bet >= limits.min && this.bet <= max) {
            if (undefined !== this.table) {
              this.onEvent.next({ action: 'start_game', parameter: { table_uuid: this.table.uuid, bet: this.bet } })
              this.timer.destoryTimerByUser(this.myUUID)
              this.bet = 0
            } else {
              this.onEvent.next({ action: 'start_game', parameter: { game_id: this.game?.gameId, bet: this.bet } })
            }
          } else {
            this.errors = ['Bet is outside of bet limits!']
            this.renderErrors()
          }
        } else if (gameMove === GameMove.CLEAR) {
          this.errors = []
          if (this.game?.mp) {
            this.chipStack?.removeChips(this.bet)
          } else {
            this.chipStack?.clear(true)
          }
          this.bet = 0
          this.updateBalance()
          this.updateButtons()
          this.renderErrors()
          this.stage?.batchDraw()
        } else if (gameMove === GameMove.INSURANCE) {
          this.onEvent.next({ action: 'button_click', parameter: gameMove, extra: params })
        } else {
          this.onEvent.next({ action: 'button_click', parameter: gameMove, extra: params })
        }
      }
    }
  }

  makeButton(move: GameMove, buttonConfig: any, label?: string, params?: any) {
    const defaultConf = Object.assign({}, buttonConfig)
    const conf = Object.assign(defaultConf, { text: label || move.toUpperCase() })
    const textWidth = measureTextWidth(conf.text, conf.fontSize + 'px italic')
    if (conf.width - 20 < textWidth) {
      conf.width = textWidth + 20
    }
    const btn = getSkewedRect(conf)
    btn.attrs.gameMove = move
    btn.attrs.params = params
    this.addClickHandler(btn as any, this.buttonClick)
    btn.id('button')
    return btn
  }

  updateButtons() {
    if (this.role === Role.THUMB_TV) {
      return
    }
    const layer = this.getLayer('game')
    if (!layer) {
      return
    }
    layer.find('#play_buttons').forEach((x) => x.destroy())
    layer.batchDraw()
    // let slotOverride = -1;
    const buttons: Konva.Group[] = []
    const smallScreen = isSmallScreen(this.getLayer('game'))
    const buttonConfig = this.getDefaultButtonConfig()
    const makeTallButton = (move: GameMove, label?: string, params?: any) => {
      const defaultConf = Object.assign({}, buttonConfig)
      const conf = Object.assign(defaultConf, { text: label || move.toUpperCase() })
      conf.height = smallScreen ? 40 : 50
      conf.fontSize = smallScreen ? 14 : 18
      const width = measureTextWidth(conf.text, 'bold ' + conf.fontSize + 'px italic')
      conf.fontStyle = 'italic bold'
      conf.width = width + (smallScreen ? 10 : 20)
      const btn = getSkewedRect(conf)
      btn.attrs.gameMove = move
      btn.attrs.params = params
      this.addClickHandler(btn as any, this.buttonClick)
      btn.id('button')
      return btn
    }
    if (this.role !== Role.FULL_TV) {
      const mp = undefined !== this.table
      const isBetSet = this.isBetSet()
      const state = this.getGameState(this.game)
      if (state === GameState.CONFIGURE && this.bet > 0 && !(mp && isBetSet)) {
        buttons.push(this.makeButton(GameMove.CLEAR, buttonConfig))
        buttons.push(makeTallButton(GameMove.DEAL))
      } else if (state === GameState.PLAY) {
        if (mp && this.game?.slots && this.game.slots[this.game.sp].userUuid !== this.myUUID) {
          // Waiting button?
          const firstMySlot = this.game.slots.find((x) => x.userUuid === this.myUUID)
          const firstMySlotIdx = this.game.slots.indexOf(firstMySlot as GameSlot)
          const premoveIdx = this.preMoves.findIndex((x) => x.table === this.table?.uuid && x.slot === firstMySlotIdx)
          if (undefined !== firstMySlot && this.game.sp <= firstMySlotIdx && premoveIdx < 0) {
            // slotOverride = firstMySlotIdx;
            if (this.game.validMoves.canInsurance) {
              buttons.unshift(
                makeTallButton(GameMove.PRE_INSURANCE, 'Insurance', { insure: true, slot_id: firstMySlot.slotId })
              )
              const b = this.makeButton(GameMove.PRE_INSURANCE, buttonConfig, "Don't insure", {
                insure: false,
                slot_id: firstMySlot.slotId,
              })
              buttons.unshift(b)
            } else {
              buttons.push(makeTallButton(GameMove.PRE_STAND, 'STAND', { slot_id: firstMySlot.slotId }))
            }

            if (getSlotValues(firstMySlot).some((x) => x < 21)) {
              buttons.push(this.makeButton(GameMove.PRE_HIT, buttonConfig, 'HIT', { slot_id: firstMySlot.slotId }))
            }
            if (firstMySlot.cards.length === 2) {
              const cards = firstMySlot.cards
              const firstCard = cards[0].rank
                .replace('jack', '10')
                .replace('queen', '10')
                .replace('king', '10')
                .replace('ace', '11')
              const secondCard = cards[1].rank
                .replace('jack', '10')
                .replace('queen', '10')
                .replace('king', '10')
                .replace('ace', '11')
              let canSplit = false
              if (firstCard === secondCard) {
                canSplit = true
              } else {
                canSplit =
                  firstCard.replace('jack', '10').replace('queen', '10').replace('king', '10') ===
                  secondCard.replace('jack', '10').replace('queen', '10').replace('king', '10')
              }

              if (canSplit) {
                buttons.unshift(
                  this.makeButton(GameMove.PRE_SPLIT, buttonConfig, 'SPLIT', { slot_id: firstMySlot.slotId })
                )
              }

              // const sum = +firstCard + +secondCard;
              // Can split on any two cards
              // if (sum >= 9 && sum <= 11) {
              if (true) {
                buttons.unshift(
                  this.makeButton(GameMove.PRE_DOUBLE_DOWN, buttonConfig, 'DOUBLE DOWN', {
                    slot_id: firstMySlot.slotId,
                  })
                )
              }
            }
          }
        } else {
          if (this.game?.validMoves.canInsurance) {
            const balance = getBalance(this.game.currency, this.game.virtualId)
            if (!this.game.mp && this.game.slots[this.game.sp].bet / 2 < balance) {
              buttons.unshift(makeTallButton(GameMove.INSURANCE, 'Insurance', { insure: true }))
            } else {
              this.onEvent.next({ action: 'button_click', parameter: GameMove.INSURANCE, extra: { insure: false } })
            }
            buttons.unshift(this.makeButton(GameMove.INSURANCE, buttonConfig, "Don't insure", { insure: false }))
          } else {
            buttons.push(makeTallButton(GameMove.STAND))
          }

          if (this.game?.validMoves.canHit) {
            const slot = this.game.slots[this.game.sp]
            const values = getSlotValues(slot)
            const lastValue = values[values.length - 1]

            if (lastValue >= 18 && lastValue < 21 && !this.justConfirmed) {
              this.needConfirmedHit = true
              buttons.push(
                this.makeButton(GameMove.HIT, Object.assign(buttonConfig, { fill: '#F8960B' }), undefined, {
                  dummy: true,
                })
              )
            } else {
              buttons.push(this.makeButton(GameMove.HIT, buttonConfig))
            }
          }

          if (this.game?.validMoves.canSplit) {
            const balance = store.getState().wallet.activeWallet?.balance as number
            const slotBet = this.game.slots[this.game.sp].bet
            if (balance >= slotBet) {
              buttons.unshift(this.makeButton(GameMove.SPLIT, buttonConfig))
            }
          }
          if (this.game?.validMoves.canDoubleDown) {
            buttons.unshift(this.makeButton(GameMove.DOUBLE_DOWN, buttonConfig, 'DOUBLE DOWN'))
          }
        }
      } else if (state === GameState.RESULTS && undefined === this.table) {
        const c = this.getGameCurrency()
        const limits = ConfigurationService.instance.getBetLimits(c)
        const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
        const b2 = Money.convertUnit(this.bet * 2, c.m_unit, c.s_unit, format)
        const b1 = Money.convertUnit(this.bet, c.m_unit, c.s_unit, format)
        const cFormat = c.short === 'FUN' ? 'FUN' : format

        const balance = getBalance(this.getGameCurrency().short, this.game?.virtualId || 0)
        if (balance >= this.bet * 2 && this.bet * 2 <= limits.max) {
          buttons.push(this.makeButton(GameMove.REBET_X2, buttonConfig, 'REBET X2 ' + b2 + cFormat))
        }
        buttons.push(makeTallButton(GameMove.CHANGE_BET))
        if (balance >= this.bet && this.bet <= limits.max) {
          buttons.push(this.makeButton(GameMove.REBET_X1, buttonConfig, 'REBET ' + b1 + cFormat))
        }
      } else {
        return
      }
    } else if (this.role === Role.FULL_TV && this.activeMove) {
      buttons.unshift(this.makeButton(GameMove.CLEAR, buttonConfig, this.activeMove.toUpperCase()))
    }

    const buttonGroup = new Konva.Group({ id: 'play_buttons' })
    for (let i = 0; i < buttons.length; i++) {
      const btn = buttons[i]
      const lastButton = buttons[i - 1]
      if (lastButton) {
        btn.x(lastButton.x() + +lastButton.width() + (smallScreen ? 5 : 10))
      }

      buttonGroup.add(btn)
    }
    const bounds = getElementBounds(buttonGroup)
    if (!this.stage) {
      return
    }
    buttonGroup.y(this.stage.getStage().height() - bounds.height - this.getButtonsOffsetFromBottom())
    const defaultX = this.stage.getStage().width() / 2 - bounds.width / 2 + 4
    buttonGroup.x(defaultX)
    layer.add(buttonGroup)
    layer.batchDraw()
  }

  createSlotGroup = (slot: GameSlot) => {
    if (!this.stage || !this.game) {
      return
    }
    const g = new Konva.Group({
      y: 0,
      id: 'slot_' + slot.slotId,
      playerUuid: slot.userUuid,
    })
    g.name('slotGroup')
    g.attrs.parent_id = slot.parentId
    let y = this.stage.height() / 2 + this.getCardOffestYFromCenter() / 3 + 3
    if (mobile) {
      const moveSlotsUp = this.stage.height() < 700
      y = this.stage.height() / 2 + this.getCardOffestYFromCenter() / 3 - (moveSlotsUp ? this.stage.height() / 10 : 0)
      // y = this.stage.height() / 2 - this.getCardOffestYFromCenter() / 5;
    }

    if (slot.slotId > 0) {
      if (this.game?.mp && slot.userUuid !== this.myUUID) {
        return g
      }
      const root = this.getRoot(slot)
      const childs = this.getChilds(root)
      const indexOfThisSlot = childs
        .map((x) => x.slotId)
        .sort()
        .findIndex((x) => x === slot.slotId)
      const activeSlot = this.game.slots[this.game.sp]?.slotId === slot.slotId
      const topLabel = new Konva.Group({
        x: (indexOfThisSlot - 1) * 35,
        y: y,
        id: 'slot_header_group',
        visible:
          null !== slot.parentId ||
          undefined !== this.game.slots.find((x) => x.parentId !== null && x.parentId === slot.slotId),
      })
      const rect = new Konva.Rect({
        width: 35,
        height: 37,
        fill: activeSlot ? '#FFFF00' : '#134031',
        stroke: '#fff',
        strokeWidth: 1,
        id: 'slot_header',
      })
      const text = new Konva.Text({
        x: 0,
        y: 0,
        width: 35,
        height: 37,
        align: 'center',
        verticalAlign: 'middle',
        text: (indexOfThisSlot + 1).toString(),
        fill: activeSlot ? '#000' : '#fff',
        fontStyle: 'bold',
        fontFamily: 'Rubik',
        fontSize: activeSlot ? 17 : 10,
        id: 'slot_header_text',
      })
      if (mobile) {
        rect.width(rect.width() / 2)
        rect.height(rect.height() / 2)

        text.width(text.width() / 2)
        text.height(text.height() / 2)
        text.fontSize(text.fontSize() / 2)
        topLabel.x(((indexOfThisSlot - 1) * 35) / 2)
      }
      topLabel.add(rect)
      topLabel.add(text)
      this.addClickHandler(rect as any, () => {
        this.activateSlot(slot)
      })
      g.add(topLabel)
    }
    return g
  }

  activateSlot(slot: GameSlot) {
    if (slot === undefined || undefined === this.game?.slots) {
      console.error('Activating undefined slot')
      console.trace()
      return
    }
    const root = this.getRoot(slot)
    const childs = this.getChilds(root)
    const activeGameSlot = this.game.slots[this.game.sp]

    // Hide everything
    childs.forEach((c) => {
      const s = this.getSlot(c.slotId)
      if (undefined === s) {
        return
      }
      const activeSlot = null !== c.parentId && activeGameSlot.slotId === c.slotId
      let fontSize = activeSlot ? 17 : 10
      if (mobile) {
        fontSize = fontSize / 2
      }
      s.find('#canvas_card').forEach((x) => x.hide())
      ;(s.findOne('#slot_header') as Konva.Rect)?.fill('#134031')
      ;(s.findOne('#slot_header_text') as Konva.Text)?.fill('#fff').fontSize(fontSize)
      if (childs.length < 2) {
        // Don't show label when there is only one slot
        s.findOne('#slot_header_group')?.hide()
      } else {
        ;(s.findOne('#slot_header_group') as Konva.Group)?.show()
      }
    })
    const g = this.getSlot(slot.slotId)
    if (undefined !== g) {
      let fontSize = 17
      if (mobile) {
        fontSize = fontSize / 2
      }
      ;(g.findOne('#slot_header') as Konva.Rect)?.fill('#FFFF00')
      ;(g.findOne('#slot_header_text') as Konva.Text)?.fill('#000').fontSize(fontSize)

      const cards = g.find('#canvas_card')
      cards.forEach((x) => x.show())
    }
    //  Hide buttons if we are not in active slot
    const isThisActiveSlot = activeGameSlot.slotId === slot.slotId
    const layer = this.getLayer('game')
    if (undefined === layer) {
      return
    }
    const allButtons = layer.find('#play_buttons')
    if (isThisActiveSlot) {
      allButtons.forEach((x) => x.show())
    } else if (slot.userIdx === activeGameSlot.userIdx) {
      allButtons.forEach((x) => x.hide())
    }

    this.updateHandSum()
    this.preMoves
      .filter((x) => x.table === this.table?.uuid && x.slot === slot.slotId)
      .forEach((x) => {
        delete x.extra.slot_id
        this.onEvent.next({ action: 'button_click', parameter: x.move, extra: x.extra })
      })
    this.preMoves = this.preMoves.filter((x) => !(x.table === this.table?.uuid && x.slot === slot.slotId))
    this.stage?.batchDraw()
  }

  getChilds(slot: GameSlot) {
    if (undefined === slot || undefined === this.game?.slots) {
      return []
    }
    let ret = [slot]
    this.game.slots
      .filter((x) => x.parentId === slot.slotId)
      .forEach((x) => {
        ret = ret.concat(this.getChilds(x))
      })

    return ret
  }

  getRoot(slot: GameSlot): any {
    if (slot.parentId === undefined || slot.parentId === null) {
      return slot
    }
    return this.getRoot(this.game?.slots.find((x) => x.slotId === slot.parentId) as GameSlot)
  }

  createPlayerSlots() {
    if (!this.game?.slots) {
      return
    }
    if (!this.stage) {
      return
    }

    const layer = this.getLayer('game')
    layer.find('.slotGroup').forEach((x) => x.destroy())
    const centerX = this.stage.getStage().width() / 2
    let pos: any = undefined
    if (this.game.mp) {
      pos = this.getLayer('mp').findOne('#slots')?.attrs.positions
    }
    const stageHeight = this.stage.height()
    const stageWidth = this.stage.width()
    const mobile = Math.min(stageWidth, stageHeight) < 800
    const isLandscape = mobile && stageWidth > stageHeight
    const cardWidth = getCardHeight()
    const cardOffset = this.getCardOffestYFromCenter()
    const slotWidth = (this as any).lastSlotGroup?.children[0]?.width() as number

    this.game.slots.forEach((slot) => {
      const g = this.createSlotGroup(slot)
      if (!g) {
        return
      }
      g.x(this.role === Role.THUMB_TV && centerX < 600 ? centerX / 1.5 : centerX)
      if (this.game?.mp && slot.slotId > 0) {
        let offsetY = cardWidth + stageHeight / 2 + cardOffset / 2
        const offsetX = 0
        const slotIdx = this.game.slots.findIndex((x) => x.slotId === slot.slotId) - 1
        const spIdx = this.game.slots.findIndex((x) => x.slotId === this.game?.sp) - 1

        try {
          if (isLandscape && slot.userUuid === this.myUUID) {
            if (slotIdx === -1) {
              g.x(stageWidth / 2 - cardOffset)
            } else {
              g.x(stageWidth / 2 + cardOffset)
            }
          } else {
            const key = slot.userUuid + '_' + slot.userIdx
            if (key in pos) {
              g.x(pos[key][0] + (isLandscape ? slotWidth / 3 : 0))
            }
          }
        } catch (e) {
          console.log(e, pos, slot)
        }

        const nameGroup = new Konva.Group({
          y: offsetY,
          x: offsetX,
        })
        const rect = new Konva.Rect({
          y: 0,
          x: 0,
          height: 30,
          width: 100,
          fill: '#000',
          stroke: slotIdx === spIdx ? '#ff0' : '#fff',
          strokeWidth: 1,
        })
        const text = new Konva.Text({
          text: slot.userUuid.substring(0, 8),
          y: 0,
          x: 0,
          fill: '#fff',
          align: 'center',
          verticalAlign: 'middle',
          height: 30,
          width: 100,
        })

        nameGroup.add(rect)
        nameGroup.add(text)
        nameGroup.hide()
        g.add(nameGroup)
      } else {
        if (slot.slotId > 0 && isLandscape) {
          g.x(stageWidth / 2 + cardOffset + 45)
        }
        g.offsetX(getCardWidth() * 0.8)
      }
      layer.add(g)
      if (slot.slotId > 0) {
        g.moveToTop()
      } else {
        g.moveToBottom()
      }
    })
  }

  chipClick = (event: any) => {
    if (!this.diffProcessed) {
      return
    }
    if (this.game && this.game.mp && this.table) {
      if (this.isBetSet()) {
        // Bet is set
        return
      }

      const idx = this.table.players.findIndex((x) => x.playerUuid === this.myUUID)
      if (idx < 0) {
        // User have not part of this game
        return
      }

      if (this.table.players[idx]?.kicked) {
        joinTableTask.next({ table: this.table.uuid, idx: this.table.players[idx]?.slotIdx })
        // store.dispatch({type: TableConstant.SIT_TABLE, tableUuid: this.table.uuid, idx: this.table.players[idx]?.slotIdx})
      }

      if (undefined === this.game.slots) {
        // There is no game, only dummy placeholder
        return
      }
    }

    if (!this.game) {
      return
    }
    if (this.getGameState(this.game) === GameState.RESULTS && undefined === this.table) {
      this.pendingChipEvent = event
      this.onEvent.next({ action: 'button_click', parameter: GameMove.CHANGE_BET, extra: {} })
      return
    } else if (this.getGameState(this.game) !== GameState.CONFIGURE) {
      return
    }
    this.errors = []
    const c = this.getGameCurrency()
    const vid = this.getGameVirtualID()
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const mconf = Money.listAll()
    const shift = mconf[c.m_unit].units[c.s_unit].shift - mconf[c.m_unit].units[format].shift
    const m = event.target.attrs.value * Math.pow(10, shift)

    const balance = getBalance(this.getGameCurrency().short, vid)
    const limits =
      undefined === this.table
        ? ConfigurationService.instance.getBetLimits(this.getGameCurrency())
        : ConfigurationService.instance.getBetLimitsMp(this.getGameCurrency())

    const haveEnoughBalance = balance >= this.bet + m
    const dontExceedMaxBetLimit = this.bet + m <= limits.max
    if (haveEnoughBalance && dontExceedMaxBetLimit) {
      this.bet += m
      const touchPos = this.stage?.getStage().getPointerPosition()
      this.chipStack?.addChipToStack(touchPos as any, event.target.attrs.value, m, this.updateBalance)
      this.playSound(Sound.BUTTON_CLICK)
      this._setTimeout(() => {
        this.playSound(Sound.CHIP_MOVE)
      }, 200)
    } else {
      if (!dontExceedMaxBetLimit) {
        this.errors = ['Maximum bet reached!']
      } else {
        this.errors = ['Not enough balance!']
      }
    }
    this.renderErrors()
    this.updateButtons()
  }

  insertCard(card: GameCard, slotId: number, cardidx: number, doneFn?: () => void, noAnimations?: boolean) {
    if (!this.stage) {
      // console.error('stage is missing');
      return
    }
    const layer = this.getLayer('game')
    const stageHeight = this.stage.height()
    const slot = this.getSlot(slotId)
    if (!slot) {
      if (doneFn !== undefined) {
        doneFn()
      }
      return
    }

    const moveSlotsUp = this.stage.height() < 700
    // Hide cards that are not in my slots
    if (this.game?.mp && slotId > 0 && this.stage.width() < 800) {
      const uuid = slot.attrs.playerUuid
      if (this.myUUID !== uuid) {
        if (doneFn !== undefined) {
          doneFn()
        }
        return
      }
    }

    const canvasCard = getCard(card, slotId, cardidx)
    canvasCard.x(canvasCard.x() + canvasCard.width() / 2)
    if (cardidx > 0) {
      canvasCard.x(canvasCard.x() + cardidx * canvasCard.width() * 0.3)
    } else {
      canvasCard.rotate(-16)
    }

    addScaleAnimation(canvasCard, layer)
    this.addClickHandler(canvasCard as any, this.cardClick)

    const bounds = getElementBounds(canvasCard)
    if (this.role === Role.THUMB_TV && slotId > 0) {
      if (canvasCard.x() === 0) {
        if (this.stage) {
          canvasCard.x(canvasCard.x() + this.stage.getStage().width() / 2 + bounds.width * 0.8)
        }
      }
    }

    slot.add(canvasCard)
    canvasCard.zIndex(cardidx)
    const stageWidth = this.stage?.width() || 0
    const mobile = Math.min(stageWidth, stageHeight) < 800
    const isLandscape = mobile && stageWidth > stageHeight

    if (slotId === 0) {
      let y = stageHeight / 2 - this.getCardOffestYFromCenter() - (moveSlotsUp ? stageHeight / 10 : 0)
      let x = canvasCard.x()
      if (this.role === Role.THUMB_TV) {
        y = bounds.height / 2 + 15
      }
      if (mobile && isLandscape && this.role !== Role.THUMB_TV) {
        // RTL, dealer on left
        x = canvasCard.x() - (this.getCardOffestYFromCenter() + 45)
        y = stageHeight / 2
      }
      animateTo(canvasCard as any, { x, y }, noAnimations ? 0 : 350, doneFn)
    } else {
      let y = stageHeight / 2 + this.getCardOffestYFromCenter() - (moveSlotsUp ? stageHeight / 10 : 0)
      const gameSlot = this.game?.slots?.find((s) => s.slotId === slotId)
      if (this.lastSlotGroup && gameSlot && this.game?.mp && gameSlot.userUuid !== this.myUUID) {
        canvasCard.height(canvasCard.height() / 3)
        canvasCard.width(canvasCard.width() / 3)
        canvasCard.children?.forEach((c) => {
          c.height(canvasCard.height())
          c.width(canvasCard.width())
          c.offsetX(canvasCard.width() / 2)
          c.offsetY(canvasCard.height() / 2)
        })
        canvasCard.x(canvasCard.width() / 2)
        if (cardidx > 0) {
          canvasCard.x(canvasCard.x() + cardidx * canvasCard.width() * 0.3)
        }
        y = this.lastSlotGroup.y() + this.lastSlotGroup.height() + canvasCard.height() + 10
      }

      let x = canvasCard.x()

      if (this.role === Role.THUMB_TV) {
        y = bounds.height / 2 + 15
      }

      // If we are animating to hidden slot(split)
      const cb = () => {
        try {
          if (undefined !== gameSlot) {
            if (null !== gameSlot.parentId && gameSlot.slotId !== this.game?.slots[this.game.sp]?.slotId) {
              canvasCard.hide()
            }
          }
          if (doneFn !== undefined) {
            doneFn()
          }
        } catch (e) {
          console.log('catched:')
          console.error(e)
        }
      }

      if (
        mobile &&
        isLandscape &&
        (!this.game?.mp || gameSlot?.userUuid === this.myUUID) &&
        this.role !== Role.THUMB_TV
      ) {
        y = stageHeight / 2
      }
      animateTo(canvasCard as any, { x, y }, noAnimations ? 0 : 350, cb)
    }
    this.playSound(Sound.DEAL)
  }

  switchToState(fromState: number, toState: number) {
    if (!this.stage) {
      // console.error('stage is missing');
    }
    if (this.table && this.table.state === 'waiting_for_game') {
      this.timer.makeTimer(ClockType.waiting_game_clock)
    }
    if (fromState === 0 && toState === 0) {
      this.drawMultiplayerSlots()
    }
    // Configure -> play
    if (fromState <= 30 && toState === 40) {
      this.initStatePlay(() => {
        this.updateButtons()
        this.updateHandSum()
        this.drawMultiplayerSlots()
      })
      if (!this.chipStack) {
        return
      }
      if (this.role === Role.THUMB_TV) {
        this.chipStack.stackFromChips(this.bet, 'right')
      }
      return
    }

    if (fromState === 40 && toState === 40) {
      this.updateButtons()
    }

    // Configure -> results
    if (fromState <= 30 && toState === 50) {
      this.initStatePlay(() => {
        this.updateButtons()
        this.updateHandSum()
        this.addSlotResults()
        this.drawMultiplayerSlots()
      })
      return
    }

    // Play -> results
    if (fromState === 40 && toState === 50) {
      this.updateButtons()
      this.updateHandSum()
      this.addSlotResults()

      // PERK - Show next card
      const nextCard = this.stage?.getStage().findOne('#' + nextCardID)
      if (nextCard) {
        nextCard.destroy()
      }
      return
    }

    if (fromState === 0 && toState === 0) {
      if (this.game && this.game.slots) {
        const mySlot = this.game.slots.find((x) => x.userUuid === this.myUUID)
        if (undefined !== mySlot && mySlot.bet > 0) {
          this.updateButtons()
        }
        this.drawMultiplayerSlots()
        if (this.table && this.table.state === 'waiting_for_bets') {
          this.timer.makeTimer(ClockType.user_bet_clock)
        }
      }
    }
  }

  addSlotResults() {
    if (undefined === this.game?.slots) {
      return
    }
    for (let i = 0; i < this.game.slots.length; i++) {
      const gameSlot = this.game.slots[i]
      const slot = this.game.slots[gameSlot.slotId]
      const canvasSlot = this.getSlot(gameSlot.slotId)
      const mobile = this.stage && this.stage.width() < 800
      if (!canvasSlot || !slot) {
        return
      }

      // Show dealer win when all slots are lost by user
      let res
      if (
        slot.slotId === 0 &&
        this.game.slots.slice(1).every((x) => x.result && x.result !== 'win' && x.result !== 'push')
      ) {
        res = getSkewedRect({ fontSize: 15, text: 'WIN', fill: '#adadad' })
      } else if (slot.slotId > 0 && slot.result) {
        if (
          this.game.mp &&
          mobile &&
          this.table?.players.find((x) => x.slotIdx === slot.userIdx)?.playerUuid !== this.myUUID
        ) {
          continue
        }
        const stageWidth = this.getLayer('game').width()
        let width = this.role === Role.THUMB_TV ? stageWidth / 2.7 : 0
        let height = this.role === Role.THUMB_TV ? width / 1.5 : 0
        let fontSize = this.role === Role.THUMB_TV ? height / 2 : 15
        if (this.game.mp && gameSlot.userUuid !== this.myUUID) {
          fontSize = fontSize / 1.4
          width = width / 4
          height = height / 5
        }

        if (slot.result === 'push') {
          res = getSkewedRect({ fontSize: fontSize, text: 'PUSH', fill: '#ff7f50', width, height })
        } else if (slot.result === 'win') {
          res = getSkewedRect({ fontSize: fontSize, text: 'WIN', fill: '#678f28', width, height })
        } else if (slot.result === 'lose' && getSlotValues(slot).every((x) => x > 21)) {
          if (this.role === Role.THUMB_TV) {
            res = getSkewedRect({
              fontSize: fontSize + 3,
              text: 'BUST',
              fill: '#ea1e22',
              textColor: '#fff',
              width,
              height,
            })
          } else {
            res = getSkewedRect({
              fontSize: fontSize + 3,
              text: 'BUST',
              fill: '#ea1e22',
              textColor: '#fff',
              width: 200,
              height: 50,
            })
          }
        }
      }

      if (res) {
        const bounds = getElementBounds(canvasSlot)
        res.x(bounds.x)
        res.y(bounds.y)
        if (isSmallScreen(canvasSlot.getLayer() as Konva.Layer)) {
          res.scale({ x: 0.7, y: 0.7 })
          res.y(res.y() - res.height() - 10)
        }
        canvasSlot.add(res)
        fadeIn(res as any)
      }
    }
    if (this.game.summary) {
      if (undefined !== this.game.summary.details && undefined !== this.game.slots) {
        // Add uuid to game summary details
        const details = this.game.summary.details.map((x) => {
          const slot = this.game?.slots.find((y) => y.slotId === x.id)
          if (undefined !== slot) {
            x.uuid = slot.userUuid
          }
          return x
        })
        const summary = JSON.parse(JSON.stringify(this.game.summary))
        summary.details = details
        this.chipStack?.splitStack(summary)
      } else {
        this.chipStack?.splitStack(this.game.summary)
      }
    }
  }

  renderErrors = () => {
    const layer = this.getLayer('game')
    layer.find('#error_rect').forEach((x) => x.destroy())
    if (this.errors.length > 0) {
      if (this.errorTimeout) {
        clearTimeout(this.errorTimeout)
        this.errorTimeout = undefined
      }

      const smallScreen = isSmallScreen(this.getLayer('game'))
      const buttonConfig = {
        fontSize: smallScreen ? 8 : 15,
        fill: 'red',
        width: smallScreen ? 130 : 200,
        height: smallScreen ? 28 : 37,
        text: this.errors[0],
        textColor: '#fff',
      }

      const rect = getSkewedRect(buttonConfig)
      if (!this.stage) {
        return
      }
      rect.y(this.stage.height() / 2 - rect.height() / 2)
      if (smallScreen) {
        rect.x(this.stage.width() / 2 + 30)
      } else {
        rect.x(this.stage.width() / 2 + 100)
      }

      rect.id('error_rect')
      layer.add(rect)

      this.errorTimeout = this._setTimeout(() => {
        this.errors = []
        this.renderErrors()
      }, 3000)
    }
    layer.batchDraw()
  }

  scale() {
    this.stage?.getStage().scale({ x: 0.4, y: 0.4 })
  }

  render(): JSX.Element {
    if (this.el) {
      return this.el
    }
    this.el = <div className="game_canvas_container" ref={(ref) => this.setRef(ref as HTMLDivElement)} />
    return this.el
  }

  public getLayer(layer: layerType): Konva.Layer {
    return this.stage?.getStage().findOne('#' + layer) as Konva.Layer
  }

  processDiff(game: Game, diff: DiffResult[], depth?: number) {
    if (game?.serverSeedHash) {
      nextServerSeedHash.next(game.serverSeedHash)
    }
    if (!this.diffProcessed) {
      if (undefined === depth) {
        depth = 0
      }
      if (depth > 100) {
        this.diffProcessed = true
        return
      }
      this._setTimeout(() => {
        this.processDiff(game, diff, (depth as number) + 1)
      }, 50)
      return
    }
    this.errors = []
    this.diffProcessed = false
    this.game = game
    if (undefined !== this.table) {
      if (this.table.state === 'waiting_for_bets') {
        this.timer.makeTimer(ClockType.user_bet_clock)
      }
      if (this.table.state === 'playing') {
        this.timer.makeTimer(ClockType.user_move_clock)
      }
      if (this.table.state === 'waiting_for_game') {
        this.timer.makeTimer(ClockType.waiting_game_clock)
      }
    }
    diff.sort((a, b) => {
      if (a.result === b.result) {
        return 0
      }
      let aPriority = diffProcessOrder.indexOf(a.result)
      if (a.result === DiffResultEnum.cardAdded && a.param.slot > 0) {
        aPriority -= 1
      }
      let bPriority = diffProcessOrder.indexOf(b.result)
      if (b.result === DiffResultEnum.cardAdded && b.param.slot > 0) {
        bPriority -= 1
      }
      return aPriority > bPriority ? 1 : -1
    })
    this._nextDiff(diff, 0)
  }

  private _nextDiff(diff: DiffResult[], i: number) {
    const d = diff[i]
    if (!d) {
      this.diffProcessed = true
      return
    }
    if (undefined === this.stage || undefined === this.game) {
      this.diffProcessed = true
      return
    }
    const gameLayer = this.getLayer('game')
    if (undefined === gameLayer || undefined === this.game) {
      this.diffProcessed = true
      return
    }
    if (d.result === DiffResultEnum.cardUpdated || d.result === DiffResultEnum.cardAdded) {
      const param: { slot: number; cardIdx: number; card: GameCard } = d.param
      if (d.result === DiffResultEnum.cardAdded) {
        const wasAtIdx = gameLayer.zIndex()
        gameLayer.zIndex((this.stage.children?.length || 0) - 1)
        this.insertCard(param.card, param.slot, param.cardIdx, () => {
          this.getSlot(param.slot)?.children?.forEach((x, i) => {
            //x.zIndex(i)
          })
          gameLayer.zIndex(wasAtIdx)
          this.updateHandSum()
          if (i !== undefined) {
            if (diff[diff.length - 1].result === DiffResultEnum.slotPointerChanged) {
              setTimeout(() => {
                this._nextDiff(diff, i + 1)
              }, 1000)
            } else {
              this._nextDiff(diff, i + 1)
            }
          }
        })
        return
      }

      if (d.result === DiffResultEnum.cardUpdated) {
        if (this.game?.slots?.length > 0) {
          this.activateSlot(this.game.slots[this.game.sp])
        }
        if (this.game?.slots?.length > 0) {
          const card = this.game.slots[param.slot]?.cards[param.cardIdx]
          if (card !== undefined) {
            const canvasCard = getCard(card, param.slot, param.cardIdx)
            addScaleAnimation(canvasCard, gameLayer)
            this.addClickHandler(canvasCard as any, this.cardClick)

            const slot = this.getSlot(param.slot)
            if (!slot) {
              this._nextDiff(diff, i + 1)
              return
            }
            const cards = this.getSlotCards(param.slot)
            const oldCard = cards[param.cardIdx]
            if (!oldCard) {
              this._nextDiff(diff, i + 1)
              return
            }
            canvasCard.x(oldCard.x())
            canvasCard.y(oldCard.y())
            canvasCard.width(oldCard.width())
            canvasCard.height(oldCard.height())
            canvasCard.rotate(oldCard.rotation())
            canvasCard.children?.forEach((x: any, ii: number) => {
              x.height(oldCard.children[ii].height())
              x.width(oldCard.children[ii].width())
              x.offsetX(oldCard.children[ii].offsetX())
              x.offsetY(oldCard.children[ii].offsetY())
            })

            slot.add(canvasCard)
            canvasCard.zIndex(param.cardIdx)
            flip(oldCard as Konva.Container, canvasCard as any, () => {
              oldCard.destroy()
              this._nextDiff(diff, i + 1)
            })
          }
        } else {
          this._nextDiff(diff, i + 1)
        }
        return
      }
    }

    if (d.result === DiffResultEnum.cardMoved) {
      const fromSlot = this.getSlot(d.param.fromSlot)
      if (fromSlot && fromSlot.children) {
        const toSlot = this.getSlot(d.param.toSlot)
        const card = fromSlot.children[d.param.cardIdx] as Konva.Group
        if (undefined !== card) {
          card.x(card.width() / 2)
          card.moveTo(toSlot)
          card.rotate(-16)
          card.hide()
          card.zIndex(0)
          card.destroy()
        }
        this.updateHandSum()
      }
      this._nextDiff(diff, i + 1)
      return
    }

    if (d.result === DiffResultEnum.slotAdded) {
      const centerX = this.stage.getStage().width() / 2
      const slot = this.game.slots.find((x) => x.slotId === d.param.slot)
      const g = this.createSlotGroup(slot as GameSlot)
      if (g && slot) {
        g.x(centerX)
        if (this.game.mp) {
          try {
            const pos = this.getLayer('mp').findOne('#slots')?.attrs.positions
            const key = slot.userUuid + '_' + slot.userIdx
            if (key in pos) {
              g.x(pos[key][0])
            }
          } catch (e) {
            console.log('catched:')
            console.log(e)
          }
        } else {
          g.offsetX(getCardWidth() * 0.8)
        }
        gameLayer.add(g)
      }
      gameLayer.batchDraw()
      this.activateSlot(this.game.slots[this.game.sp])
      this._nextDiff(diff, i + 1)
      return
    }

    if (d.result === DiffResultEnum.slotPointerChanged) {
      this.activateSlot(this.game.slots[d.param.to])
      if (this.game.mp) {
        this.drawMultiplayerSlots()
      }
      this.updateHandSum()
      gameLayer.batchDraw()
      this._nextDiff(diff, i + 1)
      return
    }

    if (d.result === DiffResultEnum.stateChaged) {
      this.switchToState(d.param.fromState, d.param.toState)
      this._nextDiff(diff, i + 1)
      return
    }

    if (d.result === DiffResultEnum.buttonClick) {
      this.displayButtonClick(d.param, () => {
        this._nextDiff(diff, i + 1)
      })
    }

    if (d.result === DiffResultEnum.playerJoined || d.result === DiffResultEnum.playerUpdated) {
      this.drawMultiplayerSlots()
      this._nextDiff(diff, i + 1)
    }

    if (d.result === DiffResultEnum.betAdded) {
      const { bet, uuid, userIdx } = d.param
      if (uuid !== this.myUUID) {
        const pos = this.getLayer('mp').findOne('#slots')?.attrs.positions
        const c = this.getGameCurrency()
        const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
        const convertedValue = Money.convertUnit(bet, c.m_unit, c.s_unit, format)
        const key = uuid + '_' + userIdx
        let x = this.stage.width() + 200
        if (key in pos) {
          x = pos[key][0]
        }

        const chips = ChipStack.getChipsForValue(c, bet)
        chips.forEach((chip, j) => {
          this.chipStack?.addChipToStack({ x, y: this.getLayer('game').height() }, chip, j === 0 ? convertedValue : 0)
        })
      }
      this._nextDiff(diff, i + 1)
    }
  }

  private displayButtonClick(move: string, done: () => void) {
    if (this.role === Role.THUMB_TV) {
      const layer = this.getLayer('game')
      const buttonHeight = ((layer.height() * 1) / layer.scaleX()) * 0.5
      const buttonWidth = buttonHeight * 2
      const moveButton = getSkewedRect({
        fontSize: 30,
        text: move,
        height: buttonHeight,
        width: buttonWidth,
        fontStyle: 'bold',
      })

      moveButton.x(layer.width() / 2)
      moveButton.y(((layer.height() / 2) * 1) / layer.scaleX() - moveButton.height() / 2)
      layer.add(moveButton)
      moveButton.moveToTop()
      layer.batchDraw()
      this._setTimeout(() => {
        moveButton.remove()
        layer.batchDraw()
      }, 1000)
    } else if (this.role === Role.FULL_TV) {
      this.activeMove = move
      this.updateButtons()
      this.activeMove = undefined
      this._setTimeout(() => {
        this.updateButtons()
      }, 1000)
    }
    done()
  }

  private getGameCurrency(): Currency {
    if (this.game && this.game.currency) {
      return ConfigurationService.instance.getCurrency(this.game.currency)
    }
    if (this.table && this.table.currency) {
      return ConfigurationService.instance.getCurrency(this.table.currency)
    }
    const currency = store.getState().wallet.activeWallet?.currency
    return ConfigurationService.instance.getCurrency(currency as string)
  }

  private getGameVirtualID(): number {
    if (this.game?.virtualId) {
      return this.game.virtualId
    }
    if (this.table) {
      return this.table.virtualId
    }
    return store.getState().wallet.activeWallet?.virtualId as number
  }

  private getGameState(game: Game | undefined): GameState {
    if (!game) {
      return GameState.IDLE
    }
    switch (+game.state) {
      case 0:
      case 10:
      case 20:
      case 30:
        return GameState.CONFIGURE
      case 40:
        return GameState.PLAY
      case 50:
        return GameState.RESULTS
      default:
        return GameState.IDLE
    }
  }

  private getInitialBet(game: Game): number {
    if (game.mp) {
      return game.slots.slice(1).reduce((acc, val) => acc + val.bet, 0)
    } else {
      return game.slots[1].bet
    }
  }

  // 0  - dealer
  // 1+ - player
  private getSlot(id: number): Konva.Group | undefined {
    if (!this.stage) {
      return
    }
    return this.stage.getStage().findOne('#slot_' + id) as any
  }

  private getSlotCards(id: number): any {
    const slot = this.getSlot(id)
    if (!slot) {
      // console.log('cannot find slot: ' + id);
      return [] as any
    }
    return slot.find('#canvas_card')
  }

  private playSound(sound: Sound) {
    if (this.role === Role.GAME) {
      SoundManager.playSound(sound)
    }
  }

  private getCardOffestYFromCenter() {
    if (!this.stage) {
      return 0
    }
    const stageHeight = this.stage.getStage().height()
    if (stageHeight <= 450) {
      return stageHeight / 9 + 26
    } else if (stageHeight <= 700) {
      return stageHeight / 9 + 56
    } else if (stageHeight <= 900) {
      return stageHeight / 9 + 36
    } else {
      return stageHeight / 9 + 66
    }
  }

  private getButtonsOffsetFromBottom() {
    if (!this.stage) {
      return 0
    }
    const shouldStack = this.stage.width() < 800
    const offset = this.stage.height() / 20
    const chipWidth = ChipStack.getChipSize(this.getLayer('game'), this.role)
    const chipListTop = chipWidth * 1.14
    return chipListTop + chipWidth / 2 + (shouldStack ? offset : 0)
  }

  private getDefaultButtonConfig() {
    const smallScreen = isSmallScreen(this.getLayer('game'))
    return {
      fontSize: smallScreen ? 10 : 14,
      fontStyle: 'italic',
      fill: '#ff0',
      width: smallScreen ? 50 : 100,
      height: smallScreen ? 28 : 37,
    }
  }

  private _setTimeout(func: () => void, timeout: number) {
    const timeoutId = setTimeout(() => {
      try {
        func()
      } catch (e) {
        console.log('catched:')
        console.error(e)
      }

      const idx = this.timeouts.findIndex((x) => x === timeoutId)
      if (idx >= 0) {
        this.timeouts.splice(idx, 1)
      }
    }, timeout)
    this.timeouts.push(timeoutId)
    return timeoutId
  }
}

export { UserGameRenderer, Role }

import React, { useState } from 'react'
import parse from 'react-html-parser'
import ITextBlock from './TextBlock.interface'
import styles from './TextBlock.module.scss'
import { useTranslation } from 'react-i18next'

const TextBlock: React.FC<ITextBlock> = ({ headline, shorttext, text }) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  return (
    <div className={`${styles.root} bg-eerieBlack py-18 px-35 md:text-left w-full`}>
      <div className="text-center">
        <h2 className={`${styles.headline}`}>{headline}</h2>
        <div className={`${styles.text}`}>{expanded ? parse(text) : parse(shorttext)}</div>
        <div>
          <button type="button" className={`${styles.button}`} onClick={() => setExpanded(!expanded)}>
            {expanded ? t('textBlockComponent.showLess', 'show less') : t('textBlockComponent.readAll', 'read all')}
          </button>
        </div>
      </div>
    </div>
  )
}
export default TextBlock

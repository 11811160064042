const FireIcon = ({ color = '#D5DCEB', ...props }) => (
  <svg {...props} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.45246 14C0.584082 12.3834 -0.171101 10.8532 0.260659 8.76339C0.579404 7.22023 1.62312 5.96472 1.72319 4.42452C2.16903 5.23587 2.35533 5.82092 2.40514 6.66877C3.82315 4.93144 4.76019 2.52637 4.81568 0C4.81568 0 8.50905 2.17012 8.7514 5.4481C9.06935 4.77245 9.22941 3.69936 8.91146 3.00389C9.86529 3.6994 15.4482 9.87366 8.15525 14C9.5264 11.3302 8.50898 7.72787 6.12829 6.06412C6.28729 6.77951 6.00853 9.4477 4.9553 10.6201C5.24713 8.66111 4.67764 7.83276 4.67764 7.83276C4.67764 7.83276 4.48217 8.93005 3.72379 10.0385C3.03126 11.0507 2.55141 12.1251 3.45246 14Z"
      fill="currentColor"
    />
  </svg>
)

export default FireIcon

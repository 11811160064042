import React, { useCallback, useEffect, useRef, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { IStore, store } from '../../store'
import { useHistory, useParams } from 'react-router'
import { InHouseGame } from '../inHouseGames/InHouseGame'

import useIsMobile from '../../hooks/IsMobile'

import './GameListComponent.scss'
import classes from './GameListComponent.module.scss'
import classNames from 'classnames'

import Env from '@src/RelayEnvironment'

import { activeWalletUpdated } from '@src/reducers/wallet.reducer'
import { fetchQuery } from 'relay-runtime'
import {
  NewGameListComponentNormalizedGamesQuery,
  NewGameListComponentNormalizedGamesQuery$data,
} from './__generated__/NewGameListComponentNormalizedGamesQuery.graphql'
import { FilteredGames } from './FilteredGames'
import { LeaderBoards } from '../LeaderBoards'
import { LatestBets } from '../LatestBets/LatestBets'
import TextBlock from '../TextBlock/TextBlock'
import Footer from '../Footer/Footer'
import { NewGameListComponentNormalizedProvidersQuery } from './__generated__/NewGameListComponentNormalizedProvidersQuery.graphql'
import { useMutation } from 'react-relay'
import {
  Currency,
  NewGameListComponentCreateHubUrlMutation,
} from './__generated__/NewGameListComponentCreateHubUrlMutation.graphql'
import { ExtendedGame } from './GameListComponent.types'
import { AppTypes } from '@src/reducers/app.reducer'
import formatError from '../errorFormatter'
import { NewGameListComponentEndorphinaCreateUrlMutation } from './__generated__/NewGameListComponentEndorphinaCreateUrlMutation.graphql'
import { TopGames } from '../TopGames'
import { ourGames } from '../helper'
import { filterProviders } from '@src/utils/providers'
import { useLockScroll } from '@src/hooks/useLockScroll'
import { useSelector } from 'react-redux'

export type Game = NewGameListComponentNormalizedGamesQuery$data['listNormalizedGames']['entries'][number]

type GameInput = NewGameListComponentNormalizedGamesQuery['variables']['filter']
type ProviderInput = NewGameListComponentNormalizedProvidersQuery['variables']['filter']

export type GameFilter = {
  aggregator: GameInput['aggregator']
  provider: GameInput['provider']
}

export type ProviderFilter = {
  aggregators: ProviderInput['aggregators']
  category?: ProviderInput['category']
}

export const NewGameListComponent = () => {
  const collapsedMenu = useSelector((state: IStore) => state.app.collapsedMenu)
  const chatOpened = useSelector((state: IStore) => state.app.chatOpened)
  const history = useHistory()

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(150)

  const [providers, setProviders] = useState<string[]>([])

  const [gameFilter, setGameFilter] = useState<GameFilter>({
    aggregator: ['BJFUN', 'HUB88', 'ENDORPHINA'],
    provider: undefined,
  })

  const [normalizedGames, setNormalizedGames] =
    useState<NewGameListComponentNormalizedGamesQuery$data['listNormalizedGames']>()
  const [openEndorphinaGameCommit] =
    useMutation<NewGameListComponentEndorphinaCreateUrlMutation>(createEndorphinaUrlMutation)
  const [openHubGameCommit] = useMutation<NewGameListComponentCreateHubUrlMutation>(createHubUrl)

  const _setFilter = useCallback((filter: GameFilter) => {
    setPage(1)
    setPageSize(150)

    setNormalizedGames(undefined)
    setGameFilter(filter)
  }, [])

  const [hubGameUrl, setHubGameUrl] = useState<string | null>()

  const [activeWallet, setActiveWallet] = useState(store.getState().wallet.activeWallet)
  const [gamewrapperStyle, setGamewrapperStyle] = useState({})

  useEffect(() => {
    const subscription = activeWalletUpdated.subscribe(() => {
      setActiveWallet(store.getState().wallet.activeWallet)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [activeWallet])

  const isMobile = useIsMobile('lg')
  const isTablet = useIsMobile('md')
  const { gameId } = useParams<{ gameId: string }>()

  const shouldLockScroll =
    isMobile &&
    (collapsedMenu || chatOpened || gameId === 'Oddin:odn_oddingame' || gameId === 'BetsyGames:btsg_sportbetting')
  useLockScroll(shouldLockScroll)

  const gameWrapperRef = useRef<HTMLDivElement>(null)

  const virtualId = activeWallet?.virtualId ?? 0
  const openGame = useCallback(
    (gameId: string) => {
      const [, gameCode] = gameId.split(':')

      if (gameId?.startsWith('endorphina')) {
        if (store.getState().authentication.user?.anonymousHash !== null) {
          return
        }
        openEndorphinaGameCommit({
          variables: {
            input: {
              currency: (activeWallet?.currency || 'BTC') as Currency,
              gameId,
              virtualId,
            },
          },
          onCompleted: ({ createUrl }) => {
            const gameLaunchOptions = {
              target_element: 'game_wrapper',
              launch_options: {
                strategy: 'iframe',
                game_url: createUrl?.url,
              },
            }
            const success = (gameDispatcher: any) => {
              console.log(gameDispatcher)
            }
            const errHandler = (error: any) => {
              console.log(error)
            }
            ;(window as any).sg.launch(gameLaunchOptions, success, errHandler)
          },
          onError(error) {
            store.dispatch({
              type: AppTypes.SHOW_NOTIFICATION,
              notification: {
                status: 'error',
                text: formatError(error)[0],
              },
            })
          },
        })

        return
      }

      openHubGameCommit({
        variables: {
          input: {
            currency: (activeWallet?.currency || 'BTC') as Currency,
            gameCode,
            mobile: false,
            virtualId,
          },
        },
        onCompleted: (resp) => {
          setHubGameUrl(resp.createHubUrl?.url)
        },
      })
    },

    [openHubGameCommit, activeWallet?.currency, virtualId, openEndorphinaGameCommit]
  )

  useEffect(() => {
    if (gameId && activeWallet?.currency) {
      openGame(gameId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId, virtualId, activeWallet?.currency])

  useEffect(() => {
    let category: 'slots' | 'live' | undefined
    if (history.location.pathname.includes('slots')) {
      category = 'slots'
    } else if (history.location.pathname.includes('live')) {
      category = 'live'
    } else {
      category = undefined
    }

    fetchQuery<NewGameListComponentNormalizedProvidersQuery>(
      Env,
      listNormalizedProviders,
      {
        filter: {
          aggregators: ['BJFUN', 'HUB88', 'ENDORPHINA'],
        },
      },
      {
        fetchPolicy: 'network-only',
      }
    ).subscribe({
      next: (data) => {
        const { listNormalizedProviders } = data

        const filtered = category
          ? filterProviders([...listNormalizedProviders], category)
          : [...listNormalizedProviders]

        setProviders(
          [...filtered].sort((a, b) => {
            if (a === 'bjfun') return -1
            if (b === 'bjfun') return 1

            return a.localeCompare(b)
          })
        )
      },
    })
  }, [gameFilter, history.location.pathname])

  useEffect(() => {
    fetchQuery<NewGameListComponentNormalizedGamesQuery>(
      Env,
      listNormalizedGames,
      {
        page,
        pageSize,
        filter: {
          ...gameFilter,
          providers,
        },
      },
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: (data) => {
        const { listNormalizedGames } = data

        setNormalizedGames((prev) => {
          if (!prev) return listNormalizedGames

          if (page > 1) {
            return {
              ...prev,
              entries: [...prev.entries, ...listNormalizedGames.entries],
            }
          }

          return {
            ...prev,
            entries: listNormalizedGames.entries,
          }
        })
      },
    })
  }, [page, providers, pageSize, gameFilter, history.location.pathname])

  useEffect(() => {
    if (!isMobile) return

    setGamewrapperStyle({
      position: 'relative',
    })
  }, [isMobile])

  const hasMore = useCallback(() => {
    if (!normalizedGames) return false
    const { pageNumber, totalPages } = normalizedGames

    if (!pageNumber || !totalPages) return false

    return totalPages > page
  }, [normalizedGames, page])

  const fetchMore = useCallback(() => {
    setPage((prev) => prev + 1)

    if (hasMore()) {
      setPageSize((prev) => prev + 100)
    }
  }, [hasMore])

  const headerHeight = document.querySelector<HTMLDivElement>('#app-header')?.getBoundingClientRect()?.height || 0

  if (gameId) {
    if (gameId === 'bitcoin-blackjack') {
      return <InHouseGame gameId="blackjack" />
    }
    if (gameId === 'bitcoin-blackjack_v2') {
      return (
        <iframe
          key={isMobile ? 'mobile' : 'desktop'}
          title="bitcoin-blackjack"
          src={process.env.NODE_ENV === 'development' ? 'http://localhost:5173' : 'https://game.blackjack.fun'}
          allowFullScreen
          className={classNames({
            'fixed left-0 top-0 w-full': isMobile,
          })}
          style={{
            height: isMobile ? '100dvh' : `calc(100dvh - ${headerHeight}px)`,
            width: '100%',
            zIndex: isMobile ? 1000 : 0,
          }}
        />
      )
    }

    if (gameId.startsWith('endorphina')) {
      return (
        <>
          <div className="flex items-center justify-center lg:px-40 h-full" style={gamewrapperStyle}>
            <div
              id="game_wrapper"
              ref={gameWrapperRef}
              style={{
                [isMobile ? 'zIndex' : '']: 55,
              }}
            />
          </div>
          <div className="max-w-7xl mx-auto pt-32">
            <LatestBets />
          </div>
        </>
      )
    }

    const styles: Record<string, any> =
      gameId === 'Oddin:odn_oddingame' || gameId === 'BetsyGames:btsg_sportbetting'
        ? {
            height: `calc(100dvh - ${headerHeight}px)`,
            transform: isMobile ? `translateY(${headerHeight}px)` : 'unset',
            paddingBottom: isTablet ? '60px' : '0px',
          }
        : {
            [isMobile ? 'zIndex' : '']: 53,
          }

    return (
      <>
        <div
          className={classNames('flex items-center justify-center h-full', {
            'lg:px-40': gameId !== 'Oddin:odn_oddingame' && gameId !== 'BetsyGames:btsg_sportbetting',
            'lg:px-16': gameId === 'Oddin:odn_oddingame' || gameId === 'BetsyGames:btsg_sportbetting',
          })}
          style={gamewrapperStyle}
        >
          <div key={hubGameUrl} id="game_wrapper" ref={gameWrapperRef} style={styles}>
            {hubGameUrl && <iframe title="game" src={hubGameUrl} allowFullScreen allow="fullscreen; autoplay" />}
          </div>
        </div>
        <div className="max-w-7xl mx-auto pt-32">
          <LatestBets />
        </div>
      </>
    )
  }

  let category: 'slots' | 'live' | undefined

  if (history.location.pathname.includes('slots')) {
    category = 'slots'
  } else if (history.location.pathname.includes('live')) {
    category = 'live'
  } else {
    category = undefined
  }

  let games = (normalizedGames?.entries || []) as ExtendedGame[]
  if (!category && !gameFilter.provider && gameFilter.provider !== 'bjfun') {
    games = [...ourGames, ...games] as ExtendedGame[]
  }
  return (
    <div className={classNames(classes.root, 'app-content-inner text-white flex flex-col')}>
      <TopGames key={category} category={category} />
      <FilteredGames
        games={games}
        providers={providers}
        filter={gameFilter}
        setFilter={_setFilter}
        category={category}
        hasMore={hasMore}
        fetchMore={fetchMore}
      />
      <div className="flex flex-col">
        <LeaderBoards />
        <LatestBets />
        <div className="mb-22 text-center inline-flex justify-center items-center w-full">
          <TextBlock
            headline="Who is blackjack.fun?"
            shorttext="Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various crypto currencies and we will add more in the future.
            Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge. "
            text="Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various crypto currencies and we will add more in the future.<br/>
            Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge.<br/>
            Blackjack.fun's greatest achievement is its community. The members can get involved in the functionality of the platform, they can bring improvements, change various aspects of the experience and even suggest games. All these are meant to provide their members a unique experience, an honest, transparent and safe approach, and a place shaped based on their perspective."
          />
        </div>
        <Footer />
      </div>
    </div>
  )
}

const listNormalizedGames = graphql`
  query NewGameListComponentNormalizedGamesQuery($filter: ListNormalizedGamesFilter!, $page: Int!, $pageSize: Int) {
    listNormalizedGames(filter: $filter, page: $page, pageSize: $pageSize) {
      entries {
        urlThumb
        aggregator
        gameId
        category
        provider
        label
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`

const listNormalizedProviders = graphql`
  query NewGameListComponentNormalizedProvidersQuery($filter: ListNormalizedProvidersFilter!) {
    listNormalizedProviders(filter: $filter)
  }
`

const createHubUrl = graphql`
  mutation NewGameListComponentCreateHubUrlMutation($input: CreateHubUrlInput!) {
    createHubUrl(input: $input) {
      url
    }
  }
`

const createEndorphinaUrlMutation = graphql`
  mutation NewGameListComponentEndorphinaCreateUrlMutation($input: CreateUrlInput!) {
    createUrl(input: $input) {
      url
    }
  }
`

/**
 * @generated SignedSource<<ee9ab7ee766db2eca25326ea71ff3dff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type AppComponentServerTimeTickSubscription$variables = {};
export type AppComponentServerTimeTickSubscription$data = {
  readonly serverTimeTick: number;
};
export type AppComponentServerTimeTickSubscription = {
  variables: AppComponentServerTimeTickSubscription$variables;
  response: AppComponentServerTimeTickSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serverTimeTick",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppComponentServerTimeTickSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppComponentServerTimeTickSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "296b1aa44c3ad856867c7a212b9baac8",
    "id": null,
    "metadata": {},
    "name": "AppComponentServerTimeTickSubscription",
    "operationKind": "subscription",
    "text": "subscription AppComponentServerTimeTickSubscription {\n  serverTimeTick\n}\n"
  }
};
})();

(node as any).hash = "985dca833701ff2eef58fa6d2468e64e";

export default node;

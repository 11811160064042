import { graphql } from 'babel-plugin-relay/macro'

export const registerMutation = graphql`
  mutation registerMutation($input: RegisterInput!) {
    register(input: $input) {
      token
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

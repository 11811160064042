/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Twitter = (props: any) => (
  <svg {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.95136 4.23432L9.67408 0H8.79192L5.55948 3.6766L2.97773 0H0L3.9041 5.55967L0 10H0.882217L4.29576 6.11739L7.02227 10H10L5.95114 4.23432H5.95136ZM4.74304 5.60865L4.34748 5.05503L1.20009 0.64984H2.55512L5.0951 4.20497L5.49067 4.75859L8.79233 9.37971H7.4373L4.74304 5.60886V5.60865Z"
      fill="white"
    />
  </svg>
)

export default Twitter

import { fetchQuery, requestSubscription } from 'react-relay'
import { betUpdatedSubscription, betStatisticQueue, LastBet } from '../App'
import { UserBet } from '../components/LatestBets/LatestBets'
import { AppComponentBetUpdatedSubscription } from '../__generated__/AppComponentBetUpdatedSubscription.graphql'
import Env from '../RelayEnvironment'
import { BetHistoryComponentTopBetsUpdatedSubscription } from '../components/rightSide/betHistory/__generated__/BetHistoryComponentTopBetsUpdatedSubscription.graphql'
import { betsQuery, topbetsUpdatedSubscription } from '../components/rightSide/betHistory/BetHistoryComponent'
import { BetHistoryComponentBetsQuery } from '../components/rightSide/betHistory/__generated__/BetHistoryComponentBetsQuery.graphql'

let bets: LastBet

const setBets = (newBets: LastBet) => {
  bets = newBets
}

export const initBets = () => {
  requestSubscription<AppComponentBetUpdatedSubscription>(Env, {
    subscription: betUpdatedSubscription,
    variables: {},
    onNext: (data) => {
      if (!bets?.bets || !data?.betUpdated || data.betUpdated.currency === 'FUN') {
        return
      }

      const updateMaskedBetInArray = (bet: UserBet, array: Array<UserBet>): [Array<UserBet>, boolean] => {
        let someUpdated = false
        return [
          array.map((x) => {
            if (x.gameId === bet.gameId) {
              someUpdated = true
              return Object.assign({}, bet)
            }
            return Object.assign({}, x)
          }),
          someUpdated,
        ]
      }

      const [recentBets, recentUpdated] = updateMaskedBetInArray(
        structuredClone(data.betUpdated) as UserBet,
        bets.bets.recent
      )

      if (!recentUpdated) {
        recentBets.push(structuredClone(data.betUpdated) as UserBet)

        recentBets.sort((a: any, b: any) => {
          if (!a._insertedAt) {
            a._insertedAt = new Date(a.insertedAt).getTime()
          }
          if (!b._insertedAt) {
            b._insertedAt = new Date(b.insertedAt).getTime()
          }
          return a._insertedAt === b._insertedAt ? 0 : a._insertedAt > b._insertedAt ? -1 : 1
        })
      }
      setBets({
        bets: {
          biggestWins: bets.bets.biggestWins.slice(0, 49),
          biggestWinsGlobal: bets.bets.biggestWinsGlobal.slice(0, 49),
          recent: recentBets.slice(0, 49),
          highrollers: bets.bets.highrollers.slice(0, 49),
        },
      })
      betStatisticQueue.next({
        biggestWins: bets.bets.biggestWins,
        biggestWinsGlobal: bets.bets.biggestWinsGlobal,
        recent: recentBets,
        highrollers: bets.bets.highrollers,
      })
    },
  })

  requestSubscription<BetHistoryComponentTopBetsUpdatedSubscription>(Env, {
    subscription: topbetsUpdatedSubscription,
    variables: {},
    onNext: (data) => {
      if (!data || !bets?.bets) {
        return
      }
      setBets({ bets: JSON.parse(JSON.stringify(data.topBetsUpdated)) })
      betStatisticQueue.next({
        biggestWins: [...data.topBetsUpdated.biggestWins] as UserBet[],
        biggestWinsGlobal: [...data.topBetsUpdated.biggestWinsGlobal] as UserBet[],
        recent: bets.bets.recent,
        highrollers: bets.bets.highrollers,
      })
    },
  })
}

export const reloadBets = () => {
  fetchQuery<BetHistoryComponentBetsQuery>(Env, betsQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
    next: (data) => {
      if (data.bets) {
        const bets = JSON.parse(JSON.stringify(data.bets))
        setBets({ bets })
        betStatisticQueue.next({
          biggestWins: data.bets.biggestWins as UserBet[],
          biggestWinsGlobal: data.bets.biggestWinsGlobal as UserBet[],
          recent: data.bets.recent as UserBet[],
          highrollers: data.bets.highrollers as UserBet[],
        })
      }
    },
  })
}

import { graphql } from 'babel-plugin-relay/macro'

export const loginMutation = graphql`
  mutation loginMutation($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        preferences {
          key
          value
          valueJson
        }
      }
    }
  }
`

import React from 'react'

import { Tier } from './Tier'

import classes from './Tier4.module.scss'

export function Tier4() {
  const image = <img alt="" src="/assets/pages/elite-seat/tier4.png" className={classes.image} />

  return (
    <Tier
      image={image}
      crateImageSRC="/assets/pages/elite-seat/tier4-crate.png"
      text="You reached 1000 BTC in wagers. WOW! You are a true player. More cashback, and even more rakeback. Mistery
    Silver box with bigger rewards and even greater support line."
    >
      <h2 className="font-poppins font-medium h-min">3</h2>
      <div className="flex flex-col transform translate-y-20 -translate-x-20">
        <h3 className="text-sm leading-5 font-bold text-white font-inter">
          TIER 4<br />
          <span className="text-pink">Elite Seat 3</span>
        </h3>
        <p className="text-white">
          VIP
          <br />
          <span className="text-eucalyptus">Wager</span> <span className="text-yellow">100 - 1000 BTC</span>
          <br />
          <span className="text-lg font-bold">
            15% rakeback
            <br />
            10% cashback
          </span>
        </p>
        <p className="text-eucalyptus font-medium">
          Mystery Silver box
          <br />
          Private Support
        </p>
      </div>
    </Tier>
  )
}

import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { serverTimeDiff } from '../App'
import './Timer.scoped.scss'

interface Props extends WithTranslation {
  endTime: number
  done?: () => void
  styleV?: 1 | 2 | 3
  minuteSecondOnly?: boolean
  labelAfter?: string | JSX.Element
  labelBefore?: string | JSX.Element
}

class Timer extends React.Component<Props, { time: string | JSX.Element | null }> {
  private interval: any

  constructor(props: Props) {
    super(props)
    this.state = {
      time: null,
    }
  }

  tick() {
    const now = (new Date().getTime() + serverTimeDiff) / 1000
    const diff = Math.max(0, this.props.endTime - now)
    if (diff <= 0) {
      if (this.props.done) {
        this.props.done()
      }
      clearInterval(this.interval)
    }
    this.setState({ time: this.formatTime(diff) })
  }

  componentWillReceiveProps() {
    clearInterval(this.interval)
    this.interval = setInterval(() => this.tick(), 1000)
    setTimeout(() => {
      this.tick()
    }, 10)
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000)
    this.tick()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  padDigits(numb: number, digits: number) {
    return (Math.pow(10, digits) + ~~numb).toString().substring(1)
  }

  formatTime(seconds: number): string | JSX.Element {
    const { t } = this.props // Access the translation function
    if (this.props.styleV !== 1) {
      let remainingSeconds = seconds
      const days = Math.floor(remainingSeconds / (60 * 60 * 24))
      remainingSeconds -= days * 60 * 60 * 24
      const hours = Math.floor(remainingSeconds / (60 * 60))
      remainingSeconds -= hours * 60 * 60
      const minutes = Math.floor(remainingSeconds / 60)
      remainingSeconds -= minutes * 60
      remainingSeconds = Math.round(remainingSeconds)
      const parts = [days, hours, minutes, remainingSeconds]

      let wasEmpty = true
      const time = ['DAY', 'HR', 'MIN', 'SEC']
        .filter((x) => {
          return !this.props.minuteSecondOnly || (this.props.minuteSecondOnly && ['MIN', 'SEC'].indexOf(x) >= 0)
        })
        .map((x, i) => {
          const val = parts[i + (this.props.minuteSecondOnly ? 2 : 0)]
          if (wasEmpty && val === 0) {
            wasEmpty = true
            return null
          } else {
            wasEmpty = false
          }
          return (
            <React.Fragment key={`${x}-${val}`}>
              <div className="timer-row">
                <div className="label">{t(`time.${x}`, x)}</div>
                <div className="value">{this.padDigits(val, 2)}</div>
              </div>
            </React.Fragment>
          )
        })
      return (
        <div className={`timer-row-container style-${this.props.styleV}`}>
          {this.props.labelBefore}
          <div className="tt">{time}</div>
          {this.props.labelAfter}
        </div>
      )
    } else {
      let hours = Math.floor(seconds / 3600)
      let minutes: any = Math.floor((seconds - hours * 3600) / 60)
      seconds = seconds - hours * 3600 - minutes * 60
      let time = ''

      if (hours !== 0) {
        time = hours + 'h '
      }
      if (minutes !== 0 || time !== '') {
        minutes = minutes < 10 && time !== '' ? '0' + minutes : String(minutes)
        time += minutes + 'm '
      }
      if (time === '') {
        time = Math.round(seconds) + 's'
      } else {
        time += seconds < 10 ? '0' + Math.round(seconds) : String(Math.round(seconds))
        time += 's'
      }
      return time
    }
  }

  render() {
    return <div>{this.state.time}</div>
  }
}

export default withTranslation()(Timer)

/**
 * @generated SignedSource<<9a6089e2dcc2ed68fc43452731b0cac6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PedestalComponentBiggestWinsQuery$variables = {};
export type PedestalComponentBiggestWinsQuery$data = {
  readonly bets: {
    readonly biggestWins: ReadonlyArray<{
      readonly currency: string;
      readonly bets: ReadonlyArray<number>;
      readonly win: number | null;
      readonly btcBet: number | null;
      readonly btcWin: number | null;
      readonly multiplier: number;
      readonly normalizedGame: {
        readonly label: string;
        readonly gameId: string;
        readonly provider: string | null;
        readonly urlThumb: string | null;
      } | null;
      readonly user: {
        readonly uuid: string | null;
        readonly name: string;
        readonly vip: {
          readonly badgeId: string | null;
          readonly offset: number | null;
          readonly avatar: string | null;
        } | null;
        readonly hasProfileImage: boolean;
      };
    }>;
  } | null;
};
export type PedestalComponentBiggestWinsQuery = {
  variables: PedestalComponentBiggestWinsQuery$variables;
  response: PedestalComponentBiggestWinsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BetSummary",
    "kind": "LinkedField",
    "name": "bets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GameBet",
        "kind": "LinkedField",
        "name": "biggestWins",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "win",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "btcBet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "btcWin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multiplier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormalizedGame",
            "kind": "LinkedField",
            "name": "normalizedGame",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gameId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "provider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlThumb",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BetUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Vip",
                "kind": "LinkedField",
                "name": "vip",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "badgeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasProfileImage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PedestalComponentBiggestWinsQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PedestalComponentBiggestWinsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "65417358e22107f80a8ed429741defa9",
    "id": null,
    "metadata": {},
    "name": "PedestalComponentBiggestWinsQuery",
    "operationKind": "query",
    "text": "query PedestalComponentBiggestWinsQuery {\n  bets {\n    biggestWins {\n      currency\n      bets\n      win\n      btcBet\n      btcWin\n      multiplier\n      normalizedGame {\n        label\n        gameId\n        provider\n        urlThumb\n      }\n      user {\n        uuid\n        name\n        vip {\n          badgeId\n          offset\n          avatar\n        }\n        hasProfileImage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "67a5bd338a9987e501360707a093a0ce";

export default node;

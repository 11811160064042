import { commitMutation, fetchQuery, requestSubscription } from 'react-relay'
import {
  joinTableMutation,
  openTableTask,
  tableErrorQueue,
  tableExitedSubscription,
  tablesRemoved,
  tableUpdated,
  tableUpdatedSubscription,
} from '../components/inHouseGames/InHouseGame'
import { InHouseGameJoinTableMutation } from '../components/inHouseGames/__generated__/InHouseGameJoinTableMutation.graphql'
import { InHouseGameTableExitedSubscription } from '../components/inHouseGames/__generated__/InHouseGameTableExitedSubscription.graphql'
import { InHouseGameTableUpdatedSubscription } from '../components/inHouseGames/__generated__/InHouseGameTableUpdatedSubscription.graphql'
import { ModalType, openModalTask } from '../components/modalComponent/modalComponent'
import { listTablesQuery, Table } from '../components/tables/TablesComponent'
import { TablesComponentTablesQuery } from '../components/tables/__generated__/TablesComponentTablesQuery.graphql'
import Env, { GraphQLError } from '../RelayEnvironment'
import ConfigurationService from '../service/ConfigurationService'
import { store } from '../store'
import { Money } from '@src/money'

export enum TableConstant {
  SET_ALL_TABLES = 'SET_ALL_TABLES',
  SET_OPENED_TABLE = 'SET_OPENED_TABLE',
  UPDATE_TABLE = 'UPDATE_TABLE',
  SIT_TABLE = 'SIT_TABLE',
  REMOVE_TABLE = 'REMOVE_TABLE',
}

export interface State {
  tables: Table[]
  openedTable?: Table
}

export interface TableAction {
  type: TableConstant
  table: Table
  tables?: Table[]
  tableUuid?: string
  idx?: number
}

const initialState: State = { tables: [] }

export const multiplayer = (state: State = initialState, action: TableAction): State => {
  switch (action.type) {
    case TableConstant.SET_ALL_TABLES:
      state.tables = [...(action.tables as Table[])]
      return state
    case TableConstant.SET_OPENED_TABLE:
      state.openedTable = action.table
      return state
    case TableConstant.UPDATE_TABLE:
      const tableToUpdate = action.table as Table
      const idx = state.tables.findIndex((x) => x.uuid === tableToUpdate.uuid)
      if (idx < 0) {
        state.tables.push(tableToUpdate)
      } else {
        state.tables[idx] = tableToUpdate
      }
      setTimeout(() => {
        tableUpdated.next(tableToUpdate)
      })
      return state
    case TableConstant.SIT_TABLE:
      const uuid = action.tableUuid as string
      commitMutation<InHouseGameJoinTableMutation>(Env, {
        mutation: joinTableMutation,
        variables: { input: { tableUuid: uuid, slotIdx: action.idx! } },
        onError: (error: GraphQLError) => {
          error.response?.json?.errors?.forEach((x: { message: string }) => {
            if (x.message === 'already_joined') {
              tableErrorQueue.next('Already joined')
            } else if (x.message === 'must_register') {
              tableErrorQueue.next('Only logged in users can join')
              // openMenu.next({onlyLogin: true});
            } else if (x.message === 'table_full') {
              tableErrorQueue.next('Table is full')
            } else if (x.message === 'expired') {
              tableErrorQueue.next('Bonus is already used')
            } else if (x.message === 'tournament_not_started') {
              tableErrorQueue.next('Tournament is not yet started')
            } else if (x.message === 'not_enough_balance') {
              tableErrorQueue.next('Not enough balance to join')
            } else if (x.message === 'seat_taken') {
              tableErrorQueue.next('Somebody already sits here')
            } else if (x.message === 'no_deposits_made') {
              tableErrorQueue.next('You have to make at least one deposit to join')
            } else if (x.message === 'tournament_already_started') {
              tableErrorQueue.next('Tournament is aldready started')
            } else {
              openTableTask.next(null)
              tableErrorQueue.next('Table not found')
            }
          })
        },
      })
      return state
    case TableConstant.REMOVE_TABLE:
      const tableToRemove = action.tableUuid as string
      const removed = state.tables.filter((x) => x.uuid === tableToRemove)
      state.tables = state.tables.filter((x) => x.uuid !== tableToRemove)
      if (state.openedTable?.uuid === tableToRemove) {
        state.openedTable = undefined
      }
      setTimeout(() => {
        tablesRemoved.next(removed)
      })
      return state
    default:
      return state
  }
}

export const init = () => {
  fetchQuery<TablesComponentTablesQuery>(Env, listTablesQuery, { page: 1 }).subscribe({
    next: (data) => {
      store.dispatch({ type: TableConstant.SET_ALL_TABLES, tables: data.tables })
    },
  })
  requestSubscription<InHouseGameTableUpdatedSubscription>(Env, {
    subscription: tableUpdatedSubscription,
    variables: {},
    onNext: (data) => {
      if (data?.tableUpdated) {
        const event = data?.tableUpdated as unknown as Table
        store.dispatch({ type: TableConstant.UPDATE_TABLE, table: event })
      }
    },
  })
  requestSubscription<InHouseGameTableExitedSubscription>(Env, {
    subscription: tableExitedSubscription,
    variables: {},
    onNext: (data: any) => {
      if (data?.tableExited.uuid) {
        const tableExited = data?.tableExited as { uuid: string }
        store.dispatch({ type: TableConstant.REMOVE_TABLE, tableUuid: tableExited.uuid })
      } else {
        const tableExited = data?.tableExited as {
          winner: string
          virtual_id: number
          winner_name: string
          fund: number
        }
        if (undefined !== tableExited?.winner) {
          const openedTable = store.getState().multiplayer.openedTable
          if (openedTable?.virtualId === tableExited.virtual_id) {
            const username = tableExited.winner_name ? tableExited.winner_name : tableExited.winner.substring(0, 8)
            const amount = formatTableAmount(openedTable, tableExited.fund)
            openModalTask.next({ type: ModalType.winner, winnerModal: { amount, username } })
          }
        }
      }
    },
  })
}

export const formatTableAmount = (table: Table, amount: number): string => {
  if (undefined === table) {
    return ''
  }
  const currency = ConfigurationService.instance.getCurrency(
    table.type === 'FREEROLL' ? table.meta?.freeroll_win_currency : table.currency
  )
  return Money.convertUnit(amount, currency.m_unit, currency.s_unit, currency.format) + currency.format
}

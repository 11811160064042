import { useMutation } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'

import classes from './AdminActions.module.scss'

export function AdminActions({
  hash,
  setMessages,
  from_uuid,
  from_muted_user,
  hideDelete = false,
  handleMessageDelete,
}: {
  hash: string
  setMessages: React.Dispatch<React.SetStateAction<any[]>>
  from_uuid: string
  from_muted_user?: boolean
  hideDelete?: boolean
  handleMessageDelete: (hash: string) => void
}) {
  const [muteUser] = useMutation(muteUserMutation)

  return (
    <div className={classes.root}>
      <button
        type="button"
        className="text-pink"
        onClick={() => {
          muteUser({
            variables: {
              input: {
                uuid: from_uuid,
                set: !from_muted_user,
              },
            },
            onCompleted: () => {
              setMessages((prev) => {
                return prev.map((message) => {
                  if (message.from_uuid === from_uuid) {
                    return {
                      ...message,
                      from_muted_user: !from_muted_user,
                    }
                  }
                  return message
                })
              })
            },
          })
        }}
      >
        {from_muted_user ? 'Unmute' : 'Mute'}
      </button>
      {!hideDelete && (
        <button
          type="button"
          className="text-pink"
          onClick={() => {
            handleMessageDelete(hash)
          }}
        >
          Delete
        </button>
      )}
    </div>
  )
}

const muteUserMutation = graphql`
  mutation AdminActionsMuteMutation($input: ModeratorMuteUserInput!) {
    moderatorMuteUser(input: $input) {
      user {
        uuid
      }
    }
  }
`

export const deleteMessageMutation = graphql`
  mutation AdminActionsDeleteMutation($input: ModeratorDeleteMessageInput!) {
    moderatorDeleteMessage(input: $input) {
      ok
    }
  }
`

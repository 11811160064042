import React from 'react'
import { WalletView } from './WalletModal'
import { DepositView } from './DepositView'
import { HistoryView } from './HistoryView'
import { WithdrawView } from './WithdrawView'
import { ListView } from './ListView'
import './WalletTab.scoped.scss'
import { ExternalDepositView } from './ExternalDepositView'
import { useTranslation, Trans } from 'react-i18next'

export const WalletTab = ({ view, openView, openListView, activeView, back, setPopup }) => {
  const { t } = useTranslation()

  const renderBody = () => {
    switch (view) {
      case WalletView.deposit:
        return <DepositView openListView={() => openListView()} />
      case WalletView.withdraw:
        return <WithdrawView openListView={() => openListView()} setPopup={setPopup} />
      case WalletView.history:
        return <HistoryView setPopup={setPopup} />
      case WalletView.list:
        return <ListView back={() => back()} />
      case WalletView.externalDeposit:
        return <ExternalDepositView />
    }
  }

  return (
    <>
      <div className="wallet-body">
        <div className="wallet-nav">
          <button
            className={`wallet-nav-button ${activeView() === WalletView.deposit ? 'active' : ''}`}
            onClick={() => openView(WalletView.deposit)}
          >
            {t('wallet.walletTab.deposit', 'Deposit')}
          </button>
          {/*
              <button
              className={activeView() === WalletView.externalDeposit ? 'active' : ''}
              onClick={() => openView(WalletView.externalDeposit)}
            >
              External deposit
            </button>
            */}
          <button
            className={`wallet-nav-button ${activeView() === WalletView.withdraw ? 'active' : ''}`}
            onClick={() => openView(WalletView.withdraw)}
          >
            {t('wallet.walletTab.withdraw', 'Withdraw')}
          </button>
          <button
            className={`wallet-nav-button ${activeView() === WalletView.history ? 'active history' : 'history'}`}
            onClick={() => openView(WalletView.history)}
          >
            {t('wallet.walletTab.transactionHistory', 'Transaction history')}
          </button>
        </div>
        {renderBody()}
      </div>
      <div className="footer">
        <Trans i18nKey="wallet.walletTab.footer">
          For better security we strongly recommend to <b>Enable 2FA.</b>
        </Trans>
      </div>
    </>
  )
}

/**
 * @generated SignedSource<<f358ebd8aaf98503edb197fae7236e92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type WithdrawViewComponentEstimateFeeQuery$variables = {
  currency: Currency;
  address?: string | null;
  tokenId?: string | null;
};
export type WithdrawViewComponentEstimateFeeQuery$data = {
  readonly estimateFee: ReadonlyArray<{
    readonly blocks: number | null;
    readonly feerate: number | null;
  }>;
};
export type WithdrawViewComponentEstimateFeeQuery = {
  variables: WithdrawViewComponentEstimateFeeQuery$variables;
  response: WithdrawViewComponentEstimateFeeQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currency"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "address",
        "variableName": "address"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "tokenId",
        "variableName": "tokenId"
      }
    ],
    "concreteType": "Fee",
    "kind": "LinkedField",
    "name": "estimateFee",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blocks",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feerate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WithdrawViewComponentEstimateFeeQuery",
    "selections": (v3/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "WithdrawViewComponentEstimateFeeQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "34b1c4a5ce8c977e9bd3380576019629",
    "id": null,
    "metadata": {},
    "name": "WithdrawViewComponentEstimateFeeQuery",
    "operationKind": "query",
    "text": "query WithdrawViewComponentEstimateFeeQuery(\n  $currency: Currency!\n  $address: String\n  $tokenId: String\n) {\n  estimateFee(currency: $currency, address: $address, tokenId: $tokenId) {\n    blocks\n    feerate\n  }\n}\n"
  }
};
})();

(node as any).hash = "c423422fcb1882a6bdf43afd6fe36370";

export default node;

import * as React from 'react'
import { errorqueue, serverTimeDiff, setTheme } from '../../App'
import classNames from 'classnames'

import './game.scss'
import { UserGameRenderer } from './GameRenderer'
import { diff } from './GameDiff'
import ConfigurationService, { Currency } from '../../service/ConfigurationService'
import { store } from '../../store'
import Timer from '../Timer'
import { Wallet, WalletConstant, activeWalletUpdated } from '../../reducers/wallet.reducer'
import * as H from 'history'
import { seedUsed } from '../provablyFairComponent/ProvablyFairComponent'
import SoundManager, { Sound } from '../../SoundManager'
import {
  openTableTask,
  tabledJoined,
  getGameQuery,
  newGameMutation,
  startGameMutation,
  setBetMutation,
  gameMoveMutation,
  tableErrorQueue,
  updateTableListTask,
  tableUpdated,
  joinTableTask,
} from './InHouseGame'
import { commitMutation, fetchQuery } from 'react-relay'
import Env, { GraphQLError } from '../../RelayEnvironment'
import { InHouseGameGetGameQuery } from './__generated__/InHouseGameGetGameQuery.graphql'
import { InHouseGameNewGameMutation } from './__generated__/InHouseGameNewGameMutation.graphql'
import { InHouseGameStartGameMutation } from './__generated__/InHouseGameStartGameMutation.graphql'
import { InHouseGameGameMoveMutation } from './__generated__/InHouseGameGameMoveMutation.graphql'
import formatError from '../errorFormatter'
import { TableConstant } from '../../reducers/multiplayer.reducer'
import { Player, Table } from '../tables/TablesComponent'
import { UserProp, userUpdated } from '../../reducers/authentication.reducer'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import { ClientSeed } from './ClientSeed'
import { Money } from '@src/money'

export enum GameState {
  // Single player
  IDLE = 0,
  CONFIGURE = 1,
  PLAY = 2,
  RESULTS = 3,
  LOADING = 4,
}

export enum GameMove {
  HIT = 'hit',
  STAND = 'stand',
  SPLIT = 'split',
  DOUBLE_DOWN = 'double_down',
  INSURANCE = 'insurance',
  PERK = 'game_perk',
  REBET_X1 = 'rebetx1',
  REBET_X2 = 'rebetx2',
  CHANGE_BET = 'change_bet',
  DEAL = 'deal',
  CLEAR = 'clear',
  SIT = 'sit',

  // Not sent to server
  PRE_HIT = 'pre_hit',
  PRE_STAND = 'pre_stand',
  PRE_SPLIT = 'pre_split',
  PRE_DOUBLE_DOWN = 'pre_double_down',
  PRE_INSURANCE = 'pre_insurance',
}

export enum GamePerkEnum {
  SHOW_DEALER_CARD = 1,
  SHOW_NEXT_CARD = 2,
  REPLACE_CARD = 3,
}

export interface Game {
  slots: GameSlot[]
  state: number
  sp: number
  insured: number
  serverSeedHash: string
  gameId: string
  userId?: string
  userNick?: string
  summary?: GameSummary
  validMoves: ValidMoves
  currency: string
  moveId: string
  virtualId: number
  mp: boolean
}

export interface GameCard {
  flipped: number
  suit: string
  rank: string
}

interface ValidMoves {
  canSplit: boolean
  canDoubleDown: boolean
  canInsurance: boolean
  canHit: boolean
}

export interface GameSummary {
  server_seed: string
  server_seed_hash: string
  client_seed: string
  final_shuffle: string
  final_shuffle_hash: string
  winnings: number
  details?: { id: number; winning: number; uuid: string; userIdx: number }[]
}

export interface GameSlot {
  slotId: number
  gid: number
  bet: number
  cards: GameCard[]
  parentId: number
  userUuid: string
  userName: string
  userIdx: number
  result: 'win' | 'lose' | 'push' | null
}

interface GamePageState {
  game?: Game
  openedTable?: Table
}

interface GameProps {
  history: H.History
}

class GamePage extends React.Component<GameProps, GamePageState> {
  private seed: string | undefined
  private subscriptions: { unsubscribe: () => void }[] = []
  private gameRenderer: UserGameRenderer

  constructor(props: GameProps) {
    super(props)
    this.gameRenderer = new UserGameRenderer()
    this.initGameRenderer()
    this.state = {}
  }

  initGameRenderer() {
    this.gameRenderer = new UserGameRenderer()
    this.gameRenderer.onEvent.subscribe((event) => {
      if (event.action === 'button_click') {
        switch (event.parameter) {
          case GameMove.STAND:
          case GameMove.HIT:
          case GameMove.DOUBLE_DOWN:
          case GameMove.INSURANCE:
          case GameMove.SPLIT:
            this.makeGameMove(event.parameter, event.extra)
            break
          case GameMove.CHANGE_BET:
            this.newGame()
            break
          case GameMove.REBET_X1:
          case GameMove.REBET_X2:
            this.rebet(event.extra.bet)
            break
          case GameMove.SIT:
            console.log(event)
            this.joinTable(event.extra.table, event.extra.slotIdx)
            break
          default:
            break
        }
      }
      if (event.action === 'start_game') {
        this.startGame(event.parameter)
      }
    })
  }

  componentDidCatch(e: any, info: any) {
    errorqueue.next(e)
  }

  componentWillUnmount() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe()
    })
    this.subscriptions = []
    this.gameRenderer?.deconstructor()
    setTheme()
  }

  componentDidMount() {
    const path = window.location.pathname
    const gameId = path.substring(15)
    const urlParams = new URLSearchParams(window.location.search)
    const forceParam = urlParams.get('f')
    if (path.startsWith('/game/bitcoin-blackjack/table/')) {
      const tableUUID = path.substring(path.lastIndexOf('/') + 1)
      let i = 0
      const setTable = (retryCount?: number) => {
        if (retryCount && retryCount > 15) {
          this.props.history.push('/')
          return
        }
        const allTables = store.getState().multiplayer.tables
        if (allTables.length === 0) {
          setTimeout(() => {
            setTable(i++)
          }, 100)
          return
        }
        const table = allTables.find((x) => x.uuid === tableUUID)
        if (table) {
          store.dispatch({ type: TableConstant.SET_OPENED_TABLE, table })
          if (table.game) {
            store.dispatch({ type: WalletConstant.SET_GAME, game: table.game })
          }
          this.setState({ openedTable: table, game: table.game })
          this.gameRenderer.setTable(table)
        } else {
          this.props.history.push('/')
          return
        }
      }
      setTable()
    } else if (path.startsWith('/game/blackjack') && gameId.length > 0) {
      this.setState({})
      this.loadGameById(gameId)
    } else if (forceParam) {
      this.newGame(true)
    } else {
      this.newGame()
    }

    this.subscriptions.push(
      tableUpdated.subscribe((event) => {
        if (undefined !== event) {
          if (this.state.openedTable && this.state.openedTable.uuid === event.uuid) {
            if (
              this.state.game &&
              this.state.game.gameId &&
              event.game &&
              event.game.gameId === this.state.game.gameId
            ) {
              this.gameRenderer.table = event
              const d = diff(this.state.game, event.game, this.state.openedTable, event)
              this.gameRenderer.processDiff(event.game, d)
            } else {
              this.gameRenderer.setTable(event)
            }
            this.setState({ game: event.game, openedTable: event })
          }
        }
      })
    )

    this.subscriptions.push(
      tabledJoined.subscribe((table: Table) => {
        this.setState({ openedTable: table })
        if (null !== table.game && undefined !== table.game) {
          this.setGame(table.game)
        }
      })
    )

    this.subscriptions.push(
      openTableTask.subscribe((table) => {
        if (undefined === table || null === table) {
          this.closeTable()
          return
        }
        this.setState({
          openedTable: table,
        })
        this.setState({ openedTable: table, game: (table as Table).game })
        this.gameRenderer.setTable(table)
      })
    )
    this.subscriptions.push(
      userUpdated.subscribe(() => {
        this.setState({})
      })
    )

    this.subscriptions.push(
      activeWalletUpdated.subscribe(() => {
        if (this.state.game?.currency !== store.getState().wallet.activeWallet?.currency) {
          this.reload()
        }
      })
    )
  }

  loadGameById = (id: string) => {
    if (id === undefined || id === 'undefined') {
      this.newGame()
      return
    }
    fetchQuery<InHouseGameGetGameQuery>(Env, getGameQuery, { gameId: id }, { fetchPolicy: 'network-only' }).subscribe({
      next: (data: any) => {
        this.gameRenderer.deconstructor()
        this.initGameRenderer()
        this.setState({
          game: data.game_by_id.game,
        })
        store.dispatch({ type: WalletConstant.SET_GAME, game: data.game_by_id.game })
        this.gameRenderer.setGame(data.game_by_id.game)
      },
      error: (error: GraphQLError) => {
        console.error(error)
        this.newGame()
      },
    })
  }

  reload(): void {
    if (this.state.game) {
      const selectedWallet = store.getState().wallet.activeWallet as Wallet
      if (
        this.state.game.currency === selectedWallet.currency &&
        this.state.game.virtualId === selectedWallet.virtualId
      ) {
        this.gameRenderer.setGame(this.state.game)
      } else {
        this.newGame()
      }
    } else if (this.state.openedTable) {
      this.gameRenderer.setTable(this.state.openedTable)
    }
  }

  closeTable() {
    if (undefined !== this.state.openedTable) {
      // window.history.pushState(null, '', '/');
      setTheme()
      this.gameRenderer.deconstructor()
      this.setState({ openedTable: undefined })
      this.props.history.push('/')
    }
  }

  newGame(force?: boolean) {
    const wallet = store.getState().wallet.activeWallet
    if (!wallet) {
      setTimeout(() => {
        this.newGame(force)
      }, 100)
      return
    }
    commitMutation<InHouseGameNewGameMutation>(Env, {
      mutation: newGameMutation,
      variables: {
        input: {
          currency: wallet.currency,
          virtualId: wallet.virtualId,
          force,
        },
      },
      onCompleted: ({ newGame }) => {
        this.gameRenderer.setGame(newGame?.game as unknown as Game)
        this.setGame(newGame?.game as unknown as Game)
      },
      onError: (err: any) => {
        console.error(err)
      },
    })
  }

  getGameState(game: Game | undefined): GameState {
    if (!game) {
      return GameState.IDLE
    }
    switch (+game.state) {
      case 0:
      case 10:
      case 20:
      case 30:
        return GameState.CONFIGURE
      case 40:
        return GameState.PLAY
      case 50:
        return GameState.RESULTS
      default:
        return GameState.IDLE
    }
  }

  startGame = (params: { game_id?: string | undefined; bet: number; table_uuid?: string }) => {
    if (!this.state.game) {
      return
    }

    const _seed = localStorage.getItem('next_seed')
    if (null != _seed) {
      this.seed = _seed
      const autoGenerate = localStorage.getItem('seed_generate')
      if (autoGenerate !== 'false') {
        localStorage.removeItem('next_seed')
        seedUsed.next()
      }
    } else {
      this.seed = generateRandomString(40)
    }

    const mp = undefined !== params.table_uuid
    if (!mp) {
      this.setState({
        game: undefined,
      })
    }

    if (mp) {
      commitMutation(Env, {
        mutation: setBetMutation,
        variables: {
          input: {
            table_uuid: params.table_uuid,
            seed: this.seed,
            bet: params.bet,
          },
        },
      })
    } else {
      const currency = store.getState().wallet.activeWallet as Wallet
      commitMutation<InHouseGameStartGameMutation>(Env, {
        mutation: startGameMutation,
        variables: {
          input: {
            seed: this.seed,
            bet: params.bet,
            gameId: params.game_id,
            currency: currency.currency,
            virtualId: currency.virtualId,
          },
        },
        onCompleted: ({ startGame }) => {
          // this.props.history.replace('/game/blackjack/' + data.start_game.game.game_id);
          const game = startGame?.game as unknown as Game
          const d = diff(this.state.game as Game, game)
          this.gameRenderer.processDiff(game, d)
          this.setState({
            game,
          })
          store.dispatch({ type: WalletConstant.SET_GAME, game })
        },
        onError: () => {
          this.newGame()
        },
      })
    }
  }

  makeGameMove = (moveName: string, args?: {}, cb?: (data: any) => void) => {
    if (!this.state.game) {
      return
    }
    SoundManager.playSound(Sound.BUTTON_CLICK)
    const mp = undefined !== this.state.openedTable
    let input = mp
      ? {
          table_uuid: this.state.openedTable?.uuid,
          move: moveName,
        }
      : {
          game_id: this.state.game.gameId,
          move: moveName,
        }
    Object.assign(input, args)

    commitMutation<InHouseGameGameMoveMutation>(Env, {
      mutation: gameMoveMutation,
      variables: { input },
      onCompleted: ({ gameMove }) => {
        if (cb) {
          cb(gameMove)
        }
        if (!mp) {
          const game = (mp ? gameMove?.table?.game : gameMove?.game) as unknown as Game
          if (undefined === game) {
            return
          }
          const d = diff(this.state.game as Game, game)
          this.gameRenderer.processDiff(game, d)
          this.setState({
            game: game,
          })
        }
      },
      onError: (error) => {
        console.log('Catching')
        console.dir(error)
        const errs = formatError(error)
        if (errs.findIndex((x) => x === 'no_game') >= 0) {
          this.newGame()
        }
        if (errs.findIndex((x) => x === 'table_not_found') >= 0) {
          this.closeTable()
          tableErrorQueue.next('Table is already closed')
          updateTableListTask.next()
        }
      },
    })
  }

  rebet(bet: number) {
    this.startGame({ game_id: undefined, bet: bet })
  }

  getBetLimits() {
    let selectedCurrency

    if (this.state.openedTable) {
      selectedCurrency = this.state.openedTable.currency
    } else if (this.state.game?.currency) {
      selectedCurrency = this.state.game.currency
    } else {
      const selectedWallet = store.getState().wallet.activeWallet
      if (selectedWallet === undefined) {
        return [0, 0, {}]
      }
      selectedCurrency = selectedWallet.currency
    }

    const c = ConfigurationService.instance.getCurrency(selectedCurrency)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    let mMin
    let mMax
    if (undefined !== this.state.openedTable) {
      const limits = ConfigurationService.instance.getBetLimitsMp(c)
      if (limits.min === 0 && limits.max === Number.MAX_VALUE) {
        mMin = limits.min
        mMax = limits.max
      } else {
        mMin = Money.convertUnit(limits.min, c.m_unit, c.s_unit, format)
        mMax = Money.convertUnit(limits.max, c.m_unit, c.s_unit, format)
      }
    } else {
      const limits = ConfigurationService.instance.getBetLimits(c)
      if (limits.min === 0 && limits.max === Number.MAX_VALUE) {
        mMin = limits.min
        mMax = limits.max
      } else {
        mMin = Money.convertUnit(limits.min, c.m_unit, c.s_unit, format)
        mMax = Money.convertUnit(limits.max, c.m_unit, c.s_unit, format)
      }
    }
    return [mMin, mMax, c, format]
  }

  formatBetLimits() {
    const [mMin, mMax, c, format] = this.getBetLimits() as [number, number, Currency, string]
    if (mMin === 0 && mMax === Number.MAX_VALUE) {
      return null
    }
    const cFormat = c.short === 'FUN' ? 'FUN' : format
    return (
      <div className="bet-limits">
        <span>BET LIMITS:</span>
        <span>
          Min: {cFormat} {mMin}
        </span>
        <span>
          Max: {cFormat} {mMax}
        </span>
      </div>
    )
  }

  startNewGame = () => {
    this.newGame(true)
  }

  joinTable = (table: string, slotIdx: number) => {
    // store.dispatch({type: TableConstant.SIT_TABLE, tableUuid: table, idx: slotIdx})
    joinTableTask.next({ table: table, idx: slotIdx })
  }

  setGame(game: Game) {
    store.dispatch({ type: WalletConstant.SET_GAME, game })
    this.setState({
      game: game,
    })
    // console.log('setGame', '/game/blackjack/' + game.gameId, game);
    // this.props.history.replace('/game/blackjack/' + game.gameId);
  }

  toggleMoreGames() {
    const e = document.querySelector('.more-games-container')
    if (!e) {
      return
    }
    if (e.classList.toggle('open')) {
      e.classList.remove('cclose')
    } else {
      e.classList.add('cclose')
    }
  }

  render() {
    let circle: JSX.Element | null = null
    const state = this.getGameState(this.state.game)
    const mp = this.state.game && this.state.game.mp === true
    if (mp && state === GameState.IDLE) {
      circle = (
        <div className="circle">
          <span>
            Place your <span className="yellow">bet</span>
          </span>
        </div>
      )
    } else if (this.state.openedTable && this.state.openedTable.players.length === 0) {
      circle = (
        <div className="circle">
          <span>
            Waiting <span className="yellow">players</span>
          </span>
        </div>
      )
    } else if (state === GameState.CONFIGURE || state === GameState.IDLE) {
      circle = (
        <div className="circle">
          <span>
            Place your <span className="yellow">bet</span>
          </span>
        </div>
      )
    } else {
      circle = (
        <div className="circle">
          <span className="insurance">Insurance</span>
          <span>
            Pays <span className="yellow">2 to 1</span>
          </span>
        </div>
      )
    }

    let c = classNames({
      'game-page': true,
      'state-configure': state === GameState.CONFIGURE,
      mp: this.state.openedTable,
    })

    const perkContainer =
      undefined === this.state.openedTable
        ? /*<PerkContainer
        game={this.state.game}
        makeGameMove={this.makeGameMove}
        reload={this.walletChanged}
        gameRenderer={this.gameRenderer}
      />*/ null
        : null

    let tournTimer
    const now = (new Date().getTime() + serverTimeDiff) / 1000
    const tournamentEnd = this.state.openedTable?.meta?.end_time
    const tournamentStart = this.state.openedTable?.meta?.start_time
    let helper: JSX.Element | null = null
    if (now < tournamentStart) {
      tournTimer = (
        <span className="tournament-end">
          Tournament starts in: <Timer done={() => this.setState({})} endTime={tournamentStart} />
        </span>
      )
    } else if (now < tournamentEnd) {
      const vh = window.innerHeight
      const vw = window.innerWidth
      const mobile = vh < 800 || vw < 800

      if (mobile) {
        if (this.state.openedTable?.type === 'BONUS_TABLE') {
          tournTimer = (
            <span className="tournament-end bl">
              <Timer done={() => this.setState({})} endTime={tournamentEnd} />
            </span>
          )
        } else {
          tournTimer = (
            <span className="tournament-end bl">
              <Timer done={() => this.setState({})} endTime={tournamentEnd} />
            </span>
          )
        }
      } else {
        if (this.state.openedTable?.type === 'BONUS_TABLE') {
          tournTimer = (
            <span className="tournament-end">
              Table ends in: <Timer done={() => this.setState({})} endTime={tournamentEnd} />
            </span>
          )
        } else {
          tournTimer = (
            <span className="tournament-end">
              Tournament ends in: <Timer done={() => this.setState({})} endTime={tournamentEnd} />
            </span>
          )
        }
      }
    } else if (now > tournamentEnd) {
      if (this.state.openedTable?.type === 'BONUS_TABLE') {
        if (null !== this.state.openedTable.game) {
          tournTimer = <span className="tournament-end">Table will close after last game is finished</span>
        } else {
          tournTimer = <span className="tournament-end">Table is closed</span>
        }
      } else {
        tournTimer = <span className="tournament-end">Tournament will end when last game is finished</span>
      }
    }
    if (
      now >= tournamentStart &&
      this.state.openedTable?.type === 'SIT_AND_GO' &&
      this.state.openedTable?.gameCount === 0 &&
      this.state.openedTable?.players?.length < this.state.openedTable?.maxPlayers
    ) {
      helper = <span className="tournament-end">Game will start when all players are joined</span>
      tournTimer = null
    }

    let leaderboard: JSX.Element | null = null
    const leaderboardTypes = ['TOURNAMENT_TABLE', 'SIT_AND_GO', 'FREEROLL']
    if (this.state.openedTable && leaderboardTypes.indexOf(this.state.openedTable.type) >= 0) {
      const c = ConfigurationService.instance.getCurrency(this.state.openedTable.currency)
      const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
      const cFormat = this.state.openedTable.currency === 'FUN' ? 'FUN' : format
      const allTables = store.getState().multiplayer.tables
      const players = allTables.reduce((acc, table) => {
        if (table.virtualId === this.state.openedTable?.virtualId) {
          return [...acc, ...table.players]
        }
        return acc
      }, [] as Player[])
      const uniquePlayers: Player[] = []
      for (let i = 0; i < players.length; i++) {
        const e = players[i]
        const idx = uniquePlayers.findIndex((x) => x.playerUuid === e.playerUuid)
        if (idx < 0) {
          uniquePlayers.push(e)
        }
      }

      const rows = uniquePlayers
        .sort((a, b) =>
          a.playerBalance === b.playerBalance ? 0 : (a.playerBalance || 0) > (b.playerBalance || 0) ? -1 : 1
        )
        .map((player) => {
          const name =
            player.playerName && player.playerName.length > 0 ? player.playerName : player.playerUuid.substring(0, 8)
          let balance = null
          if (player.playerBalance) {
            balance = Money.convertUnit(player.playerBalance, c.m_unit, c.s_unit, format) + cFormat
          }
          return (
            <div className="entry" key={player.slotIdx.toString() + player.playerUuid}>
              <span>{name}</span>
              <span> - </span>
              <span>{balance}</span>
            </div>
          )
        })
      leaderboard = (
        <div className="g-leaderboard">
          <h3>Leaderboard</h3>
          {rows}
        </div>
      )
    }
    return (
      <div className={c}>
        <div className="more-games-container">
          <div className="more-games-head" onClick={this.toggleMoreGames}>
            PROVABLY FAIR
          </div>
          <ClientSeed />
        </div>
        <div className="perk-container">{perkContainer}</div>
        {circle}
        <div className="rules no-wrap">
          <span>{this.formatBetLimits()}</span>
          <div className="bjp3t2">
            <span>Blackjack</span>
            <span>Pays 3 to 2</span>
          </div>
          <div className="dmhs17">
            <span>Dealer must hit soft 17</span>
          </div>
        </div>
        {tournTimer}
        {helper}
        {leaderboard}
        {this.gameRenderer.render()}
      </div>
    )
  }
}

const dec2hex = (dec: number) => {
  return ('0' + dec.toString(16)).substr(-2)
}

const generateRandomString = (length: number) => {
  var arr = new Uint8Array(length)
  const crypto = window.crypto || (window as any).msCrypto
  crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export { GamePage }

/**
 * @generated SignedSource<<43f3016b66a431c3821be6bf64db47cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type GameTableType = "REGULAR" | "BONUS_TABLE" | "TOURNAMENT_TABLE" | "SIT_AND_GO" | "FREEROLL" | "TOURNAMENT" | "%future added value";
export type InHouseGameTableUpdatedSubscription$variables = {};
export type InHouseGameTableUpdatedSubscription$data = {
  readonly tableUpdated: {
    readonly uuid: string;
    readonly players: ReadonlyArray<{
      readonly slotIdx: number;
      readonly playerUuid: string;
      readonly playerName: string | null;
      readonly playerBalance: number | null;
      readonly kicked: boolean | null;
    }>;
    readonly maxPlayers: number;
    readonly currency: string;
    readonly type: GameTableType;
    readonly virtualId: number;
    readonly state: string;
    readonly speed: number;
    readonly timer: number | null;
    readonly name: string;
    readonly meta: any;
    readonly gameCount: number;
    readonly tableIndex: number;
    readonly game: {
      readonly state: string | null;
      readonly gameId: string | null;
      readonly sp: number | null;
      readonly mp: boolean | null;
      readonly splitCount: number | null;
      readonly insured: number | null;
      readonly serverSeedHash: string | null;
      readonly currency: string | null;
      readonly virtualId: number | null;
      readonly validMoves: {
        readonly canSplit: boolean | null;
        readonly canDoubleDown: boolean | null;
        readonly canInsurance: boolean | null;
        readonly canHit: boolean | null;
      } | null;
      readonly summary: {
        readonly serverSeed: string | null;
        readonly serverSeedHash: string | null;
        readonly clientSeed: string | null;
        readonly finalShuffle: string | null;
        readonly finalShuffleHash: string | null;
        readonly winnings: number | null;
        readonly details: ReadonlyArray<{
          readonly userIdx: number | null;
          readonly slotId: number;
          readonly winning: number;
        } | null> | null;
      } | null;
      readonly slots: ReadonlyArray<{
        readonly slotId: number;
        readonly parentId: number | null;
        readonly bet: number | null;
        readonly result: string | null;
        readonly userUuid: string | null;
        readonly userName: string | null;
        readonly userIdx: number | null;
        readonly cards: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};
export type InHouseGameTableUpdatedSubscription = {
  variables: InHouseGameTableUpdatedSubscription$variables;
  response: InHouseGameTableUpdatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "virtualId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serverSeedHash",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userIdx",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GameTable",
    "kind": "LinkedField",
    "name": "tableUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "players",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slotIdx",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playerUuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playerName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playerBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kicked",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxPlayers",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "speed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gameCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tableIndex",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Game",
        "kind": "LinkedField",
        "name": "game",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "splitCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insured",
            "storageKey": null
          },
          (v3/*: any*/),
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidMoves",
            "kind": "LinkedField",
            "name": "validMoves",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canSplit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canDoubleDown",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canInsurance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canHit",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GameSummary",
            "kind": "LinkedField",
            "name": "summary",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serverSeed",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientSeed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finalShuffle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finalShuffleHash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "winnings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WinningDetail",
                "kind": "LinkedField",
                "name": "details",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "winning",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GameSlot",
            "kind": "LinkedField",
            "name": "slots",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "result",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userUuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userName",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GameCard",
                "kind": "LinkedField",
                "name": "cards",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rank",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flipped",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InHouseGameTableUpdatedSubscription",
    "selections": (v6/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InHouseGameTableUpdatedSubscription",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "4afe277c8e60b77533ba1167c4bd70be",
    "id": null,
    "metadata": {},
    "name": "InHouseGameTableUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription InHouseGameTableUpdatedSubscription {\n  tableUpdated {\n    uuid\n    players {\n      slotIdx\n      playerUuid\n      playerName\n      playerBalance\n      kicked\n    }\n    maxPlayers\n    currency\n    type\n    virtualId\n    state\n    speed\n    timer\n    name\n    meta\n    gameCount\n    tableIndex\n    game {\n      state\n      gameId\n      sp\n      mp\n      splitCount\n      insured\n      serverSeedHash\n      currency\n      virtualId\n      validMoves {\n        canSplit\n        canDoubleDown\n        canInsurance\n        canHit\n      }\n      summary {\n        serverSeed\n        serverSeedHash\n        clientSeed\n        finalShuffle\n        finalShuffleHash\n        winnings\n        details {\n          userIdx\n          slotId\n          winning\n        }\n      }\n      slots {\n        slotId\n        parentId\n        bet\n        result\n        userUuid\n        userName\n        userIdx\n        cards {\n          rank\n          suit\n          flipped\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "60dc77b5b29558812ab819c7691c7598";

export default node;

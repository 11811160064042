/**
 * @generated SignedSource<<2e9c1115740bcb48cc04c7d7989503ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationType = "FREESPIN_ADDED" | "NEW_BONUS_ADDED" | "USER_LOGIN" | "MANUAL_CREDIT" | "MANUAL_CASHBACK" | "DIRECT_RAIN" | "BANKROLL_PAYOUT" | "RAKEBACK" | "DIRECT_COMPETITION_CREDIT" | "DEPOSIT_BONUS" | "COMPETITION_VOTING_PAYOUT" | "AFFILIATE_PAYOUT" | "WAGER_LEVEL_REACHED_BONUS" | "CHAT_GIVEAWAY" | "%future added value";
export type ListNotificationsFilter = {
  type?: string | null;
};
export type NotificationsListNotificationsQuery$variables = {
  filter: ListNotificationsFilter;
  page: number;
  pageSize?: number | null;
};
export type NotificationsListNotificationsQuery$data = {
  readonly listNotifications: {
    readonly pageNumber: number | null;
    readonly pageSize: number | null;
    readonly totalEntries: number | null;
    readonly totalPages: number | null;
    readonly entries: ReadonlyArray<{
      readonly notificationId: number;
      readonly type: NotificationType;
      readonly readTime: string | null;
      readonly meta: any | null;
      readonly insertedAt: string;
    }>;
  };
};
export type NotificationsListNotificationsQuery = {
  variables: NotificationsListNotificationsQuery$variables;
  response: NotificationsListNotificationsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      }
    ],
    "concreteType": "PaginatedNotificationsPage",
    "kind": "LinkedField",
    "name": "listNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pageNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pageSize",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalEntries",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PaginatedNotifications",
        "kind": "LinkedField",
        "name": "entries",
        "plural": true,
        "selections": [
          {
            "alias": "notificationId",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insertedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsListNotificationsQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsListNotificationsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e196e39cef1fb7434f9ee0188b4f0798",
    "id": null,
    "metadata": {},
    "name": "NotificationsListNotificationsQuery",
    "operationKind": "query",
    "text": "query NotificationsListNotificationsQuery(\n  $filter: ListNotificationsFilter!\n  $page: Int!\n  $pageSize: Int\n) {\n  listNotifications(filter: $filter, page: $page, pageSize: $pageSize) {\n    pageNumber\n    pageSize\n    totalEntries\n    totalPages\n    entries {\n      notificationId: id\n      type\n      readTime\n      meta\n      insertedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25cac7456bf482c5d0526d8ec92724fe";

export default node;

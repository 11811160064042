import { graphql } from 'babel-plugin-relay/macro'
import { useTranslation } from 'react-i18next'
import { faCaretLeft, faClose, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { fetchQuery, useMutation } from 'react-relay'
import { interval } from 'rxjs'
import { formatBalance } from '../../reducers/wallet.reducer'
import ConfigurationService from '../../service/ConfigurationService'
import { store } from '../../store'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import { formatFiat, getFiatRatesQuery } from '../rightSide/walletComponent/WalletComponent'
import { WalletComponentFiatRatesQuery } from '../rightSide/walletComponent/__generated__/WalletComponentFiatRatesQuery.graphql'
import Env, { GraphQLError } from '../../RelayEnvironment'
import './Rain.scoped.scss'
import { UserProp } from '../../reducers/authentication.reducer'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import Select from 'react-select'
import {
  MakeItRainInput,
  RainComponentMakeItRainMutation,
} from './__generated__/RainComponentMakeItRainMutation.graphql'
import error from 'react-hot-toast'
import formatError from '../errorFormatter'
import React from 'react'
import { toast } from 'react-toastify'
import { Money } from '@src/money'

enum Tab {
  MakeItRain,
  TipMyFollowers,
}

export const RainModal = ({ closeCallback }: { closeCallback: () => void }) => {
  const { t } = useTranslation()
  const [fiatRates, setFiatRates] = useState<{ key: string; value: number }[]>([])
  const [state, setState] = useState<{ openCurrency?: string; strAmount?: string }>({
    openCurrency: undefined,
    strAmount: undefined,
  })
  const [customMessage, setCustomMessage] = useState<string>('')
  const [tab, setTab] = useState(Tab.MakeItRain)
  const [commit] = useMutation<RainComponentMakeItRainMutation>(rainMutation)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.removeProperty('overflow')
    }
  }, [tab])

  useEffect(() => {
    const getFiatRates = () => {
      fetchQuery<WalletComponentFiatRatesQuery>(Env, getFiatRatesQuery, {}).subscribe({
        next: (data) => {
          setFiatRates(
            data.fiatRates.map((x) => {
              return { key: x.key as string, value: +(x.value as string) }
            })
          )
        },
      })
    }

    const fiatSubscription = interval(200 * 1000).subscribe(() => {
      getFiatRates()
    })
    getFiatRates()

    return () => {
      fiatSubscription.unsubscribe()
    }
  }, [])

  const setOpenCurrency = (currency: string) => {
    setState({ openCurrency: currency, strAmount: state.strAmount })
  }

  const setStrAmount = (amount: string) => {
    setState({ openCurrency: state.openCurrency, strAmount: amount })
  }

  const currencies = Object.keys(ConfigurationService.instance.getTradeableCurrencies())
  const userWallets = store.getState().wallet.wallets

  const formattedBalance = (currency: string) => {
    const wallet = getWallet(currency)
    if (wallet) {
      return (
        <span className="wallet">
          {formatBalance(wallet, true, false)}
          <br />
          <span className="fiat">{formatFiat(fiatRates, wallet)}$</span>
        </span>
      )
    }
    return <span className="wallet no-funds">{t('chat.rainModal.noFunds', 'No funds available')}</span>
  }

  const getWallet = (currency: string) => {
    const wallet = userWallets.find((x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === 0)
    return wallet
  }

  const submit = () => {
    if (!state.openCurrency) {
      return
    }
    const [, max] = ConfigurationService.instance.getRainLimitForCurrency(state.openCurrency)
    const rawAmount = state.strAmount || getConvertedBalance(max).toString()
    if (!rawAmount || isNaN(rawAmount as any)) {
      return
    }
    const c = ConfigurationService.instance.getCurrency(state.openCurrency!)
    const format = getUserPropOr(('unit_' + state.openCurrency) as UserProp, c.format)
    const allCurrencies = Money.listAll()
    const units = Object.keys(allCurrencies[state.openCurrency!].units)
      .map((x) => allCurrencies[state.openCurrency!].units[x])
      .sort((a: any, b: any) => (a.shift > b.shift ? -1 : a.shift === b.shift ? 0 : 1))

    const finalAmount = Money.convertUnit(+rawAmount, c.m_unit, format, units[0].code)
    let input = {
      amount: finalAmount,
      currency: state.openCurrency as any,
      message: customMessage,
    } as MakeItRainInput
    if (tab === Tab.TipMyFollowers) {
      /*
      if (bonusCode?.length > 0) {
        input.bonusCode = bonusCode;
      }
      else {
        return;
      }
      */
      input.bonusCode = '__fill_me'
    }
    commit({
      variables: { input },
      onCompleted: () => {
        closeCallback()
      },
      onError: (err: GraphQLError) => {
        error(formatError(err)[0], {
          style: {
            background: 'red',
            color: 'white',
          },
        })
        toast.error(formatError(err)[0])
      },
    })
  }

  const getConvertedBalance = (maxLimit?: number): number => {
    let balance = getWallet(state.openCurrency!)?.balance || 0

    if (maxLimit) {
      balance = Math.max(0, Math.min(balance, maxLimit))
    }

    const c = ConfigurationService.instance.getCurrency(state.openCurrency!)
    const format = getUserPropOr(('unit_' + state.openCurrency) as UserProp, c.format)
    const amount = Money.convertUnit(balance, c.m_unit, c.s_unit, format)
    return amount
  }

  const step1 = () => {
    return currencies.map((currency) => {
      const b = getWallet(currency)?.balance || 0
      return (
        <div
          onClick={() => (b > 0 ? setOpenCurrency(currency) : false)}
          className={'row' + (b > 0 ? ' selectable' : '')}
          key={'cur_' + currency}
        >
          <div className="currency">
            <img className="rain-coin-img" alt={currency} src={getLogoForCoin(currency)} />
            {currency}
          </div>
          {formattedBalance(currency)}
        </div>
      )
    })
  }

  const step2 = () => {
    const options = currencies
      .filter((currency) => {
        return (getWallet(currency)?.balance || 0) > 0
      })
      .map((currency) => {
        return {
          value: currency,
          label: currency,
        }
      })

    if (!state.openCurrency) {
      if (options.length > 0) {
        state.openCurrency = options[0].value
      } else {
        return <h3>{t('chat.rainModal.noFunds', 'No funds available')}</h3>
      }
    }
    const [min, max] = ConfigurationService.instance.getRainLimitForCurrency(state.openCurrency)

    const amount = getConvertedBalance(max)

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        padding: '2px 10px',
        backgroundColor: state.selected ? '#18191d' : state.isFocused ? '#212226' : '#212226',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      control: (provided) => ({
        ...provided,
        color: 'white',
        backgroundColor: 'unset',
        border: '1px solid #3CCEA2',
        borderRadius: '20px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0 10px',
      }),
      input: (provided) => ({
        ...provided,
        margin: '0',
        padding: '0',
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#212226',
      }),
    }

    const c = ConfigurationService.instance.getCurrency(state.openCurrency!)
    const format = getUserPropOr(('unit_' + state.openCurrency) as UserProp, c.format)
    const formattedMin = Money.convertUnit(min < 0 ? 0 : min, c.m_unit, c.s_unit, format)
    const formattedMax = Money.convertUnit(max < 0 ? 0 : max, c.m_unit, c.s_unit, format)

    return (
      <div>
        <p>{t('chat.rainModal.amount', 'Amount')}</p>
        <div className="input-box">
          <input value={state.strAmount || amount} onChange={(e) => setStrAmount(e.target.value)} />
          <Select
            styles={customStyles}
            defaultValue={{ value: state.openCurrency as string, label: state.openCurrency as string }}
            onChange={(e) => setState({ openCurrency: e!.value, strAmount: undefined })}
            options={options as any}
            formatOptionLabel={(e) => {
              return (
                <div className="currency-select">
                  <img className="rain-coin-img" alt={e.value} src={getLogoForCoin(e.value)} />
                  <span>{e.label}</span>
                </div>
              )
            }}
          />
        </div>
        <div className="min-amount">
          <FontAwesomeIcon className="exclamation" color="#8F4BD0" icon={faExclamationCircle} />
          {tab === Tab.TipMyFollowers ? (
            <span className="amount">
              {t('chat.rainModal.amountWillBeDivided', 'Amount will be divided between all promo code users')}
            </span>
          ) : (
            <React.Fragment>
              <span className="amount">
                {t('chat.rainModal.min', 'Min')}: {formattedMin} {format}
              </span>
              <span className="amount">
                {t('chat.rainModal.max', 'Max')}: {formattedMax} {format}
              </span>
            </React.Fragment>
          )}
        </div>

        <p>{t('chat.rainModal.customMessage', 'Custom message')}</p>
        <textarea defaultValue={customMessage} onChange={(e) => setCustomMessage(e.target.value)} />
        {/*
        tab === Tab.TipMyFollowers
          ? <React.Fragment>
            <p>Insert promo code here</p>
            <input className='input-box' type='text' defaultValue={bonusCode} onChange={e => setBonusCode(e.target.value)} />
            <div className='min-amount'>
              <FontAwesomeIcon className='exclamation' color='#8F4BD0' icon={faExclamationCircle} />
              <span className='amount'>Amount will be divided between all promo code users</span>
            </div>
          </React.Fragment>
          : null
        */}

        <button
          disabled={/*tab === Tab.TipMyFollowers && bonusCode.length <= 0*/ false}
          onClick={submit}
          className="uppercase"
        >
          {tab === Tab.MakeItRain
            ? t('chat.rainModal.makeItRain', 'MAKE IT RAAAAAAAINNNNN!!!!!')
            : t('chat.rainModal.sendTip', 'SEND MY FOLLOWERS A TIP')}
        </button>
      </div>
    )
  }

  return (
    <div className="rain-container">
      <div className="rain-top-bar">
        {state.openCurrency || true ? (
          <div className="header-buttons">
            <span className={tab === Tab.MakeItRain ? 'selected' : ''} onClick={() => setTab(Tab.MakeItRain)}>
              {t('chat.rainModal.makeItRain2', 'Make it RAIN')}
            </span>
            <span className={tab === Tab.TipMyFollowers ? 'selected' : ''} onClick={() => setTab(Tab.TipMyFollowers)}>
              {t('chat.rainModal.tipMyFollowers', 'Tip my followers')}
            </span>
          </div>
        ) : (
          <div onClick={closeCallback} className="uppercase">
            <FontAwesomeIcon icon={faCaretLeft} /> {t('general.back', 'Back')}
          </div>
        )}
        <div>
          <FontAwesomeIcon className="close-modal" icon={faClose} size="2x" onClick={closeCallback} />
        </div>
      </div>
      <div className="rain-inner-container">{state.openCurrency !== undefined || true ? step2() : step1()}</div>
    </div>
  )
}

const rainMutation = graphql`
  mutation RainComponentMakeItRainMutation($input: MakeItRainInput!) {
    makeItRain(input: $input) {
      resp
    }
  }
`

import React, { ReactNode, useState } from 'react'
import { coins } from '../coinsComponent/CoinsComponent'
import ReactTooltip from 'react-tooltip'
import styles from './Currencies.module.scss'
import { ModalType, openModalTask } from '../modalComponent/modalComponent'
import { useTranslation } from 'react-i18next'

const Currencies = () => {
  const [tooltipContent, setTooltipContent] = useState<ReactNode | null>(null)
  const { t } = useTranslation()

  const handleMouseEnter = (coin, index) => {
    const content = (
      <div className="flex flex-col justify-center items-center">
        <span className="text-xs py-10">{`${coin.label} (${coin.currency})`}</span>
        <button
          onClick={() => openModalTask.next({ type: ModalType.wallet, currency: coin.currency })}
          className="bg-eucalyptus w-full my-5 p-5 text-sm text-white font-bold bg-blue-500 hover:bg-blue-700 rounded hover:bg-eucalyptusHover"
        >
          {t('coins.deposit', 'DEPOSIT')}
        </button>
      </div>
    )
    setTooltipContent(content)
    ReactTooltip.rebuild()
  }

  const handleMouseLeave = () => {
    ReactTooltip.hide()
  }

  return (
    <div className="flex flex-wrap -mx-4 -mb-12">
      {coins.map((coin, index) => (
        <div
          key={coin.currency}
          className="text-center px-4 last:m-0 mb-12"
          onMouseEnter={() => handleMouseEnter(coin, index)}
          onMouseLeave={handleMouseLeave}
          data-tip
          data-for="global-tooltip"
        >
          <img
            src={coin.logo}
            alt={`${coin.label} logo`}
            className="inline-block relative w-24 h-24 object-contain border-solid rounded-full border-2 border-eerieBlack hover:border-eucalyptus"
          />
        </div>
      ))}
      <ReactTooltip
        id="global-tooltip"
        place="top"
        effect="solid"
        delayShow={100}
        delayHide={100}
        clickable={true}
        globalEventOff="click"
        backgroundColor="#1B1D1E"
        arrowColor="transparent"
        className={styles.currencyTooltip}
        getContent={() => tooltipContent}
      />
    </div>
  )
}

export default Currencies

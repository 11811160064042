import React from 'react'
import { commitMutation } from 'react-relay'
import { useDispatch, useSelector } from 'react-redux'
import { UserProp } from '../../reducers/authentication.reducer'
import { formatBalance, WalletConstant } from '../../reducers/wallet.reducer'
import ConfigurationService from '../../service/ConfigurationService'
import { store, IStore } from '../../store'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import { updateUserPreference } from '../gameListComponent/GameListComponent'
import { GameListComponentstoreUserPreferenceMutation } from '../gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import Env from '../../RelayEnvironment'
import { formatFiat } from '../rightSide/walletComponent/WalletComponent'
import './ListView.scoped.scss'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Money } from '@src/money'

export const ListView = ({ back }: { back: () => void }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const me = useSelector((s: IStore) => s.authentication.user)
  const pendingKycBonuses = me?.pendingKycBonuses ?? []
  const hasPendingKycBonuses = pendingKycBonuses.length > 0

  const wallet = useSelector((store: IStore) => store.wallet)
  const gamblerWallet = wallet.wallets.find((w) => !!w.campaign?.meta?.playable)
  const { activeWallet } = wallet

  const isGamblerWalletActive = gamblerWallet?.virtualId === activeWallet?.virtualId
  const shouldShowSpecialBonusWallet = hasPendingKycBonuses || !!gamblerWallet

  const specialBonusData = shouldShowSpecialBonusWallet
    ? {
        balance: gamblerWallet?.balance || (pendingKycBonuses[0]?.amount ?? 0),
        currency: gamblerWallet?.currency || (pendingKycBonuses[0]?.currency ?? 'BTC'),
      }
    : null

  const walletState = useSelector((state: IStore) => state.wallet)

  const currencies = Object.keys(ConfigurationService.instance.getTradeableCurrencies())

  const setActive = (e: React.MouseEvent | undefined, currency: string) => {
    e?.preventDefault()
    e?.stopPropagation()

    let w = store
      .getState()
      .wallet.wallets.find((x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === 0)
    if (!w) {
      w = {
        balance: 0,
        currency: currency,
        virtual: false,
        virtualId: 0,
      }
      dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: w })
    } else {
      commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
        mutation: updateUserPreference,
        variables: { input: { key: UserProp.ACTIVE_COIN, value: w.currency } },
        onCompleted: () => {
          dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: w })
        },
      })
    }

    back()
  }

  const getWallet = (currency: string) => {
    return walletState.wallets.find((x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === 0)
  }

  const balance = (currency: string) => {
    const wallet = getWallet(currency)
    if (wallet) {
      return (
        <span className="balance-block">
          <span>{formatBalance(wallet, false, false)}</span>
          {formatFiat(store.getState().wallet.rates, wallet)}
        </span>
      )
    }
    return 0
  }

  return (
    <div className="coin-list">
      {specialBonusData && specialBonusData.balance > 0 && (
        <div
          className={classNames('coin-list-row has-funds', {
            active: isGamblerWalletActive,
          })}
          onClick={() => {
            if (gamblerWallet) {
              dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: gamblerWallet })
              return
            }

            history.push('/profile?tab=settings&stab=KYC')
          }}
        >
          <div className="flex">
            <img className="coin-image" alt="USDT" src={getLogoForCoin(specialBonusData.currency)} />
            <span className="coin whitespace-nowrap uppercase">
              {t('wallet.listView.specialBonus', 'SPECIAL BONUS')} {specialBonusData.currency}
            </span>
          </div>
          <div className="balance text-eucalyptus font-bold text-sm text-right">
            {Money.fromInteger(specialBonusData.balance, specialBonusData.currency)}
          </div>
        </div>
      )}
      {currencies.map((x) => {
        const b = balance(x)
        return (
          <div
            key={'wallet-list-x' + x}
            className={classNames('coin-list-row', {
              'has-funds': b !== 0,
              active: activeWallet?.currency === x && !isGamblerWalletActive,
            })}
            onClick={(e) => setActive(e, x)}
          >
            <div className="flex">
              <img className="coin-image" alt={x} src={getLogoForCoin(x)} />
              <span className="coin">{x}</span>
            </div>
            <div className="balance">{b === 0 ? t('wallet.listView.noFundsAvailable', 'No funds available') : b}</div>
          </div>
        )
      })}
    </div>
  )
}

/* eslint-disable */
let constantDegrees = 0
let speed = 0
let acceleration = 0
let freeRestriction = 0.03
let tickerRestriction = 0.4
let extraRestriction = 0.01
let finalRestriction = 0.008
let finalPosition = 0
let animationRunning = false
let animationWon: '0' | '1' = '0'
let slowDown = false
let animationStoppedFunction: (angle: number, ticks: number) => void
let currentTickRotation = -55
let sector1Value = 0
let sector2Value = sector1Value
let sector3Value = sector2Value

let tickerMoveStart = 0.97
let tickerMoveEnd = 0.15
let tickerStartCoefficient = 5.5
let tickerEndCoefficient = (tickerMoveEnd + 1 - tickerMoveStart) / tickerMoveEnd
let ticks = 0

// let magicNumber = 17.1428571428571;
let magicNumber = 0
const sectorValue = (deg: number): number => {
  const r = (((deg - 15) % 360) + 180) / 30
  return r - Math.floor(r)
}

const sectorWinValue = (deg: number): string => {
  // -21 is magic nuber, wont work without it
  const r = Math.floor((((deg - 15) % 360) + 180) / 30)
  return r % 2 === 0 ? '0' : '1'
}

const isPosition = (winVal: string, deg: number): boolean => {
  return winVal === sectorWinValue(deg)
}

const tickerValue = (deg: number) => {
  let a = sectorValue(deg)
  if (a <= tickerMoveEnd) {
    a += 1
  }
  a -= tickerMoveStart

  if (a < 0) {
    return 0
  }

  const tickerDelta = tickerMoveEnd + 1 - tickerMoveStart
  return a / tickerDelta
}

const rotateTicker = () => {}

const rotateWheel = () => {}

const tickerRotation = (deg: number): number => {
  const a = sectorValue(deg)
  if (a > tickerMoveStart || a <= tickerMoveEnd) {
    const tr = a > tickerMoveStart ? (a - tickerMoveStart) * tickerStartCoefficient : a * tickerEndCoefficient + 0.25
    return Math.round(-90 - tr * 45)
  } else {
    return -90
  }
}

const animationTick = () => {
  if (slowDown) {
    if (0 === finalRestriction) {
      finalRestriction = freeRestriction
      let count = 0
      do {
        if (count % 10 === 0) {
          speed += 0.26 // just for failsafe, if the speed is too low
          finalRestriction = freeRestriction
        }
        count++
        finalRestriction += 0.5 / 600
        finalPosition = constantDegrees
        let speedProj = speed
        while (speedProj > 0) {
          finalPosition += speedProj
          speedProj -= finalRestriction + tickerValue(finalPosition) * tickerRestriction
        }
      } while (!isPosition(animationWon, finalPosition))
    }

    rotateWheel()
    constantDegrees += speed

    if (speed > 0) {
      speed -= finalRestriction + tickerValue(constantDegrees) * tickerRestriction
      animationRunning = true
      // setTimeout(animationTick, 20);
      ticks++
      animationTick()
    } else {
      constantDegrees = finalPosition
      ticks++
      animateTickerFinal(true)
    }
  }
  sector3Value = sector2Value
  sector2Value = sector1Value
  sector1Value = tickerValue(constantDegrees)

  const t = tickerRotation(constantDegrees)
  if (t - currentTickRotation < 7) {
    currentTickRotation = t
  } else {
    if (currentTickRotation < -90) {
      currentTickRotation += 7
    }
  }
  // console.log('trotate', t, currentTickRotation);
  rotateTicker()
}

const animateTickerFinal = (started?: boolean) => {
  if (started) {
    if (Math.abs(-90 - tickerRotation(constantDegrees)) < 1) {
      speed = 0
    }
    ticks++
    // setTimeout(animateTickerFinal, 30);
    animateTickerFinal()
  } else {
    if (Math.abs(-90 - tickerRotation(constantDegrees)) < 1) {
      if (speed > 0) {
        speed = 0
      } else {
        speed += 0.02
      }
    } else {
      // accelerating the wheel backwards
      if (speed > -0.6) {
        speed -= 0.02
      }
    }

    constantDegrees += speed
    rotateWheel()

    if (currentTickRotation < -90) {
      if (speed === 0) {
        currentTickRotation += 7
      } else {
        currentTickRotation += 1
      }
    } else {
      currentTickRotation = -90
    }
    if (speed === 0 && Math.abs(currentTickRotation + 90) < 1) {
      animationRunning = false
      if (undefined !== animationStoppedFunction) {
        animationStoppedFunction(constantDegrees, ticks)
      }
    } else {
      // setTimeout(animateTickerFinal, 20);
      ticks++
      animateTickerFinal()
    }
    rotateTicker()
  }
}

export const startSimAnimation = (
  win: string,
  stoppedFunc: (degrees: number, ticks: number) => void,
  ac: any,
  cd: any,
  s: any,
  a: any,
  fr: any,
  tr: any,
  er: any,
  fir: any
) => {
  animationRunning = true
  if (win === 'red') {
    animationWon = '1'
  } else {
    animationWon = '0'
  }
  animationStoppedFunction = stoppedFunc
  slowDown = true

  acceleration = ac
  constantDegrees = cd
  speed = s
  acceleration = a
  freeRestriction = fr
  tickerRestriction = tr
  extraRestriction = er
  finalRestriction = fir
  ticks = 0
  animationTick()
}

import { PayoutEntry } from '@src/App'
import classes from './leaderBoards.module.scss'
import { FormattedWin } from './functions/getFormattedWin'
import { filters } from './useLeaderBoards'
import { Money } from '@src/money'

export function LeaderBoardItem({
  name,
  icon,
  valueDescription,
  isFirstPlace,
  winningAmount,
  reward,
  place,
  bet,
  selected,
}: {
  name: string
  icon?: JSX.Element
  valueDescription: string
  isFirstPlace: boolean
  winningAmount: string
  reward: {
    type: PayoutEntry['type']
    amount: string
    value: string
    currency: string
  }
  place: number
  bet: FormattedWin['bet']
  selected: typeof filters[number]
}) {
  const smallerImageWrapperClass = place === 3 ? classes.smallerImageWrapper_3 : classes.smallerImageWrapper
  const borderClass = place !== 3 ? 'border-b border-paleSky border-opacity-10' : ''

  let C = reward.currency
  let value = reward.value
  if (selected === 'Wins' && bet) {
    C = bet.currency
    value = Money.fromInteger(bet?.bets.reduce((acc, bet) => acc + bet, 0) ?? 0, C).toString()
  }

  if (selected === 'Bets') {
    value = Money.fromInteger(reward.amount, reward.currency).toString()
  }

  if (isFirstPlace) {
    return (
      <div className={classes.firstPlace}>
        <div className="grid grid-cols-4 md:grid-cols-3 items-center justify-center px-16">
          <div className="flex col-span-2 md:col-span-1 items-center gap-x-12 md:gap-x-32">
            <div className="w-10">
              <span className="font-normal text-paleSky leading-6">1</span>
            </div>
            <div className={classes.firstPlaceImageWrapper}>{icon}</div>
            <div className="flex flex-col font-inter">
              <span className="leading-4 font-semibold text-paleSky md:text-xs">First Place</span>
              <span className="font-black leading-6 text-xs no-wrap">{name}</span>
            </div>
          </div>
          <div className="flex justify-center">
            <div className={`flex flex-col ${classes.rewardWrapper}`}>
              <span className="leading-4 font-semibold text-paleSky md:text-xs">
                {selected === 'Wins' && bet ? 'Bet amount' : 'Reward'}
              </span>
              {reward?.type === 'freeroll' ? (
                <div className="font-normal leading-6 text-white no-wrap">
                  {reward.amount} <span className="text-eucalyptus">Free Spins</span>
                </div>
              ) : (
                <div className="leading-6 text-eucalyptus font-bold">
                  <span className="text-white text-xs">{value}</span>
                  <span className="text-xs">{C}</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center w-max md:w-auto px-8">
            <div className={`flex flex-col ${classes.winningAmountWrapper}`}>
              <span className="leading-4 font-semibold text-paleSky text-xs">{valueDescription}</span>
              <span
                style={{
                  fontSize: selected === 'Wins' ? '17px' : undefined,
                }}
                className="font-medium leading-6 text-eucalyptus no-wrap text-sm"
              >
                {winningAmount}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${classes.theRest} grid grid-cols-4 md:grid-cols-3 items-center justify-center py-5 ${borderClass}`}
    >
      <div className="flex items-center gap-x-12 md:gap-x-32 col-span-2 md:col-span-1">
        <div className="w-10">
          <span className="font-normal text-paleSky leading-6">{place}</span>
        </div>
        <div className={classes.imageWrapper}>
          <div className={smallerImageWrapperClass}>{icon}</div>
        </div>
        <div className="flex flex-col font-inter">
          <span className="font-black leading-6 text-white no-wrap text-xs">{name}</span>
        </div>
      </div>
      <div className="flex justify-center">
        <div className={`flex flex-col ${classes.rewardWrapper}`}>
          {reward.type === 'freeroll' ? (
            <div className="font-normal leading-6 text-yellow no-wrap text-xs">{reward.amount} Free Spins</div>
          ) : (
            <div className="font-normal leading-6 text-white no-wrap text-xs">
              {reward.type === 'credit' || reward.type === 'fixed_credit' ? value : reward.amount}
              <span className="text-eucalyptus font-bold">
                {reward.type === 'credit' || reward.type === 'fixed_credit' ? C : '% Rakeback'}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center w-max md:w-auto px-8">
        <div className={`flex flex-col ${classes.winningAmountWrapper}`}>
          <span
            style={{
              fontSize: selected === 'Wins' ? '17px' : undefined,
            }}
            className="font-medium leading-6 text-eucalyptus no-wrap text-sm"
          >
            {winningAmount}
          </span>
        </div>
      </div>
    </div>
  )
}

import classNames from 'classnames'
import { graphql } from 'babel-plugin-relay/macro'
//import { nextTick } from 'process'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Subscription } from 'rxjs'
import { getBalance, walletUpdated } from '../../../reducers/wallet.reducer'
import { getCardHeight, getCardWidth } from '../components/Card'
import { RedBlack } from '../rb/RedBlack'
import Card from './card'
import { Currency } from '../../../service/ConfigurationService'
import Env from '../../../RelayEnvironment'
import { commitMutation, fetchQuery } from 'react-relay'

import './holdem.scss'
import { GameCard } from '../Blackjack'
import { store } from '../../../store'
import { getChipsForValue, getChipStackForChips } from '../chips/stack'
import ConfigurationService from '../../../service/ConfigurationService'
import SoundManager, { Sound } from '../../../SoundManager'
import formatError from '../../errorFormatter'
import { requestSubscription } from 'react-relay'
import { holdemHoldemGameUpdatedSubscription } from './__generated__/holdemHoldemGameUpdatedSubscription.graphql'
import { holdemCallHoldemMutation } from './__generated__/holdemCallHoldemMutation.graphql'
import { holdemCheckHoldemMutation } from './__generated__/holdemCheckHoldemMutation.graphql'
import { holdemRaiseHoldemMutation } from './__generated__/holdemRaiseHoldemMutation.graphql'
import { holdemFoldHoldemMutation } from './__generated__/holdemFoldHoldemMutation.graphql'
import { holdemJoinHoldemMutation } from './__generated__/holdemJoinHoldemMutation.graphql'
import { holdemLeaveHoldemMutation } from './__generated__/holdemLeaveHoldemMutation.graphql'
import { holdemGetGameQuery } from './__generated__/holdemGetGameQuery.graphql'
import { BetBalance } from '../BetBalanceComponent'
import { Money } from '@src/money'

const currencies = Money.listAll()

const getHoldemGameQuery = graphql`
  query holdemGetGameQuery($gameId: String!) {
    getGame(gameId: $gameId) {
      ...HoldemManagerHoldemGameFragment @relay(mask: false)
    }
  }
`

const joinHoldemGameMutation = graphql`
  mutation holdemJoinHoldemMutation($input: JoinHoldemInput!) {
    joinHoldem(input: $input) {
      game {
        ...HoldemManagerHoldemGameFragment @relay(mask: false)
      }
    }
  }
`

const leaveHoldemGameMutation = graphql`
  mutation holdemLeaveHoldemMutation($input: LeaveHoldemInput!) {
    leaveHoldem(input: $input) {
      game {
        ...HoldemManagerHoldemGameFragment @relay(mask: false)
      }
    }
  }
`

const callHoldemGameMutation = graphql`
  mutation holdemCallHoldemMutation($input: CallHoldemInput!) {
    callHoldem(input: $input) {
      game {
        ...HoldemManagerHoldemGameFragment @relay(mask: false)
      }
    }
  }
`

const checkHoldemGameMutation = graphql`
  mutation holdemCheckHoldemMutation($input: CheckHoldemInput!) {
    checkHoldem(input: $input) {
      game {
        ...HoldemManagerHoldemGameFragment @relay(mask: false)
      }
    }
  }
`

const raiseHoldemGameMutation = graphql`
  mutation holdemRaiseHoldemMutation($input: RaiseHoldemInput!) {
    raiseHoldem(input: $input) {
      game {
        ...HoldemManagerHoldemGameFragment @relay(mask: false)
      }
    }
  }
`

const foldHoldemGameMutation = graphql`
  mutation holdemFoldHoldemMutation($input: FoldHoldemInput!) {
    foldHoldem(input: $input) {
      game {
        ...HoldemManagerHoldemGameFragment @relay(mask: false)
      }
    }
  }
`

const gameUpdatedSubscription = graphql`
  subscription holdemHoldemGameUpdatedSubscription($gameId: String!) {
    holdemGameUpdated(gameId: $gameId) {
      ...HoldemManagerHoldemGameFragment @relay(mask: false)
    }
  }
`

interface HoldemSlot {
  hand: GameCard[]
  player: {
    uuid: string
  }
}

interface HoldemArrangement {
  user: {
    uuid: string
    name: string
  }
  idx: number
}

interface ScoreHand {
  bestHand: GameCard[]
  hand: GameCard[]
  typeString: string
}

export interface HoldemGame {
  cards: {
    playerHands: HoldemSlot[]
    table: GameCard[]
  }
  meta: {
    currency: string
    virtualId: number
  }
  phase: 'idle' | 'pre_flop' | 'flop' | 'turn' | 'river' | 'game_over' | 'between_rounds'
  seating: {
    arrangement: HoldemArrangement[]
  }
  chips: {
    chipRoll: { key: string; value: number }[]
    inPlay: { key: string; value: number }[]
    paid: { key: string; value: number }[]
    round: { key: string; value: number }[]
    pot: number
    toCall: number
  }
  roles: {
    bigBlind?: { uuid: string }
    smallBlind?: { uuid: string }
    dealer?: { uuid: string }
  }
  gameId: string
  playerTracker: {
    active: string[]
    allIn: string[]
    called: string[]
    folded: string[]
    timeout: number
  }
  scoring: {
    hands: ScoreHand[]
    rewards: { key: string; value: number }[]
    winningHand: ScoreHand
  }
  move: string
}

interface Props {
  gameId: string
}

interface State {
  game?: HoldemGame
  errors: string[]
  raise: number
}

export class Holdem extends React.Component<Props, State> {
  subscriptions: Subscription[] = []
  myUUID: string = ''
  animatingBets = 0
  private gameUpdateObs?: { dispose: () => void }
  private defferedUpdates: HoldemGame[] = []
  private defferTimeout: any
  private animateDeckFromIdx = -1
  private hideResults = true
  private animateInitialCards = false
  private timeoutAnimation?: Animation

  openProvablyFair: React.MouseEventHandler<HTMLImageElement> | undefined

  constructor(props: Props) {
    super(props)
    this.state = { errors: [], raise: 0 }
  }

  componentDidMount() {
    this.myUUID = store.getState().authentication.user?.uuid as string

    fetchQuery<holdemGetGameQuery>(
      Env,
      getHoldemGameQuery,
      { gameId: this.props.gameId },
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: (data) => {
        const game = data.getGame as unknown as HoldemGame
        this.setState({ game })
        //holdemGameUpdated.next(game);
        this.subscribeToUpdates(game)
      },
    })
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe())
    this.subscriptions = []
    if (undefined !== this.defferTimeout) {
      clearTimeout(this.defferTimeout)
    }
    if (undefined !== this.gameUpdateObs) {
      this.gameUpdateObs.dispose()
      this.gameUpdateObs = undefined
    }
  }

  subscribeToUpdates(game: HoldemGame) {
    console.log({ game })
    if (undefined !== this.gameUpdateObs) {
      this.gameUpdateObs.dispose()
      this.gameUpdateObs = undefined
    }

    this.subscriptions.push(
      walletUpdated.subscribe(() => {
        this.setState({})
      })
    )

    this.gameUpdateObs = requestSubscription<holdemHoldemGameUpdatedSubscription>(Env, {
      subscription: gameUpdatedSubscription,
      variables: { gameId: game.gameId },
      onNext: (data) => {
        const updatedGame = data?.holdemGameUpdated as unknown as HoldemGame
        if (!updatedGame) {
          return
        }
        if (this.state.game?.phase === 'game_over') {
          this.defferedUpdates.push(updatedGame)
          if (undefined === this.defferTimeout) {
            this.defferTimeout = setTimeout(() => {
              this.defferTimeout = undefined
              this.hideResults = false
              this.animateDeckFromIdx = -1
              this.animateInitialCards = true
              this.resetStack()
              const g = this.defferedUpdates[this.defferedUpdates.length - 1]
              this.setState({ game: this.defferedUpdates[this.defferedUpdates.length - 1] })
              this.defferedUpdates = []
              console.log('set game from deffered', g)
            }, 4000)
          }
        } else {
          let stop = false
          switch (updatedGame.move) {
            case 'raise':
            case 'call':
              // Chip animation
              const currentActivePlayer = this.state.game?.playerTracker.active[0]
              if (undefined === currentActivePlayer) {
                break
              }
              const currentActivePlayerBet = this.state.game?.chips.paid.find((x) => x.key === currentActivePlayer)
              const futureBet = updatedGame.chips.paid.find((x) => x.key === currentActivePlayer)
              if (
                futureBet &&
                undefined !== currentActivePlayerBet &&
                currentActivePlayerBet.value !== futureBet?.value
              ) {
                stop = true
                let source: { x: number; y: number } | undefined
                if (currentActivePlayer === this.myUUID) {
                  source = { x: window.innerWidth / 2, y: window.innerHeight }
                } else {
                  source = document
                    .querySelector('.slot-player[data-player="' + currentActivePlayer + '"]')
                    ?.getBoundingClientRect()
                }

                const target = document.querySelector('div.h-pot .chip-stack .chip:last-child')!.getBoundingClientRect()
                const chipsToAdd = getChipsForValue(futureBet.value - currentActivePlayerBet.value, this.getCurrency())
                const chipElements = chipsToAdd.map((x, i) => {
                  const animatedChip = document.createElement('div')
                  animatedChip.classList.add('chip')
                  animatedChip.classList.add('chip-animated')
                  animatedChip.classList.add('chip' + x)
                  animatedChip.style.transform = 'translateX(' + source?.x + 'px) translateY(' + source?.y + 'px)'
                  return animatedChip
                })

                if (chipElements.length === 0) {
                  stop = false
                }

                chipElements.forEach((node, i, array) => {
                  setTimeout(() => {
                    document.body.appendChild(node)
                    node.animate(
                      [
                        {
                          transform: 'translateX(' + target.left + 'px) translateY(' + target.top + 'px)',
                        },
                      ],
                      RedBlack.CHIP_ANIMATION_SPEED
                    ).onfinish = () => {
                      if (i === array.length - 1) {
                        this.setState({ game: updatedGame })
                      }
                      document.body.removeChild(node)
                    }
                  }, 50 * i)
                })
              }
              break
            default:
              break
          }

          // Deal cards
          // if (data.holdem_game_updated.phase === 'pre_flop' && this.state.game?.phase === 'idle') {
          if (updatedGame.phase === 'flop' && this.state.game?.phase !== 'flop') {
            this.animateDeckFromIdx = 0
          }

          if (updatedGame.phase === 'turn' && this.state.game?.phase !== 'turn') {
            this.animateDeckFromIdx = 3
          }

          if (updatedGame.phase === 'river' && this.state.game?.phase !== 'river') {
            this.animateDeckFromIdx = 4
          }
          if (updatedGame.phase === 'game_over' && (this.state.game?.phase as any) !== 'game_over') {
            this.hideResults = true
            setTimeout(() => {
              this.hideResults = false
              const stack = document.querySelector('.chip-stack') as HTMLDivElement
              this.setState({}, () => {
                if (!this.state.game) {
                  return
                }
                if (null !== stack && stack.children.length > 0) {
                  const winList = this.state.game.chips.chipRoll
                    .map((player) => {
                      const reward = this.state.game?.scoring.rewards.find((x) => x.key === player.key)
                      if (undefined === reward || reward.value <= 0) {
                        return undefined
                      }

                      const c = this.getCurrency()
                      const m = Money.convertUnit(reward.value, c.m_unit, c.s_unit, c.format)

                      const slotRect = document
                        .querySelector('.slot-player[data-player="' + player.key + '"]')
                        ?.getBoundingClientRect()
                      return {
                        uuid: player.key,
                        reward: m,
                        rect: slotRect as DOMRect,
                      }
                    })
                    .filter((x) => x !== undefined)

                  const stackRect = stack.getBoundingClientRect()
                  for (let i = 0; i < stack.children.length; i++) {
                    const winner = winList.find((x) => (x?.reward || 0) > 0)
                    const el = stack.children[i] as HTMLDivElement
                    const chipValue = +(el.dataset.value || 0)
                    if (winner) {
                      winner.reward -= chipValue
                      if (winner.uuid === this.myUUID) {
                        el.animate(
                          {
                            transform: 'translate(0, ' + window.innerHeight + 'px)',
                          },
                          300
                        ).onfinish = () => {
                          el.style.display = 'none'
                        }
                      } else {
                        const deltaY = stackRect.top - winner.rect.top
                        const deltaX = stackRect.left - winner.rect.left
                        el.animate(
                          {
                            transform: 'translate(' + -deltaX + 'px, ' + -deltaY + 'px)',
                          },
                          300
                        ).onfinish = () => {
                          el.style.display = 'none'
                        }
                      }
                    } else {
                      el.animate(
                        {
                          transform: 'translate(0, ' + -window.innerHeight + 'px)',
                        },
                        300
                      ).onfinish = () => {
                        el.style.display = 'none'
                      }
                    }
                  }
                }
              })
            }, (updatedGame.cards.playerHands.length + 2) * 200)
          }

          if (stop) {
            return
          }

          this.setState({ game: updatedGame })
        }
      },
    })
  }

  resetStack() {
    const stack = document.querySelector('.chip-stack') as HTMLDivElement
    for (let i = 0; i < stack.children.length; i++) {
      ;(stack.children[i] as HTMLDivElement).style.transform = ''
      ;(stack.children[i] as HTMLDivElement).style.display = 'block'
    }
  }

  getButtons(): JSX.Element | null {
    if (!this.state.game) {
      return null
    }

    const calledSoFar = this.state.game.chips.round.find((x) => x.key === this.myUUID)?.value || 0
    let call: JSX.Element | null = null
    let check: JSX.Element | null = null
    let raise: JSX.Element | null = null
    const isMyTurn = this.isMyTurn()
    let buttonClass = classNames({
      'h-active': isMyTurn,
      'h-inactive': !isMyTurn,
      'h-button-container': true,
    })

    // tslint:disable-next-line: switch-default
    switch (this.state.game.phase) {
      case 'between_rounds':
      case 'idle':
        return null
      case 'pre_flop':
      case 'flop':
      case 'turn':
      case 'river':
        if (calledSoFar < this.state.game.chips.toCall) {
          call = (
            <div onClick={() => this.call()} className="h-call">
              CALL {this.state.game.chips.toCall - calledSoFar}
            </div>
          )
        } else {
          check = (
            <div onClick={() => this.check()} className="h-call">
              CHECK
            </div>
          )
        }
        let raiseLabel = 'RAISE'
        if (this.state.raise > 0) {
          raiseLabel += ' ' + this.state.raise
        }
        raise = (
          <div onClick={() => this.raise()} className="h-raise">
            {raiseLabel}
          </div>
        )
        return (
          <div className={buttonClass}>
            <div onClick={() => this.fold()} className="h-fold">
              FOLD
            </div>
            {call}
            {raise}
            {check}
          </div>
        )
    }
    return null
  }

  getCards(uuid: string, scale?: number, flipAfter?: number): JSX.Element[] | null {
    const shouldAnimate = uuid === this.myUUID && this.animateInitialCards
    let sourcePos = { x: 0, y: 0 }
    if (false && shouldAnimate) {
      this.animateInitialCards = false
      this.onNextFrame(() => {
        if (!this.state.game) {
          return null
        }
        const dealerPos = document.querySelector('.h-blind.dealer')?.getBoundingClientRect()
        const targetContainer = document.querySelector('.h-cards')
        if (undefined === targetContainer) {
          return this.state.game.cards.playerHands
            .find((x) => x.player.uuid === uuid)
            ?.hand.map((card, i) => {
              return (
                <Card sourcePos={sourcePos} flipAfter={flipAfter} scale={scale} key={i.toString() + uuid} card={card} />
              )
            })
        }
        const targetPos = targetContainer?.getBoundingClientRect()
        if (dealerPos && targetPos) {
          sourcePos = { x: -(targetPos.left - dealerPos.left + dealerPos.width), y: -(targetPos.top + getCardHeight()) }
        }

        const els = this.state.game.cards.playerHands
          .find((x) => x.player.uuid === uuid)
          ?.hand.map((card, i) => {
            const k = i.toString() + uuid
            const cardToInsert = <Card sourcePos={sourcePos} flipAfter={flipAfter} scale={scale} key={k} card={card} />
            return cardToInsert
          })

        ReactDOM.render(els as any, targetContainer)
        let counter = 0
        targetContainer?.childNodes.forEach((el) => {
          counter++
          ;(el as HTMLDivElement).animate(
            [
              {
                transform: 'translate(0, 0)',
              },
            ],
            RedBlack.CHIP_ANIMATION_SPEED
          ).onfinish = () => {
            counter--
            if (counter === 0) {
              ReactDOM.unmountComponentAtNode(targetContainer)
              this.setState({})
            }
          }
        })
        console.log(targetContainer?.childNodes.length)
      })
      return null
    }
    const hand = this.state.game?.cards.playerHands.find((x) => x.player.uuid === uuid)?.hand
    if (!hand) {
      return null
    }
    return hand.map((card, i) => {
      return <Card sourcePos={sourcePos} flipAfter={flipAfter} scale={scale} key={i.toString() + uuid} card={card} />
    })
  }

  getMySlot(): JSX.Element | null {
    if (this.state.game?.phase === 'idle' || this.state.game?.phase === 'between_rounds') {
      return null
    }

    return (
      <div className="h-my-slot">
        <div>{this.getUserWinnings(this.myUUID, true)}</div>
        <div className="h-buttons">{this.getButtons()}</div>
        {this.getUserRole(this.myUUID)}
        <div className="h-cards" style={{ height: getCardHeight(), width: getCardWidth() * 1.3 }}>
          {this.getCards(this.myUUID)}
        </div>
      </div>
    )
  }

  formatCurrentBet(): JSX.Element | null {
    const myChips = this.state.game?.chips.paid.find((x) => x.key === this.myUUID)
    if (undefined === myChips) {
      return <span>0</span>
    }

    const value = +myChips.value
    let label: JSX.Element | null = null
    if (isNaN(value)) {
      return (
        <div className="h-bet-label">
          <span>0</span>
        </div>
      )
    }
    if (value > 0) {
      const c = this.getCurrency()
      const m = Money.convertUnit(value, c.m_unit, c.s_unit, c.format)
      const cFormat = c.short === 'FUN' ? 'FUN' : c.format
      label = (
        <div className="h-bet-label">
          <span>
            {m}
            {cFormat}
          </span>
        </div>
      )
    }

    return label
  }

  getCurrency(): Currency {
    if (!this.state.game) {
      return undefined as any
    }
    return ConfigurationService.instance.getCurrency(this.state.game.meta.currency)
  }

  chipSelect = (ev: React.MouseEvent, chip: number) => {
    ev.preventDefault()
    if (!this.state.game) {
      return
    }
    if (this.state.game?.phase === 'idle' || this.state.game?.phase === 'between_rounds') {
      return
    }
    if (!this.isMyTurn()) {
      return
    }
    const c = this.getCurrency()
    const shift =
      (currencies as any)[c.m_unit].units[c.s_unit].shift - (currencies as any)[c.m_unit].units[c.format].shift
    const m = chip * Math.pow(10, shift)
    // const limits = ConfigurationService.instance.getBetLimits(c);
    const haveEnoughBalance =
      this.getBalance() >= m + this.animatingBets + this.state.raise + this.state.game.chips.toCall
    // const dontExceedMaxBetLimit = m + this.animatingBets + this.state.raise <= limits.max;
    const dontExceedMaxBetLimit = true
    if (haveEnoughBalance && dontExceedMaxBetLimit) {
      this.animatingBets += m
      SoundManager.playSound(Sound.CHIP_MOVE)
      /*this.doChipAnimation(chip, () => {
        this.animatingBets -= m;
        this.setState({raise: this.state.raise + m});
      });*/
      this.setState({ raise: this.state.raise + m })
    } else {
      if (!dontExceedMaxBetLimit) {
        this.setState({
          errors: ['Maximum bet reached!'],
        })
        setTimeout(() => {
          this.setState({
            errors: [],
          })
        }, 200000)
      } else {
        this.setState({
          errors: ['Not enough balance!'],
        })
      }
    }
    SoundManager.playSound(Sound.CHIP_SELECT)
  }

  getBalance(): number {
    if (!this.state.game) {
      return 0
    }
    return getBalance(this.state.game.meta.currency, this.state.game.meta.virtualId)
  }

  call() {
    if (!this.state.game) {
      return
    }
    SoundManager.playSound(Sound.TICK)
    commitMutation<holdemCallHoldemMutation>(Env, {
      mutation: callHoldemGameMutation,
      variables: { input: { gameId: this.state.game.gameId } },
      onCompleted: (data) => {
        if (data.callHoldem?.game) {
          // this.setState({game: data.callHoldem.game as unknown as HoldemGame})
        }
      },
      onError: (err) => {
        this.setState({ errors: formatError(err) })
      },
    })
  }

  check() {
    if (!this.state.game) {
      return
    }
    SoundManager.playSound(Sound.TICK)
    commitMutation<holdemCheckHoldemMutation>(Env, {
      mutation: checkHoldemGameMutation,
      variables: { input: { gameId: this.state.game.gameId } },
      onCompleted: (data) => {
        if (data.checkHoldem?.game) {
          if (this.state.raise > 0) {
            this.setState({ raise: 0 })
          }
          // this.setState({game: data.checkHoldem.game as unknown as HoldemGame})
        }
      },
      onError: (err) => {
        this.setState({ errors: formatError(err) })
      },
    })
  }

  raise() {
    if (!this.state.game) {
      return
    }
    if (this.state.raise <= 0) {
      return
    }
    SoundManager.playSound(Sound.TICK)
    const raiseAmount = this.state.game.chips.toCall + this.state.raise
    commitMutation<holdemRaiseHoldemMutation>(Env, {
      mutation: raiseHoldemGameMutation,
      variables: { input: { gameId: this.state.game.gameId, amount: raiseAmount } },
      onCompleted: (data) => {
        if (data.raiseHoldem?.game) {
          this.setState({ raise: 0 })
          // this.setState({game: data.raiseHoldem.game as unknown as HoldemGame})
        }
      },
      onError: (err) => {
        this.setState({ errors: formatError(err) })
      },
    })
  }

  fold() {
    if (!this.state.game) {
      return
    }
    SoundManager.playSound(Sound.TICK)

    commitMutation<holdemFoldHoldemMutation>(Env, {
      mutation: foldHoldemGameMutation,
      variables: { input: { gameId: this.state.game.gameId } },
      onCompleted: (data) => {
        if (data.foldHoldem?.game) {
          // this.setState({game: data.callHoldem.game as unknown as HoldemGame})
        }
      },
      onError: (err) => {
        this.setState({ errors: formatError(err) })
      },
    })
  }

  getUserRole(uuid: string) {
    if (!this.state.game) {
      return
    }
    const idx = this.state.game.seating.arrangement.findIndex((x) => x.user.uuid === uuid)
    if (this.state.game?.phase === 'game_over' && uuid !== this.myUUID) {
      const cards = this.getCards(uuid, 0.5, (idx + 1) * 200)
      return <div className="h-role-container abs">{cards}</div>
    }

    const roles = this.state.game.roles
    const roleElements: JSX.Element[] = []

    if (roles.bigBlind?.uuid === uuid) {
      roleElements.push(
        <div key="h-big" className="h-blind">
          B
        </div>
      )
    }
    if (roles.smallBlind?.uuid === uuid) {
      roleElements.push(
        <div key="h-small" className="h-blind small">
          S
        </div>
      )
    }
    if (roles.dealer?.uuid === uuid) {
      roleElements.push(
        <div key="h-dealer" className="h-blind dealer">
          D
        </div>
      )
    }
    return <div className="h-role-container">{roleElements}</div>
  }

  getUserWinnings(uuid: string, self?: boolean) {
    if (this.state.game?.phase !== 'game_over') {
      return
    }
    if (uuid === this.myUUID && !self) {
      return null
    }
    const idx = this.state.game.cards.playerHands.findIndex((x) => x.player.uuid === uuid)
    const score = this.state.game.scoring.hands[idx]
    const reward = this.state.game.scoring.rewards.find((x) => x.key === uuid)
    if (undefined === score && undefined === reward) {
      return null
    }
    let divClass = classNames({
      'h-hidden': this.hideResults,
      'h-score': true,
    })
    return (
      <div className={divClass}>
        <span className="h-hand-score-label">
          {score?.typeString} {(reward?.value || 0) > 0 ? ' + ' : ''}
          {reward?.value}
        </span>
      </div>
    )
  }

  getUserLabels(uuid: string) {
    if (!this.state.game) {
      return
    }
    const tracker = this.state.game.playerTracker
    let folded: JSX.Element | null = null
    let allIn: JSX.Element | null = null
    let called: JSX.Element | null = null
    if (tracker.folded.findIndex((x) => x === uuid) >= 0) {
      folded = <span className="h-hand-score-label h-fold">FOLDED</span>
    }
    if (tracker.allIn.findIndex((x) => x === uuid) >= 0) {
      allIn = <span className="h-hand-score-label h-all-in">ALL IN</span>
    }
    if (tracker.called.findIndex((x) => x === uuid) >= 0) {
      if (this.state.game.chips.toCall === 0) {
        called = <span className="h-hand-score-label h-call">Checked</span>
      } else {
        called = <span className="h-hand-score-label h-call">Called</span>
      }
    }
    return (
      <div className="h-user-label">
        {folded}
        {allIn}
        {called}
      </div>
    )
  }

  isMyTurn() {
    return this.state.game?.playerTracker.active[0] === this.myUUID
  }

  getUserPotSize(uuid: string) {
    const value = this.state.game?.chips.chipRoll.find((x) => x.key === uuid)?.value
    if (undefined === value) {
      return 0
    }
    return +value
  }

  join(idx: number) {
    if (!this.state.game) {
      return
    }
    commitMutation<holdemJoinHoldemMutation>(Env, {
      mutation: joinHoldemGameMutation,
      variables: { input: { gameId: this.state.game.gameId, idx } },
      onCompleted: (data) => {
        if (data.joinHoldem?.game) {
          this.setState({ game: data.joinHoldem.game as unknown as HoldemGame })
        }
      },
      onError: (err) => {
        this.setState({ errors: formatError(err) })
      },
    })
  }

  leave(uuid: string) {
    if (this.myUUID !== uuid) {
      return
    }
    if (!this.state.game) {
      return
    }
    commitMutation<holdemLeaveHoldemMutation>(Env, {
      mutation: leaveHoldemGameMutation,
      variables: { input: { gameId: this.state.game.gameId } },
      onCompleted: (data) => {
        if (data.leaveHoldem?.game) {
          this.setState({ game: data.leaveHoldem.game as unknown as HoldemGame })
        }
      },
      onError: (err) => {
        this.setState({ errors: formatError(err) })
      },
    })
  }

  onNextFrame(callback: () => void) {
    setTimeout(() => {
      requestAnimationFrame(callback)
    })
  }

  render() {
    if (!this.state.game) {
      return null
    }
    const c = this.getCurrency()

    const style = { width: getCardWidth(), height: getCardHeight(), transform: undefined }
    const windowWidth = window.innerWidth
    if (windowWidth < 400) {
      const maxCardWidth = windowWidth / 6.4
      const scaleDownFactor = style.width / maxCardWidth
      style.width = Math.min(style.width, style.width / scaleDownFactor)
      style.height = Math.min(style.height, style.height / scaleDownFactor)
    }
    const tableSlots = [0, 1, 2, 3, 4].map((_, i) => {
      return (
        <div key={'jsx-slot-' + i} className="h-empty" style={style}>
          <div className="h-card" style={style}>
            <img alt="" src="/assets/h-slot.png" />
          </div>
        </div>
      )
    })
    const tableCards: JSX.Element[] = []
    let sourcePos: { x: number; y: number } | undefined = undefined
    for (let i = 0; i < 5; i++) {
      let card = this.state.game.cards.table[i]
      if (card === undefined) {
        tableCards.push(<div key={'table-card-' + i} className="h-card-container" style={style} />)
        continue
      }

      let dealerPos: { left: number; top: number } | undefined = undefined //document.querySelector('.h-blind.dealer')!.getBoundingClientRect();
      if (this.animateDeckFromIdx >= 0 && i >= this.animateDeckFromIdx) {
        if (undefined === dealerPos) {
          dealerPos = {
            left: window.innerWidth,
            top: 0,
          } as any
        }
        if (!dealerPos) {
          return
        }
        const target = document
          .querySelector('.h-slot-container > div:nth-child(' + (i + 1) + ')')!
          .getBoundingClientRect()
        sourcePos = { x: -(target.left - dealerPos.left), y: -(target.top - dealerPos.top) }
        this.onNextFrame(() => {
          const el = document.querySelector('div[data-table-card="' + i + '"]')
          if (el) {
            el.animate(
              [
                {
                  transform: 'translate(0, 0)',
                },
              ],
              RedBlack.CHIP_ANIMATION_SPEED
            ).onfinish = () => {
              ;(el as HTMLDivElement).style.transform = ''
            }
          }
        })
      }
      tableCards.push(<Card index={i} sourcePos={sourcePos} key={'table-card-' + i} card={card} />)
    }
    this.animateDeckFromIdx = -1

    const players: JSX.Element[] = []
    const currentActivePlayer = this.state.game.playerTracker.active[0]
    for (let i = 0; i < 6; i++) {
      const joinedPlayer = this.state.game.seating.arrangement.find((x) => x.idx === i)
      if (joinedPlayer) {
        const isThisPlayerActive =
          ['between_rounds', 'idle', 'game_over'].indexOf(this.state.game.phase) < 0 &&
          currentActivePlayer === joinedPlayer.user.uuid

        const givenName = joinedPlayer.user.name
        const playerName = null == givenName ? joinedPlayer.user.uuid.substring(0, 8) : givenName
        players.push(
          <div className="slot-player" data-player={joinedPlayer.user.uuid} key={'joined-' + joinedPlayer.user.uuid}>
            <div
              className="h-player h-joined-player"
              title="Click to leave"
              onClick={() => this.leave(joinedPlayer.user.uuid)}
            >
              <span>{playerName}</span>
              <span>{this.getUserPotSize(joinedPlayer.user.uuid)}</span>
            </div>
            {isThisPlayerActive ? <div className="h-slot-progress" /> : null}
            <div className="h-player-info">
              {this.getUserLabels(joinedPlayer.user.uuid)}
              {this.getUserRole(joinedPlayer.user.uuid)}
              {this.getUserWinnings(joinedPlayer.user.uuid)}
            </div>
          </div>
        )

        if (isThisPlayerActive) {
          //   nextTick(() => {
          //     if (undefined !== this.timeoutAnimation) {
          //       this.timeoutAnimation.cancel()
          //       this.timeoutAnimation = undefined
          //     }
          //     const now = (new Date().getTime() + serverTimeDiff) / 1000
          //     const diff = Math.max(0, (this.state.game?.playerTracker.timeout || 0) - now)
          //     const el = document.querySelector('.h-slot-progress') as HTMLDivElement
          //     if (el) {
          //       this.timeoutAnimation = el.animate(
          //         [
          //           {
          //             width: '0',
          //             easing: 'linear',
          //           },
          //         ],
          //         diff * 1000
          //       )
          //       this.timeoutAnimation.onfinish = () => {
          //         el.style.width = '0px'
          //         this.timeoutAnimation = undefined
          //       }
          //     }
          //   })
          // }
        } else {
          players.push(
            <div key={'open-spot-' + i} className="h-player h-open-slot" onClick={() => this.join(i)}>
              <span>Player {i + 1}</span>
              <span>OPEN SPOT</span>
            </div>
          )
        }
      }

      const pot = Money.convertUnit(this.state.game?.chips.pot, c.m_unit, c.s_unit, c.format)
      const bet = getChipsForValue(this.state.game?.chips.pot, c)
      return (
        <div className="h-center-column">
          <div className="h-players">{players}</div>
          <div className="h-pot">
            {getChipStackForChips(bet)}
            {pot > 0 ? (
              <div className="h-pot-label">
                {c.format}
                {pot}
              </div>
            ) : null}
          </div>
          <div className="h-table" style={{ height: style.height, width: 'calc(5 * ' + style.height + 'px)' }}>
            <div className="h-slot-container">{tableSlots}</div>
            {tableCards}
          </div>
          {this.getMySlot()}
          <BetBalance currency={c} chipSelect={this.chipSelect} bet={this.formatCurrentBet()} />
        </div>
      )
    }
  }
}

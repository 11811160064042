import { GameSlot } from '../Blackjack'

const unique = (list: {}[]) => {
  return list.filter((value: {}, index: number) => {
    return list.indexOf(value) === index
  })
}

const getSlotValues = (slot: GameSlot): number[] => {
  let values: [number[]] = [[]]
  slot.cards.forEach((card) => {
    if (card.flipped === 1) {
      return
    }
    if (!isNaN(+card.rank)) {
      values.forEach((v) => v.push(+card.rank))
    } else if (card.rank === 'ace') {
      /**                 *
       * 'A' value can be 1 or 11
       * Deep clone current list
       *
       * Add value 1 to orignal list elements
       * Add value 11 to cloned list elements
       * Join two lists in the end.
       */
      let clonedList = values.slice()
      clonedList = clonedList.map((v) => {
        return v.slice()
      })
      clonedList.forEach((v) => v.push(1))
      values.forEach((v) => v.push(11))
      values = values.concat(clonedList) as [number[]]
    } else {
      values.forEach((v) => v.push(10))
    }
  })
  return values.map((v) => {
    return v.reduce((a, b) => a + b, 0)
  })
}

export function getHandSum(slot: GameSlot) {
  const values = getSlotValues(slot)
  return unique(values).join('/')
}

export { getSlotValues }

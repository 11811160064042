import { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { GameComponent } from './GameComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { Game } from './GameListComponent.types'
import { GameFilter } from './NewGameListComponent'

export function GameList({
  games,
  category,
  filter,
  hasMore,
  fetchMore,
}: {
  games: Game[]
  category?: 'live' | 'slots'
  filter: GameFilter
  hasMore: () => boolean
  fetchMore: () => void
}) {
  const loader = (
    <div className="overflow-hidden inline-flex w-full justify-center py-20 col-span-3">
      <FontAwesomeIcon icon={faSpinner} spin className="text-2xl" />
    </div>
  )

  useEffect(() => {
    if (!filter.provider || filter.provider.length === 0) return
    window.scrollTo(0, 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.provider])

  return (
    <InfiniteScroll
      className={classNames('grid grid-cols-3 md:flex md:flex-wrap gap-y-10 gap-x-5', {
        'md:justify-start': games.length <= 5,
        'md:justify-between': games.length > 5,
      })}
      dataLength={games.length}
      next={fetchMore}
      hasMore={hasMore()}
      loader={loader}
    >
      {games.map((game, i) => (
        <GameComponent key={JSON.stringify(game) + i} game={game} />
      ))}
    </InfiniteScroll>
  )
}

/**
 * @generated SignedSource<<9963bb61ba89626c5fdb7578a416a9bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FreespinReason = "REGSPINLINK" | "REGSPINBCODE" | "COMPETITION" | "VOTE" | "CFREESPIN" | "MANUAL" | "FCAMPAIGN" | "INITIAL" | "%future added value";
export type FreeSpinsViewFreespinsQuery$variables = {};
export type FreeSpinsViewFreespinsQuery$data = {
  readonly listFreespins: ReadonlyArray<{
    readonly amount: number;
    readonly reason: FreespinReason;
    readonly used: number;
  }>;
};
export type FreeSpinsViewFreespinsQuery = {
  variables: FreeSpinsViewFreespinsQuery$variables;
  response: FreeSpinsViewFreespinsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Freespin",
    "kind": "LinkedField",
    "name": "listFreespins",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "used",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FreeSpinsViewFreespinsQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FreeSpinsViewFreespinsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3f754c7ffc214d3aff7cb9731ffd1a6a",
    "id": null,
    "metadata": {},
    "name": "FreeSpinsViewFreespinsQuery",
    "operationKind": "query",
    "text": "query FreeSpinsViewFreespinsQuery {\n  listFreespins {\n    amount\n    reason\n    used\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a8c0562b1d70be5a08f9c2db495a0fd";

export default node;

import React from 'react'

import classes from './TopTier.module.scss'

export function TopTier({
  children,
  text,
  image,
}: {
  children: React.ReactNode | React.ReactNode[]
  text: string
  image: React.ReactNode
}) {
  return (
    <div className={classes.root}>
      <div className={classes.stats}>{children}</div>
      <div className="flex justify-center items-center">{image}</div>
      <div className={classes.container}>
        <p className="font-poppins font-medium text-sm leading-5 text-eucalyptus">{text}</p>
      </div>
    </div>
  )
}

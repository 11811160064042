/**
 * @generated SignedSource<<48dd42a384bd0ea8a3470e818e283ab8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type WalletComponentAllWalletsQuery$variables = {};
export type WalletComponentAllWalletsQuery$data = {
  readonly allWallets: ReadonlyArray<{
    readonly balance: number;
    readonly campaign: {
      readonly campaignId: number;
      readonly type: string;
      readonly payoutMultiplyer: number;
      readonly meta: any | null;
      readonly userInCampaign: {
        readonly amount: number;
        readonly betSum: number;
      } | null;
    } | null;
    readonly currency: Currency;
    readonly virtual: boolean;
    readonly virtualId: number | null;
    readonly insertedAt: string;
  } | null> | null;
};
export type WalletComponentAllWalletsQuery = {
  variables: WalletComponentAllWalletsQuery$variables;
  response: WalletComponentAllWalletsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Wallet",
    "kind": "LinkedField",
    "name": "allWallets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "balance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CampaignU",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": "campaignId",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payoutMultiplyer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserInCampaign",
            "kind": "LinkedField",
            "name": "userInCampaign",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "betSum",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "virtual",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "virtualId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletComponentAllWalletsQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WalletComponentAllWalletsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3af9828f7ac21ec685b56f25ba044bc3",
    "id": null,
    "metadata": {},
    "name": "WalletComponentAllWalletsQuery",
    "operationKind": "query",
    "text": "query WalletComponentAllWalletsQuery {\n  allWallets {\n    balance\n    campaign {\n      campaignId: id\n      type\n      payoutMultiplyer\n      meta\n      userInCampaign {\n        amount\n        betSum\n      }\n    }\n    currency\n    virtual\n    virtualId\n    insertedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e54458bd5f782a94b1ff28799d6253e";

export default node;

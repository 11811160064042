import React from 'react'
import { useTranslation } from 'react-i18next'
import Timer from '../../Timer'
import ConfigurationService from '../../../service/ConfigurationService'
import ICompetitionResultsHeader from './CompetitionResultsHeader.interface'
import styles from './CompetitionResultsHeader.module.scss'
import { roundNumberToLength } from '../../../utils/functions'
import { Money } from '@src/money'

const CompetitionResultsHeader: React.FC<ICompetitionResultsHeader> = ({ competition }) => {
  const { t } = useTranslation()
  let price: null | number = null
  const cformat = 'mBTC'

  if ([5, 6].includes(competition.compId)) {
    const c = ConfigurationService.instance.getCurrency('BTC')

    price = Money.convertUnit(competition.price, c.m_unit, c.s_unit, cformat)
  }
  price = null

  const name = competition.name
  const endTime = competition.runningRound?.endTime

  return (
    <div className={`${styles.root} ${price ? styles.hasCurrency : ''}`}>
      <div className={`${styles.timer}`}>{endTime && <Timer styleV={2} endTime={endTime} />}</div>
      <div className={styles.name}>{t(`competitions.${name}`, name)}</div>

      {price && (
        <div className={styles.price}>
          {roundNumberToLength(price)}
          <span>{cformat}</span>
        </div>
      )}
    </div>
  )
}
export default CompetitionResultsHeader

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Competitions } from '@components/Competitions/Competitions'

import classes from './CompetitionsPage.module.scss'
import Footer from '../Footer/Footer'
import TextBlock from '../TextBlock/TextBlock'
import { LatestBets } from '../LatestBets/LatestBets'
import { PedestalStand } from '../PedestalStand'

export default function CompetitionsPage() {
  const { t } = useTranslation()
  return (
    <section className={classes.root}>
      <PedestalStand bright />
      <Competitions />
      <LatestBets />
      <TextBlock
        headline={t('introText.heading', 'Who is blackjack.fun?')}
        shorttext={t(
          'introText.shortText',
          'Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various cryptocurrencies and we will add more in the future. Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge.'
        )}
        text={t(
          'introText.text',
          `Blackjack.fun is an online crypto casino platform that provides both in-house and partner games. We accept various cryptocurrencies and we will add more in the future.<br/>
    Blackjack.fun has been operating since 2018. With updates and improvements every week since, the platform aims to compete with older, bigger sites. But the most impressive thing about blackjack.fun is not the instant withdrawals or really low house edge.<br/>
    Blackjack.fun's greatest achievement is its community. The members can get involved in the functionality of the platform, they can bring improvements, change various aspects of the experience and even suggest games. All these are meant to provide their members a unique experience, an honest, transparent and safe approach, and a place shaped based on their perspective.`
        )}
      />
      <Footer />
    </section>
  )
}

import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { LoginHeader } from '../Login/Login'
import IForgotPasswordSent from './ForgotPasswordSent.interface'
import styles from '../Login/Login.module.scss'

const ForgotPasswordSent: React.FC<IForgotPasswordSent> = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <LoginHeader activeTab={t('authentication.forgotPassWord', 'Forgot password')} />
      <div className={styles.body}>
        <img src="/assets/auth/forgot-password-sent.svg" className="w-210 mx-auto" alt="Forgot password" />
      </div>
      <div className={styles.footer}>
        <div className={styles.text}>
          <Trans
            i18nKey="authentication.forgotPassword.passwordSent"
            components={{
              1: <br />,
            }}
          >
            A link to reset your password
            <br />
            has been sent to your address.
          </Trans>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordSent

import Konva from 'konva'
import { Observable, forkJoin, of } from 'rxjs'
import { Group } from 'konva/lib/Group'
import { ChipStack } from '../Konva/ChipStack'
import { Currency } from '../../../service/ConfigurationService'
import { UserProp } from '../../../reducers/authentication.reducer'
import { getUserPropOr } from '../../SettingsComponent/SettingsComponent'
import { Money } from '@src/money'

const currencies = Money.listAll()

const imageCache = new Map<number, Konva.Image>()

const chip = (width: number, value: number): Observable<Konva.Image> => {
  if (imageCache.has(value)) {
    const image = imageCache.get(value)!.clone()
    image.width(width)
    image.height(width)
    image.setAttr('value', value)
    image.setAttr('isChip', true)
    return of(image)
  } else {
    return new Observable((observer) => {
      Konva.Image.fromURL('/assets/' + value + '.png', (image: Konva.Image) => {
        image.width(width)
        image.height(width)
        image.setAttr('value', value)
        image.setAttr('isChip', true)
        // image.cache();
        // image.drawHitFromCache();
        imageCache.set(value, image)
        observer.next(image)
        observer.complete()
      })
    })
  }
}

;[5, 10, 25, 50, 500].forEach((x) => {
  chip(0, x).subscribe()
})

const chipList = (width: number, c: Currency): Observable<Group> => {
  return new Observable((observer) => {
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const shift =
      (currencies as any)[c.m_unit].units[c.s_unit].shift - (currencies as any)[c.m_unit].units[format].shift
    const group = new Konva.Group({
      id: 'chip_list',
    })

    const bgRect = new Konva.Rect({
      x: 0,
      y: 0,
      fill: '#000000BF',
    })
    group.add(bgRect)

    const observers: Array<any> = []
    ChipStack.getChips(c).forEach((e) => {
      const value = e / Math.pow(10, shift)
      observers.push(chip(width, value))
    })
    forkJoin(observers).subscribe((res: Konva.Image[]) => {
      let lastX = 0
      let lastWidth = 0
      let lastHeight = 0
      for (let i = 0; i < res.length; i++) {
        const image = res[i]
        lastWidth = image.getWidth()
        // Chips are not centered in PNG
        lastHeight = image.getHeight() - 2
        image.x(lastX)
        lastX += lastWidth
        group.add(image)
      }

      const h = lastHeight * 1.12
      bgRect.width(lastX + lastWidth / 4)
      bgRect.height(h)
      bgRect.offsetX(lastWidth / 8)
      bgRect.offsetY((h - lastHeight) / 2)
      bgRect.cornerRadius(lastWidth / 2)
      group.height(bgRect.height())
      group.width(bgRect.width())
      observer.next(group)
      observer.complete()
    })
  })
}

export { chipList, chip }

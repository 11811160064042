import * as React from 'react'
import classNames from 'classnames'
import { Holdem, HoldemGame } from './holdem'
import { Subscription } from 'rxjs'
import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery } from 'react-relay'
import Env from '../../../RelayEnvironment'
import { HoldemManagerListAllGamesQuery } from './__generated__/HoldemManagerListAllGamesQuery.graphql'

graphql`
  fragment HoldemManagerHoldemGameFragment on HoldemGame {
    cards {
      playerHands {
        hand {
          rank
          suit
          flipped
        }
        player {
          uuid
        }
      }
      table {
        rank
        suit
        flipped
      }
    }
    chips {
      chipRoll {
        key
        value
      }
      inPlay {
        key
        value
      }
      paid {
        key
        value
      }
      round {
        key
        value
      }
      pot
      toCall
    }
    gameId
    meta {
      currency
      virtualId
    }
    phase
    playerTracker {
      active
      allIn
      called
      folded
      timeout
    }
    roles {
      bigBlind {
        uuid
      }
      smallBlind {
        uuid
      }
      dealer {
        uuid
      }
    }
    scoring {
      hands {
        bestHand {
          rank
          suit
          flipped
        }
        hand {
          rank
          suit
          flipped
        }
        hasFlush
        typeString
      }
      rewards {
        key
        value
      }
      winningHand {
        bestHand {
          rank
          suit
          flipped
        }
        hand {
          rank
          suit
          flipped
        }
        hasFlush
        typeString
      }
    }
    seating {
      arrangement {
        user {
          uuid
          name
        }
        idx
      }
    }
    move
  }
`

export const listHoldemGameQuery = graphql`
  query HoldemManagerListAllGamesQuery($currency: String) {
    listAllGames(currency: $currency) {
      ...HoldemManagerHoldemGameFragment @relay(mask: false)
    }
  }
`

/*const getTestHoldemGame = graphql`
  mutation HoldemManagerMakeTestGameMutation($input: MakeTestGameInput) {
    makeTestGame(input: $input) {
      game {
        gameId
      }
    }
  }
`;

const getGameQuery = graphql`
  query HoldemManagerGetGameQuery($game_id: String!) {
    getGame(game_id: $game_id) {
      gameId
    }
  }
`;
*/
interface Props {
  fullscreen: boolean
}

interface State {
  games: HoldemGame[]
  openGame?: HoldemGame
}

export class HoldemManager extends React.Component<Props, State> {
  subscriptions: Subscription[] = []

  constructor(props: Props) {
    super(props)

    this.state = {
      games: [],
    }
  }

  componentDidMount() {
    const parts = window.location.href.split('/')
    const currency = parts[parts.length - 1]
    if (!currency || ['BTC', 'DASH', 'LTC', 'BCH', 'ETH', 'USDT', 'ADA'].indexOf(currency) < 0) {
      // window.location.href = '/';
    }

    fetchQuery<HoldemManagerListAllGamesQuery>(Env, listHoldemGameQuery, {}, { fetchPolicy: 'network-only' }).subscribe(
      {
        next: (data) => {
          const game = data?.listAllGames?.find((x) => x!.meta.currency === 'BTC')
          if (game) {
            console.log('SET STATE LIST ALL GAMES')
            this.setState({ openGame: game as any })
          }
        },
      }
    )
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe())
    this.subscriptions = []
  }

  openGame(game: HoldemGame) {}

  render() {
    console.log(this.state)
    if (this.props.fullscreen && this.state.openGame) {
      //return null;
      return <Holdem gameId={this.state.openGame.gameId} />
    }
    const gameRows = this.state.games.map((x) => {
      let action: JSX.Element | null = null
      if (this.state.openGame?.gameId === x.gameId) {
        action = <span>Is open</span>
      } else {
        action = <button onClick={() => this.openGame(x)}>JOIN</button>
      }
      return (
        <tr key={'list-' + x.gameId}>
          <td>{x.gameId}</td>
          <td>{x.meta.currency}</td>
          <td>{Math.max(x.playerTracker?.active?.length, x.chips?.chipRoll?.length)}</td>
          <td>{action}</td>
        </tr>
      )
    })

    const classes = classNames({
      'h-game-container': true,
      'h-fullscreen': this.props.fullscreen,
      'h-top': !this.props.fullscreen,
    })

    return (
      <div className={classes}>
        <div className="h-game-list">
          <table>
            <thead>
              <tr>
                <th>Game ID</th>
                <th>Currency</th>
                <th>Player count</th>
                <th />
              </tr>
            </thead>
            <tbody>{gameRows}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

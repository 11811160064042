import { useEffect, useState } from 'react'
import { fetchQuery } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { useTranslation } from 'react-i18next'

import Env from '@src/RelayEnvironment'
import { RakeBackStampQuery, RakeBackStampQuery$data } from './__generated__/RakeBackStampQuery.graphql'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'

const getRakeListQuery = graphql`
  query RakeBackStampQuery {
    listRakebacks {
      name
      type
      percent
      startTime
      endTime
    }
  }
`

export function RakeBackStamp({
  rakebackClassNames,
  rakebackPercentageClassNames,
}: {
  rakebackClassNames: string
  rakebackPercentageClassNames: string
}) {
  const [is70PercentActive, setIs70PercentActive] = useState<boolean>(false)
  const [rakeBacks, setRakeBacks] = useState<RakeBackStampQuery$data['listRakebacks']>([])
  const collapsedMenu = useSelector((state: IStore) => state.app.collapsedMenu)
  const { t } = useTranslation()

  useEffect(() => {
    fetchQuery<RakeBackStampQuery>(Env, getRakeListQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
      next: (data) => {
        setRakeBacks(data.listRakebacks)
      },
    })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchQuery<RakeBackStampQuery>(Env, getRakeListQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
        next: (data) => {
          setRakeBacks(data.listRakebacks)
        },
      })
    }, 1000 * 30)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const now = new Date()

    const currentHighest = rakeBacks
      .filter((r) => {
        const startTime = new Date(r.startTime)
        const endTime = new Date(r.endTime)

        return now >= startTime && now <= endTime
      })
      .sort((a, b) => b.percent - a.percent)[0]

    if (currentHighest?.percent === 70) {
      setIs70PercentActive(true)
      return
    }

    if (!is70PercentActive) return
    setIs70PercentActive(false)
  }, [rakeBacks, is70PercentActive])

  if (!is70PercentActive) return null
  if (collapsedMenu) return null

  return (
    <div>
      <p className={rakebackClassNames}>
        <span className={rakebackPercentageClassNames}>70%</span>
        {t('menu.rakebackBonus.rushHour', 'Rakeback Rush Hour')}
      </p>
    </div>
  )
}

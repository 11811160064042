import { useRef } from 'react'
import { createPortal } from 'react-dom'

import { useLockScroll } from '@src/hooks/useLockScroll'
import { Authentication } from '@src/components/authentication/Authentication/Authentication'

import classes from './Modal.module.scss'
import { close } from '@src/reducers/authModal.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '@src/store'

export const AuthenticationModal = () => {
  const dispatch = useDispatch()
  const tab = useSelector((state: IStore) => state.authModal.tab)
  const isOpen = useSelector((state: IStore) => state.authModal.isOpen)

  const closeModal = () => dispatch(close())

  useLockScroll(isOpen)

  return (
    <Portal>
      {isOpen && tab && (
        <>
          <Overlay onClick={closeModal}>
            <Authentication tab={tab} closeModal={closeModal} />
          </Overlay>
        </>
      )}
    </Portal>
  )
}

function Overlay({ children, onClick }: { children?: React.ReactNode; onClick?: (e: React.MouseEvent) => void }) {
  const ref = useRef<HTMLDivElement>(null)
  const handleClickOutside = (e: React.MouseEvent) => {
    if (ref.current === e.target) {
      onClick?.(e)
    }
  }

  return (
    <div ref={ref} onClick={handleClickOutside} className={classes.overlay}>
      {children}
    </div>
  )
}

function Portal({ children }: { children: React.ReactNode }) {
  const body = document.querySelector('body') as HTMLBodyElement

  return <>{createPortal(children, body)}</>
}

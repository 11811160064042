/**
 * @generated SignedSource<<c99408b3b0aed0938546303ab35b8c07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConfigurationServiceGlobalSettingsQuery$variables = {};
export type ConfigurationServiceGlobalSettingsQuery$data = {
  readonly globalSettings: {
    readonly betLimits: ReadonlyArray<{
      readonly currency: string;
      readonly limits: ReadonlyArray<number>;
    }>;
    readonly betLimitsMp: ReadonlyArray<{
      readonly currency: string;
      readonly limits: ReadonlyArray<number>;
    }>;
    readonly disabled: boolean | null;
    readonly rainLimits: any;
    readonly tipLimits: {
      readonly tipMax: number;
      readonly tipMin: number;
    };
  } | null;
};
export type ConfigurationServiceGlobalSettingsQuery = {
  variables: ConfigurationServiceGlobalSettingsQuery$variables;
  response: ConfigurationServiceGlobalSettingsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "limits",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalSettingsObject",
    "kind": "LinkedField",
    "name": "globalSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BetLimit",
        "kind": "LinkedField",
        "name": "betLimits",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BetLimit",
        "kind": "LinkedField",
        "name": "betLimitsMp",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rainLimits",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TipLimit",
        "kind": "LinkedField",
        "name": "tipLimits",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tipMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tipMin",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigurationServiceGlobalSettingsQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConfigurationServiceGlobalSettingsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54feb91f660f4f359b155e9a7763f6d6",
    "id": null,
    "metadata": {},
    "name": "ConfigurationServiceGlobalSettingsQuery",
    "operationKind": "query",
    "text": "query ConfigurationServiceGlobalSettingsQuery {\n  globalSettings {\n    betLimits {\n      currency\n      limits\n    }\n    betLimitsMp {\n      currency\n      limits\n    }\n    disabled\n    rainLimits\n    tipLimits {\n      tipMax\n      tipMin\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed973c0d90b78410768c89c37d4017f5";

export default node;

const ESportsIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 15" fill="none">
    <path d="M8.00025 3.23611L5.74219 9.44753" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path
      d="M2.80645 10.4414C2.80645 10.9904 2.40226 11.4352 1.90323 11.4352C1.82419 11.4352 1.74968 11.4252 1.67742 11.4029C1.28677 11.2936 1 10.9035 1 10.4414C1 9.89226 1.40419 9.44753 1.90323 9.44753C2.40226 9.44753 2.80645 9.89226 2.80645 10.4414Z"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path d="M7.32275 1L8.45179 2.24228" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path
      d="M8.67769 2.98766C8.92711 2.98766 9.1293 2.76519 9.1293 2.49075C9.1293 2.21631 8.92711 1.99384 8.67769 1.99384C8.42827 1.99384 8.22607 2.21631 8.22607 2.49075C8.22607 2.76519 8.42827 2.98766 8.67769 2.98766Z"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M9.58046 2.98765L10.7095 3.73302L11.8385 3.23611L11.1611 1H7.3224L2.17627 8.70216"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path d="M11.8387 3.23612L10.0322 1.99384" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path
      d="M10.7096 10.1929L9.58054 8.95062H6.87086L4.61279 9.94445"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M12.2907 12.6775C12.9142 12.6775 13.4197 12.1213 13.4197 11.4352C13.4197 10.7491 12.9142 10.1929 12.2907 10.1929C11.6671 10.1929 11.1616 10.7491 11.1616 11.4352C11.1616 12.1213 11.6671 12.6775 12.2907 12.6775Z"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path d="M3.70947 10.6898L6.64496 11.4352H11.1611" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path
      d="M1.90283 11.4352L5.06412 13.6713L10.7093 14.4167H11.8383"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M12.2903 11.6836C12.415 11.6836 12.5161 11.5724 12.5161 11.4352C12.5161 11.298 12.415 11.1867 12.2903 11.1867C12.1656 11.1867 12.0645 11.298 12.0645 11.4352C12.0645 11.5724 12.1656 11.6836 12.2903 11.6836Z"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M10.2715 9.44753C10.7683 8.83881 11.4886 8.4537 12.2902 8.4537C13.7873 8.4537 14.9999 9.78792 14.9999 11.4352C14.9999 13.0825 13.7873 14.4167 12.2902 14.4167"
      stroke="white"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path d="M10.7095 14.4167L10.0321 12.9259H6.19336" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M3.48389 8.45371L6.41937 3.98148L5.74195 3.23611" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M11.1616 6.96297H11.6132" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M12.0645 6.96297H12.5161" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M12.9678 6.96297H13.4194" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
  </svg>
)

export default ESportsIcon

import { graphql } from 'babel-plugin-relay/macro'

export const profileQuery = graphql`
  query ProfileQuery($uuid: String!) {
    profile(uuid: $uuid) {
      userStats {
        name
        value
        meta
      }
      profileBadge
      name
      insertedAt
      tournamentWon
      higestWin
      higestBet
      vip {
        badgeId
        offset
        avatar
      }
      hasProfileImage
      followers
      votes
      isUserFollowed
      following
      recentGames
      recentWins {
        gameProvider
        multiplier
        btcBet
        btcWin
        bets
        win
        insertedAt
      }
    }
  }
`

/**
 * @generated SignedSource<<93f23923e771e5b8a337edb77345b6ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RegisterInput = {
  name: string;
  username: string;
  password: string;
  anonymousHash?: string | null;
  refProps: ReadonlyArray<KeyValueIn | null>;
};
export type KeyValueIn = {
  key: string;
  value: string;
};
export type registerMutation$variables = {
  input: RegisterInput;
};
export type registerMutation$data = {
  readonly register: {
    readonly token: string;
    readonly user: {
      readonly name: string | null;
      readonly username: string | null;
      readonly otpEnabled: boolean | null;
      readonly anonymousHash: string | null;
      readonly uuid: string | null;
      readonly roles: ReadonlyArray<string> | null;
      readonly confirmed: boolean | null;
      readonly preferences: ReadonlyArray<{
        readonly key: string | null;
        readonly value: string | null;
        readonly valueJson: string | null;
      }> | null;
      readonly userStats: ReadonlyArray<{
        readonly name: string;
        readonly value: number;
        readonly meta: any | null;
      }>;
    };
  } | null;
};
export type registerMutation = {
  variables: registerMutation$variables;
  response: registerMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RegisterPayload",
    "kind": "LinkedField",
    "name": "register",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "otpEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anonymousHash",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreference",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valueJson",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserStat",
            "kind": "LinkedField",
            "name": "userStats",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "meta",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "registerMutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "registerMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7465e0a972276e3633d884d4b3239c1d",
    "id": null,
    "metadata": {},
    "name": "registerMutation",
    "operationKind": "mutation",
    "text": "mutation registerMutation(\n  $input: RegisterInput!\n) {\n  register(input: $input) {\n    token\n    user {\n      name\n      username\n      otpEnabled\n      anonymousHash\n      uuid\n      roles\n      confirmed\n      preferences {\n        key\n        value\n        valueJson\n      }\n      userStats {\n        name\n        value\n        meta\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "156f1efcd5b0d73731b619015df46bfd";

export default node;

import { useEffect, useState } from "react";

const useIntersectionObserver = ({
    target,
    onIntersect,
    threshold = 0.9,
    rootMargin = '0px',
    enabled = true,
  }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (!enabled || !target.current) {
        return;
      }
  
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
          if (entry.isIntersecting && onIntersect) {
            onIntersect();
          }
        },
        {
          rootMargin,
          threshold,
        }
      );
  
      observer.observe(target.current);
  
      return () => observer.disconnect();
    }, [target, onIntersect, threshold, rootMargin, enabled]);
  
    return isVisible;
  };
  
  export default useIntersectionObserver;
  
import React from 'react'

import classes from './Label.module.scss'
import classNames from 'classnames'

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  children: React.ReactNode
}

export function Label({ children, ...rest }: LabelProps) {
  return (
    <label {...rest} className={classNames(classes.label, rest.className)}>
      {children}
    </label>
  )
}

import React from 'react'

import classes from './Tier.module.scss'

export function Tier({
  children,
  text,
  image,
  crateImageSRC,
}: {
  children: React.ReactNode | React.ReactNode[]
  text: string
  image: React.ReactNode
  crateImageSRC: string
}) {
  return (
    <div className={classes.root}>
      <div className={classes.stats}>{children}</div>
      <div className="flex justify-center items-center">{image}</div>
      <div className={classes.container}>
        <div className="relative w-full flex flex-col">
          <div className="relative flex justify-end">
            <div className={classes.rectangle} />
            <div className="relative">
              <div className={classes.plus}>
                <span>+</span>
              </div>
              <img alt="" src={crateImageSRC} />
            </div>
          </div>
        </div>

        <p className="font-poppins font-medium text-sm leading-5 text-white">{text}</p>
      </div>
    </div>
  )
}

/**
 * @generated SignedSource<<969245da342fbe315c95380458c8a740>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type CreateUrlInput = {
  currency: Currency;
  gameId: string;
  virtualId?: number | null;
};
export type NewGameListComponentEndorphinaCreateUrlMutation$variables = {
  input: CreateUrlInput;
};
export type NewGameListComponentEndorphinaCreateUrlMutation$data = {
  readonly createUrl: {
    readonly url: string;
  } | null;
};
export type NewGameListComponentEndorphinaCreateUrlMutation = {
  variables: NewGameListComponentEndorphinaCreateUrlMutation$variables;
  response: NewGameListComponentEndorphinaCreateUrlMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUrlPayload",
    "kind": "LinkedField",
    "name": "createUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewGameListComponentEndorphinaCreateUrlMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewGameListComponentEndorphinaCreateUrlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c5ec7835d14c3476f2c183213ac335bb",
    "id": null,
    "metadata": {},
    "name": "NewGameListComponentEndorphinaCreateUrlMutation",
    "operationKind": "mutation",
    "text": "mutation NewGameListComponentEndorphinaCreateUrlMutation(\n  $input: CreateUrlInput!\n) {\n  createUrl(input: $input) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "2437ae73fbe14b26aa49d8123ffa9298";

export default node;

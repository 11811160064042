import { useLocation, useParams } from 'react-router-dom'
import useIsMobile from '../../hooks/IsMobile'

import classNames from 'classnames'
import { useMemo } from 'react'

export const Tournament = () => {
  const isMobile = useIsMobile()
  const params = useParams<{ tid: string }>()
  const { tid } = params

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const trackId = searchParams.get('track_id')
  const join = searchParams.get('join_blackjack_freeroll_tournament')

  const url = useMemo(() => {
    const iframeURL = new URL(`https://game-stage.blackjack.fun`)

    if (tid) {
      iframeURL.searchParams.set('tid', tid)
    }

    if (trackId) {
      iframeURL.searchParams.set('track_id', trackId)
    }

    if (join) {
      iframeURL.searchParams.set('join_blackjack_freeroll_tournament', join)
    }

    return iframeURL.toString()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <iframe
      key={isMobile ? 'mobile' : 'desktop'}
      title="bitcoin-blackjack"
      src={url}
      allowFullScreen
      className={classNames({
        'fixed left-0 top-0 w-full': isMobile,
      })}
      style={{
        height: isMobile ? '100dvh' : `calc(100dvh - 90px)`,
        width: '100%',
        zIndex: isMobile ? 1000 : 0,
      }}
    />
  )
}

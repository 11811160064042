import Konva from 'konva'
import { Node } from 'konva/lib/Node'
import { Vector2d } from 'konva/lib/types'

const safeCallBack = (cb?: () => void) => {
  if (undefined !== cb && null !== cb) {
    try {
      cb()
    } catch (e) {
      const callerLine = (e as any).stack.split('\n')[4]
      var index = callerLine.indexOf('at ')
      var clean = callerLine.slice(index + 2, callerLine.length)
      console.log({ callerLine, index, clean })
      console.error(e)
    }
  }
}

// tslint:disable-next-line: max-line-length
const animateTo = (
  object: Konva.Container | Konva.Image | Konva.Shape | Node,
  to: Vector2d,
  duration: number,
  doneCallback?: () => void,
  easing?: any
) => {
  if (null == object.getLayer()) {
    safeCallBack(doneCallback)
    return
  }
  try {
    const t = new Konva.Tween({
      node: object,
      duration: duration / 1000,
      x: to.x,
      y: to.y,
      easing: easing || Konva.Easings.EaseOut,
      onFinish: () => {
        t.destroy()
        safeCallBack(doneCallback)
      },
    }).play()
  } catch (e) {
    safeCallBack(doneCallback)
  }
}

const flip = (object1: Konva.Container, object2: Konva.Container, doneCallback?: () => void) => {
  if (!object1 || !object2) {
    return
  }
  if (null == object1.getLayer() || null == object2.getLayer()) {
    safeCallBack(doneCallback)
    return
  }
  try {
    // ScaleX first object from 1 to 0
    // ScaleX second object from 0 to 1
    object2.scaleX(0)
    const t = new Konva.Tween({
      node: object1,
      duration: 0.15,
      scaleX: 0,
      onFinish: () => {
        t.destroy()
        if (null == object2.getLayer()) {
          safeCallBack(doneCallback)
          return
        }
        const t2 = new Konva.Tween({
          node: object2,
          duration: 0.15,
          scaleX: 1,
          onFinish: () => {
            t2.destroy()
            safeCallBack(doneCallback)
          },
        }).play()
      },
    }).play()
  } catch (e) {
    safeCallBack(doneCallback)
  }
}

const fadeIn = (object: Konva.Container | Konva.Text, doneCallback?: () => void) => {
  if (!object) {
    return
  }
  if (null == object.getLayer()) {
    safeCallBack(doneCallback)
    return
  }
  object.opacity(0)
  object.offsetY(10)
  try {
    const t = new Konva.Tween({
      node: object,
      duration: 0.2,
      opacity: 1,
      offsetY: 0,
      onFinish: () => {
        t.destroy()
        safeCallBack(doneCallback)
      },
    }).play()
  } catch (e) {
    safeCallBack(doneCallback)
  }
}

const fadeOut = (object: Konva.Container | Konva.Text, doneCallback?: () => void) => {
  if (!object) {
    safeCallBack(doneCallback)
    return
  }
  if (null == object.getLayer()) {
    safeCallBack(doneCallback)
    return
  }
  try {
    const t = new Konva.Tween({
      node: object,
      duration: 0.2,
      opacity: 0,
      onFinish: () => {
        t.destroy()
        safeCallBack(doneCallback)
      },
    }).play()
  } catch (e) {
    safeCallBack(doneCallback)
  }
}

export { animateTo, flip, fadeIn, fadeOut, safeCallBack }

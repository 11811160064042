import { ClubShape } from './ClubShape'
import { DiamondShape } from './DiamondShape'
import { HeartShape } from './HeartShape'
import { SpadeShape } from './SpadeShape'

type ShapeProps = {
  type: 'diamond' | 'heart' | 'club' | 'spade'
  className?: string
}

export function Shape(props: ShapeProps) {
  const attributes = {
    className: props.className
  }

  switch (props.type) {
    case 'diamond':
      return <DiamondShape {...attributes} />
    case 'heart':
      return <HeartShape {...attributes} />
    case 'club':
      return <ClubShape {...attributes} />
    case 'spade':
      return <SpadeShape {...attributes} />

    default:
      break
  }

  return null
}

import { useTranslation } from 'react-i18next'

const WithdrawalProcessed = ({ isProcessed, hasError }) => {
  const { t } = useTranslation()

  if (isProcessed) {
    if (!hasError) {
      return <>{t('wallet.success', 'Success')}</>
    }
    return <>{t('wallet.error', 'Error')}</>
  }

  return <>{t('wallet.pending', 'Pending')}</>
}

export default WithdrawalProcessed

/**
 * @generated SignedSource<<a44a466e036b2c319bdf8d81a251e6f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type sportsBetsQuery$variables = {};
export type sportsBetsQuery$data = {
  readonly bets: {
    readonly sportBets: ReadonlyArray<{
      readonly gameId: string;
      readonly gameProvider: string;
      readonly currency: string;
      readonly virtualId: number;
      readonly finished: boolean;
      readonly bets: ReadonlyArray<number>;
      readonly win: number | null;
      readonly btcBet: number | null;
      readonly btcWin: number | null;
      readonly multiplier: number;
      readonly profit: number;
      readonly meta: any | null;
      readonly insertedAt: string;
      readonly user: {
        readonly uuid: string | null;
        readonly name: string;
        readonly vip: {
          readonly badgeId: string | null;
          readonly offset: number | null;
          readonly avatar: string | null;
        } | null;
        readonly hasProfileImage: boolean;
      };
    }>;
  } | null;
};
export type sportsBetsQuery = {
  variables: sportsBetsQuery$variables;
  response: sportsBetsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BetSummary",
    "kind": "LinkedField",
    "name": "bets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GameBet",
        "kind": "LinkedField",
        "name": "sportBets",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameProvider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "virtualId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finished",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "win",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "btcBet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "btcWin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multiplier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insertedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BetUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Vip",
                "kind": "LinkedField",
                "name": "vip",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "badgeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasProfileImage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "sportsBetsQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "sportsBetsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "dfb6aa1535322c0891b65f6889438c4d",
    "id": null,
    "metadata": {},
    "name": "sportsBetsQuery",
    "operationKind": "query",
    "text": "query sportsBetsQuery {\n  bets {\n    sportBets {\n      gameId\n      gameProvider\n      currency\n      virtualId\n      finished\n      bets\n      win\n      btcBet\n      btcWin\n      multiplier\n      profit\n      meta\n      insertedAt\n      user {\n        uuid\n        name\n        vip {\n          badgeId\n          offset\n          avatar\n        }\n        hasProfileImage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0dea83838dbe879ed70d06d677074c5";

export default node;

import './CoinsComponent.scss'
import { ModalType, openModalTask } from '../modalComponent/modalComponent'
import ConfigurationService from '../../service/ConfigurationService'

export const coins = [
  {
    label: 'BITCOIN',
    logo: '/assets/currencies/BTC.svg',
    currency: 'BTC',
  },
  {
    label: 'DASH',
    logo: '/assets/currencies/DASH.svg',
    currency: 'DASH',
  },
  {
    label: 'LITECOIN',
    logo: '/assets/currencies/LTC.svg',
    currency: 'LTC',
  },
  {
    label: 'BCH',
    logo: '/assets/currencies/BCH.svg',
    currency: 'BCH',
  },
  {
    label: 'ETHEREUM',
    logo: '/assets/currencies/ETH.svg',
    currency: 'ETH',
  },
  {
    label: 'USDT',
    logo: 'https://resource.cwallet.com/token/icon/usdt.png',
    currency: 'USDT',
  },
  {
    label: 'CARDANO',
    logo: '/assets/currencies/ADA.svg',
    currency: 'ADA',
  },
  {
    label: 'TRX',
    logo: '/assets/currencies/TRX.svg',
    currency: 'TRX',
  },
  {
    label: 'BNB',
    logo: '/assets/currencies/BNB.svg',
    currency: 'BNB',
  },
  {
    label: 'XMR',
    logo: '/assets/currencies/XMR.svg',
    currency: 'XMR',
  },
  {
    label: 'XRP',
    logo: 'https://resource.cwallet.com/token/icon/xrp.png',
    currency: 'XRP',
  },
  {
    label: 'USDC',
    logo: 'https://resource.cwallet.com/token/icon/usdc.png',
    currency: 'USDC',
  },
  {
    label: 'DOGE',
    logo: 'https://resource.cwallet.com/token/icon/doge.png',
    currency: 'DOGE',
  },
  {
    label: 'BUSD',
    logo: 'https://resource.cwallet.com/token/icon/busd.png',
    currency: 'BUSD',
  },
  {
    label: 'Solana',
    logo: 'https://resource.cwallet.com/token/icon/sol.png',
    currency: 'SOL',
  },
  {
    label: 'SHIB',
    logo: 'https://resource.cwallet.com/token/icon/shib.png',
    currency: 'SHIB',
  },
  {
    label: 'PEPE',
    logo: 'https://resource.cwallet.com/token/icon/pepe.png',
    currency: 'PEPE',
  },
  {
    label: 'WAVES',
    logo: 'https://resource.cwallet.com/token/icon/waves.png',
    currency: 'WAVES',
  },
  {
    label: 'ETC',
    logo: 'https://resource.cwallet.com/token/icon/etc.png',
    currency: 'ETC',
  },
  {
    label: 'BTG',
    logo: 'https://resource.cwallet.com/token/icon/btg.png',
    currency: 'BTG',
  },
  {
    label: 'MANA',
    logo: 'https://resource.cwallet.com/token/icon/mana.png',
    currency: 'MANA',
  },
  {
    label: 'ZEC',
    logo: 'https://resource.cwallet.com/token/icon/zec.png',
    currency: 'ZEC',
  },
  {
    label: 'DGB',
    logo: 'https://resource.cwallet.com/token/icon/dgb.png',
    currency: 'DGB',
  },
  {
    label: 'MATIC',
    logo: 'https://resource.cwallet.com/token/icon/matic.png',
    currency: 'MATIC',
  },
  {
    label: 'ALGO',
    logo: 'https://resource.cwallet.com/token/icon/algo.png',
    currency: 'ALGO',
  },
  {
    label: 'APT',
    logo: 'https://resource.cwallet.com/token/icon/APT.png',
    currency: 'APT',
  },
  {
    label: 'ATOM',
    logo: 'https://resource.cwallet.com/token/icon/atom.png',
    currency: 'ATOM',
  },
  {
    label: 'AVA',
    logo: 'https://resource.cwallet.com/token/icon/ava.png',
    currency: 'AVA',
  },
  {
    label: 'AVAX',
    logo: 'https://resource.cwallet.com/token/icon/avax.png',
    currency: 'AVAX',
  },
  {
    label: 'AXS',
    logo: 'https://resource.cwallet.com/token/icon/axs.png',
    currency: 'AXS',
  },
  {
    label: 'BSV',
    logo: 'https://resource.cwallet.com/token/icon/bsv.png',
    currency: 'BSV',
  },
  {
    label: 'CELO',
    logo: 'https://resource.cwallet.com/token/icon/celo.png',
    currency: 'CELO',
  },
  {
    label: 'CRO',
    logo: 'https://resource.cwallet.com/token/icon/cro.png',
    currency: 'CRO',
  },
  {
    label: 'DAI',
    logo: 'https://resource.cwallet.com/token/icon/dai.png',
    currency: 'DAI',
  },
  {
    label: 'DCR',
    logo: 'https://resource.cwallet.com/token/icon/dcr.png',
    currency: 'DCR',
  },
  {
    label: 'DOT',
    logo: 'https://resource.cwallet.com/token/icon/dot.png',
    currency: 'DOT',
  },
  {
    label: 'EGLD',
    logo: 'https://resource.cwallet.com/token/icon/egld.png',
    currency: 'EGLD',
  },
  {
    label: 'FIL',
    logo: 'https://resource.cwallet.com/token/icon/fil.png',
    currency: 'FIL',
  },
  {
    label: 'FLOKI',
    logo: 'https://resource.cwallet.com/token/icon/FLOKI.png',
    currency: 'FLOKI',
  },
  {
    label: 'FTM',
    logo: 'https://resource.cwallet.com/token/icon/ftm.png',
    currency: 'FTM',
  },
  {
    label: 'ICP',
    logo: 'https://resource.cwallet.com/token/icon/icp.png',
    currency: 'ICP',
  },
  {
    label: 'ICX',
    logo: 'https://resource.cwallet.com/token/icon/icx.png',
    currency: 'ICX',
  },
  {
    label: 'IOTX',
    logo: 'https://resource.cwallet.com/token/icon/iotx.png',
    currency: 'IOTX',
  },
  {
    label: 'KAVA',
    logo: 'https://resource.cwallet.com/token/icon/kava.png',
    currency: 'KAVA',
  },
  {
    label: 'KLAY',
    logo: 'https://resource.cwallet.com/token/icon/klay.png',
    currency: 'KLAY',
  },
  {
    label: 'KSM',
    logo: 'https://resource.cwallet.com/token/icon/ksm.png',
    currency: 'KSM',
  },
  {
    label: 'NEAR',
    logo: 'https://resource.cwallet.com/token/icon/near.png',
    currency: 'NEAR',
  },
  {
    label: 'OKT',
    logo: 'https://resource.cwallet.com/token/icon/okt.png',
    currency: 'OKT',
  },
  {
    label: 'ONE',
    logo: 'https://resource.cwallet.com/token/icon/one.png',
    currency: 'ONE',
  },
  {
    label: 'QTUM',
    logo: 'https://resource.cwallet.com/token/icon/qtum.png',
    currency: 'QTUM',
  },
  {
    label: 'ROSE',
    logo: 'https://resource.cwallet.com/token/icon/rose.png',
    currency: 'ROSE',
  },
  {
    label: 'RVN',
    logo: 'https://resource.cwallet.com/token/icon/rvn.png',
    currency: 'RVN',
  },
  {
    label: 'THETA',
    logo: 'https://resource.cwallet.com/token/icon/theta.png',
    currency: 'THETA',
  },
  {
    label: 'TOMO',
    logo: 'https://resource.cwallet.com/token/icon/tomo.png',
    currency: 'TOMO',
  },
  {
    label: 'VET',
    logo: 'https://resource.cwallet.com/token/icon/vet.png',
    currency: 'VET',
  },
  {
    label: 'XLM',
    logo: 'https://resource.cwallet.com/token/icon/xlm.png',
    currency: 'XLM',
  },
  {
    label: 'XTZ',
    logo: 'https://resource.cwallet.com/token/icon/xtz.png',
    currency: 'XTZ',
  },
  {
    label: 'ZIL',
    logo: 'https://resource.cwallet.com/token/icon/zil.png',
    currency: 'ZIL',
  },
  {
    label: 'GALA',
    logo: 'https://resource.cwallet.com/token/icon/gala.png',
    currency: 'GALA',
  },
  {
    label: 'KMD',
    logo: 'https://resource.cwallet.com/token/icon/kmd.png',
    currency: 'KMD',
  },
  {
    label: 'LINK',
    logo: 'https://resource.cwallet.com/token/icon/link.png',
    currency: 'LINK',
  },
  {
    label: 'XEC',
    logo: 'https://resource.cwallet.com/token/icon/xec.png',
    currency: 'XEC',
  },
  {
    label: 'JACK',
    logo: '/assets/currencies/JACK.svg',
    currency: 'JACK',
  },
  {
    label: 'SPACE',
    logo: 'https://resource.cwallet.com/token/icon/space.png',
    currency: 'SPACE',
  },
  {
    label: 'TON',
    logo: 'https://resource.cwallet.com/token/icon/TON.png',
    currency: 'TON',
  },
  {
    label: 'BAD',
    logo: 'https://resource.cwallet.com/token/icon/BAD.png',
    currency: 'BAD',
  },
  {
    label: 'PolyDoge',
    logo: 'https://resource.cwallet.com/token/icon/POLYDOGE.png',
    currency: 'PolyDoge',
  },
  {
    label: 'ARB',
    logo: 'https://resource.cwallet.com/token/icon/ARB.png',
    currency: 'ARB',
  },
  {
    label: 'LOVELY',
    logo: 'https://resource.cwallet.com/token/icon/LOVELY.png',
    currency: 'LOVELY',
  },
  {
    label: 'KISHU',
    logo: 'https://resource.cwallet.com/token/icon/kishu.png',
    currency: 'KISHU',
  },
  {
    label: 'BabyDoge',
    logo: 'https://resource.cwallet.com/token/icon/BABYDOGE.png',
    currency: 'BabyDoge',
  },
  {
    label: 'COQ',
    logo: 'https://resource.cwallet.com/token/COQ.png',
    currency: 'COQ',
  },
  {
    label: 'MSOL',
    logo: 'https://resource.cwallet.com/token/MSOL.png',
    currency: 'MSOL',
  },
  {
    label: 'JTO',
    logo: 'https://resource.cwallet.com/token/JTO.png',
    currency: 'JTO',
  },
  {
    label: 'BONK',
    logo: 'https://resource.cwallet.com/token/BONK.png',
    currency: 'BONK',
  },
  {
    label: 'OGGY',
    logo: 'https://resource.cwallet.com/token/icon/oggy1.png',
    currency: 'OGGY',
  },
  {
    label: 'KINGSHIB',
    logo: 'https://resource.cwallet.com/token/icon/kingshiba.png',
    currency: 'KINGSHIB',
  },
  {
    label: 'CROP',
    logo: 'https://resource.cwallet.com/token/icon/farmerdoge.jpg',
    currency: 'CROP',
  },
  {
    label: 'BFG',
    logo: 'https://resource.cwallet.com/token/icon/bfg.png',
    currency: 'BFG',
  },
]

export const CoinsComponent = () => {
  return (
    <div className="coins games">
      <div className="app-left-menu styled-scroll">
        <div className="coins-row selectable" onClick={() => openModalTask.next({ type: ModalType.betsHistory })}>
          <div className="coins-column">
            <span>BET HISTORY</span>
          </div>
          <div
            className="coins-logo table-logo"
            style={{
              backgroundImage: "url('/assets/bet_history.png')",
              backgroundSize: '23px',
            }}
          />
        </div>
        {ConfigurationService.instance.areGamesDisabled() ? null : (
          <div className="coins-row selectable" onClick={() => openModalTask.next({ type: ModalType.tables })}>
            <div className="coins-column">
              <span>ACTIVE TABLES</span>
            </div>
            <div className="coins-logo table-logo" style={{ backgroundImage: "url('/assets/active_tables.png')" }} />
          </div>
        )}
        <div className="coins-row selectable" onClick={() => window.open('https://affiliatesblackjack.fun/', '_blank')}>
          <div className="coins-column">
            <span>AFFILIATES</span>
          </div>
          <div className="coins-logo table-logo" style={{ backgroundImage: "url('/assets/active_tables.png')" }} />
        </div>
      </div>
      <div className="games-content app-content-inner w-full"></div>
    </div>
  )
}

export const getLogoForCoin = (currency: string) => {
  const coin = coins.find((x) => x.currency.toLowerCase() === currency?.toLowerCase())
  if (coin) {
    return coin.logo
  }
  return '/assets/fun_logo.svg'
}

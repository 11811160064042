import Konva from 'konva'
import { chip } from '../components/Chip'
import { Vector2d } from 'konva/lib/types'
import { animateTo, fadeOut, safeCallBack } from '../components/KonvaAnimation'
import { forkJoin } from 'rxjs'
import { Role, UserGameRenderer } from '../GameRenderer'
import { isSmallScreen, measureTextWidth } from './Helper'
import { Currency } from '../../../service/ConfigurationService'
import SoundManager, { Sound } from '../../../SoundManager'
import { GameSummary } from '../Blackjack'
import { UserProp } from '../../../reducers/authentication.reducer'
import { getUserPropOr } from '../../SettingsComponent/SettingsComponent'
import { Money } from '@src/money'

const currencies = Money.listAll()

const PLAYER_STACK_ID = 'player_chip_stack'
const DEALER_STACK_ID = 'dealer_chip_stack'
const STACK_LABEL_ID = 'game_chip_stack_label'
const ANIMATION_SPEED = 250

const STACK_DESTROYED = 0x00
const STACK_INIT = 0x01
const STACK_ONLY_LABEL = 0x02

class ChipStack {
  private bet = 0
  private stackState = STACK_DESTROYED
  private version = 0
  private multiplayerSlotPositions: { [key: string]: [number, number] } = {}

  constructor(
    private renderer: UserGameRenderer,
    private layer: Konva.Layer,
    public currency: Currency,
    private role: Role
  ) {
    this.init()
    this.moveStackToPosition('middle')
  }

  init() {
    if (!this.layer) {
      return
    }
    this.layer.find('#' + PLAYER_STACK_ID).forEach((x) => x.destroy())
    this.layer.find('#' + STACK_LABEL_ID).forEach((x) => x.destroy())
    this.layer.find('#' + DEALER_STACK_ID).forEach((x) => x.destroy())
    this.layer.add(new Konva.Group({ id: PLAYER_STACK_ID }))
    this.stackState = STACK_INIT
    this.version++
  }

  setCurrency(currency: Currency) {
    this.currency = currency
    this.render()
  }

  stackFromChips(
    value: number,
    position: Vector2d | 'left' | 'middle' | 'top' | 'right',
    stackID?: string,
    doneCallback?: () => void
  ) {
    if (this.stackState === STACK_ONLY_LABEL) {
      this.init()
    } else {
      this.version++
    }
    if (undefined === stackID || stackID === PLAYER_STACK_ID) {
      this.bet = value
    }
    this.moveStackToPosition(position, false, undefined, stackID)

    const observers: Array<any> = []
    ChipStack.getChipsForValue(this.currency, value).forEach((e) => {
      observers.push(chip(ChipStack.getChipSize(this.layer, this.role), e))
    })
    forkJoin(observers).subscribe((res: Konva.Image[]) => {
      const stack = this.getStack(stackID)
      for (let i = 0; i < res.length; i++) {
        const el = res[i]
        const nextPos = this.getNextChipLocationInStack(stackID)
        if (nextPos) {
          el.x(nextPos.x)
          el.y(nextPos.y)
          stack.add(el)
        }
      }
      this.render()
      safeCallBack(doneCallback)
    })
  }

  addChipToStack(fromPoint: Vector2d, chipValue: number, convertedValue: number, callback?: () => void) {
    if (this.stackState !== STACK_INIT) {
      console.error('Stack is in invalid state')
      console.log(console.trace())
      return
    }
    const chipWidth = ChipStack.getChipSize(this.layer, this.role)
    this.bet += convertedValue
    const stack = this.getStack()
    if (undefined === stack) {
      console.error('NO_STACK')
      console.log(console.trace())
      return
    }
    stack.zIndex((this.layer.children?.length as number) - 1)
    chip(chipWidth, chipValue).subscribe((chipImage: Konva.Image) => {
      chipImage.attrs.convertedValue = convertedValue
      const nextPos = this.getNextChipLocationInStack()
      const size = chipWidth
      if (fromPoint) {
        chipImage.x(fromPoint.x - stack.x() - size / 2)
        chipImage.y(fromPoint.y - stack.y() - size / 2)
        stack.add(chipImage)
        animateTo(chipImage, nextPos, ANIMATION_SPEED)
      } else {
        chipImage.x(nextPos.x)
        chipImage.y(nextPos.y)
        stack.add(chipImage)
      }
      safeCallBack(callback)
      this.render()
    })
  }

  // tslint:disable-next-line:max-line-length
  moveStackToPosition(
    position: Vector2d | 'left' | 'middle' | 'top' | 'right',
    animate?: boolean,
    animateCallback?: () => void,
    stackID?: string
  ) {
    if (this.stackState !== STACK_INIT) {
      console.error('Stack is in invalid state')
      console.log(console.trace())
      return
    }
    let posVector: Vector2d
    if (position === 'left' || position === 'middle' || position === 'top' || position === 'right') {
      posVector = this.getPosition(position)
    } else {
      posVector = position
    }

    const stack = this.getStack(stackID)
    if (!stack) {
      console.error('NO STACK')
      return
    }
    if (animate) {
      const lastVersion = this.version
      // const label = stackID !== DEALER_STACK_ID ? this.layer.findOne('#' + STACK_LABEL_ID) : undefined;
      animateTo(stack, posVector, ANIMATION_SPEED, () => {
        if (this.version !== lastVersion) {
          return
        }
        this.render()
        safeCallBack(animateCallback)
      })
    } else {
      stack.x(posVector.x)
      stack.y(posVector.y)
      this.render()
    }
  }

  splitStack(summary: GameSummary) {
    const playerStack = this.getStack(PLAYER_STACK_ID)
    if (!playerStack) {
      return
    }
    const chipWidth = ChipStack.getChipSize(this.layer, this.role)
    let win = summary.winnings - this.bet

    if (undefined !== summary.details) {
      win = 0
      summary.details.forEach((x) => (win += x.winning))
    }
    const middlePoint = [this.layer.getSize()].map((p) => {
      return { x: p.width / 2, y: p.height / 2 }
    })[0]
    if (this.role === Role.THUMB_TV) {
      middlePoint.x = this.getPosition('right').x
    }

    if (win < 0) {
      this.layer.find('#' + STACK_LABEL_ID).forEach((x) => x.destroy())
      const lastVersion = this.version
      fadeOut(playerStack as any, () => {
        if (this.version !== lastVersion) {
          return
        }
        if (playerStack) {
          this.clear()
          playerStack.opacity(1)
        }
      })
      return
    }
    /*  Add dealer stack to middle point.
        Offset stack so that start of stack is where player stack top right corner is
        when player stack animation ends.
    */
    const playerStackSize = playerStack.children?.length || 0
    this.layer.add(new Konva.Group({ id: DEALER_STACK_ID }))
    middlePoint.x = middlePoint.x - chipWidth / 2
    middlePoint.y = middlePoint.y - chipWidth / 2

    let stacksJoined = false
    const joinStacks = () => {
      if (stacksJoined) {
        return
      }
      this.bet = summary.winnings
      this.drawLabel()
      stacksJoined = true
      const dealerStack = this.getStack(DEALER_STACK_ID)
      if (!dealerStack) {
        return
      }
      while ((dealerStack.children?.length as number) > 0) {
        const x = (dealerStack.children as any)[0]
        x.position(this.getNextChipLocationInStack())
        x.moveTo(playerStack)
      }
      dealerStack.destroy()
      if (this.role === Role.GAME) {
        SoundManager.playSpriteSound(Sound.CHIP_SOUND)
      }

      if (undefined !== summary.details && undefined !== this.multiplayerSlotPositions) {
        const stack = this.getStack()
        const format = getUserPropOr(('unit_' + this.currency.short) as UserProp, this.currency.format)
        const shift =
          (currencies as any)[this.currency.m_unit].units[this.currency.s_unit].shift -
          (currencies as any)[this.currency.m_unit].units[format].shift
        let chipCounter = 0
        let callbackCounter = 0
        const y = this.layer.height() + chipWidth
        for (let i = 0; i < summary.details.length; i++) {
          const slot = summary.details[i]
          if (slot.winning <= 0) {
            continue
          }
          let konvaSlotPos
          if (slot.uuid !== undefined) {
            const key = slot.uuid + '_' + slot.userIdx
            konvaSlotPos = this.multiplayerSlotPositions[key]
          } else {
            konvaSlotPos = [middlePoint.x, this.layer.height() + chipWidth]
          }
          if (undefined === konvaSlotPos) {
            konvaSlotPos = [window.innerWidth / 2]
          }
          const originalZIndex = stack.zIndex()
          stack.moveToTop()
          if (stack.children) {
            let winning = slot.winning
            for (let j = chipCounter; j < stack.children.length; j++) {
              const c = stack.children[j]
              const chipValue = c.attrs.value
              const realValue = chipValue * Math.pow(10, shift)
              winning -= realValue
              if (winning <= 0) {
                callbackCounter++
                // eslint-disable-next-line no-loop-func
                this.moveChipsToLocation(
                  stack,
                  { x: konvaSlotPos[0], y: y },
                  () => {
                    callbackCounter--
                    if (callbackCounter === 0) {
                      stack.zIndex(originalZIndex)
                      playerStack.destroy()
                      this.stackState = STACK_ONLY_LABEL
                    }
                  },
                  { limit: j + 1, offset: chipCounter }
                )
                chipCounter += j + 1
                break
              }
            }
          }
          // TODO Move chips back to dealer if any remained in center
        }
      } else {
        this.moveChipsToLocation(this.getStack(), { x: middlePoint.x, y: this.layer.height() + chipWidth }, () => {
          playerStack.destroy()
          this.stackState = STACK_ONLY_LABEL
        })
      }
    }

    this.stackFromChips(win - this.bet, 'top', DEALER_STACK_ID, () => {
      if (this.role === Role.GAME) {
        SoundManager.playSound(Sound.CHIP_MOVE)
      }
      this.moveStackToPosition(
        middlePoint,
        true,
        () => {
          joinStacks()
        },
        PLAYER_STACK_ID
      )
      this.moveStackToPosition(
        { x: middlePoint.x + playerStackSize * 2, y: middlePoint.y - playerStackSize * 4 },
        true,
        () => {
          joinStacks()
        },
        DEALER_STACK_ID
      )
    })
  }

  render() {
    this.drawLabel()
    this.layer.batchDraw()
  }

  clear(animate?: boolean) {
    this.bet = 0
    this.layer.find('#' + STACK_LABEL_ID).forEach((x) => x.destroy())
    this.layer.find('#' + DEALER_STACK_ID).forEach((x) => x.destroy())
    const stack = this.getStack()
    if (!stack) {
      return
    }
    if (animate) {
      const chipWidth = ChipStack.getChipSize(this.layer, this.role)
      this.moveStackToPosition({ x: stack.x(), y: this.layer.height() + chipWidth }, true, () => {
        stack.destroyChildren()
        this.moveStackToPosition('middle')
      })
    } else {
      stack.destroyChildren()
      this.moveStackToPosition('middle')
    }
    this.render()
  }

  getBet(): number {
    return this.bet
  }

  removeChips(sum: number) {
    let removed = 0
    const stack = this.getStack()
    const chipWidth = ChipStack.getChipSize(this.layer, this.role)
    const size = this.layer.getSize()
    if (stack.children) {
      for (let i = stack.children.length - 1; i >= 0; i--) {
        const c = stack.children[i] as Konva.Image
        if (undefined === c) {
          continue
        }
        const value = c.attrs.value
        const convertedValue = c.attrs.convertedValue
        removed += value
        this.bet -= convertedValue
        animateTo(c as any, { x: 0, y: size.height / 2 + chipWidth / 2 }, 250, () => {
          c.destroy()
        })
        if (removed >= sum) {
          break
        }
      }
    }
    this.render()
  }

  setMultiplayerPosistions(positions: { [key: string]: [number, number] }) {
    this.multiplayerSlotPositions = positions
  }

  private moveChipsToLocation(
    stack: Konva.Group,
    vector: Vector2d,
    doneCallback: () => void,
    params?: { limit: number; offset: number }
  ) {
    // Stack is at x,y offset
    const targetX = vector.x - stack.x()
    const targetY = vector.y - stack.y()
    let callbackCount = 0
    const start = params === undefined ? 0 : params.offset
    if (stack.children) {
      const end = Math.min(stack.children.length, params === undefined ? stack.children.length : start + params.limit)
      for (let i = end - 1; i >= start; i--) {
        const c = stack.children[i]
        // eslint-disable-next-line no-loop-func
        setTimeout(() => {
          callbackCount++
          animateTo(
            c,
            { x: targetX, y: targetY },
            250,
            () => {
              callbackCount--
              if (callbackCount === 0) {
                safeCallBack(doneCallback)
              }
            },
            Konva.Easings.EaseIn
          )
        }, (stack.children.length - 1 - i) * 50)
      }
      if (start === end) {
        safeCallBack(doneCallback)
      }
    }
  }

  private getPosition(pos: 'left' | 'middle' | 'top' | 'right'): Vector2d {
    const chipWidth = ChipStack.getChipSize(this.layer, this.role)
    if (pos === 'left') {
      const size = this.layer.getSize()
      const left = { x: size.width * 0.3, y: size.height / 2 }
      left.x = left.x - chipWidth
      left.y = left.y - chipWidth / 2
      return left
    } else if (pos === 'middle') {
      const size = this.layer.getSize()
      const middle = { x: size.width / 2, y: size.height / 2 }
      middle.x = middle.x - chipWidth / 2
      middle.y = middle.y - chipWidth / 2
      if (size.height < 700) {
        middle.y -= size.height / 10
      }

      return middle
    } else if (pos === 'right') {
      const size = this.layer.getSize()
      const right = { x: size.width * 0.9, y: size.height / 2 }
      right.x = right.x - chipWidth / 2
      right.y = right.y - chipWidth / 2
      return right
    } else {
      const size = this.layer.getSize()
      const top = { x: size.width / 2, y: 0 - chipWidth }
      top.x = top.x - chipWidth / 2
      top.y = top.y - chipWidth / 2
      return top
    }
  }

  private getStack(id?: string): Konva.Group {
    const stackID = id || PLAYER_STACK_ID
    return this.layer.findOne('#' + stackID)
  }

  private getNextChipLocationInStack(stackID?: string): Vector2d {
    const group = this.getStack(stackID)
    if (!group || !group.children) {
      return undefined as any
    }
    if (group.children.length === 0) {
      return { x: 0, y: 0 }
    }

    const smallScreen = isSmallScreen(this.layer)
    return {
      x: group.children.length * (smallScreen ? 1.3 : 2),
      y: -group.children.length * (smallScreen ? 2 : 4),
    }
  }

  private drawLabel() {
    this.layer.find('#' + STACK_LABEL_ID).forEach((x) => x.destroy())
    const stack = this.getStack(PLAYER_STACK_ID)
    if (!stack) {
      return
    }
    const c = this.currency
    if (!c || this.bet <= 0) {
      return
    }

    const chipSize = ChipStack.getChipSize(this.layer, this.role) / 2
    const fontSize = Math.round(chipSize / 2)
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const m = Money.convertUnit(this.bet, c.m_unit, c.s_unit, format)
    const cFormat = c.short === 'FUN' ? 'FUN' : format
    const font = 'bold ' + fontSize + 'px Fjalla One'
    const t = (m < 1000 ? 1000 : m).toString()
    const textWidth = measureTextWidth(t, font)

    const labelGroup = new Konva.Group({
      id: STACK_LABEL_ID,
    })

    const bgRect = new Konva.Rect({
      width: chipSize * 2 + textWidth + chipSize / 2,
      height: chipSize * 2,
      fill: '#000000BF',
      cornerRadius: chipSize,
    })

    const betLabel = new Konva.Text({
      offsetY: -bgRect.height() / 2 - fontSize / 8,
      offsetX: -chipSize / 2,
      width: textWidth,
      text: m.toString(),
      fill: '#fff',
      fontSize: fontSize,
      fontFamily: 'Fjalla One',
      fontStyle: 'bold italic',
      align: 'center',
    })

    const currencyLabel = new Konva.Text({
      width: textWidth,
      offsetX: -chipSize / 2,
      offsetY: -bgRect.height() / 2 - fontSize / 8 + betLabel.height(),

      text: cFormat,
      align: 'center',
      fill: '#fff',
      fontFamily: 'Fjalla One',
      fontStyle: 'bold italic',
      fontSize: fontSize / 1.5,
    })

    labelGroup.width(bgRect.width())
    labelGroup.height(bgRect.height())
    labelGroup.add(bgRect)
    labelGroup.add(betLabel)
    labelGroup.add(currencyLabel)

    const center = this.getPosition('middle')

    labelGroup.y(center.y + chipSize - labelGroup.height() / 2)
    labelGroup.x(center.x - labelGroup.width() + chipSize * 2)
    this.layer.add(labelGroup)
    labelGroup.zIndex(0)
    this.layer.batchDraw()
  }

  // tslint:disable-next-line: member-ordering
  static getChipSize(layer: Konva.Layer, role: Role): number {
    if (role === Role.THUMB_TV) {
      return 45
    }
    let stageWidth = layer.getWidth()
    const stageHeight = layer.getHeight()
    if (stageHeight < stageWidth) {
      stageWidth = stageHeight
    }
    if (stageWidth <= 320) {
      return stageWidth / 7
    } else if (stageWidth <= 414) {
      return stageWidth / 7
    } else if (stageWidth <= 1024) {
      return stageWidth / 9
    } else {
      return stageWidth / 9
    }
  }

  // Returns list of chips which are needed to get given value
  // tslint:disable-next-line:member-ordering
  static getChipsForValue(c: Currency, value: number): number[] {
    if (value <= 0) {
      return []
    }

    let totalValue = value
    const ret: number[] = []
    const chipValues = this.getChips(c).sort((x, y) => {
      return x > y ? 1 : -1
    })
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const shift =
      (currencies as any)[c.m_unit].units[c.s_unit].shift - (currencies as any)[c.m_unit].units[format].shift

    out: while (totalValue > 0) {
      for (let i = chipValues.length - 1; i >= 0; i--) {
        const val = chipValues[i]
        if (totalValue >= val) {
          ret.push(val / Math.pow(10, shift))
          totalValue -= val
          if (ret.length > 20) {
            break out
          }
          continue out
        }
      }
      break
    }
    return ret
  }

  // Get list of chips with converted currency value
  // tslint:disable-next-line:member-ordering
  static getChips(c: Currency) {
    const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
    const shift =
      (currencies as any)[c.m_unit].units[c.s_unit].shift - (currencies as any)[c.m_unit].units[format].shift
    return [5, 10, 25, 50, 500].map((v) => {
      return v * Math.pow(10, shift)
    })
  }
}

export { ChipStack }

import { GraphQLError } from '../RelayEnvironment'
import i18n from '../i18next';

const REPEATED_MESSAGES = {
  insufficient_funds: {
    en: 'Insufficient funds',
    pt: 'Fundos insuficientes',
  }
};

const errors = {
  unauthorized: {
    en: 'Invalid username or password',
    pt: 'Nome de usuário ou senha inválidos',
  },
  insufficient_funds: REPEATED_MESSAGES.insufficient_funds,
  'amount insufficient funds': REPEATED_MESSAGES.insufficient_funds,
  invalid_email: {
    en: 'Invalid email',
    pt: 'Email inválido',
  },
  'password should be at least 6 character(s)': {
    en: 'Password should be at least 6 characters',
    pt: 'A senha deve ter pelo menos 6 caracteres',
  },
  too_many_requests: {
    en: 'Too many requests. Please try again later',
    pt: 'Muitas solicitações. Por favor, tente novamente mais tarde',
  },
  'amount amount too small': {
    en: 'The amount is too small',
    pt: 'O valor é muito pequeno',
  },
  affiliate_bets_low: {
    en: 'Affiliates must make more bets',
    pt: 'Os afiliados devem fazer mais apostas',
  },
  invalid_key: {
    en: 'Bonus code is already used',
    pt: 'O código de bônus já foi utilizado',
  },
  must_register: {
    en: "You have to sign up or log in before it's possible to use this bonus",
    pt: 'Você precisa se inscrever ou fazer login antes de usar este bônus',
  },
  url_error: {
    en: 'Use of links is not allowed',
    pt: 'O uso de links não é permitido',
  },
  empty: {
    en: 'Message is too short',
    pt: 'A mensagem é muito curta',
  },
  nick_too_short: {
    en: 'Nickname must be longer',
    pt: 'O apelido deve ser mais longo',
  },
  nick_too_long: {
    en: 'Nickname must be shorter',
    pt: 'O apelido deve ser mais curto',
  },
  nick_contains_forbidden_words: {
    en: 'The name contains forbidden words',
    pt: 'O nome contém palavras proibidas',
  },
  no_nick: {
    en: 'Enter nickname',
    pt: 'Digite um apelido',
  },
  nick_already_used: {
    en: 'Nickname is already used',
    pt: 'O apelido já está em uso',
  },
  rate_limit_hit: {
    en: 'Your message was not sent because you are sending messages too quickly',
    pt: 'Sua mensagem não foi enviada porque você está enviando mensagens muito rapidamente',
  },
  "name can't be blank": {
    en: "Username can't be blank",
    pt: 'O nome de usuário não pode estar em branco',
  },
  'name Name contains forbidden words': {
    en: 'Username contains forbidden words',
    pt: 'O nome de usuário contém palavras proibidas',
  },
  username_exists: {
    en: 'Username is already used',
    pt: 'O nome de usuário já está em uso',
  },
  no_addresses_available: {
    en: 'No deposit addresses available, please try again later',
    pt: 'Nenhum endereço de depósito disponível, por favor, tente novamente mais tarde',
  },
  'address invalid address': {
    en: 'Invalid address',
    pt: 'Endereço inválido',
  },
  no_deposit_made: {
    en: 'You have to make at least one deposit to get freespins',
    pt: 'Você precisa fazer pelo menos um depósito para obter giros grátis',
  },
  'otp_code wrong otp code': {
    en: 'Wrong OTP code',
    pt: 'Código OTP errado',
  },
  no_deposit: {
    en: 'You have to make at least 10 USD worth of deposits to post into the chat.',
    pt: 'Você precisa fazer depósitos de pelo menos 10 USD para postar no chat.',
  },
  no_deposit_vote: {
    en: 'You have to make a deposit to participate in voting',
    pt: 'Você precisa fazer um depósito para participar da votação',
  },
  voting_ended: {
    en: 'Voting is over',
    pt: 'A votação acabou',
  },
  cannot_cancel_withdraw: {
    en: 'Withdraw cannot be cancelled',
    pt: 'A retirada não pode ser cancelada',
  },
  'amount deposit_bonus_lock': {
    en: 'Part of balance is locked due to deposit bonus',
    pt: 'Parte do saldo está bloqueada devido ao bônus de depósito',
  },
  not_enough_funds: {
    en: 'Not enough funds',
    pt: 'Fundos insuficientes',
  },
  not_enough_players: {
    en: 'Not enough players',
    pt: 'Jogadores insuficientes',
  },
  check_amount: {
    en: 'Invalid amount',
    pt: 'Quantia inválida',
  },
  no_bonus_code: {
    en: 'You don\'t have a Promo Code',
    pt: 'Você não tem um Código Promocional',
  },
  tip_limit_exceeded: {
    en: "Today's tip limit exceeded",
    pt: 'Limite de gorjetas de hoje excedido',
  },
}

const getErrorFromAtom = (atom: string) => {
  const currentLanguage = i18n.language;
  const err = (errors as any)[atom];
  if (err) {
    return err[currentLanguage] || err['en']; // Fallback to English if translation not available
  }
  return atom;
};

const formatError = (error: GraphQLError): string[] => {
  if (!error.response) {
    return ['Unknown error']
  }
  // FIXME catch
  //if (error.networkError) {
  //    return ['Server error: ' + new Date().getTime()];
  //}
  return error.response.json?.errors?.map((x: any) => getErrorFromAtom(x.message))
}

export default formatError
export { getErrorFromAtom }

import classNames from 'classnames'
import { useEffect, useState } from 'react'
import Validator from './Validator'
import { graphql } from 'babel-plugin-relay/macro'
import { ProvablyFairValidatorGetGameByUuidQuery } from './__generated__/ProvablyFairValidatorGetGameByUuidQuery.graphql'
import Env from '../../RelayEnvironment'
import { commitMutation, fetchQuery } from 'relay-runtime'

import './ProvablyFairValidator.scss'
import { ProvablyFairValidatorValidateMutation } from './__generated__/ProvablyFairValidatorValidateMutation.graphql'
interface BlackJackGame {
  gameId: string
  gameType: GameType
  serverSeed: string
  serverSeedHash: string
  finalShuffle: string
  finalShuffleHash: string
  insertedAt: string
  version: number
  clientSeeds: string[]
}

export enum GameType {
  blackjack = 'blackjack',
  redOrBlack = 'redorblack',
  holdem = 'holdem',
}

export const ProvablyFairValidator = ({ gameId }: { gameId: string }) => {
  const [validationResult, setValidationResult] = useState<{ seed: boolean; deckHash: boolean; shuffle: boolean }>()
  const [game, setGame] = useState<BlackJackGame>()
  const [invalid, setInvalid] = useState(false)

  useEffect(() => {
    fetchQuery<ProvablyFairValidatorGetGameByUuidQuery>(
      Env,
      listGamesQuery,
      { gameId: gameId },
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: (data) => {
        if (data.getGameByUuid) {
          setGame(data.getGameByUuid as any)
        } else {
          setInvalid(true)
        }
      },
      error: () => {
        setInvalid(true)
      },
    })
  }, [setGame, gameId])

  const validate = (event: any) => {
    event.preventDefault()
    if (!game) {
      return
    }
    commitMutation<ProvablyFairValidatorValidateMutation>(Env, {
      mutation: validateMutation,
      variables: { input: { gameUuid: game.gameId } },
      onCompleted: () => {},
    })
    let serverSeed: string = '',
      serverSeedHash: string = '',
      finalShuffle: string = '',
      finalShuffleHash: string = ''
    const seeds: string[] = []
    Object.keys(event.target).forEach((x) => {
      const name = event.target[x].name
      if (name === undefined) {
        return
      }
      const value = event.target[x].value
      // tslint:disable-next-line: switch-default
      switch (name) {
        case 'server_seed':
          serverSeed = value
          return
        case 'server_seed_hash':
          serverSeedHash = value
          return
        case 'final_shuffle':
          finalShuffle = value
          return
        case 'final_shuffle_hash':
          finalShuffleHash = value
          return
      }
      if (name.startsWith('client_seed')) {
        seeds.push(event.target[x].value)
      }
    })
    let v = new Validator(
      game.gameType,
      game.version,
      serverSeed,
      serverSeedHash,
      seeds,
      finalShuffle,
      finalShuffleHash
    )

    const res = v.check()
    if (
      game.finalShuffle === finalShuffle &&
      game.finalShuffleHash === finalShuffleHash &&
      game.clientSeeds[0] === seeds[0]
    ) {
      res.shuffle = true
    }
    setValidationResult(res)
  }

  if (invalid) {
    return (
      <div className="game-entry-validator">
        <h3>Game not found</h3>
      </div>
    )
  }

  if (!game) {
    return null
  }

  const deckHash = validationResult && validationResult.deckHash
  const shuffle = validationResult && validationResult.shuffle
  let deckHashClasses = classNames({
    'v-pass': deckHash && shuffle,
    'v-failure': validationResult && !(deckHash && shuffle),
  })

  const serverSeed = validationResult && validationResult.seed
  let serverSeedClasses = classNames({
    'v-pass': serverSeed,
    'v-failure': validationResult && !serverSeed,
  })

  let shuffleClasses = classNames({
    'v-pass': deckHash && shuffle,
    'v-failure': validationResult && !(deckHash && shuffle),
  })

  let infoRow: JSX.Element | null = null
  if (undefined === game.finalShuffleHash) {
    infoRow = (
      <tr>
        <td colSpan={2}>Waiting for user bet!</td>
      </tr>
    )
  }

  const onClick = () => {
    commitMutation<ProvablyFairValidatorValidateMutation>(Env, {
      mutation: validateMutation,
      variables: { input: { gameUuid: game.gameId } },
      onCompleted: () => {},
    })
  }

  return (
    <div className="game-entry-validator">
      <img src="/assets/certificate.png" alt="" />
      <h2>PROVABLY FAIR GAMBLING</h2>
      <h5>
        BLACKJACK.FUN USES THE PROVABLY FAIR ALGORITHM.
        <br />
        THIS MEANS IT IS <span className="white">IMPOSSBILE</span> FOR THE CASINO
        <br />
        OR THE PLAYER TO CHEAT IN ANY WAY!
      </h5>
      <form onSubmit={validate}>
        <input type="hidden" name="version" value={game.version} />
        <table>
          <tbody>
            {infoRow}
            {game.clientSeeds.map((cs, i) => {
              return (
                <tr key={cs}>
                  <td>Client seed:</td>
                  <td>
                    <input
                      className={shuffleClasses}
                      name={'client_seed[' + i + ']'}
                      type="text"
                      autoComplete="off"
                      onClick={onClick}
                      defaultValue={cs}
                    />
                  </td>
                </tr>
              )
            })}
            <tr>
              <td>Server seed:</td>
              <td>
                <input
                  className={serverSeedClasses}
                  name="server_seed"
                  type="text"
                  autoComplete="off"
                  onClick={onClick}
                  defaultValue={game.serverSeed}
                />
              </td>
            </tr>
            <tr>
              <td>Server seed hash:</td>
              <td>
                <input
                  className={serverSeedClasses}
                  name="server_seed_hash"
                  type="text"
                  autoComplete="off"
                  onClick={onClick}
                  defaultValue={game.serverSeedHash}
                />
              </td>
            </tr>
            <tr>
              <td>Final shuffle:</td>
              <td>
                <input
                  className={deckHashClasses}
                  name="final_shuffle"
                  type="text"
                  autoComplete="off"
                  onClick={onClick}
                  defaultValue={game.finalShuffle}
                />
              </td>
            </tr>
            <tr>
              <td>Final shuffle hash:</td>
              <td>
                <input
                  className={deckHashClasses}
                  name="final_shuffle_hash"
                  type="text"
                  autoComplete="off"
                  onClick={onClick}
                  defaultValue={game.finalShuffleHash}
                />
              </td>
            </tr>
            <tr>
              <td className="center" colSpan={2}>
                <input disabled={null === game.finalShuffleHash} type="submit" value="Validate" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  )
}

export const listGamesQuery = graphql`
  query ProvablyFairValidatorGetGameByUuidQuery($gameId: String!) {
    getGameByUuid(gameId: $gameId) {
      gameId
      gameType
      serverSeed
      serverSeedHash
      finalShuffle
      finalShuffleHash
      insertedAt
      version
      clientSeeds
    }
  }
`

const validateMutation = graphql`
  mutation ProvablyFairValidatorValidateMutation($input: ValidateInput!) {
    validate(input: $input) {
      res
    }
  }
`

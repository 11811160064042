/**
 * @generated SignedSource<<48b6a18ae35520844d2caf007476f80f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GameAggregator = "SOFT_SWISS" | "ENDORPHINA" | "HUB88" | "SEVEN" | "BJFUN" | "%future added value";
export type ListNormalizedGamesFilter = {
  gameId?: string | null;
  keyword?: string | null;
  provider?: string | null;
  providers?: ReadonlyArray<string> | null;
  category?: string | null;
  categories?: ReadonlyArray<string> | null;
  aggregator?: ReadonlyArray<GameAggregator> | null;
};
export type GlobalSearchComponentNormalizedGamesQuery$variables = {
  filter: ListNormalizedGamesFilter;
  page: number;
  pageSize?: number | null;
};
export type GlobalSearchComponentNormalizedGamesQuery$data = {
  readonly listNormalizedGames: {
    readonly entries: ReadonlyArray<{
      readonly urlThumb: string | null;
      readonly aggregator: GameAggregator;
      readonly gameId: string;
      readonly category: string | null;
      readonly provider: string | null;
      readonly label: string;
    }>;
    readonly pageNumber: number | null;
    readonly pageSize: number | null;
    readonly totalEntries: number | null;
    readonly totalPages: number | null;
  };
};
export type GlobalSearchComponentNormalizedGamesQuery = {
  variables: GlobalSearchComponentNormalizedGamesQuery$variables;
  response: GlobalSearchComponentNormalizedGamesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      }
    ],
    "concreteType": "NormalizedGamePage",
    "kind": "LinkedField",
    "name": "listNormalizedGames",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NormalizedGame",
        "kind": "LinkedField",
        "name": "entries",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "urlThumb",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aggregator",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pageNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pageSize",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalEntries",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPages",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalSearchComponentNormalizedGamesQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalSearchComponentNormalizedGamesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "430e332bb39977e7ff2a63ce88a2f861",
    "id": null,
    "metadata": {},
    "name": "GlobalSearchComponentNormalizedGamesQuery",
    "operationKind": "query",
    "text": "query GlobalSearchComponentNormalizedGamesQuery(\n  $filter: ListNormalizedGamesFilter!\n  $page: Int!\n  $pageSize: Int\n) {\n  listNormalizedGames(filter: $filter, page: $page, pageSize: $pageSize) {\n    entries {\n      urlThumb\n      aggregator\n      gameId\n      category\n      provider\n      label\n    }\n    pageNumber\n    pageSize\n    totalEntries\n    totalPages\n  }\n}\n"
  }
};
})();

(node as any).hash = "a86775ccb2f8548896d58bbf0b4c3bae";

export default node;

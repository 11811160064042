import React, { useEffect, useRef, useState } from 'react'
import { IStore, store } from '@src/store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import classes from './BonusExpireStamp.module.scss'

export function BonusExpireStamp() {
  const collapsedMenu = useSelector((state: IStore) => state.app.collapsedMenu)
  const [wallet, setWallet] = useState(useSelector((state: IStore) => state.wallet))
  const [expiringBonus, setExpiringBonus] = useState<any>(null)
  const defaultTime = `0 Days | 0 Hours | 00 Minutes | 00 Seconds`
  const [formattedTime, setFormattedTime] = useState(defaultTime)
  const interval = useRef<any>(null)
  const { t } = useTranslation()

  useEffect(() => {
    const handleStoreUpdate = () => {
      const newWallet = store.getState().wallet
      const bonusWallets = newWallet.wallets
        .filter((wallet) => wallet.virtual === true)
        .sort((a, b) => {
          const aDate = dayjs(a.insertedAt)
          const bDate = dayjs(b.insertedAt)
          return aDate.isBefore(bDate) ? -1 : aDate.isAfter(bDate) ? 1 : 0
        })

      setWallet(newWallet)

      if (bonusWallets.length > 0) {
        setExpiringBonus(bonusWallets[0])
      } else {
        setExpiringBonus(null)
      }
    }

    const unsubscribe = store.subscribe(handleStoreUpdate)
    handleStoreUpdate() // Initial call to set state

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }

    if (!expiringBonus) {
      setFormattedTime(defaultTime)
      return
    }

    const bonusExpiresAt = dayjs(expiringBonus.insertedAt).add(7, 'days')

    interval.current = setInterval(() => {
      const now = dayjs()
      const diff = bonusExpiresAt.diff(now)

      if (diff < 0) {
        clearInterval(interval.current)
        setFormattedTime(defaultTime)
        return
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((diff / (1000 * 60)) % 60)
      const seconds = Math.floor((diff / 1000) % 60)

      const time =
        `${t('time.day', { count: days })} | ` +
        `${t('time.hour', { count: hours })} | ` +
        `${t('time.minute', { count: minutes })} | ` +
        `${t('time.second', { count: seconds })}`

      setFormattedTime(time)
    }, 500)

    return () => clearInterval(interval.current)
  }, [expiringBonus])

  const daysLeft = Number(formattedTime.split('|')[0].replace(/\D/g, ''))
  if (!expiringBonus || daysLeft > 2 || formattedTime === defaultTime) return null
  if (collapsedMenu) return null

  return (
    <div className={classes.root}>
      <h3 className="text-yellow text-sm font-bold font-poppins w-max">
        {t('menu.bonusMessage.expiresIn', 'Your bonus expires in')}
      </h3>
      <span className="font-inter text-white w-max">{formattedTime}</span>
    </div>
  )
}

import { graphql } from 'babel-plugin-relay/macro'

export const disableOtpMutation = graphql`
  mutation disableOtpMutation($input: DisableOtpInput!) {
    disableOtp(input: $input) {
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        chatApproval
        tipDisabled
        insertedAt
        kycState
        refProps
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

/**
 * @generated SignedSource<<7771adb1edfe3de722cb3d4a4136dbeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type AppComponentUserUpdatedSubscription$variables = {};
export type AppComponentUserUpdatedSubscription$data = {
  readonly userUpdated: {
    readonly preferences: ReadonlyArray<{
      readonly key: string | null;
      readonly value: string | null;
    }> | null;
  } | null;
};
export type AppComponentUserUpdatedSubscription = {
  variables: AppComponentUserUpdatedSubscription$variables;
  response: AppComponentUserUpdatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "userUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPreference",
        "kind": "LinkedField",
        "name": "preferences",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppComponentUserUpdatedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppComponentUserUpdatedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "93327909855cada44a7079811ab8cf60",
    "id": null,
    "metadata": {},
    "name": "AppComponentUserUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription AppComponentUserUpdatedSubscription {\n  userUpdated {\n    preferences {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8834c83c72d873afad467c2ecdc1cd4";

export default node;

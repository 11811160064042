import { useLazyLoadQuery } from 'react-relay'

import { ProfileQuery } from './gql/__generated__/ProfileQuery.graphql'
import { profileQuery } from './gql/ProfileQuery.gql'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '@src/store'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { setProfile } from '@src/reducers/profile.reducer'
import { Writable } from '@src/types/writable.type'

export function useProfilePage({ uuid }: { uuid?: string }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { search } = location

  const me = useSelector((s: IStore) => s.authentication.user)

  const data = useLazyLoadQuery<ProfileQuery>(
    profileQuery,
    { uuid: uuid ?? me?.uuid! },
    { fetchPolicy: 'store-and-network' }
  )

  const { profile } = data

  useEffect(() => {
    dispatch(setProfile({ profile: profile as Writable<ProfileQuery['response']['profile']> }))
  }, [dispatch, profile])

  useEffect(() => {
    if (undefined === uuid && me) {
      history.push(`/profile/${me.uuid}${search}`)
    }
  }, [me, profile, history, uuid, search])
}

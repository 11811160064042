const SportsIcon = ({ width = '16', height = '16', ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 15" fill="none">
    <path
      d="M7.00027 6.77779C7.61392 6.77779 8.11138 6.28033 8.11138 5.66668C8.11138 5.05303 7.61392 4.55557 7.00027 4.55557C6.38662 4.55557 5.88916 5.05303 5.88916 5.66668C5.88916 6.28033 6.38662 6.77779 7.00027 6.77779Z"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M7 14.1111V15" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M7 8.33334V8.77779" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M7 9.22224V9.66669" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M5.22217 13.4444H8.11106" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M4.77783 12.5556H9.22228" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path
      d="M8.77783 15V13.4445H9.22228V9.44446C9.22228 9.00002 12.1112 7.00002 12.1112 7.00002V4.55557"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M5.22249 15V13.4445H4.77805V9.44446C4.77805 9.00002 1.88916 7.00002 1.88916 7.00002V4.55557"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M11 5.22221V6.33332L8.33333 7.66666H5.66667L3 6.33332V5.22221"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M5.44444 4.55556H1V1H13V4.55556H8.55556" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path
      d="M8.33322 3.66668C8.82414 3.66668 9.22211 3.26871 9.22211 2.77779C9.22211 2.28687 8.82414 1.8889 8.33322 1.8889C7.84231 1.8889 7.44434 2.28687 7.44434 2.77779C7.44434 3.26871 7.84231 3.66668 8.33322 3.66668Z"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M6.11117 1.8889H5.66672C5.17561 1.8889 4.77783 2.28668 4.77783 2.77779C4.77783 3.2689 5.17561 3.66668 5.66672 3.66668C6.15783 3.66668 6.55561 3.22223 6.55561 2.77779H5.66672"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M10.5557 1.66664V2.99997" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M10.5557 3.44443V3.88887" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M11.4443 1.66664V2.99997" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M11.4443 3.44443V3.88887" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M2.55566 1.66664V2.99997" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M2.55566 3.44443V3.88887" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M3.44434 1.66664V2.99997" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M3.44434 3.44443V3.88887" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
  </svg>
)

export default SportsIcon

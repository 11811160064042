import sanityClient from '@src/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react'

interface ActionBannerContextModel {
  _id: string
  _type: string
  order: number
  header: string
  image: string
  redirect?: string
  modalWindow?: 'investor'
}

const ActionBannersContext = createContext<{ actionBanners: ActionBannerContextModel[]; fetchBanners: () => void }>({
  actionBanners: [],
  fetchBanners: () => {},
})

export const useActionBanners = () => useContext(ActionBannersContext)

export const ActionBannersProvider = ({ children }) => {
  const [actionBanners, setActionBanners] = useState<ActionBannerContextModel[]>([])
  const [isFetched, setIsFetched] = useState(false)

  const query = `*[_type == "actionBanners"]`

  const fetchBanners = useCallback(() => {
    if (!isFetched) {
      sanityClient.fetch(query).then((res) => {
        const banners = res
          .filter((b) => !b?._id.startsWith('drafts.'))
          .map((b) => ({ ...b, image: imageUrlBuilder(sanityClient).image(b.image).url() }))
          .sort((a, b) => (a.order > b.order ? 1 : -1))
        setActionBanners(banners)
        setIsFetched(true)
      })
    }
  }, [isFetched, query])

  useEffect(() => {
    if (!isFetched) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched])

  const value = useMemo(() => ({ actionBanners, fetchBanners }), [actionBanners, fetchBanners])

  return <ActionBannersContext.Provider value={value}>{children}</ActionBannersContext.Provider>
}

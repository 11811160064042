import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react'
import { Competition, competitionUpdatedSubscription, listCompetitionsQuery } from '@src/App'
import { DisposeFn, fetchQuery, requestSubscription } from 'relay-runtime'
import Env from '@src/RelayEnvironment'
import { AppComponentlistCompetitionsQuery } from '@src/__generated__/AppComponentlistCompetitionsQuery.graphql'
import { AppComponentCompetitionUpdatedSubscription } from '@src/__generated__/AppComponentCompetitionUpdatedSubscription.graphql'
import _ from 'lodash'

const CompetitionsContext = createContext({
  competitions: [] as Competition[],
  subscribeToCompetitions: () => {},
  unsubscribeFromCompetitions: () => {},
})

export const useCompetitions = () => useContext(CompetitionsContext)

export const CompetitionsProvider = ({ children }) => {
  const [competitions, setCompetitions] = useState<Competition[]>([])
  const updateQueue = useRef<Competition[]>([])
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const disposeSubscription = useRef<DisposeFn | null>(null)
  const [isSubscribed, setIsSubscribed] = useState(false)

  const fetchCompetitions = useCallback(() => {
    fetchQuery<AppComponentlistCompetitionsQuery>(
      Env,
      listCompetitionsQuery,
      {},
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: ({ listCompetitions }) => {
        setCompetitions(JSON.parse(JSON.stringify(listCompetitions)))
      },
      error: (error) => {
        console.log('Error fetching listCompetitions', error.message)
      },
    })
  }, [])

  const processUpdates = useCallback(() => {
    setCompetitions((currentCompetitions) => {
      const mergedCompetitions = [...currentCompetitions]
      updateQueue.current.forEach((update) => {
        const index = mergedCompetitions.findIndex((c) => c.compId === update.compId)
        if (index > -1) {
          mergedCompetitions[index] = { ...mergedCompetitions[index], ...update } // Merge updates
        } else {
          mergedCompetitions.push(update) // Add new competition
        }
      })

      // Clear the update queue after processing
      updateQueue.current = []
      return mergedCompetitions
    })

    // Clear the timeout ref
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  const subscribeToCompetitions = useCallback(() => {
    if (disposeSubscription.current) return // Already subscribed

    setIsSubscribed(true)

    const subscriptionConfig = {
      subscription: competitionUpdatedSubscription,
      variables: {},
      onNext: (data) => {
        if (!data || !data.competitionUpdated || null === data.competitionUpdated.runningRound) {
          return
        }

        // Add new data to the queue
        updateQueue.current.push(data.competitionUpdated)

        // Debounce updates to process them in batches
        if (!timeoutRef.current) {
          timeoutRef.current = setTimeout(processUpdates, 10000)
        }
      },
      onError: (error) => console.error(`Subscription error: ${error.message}`),
    }

    // Request the subscription
    disposeSubscription.current = requestSubscription<AppComponentCompetitionUpdatedSubscription>(
      Env,
      subscriptionConfig
    ).dispose
  }, [processUpdates])

  const unsubscribeFromCompetitions = useCallback(() => {
    if (disposeSubscription.current) {
      disposeSubscription.current() // Call the dispose function to unsubscribe
      disposeSubscription.current = null // Reset the ref
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current) // Clear any pending update processing
      timeoutRef.current = null
    }
    updateQueue.current = []
    setIsSubscribed(false)
  }, [])

  // Fetch initial data and keep fetching every minute if not subscribed
  useEffect(() => {
    let intervalId

    if (!isSubscribed) {
      fetchCompetitions()
      intervalId = setInterval(fetchCompetitions, 60000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isSubscribed, fetchCompetitions])

  const value = { competitions, subscribeToCompetitions, unsubscribeFromCompetitions }

  return <CompetitionsContext.Provider value={value}>{children}</CompetitionsContext.Provider>
}

import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery } from 'react-relay'
import { Subject } from 'rxjs'
import Env from '../RelayEnvironment'
import { ConfigurationServiceGlobalSettingsQuery } from './__generated__/ConfigurationServiceGlobalSettingsQuery.graphql'
import { ConfigurationServiceGlobalSettingsUpdatedSubscriptionResponse } from './__generated__/ConfigurationServiceGlobalSettingsUpdatedSubscription.graphql'

enum ConfigurationKey {
  BET_LIMIT = 'bet_limit',
  BET_LIMIT_MP = 'bet_limit_mp',
  CURRENCIES = 'currencies',
  GAMES_DISABLED = 'disabled',
  RAIN_LIMITS = 'rain_limits',
  TIP_LIMITS = 'tip_limits',
}

export enum CurrencyEnum {
  FUN = 'FUN',
  BTC = 'BTC',
  DASH = 'DASH',
  BCH = 'BCH',
  LTC = 'LTC',
  ETH = 'ETH',
  USDT = 'USDT',
  ADA = 'ADA',
  TRX = 'TRX',
  BNB = 'BNB',
  XMR = 'XMR',
  XRP = 'XRP',
  USDC = 'USDC',
  DOGE = 'DOGE',
  BUSD = 'BUSD',
  SOL = 'SOL',
  SHIB = 'SHIB',
  PEPE = 'PEPE',
  WAVES = 'WAVES',
  ETC = 'ETC',
  BTG = 'BTG',
  MANA = 'MANA',
  ZEC = 'ZEC',
  DGB = 'DGB',
  MATIC = 'MATIC',
  ALGO = 'ALGO',
  APT = 'APT',
  ATOM = 'ATOM',
  AVA = 'AVA',
  AVAX = 'AVAX',
  AXS = 'AXS',
  BSV = 'BSV',
  CELO = 'CELO',
  CRO = 'CRO',
  DAI = 'DAI',
  DCR = 'DCR',
  DOT = 'DOT',
  EGLD = 'EGLD',
  FIL = 'FIL',
  FLOKI = 'FLOKI',
  FTM = 'FTM',
  ICP = 'ICP',
  ICX = 'ICX',
  IOTX = 'IOTX',
  KAVA = 'KAVA',
  KLAY = 'KLAY',
  KSM = 'KSM',
  NEAR = 'NEAR',
  OKT = 'OKT',
  ONE = 'ONE',
  QTUM = 'QTUM',
  ROSE = 'ROSE',
  RVN = 'RVN',
  THETA = 'THETA',
  TOMO = 'TOMO',
  VET = 'VET',
  XLM = 'XLM',
  XTZ = 'XTZ',
  ZIL = 'ZIL',
  GALA = 'GALA',
  KMD = 'KMD',
  LINK = 'LINK',
  XEC = 'XEC',
  JACK = 'JACK',
  BabyDoge = 'BabyDoge',
  KISHU = 'KISHU',
  LOVELY = 'LOVELY',
  ARB = 'ARB',
  PolyDoge = 'PolyDoge',
  BAD = 'BAD',
  TON = 'TON',
  SPACE = 'SPACE',
  BFG = 'BFG',
  CROP = 'CROP',
  KINGSHIB = 'KINGSHIB',
  OGGY = 'OGGY',
  BONK = 'BONK',
  JTO = 'JTO',
  MSOL = 'MSOL',
  COQ = 'COQ',
}

export interface Currency {
  short: string
  long: string
  m_unit: string
  s_unit: string
  format: string
}

interface BetLimit {
  min: number
  max: number
}

export type CurrencyList = {
  [key in CurrencyEnum]: Currency
}

type Config = {
  [key in CurrencyEnum]: BetLimit | Currency
}

type BetLimit2 = {
  [key in string]: BetLimit
}

export const globalConfUpdated = new Subject<void>()

const settingsQuery = graphql`
  query ConfigurationServiceGlobalSettingsQuery {
    globalSettings {
      betLimits {
        currency
        limits
      }
      betLimitsMp {
        currency
        limits
      }
      disabled
      rainLimits
      tipLimits {
        tipMax
        tipMin
      }
    }
  }
`

export const globalSettingsUpdatedSubscription = graphql`
  subscription ConfigurationServiceGlobalSettingsUpdatedSubscription {
    globalSettingsUpdated {
      betLimits {
        currency
        limits
      }
      betLimitsMp {
        currency
        limits
      }
      disabled
      rainLimits
      tipLimits {
        tipMax
        tipMin
      }
    }
  }
`

class ConfigurationService {
  public static instance = new ConfigurationService()
  private config: Map<ConfigurationKey, any> = new Map()

  private constructor() {}

  public async load(): Promise<void> {
    this.config = new Map<ConfigurationKey, Config>([
      [
        ConfigurationKey.BET_LIMIT,
        {
          // Will be replaced on runtime
          BTC: { min: 100, max: 10000000 },
          DASH: { min: 100, max: 10000000 },
          FUN: { min: 100, max: 10000000 },
          LTC: { min: 100, max: 10000000 },
          BCH: { min: 100, max: 10000000 },
          ETH: { min: 100, max: 10000000 },
          USDT: { min: 100, max: 10000000 },
          ADA: { min: 100, max: 10000000 },
          TRX: { min: 100, max: 10000000 },
          BNB: { min: 100, max: 10000000 },
          XMR: { min: 100, max: 10000000 },
          XRP: { min: 100, max: 10000000},
          USDC: { min: 100, max: 10000000},
          DOGE: { min: 100, max: 10000000},
          BUSD: { min: 100, max: 10000000},
          SOL: { min: 100, max: 10000000},
          SHIB: { min: 100, max: 10000000},
          PEPE: { min: 100, max: 10000000},
          WAVES: { min: 100, max: 10000000},
          ETC: { min: 100, max: 10000000},
          BTG: { min: 100, max: 10000000},
          MANA: { min: 100, max: 10000000},
          ZEC: { min: 100, max: 10000000},
          DGB: { min: 100, max: 10000000},
          MATIC: { min: 100, max: 10000000},
          ALGO: { min: 100, max: 10000000},
          APT: { min: 100, max: 10000000},
          ATOM: { min: 100, max: 10000000},
          AVA: { min: 100, max: 10000000},
          AVAX: { min: 100, max: 10000000},
          AXS: { min: 100, max: 10000000},
          BSV: { min: 100, max: 10000000},
          CELO: { min: 100, max: 10000000},
          CRO: { min: 100, max: 10000000},
          DAI: { min: 100, max: 10000000},
          DCR: { min: 100, max: 10000000},
          DOT: { min: 100, max: 10000000},
          EGLD: { min: 100, max: 10000000},
          FIL: { min: 100, max: 10000000},
          FLOKI: { min: 100, max: 10000000},
          FTM: { min: 100, max: 10000000},
          ICP: { min: 100, max: 10000000},
          ICX: { min: 100, max: 10000000},
          IOTX: { min: 100, max: 10000000},
          KAVA: { min: 100, max: 10000000},
          KLAY: { min: 100, max: 10000000},
          KSM: { min: 100, max: 10000000},
          NEAR: { min: 100, max: 10000000},
          OKT: { min: 100, max: 10000000},
          ONE: { min: 100, max: 10000000},
          QTUM: { min: 100, max: 10000000},
          ROSE: { min: 100, max: 10000000},
          RVN: { min: 100, max: 10000000},
          THETA: { min: 100, max: 10000000},
          TOMO: { min: 100, max: 10000000},
          VET: { min: 100, max: 10000000},
          XLM: { min: 100, max: 10000000},
          XTZ: { min: 100, max: 10000000},
          ZIL: { min: 100, max: 10000000},
          GALA: { min: 100, max: 10000000},
          KMD: { min: 100, max: 10000000},
          LINK: { min: 100, max: 10000000},
          XEC: { min: 100, max: 10000000},
          JACK: { min: 100, max: 10000000},
          BabyDoge: { min: 100, max: 10000000},
          KISHU: { min: 100, max: 10000000},
          LOVELY: { min: 100, max: 10000000},
          ARB: { min: 100, max: 10000000},
          PolyDoge: { min: 100, max: 10000000},
          BAD: { min: 100, max: 10000000},
          TON: { min: 100, max: 10000000},
          SPACE: { min: 100, max: 10000000},
          BFG: { min: 100, max: 10000000},
          CROP: { min: 100, max: 10000000},
          KINGSHIB: { min: 100, max: 10000000},
          OGGY: { min: 100, max: 10000000},
          BONK: { min: 100, max: 10000000},
          JTO: { min: 100, max: 10000000},
          MSOL: { min: 100, max: 10000000},
          COQ: { min: 100, max: 10000000},
        },
      ],
      [
        ConfigurationKey.CURRENCIES,
        {
          FUN: {
            short: 'FUN',
            long: 'FUN',
            s_unit: 'cent',
            m_unit: 'XXX',
            format: 'cent',
          },
          BTC: {
            short: 'BTC',
            long: 'Bitcoin',
            s_unit: 'sat',
            m_unit: 'BTC',
            format: 'uBTC',
          },
          BTG: {
            short: 'BTC',
            long: 'Bitcoin',
            s_unit: 'sat',
            m_unit: 'BTC',
            format: 'uBTC',
          },
          DASH: {
            short: 'DASH',
            long: 'Dash',
            s_unit: 'duff',
            m_unit: 'DASH',
            format: 'mDASH',
          },
          LTC: {
            short: 'LTC',
            long: 'Litecoin',
            s_unit: 'lit',
            m_unit: 'LTC',
            format: 'mLTC',
          },
          BCH: {
            short: 'BCH',
            long: 'Bitcoin cash',
            s_unit: 'sat',
            m_unit: 'BCH',
            format: 'uBCH',
          },
          ETH: {
            short: 'ETH',
            long: 'Ethereum',
            s_unit: 'Gwei',
            m_unit: 'ETH',
            format: 'ETH',
          },
          ETC: {
            short: 'ETH',
            long: 'Ethereum classic',
            s_unit: 'Gwei',
            m_unit: 'ETH',
            format: 'ETH',
          },
          USDT: {
            short: 'USDT',
            long: 'USDT',
            s_unit: 'raw',
            m_unit: 'USDT',
            format: 'USDT',
          },
          ADA: {
            short: 'ADA',
            long: 'Cardano',
            s_unit: 'drop',
            m_unit: 'ADA',
            format: 'ADA',
          },
          TRX: {
            short: 'TRX',
            long: 'Tron',
            s_unit: 'SUN',
            m_unit: 'TRX',
            format: 'TRX',
          },
          BNB: {
            short: 'BNB',
            long: 'BNB',
            s_unit: 'Gwei',
            m_unit: 'BNB',
            format: 'BNB',
          },
          XMR: {
            short: 'XMR',
            long: 'Monero',
            s_unit: 'pico',
            m_unit: 'XMR',
            format: 'micro',
          },
          XRP: {
            short: 'XRP',
            long: 'Ripple',
            s_unit: 'drop',
            m_unit: 'XRP',
            format: 'mXRP',
          },
          USDC: {
            short: 'USDC',
            long: 'USDC',
            s_unit: 'raw',
            m_unit: 'USDC',
            format: 'USDC',
          },
          BUSD: {
            short: 'BUSD',
            long: 'BUSD',
            s_unit: 'raw',
            m_unit: 'BUSD',
            format: 'BUSD',
          },
          DOGE: {
            short: 'DOGE',
            long: 'DOGE',
            s_unit: 'raw',
            m_unit: 'DOGE',
            format: 'DOGE',
          },
          SOL: {
            short: 'SOL',
            long: 'SOL',
            s_unit: 'raw',
            m_unit: 'SOL',
            format: 'SOL',
          },
          SHIB: {
            short: 'SHIB',
            long: 'SHIB',
            s_unit: 'raw',
            m_unit: 'SHIB',
            format: 'SHIB',
          },
          PEPE: {
            short: 'PEPE',
            long: 'PEPE',
            s_unit: 'raw',
            m_unit: 'PEPE',
            format: 'PEPE',
          },
          WAVES: {
            short: 'WAVES',
            long: 'WAVES',
            s_unit: 'raw',
            m_unit: 'WAVES',
            format: 'WAVES',
          },
          MANA: {
            short: 'MANA',
            long: 'MANA',
            s_unit: 'raw',
            m_unit: 'MANA',
            format: 'MANA',
          },
          XLM: {
            short: 'XLM',
            long: 'XLM',
            s_unit: 'stroop',
            m_unit: 'XLM',
            format: 'XLM',
          },
          ZEC: {
            short: 'ZEC',
            long: 'ZEC',
            s_unit: 'raw',
            m_unit: 'ZEC',
            format: 'ZEC',
          },
          DGB: {
            short: 'DGB',
            long: 'DGB',
            s_unit: 'raw',
            m_unit: 'DGB',
            format: 'DGB',
          },
          MATIC: {
            short: 'MATIC',
            long: 'MATIC',
            s_unit: 'raw',
            m_unit: 'MATIC',
            format: 'MATIC',
          },
          ALGO: {
            short: 'ALGO',
            long: 'ALGO',
            s_unit: 'raw',
            m_unit: 'ALGO',
            format: 'ALGO',
          },
          APT: {
            short: 'APT',
            long: 'APT',
            s_unit: 'raw',
            m_unit: 'APT',
            format: 'APT',
          },
          ATOM: {
            short: 'ATOM',
            long: 'ATOM',
            s_unit: 'raw',
            m_unit: 'ATOM',
            format: 'ATOM',
          },
          AVA: {
            short: 'AVA',
            long: 'AVA',
            s_unit: 'raw',
            m_unit: 'AVA',
            format: 'AVA',
          },
          AVAX: {
            short: 'AVAX',
            long: 'AVAX',
            s_unit: 'raw',
            m_unit: 'AVAX',
            format: 'AVAX',
          },
          AXS: {
            short: 'AXS',
            long: 'AXS',
            s_unit: 'raw',
            m_unit: 'AXS',
            format: 'AXS',
          },
          BSV: {
            short: 'BSV',
            long: 'BSV',
            s_unit: 'raw',
            m_unit: 'BSV',
            format: 'BSV',
          },
          CELO: {
            short: 'CELO',
            long: 'CELO',
            s_unit: 'raw',
            m_unit: 'CELO',
            format: 'CELO',
          },
          CRO: {
            short: 'CRO',
            long: 'CRO',
            s_unit: 'raw',
            m_unit: 'CRO',
            format: 'CRO',
          },
          DAI: {
            short: 'DAI',
            long: 'DAI',
            s_unit: 'raw',
            m_unit: 'DAI',
            format: 'DAI',
          },
          DCR: {
            short: 'DCR',
            long: 'DCR',
            s_unit: 'raw',
            m_unit: 'DCR',
            format: 'DCR',
          },
          DOT: {
            short: 'DOT',
            long: 'DOT',
            s_unit: 'raw',
            m_unit: 'DOT',
            format: 'DOT',
          },
          EGLD: {
            short: 'EGLD',
            long: 'EGLD',
            s_unit: 'raw',
            m_unit: 'EGLD',
            format: 'EGLD',
          },
          FIL: {
            short: 'FIL',
            long: 'FIL',
            s_unit: 'raw',
            m_unit: 'FIL',
            format: 'FIL',
          },
          FLOKI: {
            short: 'FLOKI',
            long: 'FLOKI',
            s_unit: 'raw',
            m_unit: 'FLOKI',
            format: 'FLOKI',
          },
          FTM: {
            short: 'FTM',
            long: 'FTM',
            s_unit: 'raw',
            m_unit: 'FTM',
            format: 'FTM',
          },
          ICP: {
            short: 'ICP',
            long: 'ICP',
            s_unit: 'raw',
            m_unit: 'ICP',
            format: 'ICP',
          },
          ICX: {
            short: 'ICX',
            long: 'ICX',
            s_unit: 'raw',
            m_unit: 'ICX',
            format: 'ICX',
          },
          IOTX: {
            short: 'IOTX',
            long: 'IOTX',
            s_unit: 'raw',
            m_unit: 'IOTX',
            format: 'IOTX',
          },
          KAVA: {
            short: 'KAVA',
            long: 'KAVA',
            s_unit: 'raw',
            m_unit: 'KAVA',
            format: 'KAVA',
          },
          KLAY: {
            short: 'KLAY',
            long: 'KLAY',
            s_unit: 'raw',
            m_unit: 'KLAY',
            format: 'KLAY',
          },
          KSM: {
            short: 'KSM',
            long: 'KSM',
            s_unit: 'raw',
            m_unit: 'KSM',
            format: 'KSM',
          },
          NEAR: {
            short: 'NEAR',
            long: 'NEAR',
            s_unit: 'raw',
            m_unit: 'NEAR',
            format: 'NEAR',
          },
          OKT: {
            short: 'OKT',
            long: 'OKT',
            s_unit: 'raw',
            m_unit: 'OKT',
            format: 'OKT',
          },
          ONE: {
            short: 'ONE',
            long: 'ONE',
            s_unit: 'raw',
            m_unit: 'ONE',
            format: 'ONE',
          },
          QTUM: {
            short: 'QTUM',
            long: 'QTUM',
            s_unit: 'raw',
            m_unit: 'QTUM',
            format: 'QTUM',
          },
          ROSE: {
            short: 'ROSE',
            long: 'ROSE',
            s_unit: 'raw',
            m_unit: 'ROSE',
            format: 'ROSE',
          },
          RVN: {
            short: 'RVN',
            long: 'RVN',
            s_unit: 'raw',
            m_unit: 'RVN',
            format: 'RVN',
          },
          THETA: {
            short: 'THETA',
            long: 'THETA',
            s_unit: 'raw',
            m_unit: 'THETA',
            format: 'THETA',
          },
          TOMO: {
            short: 'TOMO',
            long: 'TOMO',
            s_unit: 'raw',
            m_unit: 'TOMO',
            format: 'TOMO',
          },
          VET: {
            short: 'VET',
            long: 'VET',
            s_unit: 'raw',
            m_unit: 'VET',
            format: 'VET',
          },
          XTZ: {
            short: 'XTZ',
            long: 'XTZ',
            s_unit: 'raw',
            m_unit: 'XTZ',
            format: 'XTZ',
          },
          ZIL: {
            short: 'ZIL',
            long: 'ZIL',
            s_unit: 'raw',
            m_unit: 'ZIL',
            format: 'ZIL',
          },
          GALA: {
            short: 'GALA',
            long: 'GALA',
            s_unit: 'raw',
            m_unit: 'GALA',
            format: 'GALA',
          },
          KMD: {
            short: 'KMD',
            long: 'KMD',
            s_unit: 'raw',
            m_unit: 'KMD',
            format: 'KMD',
          },
          LINK: {
            short: 'LINK',
            long: 'LINK',
            s_unit: 'raw',
            m_unit: 'LINK',
            format: 'LINK',
          },
          XEC: {
            short: 'XEC',
            long: 'XEC',
            s_unit: 'raw',
            m_unit: 'XEC',
            format: 'XEC',
          },
          JACK: {
            short: 'JACK',
            long: 'JACK',
            s_unit: 'raw',
            m_unit: 'JACK',
            format: 'JACK',
          },
          SPACE: {
            short: 'SPACE',
            long: 'SPACE',
            s_unit: 'raw',
            m_unit: 'SPACE',
            format: 'SPACE',
          },
          TON: {
            short: 'TON',
            long: 'TON',
            s_unit: 'raw',
            m_unit: 'TON',
            format: 'TON',
          },
          BAD: {
            short: 'BAD',
            long: 'BAD',
            s_unit: 'raw',
            m_unit: 'BAD',
            format: 'BAD',
          },
          PolyDoge: {
            short: 'PolyDoge',
            long: 'PolyDoge',
            s_unit: 'raw',
            m_unit: 'PolyDoge',
            format: 'PolyDoge',
          },
          ARB: {
            short: 'ARB',
            long: 'ARB',
            s_unit: 'raw',
            m_unit: 'ARB',
            format: 'ARB',
          },
          LOVELY: {
            short: 'LOVELY',
            long: 'LOVELY',
            s_unit: 'raw',
            m_unit: 'LOVELY',
            format: 'LOVELY',
          },
          KISHU: {
            short: 'KISHU',
            long: 'KISHU',
            s_unit: 'raw',
            m_unit: 'KISHU',
            format: 'KISHU',
          },
          BabyDoge: {
            short: 'BabyDoge',
            long: 'BabyDoge',
            s_unit: 'raw',
            m_unit: 'BabyDoge',
            format: 'BabyDoge',
          },
          COQ: {
            short: 'COQ',
            long: 'COQ',
            s_unit: 'raw',
            m_unit: 'COQ',
            format: 'COQ',
          },
          MSOL: {
            short: 'MSOL',
            long: 'MSOL',
            s_unit: 'raw',
            m_unit: 'MSOL',
            format: 'MSOL',
          },
          JTO: {
            short: 'JTO',
            long: 'JTO',
            s_unit: 'raw',
            m_unit: 'JTO',
            format: 'JTO',
          },
          BONK: {
            short: 'BONK',
            long: 'BONK',
            s_unit: 'raw',
            m_unit: 'BONK',
            format: 'BONK',
          },
          OGGY: {
            short: 'OGGY',
            long: 'OGGY',
            s_unit: 'raw',
            m_unit: 'OGGY',
            format: 'OGGY',
          },
          KINGSHIB: {
            short: 'KINGSHIB',
            long: 'KINGSHIB',
            s_unit: 'raw',
            m_unit: 'KINGSHIB',
            format: 'KINGSHIB',
          },
          CROP: {
            short: 'CROP',
            long: 'CROP',
            s_unit: 'raw',
            m_unit: 'CROP',
            format: 'CROP',
          },
          BFG: {
            short: 'BFG',
            long: 'BFG',
            s_unit: 'raw',
            m_unit: 'BFG',
            format: 'BFG',
          },
        }
      ],
    ])
    return this.reload()
  }

  public reload() {
    return new Promise<void>((resolve, reject) => {
      fetchQuery<ConfigurationServiceGlobalSettingsQuery>(Env, settingsQuery, {}).subscribe({
        next: ({ globalSettings }) => {
          const limits = {}
          if (!globalSettings?.betLimits || !globalSettings.betLimitsMp) {
            return
          }
          globalSettings.betLimits.forEach((limit: { currency: string; limits: readonly number[] }) => {
            limits[limit.currency] = { min: limit.limits[0], max: limit.limits[1] }
          })
          const limitsMp = {}
          globalSettings.betLimitsMp.forEach((limit: { currency: string; limits: readonly number[] }) => {
            limitsMp[limit.currency] = { min: limit.limits[0], max: limit.limits[1] }
          })

          this.config.set(ConfigurationKey.BET_LIMIT_MP, limitsMp)
          this.config.set(ConfigurationKey.BET_LIMIT, limits)
          this.config.set(ConfigurationKey.GAMES_DISABLED, globalSettings.disabled)
          this.config.set(ConfigurationKey.RAIN_LIMITS, globalSettings.rainLimits)
          this.config.set(ConfigurationKey.TIP_LIMITS, globalSettings.tipLimits)

          globalConfUpdated.next()
          resolve()
        },
        error: () => {
          reject()
        },
      })
    })
  }

  public updateFromResponse({ globalSettingsUpdated }: ConfigurationServiceGlobalSettingsUpdatedSubscriptionResponse) {
    const globalSettings = globalSettingsUpdated
    const limits = {} as BetLimit2
    if (!globalSettings?.betLimits || !globalSettings.betLimitsMp) {
      return
    }
    globalSettings.betLimits.forEach((limit: { currency: string; limits: readonly number[] }) => {
      limits[limit.currency] = { min: limit.limits[0], max: limit.limits[1] }
    })
    const limitsMp = {} as BetLimit2
    globalSettings.betLimitsMp.forEach((limit: { currency: string; limits: readonly number[] }) => {
      limitsMp[limit.currency] = { min: limit.limits[0], max: limit.limits[1] }
    })

    this.config.set(ConfigurationKey.BET_LIMIT_MP, limitsMp as Config)
    this.config.set(ConfigurationKey.BET_LIMIT, limits as Config)
    this.config.set(ConfigurationKey.GAMES_DISABLED, globalSettings.disabled)
    globalConfUpdated.next()
  }

  public getCurrencies(): CurrencyList {
    return this.config.get(ConfigurationKey.CURRENCIES) as { [key in CurrencyEnum]: Currency }
  }

  public getCurrency(shortName: string): Currency {
    const allCurrencies = this.getCurrencies()
    const key = Object.keys(allCurrencies).find((x) => x.toLowerCase() === shortName.toLowerCase())
    if (key) {
      return allCurrencies[key as CurrencyEnum]
    }

    // Default to FUN
    return allCurrencies[CurrencyEnum.FUN]
  }

  public getBTCLikeCurrencies(): string[] {
    return ['BTC', 'DASH', 'LTC', 'BCH']
  }

  public getTradeableCurrencies(): CurrencyList {
    const all = this.config.get(ConfigurationKey.CURRENCIES) as { [key in CurrencyEnum]: Currency }
    const currencies = {
      BTC: all[CurrencyEnum.BTC],
      USDT: all[CurrencyEnum.USDT],
      ETH: all[CurrencyEnum.ETH],
      LTC: all[CurrencyEnum.LTC],
      TRX: all[CurrencyEnum.TRX],
      BNB: all[CurrencyEnum.BNB],
      MATIC: all[CurrencyEnum.MATIC],
      DASH: all[CurrencyEnum.DASH],
      JACK: all[CurrencyEnum.JACK],
      BCH: all[CurrencyEnum.BCH],
      ADA: all[CurrencyEnum.ADA],
      XMR: all[CurrencyEnum.XMR],
      XRP: all[CurrencyEnum.XRP],
      USDC: all[CurrencyEnum.USDC],
      DOGE: all[CurrencyEnum.DOGE],
      BUSD: all[CurrencyEnum.BUSD],
      SOL: all[CurrencyEnum.SOL],
      SHIB: all[CurrencyEnum.SHIB],
      PEPE: all[CurrencyEnum.PEPE],
      WAVES: all[CurrencyEnum.WAVES],
      ETC: all[CurrencyEnum.ETC],
      BTG: all[CurrencyEnum.BTG],
      MANA: all[CurrencyEnum.MANA],
      ZEC: all[CurrencyEnum.ZEC],
      DGB: all[CurrencyEnum.DGB],
      ALGO: all[CurrencyEnum.ALGO],
      APT: all[CurrencyEnum.APT],
      ATOM: all[CurrencyEnum.ATOM],
      AVA: all[CurrencyEnum.AVA],
      AVAX: all[CurrencyEnum.AVAX],
      AXS: all[CurrencyEnum.AXS],
      BSV: all[CurrencyEnum.BSV],
      CELO: all[CurrencyEnum.CELO],
      CRO: all[CurrencyEnum.CRO],
      DAI: all[CurrencyEnum.DAI],
      DCR: all[CurrencyEnum.DCR],
      DOT: all[CurrencyEnum.DOT],
      EGLD: all[CurrencyEnum.EGLD],
      FIL: all[CurrencyEnum.FIL],
      FLOKI: all[CurrencyEnum.FLOKI],
      FTM: all[CurrencyEnum.FTM],
      ICP: all[CurrencyEnum.ICP],
      ICX: all[CurrencyEnum.ICX],
      IOTX: all[CurrencyEnum.IOTX],
      KAVA: all[CurrencyEnum.KAVA],
      KLAY: all[CurrencyEnum.KLAY],
      KSM: all[CurrencyEnum.KSM],
      NEAR: all[CurrencyEnum.NEAR],
      OKT: all[CurrencyEnum.OKT],
      ONE: all[CurrencyEnum.ONE],
      QTUM: all[CurrencyEnum.QTUM],
      ROSE: all[CurrencyEnum.ROSE],
      RVN: all[CurrencyEnum.RVN],
      THETA: all[CurrencyEnum.THETA],
      TOMO: all[CurrencyEnum.TOMO],
      VET: all[CurrencyEnum.VET],
      XLM: all[CurrencyEnum.XLM],
      XTZ: all[CurrencyEnum.XTZ],
      ZIL: all[CurrencyEnum.ZIL],
      GALA: all[CurrencyEnum.GALA],
      KMD: all[CurrencyEnum.KMD],
      LINK: all[CurrencyEnum.LINK],
      XEC: all[CurrencyEnum.XEC],
      BabyDoge: all[CurrencyEnum.BabyDoge],
      KISHU: all[CurrencyEnum.KISHU],
      LOVELY: all[CurrencyEnum.LOVELY],
      ARB: all[CurrencyEnum.ARB],
      PolyDoge: all[CurrencyEnum.PolyDoge],
      BAD: all[CurrencyEnum.BAD],
      TON: all[CurrencyEnum.TON],
      SPACE: all[CurrencyEnum.SPACE],
      BFG: all[CurrencyEnum.BFG],
      CROP: all[CurrencyEnum.CROP],
      KINGSHIB: all[CurrencyEnum.KINGSHIB],
      OGGY: all[CurrencyEnum.OGGY],
      BONK: all[CurrencyEnum.BONK],
      JTO: all[CurrencyEnum.JTO],
      MSOL: all[CurrencyEnum.MSOL],
      COQ: all[CurrencyEnum.COQ],
    } as CurrencyList
    return currencies
  }

  public getExternalCurrencies(): CurrencyEnum[] {
    return [
      CurrencyEnum.ADA,
      CurrencyEnum.XRP,
      CurrencyEnum.USDT,
      CurrencyEnum.TRX,
      CurrencyEnum.BNB,
      CurrencyEnum.ETH,
      CurrencyEnum.USDC,
      CurrencyEnum.DOGE,
      CurrencyEnum.BUSD,
      CurrencyEnum.SOL,
      CurrencyEnum.SHIB,
      CurrencyEnum.PEPE,
      CurrencyEnum.WAVES,
      CurrencyEnum.ETC,
      CurrencyEnum.BTG,
      CurrencyEnum.MANA,
      CurrencyEnum.ZEC,
      CurrencyEnum.DGB,
      CurrencyEnum.MATIC,
      CurrencyEnum.ALGO,
      CurrencyEnum.APT,
      CurrencyEnum.ATOM,
      CurrencyEnum.AVA,
      CurrencyEnum.AVAX,
      CurrencyEnum.AXS,
      CurrencyEnum.BSV,
      CurrencyEnum.CELO,
      CurrencyEnum.CRO,
      CurrencyEnum.DAI,
      CurrencyEnum.DCR,
      CurrencyEnum.DOT,
      CurrencyEnum.EGLD,
      CurrencyEnum.FIL,
      CurrencyEnum.FLOKI,
      CurrencyEnum.FTM,
      CurrencyEnum.ICP,
      CurrencyEnum.ICX,
      CurrencyEnum.IOTX,
      CurrencyEnum.KAVA,
      CurrencyEnum.KLAY,
      CurrencyEnum.KSM,
      CurrencyEnum.NEAR,
      CurrencyEnum.OKT,
      CurrencyEnum.ONE,
      CurrencyEnum.QTUM,
      CurrencyEnum.ROSE,
      CurrencyEnum.RVN,
      CurrencyEnum.THETA,
      CurrencyEnum.TOMO,
      CurrencyEnum.VET,
      CurrencyEnum.XLM,
      CurrencyEnum.XTZ,
      CurrencyEnum.ZIL,
      CurrencyEnum.GALA,
      CurrencyEnum.KMD,
      CurrencyEnum.LINK,
      CurrencyEnum.XEC,
      CurrencyEnum.BabyDoge,
      CurrencyEnum.KISHU,
      CurrencyEnum.LOVELY,
      CurrencyEnum.ARB,
      CurrencyEnum.PolyDoge,
      CurrencyEnum.BAD,
      CurrencyEnum.TON,
      CurrencyEnum.SPACE,
      CurrencyEnum.BFG,
      CurrencyEnum.CROP,
      CurrencyEnum.KINGSHIB,
      CurrencyEnum.OGGY,
      CurrencyEnum.BONK,
      CurrencyEnum.JTO,
      CurrencyEnum.MSOL,
      CurrencyEnum.COQ,
    ]
  }

  public getBetLimits(currency: Currency): { min: number; max: number } {
    if (currency === undefined) {
      return { min: 0, max: 0 }
    }
    const limits = this.config.get(ConfigurationKey.BET_LIMIT) as BetLimit2
    if (currency.short === 'XXX') {
      return limits['FUN' as string]
    }
    if (currency.short in limits) {
      return limits[currency.short]
    }
    return {min: 0, max: Number.MAX_VALUE};
  }

  public getBetLimitsMp(currency: Currency): { min: number; max: number } {
    if (currency === undefined) {
      return { min: 0, max: 0 }
    }
    const limits = this.config.get(ConfigurationKey.BET_LIMIT_MP) as BetLimit2
    if (currency.short === 'XXX') {
      return limits['FUN' as string]
    }
    if (currency.short in limits) {
      return limits[currency.short]
    }
    return {min: 0, max: Number.MAX_VALUE};
  }

  public areGamesDisabled(): boolean {
    return this.config.get(ConfigurationKey.GAMES_DISABLED)
  }

  public getRainLimitForCurrency(currency: string): [number, number] {
    const limits = this.config.get(ConfigurationKey.RAIN_LIMITS)
    return [limits['rain_min_' + currency], limits['rain_max_' + currency]]
  }

  public getTippingLimitsInUSD(): [number, number] {
    const limits = this.config.get(ConfigurationKey.TIP_LIMITS)
    return [limits.tipMin, limits.tipMax]
  }
}

export { ConfigurationKey }
export default ConfigurationService

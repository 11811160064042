import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Env from '@src/RelayEnvironment'
import { fetchQuery } from 'relay-runtime'
import { IStore, store } from '@src/store'
import { AppTypes } from '@src/reducers/app.reducer'
import { graphql } from 'babel-plugin-relay/macro'
import classNames from 'classnames'

import { Notification } from './Notification'

import {
  NotificationsListNotificationsQuery,
  NotificationsListNotificationsQuery$data,
} from './__generated__/NotificationsListNotificationsQuery.graphql'

import { useIsMobile } from '@src/hooks'
import { useLockScroll } from '@src/hooks/useLockScroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Announcement as AnnouncmentComponent } from './Announcement'
import { useTranslation } from 'react-i18next'

import classes from './Notifications.module.scss'

import sanityClient from '@src/sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import Close from '../icons/close'
const builder = imageUrlBuilder(sanityClient)

type Announcement = {
  _id: string
  _type: string
  _createdAt: string
  _updatedAt: string
  order: number
  header: string
  text: string
  imageSRC: string
}

export function Notifications() {
  const isOpen = useSelector((state: IStore) => state.app.isNotificationsOpen)
  const isChatOpen = useSelector((state: IStore) => state.app.chatOpened)
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const user = useSelector((state: IStore) => state.authentication.user)

  const [isNotificationsQueryLoading, setIsNotificationQueryLoading] = useState(true)
  const [isAnnouncementsQueryLoading, setIsAnnouncementsQueryLoading] = useState(true)

  useLockScroll(isOpen && isMobile)

  const [selectedTab, setSelectedTab] = useState<'notifications' | 'announcements'>('notifications')

  const [announcements, setAnnouncements] = useState<Announcement[]>([])
  const [notifications, setNotifications] = useState<
    NotificationsListNotificationsQuery$data['listNotifications']['entries']
  >([])

  useEffect(() => {
    if (selectedTab !== 'notifications') return
    fetchQuery<NotificationsListNotificationsQuery>(
      Env,
      listNotificationsQuery,
      {
        page: 1,
        pageSize: 999,
        filter: {
          type: 'all',
        },
      },
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: ({ listNotifications }) => {
        if (listNotifications && listNotifications.entries) {
          const { entries } = listNotifications
          const sorted = Array.from(entries).sort((a, b) => b.notificationId - a.notificationId)
          setNotifications(sorted)
        }
        setIsNotificationQueryLoading(false)
      },
    })
  }, [selectedTab])

  useEffect(() => {
    if (selectedTab !== 'announcements') return

    sanityClient.fetch('*[_type == "announcements"]').then((data: any) => {
      const filtered = data?.filter((d: any) => !d._id.startsWith('draft')) || []
      const mappedAnnouncements = filtered.map((d) => ({
        _id: d._id,
        _type: d._type,
        _createdAt: d._createdAt,
        _updatedAt: d._updatedAt,
        order: d.order,
        header: d.header,
        text: d.text,
        imageSRC: builder.image(d.image).url(),
      })) as Announcement[]

      setAnnouncements(mappedAnnouncements)
      setIsAnnouncementsQueryLoading(false)
    })
  }, [selectedTab])

  useEffect(() => {
    if (isOpen) {
      document.getElementById('notifications')?.focus()
    }
  }, [isOpen])

  useEffect(() => {
    if (isChatOpen) {
      store.dispatch({
        type: AppTypes.TOGGLE_NOTIFICATIONS,
        isOpenedNotification: false,
      })
    }
  }, [isChatOpen])

  const isLoading =
    (isNotificationsQueryLoading && selectedTab === 'notifications') ||
    (isAnnouncementsQueryLoading && selectedTab === 'announcements')

  return (
    <div
      id="notifications"
      className={classNames({
        [classes.root]: isOpen,
        [classes.root_closed]: !isOpen,
      })}
    >
      <div className={classes.header}>
        <h2
          className={classNames({
            [classes.activeTab]: selectedTab === 'notifications',
          })}
          onClick={() => setSelectedTab('notifications')}
        >
          {t('notifications.nots', 'Notifications')}
        </h2>
        <h2
          className={classNames({
            [classes.activeTab]: selectedTab === 'announcements',
          })}
          onClick={() => setSelectedTab('announcements')}
        >
          {t('notifications.announcements', 'Announcements')}
        </h2>
        <Close
          onClick={() => store.dispatch({ type: AppTypes.TOGGLE_NOTIFICATIONS })}
          className="w-28 h-28 text-white cursor-pointer"
        />
      </div>
      {isLoading ? (
        <div className="text-white h-full flex items-center text-3xl">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <div
          className={classNames(classes.wrapper, {
            'bg-raisinBlack': selectedTab === 'notifications',
          })}
        >
          {selectedTab === 'notifications' &&
            notifications
              ?.filter((n) => n.type !== 'USER_LOGIN')
              .map((n, j) => <Notification key={j} type={n.type} insertedAt={n.insertedAt} data={n.meta} />)}
          {selectedTab === 'announcements' &&
            announcements
              .sort((a, b) => b._createdAt.localeCompare(a._createdAt))
              .map((a) => (
                <AnnouncmentComponent
                  key={a._id}
                  username={user?.name || ''}
                  header={a.header}
                  text={a.text}
                  imageSRC={a.imageSRC}
                  createdAt={a._createdAt}
                />
              ))}
        </div>
      )}
    </div>
  )
}

export const listNotificationsQuery = graphql`
  query NotificationsListNotificationsQuery($filter: ListNotificationsFilter!, $page: Int!, $pageSize: Int) {
    listNotifications(filter: $filter, page: $page, pageSize: $pageSize) {
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        notificationId: id
        type
        readTime
        meta
        insertedAt
      }
    }
  }
`

import { graphql } from 'babel-plugin-relay/macro'
import './WalletComponent.scoped.scss'
import React, { useEffect, useState } from 'react'
import { fetchQuery, useQueryLoader } from 'react-relay'
import { UserProp } from '../../../reducers/authentication.reducer'
import { activeWalletUpdated, formatBalance, WalletConstant } from '../../../reducers/wallet.reducer'
import { store } from '../../../store'
import { isUserLoggedIn } from '../../../utils/functions'
import Env from '@src/RelayEnvironment'
import { WalletComponentAllWalletsQuery } from './__generated__/WalletComponentAllWalletsQuery.graphql'
import Modal from 'react-modal'
import { getLogoForCoin } from '../../coinsComponent/CoinsComponent'
import { WalletComponentlastDepositAddressQuery } from './__generated__/WalletComponentlastDepositAddressQuery.graphql'
import { Currency } from '../../../reducers/__generated__/walletWalletChangedSubscription.graphql'
import { customModalStyles } from '../../modalComponent/modalComponent'
import { tableErrorQueue } from '../../inHouseGames/InHouseGame'
import { interval } from 'rxjs'
import {
  WalletComponentFiatRatesQuery,
  WalletComponentFiatRatesQuery$data,
} from './__generated__/WalletComponentFiatRatesQuery.graphql'
import { WalletModal } from '../../wallet/WalletModal'

import classes from '@components/Modal/Modal.module.scss'
import ConfigurationService from '@src/service/ConfigurationService'
import WalletIcon from '@src/components/icons/wallet'
import useIsMobile from '@src/hooks/IsMobile'
import { useTranslation } from 'react-i18next'
import { Money } from '@src/money'

const currencies = Money.listAll()

export const useForceUpdate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setValue] = useState(0) // integer state
  return () => setValue((value) => value + 1) // update the state to force render
}

export const WalletComponent = () => {
  const [activeWallet, setActiveWallet] = useState(store.getState().wallet.activeWallet)
  const [modalVisible, setModalVisible] = useState(false)
  const [errors, setErrors] = useState<{ msg: string; created: number }[]>([])
  const [, setFiatRates] = useState<{ key: string; value: number }[]>([])
  const isMobile = useIsMobile('md')
  const { t } = useTranslation()

  useQueryLoader<WalletComponentlastDepositAddressQuery>(getDepositAddressQuery)

  useEffect(() => {
    const subscription = activeWalletUpdated.subscribe(() => {
      setActiveWallet(store.getState().wallet.activeWallet)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [activeWallet])

  useEffect(() => {
    const subscription = tableErrorQueue.subscribe((msg) => {
      const newErrors = [...errors.filter((x) => x.msg !== msg), { created: new Date().getTime(), msg }]
      setErrors(newErrors)
    })

    const intervalSubscription = interval(1230).subscribe(() => {
      const lastErrorTime = new Date().getTime() - 1000 * 5
      const filteredErrors = errors.filter((x) => x.created > lastErrorTime)
      if (errors.length !== filteredErrors.length) {
        setErrors(filteredErrors)
      }
    })

    const getFiatRates = () => {
      fetchQuery<WalletComponentFiatRatesQuery>(Env, getFiatRatesQuery, {}).subscribe({
        next: (data) => {
          parseFiatRates(data)
        },
      })
    }

    const fiatSubscription = interval(200 * 1000).subscribe(() => {
      getFiatRates()
    })
    getFiatRates()

    return () => {
      fiatSubscription.unsubscribe()
      subscription.unsubscribe()
      intervalSubscription.unsubscribe()
    }
  }, [errors])

  useEffect(() => {
    const walletState = store.getState().wallet
    const userState = store.getState().authentication

    const isloggedIn = isUserLoggedIn()

    if (!isloggedIn) {
      store.dispatch({
        type: WalletConstant.SET_WALLETS,
        wallets: [],
      })
      store.dispatch({
        type: WalletConstant.SET_ACTIVE_WALLET,
        wallet: {
          balance: 0,
          currency: 'BTC',
          virtual: false,
          virtualId: 0,
        },
      })

      return
    }

    fetchQuery<WalletComponentAllWalletsQuery>(Env, allWalletQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
      error: (error) => {
        store.dispatch({
          type: WalletConstant.SET_ACTIVE_WALLET,
          wallet: {
            balance: 0,
            currency: 'BTC',
            virtual: false,
            virtualId: 0,
          },
        })
      },
      next: (data) => {
        store.dispatch({
          type: WalletConstant.SET_WALLETS,
          wallets: data.allWallets,
        })

        if (!walletState.activeWallet) {
          let defaultWallet
          let activeWallet

          defaultWallet = data.allWallets?.find((x) => x?.virtualId === 0) ?? undefined

          const activeCoinProp = userState.user?.preferences.find((x) => x.key === UserProp.ACTIVE_COIN)

          if (activeCoinProp && activeCoinProp.value) {
            const value = activeCoinProp.value
            let currency
            if (value.startsWith('virtual_')) {
              // virtual_1018_BTC
              const parts = value.split('_')
              currency = parts[2]

              activeWallet = data.allWallets?.find((x) => x?.currency === currency && x?.virtualId === +parts[1])
              if (activeWallet) {
                store.dispatch({
                  type: WalletConstant.SET_ACTIVE_WALLET,
                  wallet: activeWallet,
                })
                return
              }
            } else {
              currency = value
            }
            activeWallet = data.allWallets?.find((x) => x?.currency === currency && x?.virtualId === 0)
          }

          if (!activeWallet && defaultWallet) {
            activeWallet = defaultWallet
          }
          if (activeWallet) {
            store.dispatch({
              type: WalletConstant.SET_ACTIVE_WALLET,
              wallet: activeWallet,
            })
          }

          if (!activeWallet && !defaultWallet) {
            const gamblerWallet = data.allWallets?.find((w) => !!(w?.campaign?.meta as Record<string, any>)?.playable)
            if (gamblerWallet) {
              store.dispatch({
                type: WalletConstant.SET_ACTIVE_WALLET,
                wallet: gamblerWallet,
              })
              return
            }
          }
        }

        if (walletState.activeWallet) {
          const activeWallet = data.allWallets?.find(
            (x) =>
              x?.currency === walletState.activeWallet?.currency && x?.virtualId === walletState.activeWallet?.virtualId
          )
          if (activeWallet) {
            store.dispatch({
              type: WalletConstant.SET_ACTIVE_WALLET,
              wallet: activeWallet,
            })
          }
          return
        }

        store.dispatch({
          type: WalletConstant.SET_ACTIVE_WALLET,
          wallet: {
            balance: 0,
            currency: 'BTC',
            virtual: false,
            virtualId: 0,
          },
        })
      },
    })
  }, [modalVisible])

  const parseFiatRates = (data: WalletComponentFiatRatesQuery$data) => {
    const rates = data.fiatRates.map((x) => {
      return { key: x.key as string, value: +(x.value as string) }
    })
    setFiatRates(rates)
    store.dispatch({ type: WalletConstant.SET_FIAT_RATES, rates })
  }

  if (undefined === activeWallet) {
    return null
  }

  return (
    <div className="wallet-component gap-x-4">
      {errors.length > 0 ? (
        <div className="error-container">
          {errors.map((x) => (
            <span key={x.msg + '_' + x.created}>{x.msg}</span>
          ))}
        </div>
      ) : null}
      {
        <React.Fragment>
          <div className="coin-row pl-18">
            <div className="wallet-logo">
              <img alt={activeWallet.currency} src={getLogoForCoin(activeWallet.currency)} />
            </div>
            <div className="wallet-balance">
              <span>{formatBalance(activeWallet, true, false)}</span>
            </div>
          </div>
          <div onClick={() => setModalVisible(true)} className="wallet-open hover:bg-eucalyptusHover">
            {isMobile ? <WalletIcon /> : <span>{t('wallet.wallet', 'Wallet')}</span>}
          </div>
        </React.Fragment>
      }
      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        ariaHideApp={false}
        portalClassName={`${classes.root} ReactModalPortal wallet-overlay-portal`}
        id="wallet-modal-overlay"
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <WalletModal closeCallback={() => setModalVisible(false)} />
      </Modal>
    </div>
  )
}

export const getExplorerLinkForTxid = (currency: string, txid: string) => {
  // tslint:disable-next-line:switch-default
  switch (currency.toUpperCase()) {
    case 'BTC':
      return 'https://blockchair.com/bitcoin/transaction/' + txid
    case 'DASH':
      return 'https://blockchair.com/dash/transaction/' + txid
    case 'BCH':
      return 'https://blockchair.com/bitcoin-cash/transaction/' + txid
    case 'LTC':
      return 'https://blockchair.com/litecoin/transaction/' + txid
    case 'ETH':
      return 'https://blockchair.com/ethereum/transaction/' + txid
    case 'USDT':
      return 'https://blockchair.com/ethereum/transaction/' + txid
    case 'ADA':
      return 'https://blockchair.com/cardano/transaction/' + txid
    case 'TRX':
      return 'https://tronscan.org/#/transaction/' + txid
    case 'BNB':
      return 'https://www.bscscan.com/tx/' + txid
    case 'XMR':
      return 'https://localmonero.co/blocks/tx/' + txid
  }
}

export const formatFiat = (
  fiatRates: { key: string; value: number }[],
  wallet: { balance: number; currency: string }
) => {
  return <span className="fiat">${formatFiatRaw(fiatRates, wallet)}</span>
}

export const formatFiatRaw = (
  fiatRates: { key: string; value: number }[],
  wallet: { balance: number; currency: string }
) => {
  if (fiatRates.length === 0) {
    return null
  }

  const rate = fiatRates.find((x) => x.key === wallet.currency)
  if (undefined === rate) {
    return null
  }

  const c = ConfigurationService.instance.getCurrency(wallet.currency)
  const precision = (currencies as any)[c.m_unit].precision
  return Math.round(((wallet.balance * rate.value) / Math.pow(10, precision)) * 100) / 100
}

export const fiatToCurrency = (
  fiatRates: { key: string; value: number }[],
  targetCurrency: Currency,
  amount: number
) => {
  if (fiatRates.length === 0) {
    return null
  }

  const rate = fiatRates.find((x) => x.key === targetCurrency)
  if (undefined === rate) {
    return null
  }

  const c = ConfigurationService.instance.getCurrency(targetCurrency)
  const precision = (currencies as any)[c.m_unit].precision
  return Math.round((amount / rate.value) * Math.pow(10, precision))
}

export const allWalletQuery = graphql`
  query WalletComponentAllWalletsQuery {
    allWallets {
      balance
      campaign {
        campaignId: id
        type
        payoutMultiplyer
        meta
        userInCampaign {
          amount
          betSum
        }
      }
      currency
      virtual
      virtualId
      insertedAt
    }
  }
`

const getDepositAddressQuery = graphql`
  query WalletComponentlastDepositAddressQuery($currency: Currency!) {
    lastDepositAddress(currency: $currency) {
      currency
      address
      isShared
      expireTime
    }
  }
`

export const getFiatRatesQuery = graphql`
  query WalletComponentFiatRatesQuery {
    fiatRates {
      key
      value
    }
  }
`

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { chatUserVipUpdated, showVipBar } from '../../App'
import {
  applyUpdatedUserPreference,
  applyUserStat,
  User,
  UserConstant,
  UserStat,
  userUpdated,
} from '../../reducers/authentication.reducer'
import { store } from '../../store'
import { updateUserPreference } from '../gameListComponent/GameListComponent'
import { graphql } from 'babel-plugin-relay/macro'

import './vipProfileComponent.scss'
import { commitMutation, fetchQuery, useMutation } from 'react-relay'
import { vipProfileComponentUpdateUserStatMutation } from './__generated__/vipProfileComponentUpdateUserStatMutation.graphql'
import { GameListComponentstoreUserPreferenceMutation } from '../gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import { vipProfileComponentClaimGiftMutation } from './__generated__/vipProfileComponentClaimGiftMutation.graphql'
import Env from '../../RelayEnvironment'
import {
  vipProfileComponentProfileQuery,
  vipProfileComponentProfileQuery$data,
} from './__generated__/vipProfileComponentProfileQuery.graphql'
import { useParams } from 'react-router-dom'
import { Money } from '@src/money'

enum View {
  closed,
  opened,
}

interface Badge {
  label: string
  subLabel: string
  treshold: number
  icon: string
}

interface BadgeGroup {
  label: string
  progress: number
  id: string
  badges: Badge[]
}

interface FeaturedBadge extends Badge {
  group: BadgeGroup
}

export interface ProfileUser {
  uuid: string
  userStats: readonly UserStat[]
  profileBadge?: string
  avatar?: string
  name: string
  insertedAt: string
  tournamentWon: number | null
  higestWin: number | null
  higestBet: number | null
}

const defaultBadgeGroups: BadgeGroup[] = [
  {
    label: 'HOURLY BIGGEST WIN COMPETITION',
    progress: 1000,
    id: 'comp_biggest_win_hourly',
    badges: [
      {
        label: 'HERE I GO!',
        subLabel: 'FIRST HOURLY BIGGEST WIN COMPETITION WIN',
        treshold: 1,
        icon: '/assets/profile/tropy_icon_1',
      },
      {
        label: '5 ALREADY?',
        subLabel: '5 HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 5,
        icon: '/assets/profile/tropy_icon_2',
      },
      {
        label: "I'M A NATURAL",
        subLabel: '10 HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 10,
        icon: '/assets/profile/tropy_icon_3',
      },
      {
        label: "I'M A NINJA AND YOU KNOW IT",
        subLabel: '50 HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50,
        icon: '/assets/profile/tropy_icon_4',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50,
        icon: '/assets/profile/badge_4_2',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50000001,
        icon: '/assets/profile/badge_6_vip',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50000001,
        icon: '/assets/profile/badge_7_vip',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50000001,
        icon: '/assets/profile/badge_8_vip',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50000001,
        icon: '/assets/profile/badge_9_vip',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50000001,
        icon: '/assets/profile/badge_10_vip',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50000001,
        icon: '/assets/profile/badge_11_vip',
      },
      {
        label: 'ARE YOU IMPRESSED YET?',
        subLabel: '50+ HOURLY BIGGEST WIN COMPETITION WINS',
        treshold: 50000001,
        icon: '/assets/profile/badge_12_vip',
      },
    ],
  },
  {
    label: 'DAILY WAGER COMPETITION',
    progress: 250,
    id: 'wager_daily',
    badges: [
      {
        label: 'HERE I GO!',
        subLabel: 'I CAME HERE LIKE A WREKING BALL',
        treshold: 1,
        icon: '/assets/profile/badge_3_1',
      },
      {
        label: 'I ATTRACT WINS',
        subLabel: '100 DAILY WAGER WINS',
        treshold: 100,
        icon: '/assets/profile/badge_4_1',
      },
      {
        label: '1000+ DAILY WAGER WINS',
        subLabel: 'VIP BADGE ONLY',
        treshold: 1000,
        icon: '/assets/profile/badge_2_2',
      },
    ],
  },
  {
    label: 'WEEKLY WAGER COMPETITION',
    progress: 250,
    id: 'wager_weekly',
    badges: [
      {
        label: 'HERE I GO',
        subLabel: 'I CAME HERE LIKE A WREKING BALL',
        treshold: 1,
        icon: '/assets/profile/badge_3_1',
      },
      {
        label: 'I ATTRACT WINS',
        subLabel: '100 WEEKLY WAGER WINS',
        treshold: 100,
        icon: '/assets/profile/badge_4_1',
      },
      {
        label: '1000+ WEEKLY WAGER WINS',
        subLabel: 'VIP BADGE ONLY',
        treshold: 1000,
        icon: '/assets/profile/badge_2_2',
      },
    ],
  },
  {
    label: 'TOURNAMENT BADGES',
    id: 'tournament',
    progress: 1000,
    badges: [
      {
        label: 'HERE I GO!',
        subLabel: 'FIRST TOURNAMENT WIN',
        treshold: 1,
        icon: '/assets/profile/badge_1_1',
      },
      {
        label: "I'M A NATURAL",
        subLabel: '50 TOURNAMENT WINS',
        treshold: 50,
        icon: '/assets/profile/badge_2_1',
      },
      {
        label: 'I AM A KING, BOW TO ME!',
        subLabel: '100+ TOURNAMENT WINS',
        treshold: 100,
        icon: '/assets/profile/badge_1_2',
      },
    ],
  },
]

const communityBadges = [
  { label: 'COMMUNITY PILLAR', value: 0x01, id: 'community_pillar' },
  { label: 'PLATFORM CONTRIBUTOR', value: 0x02, id: 'platform_contributor' },
  { label: 'VALUED ADVOCATE', value: 0x03, id: 'valued_advocate' },
  { label: 'REWARDED', value: 0x04, id: 'rewarded' },
  { label: 'COMMUNITY FAVOURITE', value: 0x05, id: 'community_favourite' },
  { label: 'COMMUNITY LEADER', value: 0x06, id: 'community_leader' },
]

export const getProfileIcon = (badgeGroupId: string, idx: number, size: string = '30px') => {
  // Community badge
  const cidx = communityBadges.map((x) => x.id).indexOf(badgeGroupId)
  if (cidx >= 0) {
    const src = '/assets/profile/Tex_badge_0' + communityBadges[cidx].value + '.png'
    return (
      <div className="">
        <img src={src} alt="" className="aspect-square object-contain object-center" style={{ width: size }} />
      </div>
    )
  }

  // Regular badge
  else {
    const value = defaultBadgeGroups.find((x) => x.id === badgeGroupId)?.badges[+idx]
    if (undefined === value) {
      return value
    }
    return (
      <div className="">
        <img
          src={value.icon + '.png'}
          alt=""
          className="aspect-square object-contain object-center"
          style={{ width: size }}
        />
      </div>
    )
  }
}

export const claimGift = () => {
  commitMutation<vipProfileComponentClaimGiftMutation>(Env, {
    mutation: claimGiftMutation,
    variables: {
      input: {},
    },
    onCompleted: (resp) => {
      if (resp.claimGift?.user) {
        store.dispatch({
          type: UserConstant.USER_UPDATED,
          user: resp.claimGift?.user,
        })
        setTimeout(() => {
          userUpdated.next()
        })
      }
    },
    onError: (err: any) => {
      console.error(err)
    },
  })
}

export const giftEnabled = () => {
  const me = store.getState().authentication.user
  if (!me) {
    return false
  }
  const stat = me.userStats.find((x) => x.name === 'wager')
  if (!stat) {
    return false
  }

  return stat.value > 100000000
}

const getStartFromStats = (stats: readonly UserStat[], key: string): number => {
  return stats.find((x) => x.name === key)?.value || 0
}

export const getGeneralStats = (user: ProfileUser) => {
  const wagered = getStartFromStats(user.userStats, 'wager')
  const wagerRank = user.userStats.find((x) => x.name === 'wager')?.meta?.rank || -1

  // const gamesPlayed = getStartFromStats(user.userStats, 'games_played');
  const gamesWon = getStartFromStats(user.userStats, 'games_won')
  const hourlyWins = getStartFromStats(user.userStats, 'comp_biggest_win_hourly')

  return (
    <div className="vip-general-stats">
      {wagerRank > 0 && false ? (
        <div className="wager-rank">
          <img src="/assets/TrophyIcons_72_t.png" alt="" />
          <span className="rank-number">#{wagerRank}</span>
          <span className="label">
            GLOBAL
            <br />
            RANKING
          </span>
        </div>
      ) : null}
      <div className="number-rank">
        <span className="amount">{Money.fromInteger(wagered, 'BTC', 'BTC').toFixed(2)} BTC</span>
        <span className="label">
          WAGERED <br />
          AMOUNT
        </span>
      </div>
      <div className="number-rank">
        <span className="amount">{hourlyWins}</span>
        <span className="label">
          HOURLY <br />
          WINS
        </span>
      </div>
      {/*<div className='number-rank'>
      <span className='amount'>{gamesPlayed}</span>
      <span className='label'>GAMES <br/>PLAYED</span>
    </div>
    */}
      <div className="number-rank">
        <span className="amount">{gamesWon}</span>
        <span className="label">
          GAMES <br />
          WON
        </span>
      </div>
    </div>
  )
}

const getActiveCommunityBadged = (user: ProfileUser) => {
  const stats = user.userStats.find((x) => x.name === 'community')
  return [1, 2, 3, 4, 5, 6]
    .map((x) => {
      const key = 'b_' + x
      const active = stats?.meta[key] === true
      if (!active) {
        return undefined
      }
      return (
        <div key={x} className="c-badge-img">
          <img src={'/assets/profile/Tex_badge_0' + x + '.png'} key={key} alt="" />
        </div>
      )
    })
    .filter((x) => undefined !== x)
}

const getGiftProgress = (stars: number) => {
  return Array.from({ length: 7 }, (x, i) => i).map((idx) => {
    if (idx < stars) {
      return <img key={'star-' + idx} src="/assets/profile/tropy_active.png" alt="" />
    }
    return <img key={'star-' + idx} src="/assets/profile/tropy_inactive.png" alt="" />
  })
}

const getGiftContainer = (giftProgress: number) => {
  if (true) {
    return null
  }
  if (!giftEnabled()) {
    return null
  }
  return (
    <div className="vip-gift">
      <div className="vip-days-to-go">
        <span>BET {7 - giftProgress} MORE DAYS</span>
        <span>TO CLAIM YOUR GIFT</span>
      </div>
      <div className="vip-gift-progress">{getGiftProgress(giftProgress)}</div>
      <div className="vip-gift-icon">
        <img src="/assets/profile/giftbox.png" alt="" />
      </div>
    </div>
  )
}

export const getGiftTopRow = (me: ProfileUser, withGifts: boolean) => {
  const giftProgress = me.userStats.find((x) => x.name === 'gift')?.value || 0
  const [badgeGroupId, idx] = (me.profileBadge || '').split('/')
  let value
  if (me.avatar) {
    if ((me?.avatar?.length || 0) > 0) {
      value = <img src={'/api/avatar/' + me?.avatar} alt="" />
    }
  } else {
    value = getProfileIcon(badgeGroupId, +idx)
  }
  return (
    <div className="vip-top-row">
      <div className="vip-info">
        {value}
        <div className="vip-me-info">
          <span className="vip-joined">Joined {me.insertedAt}</span>
          <span className="vip-welcome">WELCOME BACK</span>
          <span className="vip-username">{me.name}</span>
        </div>
      </div>
      {withGifts ? getGiftContainer(giftProgress) : null}
    </div>
  )
}

export const getProfileFromUser = (user: User) => {
  const profile: ProfileUser = {
    insertedAt: user.insertedAt,
    name: user.name,
    profileBadge: user.preferences.find((x) => x.key === 'profile_badge')?.value,
    avatar: user.preferences.find((x) => x.key === 'avatar' && (x.value?.length || 0) > 0)?.value,
    userStats: user.userStats,
    uuid: user.uuid,
    higestWin: 0,
    tournamentWon: 0,
    higestBet: 0,
  }
  return profile
}

export const ProfileBadge = ({
  uuid,
  onLoadFn,
  className,
}: {
  uuid: string
  onLoadFn?: () => void
  className?: string
}): JSX.Element | null => {
  const [profileData, setProfile] = useState<vipProfileComponentProfileQuery$data>()
  useEffect(() => {
    if (undefined !== profileData) {
      return
    }
    fetchQuery<vipProfileComponentProfileQuery>(
      Env,
      profileQuery,
      { uuid },
      { fetchPolicy: 'store-or-network' }
    ).subscribe({
      next: (data) => {
        // console.log('profileQuery', data)
        if (data.profile) {
          setProfile(data)
        }
      },
      error: (err: any) => {
        // console.log(err);
      },
    })
  }, [uuid, profileData])

  if (!profileData?.profile || !profileData.profile.vip) {
    return null
  }

  return ProfileBadgeFromVip({
    vip: profileData.profile.vip,
    onLoadFn: onLoadFn,
    className: className,
  })
}

export const ProfileBadgeFromVip = ({
  vip,
  onLoadFn,
  className,
}: {
  vip: {
    readonly badgeId: string | null
    readonly offset: number | null
    readonly avatar: string | null
  }
  onLoadFn
  className?: string
}) => {
  let src
  if ((vip?.avatar?.length || 0) > 0) {
    src = '/api/avatar/' + vip!.avatar
  } else {
    const value = defaultBadgeGroups.find((x) => x.id === vip?.badgeId)?.badges[+(vip?.offset || 0)]
    if (undefined !== value) {
      src = value.icon + '.png'
    }
  }
  if (undefined === src) {
    return null
  }
  const activeVipBadge = <img onLoad={onLoadFn} key={'badge-vip-idx' + src} className={className} src={src} alt="" />
  return activeVipBadge
}

export const VIPProfileComponent = ({ uuidR, closed }: { uuidR?: string; closed?: boolean }) => {
  const [view] = useState(closed === true ? View.closed : View.opened)
  const [me, setMe] = useState<ProfileUser>()
  const [activeVIPIdx, setActiveVIPIdx] = useState(-1)
  const [commitUserPreference] = useMutation<GameListComponentstoreUserPreferenceMutation>(updateUserPreference)
  const [commit] = useMutation<vipProfileComponentUpdateUserStatMutation>(updateUserStateMutation)

  let uuidParam: string | undefined = useParams<{ uuid?: string }>().uuid

  if (undefined !== uuidR) {
    uuidParam = uuidR
  }

  const updateMe = (user: ProfileUser | undefined) => {
    if (!user) {
      return
    }
    setMe(user)
    const entry = user.userStats.find((x) => x.name === 'wager')
    let idx = entry?.meta.selectedIdx
    if (undefined === idx) {
      idx = -1
    }
    setActiveVIPIdx(idx)
  }

  useEffect(() => {
    const myUser = store.getState().authentication.user
    const refetch = () => {
      fetchQuery<vipProfileComponentProfileQuery>(
        Env,
        profileQuery,
        { uuid: uuidParam || (myUser?.uuid as string) },
        { fetchPolicy: 'network-only' }
      ).subscribe({
        next: (data) => {
          if (data.profile) {
            updateMe({
              uuid: uuidParam as string,
              name: data.profile.name,
              insertedAt: data.profile.insertedAt,
              profileBadge: data.profile.profileBadge || undefined,
              userStats: data.profile.userStats,
              higestWin: data.profile.higestWin,
              tournamentWon: data.profile.tournamentWon,
              higestBet: data.profile.higestBet,
              avatar: data.profile.vip?.avatar || undefined,
            })
          }
        },
      })
    }
    if (undefined === me || uuidParam !== me.uuid) {
      refetch()
    }
    const subs = chatUserVipUpdated.subscribe((vip) => {
      if (vip.uuid !== uuidParam || me === undefined) {
        return
      }
      refetch()
    })

    const int = setInterval(() => {
      refetch()
    }, 1000 * 30)

    const userUpdatedSubs = userUpdated.subscribe(() => {
      const authUser = store.getState().authentication.user
      if (undefined !== authUser) {
        refetch()
      }
    })

    return () => {
      clearInterval(int)
      subs.unsubscribe()
      userUpdatedSubs.unsubscribe()
    }
  }, [uuidParam, me])

  if (!me) {
    return null
  }

  const selectBadge = (idx: number) => {
    if (undefined !== uuidParam) {
      return
    }
    let meta = Object.assign({}, me.userStats.find((x) => x.name === 'wager')?.meta || {})
    meta.selectedIdx = idx
    commit({
      variables: { input: { name: 'WAGER', meta: JSON.stringify(meta) } },
      onCompleted: ({ updateUserStat }) => {
        if (updateUserStat?.stat) {
          applyUserStat(updateUserStat?.stat)
        }
      },
    })
  }

  const getWagerProgress = (wagerStat?: UserStat) => {
    const wagerMap = [1, 3, 5, 25, 50]
    let btcWagered = 0
    if (undefined !== wagerStat) {
      btcWagered = wagerStat.value / 100000000
    }
    return Array.from({ length: 5 }, (x, i) => i).map((idx) => {
      const i = idx + 1
      const active = wagerMap[idx] <= btcWagered
      const modifier = active ? (activeVIPIdx === idx ? 'selected' : 'active') : 'inactive'
      const src = '/assets/profile/badge_' + i + '_' + modifier + '.png'
      return (
        <img
          className={active ? 'active' : ''}
          onClick={() => active && selectBadge(idx)}
          key={'wager-idx' + i}
          src={src}
          alt=""
        />
      )
    })
  }

  const badgeClick = (group: BadgeGroup, badge: Badge, vidx?: number) => {
    if (undefined !== uuidParam) {
      return
    }
    const value =
      group.id +
      '/' +
      group.badges.findIndex((x) => x.icon === badge.icon) +
      '/' +
      (undefined !== vidx ? vidx : activeVIPIdx)
    commitUserPreference({
      variables: { input: { key: 'profile_badge', value } },
      onCompleted: (resp) => {
        applyUpdatedUserPreference(resp)
      },
    })
  }

  const getBadge = (group: BadgeGroup, badge: Badge) => {
    const i = group.badges.indexOf(badge)
    const active = group.progress >= badge.treshold
    const last = active && (undefined === group.badges[i + 1] || group.progress < group.badges[i + 1].treshold)
    const iconSuffix = active ? '.png' : '_inactive.png'
    return (
      <div
        onClick={() => active && badgeClick(group, badge)}
        key={'badge_' + group.label + '_' + badge.label + '_' + badge.treshold}
        className={'vip-badge' + (active ? (last ? ' last active' : ' active') : ' inactive')}
      >
        <img src={badge.icon + iconSuffix} alt="" />
        <div className="vip-badge-desc">
          <span className="vip-badge-label">{badge.label}</span>
          <span className="vip-badge-sub-label">{badge.subLabel}</span>
        </div>
      </div>
    )
  }

  const getBadgeIcon = (badge: Badge) => {
    return <img key={badge.icon + badge.label} className="vip-badge-icon" src={badge.icon + '.png'} alt="" />
  }

  const badgeGroups = (JSON.parse(JSON.stringify(defaultBadgeGroups)) as BadgeGroup[]).map((group) => {
    const progress = me.userStats.find((x) => x.name === group.id)
    if (undefined === progress) {
      group.progress = 0
    } else {
      group.progress = progress.value
    }
    return group
  })

  const setCommunityIconAndBadge = (idx: number) => {
    const badge = communityBadges.find((x) => x.value === idx)
    if (!badge) {
      return
    }

    const value = badge.id + '/0/0'
    commitUserPreference({
      variables: { input: { key: 'profile_badge', value } },
      onCompleted: (resp) => {
        applyUpdatedUserPreference(resp)
      },
    })
  }

  const getCommunityBadges = (user: ProfileUser) => {
    const stats = user.userStats.find((x) => x.name === 'community')
    return [1, 2, 3, 4, 5, 6].map((x) => {
      const key = 'b_' + x
      const active = stats?.meta[key] === true
      return (
        <div onClick={() => active && setCommunityIconAndBadge(x)} className={'c-badge' + (active ? ' active' : '')}>
          <img src={'/assets/profile/Tex_badge_0' + x + (active ? '' : '_inactive') + '.png'} key={key} alt="" />
          <span>{communityBadges.find((b) => b.value === x)?.label}</span>
        </div>
      )
    })
  }

  const getGroupAppender = (group: { id: string }): JSX.Element | null => {
    if (group.id === 'comp_biggest_win_hourly') {
      return (
        <div className="vip-badge-appender">
          <img alt="" src="/assets/TrophyIcons_44_t.png" />
          <div className="label-container">
            <span>{Money.convertUnit(me.higestBet || 0, 'BTC', 'sat', 'BTC')} BTC</span>
            <span className="label">HIGHEST PERSONAL WIN</span>
          </div>
        </div>
      )
    } else if (group.id === 'tournament') {
      return (
        <div className="vip-badge-appender">
          <img alt="" src="/assets/TrophyIcons_14_t.png" />
          <div className="label-container">
            <span>{Money.convertUnit(me.tournamentWon || 0, 'BTC', 'sat', 'BTC')} BTC</span>
            <span className="label">WON FROM TOURNAMENTS</span>
          </div>
        </div>
      )
    } else if (group.id === 'wager_daily' || group.id === 'wager_weekly') {
      return (
        <div className="vip-badge-appender">
          <img alt="" src="/assets/TrophyIcons_24_t.png" />
          <div className="label-container">
            <span>{Money.fromInteger(getStartFromStats(me.userStats, 'wager'), 'BTC', 'BTC').toFixed(2)} BTC</span>
            <span className="label">WON FROM TOURNAMENTS</span>
          </div>
        </div>
      )
    }

    return null
  }

  const getSubPage = () => {
    return (
      <div className="vip-body">
        {undefined === uuidParam ? getGiftTopRow(me, true) : <p> </p>}
        <div className="vip-sub-header">YOUR GENERAL STATS</div>
        <div className="vip-general-stats-container">{getGeneralStats(me)}</div>

        <div className="vip-sub-header">COMMUNITY</div>
        <div className="vip-general-community-container">{getCommunityBadges(me)}</div>

        <div className="vip-sub-header">YOUR PERKS, BONUSES & BENEFITS</div>
        <div className="vip-list">
          {getWagerProgress(me.userStats.find((x) => x.name === 'wager'))}
          <div className="vip-full-list hidden">
            <span>CHECK OUT</span>
            <span>YOUR FULL</span>
            <span>BENEFITS LIST</span>
          </div>
        </div>
        {badgeGroups.map((group) => {
          return (
            <div className="vip-badge-group" key={'gr_' + group.label}>
              <div className="vip-sub-header">{group.label}</div>
              <div className="vip-badge-row">
                {getGroupAppender(group)}
                <div className="vip-badge-list">
                  {group.badges
                    .filter((x) => x.treshold !== 50000001)
                    .map((badge) => (
                      <div>{getBadge(group, badge)}</div>
                    ))}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const featuredBadges: FeaturedBadge[] = []
  badgeGroups.forEach((group) => {
    let latestActiveBadge: FeaturedBadge | undefined = undefined
    group.badges.forEach((badge) => {
      const active = group.progress >= badge.treshold
      if (active) {
        latestActiveBadge = Object.assign({ group }, badge)
      }
    })
    if (undefined !== latestActiveBadge) {
      featuredBadges.push(latestActiveBadge)
    }
  })
  featuredBadges.sort((a, b) => b.group.progress - a.group.progress)

  let activeVipBadge: JSX.Element | null = null
  let activeVipBadgeLabel = ''

  if (me.avatar) {
    activeVipBadge = (
      <img className="badge-avatar" key={'badge-vip-idx' + activeVIPIdx} src={'/api/avatar/' + me.avatar} alt="" />
    )
  } else {
    if (activeVIPIdx >= 0) {
      const src = '/assets/profile/badge_' + (activeVIPIdx + 1) + '_vip.png'
      activeVipBadge = <img key={'badge-vip-idx' + activeVIPIdx} src={src} alt="" />
      switch (activeVIPIdx) {
        case 0:
          activeVipBadgeLabel = '1 BTC WAGERED'
          break
        case 1:
          activeVipBadgeLabel = 'ONE OF OURS'
          break
        case 2:
          activeVipBadgeLabel = '5 BTC WAGERED'
          break
        case 3:
          activeVipBadgeLabel = 'PRO PLAYER'
          break
        case 4:
          activeVipBadgeLabel = '50 BTC WAGERED'
          break
      }
    }
  }

  const myCommunityBadges = getActiveCommunityBadged(me)

  return (
    <div className={'vip games' + (undefined === uuidParam ? '' : ' read-only')}>
      <div className="app-left-menu">
        <div className="vip-edit-row">
          {view === View.closed ? (
            <div className="vip-close" onClick={() => showVipBar.next(undefined)}>
              <FontAwesomeIcon size="2x" icon={faTimes} />
            </div>
          ) : null}
        </div>

        <div className="vip-badge-container">
          {activeVipBadge}
          <span className="vip-badge-label">{activeVipBadgeLabel}</span>
        </div>

        {myCommunityBadges.length > 0 ? (
          <div className="vip-feature-list">
            <hr />
            <h4>Community Badges</h4>
            <div>{myCommunityBadges}</div>
          </div>
        ) : null}

        {featuredBadges.length > 0 ? (
          <div className="vip-feature-list">
            <hr />
            <h4>Career Badges</h4>
            <div>{featuredBadges.map((x) => getBadgeIcon(x))}</div>
          </div>
        ) : null}
        <div className="vip-feature-list">
          <hr />
          <h4>Profile stats</h4>
          <div className="stats">
            <div className="vip-profile-stat">
              <div>
                <img src="/assets/TrophyIcons_14_t.png" alt="" />
              </div>
              <div className="stat-column">
                <span>{Money.convertUnit(me.tournamentWon || 0, 'BTC', 'sat', 'BTC')} BTC</span>
                <span>WON FROM TOURNAMENTS</span>
              </div>
            </div>
            <hr />
            <div className="vip-profile-stat">
              <div>
                <img src="/assets/TrophyIcons_24_t.png" alt="" />
              </div>
              <div className="stat-column">
                <span>{Money.fromInteger(getStartFromStats(me.userStats, 'wager'), 'BTC', 'BTC').toFixed(2)} BTC</span>
                <span>WAGERED</span>
              </div>
            </div>
            <hr />
            <div className="vip-profile-stat">
              <div>
                <img src="/assets/TrophyIcons_44_t.png" alt="" />
              </div>
              <div className="stat-column">
                <span>{Money.convertUnit(me.higestWin || 0, 'BTC', 'sat', 'BTC')} BTC</span>
                <span>HIGHEST RERSONAL WIN</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {view === View.closed ? null : (
        <div className="app-content-inner games-content">
          <div className="styled-scroll">{getSubPage()}</div>
        </div>
      )}
    </div>
  )
}

const updateUserStateMutation = graphql`
  mutation vipProfileComponentUpdateUserStatMutation($input: UpdateUserStatInput!) {
    updateUserStat(input: $input) {
      stat {
        name
        value
        meta
      }
    }
  }
`

const claimGiftMutation = graphql`
  mutation vipProfileComponentClaimGiftMutation($input: ClaimGiftInput!) {
    claimGift(input: $input) {
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        chatApproval
        insertedAt
        kycState
        refProps
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

export const profileQuery = graphql`
  query vipProfileComponentProfileQuery($uuid: String!) {
    profile(uuid: $uuid) {
      userStats {
        name
        value
        meta
      }
      profileBadge
      name
      insertedAt
      tournamentWon
      higestWin
      higestBet
      vip {
        badgeId
        offset
        avatar
      }
      hasProfileImage
    }
  }
`

export const userStatUpdatedSubscription = graphql`
  subscription vipProfileComponentUserStatUpdatedSubscription {
    userStatUpdated {
      uuid
      name
      value
      meta
    }
  }
`

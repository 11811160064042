import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { Store } from '@reduxjs/toolkit'
import { rootReducer } from './reducers'
import { State as AppState, AppTypes } from './reducers/app.reducer'
import { State as AState } from './reducers/authentication.reducer'
import { State as WState } from './reducers/wallet.reducer'
import { State as TState } from './reducers/multiplayer.reducer'
import { isMobile } from './hooks/IsMobile'
import { ChatState } from './reducers/chat.reducer'
import { ProfileState } from './reducers/profile.reducer'
import { AuthModalState } from './reducers/authModal.reducer'
// import { createLogger } from 'redux-logger'

// const loggerMiddleware = createLogger()

export interface IStore {
  app: AppState
  chat: ChatState
  authentication: AState
  authModal: AuthModalState
  wallet: WState
  multiplayer: TState
  profile: ProfileState
}

export const store: Store<IStore> = createStore(rootReducer, applyMiddleware(thunkMiddleware))
// export const store: Store<IStore> = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware))

window.addEventListener('resize', () => {
  if (isMobile()) {
    store.dispatch({ type: AppTypes.EXPAND_MENU })
  }
})

if (store.getState().app.chatOpened === null) {
  if (isMobile('xl', true, 1364)) {
    store.dispatch({ type: AppTypes.CLOSE_CHAT })
  } else {
    store.dispatch({ type: AppTypes.OPEN_CHAT })
  }
}

import { useEffect, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { Mutable } from '@src/types/mutable.type'

import { myBetsQuery } from './gql/myBetsQuery.gql'
import type {
  myBetsQuery as MyBetsQuery,
  myBetsQuery$data as MyBetsQueryResponse,
} from './gql/__generated__/myBetsQuery.graphql'
import {
  sportsBetsQuery as SportsBetsQuery,
  sportsBetsQuery$data,
} from './gql/__generated__/sportsBetsQuery.graphql'
import { sportsBetsQuery } from './gql/sportsBetsQuery.gql'

import { Tabs } from './LatestBets'

export const useLatestBets = ({ tab }: { tab: typeof Tabs[number] }) => {
  const [myBetsFetchKey, setMyBetsFetchKey] = useState(0)
  const [sportsBetsFetchKey, setSportsBetsFetchKey] = useState(0)

  const { myBets } = useLazyLoadQuery<MyBetsQuery>(
    myBetsQuery,
    {},
    {
      fetchKey: myBetsFetchKey,
      fetchPolicy: 'store-and-network',
    }
  )
  const { bets } = useLazyLoadQuery<SportsBetsQuery>(
    sportsBetsQuery,
    {},
    {
      fetchKey: sportsBetsFetchKey,
      fetchPolicy: 'store-and-network',
    }
  )

  useEffect(() => {
    if (tab === 'My') {
      setMyBetsFetchKey((prev) => prev + 1)
      return
    }

    if (tab === 'Sports') {
      setSportsBetsFetchKey((prev) => prev + 1)
      return
    }
  }, [tab])

  return {
    myBets: JSON.parse(JSON.stringify(myBets)) as Mutable<MyBetsQueryResponse>['myBets'],
    sportsBets: JSON.parse(JSON.stringify(bets?.sportBets ?? [])) as NonNullable<
      Mutable<sportsBetsQuery$data>['bets']
    >['sportBets'],
  }
}

import Konva from 'konva'

const canvas = document.createElement('canvas')
canvas.width = 1224
canvas.height = 768
canvas.style.display = 'none'
canvas.style.position = 'absolute'
document.body.appendChild(canvas)
const ctx = canvas.getContext('2d')

const measureTextWidth = (text: string, font?: string): number => {
  if (!ctx) {
    return 0
  }
  if (undefined === font) {
    ctx.font = 'bold 17px Rubik'
  } else {
    ctx.font = font
  }

  return ctx.measureText(text).width
}

const getElementBounds = (
  group: Konva.Group | Konva.Layer
): { width: number; height: number; x: number; y: number } => {
  if (!group.children || group.children.length === 0) {
    return { width: 0, height: 0, x: 0, y: 0 }
  }
  const firstElement = group.children[0]
  const lastElement = group.children[group.children.length - 1]
  let maxHeight = 0
  group.children.forEach((x) => (maxHeight = Math.max(maxHeight, x.height())))
  const startX = firstElement.x()
  const endX = lastElement.x() + lastElement.width()
  const startY = firstElement.y()
  return {
    width: Math.abs(startX - endX),
    height: maxHeight,
    x: startX,
    y: startY,
  }
}

const isSmallScreen = (layer: Konva.Layer): boolean => {
  if (!layer) {
    return false
  }
  const stageWidth = Math.min(layer.width(), layer.height())
  return stageWidth <= 700
}

export { measureTextWidth, getElementBounds, isSmallScreen }

import { useEffect, useState } from 'react'
import Emoji from 'react-emoji-render'
import { ReactGiphySearchBox } from 'react-giphy-searchbox'

import classes from './Emojis.module.scss'
import classNames from 'classnames'
import { sendMessage } from '../chatComponent/chat'

export function Emojis({
  isOpen,
  setValue,
  setIsEmojiWindowOpen,
}: {
  isOpen: boolean
  setValue: React.Dispatch<React.SetStateAction<string>>
  setIsEmojiWindowOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [currentTab, setCurrentTab] = useState<'EMOJI' | 'GIF'>('EMOJI')

  const rect = document.getElementById('chat-body')?.getBoundingClientRect()
  const giphyStyles = [{ columns: 3, imageWidth: 96, gutter: 4 }]
  if (rect) {
    giphyStyles[0].imageWidth = (rect.width - 64) / 3
  }

  const handleClickOutside = (e: any) => {
    if (e.target.closest('#emoji-window') || e.target.closest('#emoji-button')) {
      return
    }
    setIsEmojiWindowOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  return (
    <div
      id="emoji-window"
      className={classNames({
        [classes.root]: isOpen,
        [classes.root_closed]: !isOpen,
      })}
    >
      {currentTab === 'EMOJI' ? (
        <div className="flex flex-wrap gap-16">
          {emojiList.map((e, i) => (
            <button
              key={i}
              type="button"
              className="aspect-square h-26 flex justify-center items-center"
              onClick={() => {
                setValue((prev) => prev + String.fromCodePoint(+('0x' + e)))
              }}
            >
              <Emoji text={String.fromCodePoint(+('0x' + e))} />
            </button>
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <ReactGiphySearchBox
            apiKey={process.env.VITE_APP_GIPHY_API_KEY!}
            masonryConfig={giphyStyles}
            onSelect={async (item) => {
              try {
                await sendMessage({ msg: `https://i.giphy.com/media/${item.id}/giphy.webp` })
              } catch (err) {
                console.log({ err })
              } finally {
                setCurrentTab('EMOJI')
                setIsEmojiWindowOpen(false)
              }
            }}
          />
        </div>
      )}
      <div className="grid grid-cols-2 pt-16">
        <button
          className={classNames({
            'opacity-50': currentTab === 'GIF',
          })}
          disabled={currentTab === 'EMOJI'}
          onClick={() => setCurrentTab('EMOJI')}
        >
          EMOJI
        </button>
        <button
          className={classNames({
            'opacity-50': currentTab === 'EMOJI',
          })}
          disabled={currentTab === 'GIF'}
          onClick={() => setCurrentTab('GIF')}
        >
          GIF
        </button>
      </div>
    </div>
  )
}

export const emojiList = [
  '1F383',
  '1F384',
  '1F386',
  '1F387',
  '1F9E8',
  '2728',
  '1F388',
  '1F389',
  '1F38A',
  '1F38B',
  '1F38D',
  '1F38E',
  '1F38F',
  '1F390',
  '1F391',
  '1F9E7',
  '1F380',
  '1F381',
  '1F3AB',
  '1F970',
  '1F60D',
  '1F929',
  '1F618',
  '1F617',
  '1F61A',
  '1F619',
  '1F972',
  '1F917',
  '1F92D',
  '1F92B',
  '1F914',
  '1F60E',
  '1F913',
  '1F9D0',
  '1F615',
  '1F61F',
  '1F641',
  '1F62E',
  '1F62F',
  '1F632',
  '1F633',
  '1F97A',
  '1F626',
  '1F627',
  '1F628',
  '1F630',
  '1F625',
  '1F622',
  '1F62D',
  '1F631',
  '1F616',
  '1F623',
  '1F61E',
  '1F613',
  '1F629',
  '1F62B',
  '1F971',
  '1F4A9',
  '1F921',
  '1F479',
  '1F47A',
  '1F47B',
  '1F47D',
  '1F47E',
  '1F916',
]

/**
 * @generated SignedSource<<82c5c3074506b8b53e661206b2562a45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type NotificationType = "FREESPIN_ADDED" | "NEW_BONUS_ADDED" | "USER_LOGIN" | "MANUAL_CREDIT" | "MANUAL_CASHBACK" | "DIRECT_RAIN" | "BANKROLL_PAYOUT" | "RAKEBACK" | "DIRECT_COMPETITION_CREDIT" | "DEPOSIT_BONUS" | "COMPETITION_VOTING_PAYOUT" | "AFFILIATE_PAYOUT" | "WAGER_LEVEL_REACHED_BONUS" | "CHAT_GIVEAWAY" | "%future added value";
export type NotificationsOldNotificationUpdatedSubscription$variables = {};
export type NotificationsOldNotificationUpdatedSubscription$data = {
  readonly notificationUpdated: {
    readonly notificationId: number;
    readonly type: NotificationType;
    readonly readTime: string | null;
    readonly meta: any | null;
    readonly insertedAt: string;
  };
};
export type NotificationsOldNotificationUpdatedSubscription = {
  variables: NotificationsOldNotificationUpdatedSubscription$variables;
  response: NotificationsOldNotificationUpdatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "notificationUpdated",
    "plural": false,
    "selections": [
      {
        "alias": "notificationId",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "readTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsOldNotificationUpdatedSubscription",
    "selections": (v0/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsOldNotificationUpdatedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2a433ed88d408e94909a7b1f0600c95a",
    "id": null,
    "metadata": {},
    "name": "NotificationsOldNotificationUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationsOldNotificationUpdatedSubscription {\n  notificationUpdated {\n    notificationId: id\n    type\n    readTime\n    meta\n    insertedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf9a9bde73491e496b215322ff94fcd8";

export default node;

import { getUserIcon } from '@src/App'
import React, { useRef } from 'react'

import classes from './ProfileHero.module.scss'
import { ProfileQueryResponse } from './gql/__generated__/ProfileQuery.graphql'
import { IStore, store } from '@src/store'
import { AppTypes } from '@src/reducers/app.reducer'
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay'
import { ProfileHeroImageMutation } from './__generated__/ProfileHeroImageMutation.graphql'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Profile = ({ uuid, profile }: { uuid: string; profile: ProfileQueryResponse['profile'] }) => {
  const { t } = useTranslation()
  const me = useSelector((s: IStore) => s.authentication.user)
  const ref = useRef<HTMLInputElement>(null)
  const [uploadFile] = useMutation<ProfileHeroImageMutation>(uploadProfileImageMutation)
  const isPrivateUser = me?.preferences.find((x) => x.key === 'private')?.value === 'true'
  const isMyProfile = uuid === me?.uuid
  const name = isMyProfile && isPrivateUser ? 'Private user' : profile.name

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file) {
      toast.error(t('toast.fileUploadError', 'There was an error while uploading file!'))
      return
    }

    if (file.size > 1024 * 1024 * 128) {
      toast.error(t('toast.fileExceedError', 'File must not exceed 512KB.'))
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      if (!reader.result) return

      uploadFile({
        variables: {
          input: {
            imageData: reader.result.toString(),
          },
        },
        onCompleted(response, errors) {
          if (errors) {
            for (const err of errors) {
              store.dispatch({
                type: AppTypes.SHOW_NOTIFICATION,
                notification: {
                  status: 'error',
                  text: err.message,
                },
              })
            }
            return
          }

          console.log({ response })
          toast.success(t('toast.fileUploadSuccess', 'Image uploaded successfully.'))
          window.location.reload()
        },
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <div className="flex h-full pointer-events-none">
      <img alt="Profile Head" src="/assets/pages/profile/hero.png" />

      <div className={classes.profile}>
        {getUserIcon(name, uuid, profile.hasProfileImage, profile.vip!)}
        <input ref={ref} type="file" className="hidden" onChange={handleFileChange} />
        {uuid === me?.uuid && (
          <button
            type="button"
            style={{
              background: '#D9D9D9',
              bottom: '10%',
              right: '10%',
            }}
            className="absolute flex justify-center items-center text-black rounded-full h-30 w-30 font-mono font-bold pointer-events-auto"
            onClick={() => {
              ref.current?.click()
            }}
          >
            +
          </button>
        )}
      </div>
    </div>
  )
}

export function ProfileHero({ uuid, profile }: { uuid: string; profile: ProfileQueryResponse['profile'] }) {
  const { t } = useTranslation()

  return (
    <div className={classes.hero}>
      <div className={classes.profileHead}>
        <div className="flex">
          <h2>{profile.name}</h2>
        </div>
        <div className={classes.profileStats}>
          <span>
            {profile.followers} {t('profile.followers', 'followers')}
          </span>
          <span>{t('profile.posts', '0 posts')}</span>
        </div>
        <div className={classes.profileQuote}>
          <span>DURA LEX SED LEX</span>
        </div>
      </div>
      <Profile uuid={uuid} profile={profile} />
    </div>
  )
}

export const uploadProfileImageMutation = graphql`
  mutation ProfileHeroImageMutation($input: UploadProfileImageInput!) {
    uploadProfileImage(input: $input) {
      user {
        uuid
        hasProfileImage
      }
    }
  }
`

import { loginTelegramMutation } from '@src/App'
import { GraphQLError } from '@src/RelayEnvironment'
import { ApploginTelegramMutation } from '@src/__generated__/ApploginTelegramMutation.graphql'
import formatError from '@src/components/errorFormatter'
import { useEffect, useRef } from 'react'
import { useMutation } from 'react-relay'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const TelegramLogin = () => {
  const { t } = useTranslation()
  const telegramWrapperRef = useRef<HTMLDivElement>(null)
  const [signUserInTelegram] = useMutation<ApploginTelegramMutation>(loginTelegramMutation)

  const handleAuth = (userData) => {
    if (userData) {
      const loginData = JSON.stringify(userData)
      signUserInTelegram({
        variables: {
          input: { dataOnauth: loginData },
        },
        onCompleted: (e) => {
          if (e.loginTelegram) {
            const token = e.loginTelegram.token
            const user = e.loginTelegram.user
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(user))
            window.location.href = '/'
          } else {
            toast.error(t('toast.unknownError', 'Unknown error, please try again.'))
          }
        },
        onError: (e: GraphQLError) => {
          toast.error(formatError(e)[0])
        },
      })
    }
  }

  useEffect(() => {
    if (telegramWrapperRef.current) {
      const scriptElement = document.createElement('script')
      scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22'
      scriptElement.setAttribute('data-telegram-login', 'Blackjack_fun_bot')
      scriptElement.setAttribute('data-size', 'large')
      scriptElement.setAttribute('data-radius', '7')
      scriptElement.setAttribute('data-auth-url', 'https://blackjack.fun')
      scriptElement.setAttribute('data-onauth', 'handleAuth(user)')
      scriptElement.async = true

      window.handleAuth = handleAuth
      telegramWrapperRef.current.appendChild(scriptElement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className="flex justify-center bg-eerieBlack" ref={telegramWrapperRef}></div>
}

export default TelegramLogin

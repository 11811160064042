export function ClubShape({ className }: { className?: string }) {
  return (
    <svg
      width="110"
      height="93"
      viewBox="0 0 110 93"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.9417 85.8535H34.0845V87.4272H55.9417V85.8535Z"
        fill="#02E5FF"
      />
      <path
        d="M78.5041 79.0505H100.361V75.8976H78.5041V79.0505Z"
        fill="#FF03F9"
      />
      <path
        d="M109.782 49.3137H85.0396V53.554H109.782V49.3137Z"
        fill="#FF03F9"
      />
      <path
        d="M22.3966 67.7996H0.462891V71.6956H22.3966V67.7996Z"
        fill="#02E5FF"
      />
      <path
        d="M15.5392 33.8772H42.8662V30.7243L15.5392 30.7243V33.8772Z"
        fill="#02E5FF"
      />
      <path
        d="M87.1545 15.5935H62.4121V20.1507H87.1545V15.5935Z"
        fill="#FF03F9"
      />
      <path
        d="M49.8114 71.8923C48.1503 74.2201 46.0629 76.2201 43.6641 77.7883C42.9264 80.3183 42.1504 82.4658 41.3745 84.2799H46.0574C47.3743 81.1926 48.7131 77.1435 49.8114 71.8923Z"
        fill="#02E5FF"
      />
      <path
        d="M45.3581 85.8535H40.6752C38.2217 91.061 36.0469 92.9298 36.0469 92.9298H40.7298C40.7298 92.9298 42.9046 91.0665 45.3581 85.8535Z"
        fill="#02E5FF"
      />
      <path
        d="M8.3753 58.6579C8.3753 48.5926 14.8942 40.0683 23.9267 37.0247H19.2438C10.2058 40.0683 3.69238 48.5926 3.69238 58.6579C3.69238 63.5102 5.21693 67.9964 7.79062 71.6957H12.4735C9.89438 67.9964 8.3753 63.5102 8.3753 58.6579Z"
        fill="#02E5FF"
      />
      <path
        d="M32.6424 23.2161C32.6424 11.3913 41.6257 1.66482 53.139 0.49546C52.3686 0.418959 51.5872 0.375244 50.8003 0.375244C38.1832 0.375244 27.9595 10.599 27.9595 23.2161C27.9595 27.0739 28.9376 30.6967 30.626 33.877H35.309C33.615 30.6967 32.6424 27.0739 32.6424 23.2161Z"
        fill="#02E5FF"
      />
      <path
        d="M12.9932 77.0234C16.7854 79.8212 21.4574 81.4987 26.5337 81.4987C27.3097 81.4987 28.0747 81.4495 28.8342 81.373C24.6759 80.9413 20.8672 79.3786 17.6761 77.0234H12.9932Z"
        fill="#02E5FF"
      />
      <path
        d="M82.1274 81.373C82.887 81.4495 83.652 81.4986 84.4279 81.4986C90.1491 81.4986 95.3675 79.3785 99.3783 75.8977H94.6954C91.2528 78.8867 86.9142 80.8757 82.1274 81.373Z"
        fill="#FF03F9"
      />
      <path
        d="M75.6195 12.4459H80.3024C76.4556 5.26581 68.882 0.375244 60.161 0.375244C59.3687 0.375244 58.5873 0.413495 57.8223 0.49546C65.5433 1.28232 72.1224 5.91607 75.6195 12.4459Z"
        fill="#FF03F9"
      />
      <path
        d="M61.1499 71.8923C64.5214 88.0558 70.2371 92.9299 70.2371 92.9299H74.92C74.92 92.9299 70.636 89.2634 67.2973 77.7883C64.8984 76.2201 62.8111 74.2201 61.1499 71.8923Z"
        fill="#FF03F9"
      />
      <path
        d="M97.2415 43.9914H101.924C97.7333 39.0025 91.4548 35.8223 84.4277 35.8223C83.6627 35.8223 82.914 35.8769 82.1709 35.9534C88.209 36.5927 93.5421 39.5817 97.2415 43.9914Z"
        fill="#FF03F9"
      />
      <path
        d="M102.586 58.6577C102.586 63.9799 100.75 68.865 97.6953 72.7502H102.378C105.433 68.865 107.269 63.9799 107.269 58.6577C107.269 55.3245 106.537 52.1661 105.252 49.3137H100.57C101.854 52.1716 102.586 55.3245 102.586 58.6577Z"
        fill="#FF03F9"
      />
      <path
        d="M78.3242 23.216C78.3242 28.2159 76.7013 32.8223 73.9746 36.5872C75.6358 36.1555 77.368 35.8932 79.1548 35.8441C81.57 32.2267 83.0071 27.8989 83.0071 23.2215C83.0071 20.544 82.5208 17.9867 81.6793 15.6042H76.9964C77.8433 17.9922 78.3242 20.5494 78.3242 23.2215V23.216Z"
        fill="#FF03F9"
      />
      <path
        d="M89.4328 75.9031V72.7502H97.7003C100.755 68.8651 102.591 63.98 102.591 58.6577C102.591 55.3245 101.859 52.1661 100.575 49.3137H94.3889V43.986H97.2467C93.5474 39.5763 88.2142 36.5874 82.1762 35.948C81.3784 35.8661 80.5751 35.8169 79.7555 35.8169C79.5533 35.8169 79.362 35.8333 79.1599 35.8387C77.373 35.8879 75.6408 36.1502 73.9797 36.5819C76.7064 32.8224 78.3293 28.2106 78.3293 23.2107C78.3293 20.5332 77.843 17.9759 77.0015 15.5935H71.9688V12.4405H75.6299C72.1273 5.91069 65.5537 1.27695 57.8326 0.490089C57.0622 0.413588 56.2808 0.369873 55.4939 0.369873C54.707 0.369873 53.9202 0.408124 53.1552 0.490089C41.6419 1.66492 32.6585 11.3859 32.6585 23.2107C32.6585 27.0685 33.6366 30.6914 35.3251 33.8716H37.2322V37.0245H23.9484C14.9104 40.0681 8.39697 48.5925 8.39697 58.6577C8.39697 63.51 9.92152 67.9962 12.4952 71.6956H21.7845V77.0233H17.6972C20.8884 79.3784 24.697 80.9412 28.8554 81.3729C29.6368 81.4548 30.4346 81.4986 31.2378 81.4986C35.8388 81.4986 40.1064 80.127 43.6855 77.7828C46.0844 76.2146 48.1717 74.2146 49.8329 71.8868C48.7346 77.1435 47.3958 81.1871 46.0789 84.2744H47.9422V85.8482H45.3795C42.9205 91.0557 40.7512 92.9244 40.7512 92.9244H70.2639C70.2639 92.9244 64.5483 88.0503 61.1768 71.8868C62.8379 74.2146 64.9253 76.2146 67.3241 77.7828C70.9033 80.127 75.1764 81.4986 79.7719 81.4986C80.5751 81.4986 81.3729 81.4548 82.1543 81.3729C86.941 80.8756 91.2797 78.8921 94.7222 75.8976H89.4546L89.4328 75.9031Z"
        fill="black"
      />
      <path
        d="M85.0395 15.5935H61.3462V18.7464H85.0395V15.5935Z"
        fill="black"
      />
      <path
        d="M93.7771 15.5935H88.7554V18.7464H93.7771V15.5935Z"
        fill="#02E5FF"
      />
      <path
        d="M96.8977 15.5935H91.876V18.7464H96.8977V15.5935Z"
        fill="#FF03F9"
      />
      <path
        d="M95.3396 15.5935H90.3179V18.7464H95.3396V15.5935Z"
        fill="black"
      />
      <path
        d="M3.69736 71.6956H27.3906V68.5427H3.69736V71.6956Z"
        fill="black"
      />
      <path
        d="M84.3238 52.4668H108.017V49.3139H84.3238V52.4668Z"
        fill="black"
      />
      <path
        d="M43.5162 33.8772H40.3633V37.0301H43.5162V33.8772Z"
        fill="#02E5FF"
      />
      <path
        d="M48.2373 33.8772H46.6636V37.0301H48.2373V33.8772Z"
        fill="#FF03F9"
      />
      <path
        d="M65.6576 15.5935H68.8105V12.4406H65.6576V15.5935Z"
        fill="#FF03F9"
      />
      <path
        d="M83.1269 75.9031H86.2798V72.7502H83.1269V75.9031Z"
        fill="#FF03F9"
      />
      <path
        d="M78.4063 75.9031H79.98V72.7502H78.4063V75.9031Z"
        fill="#02E5FF"
      />
      <path
        d="M12.3697 33.8772H13.9434V30.7243H12.3697V33.8772Z"
        fill="#02E5FF"
      />
      <path
        d="M103.509 75.9031H101.935V79.056H103.509V75.9031Z"
        fill="#FF03F9"
      />
      <path
        d="M106.662 75.9031H105.088V79.056H106.662V75.9031Z"
        fill="#FF03F9"
      />
      <path
        d="M24.9215 44.5869H21.7686V47.7398H24.9215V44.5869Z"
        fill="#02E5FF"
      />
      <path
        d="M79.3404 41.8276H76.1875V44.9805H79.3404V41.8276Z"
        fill="#FF03F9"
      />
      <path
        d="M40.8985 69.6028H37.7456V72.7557H40.8985V69.6028Z"
        fill="#FF03F9"
      />
      <path d="M54.2266 84.28H51.0737V85.8537H54.2266V84.28Z" fill="#02E5FF" />
      <path
        d="M30.937 87.4329H32.5107V85.8591H30.937V87.4329Z"
        fill="#02E5FF"
      />
      <path
        d="M27.7842 87.4329H29.3579V85.8591H27.7842V87.4329Z"
        fill="#02E5FF"
      />
      <path
        d="M35.8276 65.969H34.2539V67.5427H35.8276V65.969Z"
        fill="#02E5FF"
      />
      <path
        d="M80.9141 46.9531H79.3403V48.5269H80.9141V46.9531Z"
        fill="#02E5FF"
      />
    </svg>
  )
}

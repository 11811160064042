import { Channel, Socket } from 'phoenix'
import { ReplaySubject, Subject } from 'rxjs'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const messageBus = new ReplaySubject<{ type: string }>(50)
const controlBus = new Subject<{ action: string }>()
const unreadCountUpdated = new ReplaySubject<number>()
let socket: Socket
let channel: Channel
let unread = 0

const onChannelMessage = (msg: any) => {
  messageBus.next(msg)
  updateUnreadCount(msg)
}

const resetUnreadCount = () => {
  unread = 0
  unreadCountUpdated.next(unread)
}

const updateUnreadCount = (msg: any) => {
  if (!(msg.type === 'message' || msg.type === 'competition')) {
    return
  }
  const lastRead = localStorage.getItem('last_read_time') || 0
  if (msg.timestamp > lastRead) {
    unread++
  }
  unreadCountUpdated.next(unread)
}

const onControlMessage = (msg: any) => {
  controlBus.next(msg)
}

const sendMessage = (msg: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    channel
      .push('new_msg', msg, 10000)
      .receive('ok', (resp) => resolve(resp))
      .receive('error', (err) => reject(err))
      .receive('timeout', () => reject('timeout'))
  })
}

const sendControlMessage = (msg: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    channel
      .push('control', msg, 10000)
      .receive('ok', (resp) => resolve(resp))
      .receive('error', (err) => reject(err))
      .receive('timeout', () => reject('timeout'))
  })
}

const joinChannel = () => {
  channel = socket.channel('room:lobby')
  channel.on('msg', onChannelMessage)
  channel.on('control', onControlMessage)
  channel.onClose(() => {
    // console.log('channel closed');
  })
  channel.onError((e) => {
    // console.log('channel error', e);
  })

  channel
    .join()
    .receive('ok', (resp) => {
      messageBus.next({ type: 'channel_join', msg: resp.nickname } as any)
      // Send some statistics about this session
      FingerprintJS.load().then((fp) => {
        fp.get().then((result) => {
          const info = {
            confidence: result.confidence.score,
            fingerprint: result.visitorId,

            timezone: new Date().getTimezoneOffset() / 60,

            referrer: document.referrer,
            previousSites: window.history.length,

            browserName: navigator.appName,
            browserEngine: navigator.product,
            browserVersion1a: navigator.appVersion,
            browserVersion1b: navigator.userAgent,
            browserLanguage: navigator.language,
            browserOnline: navigator.onLine,
            browserPlatform: navigator.platform,
            javaEnabled: navigator.javaEnabled(),
            dataCookiesEnabled: navigator.cookieEnabled,

            sizeScreenW: window.screen.width,
            sizeScreenH: window.screen.height,
            sizeInW: window.innerWidth,
            sizeInH: window.innerHeight,
            sizeAvailW: window.screen.availWidth,
            sizeAvailH: window.screen.availHeight,
            scrColorDepth: window.screen.colorDepth,
            scrPixelDepth: window.screen.pixelDepth,
          }
          const str = JSON.stringify(info)
          let strToSend
          try {
            strToSend = btoa(str)
          } catch (err) {
            strToSend = str
          }
          channel.push('stats', strToSend)
        })
      })
    })
    .receive('error', (err) => messageBus.next({ type: 'error', msg: err } as any))
}

const init = () => {
  const token = localStorage.getItem('token')

  if (undefined !== socket && !token) {
    messageBus.next({ type: 'onOpen' })
    joinChannel()
    return
  }

  socket = new Socket('/chat', { params: { token } })
  socket.connect()
  socket.onOpen(() => {
    messageBus.next({ type: 'onOpen' })
    joinChannel()
  })
  socket.onClose(() => {
    if (channel) {
      channel.leave()
    }
    // console.log('con close');
    messageBus.next({ type: 'onClose' })
  })

  socket.onError(() => {
    // console.log('con error');
  })
}

export {
  init,
  messageBus,
  joinChannel,
  sendMessage,
  controlBus,
  sendControlMessage,
  resetUnreadCount,
  unreadCountUpdated,
}

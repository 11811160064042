import { graphql } from 'babel-plugin-relay/macro'

export const newGameMutation = graphql`
  mutation rbNewRbGameMutation($input: NewRbGameInput!) {
    newRbGame(input: $input) {
      game {
        state
        gameId
        serverSeed
        serverSeedHash
        currency
        virtualId
        finalShuffle
        finalShuffleHash
        result
        bet
        betColor
      }
    }
  }
`

export const startGameMutation = graphql`
  mutation rbStartRbGameMutation($input: StartRbGameInput!) {
    startRbGame(input: $input) {
      game {
        state
        gameId
        serverSeed
        serverSeedHash
        currency
        virtualId
        finalShuffle
        finalShuffleHash
        result
        bet
        betColor
      }
    }
  }
`

export interface RBGame {
  state: string
  gameId: string
  serverSeed: string | null
  serverSeedHash: string
  currency: string | null
  virtualId: number | null
  finalShuffle: string | null
  finalShuffleHash: string | null
  result: boolean | null
  bet: number | null
  betColor: string | null
}

import { useEffect, useRef, useState } from 'react'
import Tooltip from '../../Tooltip/Tooltip'
import { useLocation } from 'react-router-dom'
import { AppTypes } from '@src/reducers/app.reducer'
import React from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  isCollapsed: boolean
  label: string
  onAction: () => void
  children?: React.ReactNode
  linkPath?: string
  icon: React.ReactElement
  collapsedOnly?: boolean
}

const MenuItem = ({ isCollapsed, label, onAction, children, linkPath, icon, collapsedOnly }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | undefined>(0)
  const closeMenu = () => dispatch({ type: AppTypes.CLOSE_MENU })

  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const isActive = linkPath && pathname === linkPath
  const isChildActive = React.Children.toArray(children).some(
    (child: any) => child.props.linkPath && pathname === child.props.linkPath
  )

  useEffect(() => {
    if (isActive || isChildActive) {
      setIsExpanded(true)
    } else {
      setIsExpanded(false)
    }
  }, [isActive, isChildActive])

  const handleToggle = () => {
    if (!children) {
      closeMenu()
    } else if (children && !isCollapsed) {
      setIsExpanded((prev) => !prev)
    }

    onAction()
  }

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? contentRef.current.scrollHeight : 0)
    }
  }, [isExpanded, children])

  const iconSizeProps = {
    width: isCollapsed ? '24' : '16',
    height: isCollapsed ? '24' : '16',
  }

  const iconWithSize = React.cloneElement(icon, iconSizeProps)

  if (collapsedOnly && !isCollapsed) {
    return null
  }

  return (
    <div>
      <div
        className={`flex items-center align-middle rounded-4 cursor-pointer ${isCollapsed ? 'mx-10' : 'mx-5'} ${
          isActive ? 'bg-blackRussianHover' : 'hover:bg-blackRussianHover'
        }`}
        onClick={handleToggle}
      >
        {isCollapsed ? (
          <Tooltip type="dark" place="right" text={label}>
            <div className="p-7 mx-8 text-white">{iconWithSize}</div>
          </Tooltip>
        ) : (
          <div
            className={`bg-blackRussian flex items-center justify-between w-full px-15 py-6 rounded-4 ${
              isActive ? 'bg-blackRussianHover' : 'hover:bg-blackRussianHover'
            }`}
          >
            {children ? (
              <>
                <div className="flex items-center space-x-13">
                  {iconWithSize}
                  <span className="text-white text-xs leading-5 font-semibold">{label}</span>
                </div>
                <span className="text-white text-3xs">{isExpanded ? '▲' : '▼'}</span>
              </>
            ) : (
              <div className="flex items-center space-x-13 text-white">
                {iconWithSize}
                <span className="text-white text-xs leading-5 font-semibold">{label}</span>
              </div>
            )}
          </div>
        )}
      </div>
      {!isCollapsed && children && (
        <div
          ref={contentRef}
          style={{ maxHeight: `${height}px` }}
          className="transition-all duration-300 ease-in-out overflow-hidden"
        >
          <div className="pb-10">{children}</div>
        </div>
      )}
    </div>
  )
}

export default MenuItem

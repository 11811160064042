export function VoteSVG() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66321 10.7058C8.53276 10.7058 7.6106 9.86263 7.6106 8.82886C7.6106 8.66567 7.75937 8.50249 7.9675 8.50249C8.17583 8.50249 8.3244 8.63854 8.3244 8.82886C8.3244 9.50891 8.9492 10.0802 9.69288 10.0802C10.4366 10.0802 11.0614 9.50891 11.0614 8.82886C11.0614 8.23041 10.6449 7.87669 9.574 7.57746C9.098 7.44142 7.61063 7.00612 7.61063 5.70048C7.61063 4.66675 8.53274 3.82349 9.66324 3.82349C10.7937 3.82349 11.7159 4.6667 11.7159 5.70048C11.7159 5.86366 11.5671 6.02684 11.359 6.02684C11.1805 6.02684 11.0021 5.89079 11.0021 5.70048C11.0021 5.02042 10.3773 4.44908 9.63357 4.44908C8.88989 4.44908 8.26509 5.02042 8.26509 5.70048C8.26509 6.27182 8.71121 6.65264 9.75245 6.95187C10.7341 7.2511 11.7158 7.68637 11.7158 8.82886C11.7158 9.86258 10.7937 10.7058 9.66321 10.7058Z"
        fill="#3CCEA2"
      />
      <path
        d="M9.32104 13.7647C6.12357 13.7647 3.50525 10.6595 3.50525 6.86743C3.50525 3.07536 6.12352 0 9.32104 0C12.5185 0 15.1368 3.07541 15.1368 6.89729C15.1368 10.6894 12.5186 13.7647 9.32104 13.7647ZM9.32104 0.686743C6.45091 0.686743 4.10949 3.46347 4.10949 6.86743C4.10949 10.2713 6.45082 13.0481 9.32104 13.0481C12.1912 13.0481 14.5326 10.2714 14.5326 6.86743C14.5324 3.46357 12.1911 0.686743 9.32104 0.686743Z"
        fill="#3CCEA2"
      />
      <path
        d="M20.3017 23.7059H20.2213C18.1313 23.076 15.9878 22.8363 13.8442 22.9861C13.362 23.016 12.8528 23.0761 12.3704 23.106C11.4325 23.1959 10.4679 23.316 9.47658 23.256C7.81535 23.166 6.15398 22.4763 5.13588 21.3967L1.08991 17.0185C0.87553 16.8087 0.768433 16.5387 0.768433 16.2389C0.768433 15.9389 0.87553 15.6392 1.06318 15.4291C1.54539 14.8593 2.18857 14.5596 2.88513 14.5294H2.93878C3.60865 14.5294 4.25177 14.8294 4.73403 15.3392L6.34181 17.0486C6.47582 17.1685 6.47582 17.4084 6.34181 17.5584C6.23471 17.7084 6.02033 17.7084 5.88632 17.5584L4.27854 15.8489C3.90342 15.4291 3.39431 15.2191 2.88516 15.2191C2.34931 15.2191 1.86691 15.459 1.51868 15.8788C1.43831 15.9688 1.41158 16.0587 1.41158 16.1788C1.41158 16.2987 1.46522 16.3888 1.51868 16.4788L5.59153 20.857C6.5025 21.8167 8.00296 22.4762 9.53036 22.5363C10.4683 22.5662 11.4059 22.4762 12.3169 22.3863C12.7991 22.3263 13.3083 22.2963 13.7907 22.2664C16.0147 22.1164 18.2117 22.3863 20.3822 23.0161C20.5429 23.0762 20.6502 23.2561 20.5966 23.436C20.5697 23.6159 20.4357 23.7058 20.3016 23.7058L20.3017 23.7059Z"
        fill="#3CCEA2"
      />
      <path
        d="M8.50947 19.8821C7.63883 19.8821 6.92639 19.0991 6.92639 18.1421C6.92639 17.1851 7.63878 16.402 8.50947 16.402H9.72318C10.8049 16.402 11.8602 16.17 12.8628 15.677L13.0476 15.59C13.7072 15.271 14.3931 14.952 15.1318 14.7491C17.1631 14.1692 20.3292 14.6043 22.5982 17.6782C22.7036 17.8232 22.6773 18.0261 22.5453 18.1712C22.4134 18.2871 22.2288 18.2581 22.0968 18.1131C20.1445 15.4741 17.2424 14.8652 15.2636 15.4162C14.5777 15.5902 13.9445 15.9092 13.2849 16.2282L13.1001 16.3152C12.0184 16.8371 10.884 17.0692 9.72311 17.0692H8.50939C7.98178 17.0692 7.53327 17.5621 7.53327 18.1421C7.53327 18.722 7.98176 19.215 8.50939 19.215H9.88136C10.6729 19.215 11.4907 19.128 12.2557 18.9249L14.8149 18.3158C14.9732 18.2869 15.1579 18.4028 15.1843 18.5768C15.2106 18.7508 15.1051 18.9538 14.9468 18.9828L12.4142 19.5923C11.5963 19.7954 10.752 19.8824 9.90782 19.8824L8.50947 19.8821Z"
        fill="#3CCEA2"
      />
      <path
        d="M26.4474 26H20.3425C19.947 26 19.601 25.7721 19.4033 25.4019C19.2055 25.0317 19.1809 24.5477 19.3786 24.1491L23.0614 15.9775C23.2591 15.5505 23.6298 15.2941 24.0253 15.2941H26.4474C26.5957 15.2941 26.7439 15.4365 26.7439 15.6357C26.7439 15.8065 26.6203 15.9773 26.4474 15.9773L24.0251 15.9775C23.8274 15.9775 23.6544 16.0913 23.5555 16.2907L19.8975 24.4623C19.8234 24.6617 19.8234 24.8609 19.9222 25.0603C20.021 25.2312 20.1941 25.3452 20.367 25.3452H26.4718C26.6201 25.3452 26.7684 25.4876 26.7684 25.6868C26.7686 25.886 26.6203 25.9998 26.4474 25.9998L26.4474 26Z"
        fill="#3CCEA2"
      />
    </svg>
  )
}

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { debounceTime } from 'rxjs'
import { unreadCountUpdated } from '../../chatComponent/chat'
import Chat from '../../icons/chat'
import { AppTypes } from '../../../reducers/app.reducer'
import { store, IStore } from '../../../store'
import styles from './ChatControl.module.scss'

export const ChatControl = () => {
  const [unread, setUnread] = useState(0)
  const chatOpened = useSelector((state: IStore) => state.app.chatOpened)
  const openChat = () => store.dispatch({ type: AppTypes.OPEN_CHAT })
  const closeChat = () => store.dispatch({ type: AppTypes.CLOSE_CHAT })

  useEffect(() => {
    const s = unreadCountUpdated.pipe(debounceTime(50)).subscribe((count) => {
      setUnread(count)
    })
    return () => {
      s.unsubscribe()
    }
  }, [])

  const toggle = (e) => {
    e.stopPropagation()

    if (chatOpened) {
      closeChat()
    } else {
      openChat()
      setTimeout(() => {
        localStorage.setItem('last_read_time', Date.now().toString())
        setUnread(0)
      })
    }
  }

  return (
    <div className={styles.root} onMouseDown={toggle}>
      <Chat className="w-18 h-18 md:w-20 md:h-20 hover:text-eucalyptus" />
      {unread > 0 ? <div className={styles.number}>{unread}</div> : null}
    </div>
  )
}

import React from 'react'

import classes from './ProfileAffiliate.module.scss'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'
import { toast } from 'react-toastify'
import { graphql } from 'babel-plugin-relay/macro'
import { useTranslation } from 'react-i18next'

export function ProfileAffiliate() {
  return <DirectLinkComponent />
}

const DirectLinkComponent = () => {
  const { t } = useTranslation()
  const me = useSelector((state: IStore) => state.authentication.user)!

  const baseURL = window.location.origin
  const url = `${baseURL}/bonus/${me?.uuid ?? ''}`

  return (
    <div className={classes.root}>
      <p>
        <b>{t('profile.inviterYourFriends.heading', 'Invite your friends and start winning.')}</b>
        <br />
        <br />
        {t(
          'profile.inviterYourFriends.text',
          'Blackjack.fun offers a new opportunity for all players to increase their winnings. Use the referral link to bring new players and you will receive 10% from their rakeback.'
        )}
      </p>
      <div className="flex flex-col gap-y-8">
        <label htmlFor="ref-link">{t('profile.inviterYourFriends.referralLink', 'Referral link')}</label>
        <div className="relative flex flex-col">
          <input type="text" name="ref-link" readOnly value={url} />
          <button
            type="button"
            style={{
              height: 'calc(100% - 8px)',
              borderRadius: '7px',
              background: '#17181B',
            }}
            className="absolute flex items-center right-4 top-4 p-8"
            onClick={() => {
              if (!url) return
              navigator.clipboard.writeText(url)
              toast.success(t('toast.copiedToClipboard', 'Copied to clipboard'))
            }}
          >
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5 0H7.5C6.83696 0 6.20107 0.237053 5.73223 0.65901C5.26339 1.08097 5 1.65326 5 2.25V3.75H2.5C1.83696 3.75 1.20107 3.98705 0.732233 4.40901C0.263392 4.83097 0 5.40326 0 6V18.75C0 19.3467 0.263392 19.919 0.732233 20.341C1.20107 20.7629 1.83696 21 2.5 21H12.5C13.163 21 13.7989 20.7629 14.2678 20.341C14.7366 19.919 15 19.3467 15 18.75V17.25H17.5C18.163 17.25 18.7989 17.0129 19.2678 16.591C19.7366 16.169 20 15.5967 20 15V2.25C20 1.65326 19.7366 1.08097 19.2678 0.65901C18.7989 0.237053 18.163 0 17.5 0ZM13.3333 18.75C13.3333 18.9489 13.2455 19.1397 13.0893 19.2803C12.933 19.421 12.721 19.5 12.5 19.5H2.5C2.27899 19.5 2.06702 19.421 1.91074 19.2803C1.75446 19.1397 1.66667 18.9489 1.66667 18.75V6C1.66667 5.80109 1.75446 5.61032 1.91074 5.46967C2.06702 5.32902 2.27899 5.25 2.5 5.25H12.5C12.721 5.25 12.933 5.32902 13.0893 5.46967C13.2455 5.61032 13.3333 5.80109 13.3333 6V18.75ZM18.3333 15C18.3333 15.1989 18.2455 15.3897 18.0893 15.5303C17.933 15.671 17.721 15.75 17.5 15.75H15V6C15 5.40326 14.7366 4.83097 14.2678 4.40901C13.7989 3.98705 13.163 3.75 12.5 3.75H6.66667V2.25C6.66667 2.05109 6.75446 1.86032 6.91074 1.71967C7.06702 1.57902 7.27899 1.5 7.5 1.5H17.5C17.721 1.5 17.933 1.57902 18.0893 1.71967C18.2455 1.86032 18.3333 2.05109 18.3333 2.25V15Z"
                fill="#757575"
              />
            </svg>
          </button>
        </div>
        <label htmlFor="promo-code">{t('profile.inviterYourFriends.promoCode', 'Promo code')}</label>
        <div className="relative flex flex-col">
          <input type="text" name="promo-code" readOnly value={me.name} />
          <button
            type="button"
            style={{
              height: 'calc(100% - 8px)',
              borderRadius: '7px',
              background: '#17181B',
            }}
            className="absolute flex items-center right-4 top-4 p-8"
            onClick={() => {
              if (!url) return
              navigator.clipboard.writeText(me.name)
              toast.success(t('toast.copiedToClipboard', 'Copied to clipboard'))
            }}
          >
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5 0H7.5C6.83696 0 6.20107 0.237053 5.73223 0.65901C5.26339 1.08097 5 1.65326 5 2.25V3.75H2.5C1.83696 3.75 1.20107 3.98705 0.732233 4.40901C0.263392 4.83097 0 5.40326 0 6V18.75C0 19.3467 0.263392 19.919 0.732233 20.341C1.20107 20.7629 1.83696 21 2.5 21H12.5C13.163 21 13.7989 20.7629 14.2678 20.341C14.7366 19.919 15 19.3467 15 18.75V17.25H17.5C18.163 17.25 18.7989 17.0129 19.2678 16.591C19.7366 16.169 20 15.5967 20 15V2.25C20 1.65326 19.7366 1.08097 19.2678 0.65901C18.7989 0.237053 18.163 0 17.5 0ZM13.3333 18.75C13.3333 18.9489 13.2455 19.1397 13.0893 19.2803C12.933 19.421 12.721 19.5 12.5 19.5H2.5C2.27899 19.5 2.06702 19.421 1.91074 19.2803C1.75446 19.1397 1.66667 18.9489 1.66667 18.75V6C1.66667 5.80109 1.75446 5.61032 1.91074 5.46967C2.06702 5.32902 2.27899 5.25 2.5 5.25H12.5C12.721 5.25 12.933 5.32902 13.0893 5.46967C13.2455 5.61032 13.3333 5.80109 13.3333 6V18.75ZM18.3333 15C18.3333 15.1989 18.2455 15.3897 18.0893 15.5303C17.933 15.671 17.721 15.75 17.5 15.75H15V6C15 5.40326 14.7366 4.83097 14.2678 4.40901C13.7989 3.98705 13.163 3.75 12.5 3.75H6.66667V2.25C6.66667 2.05109 6.75446 1.86032 6.91074 1.71967C7.06702 1.57902 7.27899 1.5 7.5 1.5H17.5C17.721 1.5 17.933 1.57902 18.0893 1.71967C18.2455 1.86032 18.3333 2.05109 18.3333 2.25V15Z"
                fill="#757575"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export const useBonusCode = graphql`
  mutation ProfileAffiliateUseBonusCodeMutation($input: UseBonusCodeInput!) {
    useBonusCode(input: $input) {
      result
    }
  }
`

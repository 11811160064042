const FacebookWhite = (props: any) => (
  <svg {...props} width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.0615 11V7.13186H3.53813V5.53232H5.04982C5.07749 4.34954 4.82226 3.00232 6.2214 2.36613C6.96679 2.02706 7.90406 2.18469 8.67097 2.26974V3.63113C8.02214 3.63113 7.45634 3.54722 7.11255 3.94412C6.85301 4.24351 6.93665 5.07928 6.93665 5.53232H8.59471L8.33702 7.12619L6.93665 7.13186V10.9994C7.10271 10.9994 7.37454 10.9252 7.52276 10.8917C7.7214 10.8463 7.88376 10.793 8.07688 10.7324C8.71279 10.5333 9.38499 10.1506 9.88315 9.76108C10.1648 9.54108 10.5363 9.1918 10.7645 8.90943C11.3844 8.14 11.7835 7.32634 11.9434 6.36582C11.9699 6.20536 11.9889 6.04433 12 5.88273V5.1734C11.96 4.59562 11.8216 4.02521 11.5879 3.49165C11.3438 2.93314 11.0111 2.39108 10.5756 1.94031C10.5381 1.90119 10.524 1.89495 10.4865 1.84959C10.3173 1.64887 10.1224 1.50938 9.94342 1.35629L9.33825 0.920258C8.72755 0.537526 8.03875 0.282938 7.31734 0.125309C6.98524 0.052732 6.60394 0.0113402 6.21402 0H5.79459C5.41021 0.0107732 5.0326 0.0510309 4.70172 0.120773C4.30135 0.205258 3.96679 0.30732 3.61931 0.441134C3.28229 0.570412 2.98278 0.733711 2.69065 0.903247L2.18327 1.25649C1.69065 1.62335 1.25769 2.07866 0.918819 2.57593C0.702337 2.89345 0.709717 2.90479 0.53936 3.22005C0.421894 3.43835 0.327183 3.70314 0.242927 3.94072C0.104551 4.33253 0.0252153 4.75835 0 5.18928V5.81866C0.0209102 6.17984 0.0787208 6.53536 0.168512 6.86706C0.253383 7.18005 0.388069 7.56392 0.53936 7.84345C0.728167 8.19387 0.793973 8.34639 1.06827 8.69567C1.48708 9.22923 1.58426 9.30464 2.09225 9.7401C2.13653 9.77809 2.14207 9.77696 2.18327 9.80758C2.22448 9.83876 2.23739 9.85464 2.28352 9.88809C2.35916 9.94309 2.41943 9.98505 2.49877 10.0355C3.1064 10.4211 3.72632 10.7205 4.45818 10.8872C4.61685 10.9235 4.89237 10.9994 5.06273 10.9994L5.0615 11Z"
      fill="white"
    />
  </svg>
)

export default FacebookWhite

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { store, IStore } from '../../store'
import { AppTypes } from '../../reducers/app.reducer'
import useIsMobile from '../../hooks/IsMobile'
import styles from './index.module.scss'
import { useLockScroll } from '@src/hooks/useLockScroll'
import { User } from '@src/reducers/authentication.reducer'
import { createPortal } from 'react-dom'
import CollapseIcon from '../icons/collapse'
import ExpandIcon from '../icons/expand'
import Logo from '../icons/logo'
import MobileNavBar from './components/MobileNavBar'
import MobileMenu from './components/MobileMenu'
import DesktopMenu from './components/DesktopMenu'

const AppNavigation: React.FC<{ me?: User }> = (props) => {
  const [showItems, setShowItems] = useState(true)
  const rootRef = useRef<HTMLDivElement>(null)
  const { me } = props
  const isMobile = useIsMobile('md')
  const isTablet = useIsMobile('lg', false, 1100)
  const collapsedMenu = useSelector((state: IStore) => state.app.collapsedMenu)

  const closeMenu = () => store.dispatch({ type: AppTypes.CLOSE_MENU })

  const toggleMenu = () => {
    setShowItems(false)
    store.dispatch({ type: AppTypes.TOGGLE_MENU })
  }

  const menuOpened = useSelector((state: IStore) => state.app.menuOpened)

  useEffect(() => {
    const handleTransitionEnd = () => {
      setShowItems(true)
    }

    const rootElement = rootRef.current
    if (rootElement) {
      rootElement.addEventListener('transitionend', handleTransitionEnd)
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener('transitionend', handleTransitionEnd)
      }
    }
  }, [collapsedMenu])

  useLayoutEffect(() => {
    if (!isMobile && isTablet) {
      store.dispatch({ type: AppTypes.COLLAPSE_MENU })
    }
  }, [isMobile, isTablet])

  const overlayVisible = !isMobile && isTablet && !collapsedMenu

  useLockScroll(overlayVisible || (isMobile && menuOpened))

  const location = useLocation()
  const isGame = location.pathname.includes('/game/')

  let shouldShowNav = true

  if (isGame && (isMobile || isTablet)) {
    shouldShowNav = false
  }

  if (isMobile && !menuOpened) {
    shouldShowNav = false
  }

  return (
    <>
      {shouldShowNav && (
        <div
          ref={rootRef}
          className={`${styles.root} ${!isMobile && collapsedMenu ? ' collapsed' : ''}${
            isMobile ? styles.mobileNav : ' w-250'
          }`}
        >
          <div className={`${styles.appNavItems} overflow-auto styled-scroll ${isMobile ? 'mb-145' : 'mb-0'}`}>
            <div
              className={`h-full flex flex-col justify-between w-full ${collapsedMenu ? 'px-0' : 'px-5'} ${
                isMobile ? 'mt-10' : 'mt-0'
              }`}
            >
              <div className={`${styles.items} pb-10 flex flex-col gap-10 justify-between h-full`}>
                {!isMobile && (
                  <div className={`${styles.navTop}`}>
                    {!isMobile && (
                      <>
                        {collapsedMenu ? (
                          <div className="flex flex-col justify-center align-middle items-center">
                            <Link to="/" className="" onClick={closeMenu}>
                              <Logo className="text-white w-54" />
                            </Link>
                            <ExpandIcon className="text-menuLink hover:text-menuLinkHover" onClick={toggleMenu} />
                          </div>
                        ) : (
                          <>
                            <CollapseIcon className="text-menuLink hover:text-menuLinkHover" onClick={toggleMenu} />
                            <Link to="/" onClick={closeMenu}>
                              <img
                                alt="Player First"
                                src="/assets/footer/footer-BJ-log.png"
                                className={styles.playerFirstLogo}
                              />
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
                {isMobile ? <MobileMenu me={me} /> : showItems && <DesktopMenu me={me} />}
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && <MobileNavBar me={me} />}
      <Portal>
        {overlayVisible && (
          <div
            id="over"
            className={styles.overlay}
            onClick={() => {
              store.dispatch({ type: AppTypes.COLLAPSE_MENU })
            }}
          />
        )}
      </Portal>
    </>
  )
}
export default AppNavigation

function Portal({ children }: { children: React.ReactNode }) {
  const body = document.querySelector('body') as HTMLBodyElement

  return <>{createPortal(children, body)}</>
}

import type { Message } from '@src/hooks/useChat'
import { useTranslation, Trans } from 'react-i18next'
import classNames from 'classnames'

import classes from './ParticipationReward.module.scss'
import { store } from '@src/store'
import { AppTypes } from '@src/reducers/app.reducer'
import { PlayerProfile } from '../PlayerProfile'
import { Link } from 'react-router-dom'
import { Money } from '@src/money'

export function ParticipationReward({
  style,
  className,
  msg,
}: {
  style?: React.CSSProperties
  className?: string
  msg: Message
}) {
  const { t } = useTranslation()

  const setModalView = (view) =>
    store.dispatch({
      type: AppTypes.SET_MODAL_VIEW,
      modal: { view: view },
    })

  const openProfile = (uuid: string) => {
    setModalView(<PlayerProfile uuid={uuid} />)
  }

  const message = JSON.parse(atob(msg.msg)) as {
    name: string
    amount: number
    currency: string
    tournament_id: string
    min_games: number
    winners: {
      uuid: string
      name: string
      has_profile_image: boolean
    }[]
  }

  const { amount, currency, min_games, winners } = message

  return (
    <div
      className={classNames('relative max-w-full flex flex-col gap-y-8 py-20', className)}
      style={{
        width: '322px',
        ...style,
      }}
    >
      <div className="relative flex items-center">
        <img alt="" src="/assets/chat/participation-reward.png" />
        <div className="flex absolute left-0 w-full justify-between px-8">
          <div className="inline-flex items-center w-[180px] h-[33px] text-white text-xs font-bold font-poppins leading-none pl-4">
            {Money.fromInteger(amount, currency)} {currency}{' '}
            {t('chat.participationRewards.participationBonus', 'Participation Bonus')}
            <br />
            <Trans i18nKey="chat.participationRewards.minGames" values={{ min_games }}>
              At least {min_games} bets played
            </Trans>
          </div>
          <Link to="/game/bitcoin-blackjack_v2" className={classes.button}>
            {t('chat.participationRewards.joinNow', 'Join now')}
          </Link>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          {winners.map((winner, i) => (
            <span key={i} onClick={() => openProfile(winner.uuid)}>
              {winner.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

import { useLayoutEffect } from 'react'

export function useLockScroll(condition: boolean) {
  useLayoutEffect(() => {
    if (condition) {
      document.body.classList.add('no-scroll')
      document.documentElement.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
      document.documentElement.classList.remove('no-scroll')
    }

    return () => {
      document.body.classList.remove('no-scroll')
      document.documentElement.classList.remove('no-scroll')
    }
  }, [condition])

  return condition
}

import React, { useEffect, useState } from 'react'
import { type BetStatistic, betStatisticQueue, getUserIcon } from '@src/App'

import classes from './LatestBets.module.scss'
import { Link } from 'react-router-dom'
import { formatAmount } from '@src/reducers/wallet.reducer'
import { useLatestBets } from './useLatestBets'
import classNames from 'classnames'
import { store } from '@src/store'
import { AppTypes } from '@src/reducers/app.reducer'
import { ProvablyFairValidator } from '../provablyFairValidator/ProvablyFairValidator'
import { useTranslation } from 'react-i18next'

export interface UserBet {
  gameId: string
  gameProvider: string
  currency: string
  virtualId: number
  finished: boolean
  bets: ReadonlyArray<number>
  win: number | null
  btcBet: number | null
  btcWin: number | null
  meta: unknown | null
  insertedAt: string
  multiplier: number
  profit: number
  normalizedGame: {
    label: string
    gameId: string
    provider: string
  } | null
  user: {
    uuid: string | null
    name: string | null
    hasProfileImage: boolean
    vip: any
  }
}

export const Tabs = ['Latest', 'My', 'Top', 'Sports', 'Highrollers', 'HighestWins'] as const

export function LatestBets() {
  const [data, setData] = useState<BetStatistic>({
    recent: [],
    biggestWins: [],
    biggestWinsGlobal: [],
    highrollers: [],
  })
  const [betTab, setBetTab] = useState<typeof Tabs[number]>('Latest')
  const { myBets, sportsBets } = useLatestBets({ tab: betTab })
  const { t } = useTranslation()

  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  const getWagerRows = (): UserBet[] | undefined => {
    if (betTab === 'Latest') {
      return data.recent.filter((b) => {
        if (b.user.name === 'Admin') {
          return false
        }
        return true
      }) as UserBet[]
    }
    if (betTab === 'Top') {
      return data.biggestWins.filter((b) => {
        if (b.user.name === 'Admin') {
          return false
        }
        return true
      })
    }
    if (betTab === 'My') {
      return myBets as UserBet[]
    }

    if (betTab === 'Sports') {
      return sportsBets.filter((b) => {
        if (b.user.name === 'Admin') {
          return false
        }
        return true
      }) as UserBet[]
    }

    if (betTab === 'Highrollers') {
      return data.highrollers.filter((b) => {
        if (b.user.name === 'Admin') {
          return false
        }
        return true
      }) as UserBet[]
    }

    if (betTab === 'HighestWins') {
      return data.biggestWinsGlobal.filter((b) => {
        if (b.user.name === 'Admin') {
          return false
        }
        return true
      }) as UserBet[]
    }
  }

  useEffect(() => {
    const subs = betStatisticQueue.subscribe((data) => {
      setData((prev) => {
        const newBets = data.recent.filter((b) => prev.recent.every((pb) => pb.gameId !== b.gameId))
        const recent = [
          ...newBets,
          ...prev.recent.map((b) => {
            const found = data.recent.find((rb) => rb.gameId === b.gameId)
            if (found) return found
            return b
          }),
        ]

        return {
          ...data,
          recent,
        }
      })
    })

    return () => {
      subs.unsubscribe()
    }
  }, [])

  const rows = getWagerRows() ?? []

  return (
    <div className="flex flex-col gap-y-8">
      <div className={classes.tabs}>
        <div>
          <button
            type="button"
            className={classNames({
              [classes.active]: betTab === 'Latest',
            })}
            onClick={() => setBetTab('Latest')}
          >
            {t('latestBets.latest', 'LATEST BETS')}
          </button>
          <button
            type="button"
            className={classNames({
              [classes.active]: betTab === 'HighestWins',
            })}
            onClick={() => setBetTab('HighestWins')}
          >
            {t('latestBets.highestWins', 'HIGHEST WINS')}
          </button>
        </div>
        <div>
          <button
            type="button"
            onClick={() => setBetTab('My')}
            className={classNames({
              [classes.active]: betTab === 'My',
            })}
          >
            {t('latestBets.myBets', 'MY BETS')}
          </button>
          <button
            type="button"
            onClick={() => setBetTab('Top')}
            className={classNames({
              [classes.active]: betTab === 'Top',
            })}
          >
            {t('latestBets.topWins24h', 'TOP WINS 24H')}
          </button>
          <button
            type="button"
            onClick={() => setBetTab('Sports')}
            className={classNames({
              [classes.active]: betTab === 'Sports',
            })}
          >
            {t('latestBets.sports', 'SPORTS')}
          </button>
          <button
            type="button"
            onClick={() => setBetTab('Highrollers')}
            className={classNames({
              [classes.active]: betTab === 'Highrollers',
            })}
          >
            {t('latestBets.highroller', 'HIGHROLLER')}
          </button>
        </div>
      </div>
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <div className={classes.respTable}>
            <div className={classes.respTableHead}>
              <div className={classes.tableHeadRow}>
                <div className={classes.tableHeadCell}>{t('latestBets.player', 'Player')}</div>
                <div className={classes.tableHeadCell}>{t('latestBets.status', 'Status')}</div>
                <div className={classes.tableHeadCell}>{t('latestBets.game', 'Game')}</div>
                <div className={classes.tableHeadCell}>{t('latestBets.bet', 'Bet')}</div>
                <div className={classes.tableHeadCell}>{t('latestBets.multiplier', 'Multiplier')}</div>
                <div className={classes.tableHeadCell}>{t('latestBets.payout', 'Payout')}</div>
              </div>
            </div>
            <div className={classes.respTableBody}>
              {rows
                .map((b, i) => {
                  let amount
                  const betSum = b.bets.reduce((acc, val) => acc + val, 0)
                  const payout = b.win ? formatAmount(betSum + b.profit, b.currency, true, 8) : '0'

                  let status = t('latestBets.running', 'Running')
                  if (b.finished) {
                    if (betSum === (b.win || 0)) {
                      status = t('latestBets.draw', 'Draw')
                    } else if (betSum > (b.win || 0)) {
                      status = t('latestBets.lose', 'Lose')
                    } else {
                      status = t('latestBets.win', 'Win')
                    }
                  }

                  if (
                    betTab === 'Latest' ||
                    betTab === 'Top' ||
                    betTab === 'Highrollers' ||
                    betTab === 'Sports' ||
                    betTab === 'HighestWins'
                  ) {
                    if (betTab === 'Top') {
                      status = t('latestBets.win', 'Win')
                      amount = formatAmount(betSum, b.currency)
                    } else {
                      amount = formatAmount(betSum, b.currency, true, 8)
                    }
                  } else {
                    amount = formatAmount(b.win!, b.currency)
                  }

                  let link: string
                  let label = b.gameProvider

                  if (b.normalizedGame) {
                    const { gameId, provider } = b.normalizedGame
                    link = `/game/${provider}:${gameId}`
                    label = b.normalizedGame.label
                  } else {
                    link = `/`
                  }

                  if (b.gameProvider.startsWith('blackjackfun')) {
                    link = '/game/bitcoin-blackjack_v2'
                    const tableName = (b?.meta as { table_name?: string })?.table_name
                    label = tableName ?? 'Blackjack.fun'
                  }

                  return (
                    <div className={classes.respTableRow} key={b.gameId}>
                      <div className={classes.tableBodyCell}>
                        <div className="flex items-center gap-x-18">
                          {getUserIcon(
                            b.user.name as string,
                            b.user.uuid as string,
                            b.user.hasProfileImage,
                            b.user.vip,
                            undefined,
                            '16px'
                          )}
                          {b.user.name}
                        </div>
                      </div>
                      <div className={classes.tableBodyCell}>{status}</div>
                      <div className={classes.tableBodyCell}>
                        {b.normalizedGame?.gameId === 'blackjack' && b.normalizedGame.provider === 'bjfun' ? (
                          <button
                            type="button"
                            onClick={() => {
                              console.log(b)
                              setModalView(<ProvablyFairValidator gameId={b.gameId} />)
                            }}
                          >
                            {label}
                          </button>
                        ) : (
                          <Link to={link}>{label}</Link>
                        )}
                      </div>
                      <div className={classes.tableBodyCell}>{amount}</div>
                      <div className={classes.tableBodyCell}>
                        {label === 'blackjackfun:invest' ? '' : b.multiplier.toFixed(2)}x
                      </div>
                      <div className={classes.tableBodyCell}>+{payout}</div>
                    </div>
                  )
                })
                .slice(0, 10)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

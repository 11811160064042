import React, { useState } from 'react'

import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faXmark } from '@fortawesome/free-solid-svg-icons'

import FacebookLogo from './svg/Facebook.svg?react'
import TwitterLogo from './svg/Twitter.svg?react'
import TelegramLogo from './svg/Telegram.svg?react'

import classes from './ShareWithFriends.module.scss'
import { useChatGiveawaysQuery$data } from '@src/hooks/__generated__/useChatGiveawaysQuery.graphql'
import { useMutation } from 'react-relay'
import { grabGiveawayMutation } from '@src/hooks/useChat'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'
import { Money } from '@src/money'

export function ShareWithFriends({
  giveaway,
  closeCallback,
}: {
  giveaway: useChatGiveawaysQuery$data['giveaways'][number]
  closeCallback?: () => void
}) {
  const me = useSelector((state: IStore) => state.authentication.user)
  const [grabGiveaway] = useMutation(grabGiveawayMutation)
  const [disabled, setIsDisabled] = useState(true)

  const baseURL = window.location.origin
  const url = `${baseURL}/bonus/${me?.uuid}`

  const telegramURL = new URL('https://t.me/share/url')
  telegramURL.searchParams.append('url', url)
  telegramURL.searchParams.append(
    'text',
    `New giveaway, ${fromInteger(giveaway.amount, giveaway.currency)} ${giveaway.currency} for first ${
      giveaway.usageLimit
    } users, click now before it ends!`
  )

  const facebookURL = new URL('https://www.facebook.com/sharer/sharer.php')
  facebookURL.searchParams.append('href', url)

  const tweetURL = new URL('https://twitter.com/intent/tweet')
  tweetURL.searchParams.append('url', url)

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.header}>
          <h2 className="py-16 text-sm font-poppins font-medium leading-6 uppercase">SHARE WITH YOUR FRIENDS</h2>

          <FontAwesomeIcon icon={faXmark} className={classes.xMark} onClick={closeCallback} />
        </div>
        <div className={classes.content}>
          <div className="flex flex-col gap-y-24">
            <p data-content>Share this reward with your friends to make them your referrals!</p>
            <p data-message className="text-center">{`"${giveaway.user.name} shared ${Money.fromInteger(
              giveaway.amount,
              giveaway.currency
            )} ${giveaway.currency} to ${giveaway.usageLimit} players"`}</p>
          </div>
          <p className="mt-8 text-sm">Share on</p>
          <div className="flex gap-x-30 justify-center items-center pt-24 pb-38">
            <a
              type="button"
              href={facebookURL.toString()}
              target="popup"
              onClick={() => {
                window.open(telegramURL.toString(), 'popup', 'width=600,height=600')
                setIsDisabled(false)
              }}
            >
              <FacebookLogo />
            </a>
            <a
              type="button"
              href={tweetURL.toString()}
              target="popup"
              onClick={() => {
                window.open(telegramURL.toString(), 'popup', 'width=600,height=600')
                setIsDisabled(false)
              }}
            >
              <TwitterLogo />
            </a>
            <a
              type="button"
              href={telegramURL.toString()}
              target="popup"
              onClick={() => {
                window.open(telegramURL.toString(), 'popup', 'width=600,height=600')
                setIsDisabled(false)
              }}
            >
              <TelegramLogo />
            </a>
          </div>
          <p className="text-sm">Or copy the link</p>
          <div className={classes.inputs}>
            <div className="flex flex-col w-full px-12 relative gap-y-10">
              <input
                name="referral-link"
                value={url}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                readOnly
              />
              <div className={classes.button}>
                <button
                  data-clipboard
                  onClick={() => {
                    if (!url) return
                    navigator.clipboard.writeText(url)
                    toast.success('Copied to clipboard', {
                      pauseOnHover: false,
                    })

                    setIsDisabled(false)
                  }}
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
          <div
            className="flex justify-center items-center"
            style={{
              paddingTop: '22px',
              paddingBottom: '36px',
            }}
          >
            <button
              type="button"
              className={classes.grabButton}
              disabled={disabled}
              onClick={() => {
                if (disabled) return

                grabGiveaway({
                  variables: {
                    input: {
                      giveaway_id: giveaway.giveawayId,
                    },
                  },
                  onCompleted: (resp: any) => {
                    if (resp.grabGiveaway.result) {
                      toast.success('Grabbed the giveaway!')
                    }
                    if (closeCallback) closeCallback()
                  },
                  onError: (error) => {
                    console.log(error)
                    if (closeCallback) closeCallback()
                  },
                })
              }}
            >
              {!disabled ? 'Grab' : 'I will share first!'}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

/**
 * @generated SignedSource<<7c352ca94c809b2a95bd4a96b5e8dbea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type AppComponentBetUpdatedSubscription$variables = {};
export type AppComponentBetUpdatedSubscription$data = {
  readonly betUpdated: {
    readonly gameId: string;
    readonly gameProvider: string;
    readonly currency: string;
    readonly virtualId: number;
    readonly finished: boolean;
    readonly bets: ReadonlyArray<number>;
    readonly win: number | null;
    readonly btcBet: number | null;
    readonly btcWin: number | null;
    readonly profit: number;
    readonly multiplier: number;
    readonly meta: any | null;
    readonly insertedAt: string;
    readonly normalizedGame: {
      readonly label: string;
      readonly gameId: string;
      readonly provider: string | null;
    } | null;
    readonly user: {
      readonly uuid: string | null;
      readonly name: string;
      readonly vip: {
        readonly badgeId: string | null;
        readonly offset: number | null;
        readonly avatar: string | null;
      } | null;
      readonly hasProfileImage: boolean;
    };
  } | null;
};
export type AppComponentBetUpdatedSubscription = {
  variables: AppComponentBetUpdatedSubscription$variables;
  response: AppComponentBetUpdatedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gameId",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GameBet",
    "kind": "LinkedField",
    "name": "betUpdated",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gameProvider",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "virtualId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "finished",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bets",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "win",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "btcBet",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "btcWin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "multiplier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NormalizedGame",
        "kind": "LinkedField",
        "name": "normalizedGame",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BetUser",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Vip",
            "kind": "LinkedField",
            "name": "vip",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "badgeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "offset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasProfileImage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppComponentBetUpdatedSubscription",
    "selections": (v1/*: any*/),
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppComponentBetUpdatedSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1ab73a0e79fca4648cb00775342b715f",
    "id": null,
    "metadata": {},
    "name": "AppComponentBetUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription AppComponentBetUpdatedSubscription {\n  betUpdated {\n    gameId\n    gameProvider\n    currency\n    virtualId\n    finished\n    bets\n    win\n    btcBet\n    btcWin\n    profit\n    multiplier\n    meta\n    insertedAt\n    normalizedGame {\n      label\n      gameId\n      provider\n    }\n    user {\n      uuid\n      name\n      vip {\n        badgeId\n        offset\n        avatar\n      }\n      hasProfileImage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ad88462bd88c3c4fe2177ab5b69869e";

export default node;

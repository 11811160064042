import { PortableText } from '@portabletext/react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import Footer from '../Footer/Footer'
import { useCampaignPage } from './useCampaignPage'

import classes from './campaignPage.module.scss'

export default function CampaignPage() {
  const { pathname } = useLocation()
  const campaignId = pathname.split('/campaign/')[1]

  const { campaign } = useCampaignPage({ id: campaignId })

  return (
    <div className={`${classes.root} mx-auto flex flex-col w-full px-16 gap-y-30`}>
      <div className="flex justify-center items-center">
        <img alt={campaign?.header} src={campaign?.image} className={`${classes.image}`} />
      </div>
      <div className="flex flex-col">
        <div className={classes.headerWrapper}>
          <h2 className="py-20 text-white text-center font-inter leading-5 font-bold text-sm uppercase">
            {campaign?.header}
          </h2>
        </div>
        <div className={classes.contentWrapper}>
          <div className="text-grey01 text-xs font-inter font-normal leading-5">
            <PortableText value={campaign?.content} />
          </div>
          <div className="font-medium text-lg leading-6 font-poppins">
            <h3 className="text-americanYellow text-center">Go to your wallet and deposit now!</h3>
            <span className="text-eucalyptus block text-center">Have fun!</span>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  )
}

// 16/9
// if width is 962px, height is 540px

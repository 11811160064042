import { ModalType, openModalTask } from '../modalComponent/modalComponent'
import { useTranslation } from 'react-i18next'

const DepositBanner = () => {
  const { t } = useTranslation()

  return (
    <div
      className="flex flex-col items-center px-10 bg-eucalyptus rounded-4 py-20 mb-30"
      style={{
        backgroundImage: 'url("/assets/landing/depositBanner.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="text-white text-sm md:text-xl lg:text-2xl font-medium flex flex-col items-center mb-15 text-center">
        <h3> {t('depositBanner.heading1', 'Exclusive VIP rewards at blackjack.fun are on top')}</h3>
        <h3> {t('depositBanner.heading2', 'Join now and start winning')}</h3>
      </div>
      <button
        className="bg-gradient-to-r-custom text-white font-semibold px-20 py-10 rounded-7 cursor-pointer"
        onClick={() => openModalTask.next({ type: ModalType.wallet })}
      >
        {t('depositBanner.deposit', 'Deposit')}
      </button>
    </div>
  )
}

export default DepositBanner

import React from 'react'

import classes from './EliteSeatPage.module.scss'

import { Tier1 } from './Tiers/Tier1'
import { Tier2 } from './Tiers/Tier2'
import { Tier3 } from './Tiers/Tier3'
import { Tier4 } from './Tiers/Tier4'
import { Tier5 } from './Tiers/Tier5'
import { Tier6 } from './Tiers/Tier6'
import { Tier7 } from './Tiers/Tier7'

export function EliteSeatPage() {
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <img alt="" src="/assets/pages/elite-seat/header.png" />
      </header>
      <div className={classes.wrapper}>
        <div className={classes.bg} />
        <div className="relative z-10">
          <div className="flex justify-center">
            <img alt="" src="/assets/pages/elite-seat/hero.png" />
          </div>
          <div className={classes.desc}>
            <h2 className="text-eucalyptus font-bold">What is “Elite Seat”?</h2>
            <p className="font-medium text-sm text-white">
              Elite Seat is our way of rewarding players that are loyal to our community. Unlike our competitors we
              believe that custom rewards make a difference and decided to create a program based on gradual progression
              with mystery instead of automatic code meant to keep you glued to the screen. After all we put players
              first!
            </p>
          </div>
          <div className={classes.tiers}>
            <Tier1 />
            <Tier2 />
            <Tier3 />
            <Tier4 />
            <Tier5 />
            <Tier6 />
            <Tier7 />
          </div>
        </div>
      </div>
    </div>
  )
}

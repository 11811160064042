/**
 * @generated SignedSource<<dd4521100675bf22bd68603384a53d5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RecoverPasswordCheckFpKeyQuery$variables = {
  key: string;
};
export type RecoverPasswordCheckFpKeyQuery$data = {
  readonly checkFpKey: boolean | null;
};
export type RecoverPasswordCheckFpKeyQuery = {
  variables: RecoverPasswordCheckFpKeyQuery$variables;
  response: RecoverPasswordCheckFpKeyQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "kind": "ScalarField",
    "name": "checkFpKey",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecoverPasswordCheckFpKeyQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecoverPasswordCheckFpKeyQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "86e0ff3ca794e721b839ac579e76034c",
    "id": null,
    "metadata": {},
    "name": "RecoverPasswordCheckFpKeyQuery",
    "operationKind": "query",
    "text": "query RecoverPasswordCheckFpKeyQuery(\n  $key: String!\n) {\n  checkFpKey(key: $key)\n}\n"
  }
};
})();

(node as any).hash = "1383e511ded28edfd260bc95f12420f4";

export default node;

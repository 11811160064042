import { useLockScroll } from '@src/hooks/useLockScroll'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'
import { Subject } from 'rxjs'
import { formatTableAmount, TableConstant } from '../../reducers/multiplayer.reducer'
import { getBalance } from '../../reducers/wallet.reducer'
import { store } from '../../store'
import { BecomeAnInvestorModal } from '../BecomeAnInvestor/BecomeAnInvestor'
import { joinTableTask, tableErrorQueue } from '../inHouseGames/InHouseGame'
import { InviteYourFriend } from '../InviteYourFriend'
import { ProvablyFairValidator } from '../provablyFairValidator/ProvablyFairValidator'
import { BetHistoryComponent } from '../rightSide/betHistory/BetHistoryComponent'
import { Table, TablesComponent } from '../tables/TablesComponent'
import { Confeti } from './Confeti'
import './modalComponent.scss'
import { RainModal } from './Rain'
import { ShareWithFriends } from '../ShareWithFriends/ShareWithFriends'
import { useChatGiveawaysQuery$data } from '@src/hooks/__generated__/useChatGiveawaysQuery.graphql'
import { BuyJack } from '../buyJack'
import { WalletModal } from '../wallet/WalletModal'
import classes from '@components/Modal/Modal.module.scss'

export enum ModalType {
  winner,
  rules,
  tournament,
  tables,
  provablyFair,
  betsHistory,
  wager,
  error,
  welcome,
  rain,
  beacomeAnInvestor,
  inviteYourFriends,
  shareWithFriends,
  buyJack,
  wallet,
}

export const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export const openModalTask = new Subject<OpenModalTaskAction>()
export const closeModalTask = new Subject<void>()

interface WinnerModal {
  username: string
  amount: string
}

export interface OpenModalTaskAction {
  type: ModalType
  username?: string
  winnerModal?: WinnerModal
  pendingTournament?: { table: Table; slot: number }
  gameId?: string
  error?: string
  payload?: {
    giveaway?: useChatGiveawaysQuery$data['giveaways'][number]
  }
  currency?: string
}

const idx = Math.floor(Math.random() * 3) + 1

export const ModalComponent = () => {
  const [action, setAction] = useState<OpenModalTaskAction>()
  const history = useHistory()

  useEffect(() => {
    openModalTask.subscribe((action) => {
      if (action) {
        setAction(action)
      }
    })
    closeModalTask.subscribe(() => {
      setAction(undefined)
    })
    joinTableTask.subscribe((request) => {
      const tables = store.getState().multiplayer.tables
      const myUUID = store.getState().authentication.user?.uuid
      if (!myUUID) {
        return
      }
      const table = tables.find((x) => x.uuid === request.table)
      if (table === undefined) {
        tableErrorQueue.next('Table not found')
        return
      }
      if (
        (table.type === 'TOURNAMENT_TABLE' || table.type === 'SIT_AND_GO' || table.type === 'FREEROLL') &&
        table.players.findIndex((x) => x.playerUuid === myUUID) < 0
      ) {
        setAction({
          type: ModalType.tournament,
          pendingTournament: { table, slot: request.idx },
        })
        return
      }
      const currency = table.currency
      const balance = getBalance(currency, table.virtualId)
      if (table.type === 'REGULAR' && balance <= 0) {
        tableErrorQueue.next('Add more coins')
        return
      }
      store.dispatch({
        type: TableConstant.SIT_TABLE,
        tableUuid: request.table,
        idx: request.idx,
      })
    })
  }, [])

  const closeWinnerModal = () => {
    setAction(undefined)
    history.push('/')
  }

  const closeModal = () => {
    setAction(undefined)
  }

  const confirmTournament = () => {
    if (!action?.pendingTournament) {
      return
    }
    store.dispatch({
      type: TableConstant.SIT_TABLE,
      tableUuid: action.pendingTournament.table.uuid,
      idx: action.pendingTournament.slot,
    })
    setAction(undefined)
  }

  const closeWelcome = () => {
    try {
      localStorage.setItem('popup', 'false')
    } catch (err) {}
    setAction(undefined)
  }

  /*const createAccount = () => {
    closeWelcome()
    history.push('/signup');
  }*/

  useLockScroll(action?.type !== undefined)

  if (action?.type === ModalType.winner && action.winnerModal) {
    return (
      <React.Fragment>
        <Confeti className="winner-confeti" />
        <Modal
          isOpen={action.type === ModalType.winner}
          ariaHideApp={false}
          overlayClassName={'winner-overlay'}
          onRequestClose={closeWinnerModal}
          style={customModalStyles}
        >
          <h2 className="row-1">CONGRATULATIONS {action.winnerModal.username?.toUpperCase()}</h2>
          <h5 className="row-2">You are an absolute unit. You defeated everyone!</h5>
          <h2 className="row-3">{action.winnerModal?.amount}</h2>
          <h3 className="row-4">HAS BEEN ADDED TO YOUR BALANCE</h3>
          <div className="buttons">
            <div onClick={closeWinnerModal} className="skewed-btn">
              <span>NEW GAME</span>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  } else if (action?.type === ModalType.rules) {
    return (
      <Modal
        isOpen={action?.type === ModalType.rules}
        onRequestClose={() => setAction(undefined)}
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <h3>Chatbox rules</h3>
        <ol>
          <li>Please be respectful and respect each other in the chat room.</li>
          <li>Don't make spam</li>
          <li>No Racism/sexism</li>
          <li>If you choose a username to harras other user will be forbidden.</li>
          <li>Don't ask for loans,tips,bonus etc. (Wait for the admin to post bonus link)</li>
          <li>No political, religious discussion are allowed.</li>
          <li>No alt accounts are allowed</li>
        </ol>
        <h3>If you break any rules above, you might be banned for a while in chat box</h3>
      </Modal>
    )
  } else if (action?.type === ModalType.tournament) {
    if (!action.pendingTournament) {
      return null
    }
    return (
      <Modal
        isOpen={undefined !== action.pendingTournament}
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <h2>BlackJack Tournament</h2>
        <table>
          <tbody>
            {action.pendingTournament.table?.type === 'FREEROLL' ? (
              <React.Fragment>
                <tr>
                  <td>Winners price</td>
                  <td>
                    {formatTableAmount(
                      action.pendingTournament.table,
                      action.pendingTournament.table?.meta?.freeroll_win_amount
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <tr>
                  <td>Currency</td>
                  <td>{action.pendingTournament.table?.currency}</td>
                </tr>
                <tr>
                  <td>Joining fee</td>
                  <td>
                    {formatTableAmount(action.pendingTournament.table, action.pendingTournament.table?.meta?.amount)}
                  </td>
                </tr>
                <tr>
                  <td>Winners fund</td>
                  <td>
                    {formatTableAmount(
                      action.pendingTournament.table,
                      action.pendingTournament.table?.meta?.winners_fund
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )}
            {action.pendingTournament.table?.type === 'TOURNAMENT_TABLE' ? (
              <React.Fragment>
                <tr>
                  <td>Start time</td>
                  <td>{formatTime(action.pendingTournament.table?.meta?.start_time)}</td>
                </tr>
                <tr>
                  <td>End time</td>
                  <td>{formatTime(action.pendingTournament.table?.meta?.end_time)}</td>
                </tr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <tr>
                  <td>Maximum players</td>
                  <td>{action.pendingTournament.table?.maxPlayers}</td>
                </tr>
                <tr>
                  <td>Duration (minutes)</td>
                  <td>{action.pendingTournament.table?.meta?.duration}</td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </table>
        <div className="modal description">
          {action.pendingTournament.table?.meta?.description.split('\n').map((x: any, i: number) => (
            <span key={i}>{x}</span>
          ))}
        </div>
        <div className="flex-row jcsb">
          <button className="color-button grn-button" onClick={() => confirmTournament()}>
            JOIN
          </button>
          <button className="color-button g-button" onClick={() => setAction(undefined)}>
            Close
          </button>
        </div>
      </Modal>
    )
  } else if (action?.type === ModalType.tables) {
    return (
      <Modal
        isOpen={action.type === ModalType.tables}
        onRequestClose={() => setAction(undefined)}
        portalClassName="ReactModalPortal table-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <TablesComponent history={history} />
      </Modal>
    )
  } else if (action?.type === ModalType.provablyFair) {
    return (
      <Modal
        isOpen={action.type === ModalType.provablyFair}
        onRequestClose={() => setAction(undefined)}
        portalClassName="ReactModalPortal table-overlay-portal pv-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <ProvablyFairValidator gameId={action.gameId as string} />
      </Modal>
    )
  } else if (action?.type === ModalType.betsHistory) {
    return (
      <Modal
        isOpen={action.type === ModalType.betsHistory}
        onRequestClose={() => setAction(undefined)}
        portalClassName="ReactModalPortal bet-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <BetHistoryComponent />
      </Modal>
    )
  } else if (action?.type === ModalType.error) {
    return (
      <Modal
        isOpen={action.type === ModalType.error}
        onRequestClose={() => setAction(undefined)}
        portalClassName="ReactModalPortal table-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <h3 className="modal-error">{action.error}</h3>
      </Modal>
    )
  } else if (action?.type === ModalType.welcome) {
    return (
      <Modal
        isOpen={action.type === ModalType.welcome}
        onRequestClose={closeWelcome}
        portalClassName="ReactModalPortal welcome-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <div className="welcome-container">
          <div className="welcome-inner-container">
            <img src={'/assets/welcome-' + idx + '.png'} alt="" />
            <span className="color-1 bold">CONGRATS! YOU DISCOVERED</span>
            <span className="color-2 bold">BLACKJACK.FUN</span>
            <span className="color-5 small">This is the coolest place to bet and win crypto currency.</span>
            <div className="welcome-stats color-1 mt-2">
              <div>
                <span>0,28%</span>
                <span>HOUSE EDGE</span>
                <span>IN-HOUSE</span>
                <span>GAMES</span>
              </div>
              <div>
                <span>$50,000</span>
                <span>WEEKLY WAGERS</span>
                <span>3000+</span>
                <span>AFFILIATE GAMES</span>
              </div>
              <div>
                <span>
                  24<sup>HRS</sup>
                </span>
                <span>FREEROLLS</span>
                <span>GREAT</span>
                <span>COMMUNITY</span>
              </div>
            </div>

            <span className="color-2 small mt-2">We have perks, tournaments and many new exciting things.</span>
            <span className="color-2 small">We're also transparent about our games so everything is</span>
            <span className="color-2 small">PROVABLY FAIR.</span>
            <div onClick={closeWelcome} className="button color-1 mt-1">
              CREATE AN ACCOUNT NOW
            </div>
          </div>
          <span onClick={closeWelcome} className="mt-1 close-button color-1">
            OR PLAY FOR FUN AND SEE FOR YOURSELF
          </span>
        </div>
      </Modal>
    )
  } else if (action?.type === ModalType.rain) {
    return (
      <Modal
        isOpen={action.type === ModalType.rain}
        onRequestClose={closeWelcome}
        portalClassName="ReactModalPortal rain-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <RainModal closeCallback={closeModal} />
      </Modal>
    )
  } else if (action?.type === ModalType.beacomeAnInvestor) {
    return (
      <Modal
        isOpen={action.type === ModalType.beacomeAnInvestor}
        onRequestClose={closeWelcome}
        portalClassName="ReactModalPortal becomeAnInvestor-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <BecomeAnInvestorModal closeCallback={closeModal} />
      </Modal>
    )
  } else if (action?.type === ModalType.inviteYourFriends) {
    return (
      <Modal
        isOpen={action.type === ModalType.inviteYourFriends}
        onRequestClose={closeWelcome}
        portalClassName="ReactModalPortal becomeAnInvestor-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <InviteYourFriend username={action.username} closeCallback={closeModal} />
      </Modal>
    )
  } else if (action?.type === ModalType.shareWithFriends) {
    const giveaway = action.payload?.giveaway as unknown as useChatGiveawaysQuery$data['giveaways'][number]

    return (
      <Modal
        isOpen={action.type === ModalType.shareWithFriends}
        onRequestClose={closeWelcome}
        portalClassName="ReactModalPortal becomeAnInvestor-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <ShareWithFriends giveaway={giveaway} closeCallback={closeModal} />
      </Modal>
    )
  } else if (action?.type === ModalType.buyJack) {
    return (
      <Modal
        isOpen={action.type === ModalType.buyJack}
        onRequestClose={closeWelcome}
        portalClassName="ReactModalPortal becomeAnInvestor-overlay-portal"
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <BuyJack closeCallback={closeModal} />
      </Modal>
    )
  } else if (action?.type === ModalType.wallet) {
    return (
      <Modal
        isOpen={action.type === ModalType.wallet}
        onRequestClose={closeWelcome}
        ariaHideApp={false}
        portalClassName={`${classes.root} ReactModalPortal wallet-overlay-portal`}
        id="wallet-modal-overlay"
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <WalletModal closeCallback={closeModal} currency={action.currency} />
      </Modal>
    )
  }

  return null
}

export const formatTime = (epoch: number) => {
  if (undefined === epoch) {
    return
  }
  const opts = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    hour12: false,
  }

  return new Date(epoch * 1000).toLocaleDateString('en-US', opts as any)
}

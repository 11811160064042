import { getLogoForCoin } from '../../coinsComponent/CoinsComponent'
import { fiatToCurrency, formatFiat, getFiatRatesQuery } from '../../rightSide/walletComponent/WalletComponent'
import { WalletComponentFiatRatesQuery } from '../../rightSide/walletComponent/__generated__/WalletComponentFiatRatesQuery.graphql'
import { getUserPropOr } from '../../SettingsComponent/SettingsComponent'
import { faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import { fetchQuery } from 'relay-runtime'
import { UserProp } from '../../../reducers/authentication.reducer'
import ConfigurationService from '../../../service/ConfigurationService'
import { ITipUser } from './TipUser.interface'
import Env, { GraphQLError } from '../../../RelayEnvironment'
import { interval } from 'rxjs'
import { formatBalance } from '../../../reducers/wallet.reducer'
import { store } from '../../../store'
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay'
import { TipUserComponentTipUserMutation } from './__generated__/TipUserComponentTipUserMutation.graphql'
import { Currency } from '../../../__generated__/AppComponentCompetitionUpdatedSubscription.graphql'
import './TipUser.scoped.scss'
import formatError from '@components/errorFormatter'
import classNames from 'classnames'
import { Money } from '@src/money'

const TipUser: React.FC<
  ITipUser & {
    isOpen?: boolean
  }
> = ({ uuid, name, isOpen }) => {
  const [fiatRates, setFiatRates] = useState<{ key: string; value: number }[]>([])
  const [state, setState] = useState<{ openCurrency?: string; strAmount?: string }>({
    openCurrency: undefined,
    strAmount: undefined,
  })
  const [customMessage, setCustomMessage] = useState<string>('')
  const [commit] = useMutation<TipUserComponentTipUserMutation>(tipuserMutation)
  const [error, setError] = useState<string>()
  const [result, setResult] = useState<boolean>()

  useEffect(() => {
    const modal = document.querySelector('.ReactModal__Content')
    const style = modal?.getAttribute('style')
    const overflow = style?.match(/overflow:.*?;/g)

    if (isOpen) {
      modal?.setAttribute('style', style?.replace(overflow?.[0] || '', 'overflow: hidden;') || '')
    } else {
      modal?.setAttribute('style', style || '')
    }
  }, [isOpen])

  useEffect(() => {
    const getFiatRates = () => {
      fetchQuery<WalletComponentFiatRatesQuery>(Env, getFiatRatesQuery, {}).subscribe({
        next: (data) => {
          setFiatRates(
            data.fiatRates.map((x) => {
              return { key: x.key as string, value: +(x.value as string) }
            })
          )
        },
      })
    }

    const fiatSubscription = interval(200 * 1000).subscribe(() => {
      getFiatRates()
    })
    getFiatRates()

    return () => {
      fiatSubscription.unsubscribe()
    }
  }, [])

  const setOpenCurrency = (currency: string) => {
    setState({ openCurrency: currency, strAmount: state.strAmount })
  }

  const setStrAmount = (amount: string) => {
    setState({ openCurrency: state.openCurrency, strAmount: amount })
  }

  const currencies = Object.keys(ConfigurationService.instance.getTradeableCurrencies())
  const userWallets = store.getState().wallet.wallets

  const formattedBalance = (currency: string) => {
    const wallet = getWallet(currency)
    if (wallet) {
      return (
        <span className="wallet">
          {formatBalance(wallet, true, false)}
          <br />
          <span className="fiat">{formatFiat(fiatRates, wallet)}$</span>
        </span>
      )
    }
    return <span className="wallet no-funds">No funds available</span>
  }

  const getWallet = (currency: string) => {
    const wallet = userWallets.find((x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === 0)
    return wallet
  }

  const submit = () => {
    if (!state.openCurrency) {
      return
    }
    let [, max] = ConfigurationService.instance.getTippingLimitsInUSD()
    max = fiatToCurrency(fiatRates, state.openCurrency as Currency, max)!

    const rawAmount = state.strAmount || getConvertedBalance(max).toString()
    if (!rawAmount || isNaN(rawAmount as any)) {
      return
    }
    setError(undefined)
    setResult(undefined)
    const c = ConfigurationService.instance.getCurrency(state.openCurrency!)
    const format = getUserPropOr(('unit_' + state.openCurrency) as UserProp, c.format)
    const allCurrencies = Money.listAll()
    const units = Object.keys(allCurrencies[state.openCurrency!].units)
      .map((x) => allCurrencies[state.openCurrency!].units[x])
      .sort((a: any, b: any) => (a.shift > b.shift ? -1 : a.shift === b.shift ? 0 : 1))

    const finalAmount = Money.convertUnit(+rawAmount, c.m_unit, format, units[0].code)
    commit({
      variables: { input: { amount: finalAmount, currency: state.openCurrency as Currency, uuid } },
      onCompleted: ({ tipUser }) => {
        setResult(true)
      },
      onError: (e: GraphQLError) => {
        setError(formatError(e)[0])
      },
    })
  }

  const getConvertedBalance = (maxLimit?: number): number => {
    let balance = getWallet(state.openCurrency!)?.balance || 0

    if (maxLimit) {
      balance = Math.max(0, Math.min(balance, maxLimit))
    }

    const c = ConfigurationService.instance.getCurrency(state.openCurrency!)
    const format = getUserPropOr(('unit_' + state.openCurrency) as UserProp, c.format)
    const amount = Money.convertUnit(balance, c.m_unit, c.s_unit, format)
    return amount
  }

  const step1 = () => {
    return currencies.map((currency) => {
      const b = getWallet(currency)?.balance || 0
      return (
        <div
          onClick={() => (b > 0 ? setOpenCurrency(currency) : false)}
          className={'row' + (b > 0 ? ' selectable' : '')}
          key={'cur_' + currency}
        >
          <div className="currency">
            <img className="tip-user-coin-img" alt={currency} src={getLogoForCoin(currency)} />
            {currency}
          </div>
          {formattedBalance(currency)}
        </div>
      )
    })
  }

  const step2 = () => {
    const options = currencies
      .filter((currency) => {
        return (getWallet(currency)?.balance || 0) > 0
      })
      .map((currency) => {
        return {
          value: currency,
          label: currency,
        }
      })

    if (!state.openCurrency) {
      if (options.length > 0) {
        state.openCurrency = options[0].value
      } else {
        return <h3>No funds available</h3>
      }
    }
    let [min, max] = ConfigurationService.instance.getTippingLimitsInUSD()
    min = fiatToCurrency(fiatRates, state.openCurrency as Currency, min)!
    max = fiatToCurrency(fiatRates, state.openCurrency as Currency, max)!

    const amount = getConvertedBalance(max)

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        padding: '2px 10px',
        backgroundColor: state.selected ? '#18191d' : state.isFocused ? '#212226' : '#212226',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      control: (provided) => ({
        ...provided,
        color: 'white',
        backgroundColor: 'unset',
        border: '1px solid #3CCEA2',
        borderRadius: '20px',
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#212226',
      }),
    }

    const c = ConfigurationService.instance.getCurrency(state.openCurrency!)
    const format = getUserPropOr(('unit_' + state.openCurrency) as UserProp, c.format)
    const formattedMin = Money.convertUnit(min < 0 ? 0 : min, c.m_unit, c.s_unit, format)
    const formattedMax = Money.convertUnit(max < 0 ? 0 : max, c.m_unit, c.s_unit, format)

    return (
      <div>
        <p>Amount</p>
        <div className="input-box">
          <input value={state.strAmount || amount} onChange={(e) => setStrAmount(e.target.value)} />
          <Select
            styles={customStyles}
            defaultValue={{ value: state.openCurrency as string, label: state.openCurrency as string }}
            onChange={(e) => setState({ openCurrency: e!.value, strAmount: undefined })}
            options={options as any}
            formatOptionLabel={(e) => {
              return (
                <div className="currency-select">
                  <img className="tip-user-coin-img" alt={e.value} src={getLogoForCoin(e.value)} />
                  <span>{e.label}</span>
                </div>
              )
            }}
          />
        </div>
        <div className="min-amount">
          <FontAwesomeIcon className="exclamation" color="#8F4BD0" icon={faExclamationCircle} />
          <React.Fragment>
            <span className="amount">
              Min: {formattedMin} {format}
            </span>
            <span className="amount">
              Max: {formattedMax} {format}
            </span>
          </React.Fragment>
        </div>

        <p>Custom message</p>
        <textarea defaultValue={customMessage} onChange={(e) => setCustomMessage(e.target.value)} />
        <button onClick={submit}>Tip {name}</button>
        {error ? <span className="tip-error">{error}</span> : null}
        {result !== undefined ? (
          <span className="tip-result">
            Tipped {name} <FontAwesomeIcon icon={faCircleCheck} />
          </span>
        ) : null}
      </div>
    )
  }

  return (
    <div
      className={classNames('tip-container', {
        hidden: !isOpen,
      })}
    >
      <div className="tip-inner-container">{state.openCurrency !== undefined || true ? step2() : step1()}</div>
    </div>
  )
}

export default TipUser

const tipuserMutation = graphql`
  mutation TipUserComponentTipUserMutation($input: TipUserInput!) {
    tipUser(input: $input) {
      result
    }
  }
`

/**
 * @generated SignedSource<<5c01603f685660e6b574ec1a56fc9c57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkNotificationsAsReadInput = {
  ids: ReadonlyArray<number>;
};
export type NotificationsControlMarkNotificationsAsReadMutation$variables = {
  input: MarkNotificationsAsReadInput;
};
export type NotificationsControlMarkNotificationsAsReadMutation$data = {
  readonly markNotificationsAsRead: {
    readonly count: number;
  } | null;
};
export type NotificationsControlMarkNotificationsAsReadMutation = {
  variables: NotificationsControlMarkNotificationsAsReadMutation$variables;
  response: NotificationsControlMarkNotificationsAsReadMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkNotificationsAsReadPayload",
    "kind": "LinkedField",
    "name": "markNotificationsAsRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsControlMarkNotificationsAsReadMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsControlMarkNotificationsAsReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea5254c65a78b3e74d361c709190ae8f",
    "id": null,
    "metadata": {},
    "name": "NotificationsControlMarkNotificationsAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationsControlMarkNotificationsAsReadMutation(\n  $input: MarkNotificationsAsReadInput!\n) {\n  markNotificationsAsRead(input: $input) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e6754521206174f01fcbf011790dd5a";

export default node;

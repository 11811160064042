export function RainSVG() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9531 9.02694C21.3852 5.44573 18.5032 2.77871 15.0891 2.69725C14.1905 1.02781 12.5465 0 10.7366 0C8.5908 0 6.69115 1.46231 5.99114 3.60185C4.90417 3.48298 3.88315 4.02051 3.28498 4.95027C1.5673 5.12298 0.252686 6.64533 0.252686 8.51728C0.252686 9.64377 0.746758 10.681 1.56524 11.3534C0.754234 12.2424 0.252686 13.4559 0.252686 14.7932C0.252686 17.512 2.32214 19.7242 4.86559 19.7242H21.2204C23.9953 19.7242 26.2527 17.3111 26.2527 14.3449C26.2527 11.6795 24.3899 9.40984 21.953 9.02704L21.9531 9.02694ZM1.09125 8.51722C1.09125 7.0523 2.16566 5.87279 3.53645 5.83196L3.77084 5.82476L3.88785 5.60782C4.26246 4.91399 4.95852 4.48272 5.70415 4.48272C5.86216 4.48272 6.02036 4.50333 6.18773 4.54556L6.58406 4.64502L6.6889 4.22456C7.17731 2.26515 8.84126 0.896493 10.7364 0.896493C12.093 0.896493 13.3356 1.59212 14.1169 2.74483C11.3126 3.08645 8.94852 5.16692 8.11796 8.08421C6.71688 7.95233 5.41818 8.65975 4.66334 9.86692C3.75796 9.90855 2.9206 10.2323 2.22265 10.7555C1.52062 10.2596 1.09116 9.42705 1.09116 8.51712L1.09125 8.51722ZM21.2203 18.8276H4.86545C2.78458 18.8276 1.09125 17.0179 1.09125 14.7931C1.09125 12.5683 2.78458 10.7586 4.84915 10.7582L5.13933 10.7604L5.26045 10.5367C5.78501 9.56746 6.75834 8.96549 7.80093 8.96549C7.97672 8.96549 8.16038 8.9847 8.34686 9.02193L8.73607 9.10037L8.83174 8.68932C9.52873 5.68504 12.0364 3.58617 14.9299 3.58617C18.1014 3.58617 20.7836 6.12332 21.168 9.48807L21.1973 9.74323C21.2116 9.93035 21.22 10.1193 21.22 10.3106C21.22 14.0183 18.3982 17.0347 14.9297 17.0347V17.9313C18.8607 17.9313 22.0587 14.5128 22.0587 10.3106C22.0587 10.1913 22.0495 10.0752 22.0445 9.95776C23.9685 10.3716 25.4136 12.2027 25.4136 14.3451C25.4136 16.8168 23.5323 18.8279 21.22 18.8279L21.2203 18.8276Z"
        fill="#3CCEA2"
      />
      <path
        d="M6.96222 20.6464L5.52386 23.2091C5.36735 23.4879 5.28479 23.8061 5.28479 24.1311V24.2069C5.28479 25.1958 6.03718 26.0001 6.96221 26.0001C7.88723 26.0001 8.63963 25.1958 8.63963 24.2069V24.1311C8.63963 23.8061 8.55707 23.4873 8.40056 23.2081L6.96222 20.6464ZM7.80093 24.207C7.80093 24.7015 7.42482 25.1035 6.96222 25.1035C6.49963 25.1035 6.12351 24.7015 6.12351 24.207V24.1311C6.12351 23.9688 6.16508 23.8087 6.24296 23.6698L6.96223 22.3882L7.68094 23.669C7.75938 23.8089 7.80094 23.969 7.80094 24.1311L7.80093 24.207Z"
        fill="#3CCEA2"
      />
      <path
        d="M11.1557 20.6464L9.71734 23.2091C9.56083 23.4879 9.47827 23.8061 9.47827 24.1311V24.2069C9.47827 25.1958 10.2307 26.0001 11.1557 26.0001C12.0807 26.0001 12.8331 25.1958 12.8331 24.2069V24.1311C12.8331 23.8061 12.7505 23.4873 12.594 23.2081L11.1557 20.6464ZM11.9944 24.207C11.9944 24.7015 11.6183 25.1035 11.1557 25.1035C10.6931 25.1035 10.317 24.7015 10.317 24.207V24.1311C10.317 23.9688 10.3586 23.8087 10.4364 23.6698L11.1557 22.3882L11.8744 23.669C11.9529 23.8089 11.9944 23.969 11.9944 24.1311L11.9944 24.207Z"
        fill="#3CCEA2"
      />
      <path
        d="M15.3493 20.6464L13.9109 23.2091C13.7544 23.4879 13.6719 23.8061 13.6719 24.1311V24.2069C13.6719 25.1958 14.4243 26.0001 15.3493 26.0001C16.2743 26.0001 17.0267 25.1958 17.0267 24.2069V24.1311C17.0267 23.8061 16.9442 23.4873 16.7876 23.2081L15.3493 20.6464ZM16.188 24.207C16.188 24.7015 15.8119 25.1035 15.3493 25.1035C14.8867 25.1035 14.5106 24.7015 14.5106 24.207V24.1311C14.5106 23.9688 14.5522 23.8087 14.63 23.6698L15.3493 22.3882L16.068 23.669C16.1465 23.8089 16.188 23.969 16.188 24.1311L16.188 24.207Z"
        fill="#3CCEA2"
      />
      <path
        d="M19.5429 20.6464L18.1045 23.2091C17.948 23.4879 17.8655 23.8061 17.8655 24.1311V24.2069C17.8655 25.1958 18.6179 26.0001 19.5429 26.0001C20.4679 26.0001 21.2203 25.1958 21.2203 24.2069V24.1311C21.2203 23.8061 21.1378 23.4873 20.9812 23.2081L19.5429 20.6464ZM20.3816 24.207C20.3816 24.7015 20.0055 25.1035 19.5429 25.1035C19.0803 25.1035 18.7042 24.7015 18.7042 24.207V24.1311C18.7042 23.9688 18.7458 23.8087 18.8236 23.6698L19.5429 22.3882L20.2616 23.669C20.3401 23.8089 20.3816 23.969 20.3816 24.1311L20.3816 24.207Z"
        fill="#3CCEA2"
      />
    </svg>
  )
}

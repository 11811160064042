import React, { useRef, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { PlayerProfileNewProfileQuery } from './__generated__/PlayerProfileNewProfileQuery.graphql'
import { getUserIcon } from '@src/App'

import classes from './PlayerProfileNew.module.scss'
import { CareerHighlights } from '../CareerHighlights'
// import { FeaturedBadges } from '../FeaturedBadges'
// import { ProfileFavoriteGames } from '../ProfileFavoriteGames'
import { ProfileRecentWins } from '../ProfileRecentWins'
import { formatBTC } from './functions/formatBTC'
import { PlayerProfileFollowUserMutation } from './__generated__/PlayerProfileFollowUserMutation.graphql'
import { store } from '@src/store'
import { AppTypes } from '@src/reducers/app.reducer'
import formatError from '../errorFormatter'
import TipUser from '@components/PlayerProfile/Tip/TipUser'
import classNames from 'classnames'
import { PlayerProfileNewUploadProfileImageMutation } from './__generated__/PlayerProfileNewUploadProfileImageMutation.graphql'

export function PlayerProfileNew({ uuid }: { uuid: string }) {
  const [isTipOpen, setIsTipOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const me = store.getState().authentication.user

  const [uploadFile] = useMutation<PlayerProfileNewUploadProfileImageMutation>(uploadProfileImageMutation)

  const data = useLazyLoadQuery<PlayerProfileNewProfileQuery>(
    profileQuery,
    { uuid: uuid },
    { fetchPolicy: 'network-only' }
  )

  const { profile } = data
  const { votes, followers, following, higestWin, tournamentWon, userStats, recentWins, isUserFollowed } = profile
  const [follow] = useMutation<PlayerProfileFollowUserMutation>(followUserMutation)

  const [isFollowed, setIsFollowed] = useState(isUserFollowed)

  const wagegred = userStats.find((x) => x.name === 'strict_wager')?.value || 0

  const totalWagered = formatBTC(wagegred) || ''
  const formattedHighestWin = formatBTC(higestWin || 0) || ''

  const icon = getUserIcon(profile.name, uuid, profile.hasProfileImage, {
    ...profile.vip,
  })

  const followUser = () => {
    follow({
      variables: { input: { uuid } },
      onCompleted: (res) => {
        setIsFollowed(true)
      },
      onError: (err) => {
        store.dispatch({
          type: AppTypes.SHOW_NOTIFICATION,
          notification: {
            status: 'error',
            text: formatError(err)[0],
          },
        })
      },
    })
  }

  return (
    <>
      <div id="profile-preview" className={classes.root}>
        <h2 className={classes.profileHeader}>Player Profile</h2>
        <div className="flex flex-col pt-60 gap-y-10 px-22">
          <form className="flex items-center">
            <div className={classNames(classes.icon, 'relative rounded-full')}>
              <div className="pointer-events-none">{icon}</div>
              <input
                ref={inputRef}
                type="file"
                className="hidden"
                onChange={async (e) => {
                  const file = e.target.files?.[0]
                  if (!file) {
                    store.dispatch({
                      type: AppTypes.SHOW_NOTIFICATION,
                      notification: {
                        status: 'error',
                        text: 'There was an error while uploading file!',
                      },
                    })
                    return
                  }

                  if (file.size > 1024 * 1024 * 128) {
                    store.dispatch({
                      type: AppTypes.SHOW_NOTIFICATION,
                      notification: {
                        status: 'error',
                        text: 'File must not exceed 512KB.',
                      },
                    })
                  }
                  const reader = new FileReader()
                  reader.onloadend = () => {
                    if (!reader.result) {
                      return
                    }

                    uploadFile({
                      variables: {
                        input: {
                          imageData: reader.result.toString(),
                        },
                      },
                      onCompleted(response, errors) {
                        if (errors) {
                          for (const err of errors) {
                            store.dispatch({
                              type: AppTypes.SHOW_NOTIFICATION,
                              notification: {
                                status: 'error',
                                text: err.message,
                              },
                            })
                          }
                          return
                        }
                      },
                    })
                  }

                  reader.readAsDataURL(file)
                }}
              />
              {me && me.uuid === uuid && (
                <button
                  type="button"
                  style={{
                    background: '#D9D9D9',
                  }}
                  className="absolute flex justify-center items-center text-black bottom-0 -right-4 rounded-full h-20 w-20"
                  onClick={() => {
                    inputRef.current?.click()
                  }}
                >
                  +
                </button>
              )}
            </div>
            <div className={classes.stats}>
              <div className="flex flex-col">
                <span className="font-bold">{votes}</span>
                <span>votes</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold">{followers}</span>
                <span>followers</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold">{following}</span>
                <span>following</span>
              </div>
            </div>
          </form>
          <div className={classes.header}>
            <img alt="" src="/assets/profile/badges/diamond.svg" className="w-24 h-24" />
            <div className="flex flex-col">
              <h3>{profile.name}</h3>
              <span>Elite Seat</span>
            </div>
          </div>
          <p className={classes.quote}>I am Blackjack.fun</p>
          <div className={classes.buttons}>
            <button
              type="button"
              className={classNames('px-20', {
                'bg-eucalyptus': isFollowed,
                'bg-lavenderIndigo': !isFollowed,
              })}
              onClick={followUser}
            >
              {isFollowed ? 'Following' : 'Follow'}
            </button>
            <button type="button" className="bg-lavenderIndigo px-28" onClick={() => setIsTipOpen((prev) => !prev)}>
              Tip
            </button>
            <button type="button" className="bg-lavenderIndigo px-12">
              Send message
            </button>
          </div>
        </div>
        <CareerHighlights
          totalWagered={totalWagered}
          highestWin={formattedHighestWin}
          tournamentWon={tournamentWon || 0}
        />
        {/* <FeaturedBadges />
        <ProfileFavoriteGames /> */}
        <ProfileRecentWins recentWins={recentWins} />
        <TipUser uuid={uuid} name={profile.name} isOpen={isTipOpen} />
      </div>
    </>
  )
}

export const profileQuery = graphql`
  query PlayerProfileNewProfileQuery($uuid: String!) {
    profile(uuid: $uuid) {
      userStats {
        name
        value
        meta
      }
      profileBadge
      name
      insertedAt
      tournamentWon
      higestWin
      higestBet
      vip {
        badgeId
        offset
        avatar
      }
      hasProfileImage
      followers
      votes
      isUserFollowed
      following
      recentGames
      recentWins {
        gameProvider
        multiplier
        btcBet
        btcWin
        bets
        win
        insertedAt
      }
    }
  }
`

export const followUserMutation = graphql`
  mutation PlayerProfileNewFollowUserMutation($input: FollowUserInput!) {
    followUser(input: $input) {
      result
    }
  }
`

export const uploadProfileImageMutation = graphql`
  mutation PlayerProfileNewUploadProfileImageMutation($input: UploadProfileImageInput!) {
    uploadProfileImage(input: $input) {
      user {
        uuid
        hasProfileImage
      }
    }
  }
`

import { Action } from '@reduxjs/toolkit'
import { init as initChat } from '../components/chatComponent/chat'
import { init as initMultiplayer } from '../reducers/multiplayer.reducer'
import { GameListComponentstoreUserPreferenceMutationResponse } from '../components/gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import { userChanged } from '../RelayEnvironment'
import { store } from '../store'
import { Subject } from 'rxjs'
import { requestSubscription } from 'react-relay'
import { userStatUpdatedSubscription } from '../components/profileComponent/vipProfileComponent'
import { vipProfileComponentUserStatUpdatedSubscription } from '../components/profileComponent/__generated__/vipProfileComponentUserStatUpdatedSubscription.graphql'
import Env from '../RelayEnvironment'

export enum UserConstant {
  REGISTER_REQUEST = 'REGISTER_REQUEST',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILUR = 'REGISTER_FAILUR',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  USER_UPDATED = 'USER_UPDATED',
  STAT_UPDATED = 'STAT_UPDATED',
  LOGOUT = 'LOGOUT',
}

export enum UserProp {
  ACTIVE_COIN = 'active_coin',
  SOUND_ENABLED = 'sound_enabled',
  COIN_UNIT = 'unit_',
  BACKGROUND = 'bg',
  CHAT_VISIBLE = 'chat_visible',
  CARD_THEME = 'card_theme',
  CARD_BACK = 'card_back',
  NICKNAME = 'chat_nickname',
  PRIVATE = 'private',
  FAVOURITES = 'favourites',
  TAB = 'tab',
  HOME_PAGE = 'home_page',
}

export interface UserPreference {
  key: string
  value?: string
  valueJson?: string
}

export interface UserStat {
  name: string
  value: number
  meta: any
}

export interface User {
  preferences: ReadonlyArray<UserPreference>
  uuid: string
  anonymousHash: string
  roles: string[] | null
  name: string
  otpEnabled: boolean
  confirmed: boolean
  chatApproval: boolean
  insertedAt: string
  userStats: UserStat[]
  kycState: 'idle' | 'waiting_submission' | 'waiting_confirmation'
  refProps: any
  hasProfileImage: boolean
  affiliateStats: {
    count: number
    totalCreditSumUsd: number
    creditByCurrency: string
  }
  kycSuccessful?: boolean
  pendingKycBonuses: {
    amount: string
    currency: string
    playThrough: number
  }[]
}

export interface State {
  loggedIn: boolean
  loggingIn?: boolean
  error?: boolean
  user?: User
  token?: string | null
}

export interface AuthAction extends Action {
  type: UserConstant
  user?: User
  token?: string | null
}

const serializedUser = localStorage.getItem('user')
const token = localStorage.getItem('token')

let user
if (null != serializedUser) {
  user = JSON.parse(serializedUser)
}

const initialState: State = user ? { loggedIn: false, user, token } : { loggedIn: false, token }

export const userUpdated = new Subject<void>()

export const authentication = (state: State = initialState, action: AuthAction): State => {
  switch (action.type) {
    case UserConstant.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        loggedIn: false,
      }
    case UserConstant.LOGIN_SUCCESS:
      userChanged.next()
      setTimeout(() => {
        // store.dispatch({type: WalletConstant.RESET});
        if (action.user) {
          init(action.user)
        }
        initChat()
        initMultiplayer()
      })
      return {
        loggedIn: true,
        user: action.user,
        token: action.token,
      }
    case UserConstant.LOGIN_FAILURE:
      return { loggedIn: false, error: true }
    case UserConstant.LOGOUT:
      return initialState
    case UserConstant.USER_UPDATED:
      state.user = action.user
      return state
    default:
      return state
  }
}

const init = (user: User) => {
  requestSubscription<vipProfileComponentUserStatUpdatedSubscription>(Env, {
    subscription: userStatUpdatedSubscription,
    variables: {},
    onNext: (data) => {
      if (data?.userStatUpdated.uuid === user.uuid) {
        applyUserStat(data.userStatUpdated)
      }
    },
  })
}

export const applyUpdatedUserPreference = ({
  storeUserPreference,
}: GameListComponentstoreUserPreferenceMutationResponse): User => {
  const user = store.getState().authentication.user as User
  const userPreferences = [...(user.preferences as UserPreference[])]
  const idx = userPreferences?.findIndex((x) => x.key === storeUserPreference?.preference.key)
  if (userPreferences && undefined !== idx && idx >= 0) {
    userPreferences[idx] = Object.assign({}, userPreferences[idx])
    userPreferences[idx].valueJson = storeUserPreference?.preference.valueJson as string
    userPreferences[idx].value = storeUserPreference?.preference.value as string
  } else {
    userPreferences?.push(storeUserPreference?.preference as UserPreference)
  }
  const newUser = Object.assign({}, user)
  newUser.preferences = userPreferences
  store.dispatch({ type: UserConstant.USER_UPDATED, user: newUser })
  console.log('newUser', newUser)
  setTimeout(() => {
    userUpdated.next()
  })
  return newUser
}

export const applyUserStat = (userStat: UserStat): User => {
  const user = store.getState().authentication.user as User
  const newUser = Object.assign({}, user)

  const userStats = [...user.userStats]

  if (!userStats || userStats.length === 0) {
    newUser.userStats = [userStat]
  } else {
    const idx = userStats.findIndex((x) => x.name === userStat.name)
    if (idx >= 0) {
      userStats.splice(idx, 1)
    }
    userStats.push(userStat)
  }
  newUser.userStats = userStats
  store.dispatch({ type: UserConstant.USER_UPDATED, user: newUser })
  setTimeout(() => {
    userUpdated.next()
  })
  return newUser
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../store'
import IToaster from './Toaster.interface'
import { ToastContainer, toast } from 'react-toastify'

const Toaster: React.FC<IToaster> = () => {
  const notification = useSelector((state: IStore) => state.app.notification)

  useEffect(() => {
    if (notification.status === 'success') {
      toast.success(notification.text)
      return
    }
    if (notification.status === 'error') {
      toast.error(notification.text)
      return
    }

    toast(notification.text)
  }, [notification])

  return <ToastContainer position="top-center" theme="dark" />
}

export default Toaster

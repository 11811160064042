const HeadphonesIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M11.9 14H11.2C10.643 14 10.1089 13.7671 9.71508 13.3526C9.32125 12.938 9.1 12.3757 9.1 11.7895V9.57895C9.1 8.99268 9.32125 8.43042 9.71508 8.01587C10.1089 7.60132 10.643 7.36842 11.2 7.36842H12.6C12.6 5.80504 12.01 4.30569 10.9598 3.20021C9.9096 2.09474 8.48521 1.47368 7 1.47368C5.51479 1.47368 4.09041 2.09474 3.0402 3.20021C1.99 4.30569 1.4 5.80504 1.4 7.36842H2.8C3.35695 7.36842 3.8911 7.60132 4.28492 8.01587C4.67875 8.43042 4.9 8.99268 4.9 9.57895V11.7895C4.9 12.3757 4.67875 12.938 4.28492 13.3526C3.8911 13.7671 3.35695 14 2.8 14H2.1C1.54305 14 1.0089 13.7671 0.615076 13.3526C0.221249 12.938 0 12.3757 0 11.7895L0 7.36842C0 5.41419 0.737498 3.54001 2.05025 2.15816C3.36301 0.776314 5.14348 0 7 0C8.85651 0 10.637 0.776314 11.9497 2.15816C13.2625 3.54001 14 5.41419 14 7.36842V11.7895C14 12.3757 13.7788 12.938 13.3849 13.3526C12.9911 13.7671 12.457 14 11.9 14ZM11.2 8.8421C11.0143 8.8421 10.8363 8.91974 10.705 9.05792C10.5737 9.19611 10.5 9.38352 10.5 9.57895V11.7895C10.5 11.9849 10.5737 12.1723 10.705 12.3105C10.8363 12.4487 11.0143 12.5263 11.2 12.5263H11.9C12.0857 12.5263 12.2637 12.4487 12.395 12.3105C12.5263 12.1723 12.6 11.9849 12.6 11.7895V8.8421H11.2ZM1.4 8.8421V11.7895C1.4 11.9849 1.47375 12.1723 1.60503 12.3105C1.7363 12.4487 1.91435 12.5263 2.1 12.5263H2.8C2.98565 12.5263 3.1637 12.4487 3.29497 12.3105C3.42625 12.1723 3.5 11.9849 3.5 11.7895V9.57895C3.5 9.38352 3.42625 9.19611 3.29497 9.05792C3.1637 8.91974 2.98565 8.8421 2.8 8.8421H1.4Z"
      fill="#6EF091"
    />
  </svg>
)

export default HeadphonesIcon

/**
 * @generated SignedSource<<84a94f906c54176a96304fa8f4837997>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DepositViewTokensQuery$variables = {};
export type DepositViewTokensQuery$data = {
  readonly tokens: ReadonlyArray<{
    readonly symbol: string;
    readonly crypto: string;
    readonly name: string;
    readonly logo: string;
    readonly min: string;
    readonly price: string;
    readonly coinId: string;
    readonly status: number;
    readonly tokens: ReadonlyArray<{
      readonly tokenId: string;
      readonly crypto: string;
      readonly logo: string;
      readonly name: string;
      readonly network: string;
      readonly chain: string;
      readonly contract: string;
      readonly chainLogo: string;
      readonly status: number;
    }>;
  }>;
};
export type DepositViewTokensQuery = {
  variables: DepositViewTokensQuery$variables;
  response: DepositViewTokensQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "crypto",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CCoin",
    "kind": "LinkedField",
    "name": "tokens",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "symbol",
        "storageKey": null
      },
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "min",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coinId",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CToken",
        "kind": "LinkedField",
        "name": "tokens",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tokenId",
            "storageKey": null
          },
          (v0/*: any*/),
          (v2/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "network",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contract",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chainLogo",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DepositViewTokensQuery",
    "selections": (v4/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DepositViewTokensQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "4e6da0849c5b9ce31b9e91c07b0545e1",
    "id": null,
    "metadata": {},
    "name": "DepositViewTokensQuery",
    "operationKind": "query",
    "text": "query DepositViewTokensQuery {\n  tokens {\n    symbol\n    crypto\n    name\n    logo\n    min\n    price\n    coinId\n    status\n    tokens {\n      tokenId\n      crypto\n      logo\n      name\n      network\n      chain\n      contract\n      chainLogo\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9aa881b2a2746e0bdd546ba76afcd13";

export default node;

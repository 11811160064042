import { ReplaySubject, Subject } from 'rxjs'
import ConfigurationService from '../service/ConfigurationService'
import { store } from '../store'
import { graphql } from 'babel-plugin-relay/macro'
import { requestSubscription } from 'react-relay'
import Env from '../RelayEnvironment'
import { Currency, walletWalletChangedSubscription } from './__generated__/walletWalletChangedSubscription.graphql'
import { Game } from '../components/inHouseGames/Blackjack'
import { getUserPropOr } from '../components/SettingsComponent/SettingsComponent'
import { UserProp } from './authentication.reducer'
import { walletDepositAddedSubscription } from './__generated__/walletDepositAddedSubscription.graphql'
import { walletDepositChangedSubscription } from './__generated__/walletDepositChangedSubscription.graphql'
import { walletWithdrawAddedSubscription } from './__generated__/walletWithdrawAddedSubscription.graphql'
import { walletWithdrawChangedSubscription } from './__generated__/walletWithdrawChangedSubscription.graphql'
import { roundNumberToLength } from '../utils/functions'
import { Money } from '@src/money'

export enum WalletConstant {
  BALANCE_CHANGED = 'BALANCE_CHANGED',

  SET_WALLETS = 'SET_WALLETS',
  SET_ACTIVE_WALLET = 'SET_ACTIVE_WALLET',
  SET_FIAT_RATES = 'SET_FIAT_RATES',
  SET_GAME = 'SET_GAME',
  SET_DEFER = 'SET_DEFER',
  STOP_DEFER = 'STOP_DEFER',

  RESET = 'RESET',
}

export interface Wallet {
  currency: string
  campaign?: {
    campaignId: number
    type?: string
    payoutMultiplyer: number
    meta: {
      [key: string]: string
    }
    userInCampaign?: {
      amount: number
      betSum: number
    }
  }
  balance: number
  virtual: boolean
  virtualId: number
  insertedAt?: string
}
export interface State {
  wallets: Wallet[]
  activeWallet?: Wallet
  deferred: boolean
  rates: { key: string; value: number }[]
  hasGamblerBalance?: boolean
  isGamblerBalanceActive?: boolean
}

export interface WalletAction {
  type: WalletConstant
  wallet?: Wallet
  wallets?: Wallet[]
  game?: Game
  rates?: { key: string; value: number }[]
}

export interface Deposit {
  readonly amount: number
  readonly currency: Currency
  readonly confirmations: number
  readonly confirmationsRequired: number | null
  readonly isConfirmed: boolean
  readonly insertedAt: string
  readonly txid: string | null
}

export interface Withdraw {
  readonly amount: number
  readonly currency: Currency
  readonly isProcessed: boolean
  readonly insertedAt: string
  readonly txid: ReadonlyArray<string> | null
  readonly uuid: string
  readonly meta: any
}

// TODO Why we need activeWallet
export const activeWalletUpdated = new ReplaySubject<void>()
export const walletUpdated = new Subject<Wallet>()
export const depositUpdated = new Subject<Deposit>()
export const withdrawUpdated = new Subject<Withdraw>()

const defferedActions: WalletAction[] = []
const initialState: State = {
  wallets: [],
  deferred: false,
  rates: [],
}

export const wallet = (state: State = initialState, action: WalletAction): State => {
  switch (action.type) {
    case WalletConstant.BALANCE_CHANGED:
      if (!action.wallet) {
        return state
      }
      if (state.deferred) {
        defferedActions.push(action)
        return state
      }
      const wallet = JSON.parse(JSON.stringify(action.wallet))
      const virtualId = wallet?.virtualId || 0
      wallet.virtualId = virtualId
      const idx = state.wallets.findIndex((x) => x.currency === wallet?.currency && x.virtualId === virtualId)
      const wallets = [...state.wallets]
      if (idx >= 0) {
        wallets.splice(idx, 1)
      }
      wallets.push(wallet)
      state.wallets = wallets
      if (state.activeWallet?.currency === wallet.currency && state?.activeWallet?.virtualId === virtualId) {
        state.activeWallet = wallet
        setTimeout(() => {
          activeWalletUpdated.next()
        })
      }
      setTimeout(() => {
        walletUpdated.next(wallet)
      })
      return state
    case WalletConstant.SET_WALLETS:
      if (!action.wallets) {
        return state
      }
      state.wallets = action.wallets
      const gamblerWallet = state.wallets.find((w) => !!w.campaign?.meta?.playable)
      if (gamblerWallet) {
        state.hasGamblerBalance = true
      }

      init()
      return state
    case WalletConstant.SET_GAME:
      const game = action.game
      if (!game || !game.currency || undefined === game.virtualId) {
        return state
      }
      if (state.activeWallet?.currency !== game.currency || state.activeWallet.virtualId !== game.virtualId) {
        const activeWallet = state.wallets.find((x) => x.currency === game.currency && x.virtualId === game.virtualId)
        state.activeWallet = activeWallet
      }
      return state
    case WalletConstant.SET_ACTIVE_WALLET:
      if (state.activeWallet?.currency === action.wallet?.currency && state.activeWallet?.virtualId === action.wallet?.virtualId) {
        return state
      }
      state.activeWallet = action.wallet
      setTimeout(() => {
        activeWalletUpdated.next()
      })
      return state
    case WalletConstant.SET_FIAT_RATES:
      state.rates = action.rates!
      return state
    case WalletConstant.SET_DEFER:
      state.deferred = true
      return state
    case WalletConstant.STOP_DEFER:
      state.deferred = false
      setTimeout(() => {
        defferedActions.forEach((x) => {
          store.dispatch(x)
        })
      })
      return state
    case WalletConstant.RESET:
      return initialState
    default:
      return state
  }
}

export const getWallet = (shortName: string): Wallet | undefined => {
  const allWallets = store.getState().wallet.wallets
  return allWallets.find((x) => x.currency.toLowerCase() === shortName.toLowerCase())
}

export const getBalance = (currency: string, virtualId: number) => {
  const allWallets = store.getState().wallet.wallets
  if (!virtualId) {
    virtualId = 0
  }
  const wallet = allWallets.find(
    (x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === virtualId
  )
  if (!wallet) {
    return 0
  }
  return wallet.balance
}

export const formatAmount = (amount: number | undefined, currency: string, addCurrency?: boolean, toLength = 5) => {
  if (amount == null) return
  const c = ConfigurationService.instance.getCurrency(currency)
  const format = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
  const convertedAmount = roundNumberToLength(Money.convertUnit(amount as number, c.m_unit, c.s_unit, format), toLength)
  return `${convertedAmount}${addCurrency !== false ? ` ${format}` : ''}`
}

export const formatBalance = (
  wallet: { balance: number; currency: string },
  withSuffix?: boolean,
  withPrefix?: boolean
): string => {
  if (!wallet) {
    return '0'
  }

  const defaultUnit = ConfigurationService.instance.getCurrency(wallet.currency)
  const format = getUserPropOr(('unit_' + wallet.currency.toUpperCase()) as UserProp, defaultUnit.format)
  const amount = roundNumberToLength(Money.convertUnit(wallet.balance, defaultUnit.m_unit, defaultUnit.s_unit, format), 5)

  if (withSuffix) {
    return amount.toString() + ' ' + format
  }
  if (withPrefix) {
    return format + ' ' + amount.toString()
  }
  return amount.toString()
}

const init = () => {
  requestSubscription<walletWalletChangedSubscription>(Env, {
    subscription: walletChangedSubscription,
    variables: {},
    onNext: (data) => {
      if (data?.walletChanged) {
        store.dispatch({
          type: WalletConstant.BALANCE_CHANGED,
          wallet: data.walletChanged,
        })
      }
    },
  })
  requestSubscription<walletDepositAddedSubscription>(Env, {
    subscription: depositAddedSubscription,
    variables: {},
    onNext: (data) => {
      if (data?.depositAdded) {
        depositUpdated.next(data.depositAdded)
      }
    },
  })

  requestSubscription<walletDepositChangedSubscription>(Env, {
    subscription: depositChangedSubscription,
    variables: {},
    onNext: (data) => {
      if (data?.depositChanged) {
        depositUpdated.next(data.depositChanged)
      }
    },
  })

  requestSubscription<walletWithdrawAddedSubscription>(Env, {
    subscription: withdrawAddedSubscription,
    variables: {},
    onNext: (data) => {
      if (data?.withdrawAdded) {
        withdrawUpdated.next(data.withdrawAdded)
      }
    },
  })

  requestSubscription<walletWithdrawChangedSubscription>(Env, {
    subscription: withdrawChangedSubscription,
    variables: {},
    onNext: (data) => {
      if (data?.withdrawChanged) {
        withdrawUpdated.next(data.withdrawChanged)
      }
    },
  })
}

const walletChangedSubscription = graphql`
  subscription walletWalletChangedSubscription {
    walletChanged {
      balance
      currency
      virtual
      virtualId
      insertedAt
    }
  }
`

const depositAddedSubscription = graphql`
  subscription walletDepositAddedSubscription {
    depositAdded {
      amount
      currency
      confirmations
      isConfirmed
      confirmationsRequired
      insertedAt
      txid
    }
  }
`

const depositChangedSubscription = graphql`
  subscription walletDepositChangedSubscription {
    depositChanged {
      amount
      currency
      confirmations
      isConfirmed
      confirmationsRequired
      insertedAt
      txid
    }
  }
`

const withdrawAddedSubscription = graphql`
  subscription walletWithdrawAddedSubscription {
    withdrawAdded {
      amount
      currency
      isProcessed
      insertedAt
      txid
      uuid
      meta
    }
  }
`

const withdrawChangedSubscription = graphql`
  subscription walletWithdrawChangedSubscription {
    withdrawChanged {
      amount
      currency
      isProcessed
      insertedAt
      txid
      uuid
      meta
    }
  }
`

const LightningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="27" viewBox="0 0 20 27" fill="none">
    <path
      d="M6.88384 26.254C6.78919 26.6707 7.32619 26.9256 7.58918 26.5887L19.4637 11.3807C19.6742 11.1111 19.4712 10.7188 19.1295 10.735L10.3435 11.1502C10.0276 11.1651 9.82011 10.8248 9.97809 10.5508L15.6942 0.63769C15.8476 0.37164 15.6564 0.039114 15.3493 0.0378837L6.17311 0.00112482C6.00461 0.000449799 5.85375 0.10545 5.79586 0.263701L0.207455 15.5402C0.109047 15.8092 0.317014 16.0915 0.603101 16.0772L8.76715 15.6686C9.03175 15.6554 9.23589 15.8983 9.17721 16.1567L6.88384 26.254Z"
      fill="white"
    />
  </svg>
)

export default LightningIcon

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Warning = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none" {...props}>
    <path
      d="M3.9325 1.1499L3.88375 3.79686H3.3644L3.31565 1.1499H3.9325ZM3.62502 4.8999C3.52127 4.8999 3.43252 4.86483 3.35878 4.79468C3.28503 4.72332 3.24878 4.63745 3.25003 4.53706C3.24878 4.43788 3.28503 4.35322 3.35878 4.28307C3.43252 4.21171 3.52127 4.17603 3.62502 4.17603C3.72626 4.17603 3.81376 4.21171 3.8875 4.28307C3.96125 4.35322 3.99875 4.43788 4 4.53706C3.99875 4.60358 3.98063 4.66466 3.94563 4.7203C3.91188 4.77472 3.86688 4.81826 3.81063 4.85092C3.75439 4.88358 3.69251 4.8999 3.62502 4.8999Z"
      fill="currentColor"
    />
    <path
      d="M6.25 3.0249C6.25 4.47465 5.07475 5.6499 3.625 5.6499C2.17525 5.6499 1 4.47465 1 3.0249C1 1.57515 2.17525 0.399902 3.625 0.399902C5.07475 0.399902 6.25 1.57515 6.25 3.0249Z"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Warning

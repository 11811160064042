import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '@src/store'
import { changeTab, profileTabs } from '@src/reducers/profile.reducer'
import classes from './ProfileTabs.module.scss'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function ProfileTabs() {
  const dispatch = useDispatch()
  const { tab: activeTab } = useSelector((s: IStore) => s.profile)
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    history.push({ search: `?tab=${activeTab}` })
  }, [history, activeTab])

  return (
    <div className={classes.root}>
      <ul>
        {profileTabs.map((tab, i) => (
          <li
            key={i}
            className={classNames({
              [classes.active]: tab.name === activeTab,
            })}
          >
            {tab.disabled && tab.name !== 'profile' ? (
              <Tooltip place="bottom" text={t('general.comingSoon', 'Coming soon')} type="dark">
                <span className="cursor-not-allowed">
                  {t(`profile.profileTabs.${tab.name}`, tab.name.slice(0, 1).toUpperCase() + tab.name.slice(1))}
                </span>
              </Tooltip>
            ) : (
              <button
                type="button"
                onClick={() => dispatch(changeTab({ tab: tab.name }))}
                disabled={tab.name === 'profile'}
                className={classNames({
                  'cursor-default': tab.name === 'profile',
                })}
              >
                {t(`profile.profileTabs.${tab.name}`, tab.name.slice(0, 1).toUpperCase() + tab.name.slice(1))}
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay'
import { useTranslation } from 'react-i18next'
import './FreeSpinsView.scoped.scss'
import { FreeSpinsViewFreespinsQuery } from './__generated__/FreeSpinsViewFreespinsQuery.graphql'
import Timer from '../Timer'

export const FreeSpinsView = () => {
  const { t } = useTranslation()
  const data = useLazyLoadQuery<FreeSpinsViewFreespinsQuery>(freespinsQuery, {}, { fetchPolicy: 'store-or-network' })

  if (!data?.listFreespins) return null

  console.log(data)

  const CFREESPIN = data.listFreespins.filter((x) => x.reason === 'CFREESPIN')
  const CFREESPIN_AMOUNT = CFREESPIN.reduce((sum, x) => {
    return sum + x.amount
  }, 0)
  const CFREESPIN_USED = CFREESPIN.reduce((sum, x) => {
    return sum + x.used
  }, 0)
  const VOTE = data.listFreespins.filter((x) => x.reason === 'VOTE')
  const VOTE_AMOUNT = VOTE.reduce((sum, x) => {
    return sum + x.amount
  }, 0)
  const VOTE_USED = VOTE.reduce((sum, x) => {
    return sum + x.used
  }, 0)

  return (
    <div className="body">
      <div className="freeSpins">
        <div className="value">{CFREESPIN_AMOUNT - CFREESPIN_USED}</div>
        <div className="info">{t('wallet.freeSpinsView.freeSpinsAvailable', 'Free Spins Available')}</div>
      </div>
      <div className="rows">
        <div className="row">
          <div className="value">
            {CFREESPIN_AMOUNT}/{CFREESPIN_USED}
          </div>
          <div className="desc">
            <div className="title">{t('wallet.freeSpinsView.chatFreeSpins', 'Chat Free spins')}</div>
            <div className="info">
              {t('wallet.freeSpinsView.individualFromHourlyChat', 'Individual from hourly chat')}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="value yellow">{CFREESPIN_AMOUNT - CFREESPIN_USED}</div>
          <div className="desc">
            <div className="title">{t('wallet.freeSpinsView.remaining', 'Free Spins Remaining')}</div>
            <div className="info">{t('wallet.freeSpinsView.individualFromVotes', 'Individual from votes')}</div>
          </div>
        </div>
        <div className="row">
          <div className="value pink">
            {VOTE_AMOUNT}/{VOTE_USED}
          </div>
          <div className="desc">
            <div className="title">{t('wallet.freeSpinsView.voteForWinner', 'Vote for the winner')}</div>
            <div className="info">
              {t('wallet.freeSpinsView.hourlyDailyWeeklyVotes', 'Hourly, Daily and Weekly votes')}
            </div>
          </div>
        </div>
      </div>
      <div className="timer">
        <div className="title">{t('wallet.freeSpinsView.timeUntilNextFreeSpins', 'Time until next free spins')}</div>
        <Timer
          endTime={Math.floor((new Date().getTime() + (3600000 - (new Date().getTime() % 3600000))) / 1000)}
          styleV={3}
        />
      </div>
    </div>
  )
}

const freespinsQuery = graphql`
  query FreeSpinsViewFreespinsQuery {
    listFreespins {
      amount
      reason
      used
    }
  }
`

const HomeIcon = ({ width = '24', height = '24' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M10.1014 15H5.89502V10.4985C5.89502 10.0824 6.23547 9.74196 6.65157 9.74196H9.3449C9.761 9.74196 10.1014 10.0824 10.1014 10.4985V15V15Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9962 7.54039V12.6169C14.9962 13.9333 13.9824 15 12.7266 15H3.26966C2.01378 15 1 13.9333 1 12.6169V7.54039C1 6.82924 1.29506 6.16347 1.81708 5.70954L6.54553 1.55607C7.3853 0.814645 8.61092 0.814645 9.45069 1.55607L14.1791 5.70954C14.7012 6.16347 14.9962 6.82924 14.9962 7.54039Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HomeIcon

/**
 * @generated SignedSource<<7072bf7404f337f1329645ea01778b40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SettingsComponentwebauthnRegisteredKeysQuery$variables = {};
export type SettingsComponentwebauthnRegisteredKeysQuery$data = {
  readonly webauthnRegisteredKeys: ReadonlyArray<{
    readonly publicKey: string;
    readonly insertedAt: any;
    readonly aaguid: string | null;
  }>;
};
export type SettingsComponentwebauthnRegisteredKeysQuery = {
  variables: SettingsComponentwebauthnRegisteredKeysQuery$variables;
  response: SettingsComponentwebauthnRegisteredKeysQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WebauthnRegisteredKey",
    "kind": "LinkedField",
    "name": "webauthnRegisteredKeys",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publicKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "aaguid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsComponentwebauthnRegisteredKeysQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsComponentwebauthnRegisteredKeysQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6f9fc9369cbb5da699849176b4b5897a",
    "id": null,
    "metadata": {},
    "name": "SettingsComponentwebauthnRegisteredKeysQuery",
    "operationKind": "query",
    "text": "query SettingsComponentwebauthnRegisteredKeysQuery {\n  webauthnRegisteredKeys {\n    publicKey\n    insertedAt\n    aaguid\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2fdfe44e74a45ddf1ae109404658518";

export default node;

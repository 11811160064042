const BecomeInvestorIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 16" fill="none">
    <path d="M3.54132 6.41934H3.09863" stroke="currentColor" strokeWidth="0.6" strokeLinejoin="round" />
    <path d="M2.65607 6.41934H2.21338" stroke="currentColor" strokeWidth="0.6" strokeLinejoin="round" />
    <path
      d="M0 2.80646H3.98419C4.10593 2.80646 4.20553 2.90807 4.20553 3.03226V7.09678C4.20553 7.22097 4.10593 7.32259 3.98419 7.32259H0.885375"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M7.08301 2.80646H9.96048C10.204 2.80646 10.4032 3.00968 10.4032 3.25807V3.48388C10.4032 3.73226 10.204 3.93549 9.96048 3.93549"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.51758 3.93549H10.1816C10.4251 3.93549 10.6243 4.13871 10.6243 4.3871V4.61291C10.6243 4.86129 10.4251 5.06452 10.1816 5.06452H9.51758"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.96042 5.06451C10.2039 5.06451 10.4031 5.26774 10.4031 5.51613V5.74193C10.4031 5.99032 10.2039 6.19355 9.96042 6.19355H9.29639"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M4.20557 6.87096H5.31229L7.08304 7.32257H9.73916C9.98264 7.32257 10.1819 7.11935 10.1819 6.87096V6.64516C10.1819 6.39677 9.98264 6.19354 9.73916 6.19354"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M7.30438 1.67743L6.19766 1.90324L4.8696 3.25807H4.20557"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.07495 2.80645H7.74689L7.3042 1H9.51764L9.07495 2.80645Z"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.73916 7.30002C10.4032 8.45163 12.1739 9.80647 12.1739 11.6129C12.1739 13.8574 10.3899 15 8.18976 15C5.9896 15 4.20557 13.8574 4.20557 11.6129C4.20557 9.80647 5.97632 8.45163 6.64035 7.30002"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M7.52588 11.8387C7.52588 12.2136 7.82248 12.5161 8.18991 12.5161C8.55734 12.5161 8.85394 12.2136 8.85394 11.8387C8.85394 11.4639 8.18991 11.1613 8.18991 11.1613C8.18991 11.1613 7.52588 10.8587 7.52588 10.4839C7.52588 10.109 7.82248 9.80646 8.18991 9.80646C8.55734 9.80646 8.85394 10.109 8.85394 10.4839"
      stroke="currentColor"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path d="M8.18994 9.80645V9.12903" stroke="currentColor" strokeWidth="0.6" strokeMiterlimit="10" />
    <path d="M8.18994 12.5161V13.1936" stroke="currentColor" strokeWidth="0.6" strokeMiterlimit="10" />
    <path d="M9.51758 11.1613H9.96027" stroke="currentColor" strokeWidth="0.6" strokeMiterlimit="10" />
    <path d="M6.41895 11.1613H6.86163" stroke="currentColor" strokeWidth="0.6" strokeMiterlimit="10" />
  </svg>
)

export default BecomeInvestorIcon

import classNames from 'classnames'
import classes from './Input.module.scss'

import { Field, FieldAttributes } from 'formik'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  FieldAttributes<{}> & {
    wrong?: boolean
    children?: React.ReactNode | React.ReactNodeArray
  }

export function Input(props: InputProps) {
  return (
    <Field
      {...props}
      className={classNames(classes.input, props.className, {
        [classes.wrong]: props.wrong,
      })}
    >
      {props.children}
    </Field>
  )
}

/**
 * @generated SignedSource<<802e31b38d9654379bdaf1636900ddb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NewGameInput = {
  currency: string;
  virtualId: number;
  force?: boolean | null;
};
export type InHouseGameNewGameMutation$variables = {
  input: NewGameInput;
};
export type InHouseGameNewGameMutation$data = {
  readonly newGame: {
    readonly game: {
      readonly state: string | null;
      readonly gameId: string | null;
      readonly sp: number | null;
      readonly mp: boolean | null;
      readonly splitCount: number | null;
      readonly insured: number | null;
      readonly serverSeedHash: string | null;
      readonly currency: string | null;
      readonly virtualId: number | null;
      readonly validMoves: {
        readonly canSplit: boolean | null;
        readonly canDoubleDown: boolean | null;
        readonly canInsurance: boolean | null;
        readonly canHit: boolean | null;
      } | null;
      readonly summary: {
        readonly serverSeed: string | null;
        readonly serverSeedHash: string | null;
        readonly clientSeed: string | null;
        readonly finalShuffle: string | null;
        readonly finalShuffleHash: string | null;
        readonly winnings: number | null;
        readonly details: ReadonlyArray<{
          readonly userIdx: number | null;
          readonly slotId: number;
          readonly winning: number;
        } | null> | null;
      } | null;
      readonly slots: ReadonlyArray<{
        readonly slotId: number;
        readonly parentId: number | null;
        readonly bet: number | null;
        readonly result: string | null;
        readonly userUuid: string | null;
        readonly userName: string | null;
        readonly userIdx: number | null;
        readonly cards: ReadonlyArray<{
          readonly rank: string | null;
          readonly suit: string | null;
          readonly flipped: number | null;
        } | null> | null;
      } | null> | null;
    };
  } | null;
};
export type InHouseGameNewGameMutation = {
  variables: InHouseGameNewGameMutation$variables;
  response: InHouseGameNewGameMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serverSeedHash",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userIdx",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotId",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "NewGamePayload",
    "kind": "LinkedField",
    "name": "newGame",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Game",
        "kind": "LinkedField",
        "name": "game",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "splitCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insured",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "virtualId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidMoves",
            "kind": "LinkedField",
            "name": "validMoves",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canSplit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canDoubleDown",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canInsurance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canHit",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GameSummary",
            "kind": "LinkedField",
            "name": "summary",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serverSeed",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientSeed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finalShuffle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finalShuffleHash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "winnings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WinningDetail",
                "kind": "LinkedField",
                "name": "details",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "winning",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GameSlot",
            "kind": "LinkedField",
            "name": "slots",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "result",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userUuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userName",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GameCard",
                "kind": "LinkedField",
                "name": "cards",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rank",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flipped",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InHouseGameNewGameMutation",
    "selections": (v4/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InHouseGameNewGameMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d8d38993aa62e58ec2b1c02d0dc52f53",
    "id": null,
    "metadata": {},
    "name": "InHouseGameNewGameMutation",
    "operationKind": "mutation",
    "text": "mutation InHouseGameNewGameMutation(\n  $input: NewGameInput!\n) {\n  newGame(input: $input) {\n    game {\n      state\n      gameId\n      sp\n      mp\n      splitCount\n      insured\n      serverSeedHash\n      currency\n      virtualId\n      validMoves {\n        canSplit\n        canDoubleDown\n        canInsurance\n        canHit\n      }\n      summary {\n        serverSeed\n        serverSeedHash\n        clientSeed\n        finalShuffle\n        finalShuffleHash\n        winnings\n        details {\n          userIdx\n          slotId\n          winning\n        }\n      }\n      slots {\n        slotId\n        parentId\n        bet\n        result\n        userUuid\n        userName\n        userIdx\n        cards {\n          rank\n          suit\n          flipped\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc7d6ea56caafd97dbd14a5ce20faa05";

export default node;

const CardsIcon = ({ color = '#D5DCEB', ...props }) => (
  <svg {...props} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.52276 0H8.39776C9.03942 0 9.56442 0.525 9.56442 1.16667V4.9L7.52276 0ZM10.6728 0.933333L11.4311 1.28333C12.0144 1.51667 12.3061 2.21667 12.0728 2.8L10.6728 6.24167V0.933333ZM9.44775 7.75833L6.53109 0.758333C6.35609 0.291667 5.94776 0.0583333 5.48109 0.0583333C5.30609 0.0583333 5.18942 0.116667 5.01442 0.175L0.697756 1.925C0.114422 2.15833 -0.177244 2.8 0.114422 3.38333L3.03109 10.3833C3.20609 10.85 3.61442 11.0833 4.08109 11.0833C4.25609 11.0833 4.37276 11.0833 4.54776 10.9667L8.86442 9.21667C9.33109 9.04167 9.56442 8.63333 9.56442 8.16667C9.50609 8.05 9.50609 7.875 9.44775 7.75833ZM5.59776 7.46667L3.73109 6.06667L3.96442 3.73333L5.83109 5.13333L5.59776 7.46667Z"
      fill="currentColor"
    />
  </svg>
)

export default CardsIcon

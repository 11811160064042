const ErrorRow = ({ hasError, reason }) => {
  if (hasError) {
    return (
      <div className="tr" style={{ height: '56px', marginBottom: 0 }}>
        <div>{reason}</div>
      </div>
    )
  }
  return null
}

export default ErrorRow

import { useHistory } from 'react-router-dom'
import MenuItem from './MenuItem'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'
import GreenMenuItem from './GreenMenuItem'
import { ModalType, openModalTask } from '../../modalComponent/modalComponent'
import { User } from '@src/reducers/authentication.reducer'
import styles from '../index.module.scss'
import { BonusExpireStamp } from '@src/components/BosusExpireStamp'
import classNames from 'classnames'
import { RakeBackStamp } from '@src/components/RakeBackStamp'
import { useTranslation } from 'react-i18next'

import SwordsIcon from '@src/components/icons/swords'
import ESportsIcon from '@src/components/icons/eSports'
import SportsIcon from '@src/components/icons/sports'
import ChipIcon from '@src/components/icons/chip'
import CrownIcon from '@src/components/icons/crown'
import BlogIcon from '@src/components/icons/blog'
import TeamIcon from '@src/components/icons/team'
import HeadphonesIcon from '@src/components/icons/headphones'
import JackToken from '@src/assets/menu/jackToken.svg'
import BuyJackIcon from '@src/components/icons/buyJack'
import BecomeInvestorIcon from '@src/components/icons/becomeInvestor'
import HomeIcon from '@src/components/icons/home'
import SubMenuItem from './SubMenuItem'
import DealerIcon from '@src/components/icons/dealer'
import CherryIcon from '@src/components/icons/cherry'
import Heart21Icon from '@src/components/icons/heart21'

interface Props {
  me?: User
}

const DesktopMenu = ({ me }: Props) => {
  const { t } = useTranslation()
  const isNotAnonymousUser = null === me?.anonymousHash
  const history = useHistory()
  const collapsedMenu = useSelector((state: IStore) => state.app.collapsedMenu)

  const supportClick = () => {
    const btn = document.querySelector('.myChatButtonText') as HTMLElement
    if (btn) btn.click()
  }

  return (
    <>
      <div className={`${styles.scrollableContent} ${styles.slideUp} h-full flex flex-col gap-5`}>
        <MenuItem
          isCollapsed={collapsedMenu}
          label={t('menu.home', 'Home')}
          onAction={() => history.push('/')}
          icon={<HomeIcon />}
          linkPath="/"
        ></MenuItem>
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<SwordsIcon />}
          label={t('menu.competitions', 'Competitions')}
          onAction={() => history.push('/competitions')}
          linkPath="/competitions"
        />
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<ChipIcon />}
          label={t('menu.casino', 'Casino')}
          onAction={() => history.push('/games')}
          linkPath="/games"
        >
          <SubMenuItem Icon={DealerIcon} label={t('menu.liveGames', 'Live Games')} linkPath="/live-games" />
          <SubMenuItem Icon={CherryIcon} label={t('menu.slots', 'Slots')} linkPath="/slots" />
          <SubMenuItem Icon={Heart21Icon} label={t('menu.blackjack', 'Blackjack')} linkPath="/game/bitcoin-blackjack" />
        </MenuItem>
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<DealerIcon className={'text-white'} />}
          label={t('menu.liveGames', 'Live Games')}
          onAction={() => history.push('/live-games')}
          linkPath="/live-games"
          collapsedOnly
        />
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<CherryIcon className={'text-white'} />}
          label={t('menu.slots', 'Slots')}
          onAction={() => history.push('/slots')}
          linkPath="/slots"
          collapsedOnly
        />
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<SportsIcon />}
          label={t('menu.sports', 'Sports')}
          onAction={() => history.push('/game/BetsyGames:btsg_sportbetting')}
          linkPath="/game/BetsyGames:btsg_sportbetting"
        />
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<ESportsIcon />}
          label={t('menu.eSports', 'eSports')}
          onAction={() => history.push('/game/Oddin:odn_oddingame')}
          linkPath="/game/Oddin:odn_oddingame"
        />
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<CrownIcon />}
          label={t('menu.affiliate', 'Affiliate')}
          onAction={() => window.open('https://partners.blackjack.fun', '_blank')}
        />
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<BlogIcon />}
          label={t('menu.blog', 'Blog')}
          onAction={() => window.open('https://blog.blackjack.fun/', '_blank')}
        />
        <div className={collapsedMenu ? 'flex flex-col gap-10' : ''}>
          {isNotAnonymousUser && (
            <GreenMenuItem
              isCollapsed={collapsedMenu}
              icon={<TeamIcon />}
              label={t('menu.inviteYourFriends', 'Invite your friends')}
              onAction={() => openModalTask.next({ type: ModalType.inviteYourFriends, username: me?.name })}
            />
          )}
          <GreenMenuItem
            isCollapsed={collapsedMenu}
            icon={<BecomeInvestorIcon />}
            label={t('menu.becomeAnInvestor', 'Become an investor')}
            onAction={() => openModalTask.next({ type: ModalType.beacomeAnInvestor })}
          />
          <GreenMenuItem
            isCollapsed={collapsedMenu}
            icon={<JackToken />}
            label={t('menu.jackToken', 'JACK Token')}
            onAction={() => history.push('/jack-token')}
            isNew
          />
          <GreenMenuItem
            isCollapsed={collapsedMenu}
            icon={<BuyJackIcon />}
            label={t('menu.buyJack', 'Buy Jack')}
            onAction={() => openModalTask.next({ type: ModalType.buyJack })}
            isNew
          />
        </div>
      </div>
      <div className={`${styles.slideUp} mx-5 flex flex-col gap-10`}>
        <BonusExpireStamp />
        <RakeBackStamp
          rakebackClassNames={classNames(styles.rakeback, 'text-sm leading-5 font-poppins font-bold')}
          rakebackPercentageClassNames={styles.rakeback_percentage}
        />
      </div>
      <div className={styles.slideUp}>
        <Helmet>
          <script type="text/javascript">{`(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.defer=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document, 'https://blackjackfun.ladesk.com/scripts/track.js', function(e){ LiveAgent.createButton('q18nu9lz', e); });`}</script>
        </Helmet>
        <MenuItem
          isCollapsed={collapsedMenu}
          icon={<HeadphonesIcon />}
          label={t('menu.liveSupport', 'Live support')}
          onAction={supportClick}
        />
      </div>
    </>
  )
}

export default DesktopMenu

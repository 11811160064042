import classes from './Competition.module.scss'
import { useTranslation } from 'react-i18next'

export function Competition({
  competition,
}: {
  competition: {
    type: string
    name: string
    players: {
      idx: number
      name: string
    }[]
  }
}) {
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      {competition.type === 'highest_win' ? (
        <img
          src="/assets/TrophyIcons_44_t.png"
          style={{
            height: '82px',
            width: '82px',
          }}
          alt="Trophy"
        />
      ) : (
        <img
          src="/assets/TrophyIcons_24_t.png"
          style={{
            height: '82px',
            width: '82px',
          }}
          alt="Trophy"
        />
      )}
      <span className="text-center py-16 px-38 font-inter font-medium text-white">
        {t(`competitions.${competition.name}`, competition.name)}
        <br />
        <span className="text-pink font-bold py-8">
          {t('chat.competition.hereAreTheWinners', 'Here are the winners!')}
        </span>
      </span>
      <div
        className="bg-eerieBlack flex flex-col"
        style={{
          borderRadius: '7px',
        }}
      >
        <div className="flex flex-col gap-y-8 px-60 py-28">
          {competition.players
            ?.sort((a, b) => a.idx - b.idx)
            ?.map((player, i) => (
              <span key={i} className="text-yellow">
                {`${i + 1}. ${player.name}`}
              </span>
            ))}
        </div>

        <span
          style={{
            color: '#898ec9',
            maxWidth: '140px',
          }}
          className="py-16 font-medium max-w-140 text-center mx-auto"
        >
          {t('chat.fundsInWallet', 'The funds are in your balance wallet')}
        </span>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'babel-plugin-relay/macro'
import QRCode from 'react-qr-code'
import { useMutation } from 'react-relay'
import { getMessageContainer, LoginHeader } from '../Login/Login'
import formatError from '../../errorFormatter'
import { GraphQLError } from '../../../RelayEnvironment'
import { IStore, store } from '../../../store'
import { AppTypes } from '../../../reducers/app.reducer'
import IToggle2FA from './Toggle2FA.interface'
import styles from '../Login/Login.module.scss'
import { UserConstant } from '../../../reducers/authentication.reducer'
import { Toggle2FAGenerateOtpSecretMutation } from './__generated__/Toggle2FAGenerateOtpSecretMutation.graphql'
import { Toggle2FAEnableOtpMutation } from './__generated__/Toggle2FAEnableOtpMutation.graphql'
import { Toggle2FADisableOtpMutation } from './__generated__/Toggle2FADisableOtpMutation.graphql'
import { useSelector } from 'react-redux'
import Enabled2FA from '../Enabled2FA/Enabled2FA'
import AccountCreated from '../AccountCreated/AccountCreated'

enum View {
  enable,
  disable,
}

const Toggle2FA: React.FC<IToggle2FA> = () => {
  const { t } = useTranslation()
  const user = useSelector((state: IStore) => state.authentication.user)
  const [view] = useState(user?.otpEnabled ? View.disable : View.enable)
  const [code, setCode] = useState('')
  const [message, setMessage] = useState('')
  const [otpURL, setOTPURL] = useState<string>('')

  const setModalView = (view, onClose: (() => void) | null = null) =>
    store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view, onClose: onClose ?? (() => {}) } })

  const [generate2FASecret] = useMutation<Toggle2FAGenerateOtpSecretMutation>(generate2FAMutation)

  const [commitEnable2FA] = useMutation<Toggle2FAEnableOtpMutation>(enable2FAMutation)

  const [commitDisable2FA] = useMutation<Toggle2FADisableOtpMutation>(disable2FAMutation)

  const enableOTP = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    event?.stopPropagation()
    if (!code) {
      return
    }
    setMessage('')
    commitEnable2FA({
      variables: { input: { code } },
      onCompleted: (result) => {
        if (result.enableOtp?.user) {
          store.dispatch({ type: UserConstant.USER_UPDATED, user: result.enableOtp.user })
          setMessage('')
          setModalView(<Enabled2FA />, () => {
            const show_welcome_popup = localStorage.getItem('show_welcome_popup')
            if (show_welcome_popup) {
              localStorage.removeItem('show_welcome_popup')
              setModalView(<AccountCreated />)
            }
          })
        }
      },
      onError: (e: GraphQLError) => {
        setMessage(formatError(e)[0])
      },
    })
  }

  const disableOTP = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    event?.stopPropagation()
    if (!code) {
      return
    }
    setMessage('')
    commitDisable2FA({
      variables: { input: { code } },
      onCompleted: (result) => {
        if (result.disableOtp?.user) {
          store.dispatch({ type: UserConstant.USER_UPDATED, user: result.disableOtp.user })
          setMessage(t('authentication.toggle2FA.disabled', '2FA is disabled now'))
        }
      },
      onError: (e: GraphQLError) => {
        setMessage(formatError(e)[0])
      },
    })
  }

  useEffect(() => {
    const generateOTPSecret = () => {
      setMessage('')
      generate2FASecret({
        variables: {},
        onCompleted: (data) => {
          if (data.generateOtpSecret?.url) {
            setOTPURL(data.generateOtpSecret.url)
          }
        },
        onError: (e: GraphQLError) => {},
      })
    }

    generateOTPSecret()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.root}>
      <LoginHeader
        activeTab={
          user?.otpEnabled
            ? t('authentication.toggle2FA.disable', 'Disable 2FA')
            : t('authentication.toggle2FA.enable', 'Enable 2FA')
        }
      />
      <div className={styles.body}>
        {view === View.enable && (
          <>
            <QRCode size={180} value={otpURL} className="mx-auto my-50" />
            <div className={`${styles.infoLarge} mb-30`}>{otpURL}</div>
          </>
        )}

        {view === View.disable && (
          <form className="form-group" onSubmit={(e) => disableOTP(e)}>
            <label htmlFor="confirm-form-control">
              {t('authentication.toggle2FA.enterPassworkd', 'Enter current one time password!')}
            </label>
            <input
              id="confirm-form-control"
              name="confirm"
              required={true}
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              autoFocus={true}
            />
            {getMessageContainer(message)}
            <div className="">
              <button type="submit" className="">
                {t('authentication.toggle2FA.disable', 'Disable 2FA')}
              </button>
            </div>
          </form>
        )}
      </div>
      <div className={styles.footer}>
        {view === View.enable && (
          <form className="" onSubmit={(e) => enableOTP(e)}>
            <label htmlFor="confirm-form-control">
              {t('authentication.toggle2FA.confirm', 'Confirm one time password!')}
            </label>
            <input
              id="confirm-form-control"
              name="confirm"
              required={true}
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              autoFocus={true}
            />
            {getMessageContainer(message)}
            <div className="">
              <button type="submit" className="">
                {t('authentication.toggle2FA.enable', 'Enable 2FA')}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Toggle2FA

const generate2FAMutation = graphql`
  mutation Toggle2FAGenerateOtpSecretMutation {
    generateOtpSecret {
      url
    }
  }
`

const enable2FAMutation = graphql`
  mutation Toggle2FAEnableOtpMutation($input: EnableOtpInput!) {
    enableOtp(input: $input) {
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        chatApproval
        tipDisabled
        insertedAt
        kycState
        refProps
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

const disable2FAMutation = graphql`
  mutation Toggle2FADisableOtpMutation($input: DisableOtpInput!) {
    disableOtp(input: $input) {
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        chatApproval
        tipDisabled
        insertedAt
        kycState
        refProps
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

/**
 * @generated SignedSource<<7c59b2bd9a65e63c12e17cf787ee6b1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ForgotPasswordInput = {
  username?: string | null;
};
export type ForgotPasswordForgotPasswordMutation$variables = {
  input: ForgotPasswordInput;
};
export type ForgotPasswordForgotPasswordMutation$data = {
  readonly forgotPassword: {
    readonly res: string | null;
  } | null;
};
export type ForgotPasswordForgotPasswordMutation = {
  variables: ForgotPasswordForgotPasswordMutation$variables;
  response: ForgotPasswordForgotPasswordMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ForgotPasswordPayload",
    "kind": "LinkedField",
    "name": "forgotPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "res",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotPasswordForgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotPasswordForgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4b34a768db32cbc465848090a75ae36b",
    "id": null,
    "metadata": {},
    "name": "ForgotPasswordForgotPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotPasswordForgotPasswordMutation(\n  $input: ForgotPasswordInput!\n) {\n  forgotPassword(input: $input) {\n    res\n  }\n}\n"
  }
};
})();

(node as any).hash = "a50d76804ec36ed95cac94785f59b68e";

export default node;

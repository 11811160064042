import SHA256 from "crypto-js/sha256";
import { GameType } from './ProvablyFairValidator'

declare function real__em_init_gen_rand(seed: number): number
declare function real__em_init_by_array(byteOffset: number, len: number): number
declare function real__malloc(a: any): any
declare var HEAPU8: any
declare function getValue(ptr: number, type: string): number



export default class Validator {
  private arrPtr: number = 0
  private _i: number = 0

  constructor(
    private game: GameType,
    private version: number,
    private serverSeed: string,
    private serverSeedHash: string,
    private seeds: string[],
    private finalShuffle: string,
    private finalShuffleHash: string
  ) {}

  check() {
    this._i = 0
    return {
      seed: this.validateServerSeed(),
      deckHash: this.validateDeckHash(),
      shuffle: this.validateFinalShuffle(),
    }
  }

  validateDeckHash(): boolean {
    const hash = SHA256(this.finalShuffle).toString().toUpperCase();
    return hash === this.finalShuffleHash.toUpperCase();
  }

  validateServerSeed(): boolean {
    const hash = SHA256(this.serverSeed).toString().toUpperCase();
    return hash === this.serverSeedHash.toUpperCase();
  }

  validateFinalShuffle(): boolean {
    if (this.game === GameType.blackjack) {
      switch (+this.version) {
        case 0x01: {
          return this.validateFinalShuffle_v1()
        }
        case 0x02: {
          return this.validateFinalShuffle_v2()
        }
        case 0x03: {
          return this.validateFinalShuffle_v3()
        }
        case 0x04: {
          return this.validateFinalShuffle_v4()
        }
        case 0x05: {
          return this.validateFinalShuffle_v5()
        }
        case 0x06: {
          return this.validateFinalShuffle_v6()
        }
        default: {
          return this.validateFinalShuffle_v1()
          // console.error('Unknown game version ' + this.version);
        }
      }
    }
    if (this.game === GameType.redOrBlack) {
      switch (+this.version) {
        case 0x01: {
          return this.validateFinalShuffle_v2()
        }
        default: {
          return this.validateFinalShuffle_v1()
          // console.error('Unknown game version ' + this.version);
        }
      }
    }
    console.error('unknown game type' + this.game)
    return false
  }

  validateFinalShuffle_v1(): boolean {
    let combinedSeed = this.serverSeed + this.seeds.join('');
    let hash = SHA256(combinedSeed).toString().toUpperCase();

    let seed = parseInt(hash.substring(0, 8), 16);
    this.arrPtr = real__em_init_gen_rand(seed);

    let deck = this.init_deck(4);
    deck = this._shuffle(deck, deck.length, []);

    hash = SHA256(deck.join(',')).toString().toUpperCase();

    return hash === this.finalShuffleHash.toUpperCase();
  }

  validateFinalShuffle_v2(): boolean {
    const combinedSeed = this.serverSeed + this.seeds.join('');
    let hashHexStr = SHA256(combinedSeed).toString().toUpperCase();

    const seeds: number[] = [];
    for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
      seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16));
    }

    const data = new Uint32Array(seeds);
    const nDataBytes = data.length * data.BYTES_PER_ELEMENT;
    const dataPtr = real__malloc(nDataBytes);
    const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes);
    dataHeap.set(new Uint8Array(data.buffer));
    this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length);

    let deck = this.init_deck(4);
    deck = this._shuffle(deck, deck.length, []);

    hashHexStr = SHA256(deck.join(',')).toString().toUpperCase();

    return hashHexStr === this.finalShuffleHash.toUpperCase();
  }


  validateFinalShuffle_v3(): boolean {
    const combinedSeed = this.serverSeed + this.seeds.join('');
    let hashHexStr = SHA256(combinedSeed).toString().toUpperCase();

    const seeds: number[] = [];
    for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
      seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16));
    }

    const data = new Uint32Array(seeds);
    const nDataBytes = data.length * data.BYTES_PER_ELEMENT;
    const dataPtr = real__malloc(nDataBytes);
    const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes);
    dataHeap.set(new Uint8Array(data.buffer));
    this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length);

    let deck = this.init_deck(4);
    deck = this._shuffle(deck, deck.length, []);
    this._i = 0;
    deck = this._shuffle(deck, deck.length, []);

    hashHexStr = SHA256(deck.join(',')).toString().toUpperCase();

    return hashHexStr === this.finalShuffleHash.toUpperCase();
  }

  validateFinalShuffle_v4(): boolean {
    const combinedSeed = this.serverSeed + this.seeds.join('');
    let hashHexStr = SHA256(combinedSeed).toString().toUpperCase();

    const seeds: number[] = [];
    for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
      seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16));
    }

    const data = new Uint32Array(seeds);
    const nDataBytes = data.length * data.BYTES_PER_ELEMENT;
    const dataPtr = real__malloc(nDataBytes);
    const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes);
    dataHeap.set(new Uint8Array(data.buffer));
    this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length);

    let deck = this.init_deck(4);
    for (let shuffleCount = 0; shuffleCount < 4; shuffleCount++) {
      deck = this._shuffle(deck, deck.length, []);
      this._i = 0;
    }

    hashHexStr = SHA256(deck.join(',')).toString().toUpperCase();

    return hashHexStr === this.finalShuffleHash.toUpperCase();
  }

  validateFinalShuffle_v5(): boolean {
    const combinedSeed = this.serverSeed + this.seeds.join('');
    let hashHexStr = SHA256(combinedSeed).toString().toUpperCase();

    const seeds: number[] = [];
    for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
      seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16));
    }

    const data = new Uint32Array(seeds);
    const nDataBytes = data.length * data.BYTES_PER_ELEMENT;
    const dataPtr = real__malloc(nDataBytes);
    const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes);
    dataHeap.set(new Uint8Array(data.buffer));
    this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length);

    let deck = this.init_deck(8);
    for (let shuffleCount = 0; shuffleCount < 4; shuffleCount++) {
      deck = this._shuffle(deck, deck.length, []);
      this._i = 0;
    }

    hashHexStr = SHA256(deck.join(',')).toString().toUpperCase();

    return hashHexStr === this.finalShuffleHash.toUpperCase();
  }

  validateFinalShuffle_v6(): boolean {
    const combinedSeed = this.serverSeed + this.seeds.join('');
    let hashHexStr = SHA256(combinedSeed).toString().toUpperCase();

    const seeds: number[] = [];
    for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
      seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16));
    }

    const data = new Uint32Array(seeds);
    const nDataBytes = data.length * data.BYTES_PER_ELEMENT;
    const dataPtr = real__malloc(nDataBytes);
    const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes);
    dataHeap.set(new Uint8Array(data.buffer));
    this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length);

    let deck = this.init_deck(8);
    deck = this._shuffle(deck, deck.length, []);

    hashHexStr = SHA256(deck.join(',')).toString().toUpperCase();

    return hashHexStr === this.finalShuffleHash.toUpperCase();
  }


  private _shuffle(list: string[], n: number, result: string[]): string[] {
    if (n === 1) {
      result.unshift(list[0])
      return result
    }

    if (result.length === 0) {
      let rnd = this.next_rand(this._i++)
      rnd = this.map_to_new_range(rnd, 0, n)
      result.push(list[rnd - 1])
      list[rnd - 1] = list[list.length - 1]

      return this._shuffle(list, n - 1, result)
    }

    let nrnd = this.next_rand(this._i++)

    nrnd = this.map_to_new_range(nrnd, 0, n)
    let vr = list[nrnd - 1]
    let ve = list[n - 1]

    result.unshift(vr)
    list[nrnd - 1] = ve
    return this._shuffle(list, n - 1, result)
  }

  private next_rand(i: number): number {
    return getValue(this.arrPtr + i * 4, 'i32') >>> 0
  }

  private map_to_new_range(x: number, xstart: number, xend: number): number {
    let outputRange = xend - xstart
    let inputRange = 0xffffffff
    return Math.ceil(((x - xstart) * outputRange) / inputRange)
  }

  private init_deck(numberOfDecks: number) {
    let arr: string[] = []
    for (let i = 1; i <= numberOfDecks; i++) {
      for (let j = 4; j >= 1; j--) {
        for (let k = 14; k >= 2; k--) {
          arr.push(this.numberToRank(k) + this.numberToSuit(j))
        }
      }
    }
    return arr
  }

  private numberToSuit(nmbr: number) {
    switch (nmbr) {
      case 1:
        return 'S'
      case 2:
        return 'H'
      case 3:
        return 'D'
      case 4:
        return 'C'
      default:
        console.error('Invalid suit number')
        return ''
    }
  }

  private numberToRank(nmbr: number) {
    switch (nmbr) {
      case 11:
        return 'J'
      case 12:
        return 'Q'
      case 13:
        return 'K'
      case 14:
        return 'A'
      default:
        return nmbr
    }
  }
}

// Solution using js-sha256 dependecy that broke code.

// import { sha256 } from 'js-sha256'
// import { GameType } from './ProvablyFairValidator'

// declare function real__em_init_gen_rand(seed: number): number
// declare function real__em_init_by_array(byteOffset: number, len: number): number
// declare function real__malloc(a: any): any
// declare var HEAPU8: any
// declare function getValue(ptr: number, type: string): number

// export const sha256_hash = (value: string) => {
//   if (!value) {
//     return
//   }
//   const hash = sha256.create()
//   hash.update(value)
//   return hash.hex().toUpperCase()
// }

// export default class Validator {
//   private arrPtr: number = 0
//   private _i: number = 0

//   constructor(
//     private game: GameType,
//     private version: number,
//     private serverSeed: string,
//     private serverSeedHash: string,
//     private seeds: string[],
//     private finalShuffle: string,
//     private finalShuffleHash: string
//   ) {}

//   check() {
//     this._i = 0
//     return {
//       seed: this.validateServerSeed(),
//       deckHash: this.validateDeckHash(),
//       shuffle: this.validateFinalShuffle(),
//     }
//   }

//   validateDeckHash(): boolean {
//     const hash = sha256.create()
//     hash.update(this.finalShuffle)
//     return hash.hex().toUpperCase() === this.finalShuffleHash.toUpperCase()
//   }

//   validateServerSeed(): boolean {
//     const hash = sha256.create()
//     hash.update(this.serverSeed)
//     return hash.hex().toUpperCase() === this.serverSeedHash.toUpperCase()
//   }

//   validateFinalShuffle(): boolean {
//     if (this.game === GameType.blackjack) {
//       switch (+this.version) {
//         case 0x01: {
//           return this.validateFinalShuffle_v1()
//         }
//         case 0x02: {
//           return this.validateFinalShuffle_v2()
//         }
//         case 0x03: {
//           return this.validateFinalShuffle_v3()
//         }
//         case 0x04: {
//           return this.validateFinalShuffle_v4()
//         }
//         case 0x05: {
//           return this.validateFinalShuffle_v5()
//         }
//         case 0x06: {
//           return this.validateFinalShuffle_v6()
//         }
//         default: {
//           return this.validateFinalShuffle_v1()
//           // console.error('Unknown game version ' + this.version);
//         }
//       }
//     }
//     if (this.game === GameType.redOrBlack) {
//       switch (+this.version) {
//         case 0x01: {
//           return this.validateFinalShuffle_v2()
//         }
//         default: {
//           return this.validateFinalShuffle_v1()
//           // console.error('Unknown game version ' + this.version);
//         }
//       }
//     }
//     console.error('unknown game type' + this.game)
//     return false
//   }

//   validateFinalShuffle_v1(): boolean {
//     let hash = sha256.create()
//     hash.update(this.serverSeed)
//     for (let i = 0; i < this.seeds.length; i++) {
//       hash.update(this.seeds[i])
//     }

//     // First 32 bits of hash is used as seed
//     let seed = parseInt(hash.hex().substring(0, 8), 16)
//     this.arrPtr = real__em_init_gen_rand(seed)
//     let deck = this.init_deck(4)
//     deck = this._shuffle(deck, deck.length, [])
//     hash = sha256.create()
//     hash.update(deck.join(','))

//     return hash.hex().toUpperCase() === this.finalShuffleHash.toUpperCase()
//   }

//   validateFinalShuffle_v2(): boolean {
//     let hash = sha256.create()
//     hash.update(this.serverSeed)
//     for (let i = 0; i < this.seeds.length; i++) {
//       hash.update(this.seeds[i])
//     }

//     const hashHexStr = hash.hex()
//     const seeds: number[] = []

//     for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
//       seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16))
//     }

//     const data = new Uint32Array(seeds)
//     // Get data byte size, allocate memory on Emscripten heap, and get pointer
//     const nDataBytes = data.length * data.BYTES_PER_ELEMENT
//     const dataPtr = real__malloc(nDataBytes)

//     // Copy data to Emscripten heap (directly accessed from HEAPU8)
//     const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes)
//     dataHeap.set(new Uint8Array(data.buffer))

//     this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length)

//     let deck = this.init_deck(4)
//     deck = this._shuffle(deck, deck.length, [])
//     hash = sha256.create()
//     hash.update(deck.join(','))

//     return hash.hex().toUpperCase() === this.finalShuffleHash.toUpperCase()
//   }

//   validateFinalShuffle_v3(): boolean {
//     let hash = sha256.create()
//     hash.update(this.serverSeed)
//     for (let i = 0; i < this.seeds.length; i++) {
//       hash.update(this.seeds[i])
//     }

//     const hashHexStr = hash.hex()
//     const seeds: number[] = []

//     for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
//       seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16))
//     }

//     const data = new Uint32Array(seeds)
//     // Get data byte size, allocate memory on Emscripten heap, and get pointer
//     const nDataBytes = data.length * data.BYTES_PER_ELEMENT
//     const dataPtr = real__malloc(nDataBytes)

//     // Copy data to Emscripten heap (directly accessed from HEAPU8)
//     const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes)
//     dataHeap.set(new Uint8Array(data.buffer))

//     this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length)

//     let deck = this.init_deck(4)
//     deck = this._shuffle(deck, deck.length, [])
//     this._i = 0
//     deck = this._shuffle(deck, deck.length, [])
//     hash = sha256.create()
//     hash.update(deck.join(','))
//     return hash.hex().toUpperCase() === this.finalShuffleHash.toUpperCase()
//   }

//   validateFinalShuffle_v4(): boolean {
//     let hash = sha256.create()
//     hash.update(this.serverSeed)
//     for (let i = 0; i < this.seeds.length; i++) {
//       hash.update(this.seeds[i])
//     }

//     const hashHexStr = hash.hex()
//     const seeds: number[] = []

//     for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
//       seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16))
//     }

//     const data = new Uint32Array(seeds)
//     // Get data byte size, allocate memory on Emscripten heap, and get pointer
//     const nDataBytes = data.length * data.BYTES_PER_ELEMENT
//     const dataPtr = real__malloc(nDataBytes)

//     // Copy data to Emscripten heap (directly accessed from HEAPU8)
//     const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes)
//     dataHeap.set(new Uint8Array(data.buffer))

//     this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length)

//     let deck = this.init_deck(4)
//     deck = this._shuffle(deck, deck.length, [])
//     this._i = 0
//     deck = this._shuffle(deck, deck.length, [])
//     this._i = 0
//     deck = this._shuffle(deck, deck.length, [])
//     this._i = 0
//     deck = this._shuffle(deck, deck.length, [])
//     hash = sha256.create()
//     hash.update(deck.join(','))
//     return hash.hex().toUpperCase() === this.finalShuffleHash.toUpperCase()
//   }

//   validateFinalShuffle_v5(): boolean {
//     let hash = sha256.create()
//     hash.update(this.serverSeed)
//     for (let i = 0; i < this.seeds.length; i++) {
//       hash.update(this.seeds[i])
//     }

//     const hashHexStr = hash.hex()
//     const seeds: number[] = []

//     for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
//       seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16))
//     }

//     const data = new Uint32Array(seeds)
//     // Get data byte size, allocate memory on Emscripten heap, and get pointer
//     const nDataBytes = data.length * data.BYTES_PER_ELEMENT
//     const dataPtr = real__malloc(nDataBytes)

//     // Copy data to Emscripten heap (directly accessed from HEAPU8)
//     const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes)
//     dataHeap.set(new Uint8Array(data.buffer))

//     this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length)

//     let deck = this.init_deck(8)
//     deck = this._shuffle(deck, deck.length, [])
//     this._i = 0
//     deck = this._shuffle(deck, deck.length, [])
//     this._i = 0
//     deck = this._shuffle(deck, deck.length, [])
//     this._i = 0
//     deck = this._shuffle(deck, deck.length, [])
//     hash = sha256.create()
//     hash.update(deck.join(','))
//     return hash.hex().toUpperCase() === this.finalShuffleHash.toUpperCase()
//   }

//   validateFinalShuffle_v6(): boolean {
//     let hash = sha256.create()
//     hash.update(this.serverSeed)
//     for (let i = 0; i < this.seeds.length; i++) {
//       hash.update(this.seeds[i])
//     }

//     const hashHexStr = hash.hex()
//     const seeds: number[] = []

//     for (let i = 0; i <= hashHexStr.length - 8; i += 8) {
//       seeds.push(parseInt(hashHexStr.substring(i, i + 8), 16))
//     }

//     const data = new Uint32Array(seeds)
//     // Get data byte size, allocate memory on Emscripten heap, and get pointer
//     const nDataBytes = data.length * data.BYTES_PER_ELEMENT
//     const dataPtr = real__malloc(nDataBytes)

//     // Copy data to Emscripten heap (directly accessed from HEAPU8)
//     const dataHeap = new Uint8Array(HEAPU8.buffer, dataPtr, nDataBytes)
//     dataHeap.set(new Uint8Array(data.buffer))

//     this.arrPtr = real__em_init_by_array(dataHeap.byteOffset, data.length)

//     let deck = this.init_deck(8)
//     deck = this._shuffle(deck, deck.length, [])
//     hash = sha256.create()
//     hash.update(deck.join(','))
//     return hash.hex().toUpperCase() === this.finalShuffleHash.toUpperCase()
//   }


//   private _shuffle(list: string[], n: number, result: string[]): string[] {
//     if (n === 1) {
//       result.unshift(list[0])
//       return result
//     }

//     if (result.length === 0) {
//       let rnd = this.next_rand(this._i++)
//       rnd = this.map_to_new_range(rnd, 0, n)
//       result.push(list[rnd - 1])
//       list[rnd - 1] = list[list.length - 1]

//       return this._shuffle(list, n - 1, result)
//     }

//     let nrnd = this.next_rand(this._i++)

//     nrnd = this.map_to_new_range(nrnd, 0, n)
//     let vr = list[nrnd - 1]
//     let ve = list[n - 1]

//     result.unshift(vr)
//     list[nrnd - 1] = ve
//     return this._shuffle(list, n - 1, result)
//   }

//   private next_rand(i: number): number {
//     return getValue(this.arrPtr + i * 4, 'i32') >>> 0
//   }

//   private map_to_new_range(x: number, xstart: number, xend: number): number {
//     let outputRange = xend - xstart
//     let inputRange = 0xffffffff
//     return Math.ceil(((x - xstart) * outputRange) / inputRange)
//   }

//   private init_deck(numberOfDecks: number) {
//     let arr: string[] = []
//     for (let i = 1; i <= numberOfDecks; i++) {
//       for (let j = 4; j >= 1; j--) {
//         for (let k = 14; k >= 2; k--) {
//           arr.push(this.numberToRank(k) + this.numberToSuit(j))
//         }
//       }
//     }
//     return arr
//   }

//   private numberToSuit(nmbr: number) {
//     switch (nmbr) {
//       case 1:
//         return 'S'
//       case 2:
//         return 'H'
//       case 3:
//         return 'D'
//       case 4:
//         return 'C'
//       default:
//         console.error('Invalid suit number')
//         return ''
//     }
//   }

//   private numberToRank(nmbr: number) {
//     switch (nmbr) {
//       case 11:
//         return 'J'
//       case 12:
//         return 'Q'
//       case 13:
//         return 'K'
//       case 14:
//         return 'A'
//       default:
//         return nmbr
//     }
//   }
// }

/**
 * @generated SignedSource<<f063e1908c6759d7ffdab235b3602003>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProvablyFairValidatorGetGameByUuidQuery$variables = {
  gameId: string;
};
export type ProvablyFairValidatorGetGameByUuidQuery$data = {
  readonly getGameByUuid: {
    readonly gameId: string;
    readonly gameType: string;
    readonly serverSeed: string;
    readonly serverSeedHash: string;
    readonly finalShuffle: string;
    readonly finalShuffleHash: string;
    readonly insertedAt: string;
    readonly version: string;
    readonly clientSeeds: ReadonlyArray<string>;
  };
};
export type ProvablyFairValidatorGetGameByUuidQuery = {
  variables: ProvablyFairValidatorGetGameByUuidQuery$variables;
  response: ProvablyFairValidatorGetGameByUuidQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gameId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "gameId",
        "variableName": "gameId"
      }
    ],
    "concreteType": "ValidatableGame",
    "kind": "LinkedField",
    "name": "getGameByUuid",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gameId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gameType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serverSeed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serverSeedHash",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "finalShuffle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "finalShuffleHash",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSeeds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvablyFairValidatorGetGameByUuidQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProvablyFairValidatorGetGameByUuidQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b2f23aedb1bf1550e64385adbcb8b5b7",
    "id": null,
    "metadata": {},
    "name": "ProvablyFairValidatorGetGameByUuidQuery",
    "operationKind": "query",
    "text": "query ProvablyFairValidatorGetGameByUuidQuery(\n  $gameId: String!\n) {\n  getGameByUuid(gameId: $gameId) {\n    gameId\n    gameType\n    serverSeed\n    serverSeedHash\n    finalShuffle\n    finalShuffleHash\n    insertedAt\n    version\n    clientSeeds\n  }\n}\n"
  }
};
})();

(node as any).hash = "94bbc6cf11ac1daf6f237a83fef6b65b";

export default node;

import { Action } from '@reduxjs/toolkit'
import React from 'react'

export enum AppTypes {
  OPEN_MENU = 'OPEN_MENU',
  CLOSE_MENU = 'CLOSE_MENU',
  COLLAPSE_MENU = 'COLLAPSE_MENU',
  EXPAND_MENU = 'EXPAND_MENU',
  TOGGLE_MENU = 'TOGGLE_MENU',
  SET_DARK_THEME = 'SET_DARK_THEME',
  SET_LIGHT_THEME = 'SET_LIGHT_THEME',
  OPEN_CHAT = 'OPEN_CHAT',
  CLOSE_CHAT = 'CLOSE_CHAT',
  TOGGLE_CHAT = 'TOGGLE_CHAT',
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  SET_MODAL_VIEW = 'SET_MODAL_VIEW',
  TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS',
}

export interface AppAction extends Action {
  type: AppTypes
  notification?: {
    status?: 'success' | 'error'
    text: string | null
  }
  modal?: {
    view: React.FC | null
    onClose?: () => void
  }
  isOpenedNotification?: boolean
}

export interface State {
  menuOpened: boolean
  collapsedMenu: boolean
  themeMode: 'dark' | 'light'
  chatOpened: boolean | null
  isNotificationsOpen: boolean
  notification: {
    status?: 'success' | 'error'
    text: string | null
  }
  modal: {
    view: React.FC | null
    onClose?: () => void
  }
}

const initialState: State = {
  menuOpened: false,
  collapsedMenu: false,
  themeMode: 'dark',
  chatOpened: null,
  isNotificationsOpen: false,
  notification: {
    text: null,
  },
  modal: {
    view: null,
  },
}

export const app = (state: State = initialState, action: AppAction): State => {
  switch (action.type) {
    case AppTypes.OPEN_MENU:
      return {
        ...state,
        menuOpened: true,
      }
    case AppTypes.CLOSE_MENU:
      return {
        ...state,
        menuOpened: false,
      }
    case AppTypes.COLLAPSE_MENU:
      return {
        ...state,
        collapsedMenu: true,
      }
    case AppTypes.EXPAND_MENU:
      return {
        ...state,
        collapsedMenu: false,
      }
    case AppTypes.TOGGLE_MENU:
      return {
        ...state,
        collapsedMenu: !state.collapsedMenu,
      }
    case AppTypes.SET_DARK_THEME:
      return {
        ...state,
        themeMode: 'dark',
      }
    case AppTypes.SET_LIGHT_THEME:
      return {
        ...state,
        themeMode: 'light',
      }
    case AppTypes.OPEN_CHAT:
      return {
        ...state,
        chatOpened: true,
      }
    case AppTypes.CLOSE_CHAT:
      return {
        ...state,
        chatOpened: false,
      }
    case AppTypes.TOGGLE_CHAT:
      return {
        ...state,
        chatOpened: !state.chatOpened,
      }
    case AppTypes.SHOW_NOTIFICATION:
      if (!action?.notification) return state
      return {
        ...state,
        notification: action?.notification,
      }
    case AppTypes.SET_MODAL_VIEW:
      if (!action?.modal) return state
      return {
        ...state,
        modal: action?.modal,
      }
    case AppTypes.TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        isNotificationsOpen: action?.isOpenedNotification ?? !state.isNotificationsOpen,
      }
    default:
      return state
  }
}

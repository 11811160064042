import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation, Trans } from 'react-i18next'

import classes from './WalletGamblerTab.module.scss'
import { Wallet, WalletConstant } from '@src/reducers/wallet.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { IStore, store } from '@src/store'
import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation, useMutation } from 'react-relay'
import { WalletGamblerTabClaimPlayableGlobalPlaythroughPriceMutation } from './__generated__/WalletGamblerTabClaimPlayableGlobalPlaythroughPriceMutation.graphql'
import { GameListComponentstoreUserPreferenceMutation } from '../gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import { updateUserPreference } from '../gameListComponent/GameListComponent'
import { UserProp } from '@src/reducers/authentication.reducer'

import Env from '@src/RelayEnvironment'
import { Link } from 'react-router-dom'
import { Money } from '@src/money'

export function WalletGamblerTab({ closeWalletModal }: { closeWalletModal?: () => void }) {
  const me = useSelector((s: IStore) => s.authentication.user)
  const wallet = useSelector((store: IStore) => store.wallet)
  const gamblerWallet = wallet.wallets.find((w) => !!w.campaign?.meta?.playable)

  const pendingKycBonuses = me?.pendingKycBonuses ?? []
  const hasPendingKycBonuses = pendingKycBonuses.length > 0

  const [withdrawn, setWithdrawn] = useState(false)

  if (withdrawn) return <WithdrawnGamblerTab />

  if (hasPendingKycBonuses) {
    const amount = Money.convertUnit(pendingKycBonuses[0].amount, pendingKycBonuses[0].currency, 'USDT', 'raw')
    const currency = pendingKycBonuses[0].currency

    const wagered = 0
    const toBeWagered = amount * pendingKycBonuses[0].playThrough
    const progress = (wagered / toBeWagered) * 100

    const amountLeft = `${Money.fromInteger(toBeWagered - wagered, currency).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })} ${currency}`

    const multiplier = pendingKycBonuses[0].playThrough

    return (
      <ActiveGamblerTab
        closeWalletModal={closeWalletModal}
        amount={Money.fromInteger(amount, currency)}
        currency={currency}
        progress={progress}
        amountLeft={amountLeft}
        multiplier={multiplier}
        showKYC
      />
    )
  }

  if (!gamblerWallet) return null

  const isLost = gamblerWallet.balance < 10000

  const multiplier = gamblerWallet.campaign?.payoutMultiplyer || 1

  const amount = gamblerWallet.campaign?.userInCampaign?.amount || 0
  const currency = gamblerWallet.currency

  const wagered = gamblerWallet.campaign?.userInCampaign?.betSum ?? 0
  const toBeWagered = multiplier * amount

  const progress = (wagered / toBeWagered) * 100
  const amountLeft = `${Money.fromInteger(toBeWagered - wagered, gamblerWallet.currency).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })} ${gamblerWallet.currency}`
  const wageredAmount = `${Money.fromInteger(wagered, gamblerWallet.currency).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })} ${gamblerWallet.currency}`

  if (isLost)
    return <LostGamblerTab amount={Money.fromInteger(amount, currency)} currency={currency} wageredAmount={wageredAmount} />

  const win = progress >= 100

  if (win)
    return (
      <WinGamblerTab
        amount={Money.fromInteger(amount, currency)}
        currency={currency}
        multiplier={multiplier}
        wallet={gamblerWallet}
        setWithdrawn={setWithdrawn}
      />
    )

  return (
    <ActiveGamblerTab
      amount={Money.fromInteger(amount, currency)}
      currency={currency}
      progress={progress}
      amountLeft={amountLeft}
      multiplier={multiplier}
    />
  )
}

const ActiveGamblerTab = ({
  amount,
  currency,
  progress,
  amountLeft,
  multiplier,
  showKYC = false,
  closeWalletModal,
}: {
  amount: number
  currency: string
  progress: number
  amountLeft: string
  multiplier: number
  showKYC?: boolean
  closeWalletModal?: () => void
}) => {
  const { t } = useTranslation()
  const totalAmount = (multiplier * amount).toLocaleString('en-US', { maximumFractionDigits: 2 })

  return (
    <div className={classNames(classes.exclusiveTab, 'justify-evenly')}>
      <div className="mt-auto">
        <h4 className={classes.redHeadline}>{t('wallet.gamblerTab.areYouGambler', 'Are you a gambler?')}</h4>
        <h3 className={classes.greenHeadline}>
          {t('wallet.gamblerTab.win', 'win')} {amount} {currency}
        </h3>
      </div>
      <div className="flex flex-col items-center">
        <span className={classes.how}>{t('wallet.gamblerTab.howDoesItWork', 'How does it work?')}</span>
        <p className={classes.explanation}>
          <Trans
            i18nKey="wallet.gamblerTab.explanation"
            values={{ amount, currency, multiplier, totalAmount }}
            components={{
              1: <b />,
              2: <span />,
            }}
          />
          <br />
          <br />
          <span className={classes.endline}>{t('wallet.gamblerTab.playHaveFunRepeat', 'PLAY.HAVE FUN.REPEAT')}</span>
        </p>
      </div>
      <div className="flex flex-col w-full px-50 gap-y-10 h-full mt-auto pb-40">
        <span className={classes.progressText}>
          {amountLeft} {t('wallet.gamblerTab.wagerLeft', 'wager left')}
        </span>
        <ProgressBar progress={progress} />
      </div>
      {showKYC && (
        <div>
          <p className={classes.explanation}>
            {t(
              'wallet.gamblerTab.KYCReminder',
              'To avoid alt accounts, only KYC verified accounts can enter this campaign.'
            )}
          </p>
          <div className="flex justify-center pb-30">
            <Link to="/profile?tab=settings&stab=KYC" onClick={closeWalletModal}>
              {t('wallet.gamblerTab.openKYC', 'OPEN KYC')}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

const LostGamblerTab = ({
  wageredAmount,
  amount,
  currency,
}: {
  amount: number
  currency: string
  wageredAmount: string
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const setGamblerBalanceInactive = () => {
    const wallets = store.getState().wallet.wallets.filter((w) => w.virtualId === 0)
    if (wallets[0]) {
      dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: wallets[0] })
      commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
        mutation: updateUserPreference,
        variables: {
          input: {
            key: UserProp.ACTIVE_COIN,
            value: wallets[0].currency,
          },
        },
      })
    } else {
      store.dispatch({
        type: WalletConstant.SET_ACTIVE_WALLET,
        wallet: {
          balance: 0,
          currency: 'BTC',
          virtual: false,
          virtualId: 0,
        },
      })
    }
  }

  return (
    <div className={classNames(classes.exclusiveTab, 'justify-center gap-y-8')}>
      <div className="">
        <h4 className={classes.redHeadline}>{t('wallet.gamblerTab.areYouGambler', 'Are you a gambler?')}</h4>
        <h3 className={classes.greenHeadline}>
          {t('wallet.gamblerTab.win', 'win')} {amount} {currency}
        </h3>
      </div>
      <div className="flex flex-col items-center">
        <p className={classes.message}>
          <Trans
            i18nKey="wallet.gamblerTab.bonusSpent"
            values={{ wageredAmount, currency, amount }}
            components={{
              1: <b />,
              2: <b />,
            }}
          >
            Oh, it looks like you spent your bonus and didn’t hit the target. But it looks like you did pretty good. You
            wagered{' '}
            <b>
              {{ wageredAmount }} {{ currency }}
            </b>{' '}
            with just{' '}
            <b>
              {{ amount }} {{ currency }}
            </b>
            .
          </Trans>
        </p>
      </div>
      <button type="button" className={classes.button} onClick={setGamblerBalanceInactive}>
        {t('wallet.gamblerTab.depositAndStartWinning', 'Deposit and start winning')}
      </button>
    </div>
  )
}

const WinGamblerTab = ({
  amount,
  currency,
  multiplier,
  wallet,
  setWithdrawn,
}: {
  amount: number
  currency: string
  multiplier: number
  wallet: Wallet
  setWithdrawn: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const [withdraw] = useMutation<WalletGamblerTabClaimPlayableGlobalPlaythroughPriceMutation>(
    claimPlayableGlobalPlaythroughPriceMutation
  )
  const wageredAmount = (multiplier * amount).toLocaleString('en-US', { maximumFractionDigits: 2 })

  return (
    <div className={classNames(classes.exclusiveTab, 'justify-center gap-y-8')}>
      <div className="">
        <h4 className={classes.redHeadline}>{t('wallet.gamblerTab.areYouGambler', 'Are you a gambler?')}</h4>
        <h3 className={classes.redHeadlineBig}>{t('wallet.gamblerTab.yesYouAre', 'Yes, you are!')}</h3>
      </div>
      <div className="flex flex-col items-center">
        <p className={classes.message}>
          <Trans
            i18nKey="wallet.gamblerTab.congratulations"
            values={{ wageredAmount, currency }}
            components={{
              1: <strong />,
            }}
          >
            Congratulations!!! You’ve wagered{' '}
            <strong>
              {{ wageredAmount }} {{ currency }}
            </strong>
            <br />
            <br />
            Collect your reward.
          </Trans>
        </p>
      </div>
      <button
        type="button"
        className={classes.button}
        onClick={() => {
          withdraw({
            variables: {
              input: {
                amount: wallet.campaign?.userInCampaign?.amount || 0,
                virtualId: wallet.virtualId,
              },
            },
            onCompleted(response, errors) {
              if (errors) {
                for (const err of errors) {
                  console.error(err.message)
                }
                return
              }

              if (response.claimPlayableGlobalPlaythroughPrice?.success) {
                setWithdrawn(true)
              }
            },
          })
        }}
      >
        {t('wallet.withdrawView.withdraw', 'Withdraw')} {amount}
      </button>
    </div>
  )
}

const WithdrawnGamblerTab = () => {
  const { t } = useTranslation()
  return (
    <div className={classNames(classes.exclusiveTab, 'justify-center gap-y-8')}>
      <div className="flex flex-col items-center">
        <p className={classes.message}>
          {t('wallet.gamblerTab.bonusInTheWallet', 'Your bonus is now in the main wallet.')}
        </p>
      </div>
    </div>
  )
}

const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className={classes.progressBar}>
      <div className={classes.progress} style={{ width: `${progress}%` }} />
    </div>
  )
}

const claimPlayableGlobalPlaythroughPriceMutation = graphql`
  mutation WalletGamblerTabClaimPlayableGlobalPlaythroughPriceMutation(
    $input: ClaimPlayableGlobalPlaythroughPriceInput!
  ) {
    claimPlayableGlobalPlaythroughPrice(input: $input) {
      success
    }
  }
`

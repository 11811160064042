import Konva from 'konva'

export interface ButtonConfig {
  text: string
  fontSize: number
  fontStyle?: string
  height?: number
  width?: number
  fill?: string
  align?: string
  textColor?: string
  offsetX?: number
  stroke?: string
  skew?: number
  lineHeight?: number
  cornerRadius?: number
}

export const getSkewedRect = (config: ButtonConfig) => {
  const height = config.height ? config.height : 37
  const width = config.width ? config.width : 100
  const fill = config.fill ? config.fill : '#FFF200'
  const textColor = config.textColor ? config.textColor : '#000'
  const stroke = config.stroke ? config.stroke : ''
  const skew = undefined !== config.skew ? config.skew : -20
  const lineHeight = undefined !== config.lineHeight ? config.lineHeight : 1
  const cornerRadius = undefined !== config.cornerRadius ? config.cornerRadius : 0

  const group = new Konva.Group()
  group.width(width)
  group.height(height)
  const rectangle = new Konva.Rect({
    x: 0,
    y: 0,
    width: width,
    height: height,
    fill: fill,
    stroke: stroke,
    id: 'rect',
    cornerRadius,
  })
  if (skew !== 0) {
    group.skewX(Math.tan(skew * (Math.PI / 180)))
  }
  const text = new Konva.Text({
    x: rectangle.x(),
    y: rectangle.y() + height / 2,
    width: width,
    text: config.text,
    lineHeight: lineHeight,
    fontFamily: 'Fjalla One',
    fontSize: config.fontSize,
    fill: textColor,
    id: 'rect_text',
  })
  if (config.fontStyle) {
    text.fontStyle(config.fontStyle)
  }
  if (config.align && config.align === 'left') {
    text.align('left')
    text.offsetX(-5)
  } else if (config.align && config.align === 'right') {
    text.align('right')
  } else {
    text.align('center')
  }
  if (config.offsetX) {
    text.offsetX(config.offsetX)
  }

  if (skew !== 0) {
    text.skewX(-Math.tan(skew * (Math.PI / 180)))
  }
  text.offsetY(text.height() / 2 - 1)

  group.add(rectangle)
  group.add(text)
  return group
}

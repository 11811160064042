import React from 'react'
import { useTranslation } from 'react-i18next'

import './CodeMaster.scoped.scss'
import classNames from 'classnames'

interface Data {
  code: string
}

export function CodeMaster({ data, className }: { data: Data; className?: string }) {
  const { t } = useTranslation()

  return (
    <div className={classNames('root', className)}>
      <img className="codemaster-img" alt="Robot" src="/assets/robo1_1.svg" />
      <span className="codemaster-name">{t('chat.codeMaster.master', 'CodeMaster')}</span>
      <span className="codemaster-label">{t('chat.codeMaster.newGiveawayCodeIs', 'The new giveaway code is')}</span>
      <span className="code">{data.code}</span>
    </div>
  )
}

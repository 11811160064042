import { useEffect, useState } from 'react'
import ConfigurationService, { CurrencyEnum } from '../../service/ConfigurationService'
import './ProvablyFairComponent.scss'
import { ReplaySubject, Subject } from 'rxjs'
import { generateRandomString } from '../SignInComponent'
import { useParams } from 'react-router-dom'
import { Money } from '@src/money'

enum SubPage {
  support,
  faq,
  tos,
  tosNetEnt,
}

export const seedUsed = new Subject<void>()
export const nextServerSeedHash = new ReplaySubject<string>()

export const ProvablyFairComponent = () => {
  const pageParams = useParams<{ subpage?: string }>()
  const [subpage, setSubpage] = useState(pageParams?.subpage === 'toc' ? SubPage.tos : SubPage.faq)
  const [hash, setHash] = useState<string>()

  // const tocIframeRef = useRef<HTMLIFrameElement>(null)
  // const [tocIframeHeight, setTocIframeHeight] = useState<string>('0px')

  // const onLoad = () => {
  // setTocIframeHeight((tocIframeRef.current?.contentWindow?.document.body.scrollHeight || '0') + 'px')
  // }

  const TOC = () => {
    return (
      <iframe
        // ref={tocIframeRef}
        // onLoad={onLoad}
        // height={tocIframeHeight}
        src="https://bjfun-tos.netlify.app/"
        title="TOC"
        style={{
          width: '100%',
          display: 'flex',
          height: 'calc(100dvh - var(--header-h))',
        }}
      />
    )
  }

  const getSubPage = () => {
    switch (subpage) {
      case SubPage.support:
        return <Support />
      case SubPage.faq:
        return <FAQ />
      case SubPage.tos:
        return <TOC />
      case SubPage.tosNetEnt:
        return <TOSNetEnt />
    }
  }

  let seed = localStorage.getItem('next_seed')
  if (null === seed) {
    seed = generateRandomString(40)
    localStorage.setItem('next_seed', seed)
  }

  useEffect(() => {
    const obs = nextServerSeedHash.subscribe((hash) => {
      setHash(hash)
    })

    return () => {
      obs.unsubscribe()
    }
  }, [])

  return (
    <div className="pv">
      {/* <div className="app-left-menu styled-scroll">
        <h4>PROVABLY FAIR</h4>
        <div className="pv-top">
          {hash ? (
            <span className="ssh">
              Next game server seed hash: <small className="pv-next-game-seed">{hash}</small>
            </span>
          ) : null}
          <span className="pv-next-game-seed-label">Next game client seed:</span>
          <span className="pv-next-game-seed">{seed}</span>
        </div>

        <hr />
        <div className="app-list">
          <div onClick={() => setSubpage(SubPage.support)} className={subpage === SubPage.support ? 'selected' : ''}>
            SUPPORT
          </div>
          <div onClick={() => setSubpage(SubPage.faq)} className={subpage === SubPage.faq ? 'selected' : ''}>
            FAQ
          </div>
          <div onClick={() => setSubpage(SubPage.tos)} className={subpage === SubPage.tos ? 'selected' : ''}>
            TERMS OF SERVICE
          </div>
          <div
            onClick={() => setSubpage(SubPage.tosNetEnt)}
            className={subpage === SubPage.tosNetEnt ? 'selected' : ''}
          >
            NetEnt TOS
          </div>
        </div>
      </div> */}
      <div className="app-content-inner">{getSubPage()}</div>
    </div>
  )
}

const TOSNetEnt = () => {
  return (
    <div>
      <h3>Terms and Conditions </h3>
      <span className="tos-container">
        <div className="faq-line">
          <div className="header">
            <span>1. Absolute Restriction</span>
          </div>
          <div className="body">
            NetEnt will not permit NetEnt Casino Games to be supplied to any entity that operates in any of the below
            jurisdictions (irrespective of whether or not NetEnt Casino Games are being supplied by the entity in that
            jurisdiction) without the appropriate licenses. Belgium, Bulgaria, Colombia, Croatia, Czech Republic,
            Denmark, Estonia, France, Italy, Latvia, Lithuania, Mexico, Portugal, Romania, Spain, Sweden, Switzerland,
            United Kingdom, United States of America.
          </div>
        </div>

        <div className="faq-line">
          <div className="header">
            <span>2. Blacklisted Territories</span>
          </div>
          <div className="body">
            All NetEnt Casino Games may not be offered in the following territories: Afghanistan, Albania, Algeria,
            Angola, Australia, Bahamas, Botswana, Belgium, Bulgaria, Colombia, Croatia, Czech Republic, Denmark,
            Estonia, Ecuador, Ethiopia, France, Ghana, Guyana, Hong Kong, Italy, Iran, Iraq, Israel, Kuwait, Latvia,
            Lithuania, Mexico, Namibia, Nicaragua, North Korea, Pakistan, Panama, Philippines, Portugal, Romania,
            Singapore, Spain, Sweden, Switzerland, Sudan, Syria, Taiwan, Trinidad and Tobago, Tunisia, Uganda, United
            Kingdom, United States of America, Yemen, Zimbabwe.
          </div>
        </div>

        <div className="faq-line">
          <div className="header">
            <span>3. Blacklisted Branded Games Territories</span>
          </div>
          <div className="body">
            The followed NetEnt Braded Games have some further restrictions in addition to the Blacklisted Territories
            set out above:
            <br />
            3.1. In addition to the jurisdictions set out in paragraph 2, Planet of the Apes Video Slot must not be
            offered in the following territories: Azerbaijan, China, India, Malaysia, Qatar, Russia, Thailand, Turkey,
            Ukraine.
            <br />
            3.2. In addition to the jurisdictions set out in paragraph 2, Vikings Video Slot must not be offered in the
            following jurisdictions: Azerbaijan, Cambodia, Canada, China, France, India, Indonesia, Laos, Malaysia,
            Myanmar, Papua New Guinea, Qatar, Russia, South Korea, Thailand, Turkey, Ukraine, United States of America.
            <br />
            3.3. In addition to the jurisdictions set out in paragraph 2, Narcos Video Slot must not be offered in the
            following territories: Indonesia, South Korea
            <br />
            3.4. In addition to the jurisdictions set out in paragraph 2, Street Fighter Video Slot must not be offered
            in the following territories: Anguilla, Antigua & Barbuda, Argentina, Aruba, Barbados, Bahamas, Belize,
            Bermuda, Bolivia, Bonaire, Brazil, British Virgin Islands, Canada, Cayman Islands, China, Chile, Clipperton
            Island, Columbia, Costa Rica, Cuba, Curacao, Dominica, Dominican Republic, El Salvador, Greenland, Grenada,
            Guadeloupe, Guatemala, Guyana, Haiti, Honduras, Jamaica, Japan, Martinique, Mexico, Montserrat, Navassa
            Island, Paraguay, Peru, Puerto Rico, Saba, Saint Barthelemy, Saint Eustatius, Saint Kitts and Nevis, Saint
            Lucia, Saint Maarten, Saint Martin, Saint Pierre and Miquelon, Saint Vincent and the Grenadines, South
            Korea, Suriname, Turks and Caicos Islands, United States of America, Uruguay, US Virgin Islands, Venezuela.
            <br />
            3.5. In addition to the jurisdictions set out in paragraph 2, Fashion TV Video Slot must not be offered in
            the following territories: Cuba, Jordan, Turkey, Saudi Arabia
          </div>
        </div>

        <div className="faq-line">
          <div className="header">
            <span>
              4. Universal Monsters (Dracula, Creature from the Black Lagoon, Phantoms Curse and The Invisible Man) may
              only be played in the following territories
            </span>
          </div>
          <div className="body">
            Andorra, Austria, Armenia, Azerbaijan, Belarus, Bosnia and Herzegovina, Cyprus, Finland, Georgia, Germany,
            Greece, Hungary, Iceland, Ireland, Liechtenstein, Luxembourg, Malta, Moldova, Monaco, Montenegro,
            Netherlands, North Macedonia, Norway, Poland, Russia, San Marino, Serbia, Slovakia, Slovenia, Turkey and
            Ukraine.
          </div>
        </div>
      </span>
    </div>
  )
}

export default ProvablyFairComponent

const Support = () => {
  const mailTo = 'mailto:info@blackjack.fun'
  return (
    <div className="prose">
      <h3>Support</h3>

      <p>
        Blackjack.fun is owned and operated by Satya Code SRL, registration number: 3102813297, registered address: San
        Jose, Montes De Oca, San Pedro, 400N 25E Los Yoses, CR
      </p>

      <p>For further information or if you have any questions please contact us at</p>
      <address />
      <a className="mailto" href={mailTo} target="_top">
        info@blackjack.fun
      </a>
    </div>
  )
}

const FAQ = () => {
  const currencies = ConfigurationService.instance.getTradeableCurrencies()
  const limitMap = {} as any
  Object.keys(currencies).forEach((x) => {
    const c = x as unknown as CurrencyEnum
    const limits = ConfigurationService.instance.getBetLimits(currencies[c])
    limitMap[x] = {
      min: Money.convertUnit(limits?.min, currencies[c].m_unit, currencies[c].s_unit, currencies[c].m_unit),
      max: Money.convertUnit(limits?.max, currencies[c].m_unit, currencies[c].s_unit, currencies[c].m_unit),
    }
  })

  const rules = [
    'Number of decks of cards used: 8',
    'Insurance is available when Dealer has an Ace',
    'Insurance pays 2:1    ',
    'Dealer must hit soft 17',
    'Dealer peeks on 10 value cards and Aces',
    'Player can double down on any two cards which sum to 9, 10 or 11',
    "Player can't double down after a split",
    'Hand receives one additional card after doubling down',
    'Split up to 4 hands',
    'Aces can only be split once',
    'Only one card dealt to split Aces',
    'Making Ace and 10 after splitting counts as 21, not Blackjack',
    'Any two cards with a value of 10 can be split',
    'Blackjack pays 3:2',
    'Malfunction voids all pays and plays',
    'The shuffle is done with a fair and certified random number generator.',
  ]

  const questionsAndAnsers = [
    { q: 'What are the rules?', a: rules.join('\n') },
    { q: 'How much is minimum deposit', a: 'There is no minimum or maximum deposit' },
    {
      q: 'How much is withdrawal fee',
      a: 'There is no withdrawal fee. Only cryptocurrency transaction fee what depends on different cryptocurrences',
    },
    { q: 'Domination of chips', a: 'You can change the domination of chips from Menu - Settings' },
    {
      q: 'How fast can I see my coins in my wallet after withdraw.',
      a: `${process.env.APP_NAME} has Instant Withdraws`,
    },
    {
      q: 'How much is affiliate commission',
      a: 'Affiliate Program Details:\n\nRevenue Share 50%\nCookie duration unlimited\nLifetime commissions on referred players\nNo admin fee\nInstant affiliate payments\nNo minimum payments\nNo maximum payments\nFocused also on the mobile market\nAccess to live stats and reports',
    },
    {
      q: 'Does the dealer peak on an ace or for blackjack when they have a ten/face',
      a: 'Dealer does not peak at all',
    },
    {
      q: 'What is the minimum bet limit',
      a: `The minimum bet is \nBitcoin ${limitMap.BTC.min}\nLitecoin ${limitMap.LTC.min}\nDash ${limitMap.DASH.min}`,
    },
    {
      q: 'What is the maximum bet limit',
      a: `The maximum bet is \nBitcoin ${limitMap.BTC.max}\nLitecoin ${limitMap.LTC.max}\nDash ${limitMap.DASH.max}`,
    },
    {
      q: 'How to manually verify results',
      a: "Provably fair algorithm:\n1) Server generates random seed\n2) Server seed is hashed and sent to the player. Can be seen in Menu - Provably fair\n3) Player will send its own seed. Seed can be entered manually in Menu - Provably fair\n4) Two seeds are hashed together with sha256\n5) Output is used as seed in Mersenne Twister RNG(https://github.com/jj1bdx/sfmt-erlang)\n6) Deck is generated with 4 packs of cards\n7) Deck is shuffled using Fisher–Yates algorithm, with RNG provided in step 5\n\nFor example let's pick server and client seeds\nserver seed: 85728A263F0639D368DF48852D54A8F767512500D62E111EDAF972906A414FF80643A5FF7B3A8E3F9129DB1DA261FAC41C7431F74F099654301F57F497E8F1FE\nclient seed: f192685b5b26c72a92a833830947e5cac3383d5aa5b5210d2782f4fb51ba00bc7e5ce1b190c24013\nUsing these values\nserver seed hash would be 6A66A88382FAA06BB2EBCF2AA69EDF76596197D92C899D86A144990890B96EC8\nclient and server seed hashed together: ccfdcb55b4369d121854f77fbe8104e03a5a5611decd25afbdb88b02328a5a32\nRNG seed: [3439184725, 3023478034, 408221567, 3196126432, 978998801, 3737986479, 3182988034, 847927858] (3439184725dec = 0xCCFDCB55hex and so on...)\nFirst N random values produced by RNG: [1139799984, 4122921141, 3138460245, 3260841105, 2634829831, 1147546524, 4115959087, ...]\n\nUsing same logic on game deck you can verify that server cannot alter game output",
    },
    {
      q: 'What is the original deck layout',
      a: 'Spades, hearts, diamonds, clubs from 2 to A. S2, S3 .. SA ... H2, H3 ... CK, CA',
    },
  ]

  const list = questionsAndAnsers.map((x, i) => {
    return (
      <div className="faq-line" key={i}>
        <div className="header">
          {i + 1}. <span>{x.q}?</span>
        </div>
        <div className="body" style={{ whiteSpace: 'pre-wrap' }}>
          {x.a}
        </div>
      </div>
    )
  })

  return (
    <div>
      <h3>Frequently asked questions</h3>
      {list}
    </div>
  )
}

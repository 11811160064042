import { graphql } from 'babel-plugin-relay/macro'

export const sportsBetsQuery = graphql`
  query sportsBetsQuery {
    bets {
      sportBets {
        gameId
        gameProvider
        currency
        virtualId
        finished
        bets
        win
        btcBet
        btcWin
        multiplier
        profit
        meta
        insertedAt
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
    }
  }
`

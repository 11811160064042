import { Table } from '../tables/TablesComponent'
import { Game, GameSlot } from './Blackjack'

export enum DiffResultEnum {
  buttonClick,
  stateChaged,
  cardAdded,
  cardUpdated,
  cardMoved,
  slotAdded,
  slotPointerChanged,
  playerJoined,
  playerUpdated,
  betAdded,
}

export interface DiffResult {
  result: DiffResultEnum
  param: any
}

const findSlotById = (game: Game, slotId: number): GameSlot => {
  return game.slots.find((x) => x.slotId === slotId) as GameSlot
}

function diff(currentState: Game, nextState: Game, currentTable?: Table, nextTable?: Table): DiffResult[] {
  let changes: DiffResult[] = []
  if (!currentState || +currentState.state === 0) {
    if (undefined !== currentTable && undefined !== nextTable) {
      for (let i = 0; i < nextState.slots.length; i++) {
        const nextStateslot = nextState.slots[i]
        let currentStateSlot = findSlotById(currentState, nextStateslot.slotId)
        if (currentStateSlot === undefined && nextStateslot.bet > 0) {
          changes.push({
            result: DiffResultEnum.betAdded,
            param: { bet: nextStateslot.bet, uuid: nextStateslot.userUuid, user_idx: nextStateslot.userIdx },
          })
        }
      }
    }
    changes.push({
      result: DiffResultEnum.stateChaged,
      param: {
        fromState: 0,
        toState: +nextState.state,
      },
    })
    return changes
  }
  if (nextState.moveId) {
    changes.push({
      result: DiffResultEnum.buttonClick,
      param: nextState.moveId,
    })
  }
  if (nextState.sp !== currentState.sp) {
    changes.push({
      result: DiffResultEnum.slotPointerChanged,
      param: {
        from: currentState.sp,
        to: nextState.sp,
      },
    })
  }
  if (undefined !== currentTable && undefined !== nextTable) {
    if (currentTable.players.length !== nextTable.players.length) {
      changes.push({
        result: DiffResultEnum.playerJoined,
        param: nextTable.players.filter(
          (x) => currentTable.players.findIndex((y) => y.playerUuid === x.playerUuid) === -1
        ),
      })
    } else {
      for (let i = 0; i < currentTable.players.length; i++) {
        const a = currentTable.players[i]
        const b = nextTable.players[i]
        if (a.kicked !== b.kicked) {
          changes.push({
            result: DiffResultEnum.playerUpdated,
            param: b,
          })
          break
        }
      }
    }
  }
  // i = slot
  for (let i = 0; i < nextState.slots.length; i++) {
    const nextStateslot = nextState.slots[i]
    let currentStateSlot = findSlotById(currentState, nextStateslot.slotId)
    if (currentStateSlot === undefined) {
      currentStateSlot = { cards: [], slotId: nextStateslot.slotId } as any
      changes.push({
        result: DiffResultEnum.slotAdded,
        param: {
          slot: nextStateslot.slotId,
        },
      })
    }
    // j = card index in slot
    for (let j = 0; j < nextStateslot.cards.length; j++) {
      const nextCard = nextStateslot.cards[j]
      const currentCard = currentStateSlot.cards[j]
      // Hack for not duplicating cardMoved
      if (undefined === currentCard) {
        if (j >= 0) {
          changes.push({
            result: DiffResultEnum.cardAdded,
            param: {
              slot: currentStateSlot.slotId,
              cardIdx: j,
              card: nextCard,
            },
          })
        }
      } else if (currentCard.flipped !== nextCard.flipped) {
        changes.push({
          result: DiffResultEnum.cardUpdated,
          param: {
            slot: currentStateSlot.slotId,
            cardIdx: j,
            card: nextCard,
          },
        })
      } else if (currentCard.rank !== nextCard.rank || currentCard.suit !== nextCard.suit) {
        /*
         Card can be changed if slot is splitted or replace card perk is used.
         When slot is splitted then current and next state slot count is different.
         When perk is used then card is changed but slot count is same
        */
        if (currentState.slots.length === nextState.slots.length) {
          changes.push({
            result: DiffResultEnum.cardUpdated,
            param: {
              slot: currentStateSlot.slotId,
              cardIdx: j,
              card: nextCard,
            },
          })
        } else {
          // Card is moved into new slot. New slot is added right next to this one
          // New card is added to this slot
          changes.push({
            result: DiffResultEnum.cardMoved,
            param: {
              fromSlot: currentStateSlot.slotId,
              toSlot: nextState.slots[i + 1].slotId,
              cardIdx: j,
            },
          })
          changes.push({
            result: DiffResultEnum.cardAdded,
            param: {
              slot: currentStateSlot.slotId,
              cardIdx: j,
              card: nextCard,
            },
          })
        }
      }
    }
  }

  changes.push({
    result: DiffResultEnum.stateChaged,
    param: {
      fromState: +currentState.state,
      toState: +nextState.state,
    },
  })
  return changes
}

export { diff }

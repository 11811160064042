import React from 'react'

import classes from './ProfileRecentWins.module.scss'
import type { PlayerProfileNewProfileQuery$data } from '@components/PlayerProfile/__generated__/PlayerProfileNewProfileQuery.graphql'
import { formatBTC } from '../PlayerProfile/functions/formatBTC'

export function ProfileRecentWins({
  recentWins,
}: {
  recentWins: PlayerProfileNewProfileQuery$data['profile']['recentWins']
}) {
  return (
    <div className={classes.root}>
      <div className="inline-flex justify-between text-white">
        <h3>Recent Wins</h3>
        <button type="button" className={classes.viewAllButton}>
          view all activity
        </button>
      </div>
      <div className={classes.wins}>
        {recentWins.map((w) => (
          <div className={classes.row}>
            <div className="flex justify-center font-semibold py-5">{w.gameProvider.split(':')?.[1] || ''}</div>
            <div className="flex justify-center font-semibold py-5">{formatBTC(w.win || 0)}</div>
            <div className="flex justify-center font-semibold py-5">2.00X</div>
            <div className="flex justify-center font-semibold py-5">1BTC</div>
          </div>
        ))}
      </div>
    </div>
  )
}

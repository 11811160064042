import { useEffect, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Disposable, commitMutation, fetchQuery, useMutation } from 'react-relay'
import { applyUpdatedUserPreference, UserProp } from '../../reducers/authentication.reducer'
import { activeWalletUpdated, formatBalance, WalletConstant } from '../../reducers/wallet.reducer'
import ConfigurationService, { CurrencyEnum } from '../../service/ConfigurationService'
import { store } from '../../store'
import { getLogoForCoin } from '../coinsComponent/CoinsComponent'
import { updateUserPreference } from '../gameListComponent/GameListComponent'
import { GameListComponentstoreUserPreferenceMutation } from '../gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import { formatFiat } from '../rightSide/walletComponent/WalletComponent'
import { getUserPropOr } from '../SettingsComponent/SettingsComponent'
import Env from '../../RelayEnvironment'
import { Currency } from '../rightSide/walletComponent/__generated__/WalletComponentlastDepositAddressQuery.graphql'
import { graphql } from 'babel-plugin-relay/macro'
import {
  DepositViewComponentDepositAddressQuery,
  DepositViewComponentDepositAddressQueryResponse,
} from './__generated__/DepositViewComponentDepositAddressQuery.graphql'
import QRCode from 'react-qr-code'
import './DepositView.scoped.scss'
import { DepositViewTokensQuery, DepositViewTokensQueryResponse } from './__generated__/DepositViewTokensQuery.graphql'
import Select from 'react-select'
import { DepositViewGetPermanentDepositAddressMutation } from './__generated__/DepositViewGetPermanentDepositAddressMutation.graphql'
import { Money } from '@src/money'

const allCurrencies = Money.listAll()

export const DepositView = ({ openListView }) => {
  const { t } = useTranslation()
  const walletStore = store.getState().wallet
  const [activeWallet, setActiveWallet] = useState(walletStore?.activeWallet)
  const [network, setNetwork] = useState<string>()
  const [tokens, setTokens] = useState<DepositViewTokensQueryResponse>()
  const [selectedExternalCoin, setSelectedExternalCoin] = useState<{ coin?: string; token?: string; chain: string }>()
  const [paymentOrder, setPaymentOrder] = useState<{ address: string; memo: string | null }>()

  const [commit] = useMutation<GameListComponentstoreUserPreferenceMutation>(updateUserPreference)
  const [generatePermanentPaymentAddress] = useMutation<DepositViewGetPermanentDepositAddressMutation>(
    getPermanentDepositAddressMutation
  )
  const [depositAddressData, setDepositAddressData] = useState<DepositViewComponentDepositAddressQueryResponse>()

  const copy = (event) => {
    if (depositAddressData) navigator.clipboard.writeText(depositAddressData.lastDepositAddress!.address)
    event.target.innerHTML = t('wallet.depositView.copied', 'Copied')
    event.target.classList.add('sent')
  }

  const isExternalCurrency =
    ConfigurationService.instance.getExternalCurrencies().indexOf(activeWallet?.currency as CurrencyEnum) >= 0

  useEffect(() => {
    fetchQuery<DepositViewTokensQuery>(Env, listTokens, {}).subscribe({
      next: (data) => {
        setTokens(data)
        if (isExternalCurrency && activeWallet) {
          const token = data.tokens.find((x) => x.crypto === activeWallet.currency)
          if (!token) {
            return
          }
          let defaultToken
          let defaultChain
          if (token.tokens.length === 1) {
            defaultToken = token.tokens[0].tokenId
            defaultChain = token.tokens[0].chain
          }
          setSelectedExternalCoin({ coin: token.coinId, token: defaultToken, chain: defaultChain })
          setDepositAddressData(undefined)
          setNetwork(undefined)
        }
      },
    })
  }, [activeWallet, isExternalCurrency])

  useEffect(() => {
    // We we are using external currencies then we also need network
    if (activeWallet && isExternalCurrency && (network?.length || 0) === 0) {
      return
    }
    if (activeWallet?.currency === 'JACK') {
      // Imaginary coin
      return
    }
    fetchQuery<DepositViewComponentDepositAddressQuery>(Env, getDepositAddressQuery, {
      currency: activeWallet!.currency as Currency,
    }).subscribe({
      next: (data) => {
        if (data.lastDepositAddress?.currency !== activeWallet?.currency) {
          return
        }
        if (data.lastDepositAddress) {
          setDepositAddressData(data)
        }
      },
    })
  }, [activeWallet, network, isExternalCurrency])

  useEffect(() => {
    const subscription = activeWalletUpdated.subscribe(() => {
      setActiveWallet(store.getState().wallet.activeWallet)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [activeWallet])

  /*useEffect(() => {
    let query: Disposable;
    if (selectedExternalCoin?.coin && selectedExternalCoin.token) {
      setDepositAddressData(undefined)
      query = generatePaymentOrder({
        variables: { input: { amountCents: 100, tokenId: selectedExternalCoin.token } },
        onCompleted: (resp) => {
          console.log({ resp })
          if (!resp.generatePaymentOrder) {
            return
          }
          setPaymentOrder({ address: resp.generatePaymentOrder.payAddress, memo: resp.generatePaymentOrder.memo })
          setDepositAddressData({
            lastDepositAddress: {
              address: resp.generatePaymentOrder.payAddress,
              currency: undefined as any,
              isShared: false,
              expireTime: null,
            },
          })
        },
      })
    }
    return () => {
      query?.dispose();
    }
  }, [selectedExternalCoin, generatePaymentOrder])*/

  useEffect(() => {
    let query: Disposable
    if (selectedExternalCoin?.coin && selectedExternalCoin.token) {
      setDepositAddressData(undefined)
      query = generatePermanentPaymentAddress({
        variables: { input: { tokenId: selectedExternalCoin.token, chainId: selectedExternalCoin.chain } },
        onCompleted: (resp) => {
          if (!resp.getPermanentDepositAddress) {
            return
          }
          setPaymentOrder({
            address: resp.getPermanentDepositAddress.payAddress,
            memo: resp.getPermanentDepositAddress.memo,
          })
          setDepositAddressData({
            lastDepositAddress: {
              address: resp.getPermanentDepositAddress.payAddress,
              currency: undefined as any,
              isShared: false,
              expireTime: null,
            },
          })
        },
      })
    }
    return () => {
      query?.dispose()
    }
  }, [selectedExternalCoin, generatePermanentPaymentAddress])

  if (!activeWallet) {
    return <span>...</span>
  }

  const unitConfig = allCurrencies[activeWallet.currency]
  const defaultUnit = ConfigurationService.instance.getCurrency(activeWallet.currency).format
  const selectedUnit = getUserPropOr(('unit_' + activeWallet.currency.toUpperCase()) as UserProp, defaultUnit)

  const setActive = (e: React.MouseEvent | undefined, currency: string) => {
    e?.preventDefault()
    e?.stopPropagation()

    const w = store
      .getState()
      .wallet.wallets.find((x) => x.currency.toLowerCase() === currency.toLowerCase() && x.virtualId === 0)
    if (!w) return

    store.dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: w })
    commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
      mutation: updateUserPreference,
      variables: { input: { key: UserProp.ACTIVE_COIN, value: w.currency } },
    })
  }

  const setActiveUnit = (e: React.MouseEvent, currency: string, unit: string) => {
    e.preventDefault()
    e.stopPropagation()
    setActive(undefined, currency)
    commit({
      variables: { input: { key: 'unit_' + currency, value: unit } },
      onCompleted: (resp) => {
        applyUpdatedUserPreference(resp)
      },
    })
  }
  const coin = tokens?.tokens.find((x) => x.coinId === selectedExternalCoin?.coin)
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: 'white',
      backgroundColor: 'unset',
      border: '1px solid #fff',
      borderRadius: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px 10px',
      backgroundColor: '#212226',
      color: '#fff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#212226',
    }),
  }

  const filterOption = (candidate, input) => {
    input = input.toLowerCase()
    return undefined !== Object.values(candidate.data).find((x: any) => x.toString().toLowerCase().indexOf(input) >= 0)
  }

  return (
    <div className="">
      <div className="dep-table">
        <div>
          <span>{t('wallet.balance', 'Balance')}</span>
          <span className="coin-header">{t('wallet.coin', 'Coin')}</span>
        </div>
        <div className="balance-row">
          <div className="balance">
            <span>{formatBalance(activeWallet, false, false)}</span>
            {formatFiat(walletStore.rates, activeWallet)}
          </div>
          <div>
            <div className="select" onClick={openListView}>
              <div className="currency">
                <img className="coin-image" alt={activeWallet.currency} src={getLogoForCoin(activeWallet.currency)} />
                {activeWallet.currency}
              </div>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
      <div className="deposit-units">
        <span className="label">{t('wallet.unit', 'Unit')}</span>
        <div className="unit-list">
          {Object.keys(unitConfig.units).map((key) => {
            const unit = unitConfig.units[key]
            if (unit.code === 'raw') return null

            return (
              <span
                onClick={(e) => setActiveUnit(e, activeWallet.currency, unit.code)}
                className={'wallet-unit' + (unit.code === selectedUnit ? ' selected' : '')}
                title={unit.name}
                key={'unit_' + unit.name}
              >
                {unit.code}
              </span>
            )
          })}
        </div>
      </div>
      <div className="deposit-info">
        {isExternalCurrency ? (
          <Select
            maxMenuHeight={150}
            key={JSON.stringify(selectedExternalCoin)}
            value={coin?.tokens.find((x) => x.tokenId === selectedExternalCoin?.token)}
            options={coin?.tokens
              .filter((x) => x.crypto !== 'ETH' || x.chain !== 'BASE')
              .sort((a, b) =>
                a.name === 'Ethereum' ? -1 : b.name === 'Ethereum' ? 1 : (a.name || '').localeCompare(b.name || '')
              )}
            placeholder={t('wallet.selectNetwork', 'Select network')}
            onChange={(e) =>
              setSelectedExternalCoin({ coin: selectedExternalCoin?.coin, token: e!.tokenId, chain: e!.chain })
            }
            isSearchable={false}
            styles={customStyles}
            filterOption={filterOption}
            formatOptionLabel={(e) => {
              return (
                <div className="currency-select">
                  <img alt={e.name} src={e.chainLogo} />
                  <span>
                    {e.name === 'Ethereum' && e.network === 'NATIVE' ? 'Ethereum ERC20' : `${e.name}(${e.network})`}
                  </span>
                </div>
              )
            }}
          />
        ) : null}
        {depositAddressData ? (
          <div className="deposit-address">
            <div className="qr">
              <QRCode size={122} value={depositAddressData.lastDepositAddress!.address} />
            </div>
            {paymentOrder?.address && (paymentOrder.memo?.length || 0) > 0 ? (
              <Fragment>
                <div className="memo">
                  <span>{t('wallet.memo', 'Memo')}</span> <b className="red">{paymentOrder.memo}</b>
                </div>
                <p className="memo-desc">
                  {t('wallet.memoWarning', 'NB! Not providing memo may result in loss of your assets')}
                </p>
              </Fragment>
            ) : null}
            <div className="label">{t('wallet.depositView.depositAddress', 'Deposit Address')}</div>
            <div className="address">{depositAddressData.lastDepositAddress!.address}</div>
            <button type="button" className="copy" onClick={copy}>
              {t('wallet.depositView.copy', 'Copy')}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const getDepositAddressQuery = graphql`
  query DepositViewComponentDepositAddressQuery($currency: Currency!) {
    lastDepositAddress(currency: $currency) {
      currency
      address
      isShared
      expireTime
    }
  }
`

export const listTokens = graphql`
  query DepositViewTokensQuery {
    tokens {
      symbol
      crypto
      name
      logo
      min
      price
      coinId
      status
      tokens {
        tokenId
        crypto
        logo
        name
        network
        chain
        contract
        chainLogo
        status
      }
    }
  }
`

// This will generate new deposit order, it will be valid for about 24 hours
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generatePaymentOrderMutation = graphql`
  mutation DepositViewGeneratePaymentOrderMutation($input: GeneratePaymentOrderInput!) {
    generatePaymentOrder(input: $input) {
      payAddress
      memo
    }
  }
`

// This will generate permanent deposit address for user, it will never expire
const getPermanentDepositAddressMutation = graphql`
  mutation DepositViewGetPermanentDepositAddressMutation($input: GetPermanentDepositAddressInput!) {
    getPermanentDepositAddress(input: $input) {
      payAddress
      memo
    }
  }
`

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { useChatGiveawaysQuery$data } from '@src/hooks/__generated__/useChatGiveawaysQuery.graphql'

type Giveaway = useChatGiveawaysQuery$data['giveaways'][number]

export interface ChatState {
  giveaways: Giveaway[]
}

const initialState: ChatState = {
  giveaways: [],
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setGiveaways: (
      state,
      action: PayloadAction<{
        giveaways: Giveaway[]
      }>
    ) => {
      state.giveaways = [...action.payload.giveaways]
    },
  },
})

export const { setGiveaways } = chatSlice.actions
export type ChatAction = ReturnType<typeof setGiveaways>

export default chatSlice.reducer

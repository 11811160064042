import React, { useRef, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import OutsideClickHandler from 'react-outside-click-handler'
import useIsMobile from '../../hooks/IsMobile'
import ITooltip from './Tooltip.interface'
// import styles from './Tooltip.module.scss'

const Tooltip: React.FC<ITooltip> = ({
  effect = 'solid',
  place = 'top',
  type = 'light',
  text,
  className,
  children,
}) => {
  const divRef = useRef<HTMLDivElement>(null)

  const isMobile = useIsMobile()
  const event = isMobile ? 'click' : 'mouseenter'

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (event.includes('click') && divRef?.current) {
          ReactTooltip.hide(divRef.current)
        }
      }}
    >
      <div
        ref={divRef}
        data-tip={text}
        data-effect={effect}
        data-place={place}
        data-type={type}
        className={className}
        onClick={() => {
          if (event.includes('click') && divRef?.current) {
            ReactTooltip.show(divRef.current)
          }
        }}
        onMouseEnter={() => {
          if (event.includes('click') && divRef?.current) {
            ReactTooltip.show(divRef.current)
          }
        }}
        onMouseLeave={() => {
          if (event.includes('mouseenter') && divRef?.current) {
            ReactTooltip.hide(divRef.current)
          }
        }}
      >
        {children}
      </div>
    </OutsideClickHandler>
  )
}
export default Tooltip

const SwordsIcon = ({ width = '16', height = '16' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M4.16113 10.4839L13.1934 1.45161L14.9998 1L14.5482 2.80645L5.51597 11.8387"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M3.48389 9.80645L6.19356 12.5161" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M2.80615 10.4839L5.51583 13.1935" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path
      d="M4.6129 12.2903L1.90323 15H1.22581L1 14.7742V14.0968L3.70968 11.3871"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M3.03223 12.0645L3.93545 12.9677" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M2.35498 12.7419L3.25821 13.6452" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M1.67725 13.4193L2.58047 14.3226" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M5.51611 10.4839L13.871 2.12903" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M8.45166 9.80645L10.4839 11.8387" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path
      d="M7.54839 6.19355L2.80645 1.45161L1 1L1.45161 2.80645L6.19355 7.54839"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M11.8384 10.4839L9.80615 8.45161" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M12.5158 9.80645L9.80615 12.5161" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M13.1936 10.4839L10.4839 13.1935" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path
      d="M11.3872 12.2903L14.0969 15H14.7743L15.0001 14.7742V14.0968L12.2904 11.3871"
      stroke="white"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path d="M12.9677 12.0645L12.0645 12.9677" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M13.6454 12.7419L12.7422 13.6452" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M14.3227 13.4193L13.4194 14.3226" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M6.87084 6.87096L2.12891 2.12903" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M10.4837 10.4839L9.12891 9.12903" stroke="white" strokeWidth="0.7" strokeLinejoin="round" />
  </svg>
)

export default SwordsIcon

/**
 * @generated SignedSource<<0c965c431338919377a60a590afe0a67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type EndBankrollInput = {
  currency: Currency;
};
export type BecomeAnInvestorEndBankrollMutation$variables = {
  input: EndBankrollInput;
};
export type BecomeAnInvestorEndBankrollMutation$data = {
  readonly endBankroll: {
    readonly resp: {
      readonly insertedAt: string;
    };
  } | null;
};
export type BecomeAnInvestorEndBankrollMutation = {
  variables: BecomeAnInvestorEndBankrollMutation$variables;
  response: BecomeAnInvestorEndBankrollMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EndBankrollPayload",
    "kind": "LinkedField",
    "name": "endBankroll",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bankroll",
        "kind": "LinkedField",
        "name": "resp",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insertedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BecomeAnInvestorEndBankrollMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BecomeAnInvestorEndBankrollMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a8017451f09ddcac8f5cc8efa91180c",
    "id": null,
    "metadata": {},
    "name": "BecomeAnInvestorEndBankrollMutation",
    "operationKind": "mutation",
    "text": "mutation BecomeAnInvestorEndBankrollMutation(\n  $input: EndBankrollInput!\n) {\n  endBankroll(input: $input) {\n    resp {\n      insertedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d17f5970d91d70fa65082a84ce8ed62";

export default node;

import { useEffect, useState } from 'react'
import classNames from 'classnames'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'

import sanityClient from '@src/sanityClient'

import MuxVideo from '@mux/mux-video-react'

import classes from './Events.module.scss'

const query = `*[_type == "events"]{
    _id,
    _type,
    order,
    "thumbnail": thumbnail.asset->url,
    "playbackId": video.asset->playbackId
}`

export function Events() {
  const [videos, setVideos] = useState<
    Array<{
      _id: string
      _type: string
      order: number
      thumbnail: string
      playbackId: string
    }>
  >([])

  useEffect(() => {
    sanityClient.fetch(query).then((res) => {
      const videosWithoutDrafts = res.filter((r) => !r._id.includes('drafts.'))
      setVideos(videosWithoutDrafts)
    })
  }, [])

  if (videos.length === 0) return null

  return (
    <div className={classes.root}>
      <div
        className={classNames(
          'flex justify-between items-center px-20 text-white font-poppins text-sm leading-5 py-10 gap-x-8',
          classes.header
        )}
      >
        <div className="flex gap-x-4 md:gap-x-16">
          <div className="font-black w-max">EVENTS</div>
          <span className={classes.viewAll}>View all</span>
        </div>
      </div>
      <Swiper slidesPerView={3} spaceBetween={14} className="mt-12">
        {videos.map((v, i) => (
          <SwiperSlide key={i}>
            <MuxVideo
              nonce={null}
              controls
              poster={v.thumbnail}
              playbackId={v.playbackId}
              className={classes.video}
              onClick={(e) => {
                if (e.currentTarget.paused) e.currentTarget.play()
                else e.currentTarget.pause()
              }}
            >
              <source src={`https://stream.mux.com/${videos[0]?.playbackId}.m3u8`} type="video/mp4" />
            </MuxVideo>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

import CarouselHeader from '@src/components/Elements/CarouselHeader'
import MainWinnerItem from './components/MainWinnerItem'
import WinnerItem from './components/WinnerItem'
import FireIcon from '@src/components/icons/fire'
import { useEffect, useState } from 'react'
import { formatAmount } from '@src/reducers/wallet.reducer'
import { formatFiatRaw } from '@src/components/rightSide/walletComponent/WalletComponent'
import { store } from '@src/store'
import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery } from 'relay-runtime'
import Env from '@src/RelayEnvironment'
import { PedestalComponentBiggestWinsQuery } from './__generated__/PedestalComponentBiggestWinsQuery.graphql'
import { useTranslation } from 'react-i18next'

export type FormattedPlayer = {
  user: {
    uuid: string
    name: string
    hasProfileImage: boolean
    vip: any
  }
  amountWon: string
  multiplier: number
  normalizedGame: {
    label: string
    gameId: string
    provider: string
    urlThumb: string
  }
  betAmount: string
}

const Pedestal = () => {
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<FormattedPlayer[] | null>(null)
  const [ratesLoaded, setRatesLoaded] = useState(false)

  const formatBiggestWinsData = (biggestWins) => {
    return biggestWins.map((win) => {
      const amountWon = formatFiatRaw(store.getState().wallet.rates, {
        balance: win.btcWin,
        currency: 'BTC',
      })

      const betAmount = formatAmount(
        win.bets.reduce((acc, x) => acc + x, 0),
        win.currency,
        true
      )

      return {
        user: win.user,
        amountWon,
        multiplier: parseInt(win.multiplier.toString()),
        normalizedGame: win.normalizedGame,
        betAmount,
      }
    })
  }

  useEffect(() => {
    const checkRatesLoaded = () => {
      const rates = store.getState().wallet.rates
      if (rates && rates.length > 0) {
        setRatesLoaded(true)
      }
    }

    // Initial check
    checkRatesLoaded()

    // Subscribe to store changes to detect when rates are loaded
    const unsubscribe = store.subscribe(() => {
      checkRatesLoaded()
    })

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (ratesLoaded) {
      fetchQuery<PedestalComponentBiggestWinsQuery>(
        Env,
        biggestWinsQuery,
        {},
        { fetchPolicy: 'network-only' }
      ).subscribe({
        next: ({ bets }) => {
          if (!bets?.biggestWins.length) return
          const data = formatBiggestWinsData(bets.biggestWins)
          setFormattedData(data)
        },
        error: (error) => {
          console.error('Error fetching biggest wins', error.message)
        },
      })
    }
  }, [ratesLoaded])

  if (!formattedData || formattedData.length < 5) {
    return null
  }

  return (
    <>
      <CarouselHeader
        text={t('carouselHeading.recentBigWins', 'Recent Big Wins')}
        icon={<FireIcon className={'w-16 h-16 text-carouselHeading'} />}
        uniqueId="winsHeader"
      />
      <div className="flex flex-col lg:flex-row lg:items-stretch lg:space-x-20 mb-30">
        <div className="w-full lg:w-4/12 lg:min-w-350 mb-15 lg:mb-0">
          <MainWinnerItem player={formattedData[0]} />
        </div>
        <div className="flex w-full lg:w-8/12">
          <div className="w-full mr-10 sm:w-1/3 lg:w-1/4 mb-4 lg:mb-0 lg:mr-20">
            <WinnerItem player={formattedData[1]} />
          </div>
          <div className="w-full mr-10 sm:w-1/3 lg:w-1/4 mb-4 lg:mb-0 lg:mr-20">
            <WinnerItem player={formattedData[2]} />
          </div>
          <div className="w-full sm:w-1/3 lg:w-1/4 mb-4 lg:mb-0 lg:mr-20">
            <WinnerItem player={formattedData[3]} />
          </div>
          <div className="w-full hidden lg:block sm:w-1/3 lg:w-1/4 mb-4 lg:mb-0">
            <WinnerItem player={formattedData[4]} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Pedestal

const biggestWinsQuery = graphql`
  query PedestalComponentBiggestWinsQuery {
    bets {
      biggestWins {
        currency
        bets
        win
        btcBet
        btcWin
        multiplier
        normalizedGame {
          label
          gameId
          provider
          urlThumb
        }
        user {
          uuid
          name
          vip {
            badgeId
            offset
            avatar
          }
          hasProfileImage
        }
      }
    }
  }
`

import React from 'react'

import classes from './CommandPallete.module.scss'
import classNames from 'classnames'

const commands = [
  {
    command: '/daily giveaway',
    description: 'Command description here',
  },
  {
    command: '/btc giveaway',
    description: 'Command description here',
  },
  {
    command: '/blackjack.fun is amazing',
    description: 'Command description here',
  },
  {
    command: '/I am a bonus hunter',
    description: 'Command description here',
  },
  {
    command: '/giveaway',
    description: 'Command description here',
  },
]

export function CommandPallete() {
  return (
    <div
      className={classNames(classes.root, {
        hidden: true, // ? temporary hidden
      })}
    >
      {commands.map((command, i) => (
        <div key={i} className={classes.commandWrapper}>
          <button type="button" className="px-24 flex flex-col font-inter">
            <span
              style={{
                fontSize: '13px',
                lineHeight: '18px',
              }}
              className="font-bold"
            >
              {command.command}
            </span>
            <span
              style={{
                fontSize: '9px',
                lineHeight: '18px',
              }}
              className="font-normal"
            >
              {command.description}
            </span>
          </button>
        </div>
      ))}
    </div>
  )
}

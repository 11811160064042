import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Subscription } from 'rxjs'
import { nextServerSeedHash, seedUsed } from '../provablyFairComponent/ProvablyFairComponent'
import { generateRandomString } from '../SignInComponent'

interface Props {}
interface State {
  auto: boolean
  nextClientSeed: string
  serverHash?: string
  copied: boolean
}

export class ClientSeed extends React.Component<Props, State> {
  private subscriptions: Subscription[] = []

  constructor(props: Props) {
    super(props)
    let seed = localStorage.getItem('next_seed')
    if (!seed) {
      seed = generateRandomString(40)
      localStorage.setItem('next_seed', seed)
    }
    const auto = localStorage.getItem('seed_generate') !== 'false'
    this.state = {
      auto: auto,
      nextClientSeed: seed,
      serverHash: undefined,
      copied: false,
    }
  }

  componentDidMount() {
    this.subscriptions.push(
      seedUsed.subscribe(() => {
        const seed = generateRandomString(40)
        localStorage.setItem('next_seed', seed)
        this.setState({ nextClientSeed: seed })
      })
    )
    this.subscriptions.push(
      nextServerSeedHash.subscribe((hash) => {
        this.setState({ serverHash: hash })
      })
    )
  }

  componentWillUnmount() {
    this.subscriptions.forEach((x) => x.unsubscribe())
    this.subscriptions = []
  }

  setAuto(auto: boolean) {
    this.setState({ auto })
    if (auto) {
      localStorage.setItem('seed_generate', 'true')
      const seed = generateRandomString(40)
      localStorage.setItem('next_seed', seed)
      this.setState({ nextClientSeed: seed })
    } else {
      localStorage.setItem('seed_generate', 'false')
    }
  }

  handleInputChange = (event: any) => {
    const value = event.target.value
    if (value.length > 0) {
      localStorage.setItem('next_seed', value)
    }
    this.setState({ nextClientSeed: value })
  }

  copied = () => {
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, 600)
  }

  render() {
    let autoSeed: JSX.Element | null = null
    let manualSeed: JSX.Element | null = null
    if (this.state.auto) {
      autoSeed = (
        <span>
          Next game seed: <span className="cs-auto-seed">{this.state.nextClientSeed}</span>
        </span>
      )
    } else {
      manualSeed = (
        <input
          className={
            this.state.nextClientSeed.length < 2
              ? 'cs-too-short next_game_seed cs-manual-seed'
              : 'next_game_seed cs-manual-seed'
          }
          maxLength={255}
          minLength={2}
          required={true}
          pattern="[a-zA-Z0-9]+"
          onChange={this.handleInputChange}
          value={this.state.nextClientSeed}
        />
      )
    }

    return (
      <div className="more-games">
        <div className="cs-container">
          <div>
            {this.state.serverHash ? (
              <CopyToClipboard onCopy={this.copied} text={this.state.serverHash}>
                <span className="ssh">
                  Server seed hash:{' '}
                  <small className={this.state.copied ? 'copied' : 'pv-next-game-seed'} title={this.state.serverHash}>
                    {this.state.serverHash.substring(0, 24)}...
                  </small>
                </span>
              </CopyToClipboard>
            ) : null}
          </div>
          <div>
            <div className="tab-header nt">
              <input id="cs-auto" type="checkbox" checked={this.state.auto} onChange={() => this.setAuto(true)} />
              <label htmlFor="cs-auto">Auto seed</label>
            </div>
            {autoSeed}
          </div>
          <div>
            <div className="tab-header">
              <input id="cs-manual" type="checkbox" checked={!this.state.auto} onChange={() => this.setAuto(false)} />
              <label htmlFor="cs-manual">Enter seed manually</label>
            </div>
            {manualSeed}
          </div>
        </div>
      </div>
    )
  }
}

/**
 * @generated SignedSource<<68ec7f88f0fe28d97205732233a9efc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "BTC" | "LTC" | "DASH" | "BCH" | "FUN" | "ETH" | "USDT" | "ADA" | "TRX" | "BNB" | "XMR" | "XRP" | "USDC" | "DOGE" | "BUSD" | "SOL" | "SHIB" | "PEPE" | "WAVES" | "ETC" | "MANA" | "ZEC" | "DGB" | "MATIC" | "ALGO" | "APT" | "ATOM" | "AVA" | "AVAX" | "AXS" | "BSV" | "BTG" | "CELO" | "CRO" | "DAI" | "DCR" | "DOT" | "EGLD" | "FIL" | "FLOKI" | "FTM" | "ICP" | "ICX" | "IOTX" | "KAVA" | "KLAY" | "KSM" | "NEAR" | "OKT" | "ONE" | "QTUM" | "ROSE" | "RVN" | "THETA" | "TOMO" | "VET" | "XLM" | "XNO" | "XTZ" | "ZIL" | "GALA" | "KMD" | "LINK" | "XEC" | "JACK" | "USD" | "BABYDOGE" | "KISHU" | "LOVELY" | "ARB" | "POLYDOGE" | "BAD" | "TON" | "SPACE" | "BFG" | "CROP" | "KINGSHIB" | "OGGY" | "BONK" | "JTO" | "MSOL" | "COQ" | "%future added value";
export type DepositViewComponentDepositAddressQuery$variables = {
  currency: Currency;
};
export type DepositViewComponentDepositAddressQuery$data = {
  readonly lastDepositAddress: {
    readonly currency: Currency;
    readonly address: string;
    readonly isShared: boolean | null;
    readonly expireTime: number | null;
  } | null;
};
export type DepositViewComponentDepositAddressQuery = {
  variables: DepositViewComponentDepositAddressQuery$variables;
  response: DepositViewComponentDepositAddressQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currency"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      }
    ],
    "concreteType": "DepositAddress",
    "kind": "LinkedField",
    "name": "lastDepositAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isShared",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expireTime",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DepositViewComponentDepositAddressQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DepositViewComponentDepositAddressQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a0ff6ceec0c4d9c3972ca4ee4712cffc",
    "id": null,
    "metadata": {},
    "name": "DepositViewComponentDepositAddressQuery",
    "operationKind": "query",
    "text": "query DepositViewComponentDepositAddressQuery(\n  $currency: Currency!\n) {\n  lastDepositAddress(currency: $currency) {\n    currency\n    address\n    isShared\n    expireTime\n  }\n}\n"
  }
};
})();

(node as any).hash = "e67a02eab34278d9e1e1de4ec577163d";

export default node;

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BonusBalanceTab } from './BonusBalanceTab'
import { WalletTab } from './WalletTab'
import SmallArrow from '../icons/smallArrow'
import { useLockScroll } from '@src/hooks/useLockScroll'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { IStore, store } from '@src/store'

import Env from '@src/RelayEnvironment'
import classes from './WalletModal.module.scss'

import './WalletModal.scoped.scss'
import { WalletGamblerTab } from './WalletGamblerTab'
import { WalletConstant } from '@src/reducers/wallet.reducer'
import { commitMutation } from 'relay-runtime'
import { GameListComponentstoreUserPreferenceMutation } from '../gameListComponent/__generated__/GameListComponentstoreUserPreferenceMutation.graphql'
import { updateUserPreference } from '../gameListComponent/GameListComponent'
import { UserProp } from '@src/reducers/authentication.reducer'

import cookies from 'js-cookie'

export enum Tab {
  wallet,
  bonusBalance,
  special,
}

export enum WalletView {
  deposit,
  externalDeposit,
  withdraw,
  history,
  list,
}

export enum BonusBalanceView {
  coins,
  freeSpins,
}

export const WalletModal = ({ closeCallback, currency }: { closeCallback: () => void; currency?: string }) => {
  const { t } = useTranslation()
  const me = useSelector((store: IStore) => store.authentication.user)
  const pendingKycBonuses = me?.pendingKycBonuses ?? []
  const hasPendingKycBonuses = pendingKycBonuses.length > 0

  const [popup, setPopup] = useState(null)
  const [isOpened, setIsOpened] = useState(false)

  const shouldCheckForPendingBonus = localStorage.getItem('check_for_pending_bonus')
  const [tab, setTab] = useState(hasPendingKycBonuses && shouldCheckForPendingBonus ? Tab.special : Tab.wallet)
  const [isBack, setIsBack] = useState(false)
  const [walletView, setWalletView] = useState(WalletView.deposit)
  const [lastWalletView, setLastWalletView] = useState(WalletView.deposit)
  const [bonusNalanceView, setBonusBalanceView] = useState(BonusBalanceView.coins)

  const closeWalletModal = () => closeCallback()

  const wallet = useSelector((store: IStore) => store.wallet)

  const gamblerWallet = wallet.wallets.find((w) => !!w.campaign?.meta?.playable)

  useEffect(() => {
    const shouldCheckForPendingBonus = localStorage.getItem('check_for_pending_bonus')
    if (!shouldCheckForPendingBonus) return

    localStorage.removeItem('check_for_pending_bonus')
  }, [])

  const dispatch = useDispatch()

  const setGamblerBalanceActive = useCallback(() => {
    const activeWallet = store.getState().wallet.activeWallet
    dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: gamblerWallet })
    commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
      mutation: updateUserPreference,
      variables: {
        input: {
          key: UserProp.ACTIVE_COIN,
          value: 'virtual_' + gamblerWallet?.virtualId + '_' + gamblerWallet?.currency,
        },
      },
      onCompleted: () => {
        if (!activeWallet) return
        cookies.set('last_non_virtual_wallet_currency', activeWallet.currency, { expires: 365 })
      },
    })
  }, [dispatch, gamblerWallet])

  const setGamblerBalanceInactive = () => {
    const lastNonVirtualWalletCurrency = cookies.get('last_non_virtual_wallet_currency')
    if (lastNonVirtualWalletCurrency) {
      const lastNonVirtualWallet = store
        .getState()
        .wallet.wallets.find((w) => w.currency === lastNonVirtualWalletCurrency)
      if (lastNonVirtualWallet) {
        dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: lastNonVirtualWallet })
        commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
          mutation: updateUserPreference,
          variables: {
            input: {
              key: UserProp.ACTIVE_COIN,
              value: lastNonVirtualWallet.currency,
            },
          },
        })
        return
      }
    }

    const wallets = store.getState().wallet.wallets.filter((w) => w.virtualId === 0)
    if (wallets[0]) {
      dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: wallets[0] })
      commitMutation<GameListComponentstoreUserPreferenceMutation>(Env, {
        mutation: updateUserPreference,
        variables: {
          input: {
            key: UserProp.ACTIVE_COIN,
            value: wallets[0].currency,
          },
        },
      })
    } else {
      store.dispatch({
        type: WalletConstant.SET_ACTIVE_WALLET,
        wallet: {
          balance: 0,
          currency: 'BTC',
          virtual: false,
          virtualId: 0,
        },
      })
    }
  }

  const openListWalletView = () => {
    setIsBack(true)
    setLastWalletView(walletView)
    setWalletView(WalletView.list)
  }

  const openWalletView = (view) => {
    setIsBack(false)
    setWalletView(view)
  }

  const activeWalletView = () => {
    return isBack ? lastWalletView : walletView
  }

  const back = () => {
    setWalletView(lastWalletView)
    setIsBack(false)
  }

  const openBonusBalanceView = (view) => {
    setBonusBalanceView(view)
  }

  const activeBonusBalanceView = () => {
    return bonusNalanceView
  }

  // if currency is passed set active wallet
  useEffect(() => {
    if (currency) {
      const w = {
        balance: 0,
        currency: currency,
        virtual: false,
        virtualId: 0,
      }

      dispatch({ type: WalletConstant.SET_ACTIVE_WALLET, wallet: w })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])

  const renderTab = () => {
    switch (tab) {
      case Tab.wallet:
        return (
          <WalletTab
            view={walletView}
            openView={openWalletView}
            openListView={openListWalletView}
            activeView={activeWalletView}
            back={back}
            setPopup={setPopup}
          />
        )

      case Tab.bonusBalance:
        return (
          <BonusBalanceTab
            view={bonusNalanceView}
            openView={openBonusBalanceView}
            activeView={activeBonusBalanceView}
            setPopup={setPopup}
            setTab={setTab}
            closeWalletModal={closeWalletModal}
          />
        )
      case Tab.special:
        return <WalletGamblerTab closeWalletModal={closeWalletModal} />
    }
  }

  const handleModalHeaderClick = () => {
    if (isBack) {
      back()
    } else {
      setGamblerBalanceInactive()
      setTab(Tab.wallet)
    }
  }

  useEffect(() => {
    setIsOpened(true)

    return () => {
      setIsOpened(false)
    }
  }, [])

  useLockScroll(isOpened)

  return (
    <div className={classNames('wallet-modal flex flex-col', classes.root)}>
      <div className="modal-header">
        <div className="flex gap-x-16">
          <button className={classNames('title', { active: tab === Tab.wallet })} onClick={handleModalHeaderClick}>
            {isBack && <SmallArrow className="inline-block text-white mr-10" />}
            {isBack ? t('wallet.walletModal.back', 'BACK') : t('wallet.wallet', 'Wallet')}
          </button>
          {!isBack && (
            <button
              className={`title ${tab === Tab.bonusBalance ? 'active' : ''}`}
              onClick={() => {
                setTab(Tab.bonusBalance)
              }}
            >
              {t('wallet.walletModal.bonusBalance', 'BONUS BALANCE')}
            </button>
          )}
          {(gamblerWallet || hasPendingKycBonuses) && (
            <button
              className={classNames('title', {
                active: tab === Tab.special,
              })}
              onClick={() => {
                if (gamblerWallet) {
                  setGamblerBalanceActive()
                }
                setTab(Tab.special)
              }}
            >
              {t('wallet.walletModal.special', 'SPECIAL')}
            </button>
          )}
        </div>
        <FontAwesomeIcon className="close-modal" icon={faClose} size="2x" onClick={closeCallback} />
      </div>
      {renderTab()}
      {popup && (
        <div className="popup">
          <div className="relative">
            <FontAwesomeIcon className="close" icon={faClose} size="2x" onClick={() => setPopup(null)} />
            <div className="content">{popup}</div>
          </div>
        </div>
      )}
    </div>
  )
}

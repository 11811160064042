import { useEffect, useState } from 'react'

import sanityClient from '@src/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

type Campaign = {
  _id: string
  _type: string
  order: number
  header: string
  image: string
  content: any
}

export function useCampaignPage({ id }: { id: string }) {
  const [campaign, setCampaign] = useState<Campaign>()

  useEffect(() => {
    const query = `*[_type == "actionBanners" && redirect == "/campaign/${id}"]`
    sanityClient.fetch(query).then((res) => {
      const campaign = res.filter((b) => !b?._id.startsWith('drafts.'))[0]
      if (!campaign) return

      setCampaign({
        ...campaign,
        image: campaign?.biggerImage
          ? imageUrlBuilder(sanityClient).image(campaign.biggerImage).url()
          : imageUrlBuilder(sanityClient).image(campaign.image).url(),
      })
    })
  }, [id])

  useEffect(() => {
    console.log({ campaign })
  }, [campaign])

  return {
    campaign,
  }
}

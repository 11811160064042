import { useEffect } from 'react'
import './Competitions.scss'
import { globalConfUpdated } from '../../service/ConfigurationService'
import CompetitionResults from '../CompetitionResults/CompetitionResults/CompetitionResults'
import { useForceUpdate } from '../rightSide/walletComponent/WalletComponent'
import { useCompetitions } from '@src/context/CompetitionsContext'

export const Competitions = () => {
  const { competitions, subscribeToCompetitions, unsubscribeFromCompetitions } = useCompetitions()

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const confSubs = globalConfUpdated.subscribe(() => {
      forceUpdate()
    })

    return () => {
      confSubs.unsubscribe()
    }
  }, [forceUpdate])

  useEffect(() => {
    subscribeToCompetitions()

    return () => {
      unsubscribeFromCompetitions()
    }
  }, [subscribeToCompetitions, unsubscribeFromCompetitions])

  return (
    <div className={`grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-24`}>
      {competitions
        .sort((a, b) => a.position - b.position)
        .map((competition, i) => {
          return <CompetitionResults key={JSON.stringify(competition) + '_' + i} competition={competition} />
        })}
    </div>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '@src/store'
import classes from './ProfileSubTabs.module.scss'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { changeSubTab, getProfileSubTabs } from '@src/reducers/profile.reducer'
import { useEffect } from 'react'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'

export function ProfileSubTabs() {
  const dispatch = useDispatch()
  const { tab, subTab } = useSelector((s: IStore) => s.profile)
  const history = useHistory()
  const { t } = useTranslation()

  const subTabs = getProfileSubTabs(tab)

  useEffect(() => {
    if (subTab) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('stab', subTab)
      history.push({ search: searchParams.toString() })
    }
  }, [subTab, history])

  return (
    <div className={classes.menu}>
      {subTabs.map((tab) =>
        tab.disabled ? (
          <Tooltip
            place="bottom"
            text={t('profile.profileSubTabs.comingSoon', 'Coming soon.')}
            type="dark"
            key={tab.name}
          >
            <button type="button" disabled>
              {t(`profile.profileSubTabs.${tab.name}`, tab.name)}
            </button>
          </Tooltip>
        ) : (
          <button
            key={tab.name}
            type="button"
            onClick={() => {
              dispatch(changeSubTab({ subTab: tab.name }))
            }}
            className={classNames({
              [classes.active]: tab.name === subTab,
            })}
          >
            {t(`profile.profileSubTabs.${tab.name}`, tab.name)}
          </button>
        )
      )}
    </div>
  )
  // switch (tab) {
  //   case 'Account':
  //     return (
  //       <div className={classes.menu}>
  //         <button type="button">Stats</button>
  //         {/* <button type="button">Social</button> */}
  //       </div>
  //     )
  //   case 'Affiliate':
  //     return (
  //       <div className={classes.menu}>
  //         {affiliateTabs.map((at) => (
  //           <button
  //             key={at}
  //             type="button"
  //             onClick={() => {
  //               setAffiliateTab(at)
  //             }}
  //             className={classNames({
  //               [classes.active]: at === affiliateTab,
  //             })}
  //           >
  //             {at}
  //           </button>
  //         ))}
  //       </div>
  //     )
  //   case 'Settings':
  //     return (
  //       <div className={classes.menu}>
  //         {settingsTabs.map((t) => (
  //           <button
  //             key={t}
  //             type="button"
  //             onClick={() => {
  //               setSettingsTab(t)
  //             }}
  //             className={classNames({
  //               [classes.active]: t === settingsTab,
  //             })}
  //           >
  //             {t}
  //           </button>
  //         ))}
  //       </div>
  //     )
  //   default:
  //     return null
  // }
}

/**
 * @generated SignedSource<<829fdebc6f161eab1d7a73efdc3408ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubmitKycInfoInput = {
  infoFields: any;
  imageFields: any;
};
export type submitKYCMutation$variables = {
  input: SubmitKycInfoInput;
};
export type submitKYCMutation$data = {
  readonly submitKycInfo: {
    readonly user: {
      readonly kycState: string;
    };
  } | null;
};
export type submitKYCMutation = {
  variables: submitKYCMutation$variables;
  response: submitKYCMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitKycInfoPayload",
    "kind": "LinkedField",
    "name": "submitKycInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kycState",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submitKYCMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submitKYCMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6f114c9b4df930f5b21621d1b5e03681",
    "id": null,
    "metadata": {},
    "name": "submitKYCMutation",
    "operationKind": "mutation",
    "text": "mutation submitKYCMutation(\n  $input: SubmitKycInfoInput!\n) {\n  submitKycInfo(input: $input) {\n    user {\n      kycState\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62988c00fb267cf6e8899724bf7917e8";

export default node;

import { useTranslation, Trans } from 'react-i18next'
import classNames from 'classnames'

import classes from './Rain.module.scss'
import { getUserIcon } from '@src/App'
import { Money } from '@src/money'

export function Rain({
  rain,
}: {
  rain: {
    uuid: string
    from: string
    message: string
    amount: number
    currency: string
    player_count: number
    has_profile_image: boolean
  }
}) {
  const { t } = useTranslation()
  const amountWithCurrency = `${Money.fromInteger(rain.amount, rain.currency)} ${rain.currency}`

  return (
    <div className={classNames(classes.root, 'flex flex-col items-center py-16 gap-y-16')}>
      <img alt="Make it Rain" src="/assets/make_it_rain_logo_header.png" />
      <span className={classes.heading}>{t('chat.rain.rainingCoins', 'ITS RAINING COINS')}</span>
      <div className="px-32">
        <div className={classes.wrapper}>
          <div
            className={classNames(classes.imageWrapper, {
              'px-60': rain.from !== 'RoboRain',
            })}
          >
            {rain.from === 'RoboRain' ? (
              <img alt="Robot" src="/assets/chat/robo.png" />
            ) : (
              getUserIcon(rain.from, rain.uuid, rain.has_profile_image)
            )}
          </div>
          <p className="text-yellow text-center py-16">"{rain.message}"</p>
          <p className="px-32 text-center">
            <Trans
              i18nKey="chat.rain.message"
              values={{
                from: rain.from,
                amountWithCurrency,
                player_count: rain.player_count,
              }}
              components={{
                0: <span className={classes.from} />,
                1: <span className="text-yellow" />,
                2: <span />,
              }}
            >
              <span className={classes.from}>{rain.from}</span> rained a total of{' '}
              <span className="text-yellow">{amountWithCurrency}</span> to {rain.player_count} players that used the
              chat in the last hour
            </Trans>
          </p>
          <span
            style={{
              color: '#898ec9',
            }}
            className="text-center p-16 font-medium"
          >
            {t('chat.fundsInWallet', 'The funds are in your balance wallet')}
          </span>
        </div>
      </div>
    </div>
  )
}

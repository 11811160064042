import React from 'react'
import { useSelector } from 'react-redux'
import { store, IStore } from '../../store'
import { AppTypes } from '../../reducers/app.reducer'
import ModalComponent from 'react-modal'
import IModal from './Modal.interface'
import styles from './Modal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useLockScroll } from '@src/hooks/useLockScroll'

const Modal: React.FC<IModal> = () => {
  const modal = useSelector((state: IStore) => state.app.modal)
  useLockScroll(!!modal.view)

  const closeModal = () => {
    store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: null } })
    if (modal.onClose) modal.onClose()
  }

  return (
    <ModalComponent isOpen={!!modal.view} onRequestClose={closeModal} ariaHideApp={false} portalClassName={styles.root}>
      <FontAwesomeIcon className={styles.closeModal} icon={faClose} size="2x" onClick={closeModal} />
      {modal.view}
    </ModalComponent>
  )
}

export default Modal

const WalletIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23" fill="none">
    <path
      d="M27.1355 10.6215H25.7145V8.84643C25.7145 8.84284 25.711 8.83926 25.711 8.83567C25.704 7.19691 24.6015 5.8271 23.1175 5.43265V0.885718C23.1175 0.398035 22.7325 0 22.253 0H3.458C2.562 0 1.673 0.37652 1.0115 1.03633C0.3675 1.69613 0 2.60695 0 3.53212V19.4679C0 21.415 1.5505 23 3.458 23H22.26C23.184 23 24.052 22.6307 24.703 21.9637C25.3575 21.2967 25.7145 20.4074 25.7145 19.4679V17.7H27.1355C27.6115 17.7 28 17.302 28 16.8143V11.5072C28 11.0159 27.615 10.6215 27.1355 10.6215ZM2.226 2.29857C2.5585 1.96508 3.01 1.77144 3.458 1.77144H21.3955V5.30714H3.458C2.5025 5.30714 1.729 4.51107 1.729 3.5357C1.729 3.07312 1.911 2.61771 2.226 2.29857ZM23.989 19.4679C23.989 19.9341 23.8105 20.3751 23.4815 20.7122C23.156 21.0457 22.722 21.2321 22.26 21.2321H3.458C2.5025 21.2321 1.729 20.4432 1.729 19.4715V6.58014C2.24 6.88494 2.8245 7.07499 3.458 7.07499H22.26C23.212 7.07499 23.989 7.87106 23.989 8.84643V10.6179H20.342C19.418 10.6179 18.5535 10.9872 17.9095 11.647C17.255 12.3032 16.8945 13.1961 16.8945 14.1536C16.8945 16.1079 18.4415 17.6964 20.3455 17.6964H23.9925V19.4607C23.989 19.4643 23.989 19.4643 23.989 19.4679ZM20.342 15.9286C19.3935 15.9286 18.62 15.1325 18.62 14.1536C18.62 13.6766 18.795 13.2356 19.124 12.9057C19.453 12.5686 19.8765 12.3893 20.342 12.3893H26.2745V15.9286H20.342Z"
      fill="#ECECEC"
    />
  </svg>
)

export default WalletIcon

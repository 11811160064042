import { graphql } from 'babel-plugin-relay/macro'
import { useTranslation } from 'react-i18next'
import { FormEvent, useEffect, useState } from 'react'
import { commitMutation, fetchQuery } from 'react-relay'
import { useHistory, useParams } from 'react-router-dom'
import Env from '../../../RelayEnvironment'
import { RecoverPasswordCheckFpKeyQuery } from './__generated__/RecoverPasswordCheckFpKeyQuery.graphql'
import { RecoverPasswordRecoverPasswordMutation } from './__generated__/RecoverPasswordRecoverPasswordMutation.graphql'
import '../auth.scss'
import formatError from '../../errorFormatter'
import { isUserLoggedIn } from '../../../utils/functions'
import { AppTypes } from '../../../reducers/app.reducer'
import { store } from '../../../store'
import { LoginHeader } from '../Login/Login'
import styles from '../Login/Login.module.scss'

export const useRecoverPassword = () => {
  const { t } = useTranslation()
  const { action, key } = useParams<{ action: string; key: string }>()
  const [canReset, setCanReset] = useState(false)
  // const [canReset, setCanReset] = useState(true);
  const [message, setMessage] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [confirmPassword, setConfirmPassword] = useState<string>()
  const [done, setDone] = useState(false)
  const history = useHistory()

  const setModalView = (view, onClose) =>
    store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view, onClose: onClose } })

  useEffect(() => {
    if (action !== 'recover') return

    fetchQuery<RecoverPasswordCheckFpKeyQuery>(Env, checkKeyQuery, { key }, { fetchPolicy: 'network-only' }).subscribe({
      next: (res) => {
        if (!res.checkFpKey) {
          window.location.href = '/'
        }
        setCanReset(true)
      },
      error: (err: any) => {
        window.location.href = '/'
      },
    })
  }, [action, key])

  if (action !== 'recover') return

  if (isUserLoggedIn()) history.push('/')

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setMessage(t('authentication.recoverPassword.passwordsDontMatch', "Passwords don't match"))
    }

    commitMutation<RecoverPasswordRecoverPasswordMutation>(Env, {
      mutation: recoverPasswordMutation,
      variables: { input: { key, newPassword: password } },
      onCompleted: (data) => {
        if (data.recoverPassword?.res === 'ok') {
          setDone(true)
          setTimeout(() => {
            history.push('/login')
          }, 1000)
        }
      },
      onError: (err) => {
        setMessage(formatError(err)[0])
        console.log(err)
      },
    })
  }

  if (canReset) {
    let messageContainer: JSX.Element | undefined
    if (message) {
      messageContainer = <div className="alert alert-danger p1">{message}</div>
    }
    if (done) {
      messageContainer = (
        <div className="ok-color p1">{t('authentication.recoverPassword.passwordUpdated', 'Password updated')}</div>
      )
    }

    setModalView(
      <div className={styles.root}>
        <LoginHeader activeTab={t('authentication.resetPassword', 'Reset password')} />
        <div className={styles.body}>
          <form onSubmit={handleSubmit}>
            {messageContainer}
            <div className="">
              <label htmlFor="password-form-control">
                {t('authentication.recoverPassword.newPassword', 'New password')}
              </label>
              <input
                id="password-form-control"
                name="password"
                required={true}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="">
              <label htmlFor="confirm-password-form-control">
                {t('authentication.recoverPassword.confirmPassword', 'Confirm Password')}
              </label>
              <input
                id="confirm-password-form-control"
                name="confirmPassword"
                required={true}
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button className="" type="submit">
              {t('authentication.recoverPassword.reserPassword', 'Reset password')}
            </button>
          </form>
        </div>
      </div>,
      () => {
        history.push('/')
      }
    )
  }
  return
}

const recoverPasswordMutation = graphql`
  mutation RecoverPasswordRecoverPasswordMutation($input: RecoverPasswordInput!) {
    recoverPassword(input: $input) {
      res
    }
  }
`

const checkKeyQuery = graphql`
  query RecoverPasswordCheckFpKeyQuery($key: String!) {
    checkFpKey(key: $key)
  }
`

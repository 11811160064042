import { getUserIcon } from '@src/App'
import { FormattedPlayer } from '..'
import { useHistory } from 'react-router-dom'
import { convertToK, gameClickHandler } from '@src/utils/functions'

interface Props {
  player: FormattedPlayer
}

const WinnerItem = ({ player }: Props) => {
  const history = useHistory()

  return (
    <div className="flex flex-col rounded-4">
      <div className="w-full flex justify-center">
        <img
          alt="Game"
          src={player.normalizedGame.urlThumb}
          className="w-full h-100 object-cover rounded-4 cursor-pointer"
          onClick={() => gameClickHandler(player.normalizedGame.gameId, player.normalizedGame.provider, history)}
        />
      </div>
      <div className="flex flex-row space-x-7 mt-10">
        <div className="p-4">
          {getUserIcon(player.user.name, player.user.uuid, player.user.hasProfileImage, player.user.vip, false, '25px')}
        </div>
        <div className="flex flex-col">
          <span className="text-white font-semibold text-xs truncate">{player.user.name}</span>
          <span className="text-profitGreen font-semibold text-2xs">${convertToK(Number(player.amountWon))}</span>
        </div>
      </div>
    </div>
  )
}

export default WinnerItem

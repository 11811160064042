import { getUserIcon } from '@src/App'
import { useTranslation, Trans } from 'react-i18next'
import React from 'react'

import classes from './ChatSupport.module.scss'
import classes_old from './ChatSupport_old.module.scss'
import { toast } from 'react-toastify'
import { Money } from '@src/money'

interface Data {
  type: number
}

interface SupportAnnouncement extends Data {
  type: 1
  uuid: string
  username: string
  command: string
  amount: number
  currency: string
  has_profile_image: boolean
  vip: {
    // FIXME declare this in common place
    badge_id: string
    offset: number
    appender: number
  }
}

interface SupportClaimed extends Data {
  type: 3
  amount: string

  currency: string
}

interface SupportExpired extends Data {
  type: 2
  bg_type: string
  uuid: string
  username: string
  command: string
  amount: number
  usd_value: number
  currency: string
  has_profile_image: boolean
  vip: {
    // FIXME declare this in common place
    badge_id: string
    offset: number
    appender: number
  }
}

// interface SupportExpired extends Data {
//   type: 2
//   error_message: string
// }

const getImageUrl = (type: string) => {
  switch (type) {
    case '2':
      return '/assets/chat/support/bg-2.png'
    case '3':
      return '/assets/chat/support/bg-3.png'
    case '4':
      return '/assets/chat/support/bg-4.png'
    case '5':
      return '/assets/chat/support/bg-5.png'
    case '6':
      return '/assets/chat/support/bg-6.png'
    case '7':
      return '/assets/chat/support/bg-7.png'
    case '8':
      return '/assets/chat/support/bg-8.png'
    case '9':
      return '/assets/chat/support/bg-9.png'
    case '10':
      return '/assets/chat/support/bg-10.png'
    case '11':
      return '/assets/chat/support/bg-11.png'
    case '12':
      return '/assets/chat/support/bg-12.png'
    case '13':
      return '/assets/chat/support/bg-13.png'
    case '1':
    default:
      return '/assets/chat/support/bg-1.png'
  }
}

const getFirstMatchingStepOrValue = (value: number): number => {
  const steps = [10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000]

  let max = steps[0]

  for (let i = 0; i < steps.length; i++) {
    if (steps[i] > value) {
      break
    }

    max = steps[i]
  }

  if (value < max) {
    return value
  }

  return max
}

const formatValue = (value: number) => {
  // display numbers starting from 10000 as 10k
  if (value >= 10000) {
    return `${value / 1000}K`
  }

  return value
}

type Input = SupportAnnouncement | SupportClaimed | SupportExpired

export function ChatSupport({ data }: { data: Input }) {
  const { t } = useTranslation()

  if (data.type === 2 && data.amount && data.currency) {
    const bonus = Money.fromInteger(data.amount, data.currency)
    const value = getFirstMatchingStepOrValue(data.usd_value / 100)

    const usdt = formatValue(value)

    return (
      <div
        className={classes.root}
        style={{
          background: usdt === '100K' ? `url("assets/chat/support/bg-13.png")` : `url(${getImageUrl(data.bg_type)})`,
        }}
      >
        <div className="flex flex-col gap-y-12">
          <h6>{t('chat.chatSupport.chatcheer', 'It’s CHATCHEER time for')}</h6>
          <h3>{data.username}</h3>
        </div>
        <div className={classes.imageWrapper}>
          {getUserIcon(data.username, data.uuid, data.has_profile_image, data.vip, false)}
          {usdt === '100K' ? (
            <div className={classes.value_100K}>
              <div>100,000</div>
            </div>
          ) : (
            <div className={classes.value}>
              <div>{usdt}</div>
            </div>
          )}
        </div>
        <div className={classes.message}>
          <Trans i18nKey="chat.chatSupport.reacheInBets" values={{ usdt }}>
            REACHED {usdt} USD IN BETS
          </Trans>
        </div>
        <p>
          <Trans
            i18nKey="chat.chatSupport.typeToClaim"
            values={{
              command: data.command,
              bonus,
              currency: data.currency,
            }}
            components={{
              command: (
                <span
                  className="text-yellow font-bold hover:underline cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(data.command)
                    toast.success(t('toast.copiedToClipboard'))
                  }}
                />
              ),
              bonus: <span className={classes.bonus} />,
            }}
          />
        </p>
      </div>
    )
  }

  if (data.type === 1) {
    return (
      <div className={classes_old.supportMsg}>
        <div className={classes_old.icon}>
          {getUserIcon(data.username, data.uuid, data.has_profile_image, data.vip, false)}
        </div>
        <Trans
          i18nKey="chat.chatSupport.supportAndGetRewarded"
          values={{
            username: data.username,
          }}
          components={{
            0: <span className={classes_old.green} />,
            1: <span className={classes_old.red} />,
          }}
        />
        <span className={classes_old.white}>
          {t('chat.chatSupport.type', 'Type')} {data.command}
        </span>
      </div>
    )
  }

  if (data.type === 3) {
    return (
      <div className="pl-32">
        <p
          className="px-22 py-12 whitespace-pre-line font-inter font-medium"
          style={{
            fontSize: '13px',
            borderRadius: '7px',
            backgroundColor: 'rgba(228, 228, 228, 0.10)',
            maxWidth: 'fit-content',
          }}
        >
          <React.Fragment>
            {Money.fromInteger(data.amount, data.currency)}
            {t('chat.chatSupport.addedToBalance', { currency: data.currency })}
          </React.Fragment>
        </p>
      </div>
    )
  }

  return null
}

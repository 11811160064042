import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import CompetitionResultsHeader from '../CompetitionResultsHeader/CompetitionResultsHeader'
import CompetitionResultsRow from '../CompetitionResultsRow/CompetitionResultsRow'
import ICompetitionResults from './CompetitionResults.interface'
import styles from './CompetitionResults.module.scss'

const CompetitionResults: React.FC<ICompetitionResults> = ({ competition }) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [votes, setVotes] = useState(cloneDeep(competition.runningRound?.votes ?? []))

  if (!competition.runningRound) return null

  let competitionType
  if (competition.type === 'HIGHEST_WIN') {
    competitionType = 'WINNING AMOUNT'
  } else if (competition.type === 'GAME_COUNT') {
    competitionType = 'BETS'
  } else if (competition.type === 'HIGHEST_BET') {
    competitionType = 'BET AMOUNT'
  } else {
    competitionType = 'WAGERED'
  }

  const postsPerPage = 10
  const pages = competition.runningRound?.players
    ? Math.ceil(competition.runningRound.players.length / postsPerPage)
    : 1
  const pagePlayers = competition.runningRound?.players
    ? competition.runningRound.players.slice(
        (page - 1) * postsPerPage,
        Math.min(competition.runningRound.players.length, page * postsPerPage)
      )
    : []

  return (
    <div className={`${styles.root} overflow-hidden border border-solid border-neutral bg-eerieBlack`}>
      <CompetitionResultsHeader competition={competition} />
      <div className="overflow-auto px-30 pb-20">
        <div className="min-w-300">
          <CompetitionResultsRow i={0} competition={competition} votes={votes} setVotes={setVotes} winner />
          <div className={`${styles.tableHeader} flex mt-20 pb-5`}>
            <div className="w-16 flex-shrink-0" />
            <div className={`${competition.type === 'MULTIPLIER' ? 'w-1/4 uppercase' : 'w-3/7 uppercase'}`}>
              {t('competitions.table.game', 'Player')}
            </div>
            {competition.type === 'MULTIPLIER' ? (
              <>
                <div className="w-2/7 uppercase">{t('general.game', 'Game')}</div>
                <div
                  className="min-w-100 w-1/7 pl-8"
                  style={{
                    transform: 'translateX(-30%)',
                  }}
                >
                  {t('competitions.table.multiplier', 'Multiplier')}
                </div>
                <div
                  className="flex-shrink-0 w-2/7"
                  style={{
                    transform: 'translateX(-8%)',
                  }}
                >
                  {t('competitions.table.winAmount', 'Win Amount')}
                </div>
              </>
            ) : (
              <>
                <div className="w-2/7">{t(`competitions.table.${competitionType}`, competitionType)}</div>
                <div className="w-2/7 pl-8 uppercase">
                  {competition.type !== 'HIGHEST_WIN'
                    ? t('competitions.table.prize', 'Prize')
                    : t('competitions.table.betAmount', 'Bet amount')}
                </div>
                <div className="w-44 flex-shrink-0 uppercase">{t('competitions.table.votes', 'Votes')}</div>
              </>
            )}
          </div>
          {pagePlayers.map((x, i) => (
            <CompetitionResultsRow
              key={x.user.uuid + '_' + i}
              i={(page - 1) * postsPerPage + i}
              competition={competition}
              votes={votes}
              setVotes={setVotes}
              winner={false}
            />
          ))}
          {pages > 1 && (
            <div className="mt-30 flex justify-center">
              {Array.from(Array(pages).keys()).map((p, i) => (
                <button
                  key={i}
                  type="button"
                  onClick={() => setPage(p + 1)}
                  className={`${styles.paginationPage} ${
                    page === p + 1 ? 'text-darkGrey' : 'text-white'
                  } mr-4 last:m-0`}
                >
                  {p + 1}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default CompetitionResults

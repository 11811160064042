import { Environment, FetchFunction, Network, RecordSource, RequestParameters, Store, Variables } from 'relay-runtime'
import { Socket as PhoenixSocket } from 'phoenix'
import * as AbsintheSocket from '@absinthe/socket'
// @ts-ignore
import { createSubscriber } from '@absinthe/socket-relay'
import { Observable } from 'relay-runtime'
import { Subject } from 'rxjs'

interface ErrorResponse extends Response {
  json: any
}

export class GraphQLError extends Error {
  constructor(public response?: ErrorResponse, public status?: number) {
    super()
  }
}

export const userChanged = new Subject<void>()
const baseUrl = window.location.origin
const apiUrl = baseUrl + '/api'
const baseWsUrl = baseUrl.replace(/^http/, 'ws')
const noAuthQueries = ['query ConfigurationServiceGlobalSettingsQuery', 'mutation SignInComponentAnonMutation']

const fetchGraphQL = async (query: string | undefined | null, variables: any) => {
  const token = localStorage.getItem('token')
  const final = token ? `Bearer ${token}` : ''
  const addToken = noAuthQueries.findIndex((x) => query?.startsWith(x)) < 0

  try {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json; charset=utf-8',
    }
    if (addToken) {
      headers['Authorization'] = final
      headers['x-authorization'] = final
    }

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const body = await response.json()
    if (response.ok) {
      if (undefined !== body.errors) {
        throw new GraphQLError(Object.assign(response, { json: body }), response.status)
      }

      return body
    } else {
      console.log({ response })
      throw new GraphQLError(Object.assign(response, { json: body }), response.status)
    }
  } catch (err) {
    if (err instanceof GraphQLError) {
      throw err
    }
    throw new GraphQLError(undefined, 500)
  }
}

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
const fetchRelay: FetchFunction = async (params, variables) => {
  return fetchGraphQL(params.text, variables)
}

let _socket: any = undefined
let abs: AbsintheSocket.AbsintheSocket<{}> | undefined = undefined
const getSubscriber = () => {
  if (_socket) {
    return _socket
  }
  const token = localStorage.getItem('token')
  abs = AbsintheSocket.create(new PhoenixSocket(baseWsUrl + '/socket', { params: { token: token } }))
  _socket = createSubscriber(abs)
  return _socket
}

userChanged.subscribe(() => {
  if (undefined !== abs) {
    _socket = undefined
    abs.phoenixSocket.disconnect()
  }
})

const subscribe = (request: RequestParameters, variables: Variables, cacheConfig: any): any => {
  return Observable.create((sink) => {
    getSubscriber()(request, variables, cacheConfig, {
      onNext: sink.next,
      onError: sink.error,
      onCompleted: sink.complete,
    })
  })
}

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
  network: Network.create(fetchRelay, subscribe),
  store: new Store(new RecordSource()),
})

/**
 * @generated SignedSource<<18a39ed9941c714a122be440de1e2fd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SettingsComponentWebauthnLoginStartQuery$variables = {};
export type SettingsComponentWebauthnLoginStartQuery$data = {
  readonly webauthnLoginStart: {
    readonly challenge: string;
    readonly rpId: string;
    readonly sessionId: string;
  };
};
export type SettingsComponentWebauthnLoginStartQuery = {
  variables: SettingsComponentWebauthnLoginStartQuery$variables;
  response: SettingsComponentWebauthnLoginStartQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WebauthnLoginChallenge",
    "kind": "LinkedField",
    "name": "webauthnLoginStart",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "challenge",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rpId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sessionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsComponentWebauthnLoginStartQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsComponentWebauthnLoginStartQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5e1a77f6abbe62208eda915f4b01df02",
    "id": null,
    "metadata": {},
    "name": "SettingsComponentWebauthnLoginStartQuery",
    "operationKind": "query",
    "text": "query SettingsComponentWebauthnLoginStartQuery {\n  webauthnLoginStart {\n    challenge\n    rpId\n    sessionId\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e99e993ab564bf395bfe732d3704dda";

export default node;

/**
 * @generated SignedSource<<77ec67f5b69c351e9b65a5b2621ac2d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PlayerProfileNewProfileQuery$variables = {
  uuid: string;
};
export type PlayerProfileNewProfileQuery$data = {
  readonly profile: {
    readonly userStats: ReadonlyArray<{
      readonly name: string;
      readonly value: number;
      readonly meta: any | null;
    }>;
    readonly profileBadge: string | null;
    readonly name: string;
    readonly insertedAt: string;
    readonly tournamentWon: number | null;
    readonly higestWin: number | null;
    readonly higestBet: number | null;
    readonly vip: {
      readonly badgeId: string | null;
      readonly offset: number | null;
      readonly avatar: string | null;
    } | null;
    readonly hasProfileImage: boolean;
    readonly followers: number;
    readonly votes: number;
    readonly isUserFollowed: boolean;
    readonly following: number;
    readonly recentGames: ReadonlyArray<string>;
    readonly recentWins: ReadonlyArray<{
      readonly gameProvider: string;
      readonly multiplier: number;
      readonly btcBet: number | null;
      readonly btcWin: number | null;
      readonly bets: ReadonlyArray<number>;
      readonly win: number | null;
      readonly insertedAt: string;
    }>;
  };
};
export type PlayerProfileNewProfileQuery = {
  variables: PlayerProfileNewProfileQuery$variables;
  response: PlayerProfileNewProfileQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "concreteType": "UserProfile",
    "kind": "LinkedField",
    "name": "profile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserStat",
        "kind": "LinkedField",
        "name": "userStats",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileBadge",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tournamentWon",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "higestWin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "higestBet",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Vip",
        "kind": "LinkedField",
        "name": "vip",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "badgeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "offset",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasProfileImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "followers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "votes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isUserFollowed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "following",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recentGames",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GameBet",
        "kind": "LinkedField",
        "name": "recentWins",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameProvider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multiplier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "btcBet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "btcWin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "win",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlayerProfileNewProfileQuery",
    "selections": (v3/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlayerProfileNewProfileQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ec61c353df9c40d232b36dcd7b9b50f7",
    "id": null,
    "metadata": {},
    "name": "PlayerProfileNewProfileQuery",
    "operationKind": "query",
    "text": "query PlayerProfileNewProfileQuery(\n  $uuid: String!\n) {\n  profile(uuid: $uuid) {\n    userStats {\n      name\n      value\n      meta\n    }\n    profileBadge\n    name\n    insertedAt\n    tournamentWon\n    higestWin\n    higestBet\n    vip {\n      badgeId\n      offset\n      avatar\n    }\n    hasProfileImage\n    followers\n    votes\n    isUserFollowed\n    following\n    recentGames\n    recentWins {\n      gameProvider\n      multiplier\n      btcBet\n      btcWin\n      bets\n      win\n      insertedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "213df46a63264565ddc03bc2318458a7";

export default node;

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import { List, AutoSizer } from 'react-virtualized'
import useIsMobile from '../../../hooks/IsMobile'
import type { ExtendedGame, Game } from '@components/gameListComponent/GameListComponent.types'
import { getGameBackgroundURL, openGame, ourGames, OutsideAlerter } from '../../helper'
import Search from '../../icons/search'

import Env from '@src/RelayEnvironment'

import './GlobalSearchComponent.scoped.scss'
import { graphql } from 'babel-plugin-relay/macro'
import { GlobalSearchComponentNormalizedGamesQuery } from './__generated__/GlobalSearchComponentNormalizedGamesQuery.graphql'
import { fetchQuery } from 'relay-runtime'
import { useLockScroll } from '@src/hooks/useLockScroll'
import { useTranslation } from 'react-i18next'

export const GlobalSearchComponent = () => {
  const [games, setGames] = useState<Game[]>([])
  const [searchWord, setSearchWord] = useState('')

  const history = useHistory()
  const { t } = useTranslation()
  const isMd = !useIsMobile()
  const isLg = !useIsMobile('lg')

  useLockScroll(searchWord !== '' && searchWord.length > 1)

  useEffect(() => {
    if (searchWord.length < 2) return

    fetchQuery<GlobalSearchComponentNormalizedGamesQuery>(
      Env,
      listNormalizedGames,
      {
        page: 1,
        pageSize: 999,
        filter: {
          keyword: searchWord,
          aggregator: ['HUB88', 'ENDORPHINA', 'BJFUN'],
        },
      },
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: (data) => {
        const { listNormalizedGames } = data

        setGames([...listNormalizedGames.entries])
      },
    })
  }, [searchWord])

  const click = (game) => {
    setSearchWord('')
    openGame(history, game)
  }

  let searchResult: JSX.Element | null = null

  if (searchWord.length > 0) {
    const passedGames = [...ourGames, ...games].filter((x) => {
      const game = x as unknown as ExtendedGame

      if (!game?.provider) return false

      const label = game.label ?? (game?.title || '')

      return label.toLowerCase().indexOf(searchWord) >= 0 || game.provider.toLowerCase().indexOf(searchWord) >= 0
    })

    if (passedGames.length > 0) {
      let columnCount = 1
      if (isMd && !isLg) {
        columnCount = 2
      } else if (isLg) {
        columnCount = 3
      }

      const rowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it)
      }: {
        key: string
        index: number
        isScrolling: boolean
        isVisible: boolean
        style: React.CSSProperties
      }) => {
        const games = passedGames.slice(index * columnCount, (index + 1) * columnCount)
        const h = games.map((g, idx) => {
          const game = g as unknown as Game

          return (
            <div onClick={() => click(game)} key={'global_game_' + idx} className="flex cursor-pointer">
              <div className="flex-shrink-0 w-80 mr-20">
                <img
                  alt={game.label}
                  src={game.urlThumb || getGameBackgroundURL(game)}
                  className="w-full aspect-square object-cover object-top rounded-12"
                />
              </div>
              <div className="text-white">
                <div className="typo-caption mb-2">{game.label}</div>
                <div className="typo-caption-medium text-darkGrey mb-13">{game.category}</div>
                <div className="typo-caption">{game.provider}</div>
              </div>
            </div>
          )
        })
        return (
          <div key={key} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-32 mb-10" style={style}>
            {h}
          </div>
        )
      }

      document.getElementById('root')?.classList.add('darken')
      searchResult = (
        <OutsideAlerter callback={() => setSearchWord('')}>
          <div className="global-search-box flex flex-col">
            <div className="top flex justify-between">
              <span>{t('globalSearchComponent.searchResult', 'Search result')}</span>
              <span>
                {t('globalSearchComponent.result', {
                  count: passedGames.length > 99 ? 99 : passedGames.length,
                })}
              </span>
            </div>
            <hr className="text-neutral mb-24" />
            <div className="global-search-container-wrapper">
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height}
                    rowCount={Math.ceil(passedGames.length / columnCount)}
                    rowHeight={90}
                    overscanColumnCount={10}
                    rowRenderer={rowRenderer}
                  />
                )}
              </AutoSizer>
            </div>
          </div>
        </OutsideAlerter>
      )
    } else {
      document.getElementById('root')?.classList.remove('darken')
    }
  } else {
    document.getElementById('root')?.classList.remove('darken')
  }

  return (
    <React.Fragment>
      <div className="relative">
        <input
          onChange={(e) => setSearchWord(e.target.value.toLowerCase())}
          placeholder={t('globalSearchComponent.search', 'Search')}
          type="text"
          value={searchWord}
          className="globalSearchInput"
        />
        <Search className="absolute top-1/2 right-16 transform -translate-y-1/2 w-20 h-20 md:w-24 md:h-24 text-white pointer-events-none" />
      </div>

      {searchResult ? ReactDOM.createPortal(searchResult, document.body) : null}
    </React.Fragment>
  )
}

const listNormalizedGames = graphql`
  query GlobalSearchComponentNormalizedGamesQuery($filter: ListNormalizedGamesFilter!, $page: Int!, $pageSize: Int) {
    listNormalizedGames(filter: $filter, page: $page, pageSize: $pageSize) {
      entries {
        urlThumb
        aggregator
        gameId
        category
        provider
        label
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
`

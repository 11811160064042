export function HeartShape({ className }: { className?: string }) {
  return (
    <svg
      width="122"
      height="89"
      viewBox="0 0 122 89"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M43.915 65.729H20.4189V70.7883H43.915V65.729Z" fill="#02E5FF" />
      <path
        d="M62.3101 84.6792H76.2168V81.6851H62.3101V84.6792Z"
        fill="#FF03F9"
      />
      <path
        d="M87.6378 51.2722H108.394V49.7778H87.6378V51.2722Z"
        fill="#FF03F9"
      />
      <path
        d="M102.225 59.3723H78.7285V64.4317H102.225V59.3723Z"
        fill="#FF03F9"
      />
      <path
        d="M105.468 33.6189H120.506V30.6248H105.468V33.6189Z"
        fill="#FF03F9"
      />
      <path
        d="M121.19 18.7886H97.6943V22.8153H121.19V18.7886Z"
        fill="#FF03F9"
      />
      <path d="M23.4961 36.9712H0V40.9979H23.4961V36.9712Z" fill="#02E5FF" />
      <path
        d="M7.69499 12.0688L33.6455 12.0688V9.07476L7.69499 9.07476V12.0688Z"
        fill="#02E5FF"
      />
      <path
        d="M15.3752 46.052H10.9282C14.8148 52.3048 20.1596 57.7118 25.9661 62.7348H30.4132C24.6066 57.7118 19.2619 52.2996 15.3752 46.052Z"
        fill="#02E5FF"
      />
      <path
        d="M10.6325 35.5389C8.65547 28.6997 9.46496 21.0874 13.2582 14.4039C14.3842 12.4217 15.7334 10.6626 17.2278 9.07471H12.7808C11.2863 10.6626 9.93717 12.4268 8.81114 14.4039C5.01793 21.0874 4.20844 28.6997 6.18547 35.5389C6.73033 37.4278 7.42566 39.2387 8.22478 40.9926H12.6718C11.8727 39.2387 11.1774 37.4278 10.6325 35.5389Z"
        fill="#02E5FF"
      />
      <path
        d="M33.9729 65.7288H29.5259C38.8143 73.3567 48.7358 80.2737 55.4868 88.2026H59.9338C58.8649 86.978 57.744 85.7793 56.5817 84.591C50.1213 78.1202 41.8292 72.1788 33.9729 65.7288Z"
        fill="#02E5FF"
      />
      <path
        d="M62.1545 9.10583C64.0174 7.11841 66.176 5.35413 68.672 3.94271C72.3666 1.84632 76.3362 0.720298 80.3059 0.419332C74.8988 -0.0424945 69.305 1.05759 64.2249 3.94271C62.663 4.83004 61.2464 5.86266 59.9336 6.98869C60.7171 7.65808 61.4592 8.36379 62.1545 9.10583Z"
        fill="#02E5FF"
      />
      <path
        d="M30.055 1.31702C31.7051 0.860378 33.402 0.564602 35.1092 0.419308C31.8919 0.180611 28.6799 0.471199 25.608 1.31702C22.1676 2.26662 18.9089 3.87523 16.0342 6.08058H20.4812C23.356 3.87004 26.6147 2.26662 30.055 1.31702Z"
        fill="#02E5FF"
      />
      <path
        d="M69.7466 78.6962H74.1936C84.8675 69.6725 97.9076 61.0846 106.381 49.7776H101.934C93.4606 61.0846 80.4205 69.6725 69.7466 78.6962Z"
        fill="#FF03F9"
      />
      <path
        d="M109.235 35.5388C107.891 40.1934 105.722 44.3965 103.008 48.278H107.455C110.164 44.3913 112.333 40.1882 113.677 35.5388C114.138 33.9354 114.434 32.2904 114.59 30.6248H110.143C109.987 32.2904 109.691 33.9354 109.23 35.5388H109.235Z"
        fill="#FF03F9"
      />
      <path
        d="M59.9336 88.2025H64.3806C66.285 85.9608 68.454 83.8073 70.7787 81.6902H66.3317C64.007 83.8073 61.838 85.9608 59.9336 88.2025Z"
        fill="#FF03F9"
      />
      <path
        d="M106.179 13.7293H110.626C106.807 7.42973 100.876 3.14357 94.2546 1.31702C91.1827 0.471199 87.9654 0.180611 84.7534 0.419308C86.4658 0.564602 88.1574 0.860378 89.8076 1.31702C96.434 3.14357 102.365 7.42973 106.179 13.7293Z"
        fill="#FF03F9"
      />
      <path
        d="M59.9336 11.7625C60.6082 10.8337 61.3606 9.95673 62.1545 9.11091C61.4592 8.36888 60.7172 7.66317 59.9336 6.99378C58.6208 5.86775 57.199 4.83512 55.6371 3.94779C50.5622 1.06786 44.9632 -0.0374073 39.5562 0.424419C43.5258 0.720196 47.4954 1.85141 51.19 3.94779C54.7445 5.96634 57.6712 8.65947 59.9284 11.7677L59.9336 11.7625Z"
        fill="#FF03F9"
      />
      <path
        d="M110.273 27.6359H114.72C114.663 24.6678 114.092 21.6841 113.049 18.7886H108.602C109.65 21.6841 110.221 24.6678 110.273 27.6359Z"
        fill="#FF03F9"
      />
      <path
        d="M56.5815 84.5911C57.7439 85.7794 58.8647 86.978 59.9337 88.2027C58.8907 86.978 57.7646 85.7794 56.5815 84.5911Z"
        fill="#FF03F9"
      />
      <path
        d="M66.3265 81.6904H62.7097V78.6963H69.7461C80.42 69.6725 93.4601 61.0846 101.934 49.7777H83.4919V48.2832H103.008C105.717 44.3966 107.886 40.1935 109.235 35.544C109.697 33.9406 109.992 32.2957 110.148 30.63H106.132V27.6359H110.273C110.216 24.6678 109.645 21.6841 108.602 18.7886H102.977V13.7292H106.184C102.365 7.42969 96.4334 3.14353 89.8122 1.31698C88.1621 0.860338 86.4652 0.564561 84.758 0.419268C83.2844 0.29473 81.8003 0.310297 80.311 0.419268C76.3414 0.715044 72.3717 1.84626 68.6771 3.94264C66.1864 5.35926 64.0225 7.11835 62.1597 9.10576C61.3657 9.95158 60.6133 10.8285 59.9387 11.7574C57.6815 8.64912 54.7549 5.956 51.2004 3.93745C47.5057 1.84107 43.5361 0.715044 39.5665 0.414079C38.0772 0.305108 36.5931 0.289541 35.1194 0.414079C33.4071 0.559372 31.7154 0.855149 30.0653 1.31179C26.625 2.26139 23.3662 3.87 20.4915 6.07535H25.364V9.06944H17.2275C15.7331 10.6573 14.3839 12.4216 13.2579 14.3986C9.46471 21.0821 8.65522 28.6945 10.6323 35.5337C11.1771 37.4225 11.8724 39.2335 12.6715 40.9874H20.6731V46.0467H15.3802C19.2669 52.2995 24.6116 57.7065 30.4182 62.7296H35.3322V65.7236H33.9727C41.8289 72.1737 50.121 78.1151 56.5814 84.5859C57.7645 85.769 58.8906 86.9677 59.9336 88.1975C61.8379 85.9558 64.007 83.8024 66.3317 81.6852L66.3265 81.6904Z"
        fill="black"
      />
      <path
        d="M111.383 49.7776H109.889V51.272H111.383V49.7776Z"
        fill="#FF03F9"
      />
      <path
        d="M22.0793 68.7229H44.5791V65.7288H22.0793V68.7229Z"
        fill="black"
      />
      <path
        d="M95.5095 21.7827L118.009 21.7827V18.7886L95.5095 18.7886V21.7827Z"
        fill="black"
      />
      <path
        d="M77.3537 60.8667H99.8535V57.8726H77.3537V60.8667Z"
        fill="black"
      />
      <path
        d="M4.16184 40.9927H28.7217V37.9986H4.16184V40.9927Z"
        fill="black"
      />
      <path
        d="M11.1981 68.7229H15.9668V65.7288H11.1981V68.7229Z"
        fill="#02E5FF"
      />
      <path
        d="M14.1609 68.7229H18.9297V65.7288H14.1609V68.7229Z"
        fill="#FF03F9"
      />
      <path d="M12.677 68.7229H17.4458V65.7288H12.677V68.7229Z" fill="black" />
      <path d="M41.2788 62.74H38.2847V65.7341H41.2788V62.74Z" fill="#02E5FF" />
      <path d="M45.7625 62.74H44.2681V65.7341H45.7625V62.74Z" fill="#FF03F9" />
      <path
        d="M31.3369 6.08057H28.3428V9.07466H31.3369V6.08057Z"
        fill="#02E5FF"
      />
      <path
        d="M79.2059 81.6904H77.7114V84.6845H79.2059V81.6904Z"
        fill="#FF03F9"
      />
      <path
        d="M4.70623 12.0688H6.20068V9.07476H4.70623V12.0688Z"
        fill="#02E5FF"
      />
      <path
        d="M1.71258 12.0688H3.20703L3.20703 9.07476H1.71258L1.71258 12.0688Z"
        fill="#02E5FF"
      />
      <path
        d="M100.128 30.625H103.122V27.6309H100.128V30.625Z"
        fill="#FF03F9"
      />
      <path
        d="M22.3442 19.2661H19.3501V22.2602H22.3442V19.2661Z"
        fill="#02E5FF"
      />
      <path
        d="M71.5107 67.8875H68.5166V70.8815H71.5107V67.8875Z"
        fill="#FF03F9"
      />
      <path
        d="M77.9502 12.2349H74.9561V15.229H77.9502V12.2349Z"
        fill="#FF03F9"
      />
      <path d="M77.504 49.7776H80.498V48.2831H77.504V49.7776Z" fill="#FF03F9" />
      <path
        d="M73.0202 49.7776H74.5146V48.2831H73.0202V49.7776Z"
        fill="#02E5FF"
      />
      <path
        d="M68.5169 64.8936H67.0225V66.388H68.5169V64.8936Z"
        fill="#02E5FF"
      />
      <path
        d="M25.3333 23.7544H23.8389V25.2488H25.3333V23.7544Z"
        fill="#FF03F9"
      />
    </svg>
  )
}

import { SignInComponentMeQuery } from '@src/components/__generated__/SignInComponentMeQuery.graphql'
import { store } from '@src/store'
import { useEffect } from 'react'
import { commitMutation, fetchQuery } from 'react-relay'

import Env, { GraphQLError } from '../../RelayEnvironment'
import { generateRandomString, makeAnonymousUser, meQuery } from '@src/components/SignInComponent'
import ConfigurationService from '@src/service/ConfigurationService'
import { AuthAction, User, UserConstant } from '@src/reducers/authentication.reducer'
import { refProps } from '@src/index'
import { SignInComponentAnonMutation } from '@src/components/__generated__/SignInComponentAnonMutation.graphql'

const fetchGlobalSettings = async () => {
  return await ConfigurationService.instance.load()
}

export function useLogin() {
  const loginAnon = (globalSettings$: Promise<void>) => {
    let storedUuid = localStorage.getItem('anonymous_hash')
    if (!storedUuid) {
      storedUuid = generateRandomString(25)
      localStorage.setItem('anonymous_hash', storedUuid)
    }

    const referralUuid = localStorage.getItem('referral_uuid')

    if (referralUuid) {
      refProps.push({
        key: 'affiliate_uuid',
        value: referralUuid,
      })
    }

    commitMutation<SignInComponentAnonMutation>(Env, {
      mutation: makeAnonymousUser,
      variables: {
        input: {
          refProps,
          uuid: storedUuid,
        },
      },
      onCompleted: async (data) => {
        const token = data.getAnonymousUser?.token.trim()
        const user = data.getAnonymousUser?.user
        if (null == token || null == user) {
          localStorage.clear()
          window.location.href = '/'
          return
        }
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
        await globalSettings$
        store.dispatch<AuthAction>({ type: UserConstant.LOGIN_SUCCESS, user: user as unknown as User, token })
      },
      onError: (_err) => {
        console.log({ _err })
        store.dispatch<AuthAction>({ type: UserConstant.LOGIN_FAILURE })
      },
    })
  }

  // useEffect(() => {
  //   const state = store.getState().authentication
  //   // If we have token, try to log in
  //   if (state.error) {
  //     return
  //   }

  //   if (state.token) {
  //     fetchQuery<SignInComponentMeQuery>(Env, meQuery, {}).subscribe({
  //       next: async (data) => {
  //         if (data.me) {
  //           localStorage.setItem('user', JSON.stringify(data.me))
  //           if (data.me.anonymousHash) {
  //             localStorage.setItem('anonymous_hash', data.me.anonymousHash)
  //           }
  //           const globalSettings$ = fetchGlobalSettings()
  //           await globalSettings$
  //           store.dispatch<AuthAction>({
  //             type: UserConstant.LOGIN_SUCCESS,
  //             user: data.me as unknown as User,
  //             token: state.token,
  //           })
  //         } else {
  //           store.dispatch<AuthAction>({ type: UserConstant.LOGIN_FAILURE })
  //           localStorage.clear()
  //           window.location.href = '/'
  //         }
  //       },
  //       error: (e: GraphQLError) => {
  //         if (e.status === 200) {
  //           const errors = e.response?.json?.errors
  //           if (errors?.length > 0) {
  //             const message = errors[0].message
  //             if (message === 'banned') {
  //               localStorage.clear()
  //               window.location.href = '/'
  //               return
  //             }
  //           }
  //         }
  //         try {
  //           const globalSettings$ = fetchGlobalSettings()
  //           loginAnon(globalSettings$)
  //         } catch (e) {
  //           localStorage.clear()
  //           window.location.href = '/'
  //         }
  //       },
  //     })
  //   }
  //   // Make anon login
  //   else {
  //     try {
  //       const globalSettings$ = fetchGlobalSettings()
  //       loginAnon(globalSettings$)
  //     } catch (e) {
  //       localStorage.clear()
  //       window.location.href = '/'
  //     }
  //   }
  // }, [])

  const fetchUser = async () => {
    return new Promise((resolve, reject) => {
      const state = store.getState().authentication
      // If we have token, try to log in
      if (state.error) {
        return reject(state.error)
      }

      if (state.token) {
        fetchQuery<SignInComponentMeQuery>(Env, meQuery, {}).subscribe({
          next: async (data) => {
            if (data.me) {
              localStorage.setItem('user', JSON.stringify(data.me))
              if (data.me.anonymousHash) {
                localStorage.setItem('anonymous_hash', data.me.anonymousHash)
              }
              const globalSettings$ = fetchGlobalSettings()
              await globalSettings$
              store.dispatch<AuthAction>({
                type: UserConstant.LOGIN_SUCCESS,
                user: data.me as unknown as User,
                token: state.token,
              })

              return resolve(data.me)
            } else {
              store.dispatch<AuthAction>({ type: UserConstant.LOGIN_FAILURE })
              localStorage.clear()
              window.location.href = '/'
            }
          },
          error: (e: GraphQLError) => {
            if (e.status === 200) {
              const errors = e.response?.json?.errors
              if (errors?.length > 0) {
                const message = errors[0].message
                if (message === 'banned') {
                  localStorage.clear()
                  window.location.href = '/'
                  return reject(e)
                }
              }
            }
            try {
              const globalSettings$ = fetchGlobalSettings()
              loginAnon(globalSettings$)
            } catch (e) {
              localStorage.clear()
              window.location.href = '/'
            }

            return reject(e)
          },
        })
      }
      // Make anon login
      else {
        try {
          const globalSettings$ = fetchGlobalSettings()
          loginAnon(globalSettings$)
          return resolve(null)
        } catch (e) {
          localStorage.clear()
          window.location.href = '/'
          return reject(e)
        }
      }
    })
  }

  return { fetchUser }
}

export function SpadeShape({ className }: { className?: string }) {
  return (
    <svg
      width="120"
      height="103"
      viewBox="0 0 120 103"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.3411 102.119H81.457V100.382H57.3411V102.119Z"
        fill="#FF03F9"
      />
      <path
        d="M115.117 52.8381H95.04V57.5166H115.117V52.8381Z"
        fill="#FF03F9"
      />
      <path
        d="M86.3764 71.0938H116.527V67.615H86.3764V71.0938Z"
        fill="#FF03F9"
      />
      <path
        d="M31.0063 72.7878H4.67773V78.6661H31.0063V72.7878Z"
        fill="#02E5FF"
      />
      <path
        d="M0.457479 67.3015H16.9829V63.8228H0.457479V67.3015Z"
        fill="#02E5FF"
      />
      <path
        d="M31.4645 48.2681H7.34863V50.0044H31.4645V48.2681Z"
        fill="#02E5FF"
      />
      <path
        d="M28.2631 23.748L58.4141 23.748V20.2693L28.2631 20.2693V23.748Z"
        fill="#02E5FF"
      />
      <path
        d="M93.2738 27.4922H73.1973V32.6349H93.2738V27.4922Z"
        fill="#FF03F9"
      />
      <path
        d="M77.0497 24.0134H82.2165C76.5614 18.298 70.7856 10.9607 65.245 0H60.0781C61.3322 2.41762 62.5621 4.64231 63.7799 6.73437C68.163 14.1018 72.6425 19.558 77.0437 24.0134H77.0497Z"
        fill="#FF03F9"
      />
      <path
        d="M101.72 46.9595H106.887C100.882 39.2907 93.5507 34.5338 85.8397 27.4919H80.6729C88.3839 34.5338 95.7152 39.2967 101.72 46.9595Z"
        fill="#FF03F9"
      />
      <path
        d="M108.322 64.1422H113.489C113.543 60.133 112.633 56.2443 110.794 52.8379H105.627C107.466 56.2503 108.376 60.133 108.322 64.1422Z"
        fill="#FF03F9"
      />
      <path
        d="M65.9868 87.0585C68.0728 92.5087 70.5327 96.2527 72.4861 98.6402H77.6529C75.9648 96.5783 73.9089 93.5095 72.0339 89.2109C71.4008 89.0722 70.7678 88.9396 70.1408 88.7466C68.6757 88.3125 67.3072 87.7157 65.9868 87.0525V87.0585Z"
        fill="#FF03F9"
      />
      <path
        d="M107.201 71.5035C105.181 78.2861 100.599 83.6097 94.8294 86.7327C91.8873 88.3244 88.6316 89.3312 85.2554 89.6809C90.4584 90.2054 95.577 89.1202 99.9962 86.7327C105.766 83.6097 110.348 78.2861 112.368 71.5035C112.754 70.2193 113.007 68.9171 113.2 67.6208H108.033C107.84 68.9171 107.587 70.2193 107.201 71.5035Z"
        fill="#FF03F9"
      />
      <path
        d="M75.9462 102.119H81.113C81.113 102.119 80.3534 101.582 79.1898 100.383H74.0229C75.1865 101.582 75.9462 102.119 75.9462 102.119Z"
        fill="#FF03F9"
      />
      <path
        d="M64.3711 82.1748C64.8715 83.9413 65.4141 85.557 65.9869 87.0583C67.3072 87.7215 68.6758 88.3183 70.1408 88.7524C70.7678 88.9393 71.4009 89.078 72.0339 89.2166C74.7409 89.8075 77.448 89.9582 80.0826 89.6869C78.491 89.5241 76.8933 89.2287 75.3077 88.7584C71.009 87.4803 67.3072 85.1652 64.3711 82.1808V82.1748Z"
        fill="#02E5FF"
      />
      <path
        d="M60.0781 0H54.9113C51.4506 6.84892 47.8996 12.281 44.3364 16.7907H49.5033C53.0604 12.281 56.6175 6.84892 60.0781 0Z"
        fill="#02E5FF"
      />
      <path
        d="M12.9557 71.5038C12.1961 68.9595 11.8162 66.3671 11.8162 63.8289H6.64941C6.64941 66.3671 7.02924 68.9595 7.78889 71.5038C8.57266 74.1264 9.74228 76.5199 11.2073 78.6662H16.3742C14.9091 76.5259 13.7335 74.1264 12.9557 71.5038Z"
        fill="#02E5FF"
      />
      <path
        d="M46.6032 20.2695H41.4364C31.3439 31.7427 21.4021 36.8131 13.6187 46.5257H18.7855C26.5689 36.8131 36.5107 31.7427 46.6032 20.2695Z"
        fill="#02E5FF"
      />
      <path
        d="M55.7854 82.1748C53.8501 84.1403 51.5531 85.7741 49.0028 87.0583C44.7946 98.0672 39.043 102.119 39.043 102.119H44.2098C44.2098 102.119 51.6375 96.8976 55.7915 82.1748H55.7854Z"
        fill="#02E5FF"
      />
      <path
        d="M14.9756 51.9877C15.7654 50.6674 16.5974 49.4374 17.4535 48.2678H12.2867C11.4306 49.4374 10.6046 50.6674 9.80878 51.9877C8.28345 54.5259 7.34293 57.3716 6.9209 60.3499H12.0877C12.5098 57.3716 13.4503 54.5259 14.9756 51.9877Z"
        fill="#02E5FF"
      />
      <path
        d="M21.9871 84.5383H16.8203C17.8814 85.3462 18.9907 86.0938 20.1604 86.7329C24.5796 89.1204 29.6982 90.2056 34.9012 89.681C31.519 89.3314 28.2693 88.3245 25.3272 86.7329C24.1576 86.0998 23.0482 85.3522 21.9811 84.5383H21.9871Z"
        fill="#02E5FF"
      />
      <path
        d="M60.0781 0C61.302 2.42365 62.5379 4.64834 63.7799 6.73437C62.5621 4.64231 61.3322 2.41762 60.0781 0Z"
        fill="#02E5FF"
      />
      <path
        d="M107.2 71.5037C107.586 70.2195 107.839 68.9173 108.032 67.621H100.749V64.1423H108.328C108.382 60.133 107.472 56.2444 105.633 52.838H99.4592V46.9597H101.726C95.7212 39.2909 88.39 34.534 80.6789 27.4921H72.3167V24.0134H77.0495C72.6423 19.564 68.1628 14.1078 63.7857 6.73437C62.5438 4.64231 61.3078 2.41762 60.0839 0C56.6233 6.84892 53.0722 12.281 49.5091 16.7907H51.9026V20.2694H46.6031C36.5106 31.7426 26.5688 36.813 18.7854 46.5256H22.6138V48.262H17.453C16.5969 49.4316 15.7709 50.6615 14.9751 51.9819C13.4498 54.5201 12.5093 57.3657 12.0872 60.344H14.7219V63.8228H11.8159C11.8159 66.361 12.1957 68.9534 12.9554 71.4977C13.7392 74.1203 14.9088 76.5138 16.3738 78.6601H24.9772V84.5383H21.9868C23.0479 85.3462 24.1572 86.0938 25.3329 86.7329C28.275 88.3245 31.5307 89.3314 34.9069 89.6811C38.1565 90.0187 41.5207 89.7413 44.8487 88.7526C46.3137 88.3185 47.6823 87.7216 49.0027 87.0584C51.5529 85.7803 53.85 84.1465 55.7853 82.175C51.6313 96.8978 44.2036 102.119 44.2036 102.119H75.9462C75.9462 102.119 75.1865 101.582 74.0229 100.382H68.9827V98.6461H72.4855C70.5322 96.2526 68.0723 92.5147 65.9863 87.0645C65.4135 85.5633 64.8649 83.9475 64.3705 82.181C67.3066 85.1654 71.0084 87.4805 75.3071 88.7586C76.8927 89.2349 78.4964 89.5303 80.0821 89.6871C81.8184 89.8619 83.5487 89.8619 85.2489 89.6871C88.6311 89.3374 91.8808 88.3306 94.8229 86.7389C100.593 83.6159 105.175 78.2923 107.194 71.5097L107.2 71.5037Z"
        fill="black"
      />
      <path d="M7.62592 78.666H33.7676V75.1873H7.62592V78.666Z" fill="black" />
      <path
        d="M87.3652 56.3167H113.507V52.8379H87.3652V56.3167Z"
        fill="black"
      />
      <path
        d="M65.0341 30.9768L91.1758 30.9768V27.4981L65.0341 27.4981V30.9768Z"
        fill="black"
      />
      <path
        d="M96.2518 30.9768H101.792V27.4981H96.2518V30.9768Z"
        fill="#02E5FF"
      />
      <path
        d="M99.6947 30.9768H105.235V27.4981H99.6947V30.9768Z"
        fill="#FF03F9"
      />
      <path
        d="M97.9701 30.9768H103.511V27.4981H97.9701V30.9768Z"
        fill="black"
      />
      <path
        d="M65.2933 27.4922H68.772V24.0135H65.2933V27.4922Z"
        fill="#FF03F9"
      />
      <path
        d="M58.8537 16.7908H55.375V20.2695H58.8537V16.7908Z"
        fill="#02E5FF"
      />
      <path
        d="M64.063 16.7908H62.3267V20.2695H64.063V16.7908Z"
        fill="#FF03F9"
      />
      <path
        d="M21.6677 60.3501H18.189V63.8288H21.6677V60.3501Z"
        fill="#02E5FF"
      />
      <path
        d="M26.8823 60.3501H25.146V63.8288H26.8823V60.3501Z"
        fill="#FF03F9"
      />
      <path
        d="M93.7498 67.6211H97.2285V64.1424H93.7498V67.6211Z"
        fill="#FF03F9"
      />
      <path
        d="M88.5049 67.6211H90.2412V64.1424H88.5049V67.6211Z"
        fill="#02E5FF"
      />
      <path d="M120 67.6211H118.264V71.0998H120V67.6211Z" fill="#FF03F9" />
      <path
        d="M24.7783 23.748H26.5146V20.2693H24.7783V23.748Z"
        fill="#02E5FF"
      />
      <path d="M21.3056 23.748H23.042V20.2693H21.3056V23.748Z" fill="#02E5FF" />
      <path
        d="M38.0905 78.666H34.6118V82.1447H38.0905V78.666Z"
        fill="#02E5FF"
      />
      <path d="M59.8068 46.96H56.3281V50.4387H59.8068V46.96Z" fill="#FF03F9" />
      <path
        d="M62.031 100.383H65.5098V98.6462H62.031V100.383Z"
        fill="#FF03F9"
      />
      <path
        d="M56.8164 100.383H58.5527V98.6462H56.8164V100.383Z"
        fill="#02E5FF"
      />
      <path
        d="M84.9297 100.376H83.1934V102.113H84.9297V100.376Z"
        fill="#FF03F9"
      />
      <path
        d="M29.5715 46.5317H26.0928V48.2681H29.5715V46.5317Z"
        fill="#02E5FF"
      />
      <path
        d="M3.87596 50.0044H5.6123V48.268H3.87596V50.0044Z"
        fill="#02E5FF"
      />
      <path
        d="M65.0215 53.917H63.2852V55.6533H65.0215V53.917Z"
        fill="#02E5FF"
      />
      <path d="M44.6133 78.666H42.877V80.4024H44.6133V78.666Z" fill="#FF03F9" />
    </svg>
  )
}

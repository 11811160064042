import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export const Spinner = () => {
  return (
    <div className="spinner">
      <FontAwesomeIcon size={'lg'} icon={faSpinner} spin />
    </div>
  )
}

/**
 * @generated SignedSource<<92b0f3afa7ca70bf2627c741263a8e03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type myBetsQuery$variables = {};
export type myBetsQuery$data = {
  readonly myBets: ReadonlyArray<{
    readonly gameId: string;
    readonly gameProvider: string;
    readonly currency: string;
    readonly virtualId: number;
    readonly finished: boolean;
    readonly bets: ReadonlyArray<number>;
    readonly win: number | null;
    readonly meta: any | null;
    readonly insertedAt: string;
    readonly profit: number;
    readonly multiplier: number;
    readonly normalizedGame: {
      readonly label: string;
      readonly gameId: string;
      readonly provider: string | null;
    } | null;
    readonly user: {
      readonly name: string;
      readonly uuid: string | null;
      readonly vip: {
        readonly badgeId: string | null;
        readonly offset: number | null;
        readonly avatar: string | null;
      } | null;
      readonly hasProfileImage: boolean;
    };
  }>;
};
export type myBetsQuery = {
  variables: myBetsQuery$variables;
  response: myBetsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gameId",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GameBet",
    "kind": "LinkedField",
    "name": "myBets",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gameProvider",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "virtualId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "finished",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bets",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "win",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "multiplier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NormalizedGame",
        "kind": "LinkedField",
        "name": "normalizedGame",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BetUser",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Vip",
            "kind": "LinkedField",
            "name": "vip",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "badgeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "offset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasProfileImage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "myBetsQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "myBetsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4a9183979f8c433787bf0c1faa7dbf62",
    "id": null,
    "metadata": {},
    "name": "myBetsQuery",
    "operationKind": "query",
    "text": "query myBetsQuery {\n  myBets {\n    gameId\n    gameProvider\n    currency\n    virtualId\n    finished\n    bets\n    win\n    meta\n    insertedAt\n    profit\n    multiplier\n    normalizedGame {\n      label\n      gameId\n      provider\n    }\n    user {\n      name\n      uuid\n      vip {\n        badgeId\n        offset\n        avatar\n      }\n      hasProfileImage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6a53fd80fce18fbcf0910f6d055e651";

export default node;

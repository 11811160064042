/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Activity = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M5.43364 11.086L7.67849 8.1684L10.2391 10.1798L12.4359 7.3446"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="14.9966"
      cy="3.15014"
      rx="1.44165"
      ry="1.44165"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1934 2.34009H5.74257C3.48398 2.34009 2.08353 3.93963 2.08353 6.19821V12.26C2.08353 14.5186 3.45652 16.1113 5.74257 16.1113H12.1957C14.4542 16.1113 15.8547 14.5186 15.8547 12.26V6.98082"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Activity

import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './Chat.module.scss'
import { useChat } from '@src/hooks/useChat'
import { CommandPallete } from './CommandPallete'
import { sendMessage } from '../chatComponent/chat'
import classNames from 'classnames'
import { ModalType, openModalTask } from '../modalComponent/modalComponent'

import { Emojis } from './Emojis'

import { Rain } from './Rain'
import { Competition } from './Competition'
import { Message } from './Message'

import { useSelector } from 'react-redux'
import { IStore, store } from '@src/store'
import { GrabGiveaway } from './GrabGiveaway'
import { Confeti } from '../modalComponent/Confeti'
import { ChatSupport } from './ChatSupport'
import { CodeMaster } from './CodeMaster'
import { getErrorFromAtom } from '../errorFormatter'
import { toast } from 'react-toastify'
import { MultiplayerRewardV2 } from './MultiplayerRewardV2'
import { ParticipationReward } from './ParticipationReward'
import Close from '../icons/close'
import { AppTypes } from '@src/reducers/app.reducer'

export function Chat() {
  const { t } = useTranslation()
  const me = useSelector((state: IStore) => state.authentication.user)
  const giveaways = useSelector((state: IStore) => state.chat.giveaways)
  const closeChat = () => store.dispatch({ type: AppTypes.CLOSE_CHAT })

  const ref = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState('')
  const {
    isOpen,
    handleMessageDelete,
    shouldShowConfeti,
    isLoadingGrabGiveaway,
    setMessages,
    groupedMessages,
    isCommandPalleteOpen,
    isEmojiWindowOpen,
    setIsEmojiWindowOpen,
    handleScroll,
  } = useChat({
    ref,
    value,
  })

  const handleSend = async () => {
    try {
      await sendMessage({ msg: value })
    } catch (err) {
      console.log({ err })
      toast.error(getErrorFromAtom((err as any).reason))
    } finally {
      setValue('')
    }
  }

  const handleToggleEmojiWindow = useCallback(() => {
    setIsEmojiWindowOpen((prev) => !prev)
  }, [setIsEmojiWindowOpen])

  const unusedGiveaways = giveaways.filter(
    (giveaway) => me?.uuid && giveaway.meta && !Object.keys(giveaway.meta as {}).includes(me.uuid)
  )

  const shouldDisplayGiveaway = unusedGiveaways.length > 0

  return (
    <div
      className={classNames({
        [classes.root]: isOpen,
        [classes.root_closed]: !isOpen,
      })}
    >
      <div className={classes.header}>
        <div className="py-12 px-24 flex flex-row justify-between w-full">
          <button type="button" className="uppercase">
            {t('chat.generalChat', 'GENERAL CHAT')}
          </button>
          <Close onClick={closeChat} className="w-28 h-28 cursor-pointer" />
        </div>
        {shouldDisplayGiveaway && <GrabGiveaway isLoadingGrabGiveaway={isLoadingGrabGiveaway} />}
      </div>
      {shouldShowConfeti && (
        <Confeti id="confeti" className="z-10 absolute pointer-events-none left-0 top-0" target="chat-body" />
      )}
      <div id="chat-body" ref={ref} className={classes.body} onScroll={handleScroll}>
        {groupedMessages.map((group, i) => {
          if (group[0].from_admin && (group[0].type === 'competition' || group[0].type === 'chat_support')) {
            return (
              <div key={`${group[0]?.from_uuid}_${i}`} className="text-white flex flex-col gap-y-8 px-32">
                {group.map((msg, j) => {
                  if (msg.type === 'competition') {
                    const competition = JSON.parse(atob(msg.msg)) as {
                      name: string
                      type: string
                      players: { idx: number; name: string }[]
                    }

                    if (competition.type === 'make_it_rain') {
                      const rain = competition as unknown as {
                        uuid: string
                        from: string
                        message: string
                        amount: number
                        currency: string
                        player_count: number
                        has_profile_image: boolean
                      }

                      return <Rain key={j} rain={rain} />
                    }

                    return <Competition key={j} competition={competition} />
                  }

                  if (msg.type === 'mp_reward') {
                    // return <MultiplayerReward key={j} msg={msg} />
                    return null
                  }

                  if (msg.type === 'mp_reward_v2') {
                    return <MultiplayerRewardV2 key={j} msg={msg} />
                  }

                  if (msg.type === 'tournament_participation_rewards') {
                    return <ParticipationReward key={j} msg={msg} />
                  }

                  try {
                    const data = JSON.parse(msg.msg)
                    return <ChatSupport key={j} data={data} />
                  } catch (e) {
                    console.log({ error: e })

                    return null
                  }
                })}
              </div>
            )
          }

          if (group[0].type === 'code_master') {
            const data = JSON.parse(group[0].msg)
            return <CodeMaster data={data} className="mx-32" />
          }

          return (
            <Message
              key={i}
              me={me}
              hash={group[0].hash}
              messages={group}
              setMessages={setMessages}
              handleMessageDelete={handleMessageDelete}
              has_profile_image={group[0].has_profile_image}
              from={group[0].from}
              from_uuid={group[0].from_uuid}
              from_admin={group[0].from_admin}
              from_muted_user={group[0].from_muted_user}
              vip={group[0].vip}
            />
          )
        })}
      </div>
      <div className={classes.footer}>
        {isCommandPalleteOpen && <CommandPallete />}
        <Emojis isOpen={isEmojiWindowOpen} setValue={setValue} setIsEmojiWindowOpen={setIsEmojiWindowOpen} />
        <div className="flex flex-col gap-y-16 px-20 pb-16">
          <form
            className="relative w-full flex items-center"
            onSubmit={(e) => {
              e.preventDefault()
              handleSend()
            }}
          >
            <input
              type="text"
              placeholder={`${t('chat.sendAMessage', 'Send a message')}...`}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="w-full px-50 py-14 outline-none text-white font-bold"
            />

            <button type="button" className="absolute left-20" id="emoji-button" onClick={handleToggleEmojiWindow}>
              <img alt="Happy Emoji" src="/assets/chat/emoji.svg" />
            </button>

            <button className="absolute right-20">
              <img alt="Send Emoji" src="/assets/send.svg" />
            </button>
          </form>

          <div className="flex items-center px-20 justify-between">
            <div className="flex gap-x-15">
              <button
                type="button"
                onClick={() => openModalTask.next({ type: ModalType.rain })}
                style={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <img alt="Bitcoin Emoji" src="/assets/chat/rain.svg" />
              </button>
            </div>
            <div className="flex">
              <button
                type="button"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <img alt="Imfo emoji" src="/assets/chat/info.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { graphql } from 'babel-plugin-relay/macro'
import { store } from '../store'
import { SignInComponentMeQuery } from './__generated__/SignInComponentMeQuery.graphql'
import { useEffect, useState } from 'react'
import Env, { GraphQLError } from '../RelayEnvironment'
import { commitMutation, fetchQuery } from 'react-relay'
import { SignInComponentAnonMutation } from './__generated__/SignInComponentAnonMutation.graphql'
import { AuthAction, User, UserConstant } from '../reducers/authentication.reducer'
import { Redirect, useLocation } from 'react-router-dom'
import ConfigurationService from '../service/ConfigurationService'
import { refProps } from '..'

export const SignInComponent = () => {
  const location = useLocation<{ from: { pathname: string; search?: string } }>()

  const state = store.getState().authentication
  const [isLoggedIn, setIsLoggedIn] = useState(state.loggedIn)

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().authentication.loggedIn)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const state = store.getState().authentication
    // If we have token, try to log in
    if (state.error) {
      return
    }

    if (state.token) {
      fetchQuery<SignInComponentMeQuery>(Env, meQuery, {}).subscribe({
        next: async (data) => {
          if (data.me) {
            localStorage.setItem('user', JSON.stringify(data.me))
            if (data.me.anonymousHash) {
              localStorage.setItem('anonymous_hash', data.me.anonymousHash)
            }
            const globalSettings$ = fetchGlobalSettings()
            await globalSettings$
            store.dispatch<AuthAction>({
              type: UserConstant.LOGIN_SUCCESS,
              user: data.me as unknown as User,
              token: state.token,
            })
          } else {
            store.dispatch<AuthAction>({ type: UserConstant.LOGIN_FAILURE })
            localStorage.clear()
            window.location.href = '/'
          }
        },
        error: (e: GraphQLError) => {
          if (e.status === 200) {
            const errors = e.response?.json?.errors
            if (errors?.length > 0) {
              const message = errors[0].message
              if (message === 'banned') {
                localStorage.clear()
                window.location.href = '/'
                return
              }
            }
          }
          try {
            const globalSettings$ = fetchGlobalSettings()
            loginAnon(globalSettings$)
          } catch (e) {
            localStorage.clear()
            window.location.href = '/'
          }
        },
      })
    }
    // Make anon login
    else {
      try {
        const globalSettings$ = fetchGlobalSettings()
        loginAnon(globalSettings$)
      } catch (e) {
        localStorage.clear()
        window.location.href = '/'
      }
    }
  }, [])

  if (isLoggedIn) {
    if (location.state?.from) {
      return <Redirect to={{ pathname: location.state.from.pathname, search: location.state.from.search }} />
    } else {
      return <Redirect to={{ pathname: '/' }} />
    }
  }

  if (state.error) {
    return <h2 className="sign-in-error">Unknown error occured, please try again later!</h2>
  }

  return null
}

const fetchGlobalSettings = async () => {
  return await ConfigurationService.instance.load()
}

const loginAnon = (globalSettings$: Promise<void>) => {
  let storedUuid = localStorage.getItem('anonymous_hash')
  if (!storedUuid) {
    storedUuid = generateRandomString(25)
    localStorage.setItem('anonymous_hash', storedUuid)
  }

  const referralUuid = localStorage.getItem('referral_uuid')

  if (referralUuid) {
    refProps.push({
      key: 'affiliate_uuid',
      value: referralUuid,
    })
  }

  commitMutation<SignInComponentAnonMutation>(Env, {
    mutation: makeAnonymousUser,
    variables: {
      input: {
        refProps,
        uuid: storedUuid,
      },
    },
    onCompleted: async (data) => {
      const token = data.getAnonymousUser?.token.trim()
      const user = data.getAnonymousUser?.user
      if (null == token || null == user) {
        localStorage.clear()
        window.location.href = '/'
        return
      }
      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))
      await globalSettings$
      store.dispatch<AuthAction>({ type: UserConstant.LOGIN_SUCCESS, user: user as unknown as User, token })
    },
    onError: (_err) => {
      console.log({ _err })
      store.dispatch<AuthAction>({ type: UserConstant.LOGIN_FAILURE })
    },
  })
}

const dec2hex = (dec: number) => {
  return ('0' + dec.toString(16)).substr(-2)
}

// generateId :: Integer -> String
const generateRandomString = (length: number) => {
  var arr = new Uint8Array(length)
  const crypto = window.crypto || (window as any).msCrypto
  crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export { generateRandomString }

export const meQuery = graphql`
  query SignInComponentMeQuery {
    me {
      name
      username
      otpEnabled
      anonymousHash
      uuid
      roles
      confirmed
      chatApproval
      insertedAt
      kycState
      refProps
      hasProfileImage
      preferences {
        key
        value
        valueJson
      }
      userStats {
        name
        value
        meta
      }
      affiliateStats {
        count
        totalCreditSumUsd
        creditByCurrency
      }
      kycSuccessful
      pendingKycBonuses {
        amount
        currency
        playThrough
      }
      eliteSeat {
        currentLevel {
          name
          tierStartUsd
          tierStartLevel
          tierStep
          tierCount
          bonusUsd
          levelNr
        }
        currentTier
        nextTier {
          name
          tierStartUsd
          tierStartLevel
          tierStep
          tierCount
          bonusUsd
          levelNr
        }
        tierProgress
        levelProgress
      }
    }
  }
`
export const makeAnonymousUser = graphql`
  mutation SignInComponentAnonMutation($input: GetAnonymousUserInput!) {
    getAnonymousUser(input: $input) {
      token
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        chatApproval
        insertedAt
        kycState
        refProps
        hasProfileImage
        preferences {
          key
          value
          valueJson
        }
        userStats {
          name
          value
          meta
        }
      }
    }
  }
`

import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import classes from './Authentication.module.scss'
import classNames from 'classnames'

import { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/scss'
import 'swiper/scss/effect-fade'

import { z } from 'zod'
import { Field, Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Link, useHistory } from 'react-router-dom'
import { useMutation } from 'react-relay'
import { registerMutation } from './gql/registerMutation.gql'
import type { registerMutation as RegisterMutation } from './gql/__generated__/registerMutation.graphql'
import { AuthAction, User, UserConstant } from '@src/reducers/authentication.reducer'
import { store } from '@src/store'
import { GraphQLError } from '@src/RelayEnvironment'
import type { loginMutation as LoginMutation } from './gql/__generated__/loginMutation.graphql'
import { loginMutation } from './gql/loginMutation.gql'
import { toast } from 'react-toastify'
import formatError from '@src/components/errorFormatter'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import { AppTypes } from '@src/reducers/app.reducer'
import { authTabs, changeTab } from '@src/reducers/authModal.reducer'
import { useDispatch } from 'react-redux'
import TelegramLogin from './TelegramLogin'

const SignUp = () => {
  const { t } = useTranslation()

  const schema = z.object({
    email: z.string().email(),
    username: z.string(),
    password: z.string(),
    promocode: z.string().optional(),
    agreesToTerms: z.boolean(),
    enable2FA: z.boolean(),
  })

  const history = useHistory()
  const [signUserUp] = useMutation<RegisterMutation>(registerMutation)

  const initialValues: z.infer<typeof schema> = {
    email: '',
    username: '',
    password: '',
    promocode: '',
    agreesToTerms: false,
    enable2FA: false,
  }

  const handleSignUp = async (values: z.infer<typeof schema>) => {
    const hash = localStorage.getItem('anonymous_hash')

    let params: Array<{ key: string; value: string }> = []
    const savedProps = localStorage.getItem('ref_props')
    if (savedProps) {
      params = JSON.parse(localStorage.getItem('ref_props') as any)
    }

    if (values.promocode && values.promocode.length > 0) {
      params.push({ key: 'bonus_code', value: values.promocode })
      localStorage.setItem('check_for_pending_bonus', '1')
    }

    signUserUp({
      variables: {
        input: {
          name: values.username,
          username: values.email,
          password: values.password,
          anonymousHash: hash,
          refProps: params,
        },
      },
      onCompleted: (e) => {
        if (e.register) {
          const token = e.register.token
          const user = e.register.user
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.removeItem('ref_props')

          localStorage.setItem('show_email_popup', '1')

          store.dispatch<AuthAction>({
            type: UserConstant.LOGIN_SUCCESS,
            user: user as unknown as User,
            token,
          })

          if (values.enable2FA) {
            history.push(`/`)
          }
          history.push('/')
          window.location.reload()
        } else {
          // setMessage('Unknown error, please try again.')
        }
      },
      onError: (e: GraphQLError) => {
        toast.error(formatError(e)[0])
      },
    })
  }

  return (
    <Formik initialValues={initialValues} validationSchema={toFormikValidationSchema(schema)} onSubmit={handleSignUp}>
      <Form className={classes.form}>
        <Field
          name="email"
          required
          type="email"
          placeholder={t('authentication.email', 'Email')}
          className="h-55 rounded-7"
        />
        <Field
          name="username"
          required
          type="text"
          placeholder={t('authentication.username', 'Username')}
          className="h-55 rounded-7"
        />
        <Field
          name="password"
          required
          type="password"
          autoComplete="new-password"
          placeholder={t('authentication.password', 'Password')}
          className="h-55 rounded-7"
        />
        <div className="flex flex-col">
          <label htmlFor="promocode">{t('authentication.signup.promoCode', 'Promo code')}</label>
          <span
            className="text-xs text-center"
            style={{
              color: '#80819156',
            }}
          >
            {t('authentication.signup.leaveEmpty', 'Leave empty if you don’t have a promo code')}
          </span>
        </div>
        <Field name="promocode" type="text" placeholder="Promocode" className="h-55 rounded-7" />
        <div className="flex items-center gap-x-16 pl-50 pt-12">
          <Field name="agreesToTerms" type="checkbox" required />
          <label htmlFor="agreesToTerms">
            <Trans
              i18nKey="authentication.signup.agreeToTOC"
              components={[
                <Link to="/provably-fair/toc" target="_blank" className="text-eucalyptus hover:underline" />,
              ]}
            />
          </label>
        </div>
        <div className="flex items-center gap-x-16 pl-50 pb-12">
          <Field name="enable2FA" type="checkbox" />
          <label htmlFor="enable2FA">{t('authentication.toggle2FA.enable', 'Enable 2FA')}</label>
        </div>
        <button type="submit">{t('authentication.signup.joinCommunity', 'Join the blackjack.fun community')}</button>
        <div className="flex-grow my-5 border-t border-lightGray"></div>
        <TelegramLogin />
      </Form>
    </Formik>
  )
}

const SignIn = () => {
  const { t } = useTranslation()
  const [otpRequired, setOTPRequired] = useState(false)

  const schema = z.object({
    username: z.string(),
    password: z.string(),
    otp: z.string().optional(),
  })

  const initialValues: z.infer<typeof schema> = {
    username: '',
    password: '',
    otp: '',
  }

  const [signUserIn] = useMutation<LoginMutation>(loginMutation)

  const handleSignIn = async (values: z.infer<typeof schema>) => {
    signUserIn({
      variables: {
        input: { username: values.username, password: values.password, otp: values.otp },
      },
      onCompleted: (e) => {
        if (e.login) {
          const token = e.login.token
          const user = e.login.user
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          window.location.href = '/'

          /*
          Would be nice to reset everything
          store.dispatch<AuthAction>({type: UserConstant.LOGIN_SUCCESS, user: user as unknown as User, token});
          history.push('/');
          */
        } else {
          toast.error(t('toast.unknownError', 'Unknown error, please try again.'))
        }
      },
      onError: (e: GraphQLError) => {
        if (e.response?.json?.errors[0]?.message === 'otp_required') {
          setOTPRequired(true)
        } else {
          toast.error(formatError(e)[0])
        }
      },
    })
  }

  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  return (
    <Formik initialValues={initialValues} validationSchema={toFormikValidationSchema(schema)} onSubmit={handleSignIn}>
      <Form className={classes.formSignIn}>
        <label htmlFor="username">{t('authentication.username', 'Username')}</label>
        <Field name="username" required type="text" className="h-55 rounded-7" />
        <label htmlFor="password">{t('authentication.password', 'Password')}</label>
        <Field name="password" required type="password" className="h-55 rounded-7" />
        <label
          htmlFor="otp"
          className={classNames({
            hidden: !otpRequired,
          })}
        >
          2FA
        </label>
        <Field
          name="otp"
          required={otpRequired}
          type="text"
          className={classNames('h-55 rounded-7', {
            hidden: !otpRequired,
          })}
        />
        <button type="submit"> {t('authentication.logIn', 'Login')}</button>
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-lightGray"></div>
          <span className="uppercase flex-shrink mx-4 text-lightGray">{t('general.or', 'or')}</span>
          <div className="flex-grow border-t border-lightGray"></div>
        </div>
        <TelegramLogin />
        <button className={classes.forgotPassword} onClick={() => setModalView(<ForgotPassword />)} type="button">
          {t('authentication.iForgotPassword', 'I forgot my password')}
        </button>
      </Form>
    </Formik>
  )
}

const VerifyEmail = ({ verified }: { verified: boolean }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-between items-center w-full h-full">
      <div className="flex flex-col justify-center items-center w-full flex-grow">
        <p className="text-center text-purple font-semibold font-inter mb-20 text-xl">
          {verified
            ? t('authentication.verifyEmail.verified', 'Email Verified!')
            : t('authentication.verifyEmail.verify', 'Verify your account!')}
        </p>
        <div className="text-center font-medium font-inter text-darkGrey text-base px-18">
          <p>{t('authentication.verifyEmail.congrats', 'Congrats!')}</p>
          <p>
            {verified
              ? t('authentication.verifyEmail.verifySuccess', 'Your email was successfully confirmed.')
              : t('authentication.verifyEmail.oneSmallStep', 'One small step and you’re done.')}
          </p>
          <p>
            {verified
              ? t('authentication.verifyEmail.letTheFunBegin', 'Let the fun begin!')
              : t(
                  'authentication.verifyEmail.verifEmailWasSent',
                  'A verification email was sent in order to verify your account.'
                )}
          </p>
        </div>
      </div>
      {verified ? (
        <div className="text-center font-medium text-sm text-white p-20">
          <p>{t('authentication.verifyEmail.tryInHouseBlackjack', 'Try our in-house blackjack')}</p>
          <p>{t('authentication.verifyEmail.tryFreerollBlajack', 'Try our freeroll blackjack tournaments')}</p>
        </div>
      ) : (
        <p className="italic text-center font-normal text-sm text-lightGray p-20">
          {t(
            'authentication.verifyEmail.beAware',
            'Be aware that you wont have access to the full experience without verifying your account.'
          )}
        </p>
      )}
    </div>
  )
}

export function Authentication({ tab, closeModal }: { tab?: typeof authTabs[number]; closeModal: () => void }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const setActiveTab = (t: typeof authTabs[number]) => dispatch(changeTab({ tab: t }))

  const TAB_JOIN_US = 'Join Us' as const
  const TAB_LOGIN = 'Login' as const
  const TAB_VERIFY = 'Verify' as const

  const tabTranslations = {
    [TAB_JOIN_US]: t('authentication.joinUs'),
    [TAB_LOGIN]: t('authentication.logIn'),
  }

  const getContent = () => {
    switch (tab) {
      case 'Join Us':
        return <SignUp />
      case 'Login':
        return <SignIn />
      case 'Verify':
        return <VerifyEmail verified={false} />
      case 'Verify Success':
        return <VerifyEmail verified />
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <Swiper
        loop
        speed={1500}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        unselectable="on"
        allowTouchMove={false}
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        className="hidden md:block"
      >
        {tab === 'Verify Success' ? (
          <SwiperSlide>
            <img alt="" src="/assets/auth/verification.png" />
          </SwiperSlide>
        ) : (
          <>
            <SwiperSlide>
              <img alt="" src="/assets/auth/banner.png" />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" src="/assets/auth/banner2.png" />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" src="/assets/auth/banner3.png" />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" src="/assets/auth/banner4.png" />
            </SwiperSlide>
          </>
        )}
      </Swiper>
      <div className="flex flex-col w-full p-10">
        <div className="flex justify-end">
          <button type="button" onClick={closeModal}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.4286 0C19.2927 -3.0004e-07 24.8571 5.59644 24.8571 12.5C24.8571 19.4036 19.2927 25 12.4286 25C5.56446 25 3.01764e-07 19.4036 0 12.5C-3.01764e-07 5.59644 5.56446 3.0004e-07 12.4286 0Z"
                fill="#333333"
              />
              <path
                d="M9 17L12.5 13L16 17"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M16 9L12.5 13L9 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        {(tab === TAB_JOIN_US || tab === TAB_LOGIN) && (
          <div className={classes.tabs}>
            {authTabs
              .filter((t) => !t.includes(TAB_VERIFY)) // Exclude 'Verify' tab
              .map((t) => (
                <button
                  key={t}
                  type="button"
                  onClick={() => setActiveTab(t)}
                  className={classNames('select-none', {
                    [classes.active]: tab === t,
                  })}
                >
                  {tabTranslations[t] ? tabTranslations[t] : t}
                </button>
              ))}
          </div>
        )}
        {getContent()}
      </div>
    </div>
  )
}

import * as React from 'react'

interface Props {
  id?: string
  className?: string
  target?: string
}

export class Confeti extends React.Component<Props, {}> {
  static PI_2 = 2 * Math.PI
  static colors = [
    [85, 71, 106],
    [174, 61, 99],
    [219, 56, 83],
    [244, 92, 68],
    [248, 182, 70],
  ]

  confetiNumber = 350
  mounted = false
  canvas?: HTMLCanvasElement
  canvasContext: CanvasRenderingContext2D
  xpos: number = 0.5
  w = 200
  h = 200
  targetElement: HTMLElement | null = null

  constructor(props: Props) {
    super(props)
    this.canvasContext = undefined as any
  }

  componentDidMount() {
    if (!this.canvas) {
      return
    }
    this.mounted = true
    this.canvasContext = this.canvas.getContext('2d') as CanvasRenderingContext2D
    const s2 = class {
      style: number[] = []
      rgb: string
      r: number
      r2: number
      opacity: number
      dop: number
      x: number
      y: number
      xmax: number
      ymax: number
      vx: number
      vy: number

      constructor(private outer: Confeti, private w: number, private h: number) {
        this.style = Confeti.colors[~~this.range(0, 5)]
        this.rgb = 'rgba(' + this.style[0] + ',' + this.style[1] + ',' + this.style[2]
        this.r = ~~this.range(2, 6)
        this.r2 = 2 * this.r
        this.opacity = undefined as any
        this.dop = undefined as any
        this.x = undefined as any
        this.y = undefined as any
        this.xmax = undefined as any
        this.ymax = undefined as any
        this.vx = undefined as any
        this.vy = undefined as any
        this.replace()
      }

      replace() {
        this.opacity = 0
        this.dop = 0.03 * this.range(1, 4)
        this.x = this.range(-this.r2, this.w - this.r2)
        this.y = this.range(-20, this.h - this.r2)
        this.xmax = this.w - this.r
        this.ymax = this.h - this.r
        this.vx = this.range(0, 2) + 8 * this.outer.xpos - 5

        return (this.vy = 0.7 * this.r + this.range(-1, 1))
      }

      draw() {
        let ref
        this.x += this.vx
        this.y += this.vy
        this.opacity += this.dop
        if (this.opacity > 1) {
          this.opacity = 1
          this.dop *= -1
        }
        if (this.opacity < 0 || this.y > this.ymax) {
          this.replace()
        }
        if (!(0 < (ref = this.x) && ref < this.xmax)) {
          this.x = (this.x + this.xmax) % this.xmax
        }

        return this.drawCircle(~~this.x, ~~this.y, this.r, this.rgb + ',' + this.opacity + ')')
      }

      drawCircle(x: number, y: number, r: number, style: string) {
        this.outer.canvasContext.beginPath()
        this.outer.canvasContext.arc(x, y, r, 0, Confeti.PI_2, false)
        this.outer.canvasContext.fillStyle = style
        return this.outer.canvasContext.fill()
      }

      range(min: number, max: number) {
        return (max - min) * Math.random() + min
      }
    }

    let confettis: any[] = []
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let i, j = 1, ref = this.confetiNumber; 1 <= ref ? j <= ref : j >= ref; i = 1 <= ref ? ++j : --j) {
      confettis.push(new s2(this, this.w, this.h))
    }

    const step = () => {
      if (!this.mounted) {
        confettis = []
        return
      }
      if (!(this.canvasContext as any).clearRect) {
        this.canvasContext = this.canvas?.getContext('2d') as CanvasRenderingContext2D
      }
      requestAnimationFrame(step)
      this.canvasContext?.clearRect(0, 0, this.w, this.h)
      confettis.forEach((x) => x.draw())
    }

    step()
    if (this.targetElement) {
      this.targetElement.addEventListener('mousemove', this.mousemove)
    } else {
      document.addEventListener('mousemove', this.mousemove)
    }
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.targetElement) {
      this.targetElement.removeEventListener('mousemove', this.mousemove)
    } else {
      document.removeEventListener('mousemove', this.mousemove)
    }
  }

  mousemove = (e: MouseEvent) => {
    let vw
    if (this.targetElement) {
      vw = this.targetElement.getBoundingClientRect().width
    } else {
      vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    }

    this.xpos = e.clientX / vw
  }

  setRef(ref: HTMLCanvasElement) {
    if (!ref || this.canvas) {
      return
    }

    this.canvas = ref
    const parent = document.body
    let w
    let h
    if (this.props.target) {
      this.targetElement = document.querySelector(this.props.target)
      const rect = this.targetElement?.getBoundingClientRect()
      w = rect?.width
      h = rect?.height
    }
    if (undefined === w || undefined === h) {
      w = window.innerWidth
      h = window.innerHeight
    }

    if (parent) {
      // const rect = parent.getBoundingClientRect();
      this.canvas.width = w
      this.canvas.height = h
      this.canvas.width = w
      this.canvas.height = h
      this.w = w
      this.h = h
    }
  }

  render() {
    return (
      <canvas
        id={this.props.id}
        className={this.props.className}
        ref={(ref) => this.setRef(ref as HTMLCanvasElement)}
      />
    )
  }
}

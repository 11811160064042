import { AppTypes } from '@src/reducers/app.reducer'
import React from 'react'
import Tooltip from '../../Tooltip/Tooltip'
import { store } from '@src/store'
import { useTranslation } from 'react-i18next'

interface Props {
  isCollapsed: boolean
  label: string
  onAction: () => void
  icon: React.ReactElement
  isNew?: boolean
}

const GreenMenuItem = ({ isCollapsed, label, onAction, icon, isNew }: Props) => {
  const { t } = useTranslation()
  const closeMenu = () => store.dispatch({ type: AppTypes.CLOSE_MENU })

  const handleToggle = () => {
    closeMenu()
    onAction()
  }

  const iconSizeProps = {
    width: isCollapsed ? '24' : '16',
    height: isCollapsed ? '24' : '16',
  }

  const iconWithSize = React.cloneElement(icon, iconSizeProps)

  return (
    <div>
      <div
        className={`flex items-center align-middle rounded-4 cursor-pointer hover:bg-blackRussianHover ${
          isCollapsed ? 'mx-10' : 'mx-5'
        } `}
        onClick={handleToggle}
      >
        {isCollapsed ? (
          <Tooltip type="dark" place="right" text={label}>
            <div className="p-7 mx-8 text-eucalyptus">{iconWithSize}</div>
          </Tooltip>
        ) : (
          <div className={`flex items-center justify-between w-full px-15 py-6 rounded-4 hover:bg-blackRussianHover `}>
            <div className="flex items-center space-x-13 text-eucalyptus">
              {iconWithSize}
              <span className="text-eucalyptus text-xs leading-5 font-semibold">
                {label} {isNew && <span className="text-pink text-xs">{`(${t('menu.new', 'New')})`}</span>}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GreenMenuItem

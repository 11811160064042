import { useIsMobile } from '@src/hooks'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { LeaderBoardItem } from './leaderBoardItem'
import { useLeaderBoards } from './useLeaderBoards'

import classes from './leaderBoards.module.scss'

export default function LeaderBoards() {
  const isMobile = useIsMobile()
  const { data, filters, valueDescription, selected, setSelected } = useLeaderBoards()

  return (
    <div className={classes.root}>
      <div
        className={classNames(
          'flex justify-between items-center px-20 text-white font-poppins leading-5 py-10 gap-x-8',
          classes.header
        )}
      >
        <div className="flex gap-x-4 md:gap-x-16">
          <div className="font-black w-max">LEADERS</div>
          {!isMobile && (
            <Link to="/competitions" className={classes.viewAll}>
              View all
            </Link>
          )}
        </div>
        <div className={classNames('flex gap-x-8 md:gap-x-16 md:text-sm', classes.filters)}>
          {filters.map((f) => (
            <span
              key={f}
              onClick={() => setSelected(f)}
              className={classNames('font-medium cursor-pointer', {
                [classes.activeFilter]: selected === f,
              })}
            >
              {f}
            </span>
          ))}
        </div>
      </div>
      <div className={classes.places}>
        {data?.map((i, index) => (
          <LeaderBoardItem
            selected={selected}
            key={JSON.stringify(i)}
            valueDescription={valueDescription}
            isFirstPlace={index === 0}
            name={i.name}
            icon={i.icon}
            place={index + 1}
            reward={{
              type: i.payoutType,
              amount: i.payoutAmount.toString(),
              currency: i.currency,
              value: i.reward,
            }}
            bet={i.bet}
            winningAmount={i.amount}
          />
        ))}
      </div>
    </div>
  )
}

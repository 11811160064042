const LIVE_PROVIDERS = new Set([
  'livesolutions',
  'lotto instant win',
  'sa gaming',
  '7mojos',
  '7mojos live',
  'super spade games',
  'asia gaming',
  'betgames.tv',
  'bombay live',
  'evolution gaming',
])
const SLOT_PROVIDERS = new Set([
  '2by2',
  '7777gaming',
  '7mojos',
  'airdice',
  'alchemy gaming',
  'all41',
  'apollo',
  'apparat gaming',
  'avatarux',
  'barbarabang',
  'betsoft',
  'big time gaming',
  'blueprint gaming',
  'booongo',
  'buck stakes entertainment',
  'caleta gaming',
  'ct gaming',
  'ebet',
  'endorphina',
  'evoplay entertainment',
  'fantasma',
  'fazi',
  'foxium',
  'fugaso',
  'g games',
  'gameart',
  'gameburger',
  'gamingcorps',
  'gamzix',
  'genii',
  'golden hero',
  'golden rock studios',
  'gong gaming',
  'green jade',
  'h27',
  'habanero',
  'hacksaw gaming',
  'indi slots',
  'jftw',
  'kagaming',
  'kalamba games',
  'kiron',
  'mancala gaming',
  'manna play',
  'mascot gaming',
  'microgaming',
  'neon valley',
  'netent',
  'netgaming',
  'nolimit city',
  'northern lights gaming',
  'old skool',
  'onetouch',
  'onlyplay',
  'pgsoft',
  'playson',
  'pragmatic play',
  'pulse 8',
  'rabcat',
  'red rake gaming',
  'revolver gaming',
  'slingshot',
  'slotmill games',
  'smartsoft',
  'snowborn studios',
  'spadegaming',
  'spinplay games',
  'stormcraft',
  'swintt',
  'triple edge',
  'truelab',
  'urgent games',
  'wazdan',
  'yolted',
  'zillion',
])

export const filterProviders = (providers: string[], type: 'slots' | 'live'): string[] => {
  return providers.filter((p) => {
    if (type === 'slots') {
      return SLOT_PROVIDERS.has(p.toLowerCase())
    }

    return LIVE_PROVIDERS.has(p.toLowerCase())
  })
}

import React from 'react'
import { useParams } from 'react-router-dom'

import { ProfileHero } from './ProfileHero'
import { useProfilePage } from './useProfilePage'

import { ProfileTabs } from './ProfileTabs'

import classes from './ProfilePage.module.scss'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'
import { AppLoader } from '@src/components/AppLoader/AppLoader'
import { ProfileSubTabs } from './ProfileSubTabs'
import { ProfileContent } from './ProfileContent'
import classNames from 'classnames'

export function ProfilePage() {
  const profile = useSelector((s: IStore) => s.profile.profile)
  const { uuid } = useParams<{ uuid: string }>()
  useProfilePage({ uuid })

  if (!profile) return <AppLoader />

  return (
    <div className={classNames('mx-auto', classes.root)}>
      <ProfileHero uuid={uuid} profile={profile} />
      <ProfileTabs />
      <ProfileSubTabs />
      <ProfileContent uuid={uuid} profile={profile} />
    </div>
  )
}

export default ProfilePage

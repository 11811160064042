import './UserComponent.scoped.scss'
import { User } from '../../../reducers/authentication.reducer'
// import { ProfileBadge } from '../../profileComponent/vipProfileComponent'
import { getUserIconFromUser } from '../../../App'
import React from 'react'
import { NavLink } from 'react-router-dom'
import useComponentVisible from '../UseComponentVisible'
import Tooltip from '../../Tooltip/Tooltip'
import { openModalTask } from '../../modalComponent/modalComponent'
import { ModalType } from '../../modalComponent/modalComponent'
import { useTranslation } from 'react-i18next'

export const UserComponent = ({ user }: { user: User }) => {
  const { t } = useTranslation()
  const isPrivateUser = user.preferences.find((x) => x.key === 'private')?.value === 'true'

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    let e = event.nativeEvent.target
    while (e) {
      const h = e as HTMLElement
      // We want to close on link
      if (h.classList.contains('link')) {
        break
      }
      if (h.classList.contains('userPopup')) {
        return
      }
      e = (e as HTMLElement).parentElement
    }
    setIsComponentVisible(!isComponentVisible)
  }

  return (
    <React.Fragment>
      <div ref={ref} className={'user-row' + (isComponentVisible ? ' open' : '')} onClick={(e) => clickHandler(e)}>
        <div className="border-solid rounded-full border-2 border-eucalyptus hover:border-eucalyptusHover">
          {getUserIconFromUser(user, true, '35px', isPrivateUser)}
        </div>
        <div className={'userPopup' + (isComponentVisible ? ' open' : '')}>
          <div className="header">
            {user.name}
            <a href="/logout">
              <img alt="" src="/assets/logout.svg" className="inline-block" />
              {t('userComponent.logOut', 'Logout')}
            </a>
          </div>
          <div className="inner">
            <NavLink to="/profile" className="link">
              <img alt="" src="/assets/profile.svg" />
              {t('userComponent.yourProfile', 'Your profile')}
            </NavLink>
            <NavLink to="/settings" className="link">
              <img alt="" src="/assets/settings.svg" />
              {t('userComponent.globalSettings', 'Global settings')}
            </NavLink>
            <Tooltip text={t('general.comingSoon', 'Coming soon')}>
              <div
                className="link"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <img alt="" src="/assets/statistics.svg" />
                {t('userComponent.statistics', 'Statistics')}
              </div>
            </Tooltip>
            <button
              type="button"
              className="link cta"
              onClick={() => {
                openModalTask.next({ type: ModalType.beacomeAnInvestor })
              }}
            >
              {t('userComponent.becomeAnInvestor', 'BECOME AN INVESTOR')}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import { getUserIcon } from '@src/App'
import type { Message } from '@src/hooks/useChat'

import classes from './MultiplayerReward.module.scss'
import { Money } from '@src/money'

export function MultiplayerRewardV2({
  style,
  className,
  msg,
}: {
  style?: React.CSSProperties
  className?: string
  msg: Message
}) {
  const { t } = useTranslation()

  const message = JSON.parse(atob(msg.msg)) as {
    amount: number
    currency: string
    name: string
    winners: {
      name: string
      amount: number
      percent: number
      uuid: string
      has_profile_image: boolean
    }[]
  }

  const { amount, currency, name } = message

  const winners = message.winners ?? []

  return (
    <>
      {winners.map((winner, i) => {
        const ProfileImage = getUserIcon(winner.name, winner.uuid, winner.has_profile_image)

        return (
          <div
            key={i}
            className={classNames('relative max-w-full', className)}
            style={{
              width: '322px',
              height: '656px',
              ...style,
            }}
          >
            <img
              alt=""
              style={{
                width: '322px',
                height: '656px',
              }}
              className="absolute rounded-[7px] left-0 top-0"
              src="/assets/chat/mp-reward.png"
            />
            <div className="py-22 z-10 relative">
              <div className="flex flex-col items-center gap-y-20">
                <div className={classes.congrats}>{t('chat.multiplayerReward.congrats', 'CONGRATS')}</div>
                <div className={classes.name}>{winner.name}</div>
                <p className={classes.text}>
                  <Trans i18nKey="chat.multiplayerReward.justWon" values={{ name }}>
                    Just won the {name}
                  </Trans>
                </p>
              </div>
              <div className="flex flex-col items-center gap-y-20">
                <div
                  style={{
                    width: '100%',
                    maxWidth: '253px',
                  }}
                  className={classNames(
                    classes.imageWrapper,
                    'aspect-square relative flex items-center justify-center rounded-full'
                  )}
                >
                  {ProfileImage}
                </div>

                <div className="flex flex-col gap-y-15">
                  <div className={classes.reward}>
                    +{Money.fromInteger(amount, currency)} {currency}
                  </div>
                  <p className={classes.rewardMessage}>
                    {t('chat.multiplayerReward.addedToWallet', 'was aded in your wallet.')}
                  </p>
                  <div className="px-40">
                    <Link to="/game/bitcoin-blackjack_v2" type="button" className={classes.button}>
                      {t('chat.multiplayerReward.joinNextFreeroll', 'JOIN THE NEXT FREEROLL TOURNAMENT')}
                    </Link>
                  </div>
                </div>

                <div className="flex justify-center">
                  <span className={classes.footerText}>
                    <Trans i18nKey="chat.multiplayerReward.claimBonus" values={{ winnerName: winner.name }}>
                      Type /champion {winner.name} to claim your supporter bonus!
                    </Trans>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay'
import { getMessageContainer, LoginHeader } from '../Login/Login'
import formatError from '../../errorFormatter'
import { GraphQLError } from '../../../RelayEnvironment'
import { store } from '../../../store'
import { AppTypes } from '../../../reducers/app.reducer'
import { ForgotPasswordForgotPasswordMutation } from './__generated__/ForgotPasswordForgotPasswordMutation.graphql'
import IForgotPassword from './ForgotPassword.interface'
import styles from '../Login/Login.module.scss'
import ForgotPasswordSent from '../ForgotPasswordSent/ForgotPasswordSent'

const ForgotPassword: React.FC<IForgotPassword> = () => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')

  const setModalView = (view) => store.dispatch({ type: AppTypes.SET_MODAL_VIEW, modal: { view: view } })

  const [commit] = useMutation<ForgotPasswordForgotPasswordMutation>(forgotPasswordMutation)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    commit({
      variables: { input: { username: email } },
      onCompleted: () => {
        setModalView(<ForgotPasswordSent />)
      },
      onError: (e: GraphQLError) => {
        setMessage(formatError(e)[0])
      },
    })
  }

  return (
    <div className={styles.root}>
      <LoginHeader activeTab={t('authentication.forgotPassWord', 'Forgot password')} />
      <div className={styles.body}>
        <img src="/assets/auth/forgot-password.svg" className="w-210 mx-auto" alt="Forgot password" />
      </div>
      <div className={styles.footer}>
        <form onSubmit={handleSubmit}>
          <div className="">
            <div className="">
              <label htmlFor="email-form-control">{t('authentication.email', 'Email')}</label>
              <input
                id="email-form-control"
                name="email"
                required={true}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {getMessageContainer(message)}
            <button className="" type="submit">
              {t('authentication.forgotPassword.recoverPassword', 'Recover password')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword

const forgotPasswordMutation = graphql`
  mutation ForgotPasswordForgotPasswordMutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      res
    }
  }
`

import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import TelegramWebApp from '@twa-dev/sdk'

import './App.scss'
import './app.scoped.scss'
import { gameClosedSubscription } from './components/gameListComponent/GameListComponent'
import { PrivateRoute } from './components/PrivateRoute'
import Jdenticon from './components/jdenticon'
import { getUserPropOr, SettingsComponent } from './components/SettingsComponent/SettingsComponent'
import { SignInComponent } from './components/SignInComponent'

import Cookie from 'js-cookie'

import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { CoinsComponent } from './components/coinsComponent/CoinsComponent'
import { ProvablyFairComponent } from './components/provablyFairComponent/ProvablyFairComponent'
import { store, IStore } from './store'
import { fetchQuery, requestSubscription, useMutation } from 'react-relay'
import { User, UserProp, userUpdated } from './reducers/authentication.reducer'
import { LogoutComponent } from './components/authentication/LogoutComponent'
import Env, { GraphQLError } from './RelayEnvironment'
import { graphql } from 'babel-plugin-relay/macro'

import { ReplaySubject, Subject } from 'rxjs'
import { ModalComponent } from './components/modalComponent/modalComponent'
import { LandingPageComponent } from './components/landingComponent/LandingComponent'
import { UserBet } from './components/LatestBets/LatestBets_old'
import { GameListComponentSGameClosedSubscription } from './components/gameListComponent/__generated__/GameListComponentSGameClosedSubscription.graphql'
import ConfigurationService, { globalSettingsUpdatedSubscription } from './service/ConfigurationService'
import { ConfigurationServiceGlobalSettingsUpdatedSubscription } from './service/__generated__/ConfigurationServiceGlobalSettingsUpdatedSubscription.graphql'
import { ConfirmEmailComponent } from './components/authentication/ConfirmEmailComponent'
import { ResponsibleGaming } from './components/responsibleGaming'
import { getProfileIcon, VIPProfileComponent } from './components/profileComponent/vipProfileComponent'
import { KYCInfo } from './components/kyc/KYCInfo'
import { initBets, reloadBets } from './reducers/bet.reducer'
import { CompType, Currency } from './__generated__/AppComponentlistCompetitionsQuery.graphql'
import { AppComponentServerTimeTickSubscription } from './__generated__/AppComponentServerTimeTickSubscription.graphql'
import ReactTooltip from 'react-tooltip'
import { KYCSetup } from './components/kyc/KYCSetup'
import useIsMobile from './hooks/IsMobile'
import AppTop from './components/AppTop/AppTop'
import classNames from 'classnames'
import Toaster from './components/Toaster/Toaster'
import { AppTypes } from './reducers/app.reducer'
import Modal from './components/Modal/Modal'
import ReactGA from 'react-ga4'
import Toggle2FA from './components/authentication/Toggle2FA/Toggle2FA'
import AccountCreated from './components/authentication/AccountCreated/AccountCreated'
import { CampaignPage } from './components/campaignPage'
import { CompetitionsPage } from '@components/CompetitionsPage'
import { useLockScroll } from './hooks/useLockScroll'
import { Notifications } from '@components/Notifications'
import { NotificationTestPage } from './components/NotificationsOld'
import { NewGameListComponent } from './components/gameListComponent/NewGameListComponent'
import { Game } from './components/gameListComponent/GameListComponent.types'
import { EliteSeatPage } from './pages/EliteSeatPage/EliteSeatPage'
import { NewGameListComponentNormalizedGamesQuery$data } from './components/gameListComponent/__generated__/NewGameListComponentNormalizedGamesQuery.graphql'
import { AppContent } from './containers/AppContent'
import { Chat } from './components/chat/Chat'
import { AppComponentUserUpdatedSubscription } from './__generated__/AppComponentUserUpdatedSubscription.graphql'
import { Tournament } from './components/Tournament/Tournament'
import { AppComponentBroadcastCreatedSubscription } from './__generated__/AppComponentBroadcastCreatedSubscription.graphql'
import { ApploginTelegramMutation, LoginTelegramInput } from './__generated__/ApploginTelegramMutation.graphql'
import Login from './components/authentication/Login/Login'

import { useLogin } from './hooks/init/useLogin'
import { LoyaltyReward } from './components/LoyaltyReward'
import { ProfilePage } from './pages/Profile'
import {
  changeSubTab,
  changeTab,
  getProfileSubTabs,
  ProfileSubTab,
  ProfileTab,
  profileTabs,
} from './reducers/profile.reducer'
import { AuthenticationModal } from './components/authentication/Authentication/Modal'
import { open } from './reducers/authModal.reducer'
import { ActionBannersProvider } from './context/ActionBannerContext'
import { CompetitionsProvider } from './context/CompetitionsContext'
import formatError from './components/errorFormatter'
import { toast } from 'react-toastify'
import { allWalletQuery } from './components/rightSide/walletComponent/WalletComponent'
import { WalletComponentAllWalletsQuery } from './components/rightSide/walletComponent/__generated__/WalletComponentAllWalletsQuery.graphql'
import { WalletConstant } from './reducers/wallet.reducer'
import AppNavigation from './components/AppNavigation'

if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  ReactGA.initialize('G-R5Q55NTYPP')
}

export type LastBet = {
  bets: {
    biggestWins: Array<UserBet>
    biggestWinsGlobal: Array<UserBet>
    recent: Array<UserBet>
    highrollers: ReadonlyArray<UserBet>
  } | null
}

export type BetStatistic = {
  biggestWins: ReadonlyArray<UserBet>
  biggestWinsGlobal: ReadonlyArray<UserBet>
  recent: ReadonlyArray<UserBet>
  highrollers: ReadonlyArray<UserBet>
}

export type CompetitionRound = {
  roundId: number
  startTime: number
  endTime: number
  payoutsDone: boolean
  payouts: {
    price: number
    votingFreerollCount: number | null
    votingFreerollGame: string | null
    payoutStr: string
    payouts: ReadonlyArray<{
      user: {
        uuid: string
        name: string
      }
      value: string | null
    }>
    votingPayouts: ReadonlyArray<{
      user: {
        uuid: string
        name: string
      }
      value: string | null
    }> | null
  } | null
  votes: ReadonlyArray<{
    user: {
      uuid: string
      name: string
    }
    votes: ReadonlyArray<{
      user: {
        uuid: string
        name: string
      }
      value: string | null
    }>
  }>
  players: ReadonlyArray<{
    user: {
      uuid: string
      name: string
      hasProfileImage: boolean
      vip: {
        badgeId: string | null
        offset: number | null
        avatar: string | null
      } | null
    }
    amount: number
    bet: {
      multiplier: number
      normalizedGame: NewGameListComponentNormalizedGamesQuery$data['listNormalizedGames']['entries'][number]
      bets: number[]
      win: number
      btcWin: number
      currency: Currency
    }
  }>
}

export interface PayoutEntry {
  amount: number
  type: 'freeroll' | 'credit' | 'fixed_credit' | 'rakeback'
  unit: 'spins' | 'percent'
  game?: string
}

export type Competition = {
  compId: number
  type: CompType
  name: string
  autoPayouts: boolean
  autoRenew: boolean
  enabled: boolean
  votingEnabled: boolean
  chatAnnouncement: boolean
  payoutStr: PayoutEntry[]
  price: number
  currency: Currency
  period: string
  votingFreerollCount: number | null
  votingFreerollGame: string | null
  insertedAt: string
  payoutToComp: boolean
  position: number
  runningRound: CompetitionRound | null
}

export let serverTimeDiff = 0
export const errorqueue = new Subject<any>()
export const betStatisticQueue = new ReplaySubject<BetStatistic>(1)
export const chatUserVipUpdated = new Subject<{
  uuid: string
  badgeId: string
  offset: number
  appender: number
}>()

export let mobile = false

export const setMobile = (isMobile: boolean) => {
  mobile = isMobile
}

export const setTheme = () => {
  const e = document.querySelector('.app-frame')
  if (!e) {
    return
  }
  const bg = getUserPropOr(UserProp.BACKGROUND, 'dark')
  if (bg) {
    localStorage.setItem('theme', bg as string)
    e.classList.add('theme-' + bg)
  } else {
    e.classList.add('theme-dark')
  }
}

export const removeTheme = () => {
  const cl = document.querySelector('.app-frame')?.classList
  if (!cl) {
    return
  }
  const toRemove: string[] = []
  for (let i = 0; i < cl.length; i++) {
    const item = cl.item(i)
    if (item && item.startsWith('theme-')) {
      toRemove.push(item)
    }
  }
  toRemove.forEach((x) => cl.remove(x))
}

export const changeChatVisibility = new Subject<boolean>()

export const getUserIcon = (
  name: string,
  uuid: string,
  has_profile_image: boolean,
  vip?:
    | {
        badgeId?: string | null
        offset?: number | null
        avatar?: string | null
      }
    | undefined,
  noLink?: boolean,
  size?: string
): JSX.Element => {
  const avatarSize = size ?? '30px'
  const privateUser = name === 'Private user'

  if (has_profile_image) {
    const img = (
      <img
        alt="user-avatar"
        src={'/api/avatar/user/' + uuid}
        className="aspect-square object-cover rounded-full"
        style={{ width: avatarSize }}
      />
    )

    if (privateUser) {
      return (
        <img
          alt="user-avatar"
          src={'/assets/profile/profile_private.png'}
          className="aspect-square object-cover rounded-full"
          style={{ width: avatarSize }}
        />
      )
    } else {
      // ?temporarily removing url to the profile,
      // !TODO: put the Link back when profile changes are made.
      // return <Link to={`/profile/${uuid}`}>{img}</Link>
      return img
    }
  }

  if (name === 'System') {
    return (
      <img
        alt="System"
        src={'/assets/chat/system.png'}
        className="aspect-square object-cover rounded-full"
        style={{ width: avatarSize }}
        loading="lazy"
      />
    )
  }

  if ((vip?.avatar?.length || 0) > 0) {
    const img = (
      <img
        alt="vip-user-avatar"
        src={'/api/avatar/' + vip?.avatar}
        className="aspect-square object-cover rounded-full"
        style={{ width: avatarSize }}
        loading="lazy"
      />
    )

    if (privateUser || noLink) {
      return (
        <img
          alt="user-avatar"
          src={'/assets/profile/profile_private.png'}
          className="aspect-square object-cover rounded-full"
          style={{ width: avatarSize }}
        />
      )
    } else {
      // ?temporarily removing url to the profile,
      // !TODO: put the Link back when profile changes are made.
      // return <Link to={`/profile/${uuid}`}>{img}</Link>
      return img
    }
  }

  const getDefaultIcon = (privateUser: boolean, size: string = '30px') => {
    const jdenticon = <Jdenticon className="" value={uuid || '__admin__'} size={size} />
    if (privateUser) {
      return (
        <img
          alt="user-avatar"
          src={'/assets/profile/profile_private.png'}
          className="aspect-square object-cover rounded-full"
          style={{ width: avatarSize }}
        />
      )
    }

    // ?temporarily removing url to the profile,
    // !TODO: put the Link back when profile changes are made.
    // return <Link to={`/profile/${uuid}`}>{jdenticon}</Link>
    return jdenticon
  }

  let userIcon
  if (undefined === vip || undefined === vip?.badgeId || null == vip.badgeId) {
    userIcon = getDefaultIcon(privateUser, avatarSize)
  } else {
    const icon = getProfileIcon(vip.badgeId, vip.offset as number, avatarSize)
    if (icon === undefined) {
      userIcon = getDefaultIcon(privateUser, avatarSize)
    } else {
      if (privateUser || noLink) {
        userIcon = (
          <img
            alt="user-avatar"
            src={'/assets/profile/profile_private.png'}
            className="aspect-square object-cover rounded-full"
            style={{ width: avatarSize }}
          />
        )
      } else {
        // ?temporarily removing url to the profile,
        // !TODO: put the Link back when profile changes are made.
        // userIcon = <Link to={`/profile/${uuid}`}>{icon}</Link>
        userIcon = icon
      }
    }
  }

  return userIcon
}

export const getUserLink = (user: { uuid: string | null; name: string | null }) => {
  if (user.name === 'Private user' || null === user.uuid || undefined === user.uuid) {
    return user.name
  }
  // ?temporarily removing url to the profile,
  // !TODO: put the Link back when profile changes are made.
  // return <Link to={`/profile/${user.uuid}`}>{user.name}</Link>
  return user.name
}

export const getUserIconFromUser = (
  user: User,
  noLink: boolean = true,
  size: string = '30px',
  privateAcc?: boolean
): JSX.Element => {
  let vip
  const name = privateAcc ? 'Private user' : user.name
  const prop = user.preferences.find((x) => x.key === 'profile_badge')
  if (undefined !== prop && undefined !== prop.value) {
    const [id, sIdx] = prop.value.split('/')
    vip = { badgeId: id, offset: +sIdx }
  }

  return getUserIcon(name, user.uuid, user.hasProfileImage, vip, noLink, size)
}

export const showVipBar = new Subject<{ uuid: string } | undefined>()

const setModalView = (view, onClose: (() => void) | null = null) =>
  store.dispatch({
    type: AppTypes.SET_MODAL_VIEW,
    modal: { view: view, onClose: onClose ?? (() => {}) },
  })

function App() {
  const isMobile = useIsMobile()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loginFailure, setHasLoginFailure] = useState(false)
  const [me, setMe] = useState<User>()
  const [vipUser, setVipUser] = useState<string>()
  const [data, setData] = useState<{ listGames: Game[] }>()
  const collapsedMenu = useSelector((state: IStore) => state.app.collapsedMenu)
  const chatOpened = useSelector((state: IStore) => state.app.chatOpened)
  const isNotificationsOpen = useSelector((state: IStore) => state.app.isNotificationsOpen)
  const [signUserInTelegram] = useMutation<ApploginTelegramMutation>(loginTelegramMutation)
  const [tUser, setTUser] = useState(() => {
    try {
      const storedUser = localStorage.getItem('user')
      return storedUser ? JSON.parse(storedUser) : null
    } catch (error) {
      return null
    }
  })

  const headerHeight = document.querySelector<HTMLDivElement>('#app-header')?.getBoundingClientRect()?.height || 0

  const jackTokenIframe = useMemo(
    () => () =>
      (
        <iframe
          title="jack-token"
          src="https://jack-landing.netlify.app/"
          className="w-full"
          style={{
            height: 'calc(100dvh - ' + headerHeight + 'px)',
          }}
        />
      ),
    [headerHeight]
  )

  const pickWalletWithBalance = () => {
    localStorage.removeItem('pick_wallet_with_balance')
    // check if active currency selected in user preferences
    if (me && me.preferences.find((x) => x.key === UserProp.ACTIVE_COIN)) return

    fetchQuery<WalletComponentAllWalletsQuery>(Env, allWalletQuery, {}, { fetchPolicy: 'network-only' }).subscribe({
      error: (error) => {
        console.error('Error setting wallet', error)
      },
      next: (data) => {
        if (data.allWallets?.length) {
          const initialWallet = data.allWallets.find((wallet) => wallet != null) ?? { balance: 0 }

          const coinWithMostValue = data.allWallets.reduce((max, coin) => {
            return coin && max && coin.balance > max.balance ? coin : max
          }, initialWallet)

          store.dispatch({
            type: WalletConstant.SET_ACTIVE_WALLET,
            wallet: coinWithMostValue,
          })
        }
      },
    })
  }

  useEffect(() => {
    const pick_wallet_with_balance = localStorage.getItem('pick_wallet_with_balance')
    if (isLoggedIn && me && pick_wallet_with_balance) {
      pickWalletWithBalance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, me])

  useLockScroll(isMobile && (chatOpened || false))
  useEffect(() => {
    if (chatOpened) {
      document.getElementById('chat')?.focus()
    }
  }, [chatOpened])

  const matchesUsername = (dbUsername, inputUsername) => {
    if (!dbUsername) {
      return false
    }

    const normalizedDbUsername = dbUsername.startsWith('@') ? dbUsername.slice(1) : dbUsername
    const normalizedInputUsername = inputUsername.startsWith('@') ? inputUsername.slice(1) : inputUsername

    return normalizedDbUsername === normalizedInputUsername
  }

  useEffect(() => {
    TelegramWebApp.ready()
    if (TelegramWebApp && TelegramWebApp.initData && !isLoggedIn) {
      const telegramUser = TelegramWebApp.initDataUnsafe.user
      const initDataDecoded = decodeURIComponent(TelegramWebApp.initData)
      const initDataParams = new URLSearchParams(initDataDecoded)
      const tgWebAppData = initDataParams.get('tgWebAppData') || initDataParams.get('tgWebAppStartParam')

      // Check if already signed in
      if (tUser && telegramUser && matchesUsername(tUser.name, telegramUser.username)) {
        return
      }

      const variables: { input: LoginTelegramInput } = {
        input: { initData: TelegramWebApp.initData },
      }

      // Add the tgWebAppData to the input if it exists
      if (tgWebAppData) {
        variables.input.referralStr = tgWebAppData
      }

      signUserInTelegram({
        variables,
        onCompleted: (e) => {
          if (e.loginTelegram) {
            const token = e.loginTelegram.token
            const user = e.loginTelegram.user
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('pick_wallet_with_balance', '1')
            setTUser(user)
            window.location.href = '/'
          } else {
            toast.error('Unknown error, please try again.')
          }
        },
        onError: (e: GraphQLError) => {
          toast.error(formatError(e)[0])
        },
      })
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    setMe(store.getState().authentication.user)
    initBets()
    reloadBets()
  }, [isLoggedIn])

  useEffect(() => {
    const show_2fa_popup = localStorage.getItem('show_2fa_popup')
    const show_welcome_popup = localStorage.getItem('show_welcome_popup')
    const show_email_popup = localStorage.getItem('show_email_popup')
    const show_email_verified_popup = localStorage.getItem('show_email_verified_popup')

    if (show_2fa_popup) {
      localStorage.removeItem('show_2fa_popup')
      setModalView(<Toggle2FA />, () => {
        if (show_welcome_popup) {
          localStorage.removeItem('show_welcome_popup')
          setModalView(<AccountCreated />)
        }
      })
    } else if (show_welcome_popup) {
      localStorage.removeItem('show_welcome_popup')
      setModalView(<AccountCreated />)
    } else if (show_email_popup) {
      localStorage.removeItem('show_email_popup')
      dispatch(open({ tab: 'Verify' }))
    } else if (show_email_verified_popup) {
      localStorage.removeItem('show_email_verified_popup')
      dispatch(open({ tab: 'Verify Success' }))
    }
  }, [])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const vipSubs = showVipBar.subscribe((user: any) => {
      if (undefined === user || vipUser === user.uuid) {
        setTimeout(() => {
          setVipUser(undefined)
        }, 200)
        document.querySelector('.vip-bar')?.classList.add('slide-close')
        return
      }
      setVipUser(user.uuid)
    })

    const globalSettingsUpdated = requestSubscription<ConfigurationServiceGlobalSettingsUpdatedSubscription>(Env, {
      subscription: globalSettingsUpdatedSubscription,
      variables: {},
      onNext: (data) => {
        if (data) {
          ConfigurationService.instance.updateFromResponse(data)
        }
      },
    })

    return () => {
      vipSubs.unsubscribe()
      globalSettingsUpdated.dispose()
    }
  }, [isLoggedIn, vipUser])

  const history = useHistory()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    history.listen(() => {
      ReactGA.send('pageview')
    })

    if (token) {
      Cookie.set('bjfun-auth-token', token, {
        domain: `.blackjack.fun`,
        expires: 7,
      })
    } else {
      Cookie.remove('bjfun-auth-token', {
        domain: `.blackjack.fun`,
      })
    }

    const subscription$ = requestSubscription<GameListComponentSGameClosedSubscription>(Env, {
      subscription: gameClosedSubscription,
      variables: {},
      onNext: () => {
        history.push('/')
      },
    })

    return () => {
      subscription$.dispose()
    }
  }, [token, history, isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const subscription$ = requestSubscription<AppComponentServerTimeTickSubscription>(Env, {
      subscription: serverTimeSubscription,
      variables: {},
      onNext: (time) => {
        if (!time?.serverTimeTick) {
          return
        }
        const ourTime = new Date().getTime()
        const serverTime = time.serverTimeTick
        serverTimeDiff = serverTime - ourTime
      },
    })

    return () => {
      subscription$.dispose()
    }
  }, [isLoggedIn])

  useEffect(() => {
    const subs = userUpdated.subscribe(() => {
      setMe(store.getState().authentication.user)
    })

    return () => {
      subs.unsubscribe()
    }
  }, [me])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const subscription$ = requestSubscription<AppComponentUserUpdatedSubscription>(Env, {
      subscription: userUpdatedSubscription,
      variables: {},
      onNext: (resp) => {
        console.log(resp)
      },
    })

    return () => {
      subscription$.dispose()
    }
  }, [isLoggedIn])

  useEffect(() => {
    let isCancelled = false
    const fetchData = async () => {
      const data = await fetch('/assets/games.json')
      const json = await data.json()
      if (isCancelled) {
        return
      }
      setData(json)
    }

    fetchData().catch(console.error)

    return () => {
      isCancelled = true
    }
  }, [])

  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    const subscription$ = requestSubscription<AppComponentBroadcastCreatedSubscription>(Env, {
      subscription: broadcastCreatedSubscription,
      variables: {
        windowId: null,
      },
      onNext: (broadcast) => {
        const broadcastCreated = broadcast?.broadcastCreated as { action?: string; payload?: string }
        if (broadcastCreated?.action === 'WEB_APP_OPEN_SIGNIN') {
          setModalView(<Login />)
        }

        if (broadcastCreated?.action === 'TOURNAMENT_ID_CHANGED') {
          const payload = broadcastCreated.payload
          if (!payload) return

          let url = `/tournament/${payload}`
          if (params.get('track_id')) {
            url += `?track_id=${params.get('track_id')}`
          }

          window.history.pushState({}, '', url)
        }
      },
    })

    return () => {
      subscription$.dispose()
    }
  })

  useEffect(() => {
    const unsub = store.subscribe(() => {
      const isLoggedInNext = store.getState().authentication.loggedIn
      if (isLoggedInNext && !isLoggedIn) {
        setTimeout(() => {
          setIsLoggedIn(true)
        })
      }
      if (store.getState().authentication.error) {
        setTimeout(() => {
          setHasLoginFailure(true)
        })
      }
    })

    return () => {
      unsub()
    }
  })

  const { fetchUser } = useLogin()

  const memoizedFetchUser = useMemo(
    () => fetchUser,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    memoizedFetchUser()
  }, [memoizedFetchUser])

  const dispatch = useDispatch()
  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const profileTab = urlParams.get('tab')
    const profileTabAllowedValues = profileTabs.map((pt) => pt.name.toLowerCase())

    if (!profileTab || !profileTabAllowedValues.includes(profileTab.toLowerCase())) return
    dispatch(changeTab({ tab: profileTab as ProfileTab }))

    const profileSubTab = urlParams.get('stab')
    if (profileSubTab) {
      dispatch(changeSubTab({ subTab: profileSubTab as ProfileSubTab<ProfileTab> }))
      return
    }

    const subTabs = [...getProfileSubTabs(profileTab as ProfileTab)]
    const firstEnabled = subTabs.find((st) => st.disabled === false)

    if (!firstEnabled) return
    dispatch(changeSubTab({ subTab: firstEnabled.name as ProfileSubTab<ProfileTab> }))
  }, [search, dispatch])

  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const btag = urlParams.get('btag')

    if (btag && !isLoggedIn) {
      dispatch(open({ tab: 'Join Us' }))
    }
  }, [search, dispatch, isLoggedIn])

  useEffect(() => {
    if (me?.kycState === 'waiting_submission') {
      history.push(`/profile/${me.uuid}?tab=settings&stab=KYC`)
    }
  }, [me, history])

  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  mobile = w < 1400

  const theme = getUserPropOr(UserProp.BACKGROUND, 'dark')

  let vipBar: JSX.Element | null = null
  if (vipUser) {
    vipBar = (
      <div className="vip-bar">
        <VIPProfileComponent closed={true} uuidR={vipUser} />
      </div>
    )
  }

  if (loginFailure) {
    return <div className="login-error">Could not connect to the server, please try again later...</div>
  }

  if (me?.kycState === 'waiting_submission') {
    return (
      <>
        <ProfilePage />
        <ReactTooltip />
      </>
    )
  }

  return (
    <>
      <div
        className={classNames(`app-frame theme-${theme}`, {
          chatOpened: chatOpened,
          collapsed: collapsedMenu,
          notificationsOpened: isNotificationsOpen,
        })}
      >
        {me && (
          <>
            <AppNavigation me={me} />
            <div className="app-body">
              <AppTop searchData={data!} user={me} />
              <AppContent>
                <ActionBannersProvider>
                  <CompetitionsProvider>
                    <Switch>
                      <Route path="/signin">
                        <SignInComponent />
                      </Route>
                      <Route path="/confirm/:uuid/:secret" component={ConfirmEmailComponent} />
                      <PrivateRoute path="/responsible-gaming" component={ResponsibleGaming} />
                      <PrivateRoute path="/kyc-policy" component={KYCInfo} />
                      <PrivateRoute path="/kyc-setup" component={KYCSetup} />
                      <PrivateRoute path="/logout" component={LogoutComponent} />
                      <PrivateRoute path="/coins" component={CoinsComponent} />
                      <PrivateRoute path="/provably-fair/:subpage" component={ProvablyFairComponent} />
                      <PrivateRoute path="/provably-fair" component={ProvablyFairComponent} />
                      <PrivateRoute path="/vip/:uuid?" component={VIPProfileComponent} />
                      <PrivateRoute path="/profile/:uuid?" component={ProfilePage} />
                      <PrivateRoute path="/settings" component={SettingsComponent} />
                      <PrivateRoute path="/games" component={NewGameListComponent} componentProps={{ data }} />
                      <PrivateRoute path="/tournament/:tid" component={Tournament} />
                      <PrivateRoute
                        path="/live-games"
                        component={NewGameListComponent}
                        componentProps={{ category: 'live' }}
                      />
                      <PrivateRoute
                        path="/slots"
                        component={NewGameListComponent}
                        componentProps={{ category: 'slots' }}
                      />
                      <PrivateRoute path="/campaign/:campaignId" component={CampaignPage} />
                      <PrivateRoute path="/game/:gameId" component={NewGameListComponent} />
                      <PrivateRoute path="/game/:gameId/:subGameId" component={NewGameListComponent} />
                      <PrivateRoute path="/competitions" component={CompetitionsPage} />
                      <PrivateRoute path="/notifications" component={NotificationTestPage} />
                      <PrivateRoute path="/elite-seat" component={EliteSeatPage} />
                      <PrivateRoute path="/jack-token" component={jackTokenIframe} />
                      <PrivateRoute path="/:action/:key" component={LandingPageComponent} />
                      <PrivateRoute
                        path="/"
                        component={LandingPageComponent}
                        componentProps={{
                          me,
                        }}
                      />
                      <Redirect from="*" to="/" />
                    </Switch>
                  </CompetitionsProvider>
                </ActionBannersProvider>
              </AppContent>
              <Notifications />
              <LoyaltyReward />
              {vipBar}
            </div>
            <Chat />
            <AuthenticationModal />
            <ModalComponent />
            <Modal />
            <ReactTooltip />
            <Toaster />
          </>
        )}
      </div>
    </>
  )
}

export default App

const serverTimeSubscription = graphql`
  subscription AppComponentServerTimeTickSubscription {
    serverTimeTick
  }
`

graphql`
  fragment AppCompetitionRound on CompetitionRound {
    roundId: id
    startTime
    endTime
    payoutsDone
    payouts {
      price
      payoutStr
      votingFreerollCount
      votingFreerollGame
      payouts {
        user {
          uuid
          name
        }
        value
      }
      votingPayouts {
        user {
          uuid
          name
        }
        value
      }
    }
    votes {
      user {
        uuid
        name
      }
      votes {
        user {
          uuid
          name
        }
        value
      }
    }
    players {
      user {
        uuid
        name
        vip {
          badgeId
          offset
          avatar
        }
        hasProfileImage
      }
      amount
      bet {
        multiplier
        bets
        win
        currency
        btcWin
        normalizedGame {
          label
          gameId
          provider
          urlThumb
        }
      }
    }
  }
`
graphql`
  fragment AppCompetitionFragment on Competition {
    compId: id
    type
    name
    autoPayouts
    autoRenew
    enabled
    votingEnabled
    chatAnnouncement
    payoutStr
    price
    currency
    period
    votingFreerollCount
    votingFreerollGame
    insertedAt
    payoutToComp
    position
    runningRound {
      ...AppCompetitionRound @relay(mask: false)
    }
  }
`

export const listCompetitionsQuery = graphql`
  query AppComponentlistCompetitionsQuery {
    listCompetitions {
      ...AppCompetitionFragment @relay(mask: false)
    }
  }
`

export const competitionUpdatedSubscription = graphql`
  subscription AppComponentCompetitionUpdatedSubscription {
    competitionUpdated {
      ...AppCompetitionFragment @relay(mask: false)
    }
  }
`

export const roundEndedSubscription = graphql`
  subscription AppComponentRoundEndedSubscription {
    roundEnded {
      ...AppCompetitionRound @relay(mask: false)
    }
  }
`

export const roundAddedSubscription = graphql`
  subscription AppComponentRoundAddedSubscription {
    roundAdded {
      ...AppCompetitionRound @relay(mask: false)
    }
  }
`

export const roundDeletedSubscription = graphql`
  subscription AppComponentRoundDeletedSubscription {
    roundDeleted {
      ...AppCompetitionRound @relay(mask: false)
    }
  }
`

export const userUpdatedSubscription = graphql`
  subscription AppComponentUserUpdatedSubscription {
    userUpdated {
      preferences {
        key
        value
      }
    }
  }
`

export const betUpdatedSubscription = graphql`
  subscription AppComponentBetUpdatedSubscription {
    betUpdated {
      gameId
      gameProvider
      currency
      virtualId
      finished
      bets
      win
      btcBet
      btcWin
      profit
      multiplier
      meta
      insertedAt
      normalizedGame {
        label
        gameId
        provider
      }
      user {
        uuid
        name
        vip {
          badgeId
          offset
          avatar
        }
        hasProfileImage
      }
    }
  }
`

export const broadcastCreateMutation = graphql`
  mutation AppComponentCreateBroadcastMutation($input: CreateBroadcastInput!) {
    createBroadcast(input: $input) {
      result
    }
  }
`

export const broadcastCreatedSubscription = graphql`
  subscription AppComponentBroadcastCreatedSubscription($windowId: String) {
    broadcastCreated(windowId: $windowId)
  }
`

export const loginTelegramMutation = graphql`
  mutation ApploginTelegramMutation($input: LoginTelegramInput!) {
    loginTelegram(input: $input) {
      token
      user {
        name
        username
        otpEnabled
        anonymousHash
        uuid
        roles
        confirmed
        preferences {
          key
          value
          valueJson
        }
      }
    }
  }
`

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Banner1 = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div
      className="relative flex flex-row justify-between items-center rounded-4 overflow-hidden h-160 md:h-auto bg-cover bg-center"
      style={{
        backgroundImage: 'url("/assets/pedestal/bg-big.png")',
      }}
    >
      <div className="z-10 text-white text-sm max-w-140 md:max-w-none md:text-xl lg:text-2xl font-medium ml-15 md:ml-20 lg:ml-30">
        <p>{t('banners.hiThere', 'Hi there,')}</p>
        <p className="linerGradientText text-xl md:text-4xl lg:text-5xl font-extrabold whitespace-nowrap">
          {t('banner1.amount', '$100,000')}
        </p>
        <p>{t('banners.banner1.rewards', 'Given this month in competition rewards')}</p>
        <button
          className="bg-gradient-to-r-custom font-semibold px-10 py-5 mt-10 md:px-15 md:py-10 lg:px-20 md:mt-20 rounded-4 cursor-pointer"
          onClick={() => history.push('/games')}
        >
          {t('banners.banner1.playNow', 'Play Now')}
        </button>
      </div>
      <div className="absolute bottom-0 right-0 md:static md:flex-shrink-0 pt-2 md:pt-5 lg:pt-10 w-1/2 md:w-auto">
        <img
          alt="girl and guy"
          src="/assets/hero_carousel/girl-and-guy.png"
          draggable={false}
          className="object-contain h-full w-full md:h-full md:w-auto"
        />
      </div>
    </div>
  )
}

export default Banner1

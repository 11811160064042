import React from 'react'
import { useTranslation } from 'react-i18next'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from './inviteYourFriend.module.scss'
import { useSelector } from 'react-redux'
import { IStore } from '@src/store'

export function InviteYourFriend({ username, closeCallback }: { username?: string; closeCallback: () => void }) {
  const { t } = useTranslation()
  const me = useSelector((state: IStore) => state.authentication.user)

  const baseURL = window.location.origin
  const url = `${baseURL}/bonus/${me?.uuid}`

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h2 className="text-sm font-poppins font-medium leading-6 uppercase pt-14 pb-20">
          {t('profile.inviterYourFriends.invite', 'INVITE YOUR FRIEND')}
        </h2>
        <FontAwesomeIcon icon={faXmark} className={classes.xMark} onClick={closeCallback} />
      </div>
      <div className={classes.content}>
        <p className="font-inter text-sm leading-5 text-center font-normal">
          <span className="font-bold">
            {t('profile.inviterYourFriends.heading', 'Invite your friends and start winning.')}
          </span>
          <br />
          {t(
            'profile.inviterYourFriends.text',
            'Blackjack.fun offers a new opportunity for all players to increase their winnings. Use the referral link to bring new players and you will receive 10% from their rakeback.'
          )}
        </p>
        <div className={classes.inputs}>
          <div className="flex flex-col w-full px-12 relative gap-y-10">
            <label htmlFor="referral-link">{t('profile.inviterYourFriends.referralLink', 'Referral link')}</label>
            <input name="referral-link" value={url} autoComplete="off" autoCorrect="off" spellCheck="false" readOnly />
            <div className={classes.button}>
              <button
                onClick={() => {
                  if (!url) return
                  navigator.clipboard.writeText(url)
                }}
              >
                {t('wallet.depositView.copy', 'Copy')}
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full px-12 relative gap-y-10">
            <label htmlFor="promo-code">{t('profile.inviterYourFriends.promoCode', 'Promo code')}</label>
            <input
              name="promo-code"
              value={username}
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              readOnly
            />
            <div className={classes.button}>
              <button
                onClick={() => {
                  if (!username) return
                  navigator.clipboard.writeText(username)
                }}
              >
                {t('wallet.depositView.copy', 'Copy')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import React from 'react'

import classes from './Announcement.module.scss'

export function Announcement({
  header,
  username,
  imageSRC = 'https://cdn.sanity.io/images/rkf044et/production/2f22fb213864a2e795e5d6ca9dacbda5e9645c34-121x122.png',
  text,
  createdAt,
}: {
  header: string
  username: string
  imageSRC?: string
  text: string
  createdAt: string
}) {
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <img src={imageSRC} alt="Announcement" />
      </div>
      <div className={classes.content}>
        <div className={classes.header}>
          <span className="text-darkGrey font-inter">{dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
          {/* <span className="text-darkGrey font-inter">06/10/2022 08:13:43 AM</span> */}
          <h2 className="text-white font-poppins text-lg leading-6">{header}</h2>
        </div>
        <p className="text-darkGrey text-sm leading-6 font-inter">
          <span className="text-yellow">
            {t('general.hi', 'Hi')} {username},
          </span>
          <br />
          <br />
          {text}
          <br />
          <br />
          <span className="text-eucalyptus">
            {t('notifications.thankYouForSupport', 'Thank you for your support.')}
          </span>
        </p>
      </div>
    </div>
  )
}

/**
 * @generated SignedSource<<b4f643dbbafd0b2eaeebdfeafe43dce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Toggle2FAGenerateOtpSecretMutation$variables = {};
export type Toggle2FAGenerateOtpSecretMutation$data = {
  readonly generateOtpSecret: {
    readonly url: string;
  } | null;
};
export type Toggle2FAGenerateOtpSecretMutation = {
  variables: Toggle2FAGenerateOtpSecretMutation$variables;
  response: Toggle2FAGenerateOtpSecretMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GenerateOtpSecretPayload",
    "kind": "LinkedField",
    "name": "generateOtpSecret",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Toggle2FAGenerateOtpSecretMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Toggle2FAGenerateOtpSecretMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6bfde886dbc8b94e84c81b64229e8c04",
    "id": null,
    "metadata": {},
    "name": "Toggle2FAGenerateOtpSecretMutation",
    "operationKind": "mutation",
    "text": "mutation Toggle2FAGenerateOtpSecretMutation {\n  generateOtpSecret {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "63141508e2c605ad78156a583aa97f69";

export default node;

export const KYCInfo = () => {
  return (
    <div className="rg prose">
      <h3>KYC Policy of Satya Code SRL.</h3>
      <p>Last updated 12 January 2022</p>
      <p>
        When a user makes an aggregate lifetime total of deposits exceeding EUR 2000 or requests a withdrawal of any
        amount inside the https://blackjack.fun Platform, then it is compulsory for them to perform a full KYC process.
        During this process, the user will have to input some basic details about themselves and then upload
      </p>
      <ul className="list-disc list-inside">
        <li>A copy of Government Issued Photo ID (in some cases front and back depending on the doc)</li>
        <li>A selfie of themselves holding the ID doc</li>
        <li>A bank statement/Utility Bill</li>
      </ul>
      <p>
        Once uploaded, the user will get a “Temporarily Approved” Status and the documents will now be on our side, and
        the “KYC Team” will have 24hrs to go over them and email the user about the outcome:
      </p>
      <ul className="list-disc list-inside">
        <li>Approval</li>
        <li>Rejection</li>
        <li>More information needed – No change in Status</li>
      </ul>
      When the user is on “Temporarily Approved” Status then
      <ul className="list-disc list-inside">
        <li>They can use the platform normally </li>
        <li>They cannot deposit more than EUR 500 in aggregate total</li>
        <li>They cannot complete any withdrawal.</li>
      </ul>
      <div className="faq-line">
        <div className="header">
          <span>Guideline for the “KYC Process”</span>
        </div>
        <div className="body">
          <ul className="list-disc list-inside">
            <li>
              Proof of ID
              <ul className="list-disc list-inside">
                <li>Signature Is there</li>
                <li>
                  Country is not a Restricted Country: United States of America and its territories, France and its
                  territories, Netherlands and its territories and countries that form the Kingdom of Netherlands
                  including Bonaire, Sint Eustatius, Saba, Aruba, Curacao and Sint Maarten, Australia and its
                  territories, United Kingdom of Great Britain, Northern Ireland, Spain.{' '}
                </li>
                <li>Full Name matches client’s name</li>
                <li>Document does not expire in the next 3 months</li>
                <li>Owner is over 18 years of age</li>
              </ul>
            </li>
            <li>
              Proof of Residence
              <ul className="list-disc list-inside">
                <li>Bank Statement or Utility Bill</li>
                <li>
                  Country is not a Restricted Country: United States of America and its territories, France and its
                  territories, Netherlands and its territories and countries that form the Kingdom of Netherlands
                  including Bonaire, Sint Eustatius, Saba, Aruba, Curacao and Sint Maarten, Australia and its
                  territories, United Kingdom of Great Britain, Northern Ireland, Spain, and Cyprus.{' '}
                </li>
                <li>Full Name matches client’s name and is same as in proof of ID.</li>
                <li>Date of Issue: In the last 3 months</li>
              </ul>
            </li>
            <li>
              Selfie with ID
              <ul className="list-disc list-inside">
                <li>Holder is the same as in the ID document above</li>
                <li>ID document is the same as in “1”. Make sure photo/ID number is the same</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="faq-line">
        <div className="header">
          <span>Notes on the "KYC Process"</span>
        </div>
        <div className="body">
          <ul className="list-disc list-inside">
            <li>
              When the KYC process is unsuccessful then the reason is documented and a support ticket is created in the
              system. The ticket number along with an explanation is communicated back to the user.
            </li>
            <li>Once all proper documents are in our possession then the account gets approved.</li>
          </ul>
        </div>
      </div>
      <div className="faq-line">
        <div className="header">
          <span>"Other AML measures"</span>
        </div>
        <div className="body">
          <ul className="list-disc list-inside">
            <li>
              If a user has not passed full KYC then they cannot make additional deposits or withdrawals of any amount.{' '}
            </li>
            <li>If a user has passed the KYC process successfully then</li>
            <li>There is a deposit limit per transaction (max EUR 2,000)</li>
            <li>
              Prior to any withdrawal there is a detailed algorithmic and manual check on the activity and balance of
              the user to see if the amount withdrawn is a result of proper activity in the platform.{' '}
            </li>
            <li>Under no circumstances may a user transfer funds directly to another user. </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

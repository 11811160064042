const TelegramWhite = (props: any) => (
  <svg {...props} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5936 0.0214066L0.191244 4.21187C-0.065543 4.3154 -0.0629974 4.69437 0.194425 4.79524L2.72923 5.7828L3.71063 9.0773C3.77356 9.28834 4.02081 9.366 4.18416 9.22596L5.59712 8.02338C5.74522 7.89728 5.95624 7.8913 6.1107 8.00811L8.65885 9.94008C8.83428 10.0735 9.08281 9.9726 9.12667 9.75159L10.9935 0.375811C11.0418 0.134232 10.8142 -0.0675263 10.5936 0.0214066ZM8.60991 2.2142L4.52167 6.18233C4.37802 6.3217 4.28521 6.50886 4.25916 6.71195L4.11996 7.78976C4.10153 7.93378 3.90767 7.94772 3.87017 7.80834L3.33434 5.84319C3.27332 5.61887 3.36232 5.37994 3.55236 5.25716L8.50631 2.07151C8.5953 2.01443 8.68682 2.13987 8.61055 2.2142H8.60991Z"
      fill="white"
    />
  </svg>
)

export default TelegramWhite

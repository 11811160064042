/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Arrow = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none" {...props}>
    <path
      d="M9.81158 1L5.40579 5.09302L0.99999 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Arrow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-relay'
import { useSelector } from 'react-redux'
import ConfigurationService from '../../../service/ConfigurationService'
import formatError from '../../errorFormatter'
import { getLogoForCoin } from '../../coinsComponent/CoinsComponent'
import { getUserIcon, getUserLink } from '../../../App'
import { voteWinnerMutation } from '../../../queries/voteWinner'
import { voteWinnerMutation as voteWinnerMutationType } from '../../../queries/__generated__/voteWinnerMutation.graphql'
import { store, IStore } from '../../../store'
import { AppTypes } from '../../../reducers/app.reducer'
import ICompetitionResultsRow from './CompetitionResultsRow.interface'
import styles from './CompetitionResultsRow.module.scss'
import { roundNumberToLength } from '../../../utils/functions'
import { formatFiatRaw } from '../../rightSide/walletComponent/WalletComponent'
import { getUserPropOr } from '../../SettingsComponent/SettingsComponent'
import { UserProp } from '../../../reducers/authentication.reducer'
import { formatAmount } from '../../../reducers/wallet.reducer'
import { Link } from 'react-router-dom'
import { Game } from '@src/components/gameListComponent/GameListComponent.types'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { Money } from '@src/money'

const CompetitionResultsRow: React.FC<ICompetitionResultsRow> = ({ i, competition, votes, setVotes, winner }) => {
  const { t } = useTranslation()
  const currenUser = useSelector((state: IStore) => state.authentication.user)
  const [commit] = useMutation<voteWinnerMutationType>(voteWinnerMutation)
  const showNotification = (status, text) =>
    store.dispatch({ type: AppTypes.SHOW_NOTIFICATION, notification: { status: status, text: text } })

  const player = competition.runningRound?.players[i]
  if (!player) {
    return null
  }
  const user = player?.user
  const payout = competition.payoutStr[i]
  let voted = false
  let votedFor
  if (currenUser) {
    const userVotes = votes.find((x) => x.user.uuid === currenUser.uuid)
    if (userVotes !== undefined) {
      voted = true
      votedFor = userVotes.votes[0].user.uuid
    }
  }

  const playerVotes = votes.filter((x) => x.votes[0].user.uuid === user?.uuid).length

  const currency = competition.currency
  const c = ConfigurationService.instance.getCurrency(currency)
  const cformat = getUserPropOr(('unit_' + c.short) as UserProp, c.format)
  const compAmount =
    competition.type === 'MULTIPLIER'
      ? formatFiatRaw(store.getState().wallet.rates, { balance: player!.bet.btcWin, currency: 'BTC' })
      : competition.type !== 'GAME_COUNT'
      ? formatFiatRaw(store.getState().wallet.rates, { balance: player!.amount, currency: 'BTC' })
      : player?.amount

  const competitionAmount = roundNumberToLength(Number(compAmount), 5)

  const formattedWinAmount =
    competition.type !== 'HIGHEST_WIN'
      ? formatAmount((competition.price * payout.amount) / 100, currency, false)
      : formatAmount(
          player.bet.bets.reduce((acc, x) => acc + x, 0),
          player.bet.currency,
          true
        )

  const winAmount = formattedWinAmount?.split(' ')[0]
  const winCurrency = formattedWinAmount?.split(' ')[1] || cformat

  if (!currenUser || !user) return null

  const HandleClick = (event, place, roundId, user) => {
    const userUuid = user.uuid
    commit({
      variables: { input: { place, roundId, userUuid } },
      onCompleted: () => {
        setVotes([
          ...votes,
          {
            user: currenUser,
            votes: [
              {
                user: user,
                value: null,
              },
            ],
          },
        ])

        showNotification('success', t('competitions.table.voted', 'Voted'))
      },
      onError: (e) => {
        showNotification('error', formatError(e)[0])
      },
    })
  }

  let competitionType
  if (competition.type === 'HIGHEST_WIN') {
    competitionType = 'Winning amount'
  } else if (competition.type === 'GAME_COUNT') {
    competitionType = 'Bets'
  } else if (competition.type === 'HIGHEST_BET') {
    competitionType = 'Bet amount'
  } else {
    competitionType = 'Wagered'
  }

  if (!winner && i === 0) return null

  const formatMultiplier = (multiplier: number) => {
    return parseInt(multiplier.toString())
  }

  const getGameLink = (game?: Game) => {
    if (!game) return '#'
    return `/game/${game.provider}:${game.gameId}`
  }

  const getFixedCreditTableRow = () => {
    if (competitionType === 'Wagered') {
      return (
        <>
          <span className="">{Money.fromInteger(payout.amount, currency, cformat)}</span>
          <span className="text-eucalyptus">USD</span>
        </>
      )
    } else {
      return (
        <>
          <img
            src={getLogoForCoin(competition.currency)}
            alt={competition.currency}
            className="w-14 aspect-square object-contain object-center mr-3"
          />
          <span className="">{Money.fromInteger(payout.amount, currency, cformat)}</span>
          <span className="text-eucalyptus">{cformat}</span>
        </>
      )
    }
  }

  const prizeText =
    competition.type !== 'HIGHEST_WIN'
      ? competition.payoutToComp
        ? t('competitions.table.prizeBonus', 'Prize (Bonus wallet)')
        : t('competitions.table.prizeMainWallet', 'Prize (Main wallet)')
      : t('competitions.table.betAmount', 'Bet amount')

  const isDailyOrWeeklyWageringCompetition =
    competition.name.toLowerCase() === 'daily wagering competition' ||
    competition.name.toLowerCase() === 'weekly wagering competition'

  if (winner)
    return (
      <div
        className={`${
          styles.winner
        } mt-20 py-9 px-24 bg-raisinBlack border border-solid text-paleSky flex items-center hover:border-yellow ${
          votedFor && votedFor === user.uuid ? 'border-yellow' : ''
        }`}
      >
        <div className="w-90 xxl:w-130">
          {competition.type === 'MULTIPLIER' ? (
            <div className="mb-5 text-eucalyptus font-black text-sm">${competitionAmount}</div>
          ) : (
            <div className="mb-5">{t('competitions.table.firstPlace', 'First place')}</div>
          )}
          <div
            className={`${styles.largeText} text-white uppercase overflow-ellipsis overflow-hidden whitespace-nowrap mb-10`}
          >
            {getUserLink(user)}
          </div>
          <div className="flex gap-x-7">
            {(!voted || (votedFor && votedFor === user.uuid)) && (
              <button
                className={`btn-yellow uppercase ${voted ? 'text-white bg-pink cursor-auto' : ''}`}
                type="button"
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  HandleClick(e, 0, competition.runningRound?.roundId, user)
                }
              >
                {!voted && t('competitions.table.vote', 'Vote')}
                {voted && votedFor && votedFor === user.uuid && t('competitions.table.voted', 'Voted')}
              </button>
            )}
            {playerVotes > 0 && (
              <div className={`${styles.votesNumber} rounded-full bg-pink text-white flex items-center justify-center`}>
                {playerVotes}
              </div>
            )}
          </div>
        </div>
        <div className="flex-grow flex items-center justify-center">
          <div className={`${competition.compId === 5 ? 'w-90' : 'w-75'} mx-10`}>
            {getUserIcon(
              user.name,
              user.uuid,
              user.hasProfileImage,
              user.vip || undefined,
              false,
              competition.compId === 5 ? '90px' : '75px'
            )}
          </div>
        </div>
        <div className="w-90 xxl:w-130 text-right">
          <div className="mb-5">
            {competition.type === 'MULTIPLIER'
              ? t('competitions.table.multiplier', 'Multiplier')
              : t(`competitions.table.${competitionType}`, competitionType)}
          </div>
          {competition.type === 'MULTIPLIER' ? (
            <div className={`${styles.largeText} text-eucalyptus mb-5`}>
              {formatMultiplier(
                competition.runningRound?.players.find((p) => p.user.uuid === user.uuid)?.bet.multiplier || 0
              )}
              x
            </div>
          ) : (
            <div className={`${styles.largeText} text-eucalyptus mb-5`}>
              {competition.type !== 'GAME_COUNT' ? '$' : ''}
              {competitionAmount}
            </div>
          )}
          <div className="mb-5">{competition.type === 'MULTIPLIER' ? t('general.game', 'Game') : prizeText}</div>
          {competition.type === 'MULTIPLIER' ? (
            <div className="text-white font-inter">
              <Link
                to={getGameLink(
                  competition.runningRound?.players.find((p) => p.user.uuid === user.uuid)?.bet.normalizedGame
                )}
              >
                {competition.runningRound?.players.find((p) => p.user.uuid === user.uuid)?.bet.normalizedGame?.label}
              </Link>
            </div>
          ) : (
            <>
              <div className={`${styles.prize} text-white break-normal`}>
                {(payout.type === 'credit' || payout.type === 'fixed_credit') &&
                  (isDailyOrWeeklyWageringCompetition ? (
                    <div className="ml-auto w-min">
                      <Tooltip
                        place="right"
                        text={t('competitions.table.rewardInWageredCurrency', 'Reward in wagered currency')}
                        type="dark"
                        effect="float"
                        className={styles.tooltip}
                      >
                        <div className="text-2xs">
                          <span>
                            {payout.type === 'fixed_credit' ? Money.fromInteger(payout.amount, currency, cformat) : winAmount}
                          </span>
                          <span className="text-eucalyptus">USD</span>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <>
                      <span className="">
                        {payout.type === 'fixed_credit' ? Money.fromInteger(payout.amount, currency, cformat) : winAmount}
                      </span>
                      <span className="text-eucalyptus">{winCurrency}</span>
                    </>
                  ))}
                {payout.type !== 'credit' && payout.type !== 'rakeback' && payout.type !== 'fixed_credit' && (
                  <>
                    <span className="">{payout.amount}</span>
                    <span className="">{t('competitions.table.spins', 'spin(s)')}</span>
                  </>
                )}
                {payout.type === 'rakeback' && (
                  <>
                    <span className="font-bold">{payout.amount}%</span>
                    <span className="text-eucalyptus">{t('competitions.table.rakeback', 'Rakeback')}</span>
                  </>
                )}
              </div>
              {isDailyOrWeeklyWageringCompetition && (
                <span className="py-2 inline-flex">{t('competitions.table.inCrypto', 'in crypto')}</span>
              )}
            </>
          )}
        </div>
      </div>
    )

  return (
    <div
      className={`${styles.root} flex items-center h-42 border-solid group ${!voted ? styles.didntVote : ''} ${
        votedFor && votedFor === user.uuid ? styles.votedFor : ''
      }`}
      key={JSON.stringify([user, payout])}
    >
      <div className={`${styles.index} w-18 flex-shrink-0 text-darkGrey`}>{i + 1}</div>
      <div
        className={`${styles.text} ${competition.type === 'MULTIPLIER' ? 'w-2/7' : 'w-3/7'} ${
          !voted ? 'group-hover:w-auto group-hover:flex-grow' : ''
        } flex items-center min-w-0`}
      >
        <div className="mr-4 flex-shrink-0">
          {getUserIcon(user.name, user.uuid, user.hasProfileImage, user.vip || undefined, false, '26px')}
        </div>
        <div className={`${styles.name} flex-grow overflow-ellipsis overflow-hidden whitespace-nowrap text-white pr-8`}>
          {getUserLink(user)}
        </div>
      </div>
      <div
        className={`${
          styles.wagger
        } w-2/7 flex items-center overflow-ellipsis overflow-hidden whitespace-nowrap text-eucalyptus ${
          !voted ? 'group-hover:hidden' : ''
        }`}
      >
        {competition.type !== 'MULTIPLIER' ? (
          <span className="">
            {competition.type !== 'GAME_COUNT' ? '$' : ''}
            {competitionAmount}
          </span>
        ) : (
          <Link
            to={getGameLink(
              competition.runningRound?.players.find((p) => p.user.uuid === user.uuid)?.bet.normalizedGame as Game
            )}
          >
            {competition.runningRound?.players.find((p) => p.user.uuid === user.uuid)?.bet.normalizedGame?.label}
          </Link>
        )}
      </div>
      <div
        className={`${styles.prize} min-w-100 w-2/7 flex items-center text-white break-normal ${
          !voted ? 'group-hover:hidden' : ''
        } pl-8`}
      >
        {payout.type === 'credit' && competition.type !== 'MULTIPLIER' && (
          <>
            {!isDailyOrWeeklyWageringCompetition && (
              <img
                src={getLogoForCoin(competition.currency)}
                alt={competition.currency}
                className="w-14 aspect-square object-contain object-center mr-3"
              />
            )}
            <span className="">{winAmount}</span>
            <span className="text-eucalyptus">{isDailyOrWeeklyWageringCompetition ? 'USD' : winCurrency}</span>
            {/* <span className="text-yellow">({payout.amount}%)</span> */}
          </>
        )}
        {competition.type === 'MULTIPLIER' && (
          <>
            <span className="text-eucalyptus font-black leading-6">
              {formatMultiplier(
                competition.runningRound?.players.find((p) => p.user.uuid === user.uuid)?.bet.multiplier || 0
              )}
              x
            </span>
          </>
        )}
        {payout.type !== 'credit' && payout.type !== 'rakeback' && payout.type !== 'fixed_credit' && (
          <>
            <span className="">{payout.amount}</span>
            <span className="">{t('competitions.table.spins', 'spin(s)')}</span>
            <span>{payout.type}</span>
          </>
        )}
        {payout.type === 'rakeback' && (
          <>
            <span className="font-bold">{payout.amount}%</span>
            <span className="text-eucalyptus">{t('competitions.table.rakeback', 'Rakeback')}</span>
          </>
        )}
        {payout.type === 'fixed_credit' && getFixedCreditTableRow()}
      </div>
      {!voted && (
        <div className={`${styles.text} items-center pr-15 hidden group-hover:flex`}>
          <button
            className={`btn-yellow w-85 uppercase`}
            type="button"
            onClick={(e: React.MouseEvent<HTMLElement>) => HandleClick(e, 0, competition.runningRound?.roundId, user)}
          >
            {t('competitions.table.voteNow', 'Vote now!')}
          </button>
        </div>
      )}
      {competition.type === 'MULTIPLIER' && (
        <div className={`${styles.text} w-2/7 flex items-center`}>
          <span className="text-eucalyptus font-inter font-black leading-6" style={{ fontSize: 10 }}>
            ${competitionAmount}
          </span>
        </div>
      )}
      <div className="w-44 flex-shrink-0 flex justify-center">
        {playerVotes > 0 && (
          <div className={`${styles.votesNumber} rounded-full bg-pink text-white flex items-center justify-center`}>
            {playerVotes}
          </div>
        )}
      </div>
    </div>
  )
}
export default CompetitionResultsRow

const Heart21Icon = ({ color = '#D5DCEB', ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
    <path
      d="M6.97268 8H4.03785L4.0172 7.95963C4.00573 7.92823 4 7.77684 4 7.50547C4 7.18475 4.22364 6.85394 4.67092 6.51304C5.0425 6.21475 5.30743 5.96692 5.4657 5.76955C5.62396 5.57219 5.7031 5.37819 5.7031 5.18755C5.7031 5.07766 5.67787 4.97337 5.6274 4.87468C5.53795 4.6975 5.40491 4.60892 5.22829 4.60892C5.0792 4.60892 4.95305 4.62461 4.84983 4.65601L4.68468 5.34903L4.05849 5.34567L4.00688 4.55509C4.2569 4.18503 4.73514 4 5.44161 4C5.60217 4 5.75127 4.01121 5.88889 4.03364C6.02651 4.05607 6.1584 4.09532 6.28456 4.15139C6.41301 4.20746 6.52196 4.27811 6.61142 4.36333C6.70087 4.44856 6.77198 4.55845 6.82473 4.69302C6.87749 4.82534 6.90387 4.97785 6.90387 5.15055C6.90387 5.27614 6.88322 5.39613 6.84194 5.51051C6.80294 5.62265 6.74904 5.72246 6.68023 5.80992C6.61371 5.89515 6.53343 5.98038 6.43939 6.0656C6.34534 6.15083 6.24671 6.22708 6.14349 6.29436C6.04257 6.36165 5.93362 6.43342 5.81664 6.50967C5.70195 6.58593 5.59185 6.65882 5.48634 6.72834C5.38312 6.79563 5.27876 6.873 5.17324 6.96047C5.07003 7.04794 4.98172 7.13541 4.90832 7.22288L6.97268 7.07149V8Z"
      fill="currentColor"
    />
    <path
      d="M7.8474 8V5.37595L7.22465 5.44323V4.72666C7.3531 4.70872 7.4609 4.68853 7.54807 4.66611C7.63523 4.64368 7.72698 4.60891 7.82331 4.56182C7.91965 4.51472 8.00222 4.44968 8.07104 4.36669C8.13985 4.28371 8.19605 4.18167 8.23963 4.06055H9V8H7.8474Z"
      fill="currentColor"
    />
    <path
      d="M7.03063 12.2988C6.95784 12.2988 6.88262 12.2819 6.81468 12.243C3.05375 10.1636 0.605503 7.40721 0.0935311 4.6775C-0.156389 3.3454 0.0983818 2.13948 0.811746 1.28538C1.53967 0.411878 2.46656 -0.032157 3.48807 0.00181274C4.88811 0.0479145 6.14742 0.945685 6.97968 1.89684C7.81193 0.945685 9.07124 0.0479145 10.4713 0.00181274C11.4952 -0.0297306 12.4173 0.411878 13.1476 1.28538C13.861 2.14191 14.14 3.33085 13.9338 4.63383C13.7057 6.06298 12.6866 8.86063 8.39427 11.5515C8.18317 11.6825 7.90899 11.6195 7.77797 11.4108C7.64694 11.2021 7.71003 10.9255 7.9187 10.7945C10.8328 8.96497 12.7036 6.66959 13.0481 4.4931C13.2131 3.44731 13.0044 2.51315 12.4585 1.85802C11.9053 1.19318 11.2453 0.872895 10.5004 0.894733C9.43035 0.931129 8.19045 1.70758 7.34121 2.87468C7.17136 3.10519 6.78556 3.10762 6.61571 2.87468C5.76647 1.70758 4.52658 0.931129 3.45653 0.894733C2.71163 0.870469 2.05164 1.19318 1.49842 1.85802C0.959757 2.50344 0.772924 3.44732 0.97189 4.51251C1.19027 5.67961 2.2409 8.69078 7.24658 11.4593C7.46253 11.5782 7.5426 11.8524 7.42128 12.0683C7.33879 12.2164 7.18592 12.2988 7.02821 12.2988H7.03063Z"
      fill="currentColor"
    />
  </svg>
)

export default Heart21Icon
